import React, { useContext, useMemo } from 'react'

import * as styled from './Sections.styled'

import { ChapterTitle, Section } from 'components'
import { DeviceSettingsContext, ProjectContext, selectedDefaultState } from 'context'
import { scrollContainerId } from 'hooks'
import { Screen } from 'utils'
import { IconContainer, TSvgContainer } from './components'
import { sectionsSubTitles } from './constants'

export const Sections = () => {
  const { setSelected } = useContext(DeviceSettingsContext)
  const {
    state: { screens = [] },
  } = useContext(ProjectContext)

  const sections = useMemo(
    () =>
      screens.reduce((sections, el) => {
        const [title, subTitle] = el.path.split('/')
        if (!sections[title]) {
          sections[title] = {}
        }
        if (!sections[title][subTitle]) {
          sections[title][subTitle] = []
        }
        sections[title][subTitle].push(el)
        return sections
      }, {} as { [key: string]: { [key: string]: Screen[] } }),
    [screens]
  )

  return (
    <styled.Container id={scrollContainerId} onClick={() => setSelected(selectedDefaultState)}>
      {Object.keys(sections).map(title => (
        <React.Fragment key={title}>
          <ChapterTitle>{title}</ChapterTitle>
          <div>
            {Object.keys(sections[title]).map(subTitle => (
              <Section key={subTitle} title={subTitle} subTitle={sectionsSubTitles[subTitle]}>
                {sections[title][subTitle].map(el => (
                  <React.Fragment key={el.id}>
                    <TSvgContainer screenConfig={el} />
                    {el.screenName === 'Launch Screen' && <IconContainer />}
                  </React.Fragment>
                ))}
              </Section>
            ))}
          </div>
        </React.Fragment>
      ))}
    </styled.Container>
  )
}
