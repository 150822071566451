import { SortDirection, TableCell, TableRow, TableSortLabel } from '@mui/material'
import React, { memo, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './HeadRow.styled'

import { Button, ButtonColor, Checkbox } from 'components'
import { ProgressContext } from 'context'
import { CMSCollectionProperty, CMSCollectionRecord, CMSService, RecordStatus } from 'services/cms'
import { Collection, TableQueryOrder, VariableSource, firstLetterUpperCase } from 'utils'
import { TheadController } from '../TheadController'

const defaultOrderedBy = 'createdAt'
const defaultOrder = TableQueryOrder.descending

interface Props {
  recordsLength: number
  collectionId: string
  currentFolder?: Collection
  selectedRecords: CMSCollectionRecord[]
  setSelectedRecords: React.Dispatch<React.SetStateAction<CMSCollectionRecord[]>>
  onSelectAllClick: (val: boolean) => void
  refreshRecords: () => void
  activeColumns: CMSCollectionProperty[]
  inactiveColumns: CMSCollectionProperty[]
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource>) => void
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  properties: CMSCollectionProperty[]
  setProperties: React.Dispatch<React.SetStateAction<CMSCollectionProperty[]>>
}

export const HeadRow: React.FC<Props> = memo(
  ({
    recordsLength,
    collectionId,
    currentFolder,
    selectedRecords,
    setSelectedRecords,
    onSelectAllClick,
    refreshRecords,
    activeColumns,
    inactiveColumns,
    currentVariable,
    setCurrentVariable,
    setOpenDeleteModal,
    properties,
    setProperties,
  }) => {
    const { startLoader, stopLoader, toast } = useContext(ProgressContext)
    const { id } = useParams()

    const dublicateSelected = () => {
      startLoader()
      Promise.all(selectedRecords.map(el => CMSService.postRecord(id as string, collectionId, el)))
        .then(() => {
          setSelectedRecords([])
          refreshRecords()
        })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }

    const publishSelectedRecords = (publish: boolean) => {
      startLoader()
      Promise.all([...selectedRecords.map(el => CMSService.publishRecord(id as string, collectionId, el.id, publish))])
        .then(() => {
          setSelectedRecords([])
          refreshRecords()
        })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }

    const sortClick = (name: string) =>
      setCurrentVariable(currentVariable => {
        let order = currentVariable.query?.order
        let orderedBy = currentVariable.query?.orderedBy
        if (orderedBy !== name || order === TableQueryOrder.ascending) {
          orderedBy = order === TableQueryOrder.ascending ? defaultOrderedBy : name
          order = defaultOrder
        } else {
          orderedBy = name
          order = TableQueryOrder.ascending
        }
        return { ...currentVariable, query: { ...currentVariable.query, order, orderedBy } }
      })

    const publishSelected = useMemo(
      () => selectedRecords.filter(el => el.recordStatus !== RecordStatus.PUBLISHED).length === selectedRecords.length,
      [selectedRecords]
    )
    const unpublishSelected = useMemo(
      () => selectedRecords.filter(el => el.recordStatus === RecordStatus.PUBLISHED).length === selectedRecords.length,
      [selectedRecords]
    )

    const order = currentVariable.query?.order
    const orderedBy = currentVariable.query?.orderedBy

    return (
      <>
        <TableRow>
          <TableCell>
            <Checkbox
              value={recordsLength > 0 && selectedRecords.length === recordsLength}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {currentFolder && <TableCell>Preview</TableCell>}
          {activeColumns.map(property => (
            <TableCell
              key={property.id}
              sortDirection={orderedBy === property.name ? (order?.replace('ending', '') as SortDirection) : false}
            >
              <TableSortLabel
                active={orderedBy === property.name}
                direction={
                  (orderedBy === property.name ? order : defaultOrder)?.replace('ending', '') as
                    | 'asc'
                    | 'desc'
                    | undefined
                }
                onClick={() => sortClick(property.name)}
              >
                {firstLetterUpperCase(property.name)}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell>RecordStatus</TableCell>
          <TableCell>
            <TheadController
              properties={properties}
              setProperties={setProperties}
              activeColumns={activeColumns}
              inactiveColumns={inactiveColumns}
            />
          </TableCell>
        </TableRow>
        {!!selectedRecords.length && (
          <TableRow sx={{ position: 'relative', height: '58px' }}>
            <TableCell
              style={{
                position: 'absolute',
                left: '0',
                width: '100%',
                maxWidth: 'none',
                height: '100%',
                padding: '0',
                border: 'none',
              }}
            >
              <styled.SelectedRecordsActions>
                <Button color={ButtonColor.DELETE} onClick={() => setOpenDeleteModal(true)}>
                  Delete
                </Button>
                <Button color={ButtonColor.SECONDARY} onClick={dublicateSelected}>
                  Dublicate
                </Button>
                {publishSelected && <Button onClick={() => publishSelectedRecords(true)}>Publish</Button>}
                {unpublishSelected && (
                  <Button color={ButtonColor.DELETE} onClick={() => publishSelectedRecords(false)}>
                    Unpublish
                  </Button>
                )}
              </styled.SelectedRecordsActions>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }
)
