import React from 'react'
import { ISvg, Svg } from '../Svg'

const Lock: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.42857C11.6142 8.42857 11.2443 8.57908 10.9715 8.84699C10.6987 9.1149 10.5455 9.47826 10.5455 9.85714V10.5714H13.4545V9.85714C13.4545 9.47826 13.3013 9.1149 13.0285 8.84699C12.7557 8.57908 12.3858 8.42857 12 8.42857ZM9.09091 9.85714V10.5714C8.80158 10.5714 8.52411 10.6843 8.31952 10.8852C8.11493 11.0862 8 11.3587 8 11.6429V15.9286C8 16.2127 8.11493 16.4853 8.31952 16.6862C8.52411 16.8871 8.80158 17 9.09091 17H14.9091C15.1984 17 15.4759 16.8871 15.6805 16.6862C15.8851 16.4853 16 16.2127 16 15.9286V11.6429C16 11.3587 15.8851 11.0862 15.6805 10.8852C15.4759 10.6843 15.1984 10.5714 14.9091 10.5714V9.85714C14.9091 9.09938 14.6026 8.37266 14.057 7.83684C13.5115 7.30102 12.7715 7 12 7C11.2285 7 10.4885 7.30102 9.94296 7.83684C9.3974 8.37266 9.09091 9.09938 9.09091 9.85714ZM12 14.6786C12.1194 14.6786 12.2376 14.6555 12.3479 14.6106C12.4582 14.5657 12.5584 14.5 12.6428 14.4171C12.7272 14.3342 12.7942 14.2357 12.8399 14.1274C12.8856 14.0191 12.9091 13.903 12.9091 13.7857C12.9091 13.6685 12.8856 13.5524 12.8399 13.444C12.7942 13.3357 12.7272 13.2373 12.6428 13.1544C12.5584 13.0715 12.4582 13.0057 12.3479 12.9608C12.2376 12.916 12.1194 12.8929 12 12.8929C11.7589 12.8929 11.5277 12.9869 11.3572 13.1544C11.1867 13.3218 11.0909 13.5489 11.0909 13.7857C11.0909 14.0225 11.1867 14.2496 11.3572 14.4171C11.5277 14.5845 11.7589 14.6786 12 14.6786Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Lock
