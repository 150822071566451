import React from 'react'
import { ISvg, Svg } from '../Svg'

const Landing: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.9845 14.4545C16.0909 13.6364 16.0909 12.8182 16.0909 12C16.0909 11.1818 16.05 10.3636 15.9845 9.54545H20.6482C21.1173 11.1482 21.1173 12.8518 20.6482 14.4545H15.9845ZM14.1518 7.90909C13.6364 4.71 12.6136 3 12 3C11.3864 3 10.3636 4.71 9.84818 7.90909H14.1518ZM9.84818 16.0909C10.3555 19.29 11.3864 21 12 21C12.6136 21 13.6364 19.29 14.1518 16.0909H9.84818ZM14.3482 9.54545H9.65182C9.58636 10.3064 9.54546 11.1164 9.54546 12C9.54546 12.8836 9.58636 13.6936 9.65182 14.4545H14.3482C14.4136 13.6936 14.4545 12.8836 14.4545 12C14.4545 11.1164 14.4545 10.3064 14.3482 9.54545ZM7.90909 12C7.90909 11.1818 7.90909 10.3636 8.01546 9.54545H3.35182C2.88273 11.1482 2.88273 12.8518 3.35182 14.4545H8.01546C7.95 13.6364 7.90909 12.8182 7.90909 12ZM8.20364 16.0909H3.99C4.55423 17.1898 5.33865 18.1608 6.29442 18.9435C7.25018 19.7261 8.35683 20.3036 9.54546 20.64C8.85998 19.2043 8.40706 17.6688 8.20364 16.0909ZM15.7964 16.0909C15.5929 17.6688 15.14 19.2043 14.4545 20.64C15.6432 20.3036 16.7498 19.7261 17.7056 18.9435C18.6613 18.1608 19.4458 17.1898 20.01 16.0909H15.7964ZM15.7964 7.90909H20.01C19.4458 6.81016 18.6613 5.83916 17.7056 5.05653C16.7498 4.2739 15.6432 3.6964 14.4545 3.36C15.14 4.79569 15.5929 6.33121 15.7964 7.90909ZM8.20364 7.90909C8.40706 6.33121 8.85998 4.79569 9.54546 3.36C8.35683 3.6964 7.25018 4.2739 6.29442 5.05653C5.33865 5.83916 4.55423 6.81016 3.99 7.90909H8.20364Z"
      fill="#212133"
    />
  </Svg>
)

export default Landing
