import React from 'react'
import { ISvg, Svg } from '../Svg'

const Calendar: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 15 15" fill="none" {...props}>
    <rect x="1.5" y="2.5" width="12" height="11" rx="1.5" stroke="#7F899E" />
    <path d="M4 1L4 4" stroke="#7F899E" strokeLinecap="round" />
    <path d="M11 1L11 4" stroke="#7F899E" strokeLinecap="round" />
    <path d="M2 6H13" stroke="#7F899E" />
  </Svg>
)

export default Calendar
