import React from 'react'
import { ISvg, Svg } from '../Svg'

const Reset: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3.5 2.75C3.5 2.33579 3.16421 2 2.75 2C2.33579 2 2 2.33579 2 2.75V6.93761H6.42851C6.84272 6.93761 7.17851 6.60183 7.17851 6.18761C7.17851 5.7734 6.84272 5.43761 6.42851 5.43761H4.20967C4.29549 5.38135 4.37322 5.3103 4.43807 5.227C4.91438 4.61519 5.5431 4.1329 6.26672 3.83199C7.2204 3.43542 8.28123 3.37789 9.27221 3.66899C10.2632 3.9601 11.1244 4.58224 11.712 5.4316C12.2997 6.28096 12.5783 7.30617 12.5014 8.33615C12.4245 9.36613 11.9967 10.3386 11.2894 11.0912C10.5821 11.8439 9.63808 12.3312 8.61487 12.4719C7.59165 12.6127 6.55112 12.3982 5.66692 11.8644C4.99601 11.4594 4.4459 10.889 4.06573 10.2133C3.86453 9.85564 3.44352 9.65209 3.05776 9.79196C2.67199 9.93183 2.46862 10.3608 2.65507 10.7263C3.16136 11.7189 3.93667 12.5556 4.89891 13.1365C6.07419 13.8461 7.45725 14.1311 8.8173 13.9441C10.1774 13.7571 11.4321 13.1093 12.3723 12.1089C13.3124 11.1084 13.881 9.81584 13.9833 8.4468C14.0855 7.07776 13.7152 5.71507 12.934 4.58611C12.1529 3.45714 11.0082 2.6302 9.69103 2.24326C8.37384 1.85632 6.96379 1.93279 5.69616 2.45991C4.84878 2.81229 4.0993 3.35179 3.5 4.03129V2.75Z"
      fill="#7F899E"
    />
  </Svg>
)
export default Reset
