import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { SettingsLayout } from '../components'
import { AppLanguages } from './sections'

export const Languages: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Languages</Text>
    <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
      Choose languages and manage translations.
    </Text>
    <CustomSectionsWrapper>
      <AppLanguages />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
