import React from 'react'
import { ISvg, Svg } from '../Svg'

const Video: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M19.8469 15.718C20.0939 15.8736 20.0939 16.2338 19.8469 16.3894L13.6849 20.2704C13.4208 20.4368 13.0768 20.2469 13.0768 19.9347L13.0768 12.1727C13.0768 11.8605 13.4208 11.6707 13.6849 11.837L19.8469 15.718Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <rect x="6.75" y="6.75" width="18.5" height="18.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Video
