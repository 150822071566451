import { hoverHintLabelId, selectedHintLabelId } from 'hooks'
import { screenComponentClassName } from 'utils'

export const hoverHintLabel = document.createElement('i')
hoverHintLabel.id = hoverHintLabelId

export const selectedHintLabel = document.createElement('i')
selectedHintLabel.id = selectedHintLabelId

export const pointerLine = document.createElement('div')
pointerLine.classList.add('pointerLine', screenComponentClassName)

export const hintLabelPositionHandler = (hintLabel: HTMLElement, screen: HTMLDivElement) => {
  hintLabel.style.removeProperty('right')
  hintLabel.style.left = '-1px'
  hintLabel.style.top = '-27px'
  const hiddenOverflows = ['hidden', 'auto', 'hidden auto', 'auto hidden']
  let parent = hintLabel.parentElement as HTMLElement
  while (parent && parent !== screen && !hiddenOverflows.includes(parent.style.overflow)) {
    parent = parent.parentElement as HTMLElement
  }
  const { x: sX, y: sY, width: sWidth } = parent.getBoundingClientRect()
  const { x: hX, y: hY, width: hWidth } = hintLabel.getBoundingClientRect()
  if (hX + hWidth > sX + sWidth) {
    hintLabel.style.removeProperty('left')
    hintLabel.style.right = '-1px'
  }
  if (hY < sY) {
    hintLabel.style.top = '1px'
  }
}
