import styled from 'styled-components'

export const ProductDetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 12px;
  line-height: 150%;
  & b {
    color: ${props => props.theme.colors.grey1};
  }
  & span {
    color: ${props => props.theme.colors.darkText};
  }
`
