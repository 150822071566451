import { Dispatch, SetStateAction } from 'react'

import { GetVariable, GetVariableValue } from 'hooks'
import {
  BooleanVariable,
  BrandingColorStyles,
  BrandingFontStyle,
  BrandingThemeType,
  ComponentType,
  LocalizedContent,
  Product,
  Resource,
  Screen,
  ScreenComponent,
  ScreenTopBar,
  ScreenTopBarComponentsKey,
  generateFirestoreId,
  getTextValue,
  screenComponentClassName,
  setComponents,
  setInnerModeEvent,
} from 'utils'

export const createScreenTopBar = async (
  id: string,
  topBarId: string,
  height: number,
  screen: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  products: Product[],
  compression: number,
  screenConfig: Screen,
  language: string,
  getPxValue: (val?: string) => string,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  topBar?: ScreenTopBar,
  showBackButton?: BooleanVariable,
  screenTitle?: LocalizedContent,
  onClickComponent?: (componentId?: string, subComponentId?: string) => void,
  setHoveredId?: Dispatch<SetStateAction<string>>,
  setMovableComponent?: (config: ScreenComponent) => void
) => {
  const screenTopBar = document.createElement('div')
  const fromVB = !!(onClickComponent && setHoveredId)
  if (fromVB) {
    screenTopBar.ondblclick = e => {
      e.stopPropagation()
      document.dispatchEvent(new Event(setInnerModeEvent))
    }
    screenTopBar.onmousedown = e => {
      e.stopPropagation()
      onClickComponent()
    }
    screenTopBar.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(topBarId)
    }
    screenTopBar.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
  }
  screenTopBar.id = id
  screenTopBar.className = screenComponentClassName
  screenTopBar.style.zIndex = '1'
  screenTopBar.style.position = 'sticky'
  screenTopBar.style.marginBottom = `-${height}px`
  screenTopBar.style.left = '0'
  screenTopBar.style.top = '0'
  screenTopBar.style.background = colorStyles.background[theme]
  screenTopBar.style.borderBottom = `1px solid ${colorStyles.surfaceVariant[theme]}`
  screenTopBar.style.boxSizing = 'border-box'
  screenTopBar.style.height = `${height}px`
  screenTopBar.style.padding = '0 12px 11px'
  screenTopBar.style.display = 'flex'
  screenTopBar.style.alignItems = 'flex-end'
  screen.appendChild(screenTopBar)

  const leading = document.createElement('div')
  const leadingId = generateFirestoreId()
  if (fromVB) {
    leading.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(leadingId)
    }
    leading.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
  }
  leading.id = `${ComponentType.stack}.${ScreenTopBarComponentsKey.leadingComponents}.${leadingId}`
  leading.className = screenComponentClassName
  leading.style.position = 'relative'
  leading.style.width = 'calc(100% / 3)'
  leading.style.display = 'flex'
  leading.style.alignItems = 'flex-end'
  screenTopBar.appendChild(leading)

  const headline = document.createElement('div')
  const headlineId = generateFirestoreId()
  if (fromVB) {
    headline.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(headlineId)
    }
    headline.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
  }
  headline.id = `${ComponentType.stack}.${ScreenTopBarComponentsKey.headlineComponents}.${headlineId}`
  headline.className = screenComponentClassName
  headline.style.position = 'relative'
  headline.style.width = 'calc(100% / 3)'
  headline.style.display = 'flex'
  headline.style.alignItems = 'flex-end'
  headline.style.justifyContent = 'center'
  screenTopBar.appendChild(headline)

  const trailing = document.createElement('div')
  const trailingId = generateFirestoreId()
  if (fromVB) {
    trailing.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(trailingId)
    }
    trailing.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
  }
  trailing.id = `${ComponentType.stack}.${ScreenTopBarComponentsKey.trailingComponents}.${trailingId}`
  trailing.className = screenComponentClassName
  trailing.style.position = 'relative'
  trailing.style.width = 'calc(100% / 3)'
  trailing.style.display = 'flex'
  trailing.style.alignItems = 'flex-end'
  trailing.style.justifyContent = 'flex-end'
  screenTopBar.appendChild(trailing)

  const showBackButtonValue = showBackButton?.source
    ? (await getVariableValue(showBackButton.source)) === 'true'
    : !!showBackButton?.constant
  if (showBackButtonValue) {
    const backButton = document.createElement('div')
    backButton.style.pointerEvents = 'none'
    backButton.style.display = 'flex'
    backButton.innerHTML = `
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0659 12.4121H5.50586" stroke="${colorStyles.onBackground[theme]}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7859 19.4121L5.50586 12.4121L12.7859 5.41211" stroke="${colorStyles.onBackground[theme]}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `
    leading.appendChild(backButton)
  }

  if (topBar?.leadingComponents?.length) {
    await setComponents(
      topBar.leadingComponents,
      leading,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      products,
      compression,
      false,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      () => {},
      onClickComponent,
      setHoveredId,
      setMovableComponent,
      topBarId
    )
  }

  if (topBar?.headlineComponents?.length) {
    await setComponents(
      topBar.headlineComponents,
      headline,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      products,
      compression,
      false,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      () => {},
      onClickComponent,
      setHoveredId,
      setMovableComponent,
      topBarId
    )
  } else if (screenTitle) {
    const title = document.createElement('div')
    title.style.pointerEvents = 'none'
    title.style.whiteSpace = 'nowrap'
    title.style.fontFamily = fontFamily
    title.style.fontWeight = '600'
    title.style.fontSize = '17px'
    title.style.lineHeight = '130%'
    title.style.color = colorStyles.onBackground[theme]
    title.innerHTML = await getTextValue(language, getVariableValue, screenTitle)
    headline.appendChild(title)
  }

  if (topBar?.trailingComponents?.length) {
    await setComponents(
      topBar.trailingComponents,
      trailing,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      products,
      compression,
      false,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      () => {},
      onClickComponent,
      setHoveredId,
      setMovableComponent,
      topBarId
    )
  }
}
