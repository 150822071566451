import React from 'react'
import { FillType, ISvg, Svg } from '../Svg'

const Analytics: React.FC<ISvg> = props => (
  <Svg fillType={FillType.STROKE} viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M18 20V10" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 20V4" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 20V14" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
)

export default Analytics
