import { Box } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from '../index.styled'

import { Crumbs, CustomSectionsWrapper, Text, Type } from 'components'
import { WorkspaceContext } from 'context'
import { BillingAccount, MonthlySpend, PaymentHistory, Plans, WorkspaceQuotas } from './components'

export const Billing: React.FC = () => {
  const { workspace } = useContext(WorkspaceContext)

  return workspace ? (
    <styled.Container>
      <styled.MaxWidthContainer>
        <Crumbs
          firstCrumbTitle={workspace?.name}
          secondCrumbTitle={`Billing (workspaceId=${workspace.id}, customerId=${workspace.billingAccountId})`}
          icon={null}
          to={`/workspaces/${workspace.id}`}
        />
        <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
          Workspace members can create, edit, and delete projects. They can also access project data and invite other
          members.
        </Text>
        <Box display="flex" gap="20px">
          <CustomSectionsWrapper>
            <MonthlySpend />
            <PaymentHistory />
            <Plans />
          </CustomSectionsWrapper>
          <Box width="300px" minWidth="300px" display="flex" flexDirection="column" gap="20px">
            <BillingAccount />
            <WorkspaceQuotas />
          </Box>
        </Box>
      </styled.MaxWidthContainer>
    </styled.Container>
  ) : null
}
