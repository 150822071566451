import React from 'react'
import { ISvg, Svg } from '../Svg'

const SendMessage: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 28 28" fill="none" {...props}>
    <rect width="28" height="28" rx="14" fill="#737BF8" />
    <path d="M14 20V10" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M9 13L14 8L19 13" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <rect x="9" y="9" width="10" height="10" rx="3" fill="white" stroke="white" strokeWidth="2" />
  </Svg>
)
export default SendMessage
