import React from 'react'
import { ISvg, Svg } from '../Svg'

const TopBar: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="0.5" y="1.5" width="15" height="13" rx="2.3" stroke="#7F899E" />
    <path d="M5 6L3 8L5 10" stroke="#7F899E" strokeLinecap="round" />
    <path
      d="M7.38759 12C7.69185 12 7.86497 11.8481 7.97513 11.4956L8.54693 9.81424H11.4427L12.0197 11.501C12.1246 11.8481 12.2978 12 12.6125 12C12.9535 12 13.2 11.7668 13.2 11.4468C13.2 11.3437 13.1791 11.2407 13.1266 11.0888L10.8184 4.62915C10.661 4.19525 10.4092 4 10 4C9.58562 4 9.32857 4.20068 9.17644 4.63458L6.87349 11.0888C6.82103 11.2353 6.80005 11.3546 6.80005 11.4468C6.80005 11.7776 7.03611 12 7.38759 12ZM8.84595 8.82169L9.97382 5.38847H10.0053L11.1437 8.82169H8.84595Z"
      fill="#7F899E"
    />
  </Svg>
)

export default TopBar
