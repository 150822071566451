import React, { useContext, useEffect, useState } from 'react'
import AceEditor from 'react-ace'
import { Link, useLocation } from 'react-router-dom'

import * as styled from './FileContent.styled'

import { DropDown, Icon, Name } from 'components'
import { ApplicationContext } from 'context'
import { File } from 'pages/Repository/pages'

// @ts-ignore
const modelist = ace.require('ace/ext/modelist')

const getImage = (data: File) => (data.type.includes('image') ? `data:${data.type};base64,${btoa(data.content)}` : '')

interface Props {
  data: File
  flag: string
}

export const FileContent: React.FC<Props> = ({ data, flag }) => {
  const { application } = useContext(ApplicationContext)
  const { pathname } = useLocation()
  const [image, setImage] = useState(getImage(data))

  useEffect(() => {
    setImage(getImage(data))
  }, [data])

  const crumbs = pathname.replace(flag, '').split('/')
  const commit = crumbs[0]?.slice(0, 7)
  const name = pathname.split('/').slice(-1)[0]
  const mode = modelist.getModeForPath(name).name

  return (
    <styled.Container>
      <styled.Name>{name}</styled.Name>
      <styled.DropDowns>
        <DropDown options={[{ label: 'Source' }]} defaultLabel="Source" width="120px" />
        <DropDown
          options={[{ label: 'master', iconName: Name.REPOSITORY_BRANCH }]}
          defaultLabel="master"
          width="120px"
        />
        <DropDown options={[{ label: commit }]} defaultLabel={commit} width="120px" />
      </styled.DropDowns>
      <styled.File>
        <styled.FileHead>
          <div>
            <Icon name={Name.REPOSITORY_FILE} />
            <styled.FileCrumbs>
              {crumbs.map((el, ind) => (
                <Link
                  key={el}
                  to={`${pathname
                    .split('/')
                    .slice(0, -(crumbs.length - 1 - ind))
                    .join('/')}${ind === 0 ? '/' : ''}`}
                >
                  {ind === 0 ? application?.name : `/ ${el}`}
                </Link>
              ))}
            </styled.FileCrumbs>
          </div>
          <span>...</span>
        </styled.FileHead>
        {image ? (
          <styled.ImageWrapper>
            <img src={image} alt="img" />
          </styled.ImageWrapper>
        ) : (
          <styled.CodeWrapper>
            <AceEditor value={data.content} mode={mode} width="100%" height="100%" readOnly />
          </styled.CodeWrapper>
        )}
      </styled.File>
    </styled.Container>
  )
}
