export enum ValueType {
  any = 'any',
  string = 'string',
  number = 'number',
  image = 'image',
  video = 'video',
  audio = 'audio',
  file = 'file',
  color = 'color',
  date = 'date',
  dateTime = 'dateTime',
  insets = 'insets',
  boolean = 'boolean',
  url = 'url',
  list = 'list',
  user = 'user',
  record = 'record',
  array = 'array',
  coordinate = 'coordinate',
  calendarStyle = 'calendarStyle',
  calendarItemSelection = 'calendarItemSelection',
  accessLevel = 'accessLevel',
  richText = 'richText',
  json = 'json',
  keyValueMap = 'keyValueMap',
  // for local
  typescript = 'typescript',
  fileInput = 'fileInput',
  folder = 'folder',
  screenshots = 'screenshots',
  terms = 'terms',
  avatar = 'avatar',
  appIcon = 'appIcon',
  coreColor = 'coreColor',
  launchScreenIcon = 'launchScreenIcon',
  launchScreenBackground = 'launchScreenBackground',
  variable = 'variable',
  logicItem = 'logicItem',
  subCollection = 'subCollection',
}

export interface MediaConstant {
  resourceId?: string
}

export enum CalendarStyleConstant {
  monthlyHorizontal = 'monthlyHorizontal',
  monthlyVertical = 'monthlyVertical',
  weekly = 'weekly',
}

export enum CalendarItemSelectionConstant {
  noSelection = 'noSelection',
  rangeStart = 'rangeStart',
  rangeEnd = 'rangeEnd',
  rangeMiddle = 'rangeMiddle',
  single = 'single',
}

export interface InsetsConstant {
  leading?: string
  trailing?: string
  top?: string
  bottom?: string
}

export interface CoordinateConstant {
  latitude?: string
  longitude?: string
}

export enum VariableSourceType {
  localVariable = 'localVariable',
  globalVariable = 'globalVariable',
  contextVariable = 'contextVariable',
  collection = 'collection',
  component = 'component',
}

export enum TableQueryFilterOperand {
  equal = 'equal',
  notEqual = 'notEqual',
  less = 'less',
  lessOrEqual = 'lessOrEqual',
  greater = 'greater',
  greaterOrEqual = 'greaterOrEqual',
  contains = 'contains',
}

export interface TableQueryFilter {
  field: string
  operator: TableQueryFilterOperand
  value: AnyVariable
  // for local
  default?: boolean
}

export enum TableQueryOrder {
  ascending = 'ascending',
  descending = 'descending',
}

export interface TableQuery {
  filters?: TableQueryFilter[]
  search?: AnyVariable
  orderedBy?: string
  order?: TableQueryOrder
}

export interface ItemSize {
  width?: string
  height?: string
}

export enum VariableTransformTransform {
  boolNot = 'boolNot',
  field = 'field',
  cast = 'cast',
  resize = 'resize',
  formatTimeInterval = 'formatTimeInterval',
  formatTimeAgo = 'formatTimeAgo',
  first = 'first',
  exists = 'exists',
  trimWhitespacesAndNewlines = 'trimWhitespacesAndNewlines',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  compareEqual = 'compareEqual',
  compareNotEqual = 'compareNotEqual',
  compareGreater = 'compareGreater',
  compareLess = 'compareLess',
  compareGreaterOrEqual = 'compareGreaterOrEqual',
  compareLessOrEqual = 'compareLessOrEqual',
  conditionalValue = 'conditionalValue',
  conversationImage = 'conversationImage',
  conversationTitle = 'conversationTitle',
  year = 'year',
  monthYearLong = 'monthYearLong',
  monthYearDateLong = 'monthYearDateLong',
  identifier = 'identifier',
  anyExists = 'anyExists',
}

export enum VariableTransformResizeType {
  proportionally = 'proportionally',
  exact = 'exact',
}

export interface VariableTransform {
  transform: VariableTransformTransform
  fieldName?: string
  query?: TableQuery
  value?: AnyVariable
  value2?: AnyVariable
  size?: ItemSize
  resizeType?: VariableTransformResizeType
  participantsKey?: string
  participantImageKey?: string
  participantNameKeys?: string[]
}

export interface VariableSource {
  type: VariableSourceType
  variableName?: string
  componentName?: string
  collection?: CollectionVariable
  selector?: StringVariable
  fieldName?: string
  query?: TableQuery
  transforms?: VariableTransform[]
}

export interface BooleanVariable {
  constant?: boolean
  source?: VariableSource
}

export interface StringVariable {
  constant?: string
  source?: VariableSource
}

export interface MediaVariable {
  constant?: MediaConstant
  source?: VariableSource
}

export interface InsetsVariable {
  constant?: InsetsConstant
  source?: VariableSource
}

export interface CoordinateVariable {
  constant?: CoordinateConstant
  source?: VariableSource
}

export interface CalendarStyleVariable {
  constant?: CalendarStyleConstant
  source?: VariableSource
}

export interface CollectionVariable {
  name: string
  params?: {
    [key: string]: VariableSource
  }
}

export enum AnyVariablTypeKey {
  urlConstant = 'urlConstant',
  accessLevelConstant = 'accessLevelConstant',
  booleanConstant = 'booleanConstant',
  dateConstant = 'dateConstant',
  dateTimeConstant = 'dateTimeConstant',
  textConstant = 'textConstant',
  videoConstant = 'videoConstant',
  numberConstant = 'numberConstant',
  imageConstant = 'imageConstant',
  colorConstant = 'colorConstant',
  insetsConstant = 'insetsConstant',
  coordinateConstant = 'coordinateConstant',
  calendarStyleConstant = 'calendarStyleConstant',
  arrayConstant = 'arrayConstant',
  source = 'source',
}

export interface AnyVariable {
  [AnyVariablTypeKey.urlConstant]?: string
  [AnyVariablTypeKey.accessLevelConstant]?: string
  [AnyVariablTypeKey.booleanConstant]?: boolean
  [AnyVariablTypeKey.dateConstant]?: string
  [AnyVariablTypeKey.dateTimeConstant]?: string
  [AnyVariablTypeKey.textConstant]?: LocalizedContent
  [AnyVariablTypeKey.videoConstant]?: MediaConstant
  [AnyVariablTypeKey.numberConstant]?: string
  [AnyVariablTypeKey.imageConstant]?: MediaConstant
  [AnyVariablTypeKey.colorConstant]?: string
  [AnyVariablTypeKey.insetsConstant]?: InsetsConstant
  [AnyVariablTypeKey.coordinateConstant]?: CoordinateConstant
  [AnyVariablTypeKey.calendarStyleConstant]?: CalendarStyleConstant
  [AnyVariablTypeKey.arrayConstant]?: AnyVariable[]
  [AnyVariablTypeKey.source]?: VariableSource
}

export enum ImageLayout {
  fill = 'fill',
  fit = 'fit',
  stretch = 'stretch',
  center = 'center',
}

export interface Action {
  options?: ActionOption
  enabled?: BooleanVariable
  actionType?: ActionType
  parameters?: ScreenInputParameter[]
  collection?: CollectionVariable
  record?: VariableSource
  recordModifications?: RecordModification[]
  actionArguments?: ActionArgument[]
  componentName?: string
  variable?: AnyVariable
  valueTarget?: VariableSource
  transforms?: VariableTransform[]
  variableContextKey?: string
  description?: LocalizedContent
  subActions?: Action[]
  fallbackActions?: Action[]
}

export enum ActionType {
  placeholder = 'placeholder',
  acceptTerms = 'acceptTerms',
  appleAuthentication = 'appleAuthentication',
  googleAuthentication = 'googleAuthentication',
  anonymousAuthentication = 'anonymousAuthentication',
  enableNotifications = 'enableNotifications',
  enableIdfaAndCookieTracking = 'enableIdfaAndCookieTracking',
  enableLocationTracking = 'enableLocationTracking',
  logout = 'logout',
  openEmail = 'openEmail',
  openWeb = 'openWeb',
  openLink = 'openLink',
  checkoutSelected = 'checkoutSelected',
  restorePurchases = 'restorePurchases',
  closePurchases = 'closePurchases',
  deleteAccount = 'deleteAccount',
  updateRecord = 'updateRecord',
  createRecord = 'createRecord',
  deleteRecord = 'deleteRecord',
  setValue = 'setValue',
  getPhoto = 'getPhoto',
  getVideo = 'getVideo',
  getAudio = 'getAudio',
  getFile = 'getFile',
  navigate = 'navigate',
  navigateBack = 'navigateBack',
  show = 'show',
  scrollToStart = 'scrollToStart',
  subscribe = 'subscribe',
  openDocument = 'openDocument',
  runFlow = 'runFlow',
  createUserWithEmailAndPassword = 'createUserWithEmailAndPassword',
  signInWithEmailAndPassword = 'signInWithEmailAndPassword',
  sendPasswordResetEmail = 'sendPasswordResetEmail',
  signInWithPhoneNumber = 'signInWithPhoneNumber',
  reSignInWithPhoneNumber = 'reSignInWithPhoneNumber',
  confirmPhoneNumber = 'confirmPhoneNumber',
  sendSignInLinkToEmail = 'sendSignInLinkToEmail',
  reSendSignInLinkToEmail = 'reSendSignInLinkToEmail',
  openEmailApp = 'openEmailApp',
  waitProfile = 'waitProfile',
  checkNotificationsEnabled = 'checkNotificationsEnabled',
  checkIdfaAndCookieTrackingEnabled = 'checkIdfaAndCookieTrackingEnabled',
  checkLocationTrackingEnabled = 'checkLocationTrackingEnabled',
  checkAccessLevels = 'checkAccessLevels',
  check = 'check',
  handleDeeplink = 'handleDeeplink',
}

export interface RecordModification {
  type?: 'set'
  fieldName?: string
  value?: AnyVariable
}

export interface ActionArgument {
  name: string
  value: AnyVariable
}

export interface ScreenInputParameter {
  parameter?: string
  getter?: AnyVariable
}

export interface ActionOption {
  email?: string
  screen?: string
  link?: StringVariable
}

export interface LocalizedContent {
  key: string
  locales: { [key: string]: string }
  values?: VariableSource[]
}

export enum TextAlignment {
  leading = 'leading',
  trailing = 'trailing',
  center = 'center',
}

export interface TabBar {
  id: string
  name: string
  tabs?: TabBarItem[]
}

export interface TabBarItem {
  screen: string
  title: LocalizedContent
  image: MediaVariable
  visible: BooleanVariable
}

export interface ScreenParameter {
  parameter: string
  inout?: boolean
  type: ValueType
  accept?: ValueType
  collection?: string
}

export enum ComponentType {
  view = 'view',
  spacer = 'spacer',
  divider = 'divider',
  stack = 'stack',
  overlay = 'overlay',
  carousel = 'carousel',
  datePicker = 'datePicker',
  button = 'button',
  image = 'image',
  text = 'text',
  textInput = 'textInput',
  video = 'video',
  list = 'list',
  scroll = 'scroll',
  map = 'map',
  product = 'product',
  themePicker = 'themePicker',
  notificationsSettings = 'notificationsSettings',
  calendar = 'calendar',
  videoPlayer = 'videoPlayer',
  slider = 'slider',
  toggle = 'toggle',
  progressIndicator = 'progressIndicator',
}

export enum ComponentAlignment {
  fill = 'fill',
  leading = 'leading',
  start = 'start',
  top = 'top',
  topLeading = 'topLeading',
  topCenter = 'topCenter',
  topTrailing = 'topTrailing',
  centerLeading = 'centerLeading',
  center = 'center',
  centerTrailing = 'centerTrailing',
  trailing = 'trailing',
  end = 'end',
  bottom = 'bottom',
  bottomLeading = 'bottomLeading',
  bottomCenter = 'bottomCenter',
  bottomTrailing = 'bottomTrailing',
}

export enum ComponentsDirection {
  horizontal = 'horizontal',
  vertical = 'vertical',
  all = 'all',
}

export enum TextInputType {
  general = 'general',
  email = 'email',
  password = 'password',
  number = 'number',
  phone = 'phone',
}

export enum ImageLocation {
  leading = 'leading',
  trailing = 'trailing',
  leadingAttached = 'leadingAttached',
  trailingAttached = 'trailingAttached',
  top = 'top',
  bottom = 'bottom',
}

export enum ComponentStyle {
  column = 'column',
  row = 'row',
  grid = 'grid',
  swipeable = 'swipeable',
}

export enum TextType {
  plain = 'plain',
  markdown = 'markdown',
}

export enum MapStyle {
  standard = 'standard',
  hybrid = 'hybrid',
  satellite = 'satellite',
}

export interface ScreenComponent {
  id: string
  componentType: ComponentType
  name: string
  componentClass?: string
  componentStyle?: ComponentStyle
  visible?: BooleanVariable
  enabled?: BooleanVariable
  alpha?: StringVariable
  cornersRadius?: StringVariable
  borderColor?: StringVariable
  borderWidth?: StringVariable
  width?: StringVariable
  height?: StringVariable
  widthHeightRatio?: StringVariable
  margins?: InsetsConstant
  backgroundColor?: StringVariable
  backgroundPattern?: MediaConstant
  backgroundGradient?: BackgroundGradient
  actions?: Action[]
  localVariables?: LocalVariable[]
  componentAlignment: ComponentAlignment
  subComponents?: ScreenComponent[]
  componentsSpacing?: StringVariable
  removeHiddenComponents?: BooleanVariable
  componentsDirection?: ComponentsDirection
  text?: LocalizedContent
  textAlignment?: TextAlignment
  textColor?: StringVariable
  font?: string
  numberOfLines?: StringVariable
  textType?: TextType
  showMoreTextButton?: BooleanVariable
  textInputType?: TextInputType
  textInputMultiline?: BooleanVariable
  placeholderText?: LocalizedContent
  placeholderTextColor?: StringVariable
  placeholderTextFont?: string
  contentPadding?: InsetsVariable
  image?: MediaVariable
  imageColor?: StringVariable
  imageLayout?: ImageLayout
  imageLocation?: ImageLocation
  placeholderImage?: MediaVariable
  placeholderImageColor?: StringVariable
  placeholderImageLayout?: ImageLayout
  date?: StringVariable
  minimumDate?: StringVariable
  maximumDate?: StringVariable
  dateFormat?: DateTimeFormat
  displayDate?: StringVariable
  video?: MediaVariable
  autoplay?: BooleanVariable
  loop?: BooleanVariable
  duration?: number
  showScrollBar?: BooleanVariable
  reversed?: BooleanVariable
  listItemContextKey?: string
  listItems?: StringVariable
  listItemCoordinate?: CoordinateVariable
  mapStyle?: MapStyle
  showUserLocation?: BooleanVariable
  coordinate?: CoordinateVariable
  zoom?: StringVariable
  product?: string
  selected?: BooleanVariable
  showDiscount?: BooleanVariable
  calendarStyle?: CalendarStyleVariable
  showHeader?: BooleanVariable
  headerFont?: string
  headerColor?: StringVariable
  showWeekdays?: BooleanVariable
  listItemDate?: StringVariable
  value?: StringVariable
  minimumValue?: StringVariable
  maximumValue?: StringVariable
  primaryColor?: StringVariable
  secondaryColor?: StringVariable
  discrete?: BooleanVariable
  // for local
  listId?: string
  indexInList?: number
}

export enum ScreenSize {
  responsive = 'responsive',
  compact = 'compact',
}

export enum Module {
  launchScreen = 'launchScreen',
  onboarding = 'onboarding',
  beforeJoin = 'beforeJoin',
  authentication = 'authentication',
  emailAuthentication = 'emailAuthentication',
  phoneAuthentication = 'phoneAuthentication',
  permissions = 'permissions',
  home = 'home',
  profile = 'profile',
  about = 'about',
  settings = 'settings',
  video = 'video',
  paywall = 'paywall',
  others = 'others',
}

export enum ScreenStatusBarStyle {
  default = 'default',
  inverted = 'inverted',
  light = 'light',
  dark = 'dark',
  hidden = 'hidden',
}

export enum ScreenTopBarComponentsKey {
  leadingComponents = 'leadingComponents',
  headlineComponents = 'headlineComponents',
  trailingComponents = 'trailingComponents',
}

export type ScreenTopBar = {
  [key in ScreenTopBarComponentsKey]?: ScreenComponent[]
}

export interface ExperimentalPane {
  screenName?: string
  screenComponentIds?: string[]
  components?: ScreenComponent[]
}

export interface Experimental {
  trailingPane?: ExperimentalPane
  leadingPane?: ExperimentalPane
}

export interface Screen {
  path: string
  id: string
  screenName: string
  size: ScreenSize
  module: Module
  components?: ScreenComponent[]
  enabled?: boolean
  onScreenVisitActions?: Action[]
  statusBarStyle?: ScreenStatusBarStyle
  backgroundColor?: StringVariable
  backgroundPattern?: MediaConstant
  backgroundGradient?: BackgroundGradient
  topBar?: ScreenTopBar
  showTopBar?: BooleanVariable
  showBackButton?: BooleanVariable
  screenTitle?: LocalizedContent
  tabBar?: string
  accessLevels?: string[]
  inputParameters?: ScreenParameter[]
  localVariables?: LocalVariable[]
  experimental?: Experimental
}

export enum DateTimeFormatFormat {
  short = 'short',
  medium = 'medium',
  full = 'full',
}

export interface DateTimeFormat {
  format?: DateTimeFormatFormat
  customFormat?: string
}

export interface IosConfig {
  bundleId: string
  teamId?: string
  appGroups?: string[]
  appleKeyId?: string
  projectName: string
}

export interface AndroidConfig {
  packageName: string
  projectName: string
  minSDKVersion: string
  dataProviderApps?: string[]
}

export enum ProductType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  IN_APP_PURCHASE = 'IN_APP_PURCHASE',
}

export enum ProductStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export interface Product {
  id: string
  name: string
  description: string
  type: ProductType
  duration: string
  trialDuration?: string
  isAutoRenewable?: boolean
  isConsumable?: boolean
  tier: string
  accessLevels?: string[]
  status: ProductStatus
  errors?: string[]
  discount?: string
}

export interface Resource {
  id: string
  assetId?: string
  version?: string
  format: string
  folder?: string
  bytes: number
  width: number
  height: number
  signature?: string
  url: string
  resourceType: string
  createdAt: string
  name: string
}

export enum BackgroundGradientDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
  radial = 'radial',
}

export interface BackgroundGradient {
  start: string
  end: string
  direction: BackgroundGradientDirection
}

export enum BrandingColorType {
  background = 'background',
  black = 'black',
  error = 'error',
  errorContainer = 'errorContainer',
  inverseOnSurface = 'inverseOnSurface',
  inversePrimary = 'inversePrimary',
  inverseSurface = 'inverseSurface',
  neutral = 'neutral',
  onBackground = 'onBackground',
  onError = 'onError',
  onErrorContainer = 'onErrorContainer',
  onPrimary = 'onPrimary',
  onPrimaryContainer = 'onPrimaryContainer',
  onSecondary = 'onSecondary',
  onSecondaryContainer = 'onSecondaryContainer',
  onSurface = 'onSurface',
  onSurfaceVariant = 'onSurfaceVariant',
  onTertiary = 'onTertiary',
  onTertiaryContainer = 'onTertiaryContainer',
  outline = 'outline',
  primary = 'primary',
  primaryContainer = 'primaryContainer',
  primaryVariant = 'primaryVariant',
  secondary = 'secondary',
  secondaryContainer = 'secondaryContainer',
  secondaryVariant = 'secondaryVariant',
  semiTransparentBackground = 'semiTransparentBackground',
  shadow = 'shadow',
  surface = 'surface',
  surfaceVariant = 'surfaceVariant',
  tertiary = 'tertiary',
  tertiaryContainer = 'tertiaryContainer',
  transparent = 'transparent',
  white = 'white',
}

export enum BrandingThemeType {
  light = 'light',
  dark = 'dark',
}

export type BrandingColorStyles = {
  [key in BrandingColorType]: { [key in BrandingThemeType]: string }
}

export enum Platform {
  iOS = 'iOS',
  Android = 'Android',
}

export interface BrandingFontStyleItem {
  fontFamily: string
  fontStyle: string
  fontWeight: number
  fontSize: number
  lineHeight: number
  letterSpacing: number
}

export interface BrandingFontStyle {
  styleName: string
  categoryName: string
  ios: BrandingFontStyleItem
  android: BrandingFontStyleItem
}

export enum InnerLaunchScreenIcon {
  splashIcon = 'splashIcon',
  brandLogo = 'brandLogo',
}

export enum LaunchScreenIcon {
  androidIcon = 'androidIcon',
  iosIcon = 'iosIcon',
}

export interface CollectionProperty {
  name: string
  type: ValueType
  position: number
  isRequired?: boolean
  isSystem?: boolean
  isHidden?: boolean
  accept?: ValueType
  collection?: string
  dataObject?: string
  helpText?: string
  acceptValues?: string[]
}

export enum CollectionRule {
  Anyone = 'Anyone',
  Owner = 'Owner',
  'No-one' = 'No-one',
  Custom = 'Custom',
}

export interface CollectionPermissions {
  list?: CollectionRule
  get?: CollectionRule
  create?: CollectionRule
  update?: CollectionRule
  delete?: CollectionRule
}

export interface CollectionDatasource {
  type: string
  databaseName?: string
  collectionName: string
}

export interface Collection {
  name: string
  properties?: CollectionProperty[]
  datasource: CollectionDatasource
  isSystem?: boolean
  helpText?: string
  titleField?: string
  previewScreen?: string
  permissions?: CollectionPermissions
}

export interface Storage {
  name: string
  datasource: {
    type: string
    bucketName: string
  }
  indexer: string
}

export interface DataObject {
  name: string
  properties?: CollectionProperty[]
}

export interface LocalVariable {
  name: string
  variable?: AnyVariable
  type?: ValueType
  accept?: ValueType
  collection?: string
}

export interface GlobalVariable {
  id: string
  variableName: string
  variable: AnyVariable
}

export interface Flow {
  name: string
  sourceCode: string
  input: DataObject
  output: DataObject
}

export interface Prompt {
  name: string
  sourceCode: string
  input: DataObject
  output: DataObject
}

export interface Indexer {
  name: string
  sourceCode: string
  input: DataObject
  output: DataObject
}

export interface Retriever {
  name: string
  sourceCode: string
  input: DataObject
  output: DataObject
}

export interface Embedder {
  name: string
  sourceCode: string
  input: DataObject
  output: DataObject
}

export interface ConfigLogic {
  flows?: Flow[]
  prompts?: Prompt[]
  indexers?: Indexer[]
  retrievers?: Retriever[]
  embedders?: Embedder[]
}

export interface ConfigData {
  storages?: Storage[]
  dataObjects?: DataObject[]
  collections?: Collection[]
  globalVariables?: GlobalVariable[]
}

export interface Config {
  codeplatform: {
    syntaxVersion: string
    template: string
    templateName: string
  }
  apiUrl: string
  ios: IosConfig
  android: AndroidConfig
  products?: Product[]
  logic: ConfigLogic
  data: ConfigData
  resources?: Resource[]
  localization: {
    languages?: string[]
    primaryLanguage: string
  }
  branding: {
    appName: LocalizedContent
    icons: { [key in LaunchScreenIcon]: string }
    typography: {
      iosDefaultFontFamily: string
      androidDefaultFontFamily: string
      customFontFamilies?: string[]
      fontStyles?: BrandingFontStyle[]
    }
    colorStyles: BrandingColorStyles
  }
  tabBars?: TabBar[]
  screens?: Screen[]
}

export const schema = {
  type: 'object',
  $defs: {
    Color: {
      required: ['light', 'dark'],
      type: 'object',
      properties: {
        light: {
          type: 'string',
        },
        dark: {
          type: 'string',
        },
      },
    },
    ValueType: {
      type: 'string',
      enum: [
        'any',
        'string',
        'number',
        'image',
        'video',
        'audio',
        'file',
        'color',
        'date',
        'dateTime',
        'insets',
        'boolean',
        'url',
        'list',
        'user',
        'record',
        'array',
        'coordinate',
        'calendarStyle',
        'calendarItemSelection',
        'accessLevel',
        'richText',
        'json',
        'keyValueMap',
      ],
    },
    MediaConstant: {
      type: 'object',
      properties: {
        resourceId: {
          type: 'string',
        },
      },
    },
    CalendarStyleConstant: {
      type: 'string',
      enum: ['monthlyHorizontal', 'monthlyVertical', 'weekly'],
    },
    CalendarItemSelectionConstant: {
      type: 'string',
      enum: ['noSelection', 'rangeStart', 'rangeEnd', 'rangeMiddle', 'single'],
    },
    InsetsConstant: {
      type: 'object',
      properties: {
        leading: {
          type: 'string',
        },
        trailing: {
          type: 'string',
        },
        top: {
          type: 'string',
        },
        bottom: {
          type: 'string',
        },
      },
    },
    CoordinateConstant: {
      type: 'object',
      properties: {
        latitude: {
          type: 'string',
        },
        longitude: {
          type: 'string',
        },
      },
    },
    VariableSource: {
      required: ['type'],
      type: 'object',
      properties: {
        type: {
          type: 'string',
          enum: ['localVariable', 'globalVariable', 'contextVariable', 'collection', 'component'],
        },
        variableName: {
          type: 'string',
        },
        componentName: {
          type: 'string',
        },
        collection: {
          $ref: '#/$defs/CollectionVariable',
        },
        selector: {
          $ref: '#/$defs/StringVariable',
        },
        fieldName: {
          type: 'string',
        },
        query: {
          $ref: '#/$defs/TableQuery',
        },
        transforms: {
          type: 'array',
          items: {
            $ref: '#/$defs/VariableTransform',
          },
        },
      },
    },
    LocalVariable: {
      required: ['name'],
      type: 'object',
      properties: {
        variable: {
          $ref: '#/$defs/AnyVariable',
        },
        name: {
          type: 'string',
        },
        type: {
          $ref: '#/$defs/ValueType',
        },
        accept: {
          $ref: '#/$defs/ValueType',
        },
        collection: {
          type: 'string',
        },
      },
    },
    BooleanVariable: {
      type: 'object',
      properties: {
        constant: {
          type: 'boolean',
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    StringVariable: {
      type: 'object',
      properties: {
        constant: {
          type: 'string',
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    MediaVariable: {
      type: 'object',
      properties: {
        constant: {
          $ref: '#/$defs/MediaConstant',
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    InsetsVariable: {
      type: 'object',
      properties: {
        constant: {
          $ref: '#/$defs/InsetsConstant',
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    CoordinateVariable: {
      type: 'object',
      properties: {
        constant: {
          $ref: '#/$defs/CoordinateConstant',
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    CalendarStyleVariable: {
      type: 'object',
      properties: {
        constant: {
          $ref: '#/$defs/CalendarStyleConstant',
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    CollectionVariable: {
      required: ['name'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        params: {
          type: 'object',
          additionalProperties: {
            $ref: '#/$defs/VariableSource',
          },
        },
      },
    },
    AnyVariable: {
      type: 'object',
      properties: {
        urlConstant: {
          type: 'string',
        },
        accessLevelConstant: {
          type: 'string',
        },
        booleanConstant: {
          type: 'boolean',
        },
        dateConstant: {
          type: 'string',
        },
        dateTimeConstant: {
          type: 'string',
        },
        textConstant: {
          $ref: '#/$defs/LocalizedContent',
        },
        videoConstant: {
          $ref: '#/$defs/MediaConstant',
        },
        numberConstant: {
          type: 'string',
        },
        imageConstant: {
          $ref: '#/$defs/MediaConstant',
        },
        colorConstant: {
          type: 'string',
        },
        insetsConstant: {
          $ref: '#/$defs/InsetsConstant',
        },
        coordinateConstant: {
          $ref: '#/$defs/CoordinateConstant',
        },
        calendarStyleConstant: {
          $ref: '#/$defs/CalendarStyleConstant',
        },
        arrayConstant: {
          type: 'array',
          items: {
            $ref: '#/$defs/AnyVariable',
          },
        },
        source: {
          $ref: '#/$defs/VariableSource',
        },
      },
    },
    GlobalVariable: {
      required: ['id', 'variable', 'variableName'],
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        variable: {
          $ref: '#/$defs/AnyVariable',
        },
        variableName: {
          type: 'string',
        },
      },
    },
    VariableTransform: {
      required: ['transform'],
      type: 'object',
      properties: {
        transform: {
          type: 'string',
          enum: [
            'boolNot',
            'field',
            'cast',
            'resize',
            'formatTimeInterval',
            'formatTimeAgo',
            'first',
            'exists',
            'trimWhitespacesAndNewlines',
            'isEmpty',
            'isNotEmpty',
            'compareEqual',
            'compareNotEqual',
            'compareGreater',
            'compareLess',
            'compareGreaterOrEqual',
            'compareLessOrEqual',
            'conditionalValue',
            'conversationImage',
            'conversationTitle',
            'year',
            'monthYearLong',
            'monthYearDateLong',
            'identifier',
            'anyExists',
          ],
        },
        fieldName: {
          type: 'string',
        },
        query: {
          $ref: '#/$defs/TableQuery',
        },
        value: {
          $ref: '#/$defs/AnyVariable',
        },
        value2: {
          $ref: '#/$defs/AnyVariable',
        },
        size: {
          $ref: '#/$defs/ItemSize',
        },
        resizeType: {
          type: 'string',
          enum: ['proportionally', 'exact'],
        },
        participantsKey: {
          type: 'string',
        },
        participantImageKey: {
          type: 'string',
        },
        participantNameKeys: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    ImageLayout: {
      type: 'string',
      enum: ['fill', 'fit', 'stretch', 'center'],
    },
    Action: {
      type: 'object',
      properties: {
        options: {
          $ref: '#/$defs/ActionOption',
        },
        enabled: {
          $ref: '#/$defs/BooleanVariable',
        },
        actionType: {
          $ref: '#/$defs/ActionType',
        },
        parameters: {
          type: 'array',
          items: {
            $ref: '#/$defs/ScreenInputParameter',
          },
        },
        collection: {
          $ref: '#/$defs/CollectionVariable',
        },
        record: {
          $ref: '#/$defs/VariableSource',
        },
        recordModifications: {
          type: 'array',
          items: {
            $ref: '#/$defs/RecordModification',
          },
        },
        actionArguments: {
          type: 'array',
          items: {
            $ref: '#/$defs/ActionArgument',
          },
        },
        componentName: {
          type: 'string',
        },
        variable: {
          $ref: '#/$defs/AnyVariable',
        },
        valueTarget: {
          $ref: '#/$defs/VariableSource',
        },
        transforms: {
          type: 'array',
          items: {
            $ref: '#/$defs/VariableTransform',
          },
        },
        variableContextKey: {
          type: 'string',
        },
        description: {
          $ref: '#/$defs/LocalizedContent',
        },
        subActions: {
          type: 'array',
          items: {
            $ref: '#/$defs/Action',
          },
        },
        fallbackActions: {
          type: 'array',
          items: {
            $ref: '#/$defs/Action',
          },
        },
      },
    },
    ActionType: {
      type: 'string',
      enum: [
        'placeholder',
        'acceptTerms',
        'appleAuthentication',
        'googleAuthentication',
        'anonymousAuthentication',
        'enableNotifications',
        'enableIdfaAndCookieTracking',
        'enableLocationTracking',
        'logout',
        'openEmail',
        'openWeb',
        'openLink',
        'checkoutSelected',
        'restorePurchases',
        'closePurchases',
        'deleteAccount',
        'updateRecord',
        'createRecord',
        'deleteRecord',
        'setValue',
        'getPhoto',
        'getVideo',
        'getAudio',
        'getFile',
        'navigate',
        'navigateBack',
        'show',
        'scrollToStart',
        'subscribe',
        'openDocument',
        'runFlow',
        'createUserWithEmailAndPassword',
        'signInWithEmailAndPassword',
        'sendPasswordResetEmail',
        'signInWithPhoneNumber',
        'reSignInWithPhoneNumber',
        'confirmPhoneNumber',
        'sendSignInLinkToEmail',
        'reSendSignInLinkToEmail',
        'openEmailApp',
        'waitProfile',
        'checkNotificationsEnabled',
        'checkIdfaAndCookieTrackingEnabled',
        'checkLocationTrackingEnabled',
        'checkAccessLevels',
        'check',
        'handleDeeplink',
      ],
    },
    RecordModification: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          enum: ['set'],
        },
        fieldName: {
          type: 'string',
        },
        value: {
          $ref: '#/$defs/AnyVariable',
        },
      },
    },
    ActionArgument: {
      required: ['name', 'value'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        value: {
          $ref: '#/$defs/AnyVariable',
        },
      },
    },
    ScreenInputParameter: {
      type: 'object',
      properties: {
        parameter: {
          type: 'string',
        },
        getter: {
          $ref: '#/$defs/AnyVariable',
        },
      },
    },
    ActionOption: {
      type: 'object',
      properties: {
        email: {
          type: 'string',
        },
        successTarget: {
          type: 'string',
        },
        screen: {
          type: 'string',
        },
        link: {
          $ref: '#/$defs/StringVariable',
        },
      },
    },
    ItemSize: {
      type: 'object',
      properties: {
        width: {
          type: 'string',
        },
        height: {
          type: 'string',
        },
      },
    },
    LocalizedContent: {
      required: ['key', 'locales'],
      type: 'object',
      properties: {
        key: {
          type: 'string',
        },
        locales: {
          type: 'object',
          additionalProperties: {
            type: 'string',
          },
        },
        values: {
          type: 'array',
          items: {
            $ref: '#/$defs/VariableSource',
          },
        },
      },
    },
    TextAlignment: {
      type: 'string',
      enum: ['leading', 'trailing', 'center'],
    },
    TabBar: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        tabs: {
          type: 'array',
          items: {
            $ref: '#/$defs/TabBarItem',
          },
        },
      },
    },
    TabBarItem: {
      required: ['screen'],
      type: 'object',
      properties: {
        screen: {
          type: 'string',
        },
        title: {
          $ref: '#/$defs/LocalizedContent',
        },
        image: {
          $ref: '#/$defs/MediaVariable',
        },
        visible: {
          $ref: '#/$defs/BooleanVariable',
        },
      },
    },
    ScreenParameter: {
      required: ['parameter', 'type'],
      type: 'object',
      properties: {
        parameter: {
          type: 'string',
        },
        inout: {
          type: 'boolean',
        },
        type: {
          $ref: '#/$defs/ValueType',
        },
        accept: {
          $ref: '#/$defs/ValueType',
        },
        collection: {
          type: 'string',
        },
      },
    },
    ScreenComponent: {
      // required: ['componentType', 'id', 'name', 'componentAlignment'],
      required: ['componentType', 'id', 'componentAlignment'],
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        componentType: {
          type: 'string',
          enum: [
            'view',
            'spacer',
            'divider',
            'stack',
            'overlay',
            'carousel',
            'datePicker',
            'button',
            'image',
            'button',
            'text',
            'textInput',
            'video',
            'list',
            'scroll',
            'map',
            'product',
            'themePicker',
            'notificationsSettings',
            'calendar',
            'videoPlayer',
            'slider',
            'toggle',
            'progressIndicator',
          ],
        },
        componentClass: {
          type: 'string',
        },
        componentStyle: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        visible: {
          $ref: '#/$defs/BooleanVariable',
        },
        enabled: {
          $ref: '#/$defs/BooleanVariable',
        },
        alpha: {
          $ref: '#/$defs/StringVariable',
        },
        cornersRadius: {
          $ref: '#/$defs/StringVariable',
        },
        borderColor: {
          $ref: '#/$defs/StringVariable',
        },
        borderWidth: {
          $ref: '#/$defs/StringVariable',
        },
        width: {
          $ref: '#/$defs/StringVariable',
        },
        height: {
          $ref: '#/$defs/StringVariable',
        },
        widthHeightRatio: {
          $ref: '#/$defs/StringVariable',
        },
        margins: {
          $ref: '#/$defs/InsetsConstant',
        },
        backgroundColor: {
          $ref: '#/$defs/StringVariable',
        },
        backgroundPattern: {
          $ref: '#/$defs/MediaConstant',
        },
        backgroundGradient: {
          $ref: '#/$defs/BackgroundGradient',
        },
        actions: {
          type: 'array',
          items: {
            $ref: '#/$defs/Action',
          },
        },
        localVariables: {
          type: 'array',
          items: {
            $ref: '#/$defs/LocalVariable',
          },
        },
        componentAlignment: {
          type: 'string',
          enum: [
            'fill',
            'top',
            'bottom',
            'trailing',
            'leading',
            'topCenter',
            'topTrailing',
            'topLeading',
            'centerTrailing',
            'centerLeading',
            'center',
            'bottomCenter',
            'bottomTrailing',
            'bottomLeading',
            'end',
            'start',
          ],
        },
        subComponents: {
          $ref: '#/$defs/ScreenComponents',
        },
        componentsSpacing: {
          $ref: '#/$defs/StringVariable',
        },
        removeHiddenComponents: {
          $ref: '#/$defs/BooleanVariable',
        },
        componentsDirection: {
          type: 'string',
          enum: ['horizontal', 'vertical', 'all'],
        },
        text: {
          $ref: '#/$defs/LocalizedContent',
        },
        textAlignment: {
          $ref: '#/$defs/TextAlignment',
        },
        textColor: {
          $ref: '#/$defs/StringVariable',
        },
        font: {
          type: 'string',
        },
        numberOfLines: {
          $ref: '#/$defs/StringVariable',
        },
        textType: {
          type: 'string',
          enum: ['plain', 'markdown'],
        },
        showMoreTextButton: {
          $ref: '#/$defs/BooleanVariable',
        },
        textInputType: {
          type: 'string',
          enum: ['general', 'email', 'password', 'number', 'phone'],
        },
        textInputMultiline: {
          $ref: '#/$defs/BooleanVariable',
        },
        placeholderText: {
          $ref: '#/$defs/LocalizedContent',
        },
        placeholderTextColor: {
          $ref: '#/$defs/StringVariable',
        },
        placeholderTextFont: {
          type: 'string',
        },
        contentPadding: {
          $ref: '#/$defs/InsetsVariable',
        },
        image: {
          $ref: '#/$defs/MediaVariable',
        },
        imageColor: {
          $ref: '#/$defs/StringVariable',
        },
        imageLayout: {
          $ref: '#/$defs/ImageLayout',
        },
        imageLocation: {
          type: 'string',
          enum: ['leading', 'trailing', 'leadingAttached', 'trailingAttached', 'top', 'bottom'],
        },
        placeholderImage: {
          $ref: '#/$defs/MediaVariable',
        },
        placeholderImageColor: {
          $ref: '#/$defs/StringVariable',
        },
        placeholderImageLayout: {
          $ref: '#/$defs/ImageLayout',
        },
        date: {
          $ref: '#/$defs/StringVariable',
        },
        minimumDate: {
          $ref: '#/$defs/StringVariable',
        },
        maximumDate: {
          $ref: '#/$defs/StringVariable',
        },
        dateFormat: {
          $ref: '#/$defs/DateTimeFormat',
        },
        displayDate: {
          $ref: '#/$defs/StringVariable',
        },
        video: {
          $ref: '#/$defs/MediaVariable',
        },
        autoplay: {
          $ref: '#/$defs/BooleanVariable',
        },
        loop: {
          $ref: '#/$defs/BooleanVariable',
        },
        duration: {
          type: 'number',
        },
        reversed: {
          $ref: '#/$defs/BooleanVariable',
        },
        showScrollBar: {
          $ref: '#/$defs/BooleanVariable',
        },
        listItemContextKey: {
          type: 'string',
        },
        listItems: {
          $ref: '#/$defs/StringVariable',
        },
        listItemCoordinate: {
          $ref: '#/$defs/CoordinateVariable',
        },
        mapStyle: {
          type: 'string',
          enum: ['standard', 'hybrid', 'satellite'],
        },
        showUserLocation: {
          $ref: '#/$defs/BooleanVariable',
        },
        coordinate: {
          $ref: '#/$defs/CoordinateVariable',
        },
        zoom: {
          $ref: '#/$defs/StringVariable',
        },
        product: {
          type: 'string',
        },
        selected: {
          $ref: '#/$defs/BooleanVariable',
        },
        showDiscount: {
          $ref: '#/$defs/BooleanVariable',
        },
        calendarStyle: {
          $ref: '#/$defs/CalendarStyleVariable',
        },
        showHeader: {
          $ref: '#/$defs/BooleanVariable',
        },
        headerFont: {
          type: 'string',
        },
        headerColor: {
          $ref: '#/$defs/StringVariable',
        },
        showWeekdays: {
          $ref: '#/$defs/BooleanVariable',
        },
        listItemDate: {
          $ref: '#/$defs/StringVariable',
        },
        value: {
          $ref: '#/$defs/StringVariable',
        },
        minimumValue: {
          $ref: '#/$defs/StringVariable',
        },
        maximumValue: {
          $ref: '#/$defs/StringVariable',
        },
        primaryColor: {
          $ref: '#/$defs/StringVariable',
        },
        secondaryColor: {
          $ref: '#/$defs/StringVariable',
        },
        discrete: {
          $ref: '#/$defs/BooleanVariable',
        },
      },
    },
    ScreenComponents: {
      type: 'array',
      // uniqueItemProperties: ['id', 'name'],
      items: {
        $ref: '#/$defs/ScreenComponent',
      },
    },
    Module: {
      type: 'string',
      enum: [
        'launchScreen',
        'onboarding',
        'beforeJoin',
        'authentication',
        'emailAuthentication',
        'phoneAuthentication',
        'permissions',
        'home',
        'profile',
        'about',
        'settings',
        'video',
        'paywall',
        'others',
      ],
    },
    EditingMode: {
      type: 'string',
      enum: ['editable', 'nonEditable', 'systemComponent'],
    },
    Screen: {
      required: ['id', 'module', 'path', 'screenName', 'components'],
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        screenName: {
          type: 'string',
        },
        path: {
          type: 'string',
        },
        module: {
          $ref: '#/$defs/Module',
        },
        enabled: {
          type: 'boolean',
        },
        components: {
          $ref: '#/$defs/ScreenComponents',
        },
        topBar: {
          $ref: '#/$defs/ScreenTopBar',
        },
        onScreenVisitActions: {
          type: 'array',
          items: {
            $ref: '#/$defs/Action',
          },
        },
        statusBarStyle: {
          type: 'string',
          enum: ['default', 'inverted', 'light', 'dark', 'hidden'],
        },
        backgroundColor: {
          $ref: '#/$defs/StringVariable',
        },
        backgroundPattern: {
          $ref: '#/$defs/MediaConstant',
        },
        backgroundGradient: {
          $ref: '#/$defs/BackgroundGradient',
        },
        showTopBar: {
          $ref: '#/$defs/BooleanVariable',
        },
        showBackButton: {
          $ref: '#/$defs/BooleanVariable',
        },
        screenTitle: {
          $ref: '#/$defs/LocalizedContent',
        },
        tabBar: {
          type: 'string',
        },
        accessLevels: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        inputParameters: {
          type: 'array',
          items: {
            $ref: '#/$defs/ScreenParameter',
          },
        },
        localVariables: {
          type: 'array',
          items: {
            $ref: '#/$defs/LocalVariable',
          },
        },
      },
    },
    Screens: {
      type: 'array',
      uniqueItemProperties: ['id', 'screenName'],
      items: { $ref: '#/$defs/Screen' },
    },
    ScreenTopBar: {
      type: 'object',
      properties: {
        headlineComponents: {
          $ref: '#/$defs/ScreenComponents',
        },
        leadingComponents: {
          $ref: '#/$defs/ScreenComponents',
        },
        trailingComponents: {
          $ref: '#/$defs/ScreenComponents',
        },
      },
    },
    DateTimeFormat: {
      type: 'object',
      properties: {
        format: {
          type: 'string',
          enum: ['short', 'medium', 'full'],
        },
        customFormat: {
          type: 'string',
        },
      },
    },
    TableQuery: {
      type: 'object',
      properties: {
        filters: {
          type: 'array',
          items: {
            $ref: '#/$defs/TableQueryFilter',
          },
        },
        search: {
          $ref: '#/$defs/AnyVariable',
        },
        orderedBy: {
          type: 'string',
        },
        order: {
          type: 'string',
          enum: ['ascending', 'descending'],
        },
      },
    },
    TableQueryFilter: {
      required: ['field', 'operator', 'value'],
      type: 'object',
      properties: {
        field: {
          type: 'string',
        },
        operator: {
          type: 'string',
          enum: ['equal', 'notEqual', 'less', 'lessOrEqual', 'greater', 'greaterOrEqual', 'contains'],
        },
        value: {
          $ref: '#/$defs/AnyVariable',
        },
      },
    },
    IosConfig: {
      required: ['bundleId', 'projectName'],
      type: 'object',
      properties: {
        bundleId: {
          type: 'string',
        },
        teamId: {
          type: 'string',
        },
        appGroups: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        appleKeyId: {
          type: 'string',
        },
        projectName: {
          type: 'string',
        },
      },
    },
    AndroidConfig: {
      required: ['dataProviderApps', 'minSDKVersion', 'packageName', 'projectName'],
      type: 'object',
      properties: {
        packageName: {
          type: 'string',
        },
        projectName: {
          type: 'string',
        },
        minSDKVersion: {
          type: 'string',
        },
        dataProviderApps: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    Product: {
      required: ['id', 'type'],
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        type: {
          type: 'string',
          enum: ['SUBSCRIPTION', 'IN_APP_PURCHASE'],
        },
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        discount: {
          type: 'string',
        },
        duration: {
          type: 'string',
        },
        trialDuration: {
          type: 'string',
        },
        tier: {
          type: 'string',
        },
        accessLevels: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    Storage: {
      required: ['name', 'datasource', 'indexer'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        datasource: {
          required: ['type', 'bucketName'],
          type: 'object',
          properties: {
            type: {
              type: 'string',
            },
            bucketName: {
              type: 'string',
            },
          },
        },
        indexer: {
          type: 'string',
        },
      },
    },
    DataObject: {
      required: ['name', 'properties'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        properties: {
          type: 'array',
          items: {
            $ref: '#/$defs/CollectionProperty',
          },
        },
      },
    },
    Collection: {
      required: ['datasource', 'name', 'properties'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        isSystem: {
          type: 'boolean',
        },
        helpText: {
          type: 'string',
        },
        titleField: {
          type: 'string',
        },
        previewScreen: {
          type: 'string',
        },
        datasource: {
          $ref: '#/$defs/CollectionDatasource',
        },
        properties: {
          type: 'array',
          items: {
            $ref: '#/$defs/CollectionProperty',
          },
        },
      },
    },
    CollectionProperty: {
      required: ['name', 'type', 'position'],
      type: 'object',
      properties: {
        isHidden: {
          type: 'boolean',
        },
        isRequired: {
          type: 'boolean',
        },
        name: {
          type: 'string',
        },
        type: {
          $ref: '#/$defs/ValueType',
        },
        accept: {
          $ref: '#/$defs/ValueType',
        },
        collection: {
          type: 'string',
        },
        dataObject: {
          type: 'string',
        },
        position: {
          type: 'number',
        },
        isSystem: {
          type: 'boolean',
        },
        helpText: {
          type: 'string',
        },
        acceptValues: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    CollectionDatasource: {
      required: ['collectionName', 'type'],
      type: 'object',
      properties: {
        type: {
          type: 'string',
          enum: ['firestore'],
        },
        databaseName: {
          type: 'string',
        },
        collectionName: {
          type: 'string',
        },
      },
    },
    BackgroundGradient: {
      type: 'object',
      properties: {
        start: {
          type: 'string',
        },
        end: {
          type: 'string',
        },
        direction: {
          type: 'string',
          enum: ['vertical', 'horizontal', 'radial'],
        },
      },
    },
    Resource: {
      required: ['id', 'format', 'bytes', 'width', 'height', 'url', 'resourceType', 'createdAt', 'name'],
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        assetId: {
          type: 'string',
        },
        version: {
          type: 'string',
        },
        format: {
          type: 'string',
        },
        folder: {
          type: 'string',
        },
        bytes: {
          type: 'number',
        },
        width: {
          type: 'number',
        },
        height: {
          type: 'number',
        },
        signature: {
          type: 'string',
        },
        url: {
          type: 'string',
        },
        resourceType: {
          type: 'string',
        },
        createdAt: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
      },
    },
    BrandingFontStyleItem: {
      required: ['fontFamily', 'fontStyle', 'fontWeight', 'fontSize', 'lineHeight', 'letterSpacing'],
      type: 'object',
      properties: {
        fontFamily: {
          type: 'string',
        },
        fontStyle: {
          type: 'string',
        },
        fontWeight: {
          type: 'number',
        },
        fontSize: {
          type: 'number',
        },
        lineHeight: {
          type: 'number',
        },
        letterSpacing: {
          type: 'number',
        },
      },
    },
    Flow: {
      required: ['name', 'sourceCode', 'input', 'output'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        sourceCode: {
          type: 'string',
        },
        input: {
          $ref: '#/$defs/DataObject',
        },
        output: {
          $ref: '#/$defs/DataObject',
        },
      },
    },
    Prompt: {
      required: ['name', 'sourceCode', 'input', 'output'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        sourceCode: {
          type: 'string',
        },
        input: {
          $ref: '#/$defs/DataObject',
        },
        output: {
          $ref: '#/$defs/DataObject',
        },
      },
    },
    Indexer: {
      required: ['name', 'sourceCode', 'input', 'output'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        sourceCode: {
          type: 'string',
        },
        input: {
          $ref: '#/$defs/DataObject',
        },
        output: {
          $ref: '#/$defs/DataObject',
        },
      },
    },
    Retriever: {
      required: ['name', 'sourceCode', 'input', 'output'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        sourceCode: {
          type: 'string',
        },
        input: {
          $ref: '#/$defs/DataObject',
        },
        output: {
          $ref: '#/$defs/DataObject',
        },
      },
    },
    Embedder: {
      required: ['name', 'sourceCode', 'input', 'output'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        sourceCode: {
          type: 'string',
        },
        input: {
          $ref: '#/$defs/DataObject',
        },
        output: {
          $ref: '#/$defs/DataObject',
        },
      },
    },
  },
  properties: {
    codeplatform: {
      required: ['syntaxVersion', 'template', 'templateName'],
      type: 'object',
      properties: {
        syntaxVersion: {
          type: 'string',
        },
        template: {
          type: 'string',
        },
        templateName: {
          type: 'string',
        },
      },
    },
    ios: { $ref: '#/$defs/IosConfig' },
    android: { $ref: '#/$defs/AndroidConfig' },
    apiUrl: {
      type: 'string',
    },
    products: {
      type: 'array',
      items: {
        $ref: '#/$defs/Product',
      },
    },
    logic: {
      required: ['flows', 'prompts', 'indexers', 'retrievers', 'embedders'],
      type: 'object',
      properties: {
        flows: {
          type: 'array',
          items: {
            $ref: '#/$defs/Flow',
          },
        },
        prompts: {
          type: 'array',
          items: {
            $ref: '#/$defs/Prompt',
          },
        },
        indexers: {
          type: 'array',
          items: {
            $ref: '#/$defs/Indexer',
          },
        },
        retrievers: {
          type: 'array',
          items: {
            $ref: '#/$defs/Retriever',
          },
        },
        embedders: {
          type: 'array',
          items: {
            $ref: '#/$defs/Embedder',
          },
        },
      },
    },
    data: {
      required: ['storages', 'dataObjects', 'collections', 'globalVariables'],
      type: 'object',
      properties: {
        storages: {
          type: 'array',
          items: {
            $ref: '#/$defs/Storage',
          },
        },
        dataObjects: {
          type: 'array',
          items: {
            $ref: '#/$defs/DataObject',
          },
        },
        collections: {
          type: 'array',
          items: {
            $ref: '#/$defs/Collection',
          },
        },
        globalVariables: {
          type: 'array',
          items: {
            $ref: '#/$defs/GlobalVariable',
          },
        },
      },
    },
    resources: {
      type: 'array',
      items: {
        $ref: '#/$defs/Resource',
      },
    },
    localization: {
      required: ['languages', 'primaryLanguage'],
      type: 'object',
      properties: {
        languages: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        primaryLanguage: {
          type: 'string',
        },
      },
    },
    branding: {
      required: ['appName', 'icons', 'typography', 'colorStyles'],
      type: 'object',
      properties: {
        appName: {
          $ref: '#/$defs/LocalizedContent',
        },
        icons: {
          required: ['iosIcon', 'androidIcon'],
          type: 'object',
          properties: {
            iosIcon: {
              type: 'string',
            },
            androidIcon: {
              type: 'string',
            },
          },
        },
        typography: {
          required: ['iosDefaultFontFamily', 'androidDefaultFontFamily', 'customFontFamilies', 'fontStyles'],
          type: 'object',
          properties: {
            iosDefaultFontFamily: {
              type: 'string',
            },
            androidDefaultFontFamily: {
              type: 'string',
            },
            customFontFamilies: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
            fontStyles: {
              type: 'array',
              items: {
                required: ['styleName', 'categoryName', 'ios', 'android'],
                type: 'object',
                properties: {
                  styleName: {
                    type: 'string',
                  },
                  categoryName: {
                    type: 'string',
                  },
                  ios: {
                    $ref: '#/$defs/BrandingFontStyleItem',
                  },
                  android: {
                    $ref: '#/$defs/BrandingFontStyleItem',
                  },
                },
              },
            },
          },
        },
        colorStyles: {
          required: [
            'transparent',
            'semiTransparentBackground',
            'white',
            'black',
            'primary',
            'primaryContainer',
            'primaryVariant',
            'secondary',
            'secondaryContainer',
            'secondaryVariant',
            'tertiary',
            'onTertiary',
            'tertiaryContainer',
            'onTertiaryContainer',
            'background',
            'surface',
            'error',
            'onPrimary',
            'onPrimaryContainer',
            'onSecondary',
            'onSecondaryContainer',
            'onBackground',
            'onSurface',
            'onError',
            'errorContainer',
            'onErrorContainer',
            'outline',
            'surfaceVariant',
            'onSurfaceVariant',
            'shadow',
            'inverseSurface',
            'inverseOnSurface',
            'inversePrimary',
            'neutral',
          ],
          type: 'object',
          properties: {
            transparent: { $ref: '#/$defs/Color' },
            semiTransparentBackground: { $ref: '#/$defs/Color' },
            white: { $ref: '#/$defs/Color' },
            black: { $ref: '#/$defs/Color' },
            primary: { $ref: '#/$defs/Color' },
            primaryContainer: { $ref: '#/$defs/Color' },
            primaryVariant: { $ref: '#/$defs/Color' },
            secondary: { $ref: '#/$defs/Color' },
            secondaryContainer: { $ref: '#/$defs/Color' },
            secondaryVariant: { $ref: '#/$defs/Color' },
            tertiary: { $ref: '#/$defs/Color' },
            onTertiary: { $ref: '#/$defs/Color' },
            tertiaryContainer: { $ref: '#/$defs/Color' },
            onTertiaryContainer: { $ref: '#/$defs/Color' },
            background: { $ref: '#/$defs/Color' },
            surface: { $ref: '#/$defs/Color' },
            error: { $ref: '#/$defs/Color' },
            onPrimary: { $ref: '#/$defs/Color' },
            onPrimaryContainer: { $ref: '#/$defs/Color' },
            onSecondary: { $ref: '#/$defs/Color' },
            onSecondaryContainer: { $ref: '#/$defs/Color' },
            onBackground: { $ref: '#/$defs/Color' },
            onSurface: { $ref: '#/$defs/Color' },
            onError: { $ref: '#/$defs/Color' },
            errorContainer: { $ref: '#/$defs/Color' },
            onErrorContainer: { $ref: '#/$defs/Color' },
            outline: { $ref: '#/$defs/Color' },
            surfaceVariant: { $ref: '#/$defs/Color' },
            onSurfaceVariant: { $ref: '#/$defs/Color' },
            shadow: { $ref: '#/$defs/Color' },
            inverseSurface: { $ref: '#/$defs/Color' },
            inverseOnSurface: { $ref: '#/$defs/Color' },
            inversePrimary: { $ref: '#/$defs/Color' },
            neutral: { $ref: '#/$defs/Color' },
          },
        },
      },
    },
    tabBars: {
      type: 'array',
      items: {
        $ref: '#/$defs/TabBar',
      },
    },
    screens: {
      $ref: '#/$defs/Screens',
    },
  },
  required: [
    'codeplatform',
    'ios',
    'android',
    'apiUrl',
    'products',
    'logic',
    'data',
    'resources',
    'localization',
    'branding',
    'tabBars',
    'screens',
  ],
}
