import { Box } from '@mui/material'
import React from 'react'

import { Section } from 'pages/Studio/components/ComponentsToAdd/Section'
import { sections } from 'pages/Studio/components/ComponentsToAdd/constants'
import { includesSearch } from 'utils'

interface Props {
  search: string
}

export const AddComponents: React.FC<Props> = ({ search }) => (
  <Box p="0 8px 8px">
    {sections.map(el => (
      <Section key={el.name} title={el.name} components={el.components.filter(el => includesSearch(el.name, search))} />
    ))}
  </Box>
)
