import React from 'react'
import { ISvg, Svg } from '../Svg'

const Actions: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5555 3.5581C11.5554 3.43235 11.5132 3.31028 11.4355 3.21169C11.3578 3.11309 11.2493 3.04375 11.1275 3.01489C11.0056 2.98604 10.8777 2.99936 10.7644 3.05271C10.651 3.10605 10.559 3.19629 10.5031 3.3088L6.05941 12.2391C6.0167 12.3241 5.99642 12.4188 6.00052 12.5139C6.00462 12.6091 6.03295 12.7016 6.08282 12.7827C6.13269 12.8637 6.20241 12.9305 6.28532 12.9767C6.36823 13.0229 6.46155 13.0469 6.55636 13.0465H10.4445V18.4419C10.4446 18.5676 10.4868 18.6897 10.5645 18.7883C10.6422 18.8869 10.7507 18.9563 10.8725 18.9851C10.9944 19.014 11.1223 19.0006 11.2356 18.9473C11.349 18.8939 11.441 18.8037 11.4969 18.6912L15.9406 9.76092C15.9833 9.67588 16.0036 9.58124 15.9995 9.48606C15.9954 9.39089 15.967 9.29836 15.9172 9.21734C15.8673 9.13632 15.7976 9.06951 15.7147 9.02331C15.6318 8.97711 15.5384 8.95307 15.4436 8.95348H11.5555V3.5581Z"
      fill="#F2F2F3"
    />
  </Svg>
)
export default Actions
