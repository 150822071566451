import React from 'react'
import { ISvg, Svg } from '../Svg'

const Backend: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M17.1552 4.89494C16.9958 4.62477 16.7688 4.40033 16.4964 4.24349C16.224 4.08666 15.9154 4.00277 15.6007 4H8.39925C8.0846 4.00277 7.77603 4.08666 7.50361 4.24349C7.23119 4.40033 7.00422 4.62477 6.8448 4.89494L3.24405 11.1051C3.08417 11.3808 3 11.6937 3 12.0121C3 12.3305 3.08417 12.6434 3.24405 12.9191L6.8448 19.1051C7.00422 19.3752 7.23119 19.5997 7.50361 19.7565C7.77603 19.9133 8.0846 19.9972 8.39925 20H15.6007C15.9154 19.9972 16.224 19.9133 16.4964 19.7565C16.7688 19.5997 16.9958 19.3752 17.1552 19.1051L20.7559 12.8949C20.9158 12.6192 21 12.3063 21 11.9879C21 11.6695 20.9158 11.3566 20.7559 11.0809L17.1552 4.89494ZM15.036 12C15.036 12.598 14.858 13.1825 14.5244 13.6797C14.1908 14.1769 13.7166 14.5645 13.1618 14.7933C12.6071 15.0221 11.9966 15.082 11.4077 14.9653C10.8188 14.8487 10.2778 14.5607 9.85319 14.1379C9.4286 13.7151 9.13944 13.1763 9.02229 12.5898C8.90515 12.0034 8.96527 11.3954 9.19506 10.843C9.42485 10.2905 9.81399 9.81833 10.3133 9.48611C10.8125 9.15389 11.3995 8.97657 12 8.97657C12.8052 8.97657 13.5774 9.29511 14.1468 9.86211C14.7162 10.4291 15.036 11.1981 15.036 12Z"
      fill="#212133"
    />
  </Svg>
)

export default Backend
