import styled from 'styled-components'

export const Paragraph = styled.p<{ mt: string }>`
  margin-top: ${props => props.mt};
  color: ${props => props.theme.colors.grey1};
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  & b {
    font-weight: 700;
  }
`
