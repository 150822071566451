import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 30px;
  max-width: 1280px;
  width: 100%;
`

export const Name = styled.h1`
  margin-bottom: 10px;
  color: ${props => props.theme.colors.darkText};
  font-weight: ${props => props.theme.weights.bold};
  font-size: 18px;
  line-height: 27px;
`

export const DropDowns = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 33px;
  & > span {
    margin-right: 10px;
  }
`

export const File = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 4px;
  overflow: hidden;
`

export const FileHead = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 14px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  background-color: ${props => props.theme.colors.primaryBackground};
  & div {
    display: flex;
    align-items: center;
    & svg {
      margin-right: 12px;
    }
  }
  & span {
    cursor: pointer;
    font-size: 20px;
    font-weight: ${props => props.theme.weights.bold};
    position: absolute;
    right: 15px;
    top: 5px;
  }
`

export const FileCrumbs = styled.div`
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors.grey1};
    font-size: 14px;
    margin-right: 4px;
    &:last-child {
      pointer-events: none;
      color: ${props => props.theme.colors.darkText};
      font-weight: ${props => props.theme.weights.bold};
    }
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 390px);
  display: flex;
  justify-content: center;
  padding: 40px 20px;
`

export const CodeWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 390px);
`
