import { memo, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Icon, Name } from 'components'
import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'
import { usePicker } from '../context'
import { imageInput, imageInputWrapper } from '../style'

export const ImageInput = memo(() => {
  const { id } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { onChange } = usePicker()

  const onImageChange = event => {
    const image = event.target.files[0]
    if (id && image) {
      const formData = new FormData()
      formData.append('resource', image)
      startLoader()
      ProjectsService.uploadResource(id, formData)
        .then(res => onChange(`url("${res.data.url}") center center / cover`))
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }
  }

  return (
    <div style={imageInputWrapper}>
      <Icon name={Name.ADDITIONAL_UPLOAD_IMAGE} />
      <span
        style={{
          fontSize: 12,
          lineHeight: '150%',
          color: '#27334D',
          maxWidth: 136,
          margin: '10px 0 9px',
        }}
      >
        Drag and drop an image, or <u>Browse</u>
      </span>
      <span
        style={{
          fontSize: 10,
          lineHeight: '150%',
          color: '#93999F',
          maxWidth: 175,
        }}
      >
        Minimum 1600px width recommended. Max 10MB
      </span>
      <input onChange={onImageChange} type="file" accept="image/*" style={imageInput} />
    </div>
  )
})
