import { Box } from '@mui/material'
import React from 'react'

import { AnyVariableInput, Icon, Name } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { AnyVariable, LocalVariable, Screen, StringVariable, ValueType, VariableSource } from 'utils'

export const isSearchNotValid = (search?: StringVariable) => !!search && !Object.keys(search).length

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Search: React.FC<Props> = ({ currentVariable, setCurrentVariable, screenConfig, allLocalVariables }) => {
  const { query } = currentVariable

  const onAdd = () =>
    setCurrentVariable(currentVariable =>
      currentVariable ? { ...currentVariable, query: { ...currentVariable.query, search: {} } } : null
    )

  const onRemove = () =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      if (copy?.query) {
        delete copy.query.search
      }
      if (copy?.query && !copy.query.filters && !copy.query.search && !copy.query.order && !copy.query.orderedBy) {
        delete copy.query
      }
      return copy
    })

  const onChange = (value?: AnyVariable) =>
    setCurrentVariable(currentVariable =>
      currentVariable ? { ...currentVariable, query: { ...currentVariable.query, search: value || {} } } : null
    )

  return (
    <SubWrapper
      small
      noBorderBottom
      title="Search"
      onAdd={!query?.search ? onAdd : undefined}
      canHide={!!query?.search}
      childrenFor=""
    >
      {query?.search && (
        <Box display="flex" flexDirection="column" gap="12px">
          <RightSidebarItem small title="">
            <SubWrapper
              small
              title="SEARCH"
              childrenFor=""
              customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={onRemove} />}
            >
              <AnyVariableInput
                valueType={ValueType.string}
                anyVariableValue={query.search}
                onChange={onChange}
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            </SubWrapper>
          </RightSidebarItem>
        </Box>
      )}
    </SubWrapper>
  )
}
