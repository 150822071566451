export const overlayHandler = (overlay: HTMLElement, compression: number) => {
  const { width, height } = overlay.getBoundingClientRect()
  if (!(width && height)) {
    let newWidth = 0
    let newHeight = 0
    overlay.childNodes.forEach(el => {
      const child = el as HTMLElement
      const position = child.style.position
      const display = child.style.display
      child.style.position = 'static'
      child.style.display = 'flex'
      const { marginLeft, marginRight, marginTop, marginBottom } = window.getComputedStyle(child)
      const { width, height } = child.getBoundingClientRect()
      const childWidth = Math.ceil(parseFloat(marginLeft) + width / compression + parseFloat(marginRight))
      const childHeight = Math.ceil(parseFloat(marginTop) + height / compression + parseFloat(marginBottom))
      if (childWidth > newWidth) {
        newWidth = childWidth
      }
      if (childHeight > newHeight) {
        newHeight = childHeight
      }
      child.style.position = position
      child.style.display = display
    })
    if (newWidth > width) {
      overlay.style.width = `${newWidth}px`
    }
    if (newHeight > height) {
      overlay.style.height = `${newHeight}px`
    }
  }
}
