import React from 'react'
import { ISvg, Svg } from '../Svg'

const KeyValueMap: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M5.41176 4.5V6M5.41176 10V11.5M7.29412 8H8.70588M4.47059 4H6.35294C6.61284 4 6.82353 3.77614 6.82353 3.5V1.5C6.82353 1.22386 6.61284 1 6.35294 1H4.47059C4.21069 1 4 1.22386 4 1.5V3.5C4 3.77614 4.21069 4 4.47059 4ZM4.47059 9.5H6.35294C6.61284 9.5 6.82353 9.27614 6.82353 9V7C6.82353 6.72386 6.61284 6.5 6.35294 6.5H4.47059C4.21069 6.5 4 6.72386 4 7V9C4 9.27614 4.21069 9.5 4.47059 9.5ZM9.64706 9.5H11.5294C11.7893 9.5 12 9.27614 12 9V7C12 6.72386 11.7893 6.5 11.5294 6.5H9.64706C9.38716 6.5 9.17647 6.72386 9.17647 7V9C9.17647 9.27614 9.38716 9.5 9.64706 9.5ZM4.47059 15H6.35294C6.61284 15 6.82353 14.7761 6.82353 14.5V12.5C6.82353 12.2239 6.61284 12 6.35294 12H4.47059C4.21069 12 4 12.2239 4 12.5V14.5C4 14.7761 4.21069 15 4.47059 15Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
  </Svg>
)

export default KeyValueMap
