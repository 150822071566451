import React from 'react'
import { ISvg, Svg } from '../Svg'

const RichText: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11 2.00024L15 2.00024M11 5.00024L15 5.00024M11 8.00024L15 8.00024M1 11.0002L15 11.0002M1 14.0002L15 14.0002M2 8.00024L4.54043 2.07257C4.71359 1.66852 5.28641 1.66852 5.45957 2.07257L8 8.00024M3 6.00024L7 6.00024"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Svg>
)

export default RichText
