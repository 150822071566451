import React from 'react'
import { ISvg, Svg } from '../Svg'

const Fab: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <circle cx="16" cy="16" r="9.25" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M16 20L16 12" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M20 16L12 16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Fab
