import styled from 'styled-components'

export const Preview = styled.div`
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  margin: 15px -10px -20px;
  padding: 0 10px 20px;
  max-height: 300px;
  overflow: auto;
  & > h3 {
    padding: 10px 0;
    color: ${props => props.theme.colors.grey1};
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  }
  & > div {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 0 10px;
    background: ${props => props.theme.colors.settingField};
    & > div {
      border-bottom: 1px solid ${props => props.theme.colors.settingField};
      &:last-child {
        border-bottom: unset;
      }
    }
  }
`
