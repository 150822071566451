import React from 'react'

import { Container, FieldWrapper, SubLabel } from '../SettingField.styled'
import * as styled from './SettingAlignment.styled'

import { Icon, Name, Rotation } from 'components'
import { ImageLocation } from 'utils'

interface Props {
  subLabel: string
  value?: string
  onChange: (val: ImageLocation) => void
  disabled?: boolean
}

export const SettingImageAlignment: React.FC<Props> = ({ subLabel, value, onChange, disabled }) => (
  <Container disabled={disabled}>
    <FieldWrapper>
      <SubLabel>{subLabel}</SubLabel>
      <styled.Icons>
        <styled.IconWrapper active={value === ImageLocation.leading}>
          <Icon name={Name.RIGHT_SIDEBAR_IMAGE_LEFT} onClick={() => onChange(ImageLocation.leading)} />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === ImageLocation.trailing}>
          <Icon
            name={Name.RIGHT_SIDEBAR_IMAGE_LEFT}
            rotation={Rotation.LEFT}
            onClick={() => onChange(ImageLocation.trailing)}
          />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === ImageLocation.leadingAttached}>
          <Icon name={Name.RIGHT_SIDEBAR_IMAGE_T_LEFT} onClick={() => onChange(ImageLocation.leadingAttached)} />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === ImageLocation.trailingAttached}>
          <Icon name={Name.RIGHT_SIDEBAR_IMAGE_T_RIGHT} onClick={() => onChange(ImageLocation.trailingAttached)} />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === ImageLocation.top}>
          <Icon
            name={Name.RIGHT_SIDEBAR_IMAGE_LEFT}
            rotation={Rotation.DOWN}
            onClick={() => onChange(ImageLocation.top)}
          />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === ImageLocation.bottom}>
          <Icon
            name={Name.RIGHT_SIDEBAR_IMAGE_LEFT}
            rotation={Rotation.UP}
            onClick={() => onChange(ImageLocation.bottom)}
          />
        </styled.IconWrapper>
      </styled.Icons>
    </FieldWrapper>
  </Container>
)
