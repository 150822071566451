import React from 'react'
import { ISvg, Svg } from '../Svg'

const Text: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <path d="M11 7V15" stroke="#F2F2F3" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 7L15 7" stroke="#F2F2F3" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Text
