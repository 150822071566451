import React from 'react'
import { ISvg, Svg } from '../Svg'

const Audio: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M14 5.72406C15.202 7.28659 15.3717 9.36621 14.2284 11.2065M10.0924 2L6.12508 5.4884H2V10.5111L6.12508 10.5099L10.0924 14V2Z"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Audio
