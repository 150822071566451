import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const ScreenshotsGenerator: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.0171 17H18.5217C19.3892 17 19.823 17 20.1543 16.8297C20.4457 16.6799 20.6827 16.4409 20.8312 16.1469C21 15.8126 21 15.3751 21 14.5V10.5C21 9.62492 21 9.18738 20.8312 8.85314C20.6827 8.55914 20.4457 8.3201 20.1543 8.1703C19.823 8 19.3892 8 18.5217 8H18C18.0261 8.39704 18.0261 8.86538 18.0261 9.38891V15.9861C18.0261 16.353 18.0261 16.6928 18.0171 17Z"
      fill="#476CEC"
    />
    <path
      d="M13 19H13.5066C14.7294 19 15.3408 19 15.8079 18.7527C16.2187 18.5353 16.5527 18.1882 16.762 17.7614C17 17.2761 17 16.6409 17 15.3704V8.62963C17 7.35914 17 6.7239 16.762 6.23863C16.5527 5.81178 16.2187 5.46474 15.8079 5.24725C15.3408 5 14.7294 5 13.5066 5H13C13.0104 5.09214 13.0189 5.18391 13.0261 5.27517C13.07 5.83353 13.07 6.50727 13.0699 7.26853V16.7315C13.07 17.4927 13.07 18.1665 13.0261 18.7248C13.0189 18.8161 13.0104 18.9079 13 19Z"
      fill="#476CEC"
    />
    <rect x="2" y="3" width="10" height="18" rx="2.5" fill="#476CEC" />
  </Svg>
)
