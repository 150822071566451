import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './LeftPanel.styled'

import { Button, ButtonSize, ProjectSetup, Text, Type } from 'components'
import { useProject } from 'hooks'
import { AppName } from '../AppName'

interface Props {
  id: string
}

export const LeftPanelProjectSetup: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate()
  const {
    project: { name, logoUrl },
  } = useProject(id)

  return (
    <>
      <AppName name={name} logoUrl={logoUrl} />
      <Box p="25px" m="110px 0 95px" height="calc(100% - 205px)" overflow="auto">
        <Text type={Type.H1} fontWeight={700}>
          Preparing you project
        </Text>
        <ProjectSetup id={id} />
      </Box>
      <styled.ExploreProjectWrapper>
        <Button size={ButtonSize.DEFAULT} onClick={() => navigate(`/projects/${id}`)}>
          Explore project
        </Button>
      </styled.ExploreProjectWrapper>
    </>
  )
}
