import styled from 'styled-components'

export const Components = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
  overflow: auto;
  & > div > svg {
    width: 190px !important;
    min-width: 190px;
  }
`
