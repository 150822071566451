import React, { useContext } from 'react'

import {
  ArrayConstantInput,
  BooleanInput,
  BrandingColorInput,
  CoordinateInput,
  DateInput,
  ImageInput,
  SourceInput,
  StringInput,
} from 'components'
import { ProjectContext } from 'context'
import { SettingNumberField } from 'partials/RightSidebar/components'
import { AnyVariable, LocalVariable, Screen, ValueType } from 'utils'

export const getValueType = (variable?: AnyVariable) =>
  variable?.urlConstant
    ? ValueType.url
    : variable?.accessLevelConstant
    ? ValueType.accessLevel
    : variable?.booleanConstant
    ? ValueType.boolean
    : variable?.dateConstant
    ? ValueType.date
    : variable?.dateTimeConstant
    ? ValueType.dateTime
    : variable?.textConstant
    ? ValueType.string
    : variable?.videoConstant
    ? ValueType.video
    : variable?.numberConstant
    ? ValueType.number
    : variable?.imageConstant
    ? ValueType.image
    : variable?.colorConstant
    ? ValueType.color
    : variable?.insetsConstant
    ? ValueType.insets
    : variable?.coordinateConstant
    ? ValueType.coordinate
    : variable?.calendarStyleConstant
    ? ValueType.calendarStyle
    : variable?.arrayConstant
    ? ValueType.array
    : variable?.source
    ? ValueType.any
    : undefined

interface Props {
  valueType?: ValueType
  anyVariableValue?: AnyVariable
  onChange: (val?: AnyVariable) => void
  disabled?: boolean
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
}

export const AnyVariableInput: React.FC<Props> = ({
  valueType,
  anyVariableValue,
  onChange,
  disabled,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)

  return valueType === ValueType.boolean ? (
    <BooleanInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.booleanConstant}
      onChange={value => onChange(value !== undefined ? { booleanConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.number ? (
    <SettingNumberField
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.numberConstant}
      onChange={value => onChange(value ? { numberConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.date ? (
    <DateInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.dateConstant}
      onChange={value => onChange(value ? { dateConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.dateTime ? (
    <DateInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.dateTimeConstant}
      onChange={value => onChange(value ? { dateTimeConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.color ? (
    <BrandingColorInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.colorConstant}
      onChange={value => onChange(value ? { colorConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.coordinate ? (
    <CoordinateInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.coordinateConstant}
      onChange={value => onChange(value ? { coordinateConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.image ? (
    <ImageInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.imageConstant?.resourceId}
      onChange={value => onChange(value ? { imageConstant: { resourceId: value } } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.string ? (
    <StringInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.textConstant?.locales[language]}
      onChange={value =>
        onChange(
          value
            ? {
                textConstant: {
                  key: anyVariableValue?.textConstant?.key || 'key',
                  locales: { ...anyVariableValue?.textConstant?.locales, [language]: value },
                },
              }
            : undefined
        )
      }
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.url ? (
    <StringInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.urlConstant}
      onChange={value => onChange(value ? { urlConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.accessLevel ? (
    <StringInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.accessLevelConstant}
      onChange={value => onChange(value ? { accessLevelConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.array ? (
    <ArrayConstantInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.arrayConstant}
      onChange={value => onChange(value ? { arrayConstant: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType ? (
    <SourceInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : (
    <></>
  )
}
