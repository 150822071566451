import React from 'react'
import { ISvg, Svg } from '../Svg'

const Search: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M19 19L16.3529 16.3529" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="10" cy="10" r="5.25" stroke="#212133" strokeWidth="1.5" />
  </Svg>
)

export default Search
