import React, { useContext, useEffect } from 'react'

import { TopPanelContext } from 'context'
import { Button, ButtonVariant, Icon, Name } from 'components'

export const TopPanelConversations: React.FC = () => {
  const { setLeftButtons } = useContext(TopPanelContext)

  useEffect(() => {
    setLeftButtons(
      <Button variant={ButtonVariant.TOP_PANEL_ACTION} leftIcon={<Icon name={Name.ADDITIONAL_ADD} />}>
        Add
      </Button>
    )
    return () => setLeftButtons(null)
  }, [])

  return <></>
}
