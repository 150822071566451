import styled from 'styled-components'

export enum FillType {
  STROKE = 'stroke',
  FILL = 'fill',
}

export interface ISvg {
  color?: string
  width?: number
  height?: number
  stroke?: string
  fillType?: FillType
  rotation?: string
}

export const Svg = styled.svg`
  width: ${(props: ISvg) => (props.width ? `${props.width}px` : '16px')};
  height: ${(props: ISvg) => (props.height ? `${props.height}px` : '16px')};
  transform: ${(props: ISvg) => (props.rotation ? `rotate(${props.rotation}deg)` : 'none')};
  transition: transform 0.2s;
  /* ${(props: ISvg) => {
    if (props.fillType === FillType.FILL) return `fill: ${props.color};`
    return `stroke: ${props.color};`
  }} */
`

// Svg.defaultProps = {
//   fillType: FillType.FILL,
//   color: '#000',
// }
