import React from 'react'

import { CustomPicker } from 'components'
import { BackgroundGradient } from 'utils'
import { OptionsContainer } from './components'

interface Props {
  close: () => void
  value?: BackgroundGradient
  onChange: (val: BackgroundGradient) => void
}

export const GradientPicker: React.FC<Props> = ({ close, value, onChange }) => (
  <CustomPicker
    title="Gradient"
    close={close}
    optionsContainer={<OptionsContainer value={value} onChange={onChange} close={close} />}
  />
)
