import React from 'react'
import { ISvg, Svg } from '../Svg'

const Shadow: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C4 5.79086 5.79086 4 8 4H20C22.2091 4 24 5.79086 24 8C26.2091 8 28 9.79086 28 12V24C28 26.2091 26.2091 28 24 28H12C9.79086 28 8 26.2091 8 24C5.79086 24 4 22.2091 4 20V8ZM9 6C7.34315 6 6 7.34315 6 9V19C6 20.6569 7.34315 22 9 22H19C20.6569 22 22 20.6569 22 19V9C22 7.34315 20.6569 6 19 6H9Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Shadow
