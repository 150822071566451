import React from 'react'
import { ISvg, Svg } from '../Svg'

const Text: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M1.64262 12.0834C1.97541 12.0834 2.16475 11.9283 2.28525 11.5684L2.91066 9.85205H6.07787L6.70902 11.574C6.82377 11.9283 7.01311 12.0834 7.35738 12.0834C7.73033 12.0834 8 11.8453 8 11.5186C8 11.4134 7.97705 11.3082 7.91967 11.1532L5.39508 4.55895C5.22295 4.11601 4.94754 3.91669 4.5 3.91669C4.04672 3.91669 3.76557 4.12155 3.59918 4.56448L1.08033 11.1532C1.02295 11.3027 1 11.4245 1 11.5186C1 11.8563 1.2582 12.0834 1.64262 12.0834ZM3.2377 8.83883L4.47131 5.33409H4.50574L5.75082 8.83883H3.2377Z"
      fill="#7F899E"
    />
    <path
      d="M11.4545 12.0775C12.3474 12.0775 13.2208 11.6418 13.6183 10.9531H13.6509V11.477C13.664 11.8538 13.9312 12.0834 14.3222 12.0834C14.7198 12.0834 15.0001 11.842 15.0001 11.4417V7.70354C15.0001 6.46141 13.9377 5.66669 12.2757 5.66669C11.0438 5.66669 10.0075 6.11409 9.64906 6.81462C9.56433 6.95591 9.52522 7.09719 9.52522 7.2267C9.52522 7.54459 9.77941 7.75652 10.1314 7.75652C10.379 7.75652 10.5615 7.6741 10.7049 7.48572C11.096 6.92059 11.5457 6.70277 12.2235 6.70277C13.0839 6.70277 13.5988 7.11485 13.5988 7.80361V8.28045L11.7152 8.38053C10.0923 8.46294 9.16675 9.12816 9.16675 10.2113C9.16675 11.324 10.1118 12.0775 11.4545 12.0775ZM11.839 11.0708C11.0895 11.0708 10.5876 10.7176 10.5876 10.176C10.5876 9.6462 11.0634 9.31065 11.9042 9.25767L13.5988 9.16348V9.65208C13.5988 10.4586 12.8232 11.0708 11.839 11.0708Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Text
