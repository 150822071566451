import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as styled from '../Projects/Projects.styled'
import * as templatesStyled from './Templates.styled'

import { AIModal, Button, ButtonColor, Crumbs, Select, SelectArrowVariant, Text, Type } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { ITemplate } from 'context/types'
import { useParamsFromUrl } from 'hooks'
import { firestore } from 'services/firebase'
import { Template } from './components'

enum TagAttachment {
  ALL = 'All',
  CATEGORIES = 'Categories',
  TECHNOLOGIES = 'Technologies',
  LANGUAGES = 'Languages',
  FEATURES = 'Features',
}

interface ITemplatesSortOptions {
  categories: string[]
  features: string[]
  languages: string[]
  technologies: string[]
}

interface Props {
  isTemplatesPage?: boolean
}

export const TemplateList: React.FC<Props> = ({ isTemplatesPage }) => {
  const themeContext = useContext(ThemeContext)
  const { toast } = useContext(ProgressContext)
  const { workspace } = useContext(WorkspaceContext)
  const [templates, setTemplates] = useState<ITemplate[]>([])
  const [templatesSortOptions, setTemplatesTemplatesSortOptions] = useState<ITemplatesSortOptions>({
    categories: [],
    features: [],
    languages: [],
    technologies: [],
  })
  const [currentTag, setCurrentTag] = useState<{ attachment: TagAttachment; tag: string }>({
    attachment: TagAttachment.ALL,
    tag: TagAttachment.ALL,
  })
  const { conversationId, intent, templateId, workspaceId, setParamsToUrl } = useParamsFromUrl()
  const openAIByDefault = !!(conversationId || (intent && templateId && workspaceId))
  const [openAI, setOpenAI] = useState(openAIByDefault)

  useEffect(() => {
    setOpenAI(openAIByDefault)
  }, [openAIByDefault])

  useEffect(() => {
    const unsubscribe = firestore
      .collection('templates')
      .orderBy('position')
      .onSnapshot({
        next: res => {
          const templates = res.docs.map(el => ({ id: el.id, ...el.data() })) as ITemplate[]
          setTemplates(templates)
          setTemplatesTemplatesSortOptions(templatesSortOptions => {
            const categories = new Set()
            const features = new Set()
            const languages = new Set()
            const technologies = new Set()
            templates.forEach(el => {
              el.categories.forEach(el => categories.add(el))
              el.featuresCollection.items.forEach(el => features.add(el.feature))
              el.languages.forEach(el => languages.add(el))
              el.technologies.forEach(el => technologies.add(el))
            })
            templatesSortOptions.categories = Array.from(categories) as string[]
            templatesSortOptions.features = Array.from(features) as string[]
            templatesSortOptions.languages = Array.from(languages) as string[]
            templatesSortOptions.technologies = Array.from(technologies) as string[]
            return templatesSortOptions
          })
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  const tags = [
    {
      attachment: TagAttachment.CATEGORIES,
      options: templatesSortOptions.categories,
      currentTag: currentTag.attachment === TagAttachment.CATEGORIES ? currentTag.tag : TagAttachment.CATEGORIES,
    },
    {
      attachment: TagAttachment.TECHNOLOGIES,
      options: templatesSortOptions.technologies,
      currentTag: currentTag.attachment === TagAttachment.TECHNOLOGIES ? currentTag.tag : TagAttachment.TECHNOLOGIES,
    },
    {
      attachment: TagAttachment.LANGUAGES,
      options: templatesSortOptions.languages,
      currentTag: currentTag.attachment === TagAttachment.LANGUAGES ? currentTag.tag : TagAttachment.LANGUAGES,
    },
    {
      attachment: TagAttachment.FEATURES,
      options: templatesSortOptions.features,
      currentTag: currentTag.attachment === TagAttachment.FEATURES ? currentTag.tag : TagAttachment.FEATURES,
    },
  ]

  const filterTemplates = templates.filter((template: ITemplate) =>
    currentTag.attachment === TagAttachment.CATEGORIES
      ? template.categories.includes(currentTag.tag)
      : currentTag.attachment === TagAttachment.TECHNOLOGIES
      ? template.technologies.includes(currentTag.tag)
      : currentTag.attachment === TagAttachment.LANGUAGES
      ? template.languages.includes(currentTag.tag)
      : currentTag.attachment === TagAttachment.FEATURES
      ? template.featuresCollection.items.find((feature: any) => feature.feature === currentTag.tag)
      : currentTag.attachment === TagAttachment.ALL
      ? template
      : template
  )

  const [currentCategory, setCurrentCategory] = useState<number>(1)

  useEffect(() => {
    ;(Object.keys(TagAttachment) as Array<keyof typeof TagAttachment>).forEach((key, index) => {
      if (currentTag.attachment === TagAttachment[key]) {
        setCurrentCategory(index + 1)
      }
    })
  }, [currentTag.attachment])

  return workspace ? (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="flex-end">
          <Box>
            {isTemplatesPage ? (
              <Crumbs firstCrumbTitle="Projects" secondCrumbTitle="App Templates" to={`/workspaces/${workspace.id}`} />
            ) : (
              <Text type={Type.TITLE}>Popular Templates</Text>
            )}
            <Text type={Type.SUB_TITLE} color={themeContext.colors.darkBlue_500}>
              Select a template to start with 🚀
            </Text>
            {isTemplatesPage && (
              <templatesStyled.SelectBox activeCategory={currentCategory}>
                <templatesStyled.SelectAll
                  active={currentCategory === 1}
                  onClick={() => setCurrentTag({ attachment: TagAttachment.ALL, tag: TagAttachment.ALL })}
                >
                  All
                </templatesStyled.SelectAll>
                {tags.map(tag => {
                  return (
                    <Select
                      key={tag.attachment}
                      initValue={tag.currentTag}
                      outerValue={!!tag.currentTag}
                      arrowVariant={SelectArrowVariant.TEMPLATES_S20}
                      top="44px"
                      left="15px"
                      width="max-content"
                      optionsWrapperStyle={{ minWidth: '190px' }}
                      options={tag.options.map(option => ({ value: option }))}
                      onClick={(value: string) => setCurrentTag({ attachment: tag.attachment, tag: value })}
                    />
                  )
                })}
              </templatesStyled.SelectBox>
            )}
          </Box>
          {!isTemplatesPage && (
            <Link to={`/workspaces/${workspace.id}/templates`} style={{ textDecoration: 'none' }}>
              <Button color={ButtonColor.SECONDARY} style={{ padding: '9.5px 15px' }}>
                View all
              </Button>
            </Link>
          )}
        </Box>
      </Box>
      <Box width="100%">
        <styled.List isTemplate isNewProject={isTemplatesPage}>
          {!filterTemplates
            ? Array(4)
                .fill(0)
                .map((el, i) => <Template key={el + i} />)
            : filterTemplates.map(el => (
                <Template
                  key={el.id}
                  template={el}
                  setCurrentTemplate={() => setParamsToUrl('create-project', el.id, workspace.id)}
                />
              ))}
        </styled.List>
      </Box>
      <AIModal active={openAI} close={() => setOpenAI(false)} previewMode />
    </>
  ) : null
}
