import { Box } from '@mui/material'
import React from 'react'

import { SelectorPicker } from 'components'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { getVariableName, LocalVariable, Screen, StringVariable, VariableSource } from 'utils'

interface Props {
  selector?: StringVariable
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  collectionName?: string
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Selector: React.FC<Props> = ({
  selector,
  setCurrentVariable,
  collectionName,
  screenConfig,
  allLocalVariables,
}) => (
  <SubWrapper small noBorderBottom childrenFor="">
    <Box display="flex" flexDirection="column" gap="12px">
      <RightSidebarItem small title="">
        <SubWrapper small title="id" childrenFor="">
          <SettingField
            insidePicker
            value={selector?.source ? getVariableName(selector.source) : selector?.constant}
            small
            optionsContainer={
              <SelectorPicker
                selector={selector}
                setCurrentVariable={setCurrentVariable}
                close={() => {}}
                collectionName={collectionName}
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            }
          />
        </SubWrapper>
      </RightSidebarItem>
    </Box>
  </SubWrapper>
)
