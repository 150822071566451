import React, { useContext, useEffect } from 'react'

import { DeviceSettingsContext, ProjectContext } from 'context'
import { rightSidebarId } from 'hooks'
import {
  ComponentType,
  Config,
  Resource,
  Screen,
  ScreenComponent,
  TabBar,
  TabBarItem,
  detectEmptyValue,
  findComponent,
  findComponentParentDataById,
  getAllLocalVariables,
} from 'utils'
import * as settings from './settings'

export const RightSidebar: React.FC = () => {
  const { selected } = useContext(DeviceSettingsContext)
  const { screenId, componentId, innerModeComponentId, subComponentId, appIcon, tabBarId, topBarId, tabScreen } =
    selected
  const {
    state: { screens = [], tabBars = [] },
    setState,
  } = useContext(ProjectContext)

  const onScreenPropertiesChange = (props: { propName: keyof Screen; value: any; resources?: Resource[] }[]) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const screen = stateCopy.screens?.find(el => el.id === screenId)
      if (screen) {
        props.forEach(({ propName, value, resources }) => {
          if (detectEmptyValue(value)) {
            delete screen[propName]
          } else {
            screen[propName] = value as never
          }
          if (resources) {
            stateCopy.resources = resources
          }
        })
      }
      return stateCopy
    })
  }

  const onComponentPropertiesChange =
    (componentId: string) => (props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]) => {
      setState(state => {
        const stateCopy = JSON.parse(JSON.stringify(state)) as Config
        const screen = stateCopy.screens?.find(el => el.id === screenId)
        if (screen && componentId) {
          const component = findComponent(screen, 'id', componentId)
          if (component) {
            props.forEach(({ propName, value, resources }) => {
              if (detectEmptyValue(value)) {
                delete component[propName]
              } else {
                component[propName] = value as never
              }
              if (resources) {
                stateCopy.resources = resources
              }
            })
            return stateCopy
          }
        }
        return state
      })
    }

  const onComponentStyleChange = (componentId: string) => (config: ScreenComponent) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const screen = stateCopy.screens?.find(el => el.id === screenId)
      if (screen && componentId) {
        const componentParentData = findComponentParentDataById(screen, componentId)
        if (componentParentData) {
          const { components } = componentParentData
          const componentIndex = components.findIndex(el => el.id === componentId)
          if (componentIndex !== -1) {
            const { id } = components[componentIndex]
            components.splice(componentIndex, 1, { ...config, id })
            return stateCopy
          }
        }
      }
      return state
    })
  }

  const onTabBarPropertiesChange = (props: { propName: keyof TabBar; value: any; resources?: Resource[] }[]) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const tabBar = stateCopy.tabBars?.find(el => el.id === tabBarId)
      if (tabBar) {
        props.forEach(({ propName, value, resources }) => {
          if (detectEmptyValue(value)) {
            delete tabBar[propName]
          } else {
            tabBar[propName] = value
          }
          if (resources) {
            stateCopy.resources = resources
          }
        })
        return stateCopy
      }
      return state
    })
  }

  const onTabPropertiesChange = (props: { propName: keyof TabBarItem; value: any; resources?: Resource[] }[]) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const tab = stateCopy.tabBars
        ?.find(el => el.tabs?.find(el => el.screen === tabScreen))
        ?.tabs?.find(el => el.screen === tabScreen)
      if (tab) {
        props.forEach(({ propName, value, resources }) => {
          if (detectEmptyValue(value)) {
            delete tab[propName]
          } else {
            tab[propName] = value
          }
          if (resources) {
            stateCopy.resources = resources
          }
        })
        return stateCopy
      }
      return state
    })
  }

  useEffect(() => {
    const rightSidebar = document.getElementById(rightSidebarId)
    if (rightSidebar) {
      rightSidebar.scrollTop = 0
    }
  }, [JSON.stringify(selected)])

  if (screenId) {
    const screenConfig = screens.find(el => el.id === screenId)
    if (screenConfig) {
      const allLocalVariables = getAllLocalVariables(screenConfig, screenConfig)
      const id = subComponentId || componentId
      if (id) {
        const component = findComponent(screenConfig, 'id', id)
        if (component) {
          const allLocalVariables = getAllLocalVariables(screenConfig, component)
          const mbListParent =
            subComponentId && innerModeComponentId ? findComponent(screenConfig, 'id', innerModeComponentId) : undefined
          const listParent = mbListParent?.componentType === ComponentType.list ? mbListParent : undefined
          const props = {
            key: id,
            component,
            onComponentPropertiesChange: onComponentPropertiesChange(id),
            onComponentStyleChange: onComponentStyleChange(id),
            screenConfig,
            allLocalVariables,
            listId: listParent?.id,
          }
          switch (component.componentType) {
            case ComponentType.text:
              return <settings.TextSetting {...props} />
            case ComponentType.image:
              return <settings.ImageSetting {...props} />
            case ComponentType.button:
              return <settings.ButtonSetting {...props} />
            case ComponentType.textInput:
              return <settings.TextInputSetting {...props} />
            case ComponentType.datePicker:
              return <settings.TextInputSetting {...props} />
            case ComponentType.stack:
              return <settings.StackSetting {...props} />
            case ComponentType.scroll:
              return <settings.ScrollSetting {...props} />
            case ComponentType.product:
              return <settings.ProductSetting {...props} />
            case ComponentType.videoPlayer:
              return <settings.CommonSetting {...props} />
            case ComponentType.overlay:
              return <settings.CommonSetting {...props} />
            case ComponentType.view:
              return <settings.CommonSetting {...props} />
            case ComponentType.spacer:
              return <settings.CommonSetting {...props} />
            case ComponentType.slider:
              return <settings.SliderSetting {...props} />
            case ComponentType.toggle:
              return <settings.ToggleSetting {...props} />
            case ComponentType.progressIndicator:
              return <settings.ProgressIndicatorSetting {...props} />
            case ComponentType.divider:
              return <settings.CommonSetting {...props} />
            case ComponentType.notificationsSettings:
              return <settings.CommonSetting {...props} />
            case ComponentType.themePicker:
              return <settings.CommonSetting {...props} />
            case ComponentType.carousel:
              return <settings.CarouselSetting {...props} />
            case ComponentType.map:
              return <settings.MapSetting {...props} />
            case ComponentType.calendar:
              return <settings.CalendarSetting {...props} />
            case ComponentType.list:
              return (
                <settings.ListSetting
                  {...props}
                  onSubComponentStyleChange={onComponentStyleChange}
                  onSubComponentPropertiesChange={onComponentPropertiesChange}
                />
              )
          }
        }
      }
      if (screenConfig && tabBarId && tabScreen) {
        const tabBar = tabBars.find(el => el.id === tabBarId)
        const tab = tabBar?.tabs?.find(el => el.screen === tabScreen)
        if (tabBar && tab) {
          return (
            <settings.TabSetting
              key={tabScreen}
              tabBars={tabBars}
              tabBar={tabBar}
              tab={tab}
              isActive={screenConfig.screenName === tab.screen}
              onTabPropertiesChange={onTabPropertiesChange}
              screenConfig={screenConfig}
              allLocalVariables={allLocalVariables}
            />
          )
        }
      }
      if (tabBarId) {
        const tabBar = tabBars.find(el => el.id === tabBarId)
        if (tabBar) {
          return (
            <settings.TabBarSetting
              key={tabBarId}
              tabBar={tabBar}
              onTabBarPropertiesChange={onTabBarPropertiesChange}
            />
          )
        }
      }
      if (topBarId) {
        return (
          <settings.TopBarSetting
            key={topBarId}
            onScreenPropertiesChange={onScreenPropertiesChange}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        )
      }
      const props = {
        key: screenId,
        onScreenPropertiesChange,
        screenConfig,
        allLocalVariables,
      }
      switch (screenConfig.screenName) {
        case 'Purchases':
          return <settings.Paywall {...props} />
        case 'Launch Screen':
          return <settings.LaunchScreen {...props} />
        default:
          return <settings.ScreenSetting {...props} />
      }
    }
  }
  if (appIcon) {
    return <settings.AppIconSettings />
  }
  return <settings.ProjectName />
}
