import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import * as styled from './Icons.styled'

import { Icon, Name } from 'components'
import { ProgressContext } from 'context'
import { EmojiIconAttachment, IconAttachment, IconsKeyboardPayload, MessageBeforeSend } from 'services/conversations'
import { ProjectsService } from 'services/projects'
import { Resource } from 'utils'

interface Props {
  payload: IconsKeyboardPayload
  changePayload: (payload: IconsKeyboardPayload) => void
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
  setIcon: React.Dispatch<React.SetStateAction<IconAttachment | null>>
  icon?: IconAttachment
}

export const Icons: React.FC<Props> = ({
  payload,
  changePayload,
  sendMessage,
  setOnClickActionButton,
  setIcon,
  icon,
}) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [icons, setIcons] = useState(payload)
  const [selected, setSelected] = useState(icon || null)
  const [file, setFile] = useState<any>(null)
  const [emoji, setEmoji] = useState<EmojiIconAttachment | null>(null)

  useEffect(() => {
    if (JSON.stringify(icons) !== JSON.stringify(payload)) {
      changePayload(icons)
    }
  }, [icons, payload])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => e.target.files?.[0] && setFile(e.target.files[0])

  useEffect(() => {
    if (file) {
      const abortController = new AbortController()
      const formData = new FormData()
      formData.append('resource', file)
      startLoader()
      ProjectsService.uploadResource('-', formData, abortController)
        .then(res => {
          const resource = res.data as Resource
          setIcons(icons => [resource, ...icons])
          setSelected(resource)
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => stopLoader())
      return () => {
        abortController.abort()
      }
    }
  }, [file])

  useEffect(() => {
    if (emoji) {
      const abortController = new AbortController()
      startLoader()
      fetch(emoji.data, { signal: abortController.signal })
        .then(response => response.blob())
        .then(res => {
          const formData = new FormData()
          formData.append('resource', new File([res], `app-logo.${res.type.split('/')[1]}`, { type: res.type }))
          return ProjectsService.uploadResource('-', formData, abortController)
        })
        .then(res => {
          const resource = { ...res.data, id: emoji.id } as Resource
          setIcons(icons => {
            const iconsCopy = JSON.parse(JSON.stringify(icons)) as IconsKeyboardPayload
            iconsCopy.splice(
              iconsCopy.findIndex(el => el.id === resource.id),
              1,
              resource
            )
            return iconsCopy
          })
          setSelected(resource)
          setEmoji(null)
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => stopLoader())
      return () => {
        abortController.abort()
      }
    }
  }, [emoji])

  useEffect(() => {
    setIcon(selected)
    setOnClickActionButton(() =>
      selected ? () => sendMessage({ body: 'App icon', format: 'text', iconAttachment: selected }) : null
    )
  }, [selected])

  return (
    <styled.Icons>
      <styled.PickIcon>
        <div>
          <Icon name={Name.ADDITIONAL_PLUS} />
          <input type="file" accept="image/*" onChange={onChange} />
        </div>
      </styled.PickIcon>
      <styled.Separator />
      <Box display="flex" gap="6px">
        {icons.map((el, i) => (
          <styled.Icon
            key={el.id}
            onClick={() =>
              (el as EmojiIconAttachment).data
                ? setEmoji(el as EmojiIconAttachment)
                : setSelected(selected => (selected?.id === (el as IconAttachment).id ? null : (el as IconAttachment)))
            }
            selected={selected?.id === el.id}
            loading={emoji?.id === el.id}
          >
            <img
              src={(el as IconAttachment).url || (el as EmojiIconAttachment).data}
              alt={(el as IconAttachment).name || `app-logo-${i}`}
            />
          </styled.Icon>
        ))}
      </Box>
    </styled.Icons>
  )
}
