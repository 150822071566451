import styled, { css } from 'styled-components'

export const TemplateHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 20px;
  background: ${props => props.theme.colors.primaryBackground};
  opacity: 0;
  pointer-events: none;
  transition: 0.2s opacity ease;
  & > p {
    font-weight: ${props => props.theme.weights.normal};
  }
`

export const TemplateContainer = styled.div`
  transition: 0.4s transform ease;
  transform: translateY(0px);
  &:hover {
    transform: translateY(-10px);
    ${TemplateHover} {
      opacity: 1;
      pointer-events: all;
    }
  }
`

export const TemplateImage = styled.img`
  width: 63%;
  height: 85%;
  background: #fdfdfd;
  border: 4px solid ${props => props.theme.colors.blackBorder};
  object-fit: contain;
  border-radius: 22px;
`

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  & a {
    text-decoration: none;
    width: 100%;
  }
  & button {
    width: 100%;
  }
`

export const TemplateBox = styled.div<{
  load: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 1/1.6;
  background: ${props => props.theme.colors.primaryBackground};
  position: relative;
  ${props =>
    props.load &&
    css`
      pointer-events: none;
    `}
`

export const BottomBox = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  position: relative;
`

export const BottomAnchor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  @media (max-height: 750px) {
    display: block;
  }
`

export const IconBox = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  margin-right: 15px;
`

export const MainIcon = styled.img`
  width: inherit;
  height: inherit;
`

export const AddIcon = styled.div`
  position: absolute;
  right: -15px;
  bottom: -15px;
  & svg {
    width: 30px;
    height: 30px;
    & > circle:first-child {
      stroke: ${props => props.theme.colors.secondaryBackground};
    }
  }
`
