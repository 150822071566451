import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { PickerOverlay, PickerWrapper } from 'components/Modal/Modal.styled'
import * as styled from './Translations.styled'

import { Button, ButtonColor, CustomPicker, CustomSectionsWrapper } from 'components'
import { ProjectContext } from 'context'

interface Props {
  children: React.ReactNode
}

export const Translation: React.FC<Props> = ({ children }) => {
  const { language } = useContext(ProjectContext)
  const themeContext = useContext(ThemeContext)
  const [showManageTranslations, setShowManageTranslations] = useState(false)

  const close = () => setShowManageTranslations(false)

  return (
    <>
      <styled.Lang onClick={() => setShowManageTranslations(true)}>{language}</styled.Lang>
      {showManageTranslations && (
        <PickerWrapper>
          <PickerOverlay onClick={close} />
          <Box maxWidth="822px" width="calc(100vw - 40px)" zIndex="1">
            <CustomPicker
              title="Translations"
              close={close}
              optionsContainer={
                <>
                  <Box width="100%" height="calc(100vh - 140px)" p="40px" overflow="auto">
                    <CustomSectionsWrapper>{children}</CustomSectionsWrapper>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    p="10px 16px 12px"
                    borderTop={`1px solid ${themeContext.colors.darkBlue_100}`}
                  >
                    <Button color={ButtonColor.SECONDARY} onClick={close}>
                      Cancel
                    </Button>
                  </Box>
                </>
              }
            />
          </Box>
        </PickerWrapper>
      )}
    </>
  )
}
