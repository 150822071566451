import styled from 'styled-components'

export const IconWrapper = styled.i`
  cursor: pointer;
  &:hover > svg {
    & *[stroke] {
      stroke: ${props => props.theme.colors.darkText};
    }
    & *[fill] {
      fill: ${props => props.theme.colors.darkText};
    }
  }
`
