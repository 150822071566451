import React from 'react'
import { ISvg, Svg } from '../Svg'

const Chat: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M15 22.25H14.7927L14.6148 22.3565L9.06818 25.6769L9.06818 23V22.25H8.31818H5.2934C4.44101 22.25 3.75 21.559 3.75 20.7066V8.2934C3.75 7.441 4.441 6.75 5.2934 6.75H26.7066C27.559 6.75 28.25 7.441 28.25 8.2934L28.25 20.7066C28.25 21.559 27.559 22.25 26.7066 22.25H15Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path d="M8 12H24" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 16H16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Chat
