import React from 'react'
import { ISvg, Svg } from '../Svg'

const Image: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M1.69995 11.5L4.31841 9.17248C4.62179 8.9028 5.07876 8.90206 5.38302 9.17076L6.3007 9.98118C6.6274 10.2697 7.12464 10.2448 7.42086 9.92504L10.5116 6.58899C10.8468 6.2272 11.4259 6.25043 11.7311 6.63791L14.3 9.89999"
      stroke="#7F899E"
      strokeLinecap="round"
    />
    <circle cx="5.1067" cy="5.10673" r="1.50673" stroke="#7F899E" />
    <rect x="1.5" y="1.5" width="13" height="13" rx="2.3" stroke="#7F899E" />
  </Svg>
)

export default Image
