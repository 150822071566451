import React from 'react'
import { ISvg, Svg } from '../Svg'

const Code: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M4 5L1 8L4 11" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 11L15 8L12 5" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 3L6 13" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Code
