import { Box } from '@mui/material'
import React from 'react'

import { AnyVariableInput, Icon, Name } from 'components'
import { RightSidebarItem, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { Action, ActionArgument, LocalVariable, Screen, ValueType } from 'utils'

export const isActionArgumentsNotValid = (actionArguments?: ActionArgument[]) =>
  !!actionArguments?.find(el => !el.name || !el.value)

interface Props {
  actionArguments?: ActionArgument[]
  setAction: React.Dispatch<React.SetStateAction<Action>>
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ActionArguments: React.FC<Props> = ({ actionArguments, setAction, screenConfig, allLocalVariables }) => {
  const onAdd = () =>
    setAction(action => ({
      ...action,
      actionArguments: [...(action.actionArguments || []), {} as ActionArgument],
    }))

  const onRemove = (index: number) =>
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.actionArguments?.splice(index, 1)
      if (copy.actionArguments && !copy.actionArguments.length) {
        delete copy.actionArguments
      }
      return copy
    })

  const onChange = (index: number, actionArgument: ActionArgument) =>
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.actionArguments?.splice(index, 1, actionArgument)
      return copy
    })

  return (
    <SubWrapper small noBorderBottom title="Arguments" onAdd={onAdd} canHide={!!actionArguments?.length} childrenFor="">
      {actionArguments && (
        <Box display="flex" flexDirection="column" gap="12px">
          {actionArguments.map((el, i) => (
            <RightSidebarItem key={`${el.name}${i}`} small title="">
              <SubWrapper
                small
                title="ARGUMENT"
                childrenFor=""
                customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />}
              >
                <SettingTextField
                  insidePicker
                  subLabel="Name"
                  value={el.name}
                  onChange={value => onChange(i, { ...el, name: value })}
                  small
                />
                <AnyVariableInput
                  valueType={ValueType.any}
                  anyVariableValue={el.value}
                  onChange={value => onChange(i, { ...el, value } as ActionArgument)}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
