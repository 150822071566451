import React from 'react'
import { ISvg, Svg } from '../Svg'

const AppName: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="1.75" y="1.75" width="16.5" height="16.5" rx="4.25" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M6.7 10C6.3134 10 5.99377 10.3158 6.06108 10.6965C6.20293 11.4987 6.58767 12.2445 7.17157 12.8284C7.92172 13.5786 8.93913 14 10 14C11.0609 14 12.0783 13.5786 12.8284 12.8284C13.4123 12.2445 13.7971 11.4987 13.9389 10.6965C14.0062 10.3158 13.6866 10 13.3 10C12.9134 10 12.6092 10.3189 12.5063 10.6916C12.3877 11.1215 12.1594 11.5176 11.8385 11.8385C11.3509 12.3261 10.6896 12.6 10 12.6C9.31044 12.6 8.64912 12.3261 8.16152 11.8385C7.84064 11.5176 7.6123 11.1215 7.49366 10.6916C7.39082 10.3189 7.0866 10 6.7 10Z"
      fill="#7F899E"
    />
    <rect x="6" y="6" width="2" height="2" rx="1" fill="#7F899E" />
    <rect x="12" y="6" width="2" height="2" rx="1" fill="#7F899E" />
  </Svg>
)

export default AppName
