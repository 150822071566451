import React from 'react'
import { ISvg, Svg } from '../Svg'

const Map: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M14.5189 26.292L15.1201 25.8436L14.5189 26.292C15.2631 27.2897 16.7369 27.2897 17.4811 26.292C18.5265 24.8905 20.3239 22.3947 21.8631 19.8215C22.6324 18.5355 23.3459 17.2158 23.8692 15.9943C24.3843 14.7919 24.75 13.607 24.75 12.6154C24.75 7.96092 20.7972 4.25 16 4.25C11.2028 4.25 7.25 7.96092 7.25 12.6154C7.25 13.607 7.61565 14.7919 8.1308 15.9943C8.65415 17.2158 9.36762 18.5355 10.1369 19.8215C11.6761 22.3947 13.4735 24.8905 14.5189 26.292Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <circle cx="16" cy="13" r="3.25" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Map
