import React from 'react'
import { ISvg, Svg } from '../Svg'

const Warning: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M8.5759 2.76774L1.20332 15.6962C1.07015 15.9296 1.00002 16.1945 1 16.4641C0.999975 16.7337 1.07005 16.9986 1.20318 17.2321C1.33632 17.4656 1.52781 17.6595 1.75842 17.7943C1.98903 17.9291 2.25062 18 2.5169 18H17.2609C17.5272 18 17.7887 17.9291 18.0194 17.7943C18.25 17.6595 18.4415 17.4656 18.5746 17.2321C18.7077 16.9986 18.7778 16.7337 18.7778 16.4641C18.7778 16.1945 18.7076 15.9296 18.5745 15.6962L11.2027 2.76774C11.0696 2.53432 10.8781 2.34048 10.6476 2.20572C10.417 2.07095 10.1555 2 9.88929 2C9.62307 2 9.36156 2.07095 9.13101 2.20572C8.90046 2.34048 8.70901 2.53432 8.5759 2.76774Z"
      fill="#FFCD2F"
    />
    <path
      d="M9.98561 6.75537H9.79089C9.31149 6.75537 8.92285 7.14401 8.92285 7.62341V11.7725C8.92285 12.2519 9.31149 12.6405 9.79089 12.6405H9.98561C10.465 12.6405 10.8537 12.2519 10.8537 11.7725V7.62341C10.8537 7.14401 10.465 6.75537 9.98561 6.75537Z"
      fill="white"
    />
    <path
      d="M9.88825 15.9313C10.4214 15.9313 10.8537 15.4991 10.8537 14.9659C10.8537 14.4327 10.4214 14.0005 9.88825 14.0005C9.35508 14.0005 8.92285 14.4327 8.92285 14.9659C8.92285 15.4991 9.35508 15.9313 9.88825 15.9313Z"
      fill="white"
    />
  </Svg>
)

export default Warning
