import styled from 'styled-components'

export const Suggestions = styled.div<{ scrollX?: boolean }>`
  min-height: 30px;
  display: flex;
  flex-wrap: ${props => !props.scrollX && 'wrap'};
  gap: 8px;
  color: ${props => props.theme.colors.darkText};
  overflow-x: ${props => props.scrollX && 'auto'};
  & span {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 0px 10px;
    border-radius: 100px;
    background-color: ${props => props.theme.colors.settingField};
    transition: ${props => props.theme.transition.secondary};
    & > i {
      margin-left: -6px;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      background-color: ${props => props.theme.colors.primaryText};
      color: ${props => props.theme.colors.white};
    }
    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.colors.settingFieldHover};
    }
  }
  @media (max-width: 767px) {
    & span {
      font-size: 17px;
      line-height: 35px;
      & > i {
        width: 26.5px;
        height: 26.5px;
        min-width: 26.5px;
        min-height: 26.5px;
      }
    }
  }
`
