import styled from 'styled-components'

export const PlanName = styled.span`
  font-size: 18px;
  font-weight: ${props => props.theme.weights.bold};
  line-height: 150%;
  color: ${props => props.theme.colors.primaryText};
`

export const PlanDetails = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  background-color: ${props => props.theme.colors.settingField};
  border-radius: 4px;
  color: ${props => props.theme.colors.primaryText1};
  font-size: 12px;
  line-height: 150%;
  font-weight: ${props => props.theme.weights.medium};
`
