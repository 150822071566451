import React from 'react'
import { useParams } from 'react-router-dom'

import { CustomSection, CustomSectionButtons, CustomSectionSubTitle, CustomSectionTitle } from 'components'
import { useAppStoreConnectionModal, useProjectIntegrations } from 'hooks'
import {
  AppStoreButton,
  appStoreDescription,
  GooglePlayButton,
  googlePlayDescription,
  StripeButton,
  stripeDescription,
} from './components'

interface Props {
  appStore?: boolean
  googlePlay?: boolean
  stripe?: boolean
}

export const Connections: React.FC<Props> = ({ appStore, googlePlay, stripe }) => {
  const { id } = useParams()
  const { appstoreIntegration, googleplayIntegration, stripeIntegration } = useProjectIntegrations(id as string)
  const appStoreConnectionModal = useAppStoreConnectionModal(appstoreIntegration)

  return (
    <CustomSection style={{ paddingBottom: '37px' }}>
      <CustomSectionTitle>Connections</CustomSectionTitle>
      <CustomSectionSubTitle>
        {appStore && googlePlay && stripe
          ? 'Authorize CodePlatform to access your App Store Connect, Google Play, and Stripe accounts to manage in-app purchases and subscriptions.'
          : appStore
          ? appStoreDescription
          : googlePlay
          ? googlePlayDescription
          : stripe
          ? stripeDescription
          : null}
      </CustomSectionSubTitle>
      <CustomSectionButtons>
        {appStore && <AppStoreButton appstoreIntegration={appstoreIntegration} />}
        {googlePlay && <GooglePlayButton googleplayIntegration={googleplayIntegration} />}
        {stripe && <StripeButton stripeIntegration={stripeIntegration} />}
      </CustomSectionButtons>
      {appStore && appStoreConnectionModal}
    </CustomSection>
  )
}
