import React from 'react'
import { ISvg, Svg } from '../Svg'

const AppStore: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M17.3086 3H6.69141C4.65596 3 3 4.65596 3 6.69141V17.3086C3 19.344 4.65596 21 6.69141 21H17.3086C19.344 21 21 19.344 21 17.3086V6.69141C21 4.65596 19.344 3 17.3086 3ZM8.54235 16.8697C8.37975 17.1513 8.0837 17.3089 7.7797 17.3089C7.63068 17.3089 7.47978 17.2711 7.34173 17.1914C6.92203 16.9491 6.77771 16.4105 7.02001 15.9908L7.19287 15.6914H9.22262L8.54235 16.8697ZM6.88477 14.9883C6.40014 14.9883 6.00586 14.594 6.00586 14.1094C6.00586 13.6247 6.40014 13.2305 6.88477 13.2305H8.61368L10.9851 9.12302L10.3424 8.00984C10.1001 7.59014 10.2444 7.05155 10.6641 6.80921H10.6641C11.0838 6.56692 11.6224 6.71127 11.8647 7.13093L12 7.36525L12.1353 7.13096C12.3777 6.71123 12.9163 6.56695 13.3359 6.80925C13.5392 6.92664 13.6846 7.11616 13.7454 7.34292C13.8062 7.56968 13.7749 7.80656 13.6575 8.00987L10.6434 13.2305H12.5446L13.5595 14.9883H6.88477ZM17.1152 14.9883H16.4012L16.98 15.9908C17.2223 16.4104 17.078 16.949 16.6583 17.1913C16.5229 17.2695 16.3725 17.3095 16.2202 17.3095C16.1439 17.3095 16.0671 17.2995 15.9913 17.2792C15.7645 17.2184 15.575 17.0729 15.4576 16.8696L12.4059 11.5839L13.4208 9.82611L15.3863 13.2305H17.1152C17.5999 13.2305 17.9941 13.6247 17.9941 14.1094C17.9941 14.594 17.5999 14.9883 17.1152 14.9883Z"
      fill="#1D80F5"
    />
  </Svg>
)

export default AppStore
