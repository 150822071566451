import React from 'react'
import { ISvg, Svg } from '../Svg'

const Slider: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M4 16L9 16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M23 16L28 16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="16" cy="16" r="4" fill="#7F899E" />
  </Svg>
)

export default Slider
