import styled from 'styled-components'

export const Buttons = styled.div<{ scrollX?: boolean }>`
  display: flex;
  gap: 10px;
  & > button {
    border-radius: 100px;
    width: 44px;
    & > svg {
      min-width: 24px;
      min-height: 24px;
    }
  }
`
