import styled from 'styled-components'

export const Chat = styled.div`
  height: calc(100% - 45px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    height: 100%;
  }
`

export const MessagesBox = styled.main`
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column-reverse;
  gap: 36px;
  overflow: auto;
  @media (max-width: 767px) {
    padding: 10px;
  }
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 30px 30px;
  @media (max-width: 767px) {
    padding: 0 16px 16px;
  }
`

export const EditMode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.darkText};
  background-color: ${props => props.theme.colors.blue_50};
  padding: 3px 3px 3px 10px;
  & svg {
    cursor: pointer;
    & path {
      transition: ${props => props.theme.transition.primary};
    }
    &:hover {
      & path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
  @media (max-width: 767px) {
    font-size: 17px;
    line-height: 25px;
  }
`

export const Form = styled.form<{ blockTyping?: boolean }>`
  opacity: ${props => props.blockTyping && '0.6'};
  pointer-events: ${props => props.blockTyping && 'none'};
`

export const Input = styled.div`
  min-height: 40px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  & > div {
    width: calc(100% - 32px);
    height: 100%;
    font-size: 14px;
    line-height: 150%;
    padding: 9.5px 10px;
    color: ${props => props.theme.colors.darkText};
    outline: 0;
    &:empty:before {
      content: attr(placeholder);
      color: ${props => props.theme.colors.grey1};
    }
  }
  @media (max-width: 767px) {
    & > div {
      font-size: 17px;
      line-height: normal;
    }
  }
`

export const SendMessageButton = styled.button<{ generating: boolean; disabled: boolean }>`
  pointer-events: ${props => props.disabled && 'none'};
  opacity: ${props => props.disabled && '0.3'};
  cursor: ${props => !props.disabled && 'pointer'};
  position: absolute;
  right: 8px;
  border: none;
  background-color: transparent;
  padding: 0;
  display: inline-flex;
  & > svg {
    & > path {
      display: ${props => props.generating && 'none'};
    }
    & > rect:last-child {
      display: ${props => !props.generating && 'none'};
    }
  }
  &:hover > svg > rect:first-child {
    fill: ${props => props.theme.colors.primaryTextHover};
  }
`
