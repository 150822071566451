import { ComponentForLeftMenu, baseComponentsForLeftMenu, systemComponentsForLeftMenu } from 'utils'

interface Section {
  name: string
  components: ComponentForLeftMenu[]
}

export const sections: Section[] = [
  {
    name: 'Base Components',
    components: baseComponentsForLeftMenu,
  },
  {
    name: 'System Components',
    components: systemComponentsForLeftMenu,
  },
]
