import React from 'react'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration, IntegrationStatus, IntegrationStripe } from 'hooks'
import { ConnectStripeButton } from './ConnectStripeButton'
import { StripeLink } from './StripeLink'

export const stripeDescription =
  'Authorize CodePlatform to access your Stripe Account and manage in-app purchases and subscriptions.'

interface Props {
  stripeIntegration?: Integration
}

export const StripeButton: React.FC<Props> = ({ stripeIntegration }) =>
  stripeIntegration?.status === IntegrationStatus.READY ? (
    <StripeLink>
      <Button
        variant={ButtonVariant.LINK}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.LEFT_SIDEBAR_PROJECT_STRIPE} />}
      >
        {(stripeIntegration?.data as IntegrationStripe | undefined)?.teamName}
      </Button>
    </StripeLink>
  ) : (
    <ConnectStripeButton disabled={!stripeIntegration} />
  )
