import { memo } from 'react'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: number | null
  onChange: (val: number | null) => void
}

export const NumberInput = memo((props: Props) => (
  <input
    type="number"
    value={props.value || undefined}
    onChange={({ target: { value } }) => props.onChange(value ? +value : null)}
    placeholder={props.placeholder}
    disabled={props.disabled}
  />
))
