import React, { useEffect, useRef } from 'react'

import * as styled from './Auth.styled'

import { LogInForm } from 'components'
import { MessageBeforeSend } from 'services/conversations'
import firebase from 'services/firebase'

interface Props {
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
  user: firebase.User | null
  adminMode?: boolean
}

export const Auth: React.FC<Props> = ({ sendMessage, user, adminMode }) => {
  const ref = useRef<HTMLDivElement>(null)
  const authorized = !adminMode && user && !user.isAnonymous

  const scrollIntoView = () => ref.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    if (authorized) {
      const { displayName, email, providerId, photoURL } = user
      sendMessage({
        body: 'Logged in',
        format: 'text',
        authAttachment: { displayName, email, providerId, photoURL },
      })
    }
  }, [authorized])

  if (authorized) {
    return <></>
  }

  return (
    <styled.LogInWrapper ref={ref}>
      <LogInForm scrollIntoView={scrollIntoView} />
    </styled.LogInWrapper>
  )
}
