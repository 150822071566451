import React, { useState } from 'react'

import { LocalVariable, Screen, VariableSource } from 'utils'
import { CustomPicker } from '../CustomPicker'
import { Variable } from '../common'
import { OptionsContainer } from '../CommonPicker'

const values = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
]

interface Props {
  close: () => void
  value?: boolean
  onChange: (val: boolean) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
}

export const BooleanPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
}) => {
  const [search, setSearch] = useState('')

  return (
    <CustomPicker
      title="Constant"
      close={close}
      setSearch={setSearch}
      defaultTab={source ? 1 : 0}
      optionsContainer={
        <OptionsContainer
          search={search}
          value={value !== undefined ? JSON.stringify(value) : undefined}
          onChange={value => onChange(value === 'true')}
          values={values}
          close={close}
        />
      }
      variableContainer={
        onSourceChange && screenConfig && allLocalVariables ? (
          <Variable
            value={source}
            onChange={onSourceChange}
            onRemove={() => onSourceChange()}
            close={() => {}}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        ) : undefined
      }
    />
  )
}
