import React from 'react'
import { ISvg, Svg } from '../Svg'

const Reload: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <path
      d="M13.9193 7.138C13.126 6.50333 12.162 6 11 6C10.0842 6.00039 9.18612 6.25228 8.4037 6.7282C7.62129 7.20412 6.98461 7.88579 6.56313 8.69882C6.14166 9.51186 5.95157 10.425 6.01361 11.3387C6.07564 12.2524 6.38743 13.1315 6.91494 13.8801C7.44246 14.6287 8.16543 15.218 9.005 15.5838C9.84456 15.9496 10.7685 16.0778 11.6759 15.9544C12.5833 15.831 13.4395 15.4607 14.1508 14.884C14.8622 14.3073 15.4016 13.5463 15.71 12.684C15.8333 12.3373 16.1833 12.104 16.5433 12.1807L17.1953 12.3193C17.5553 12.3953 17.7887 12.7507 17.6787 13.102C16.7853 15.9407 14.1347 18 11 18C7.134 18 4 14.866 4 11C4 7.134 7.134 4 11 4C12.8553 4 14.3013 4.84533 15.3407 5.71667L16.5287 4.52867C16.6219 4.43546 16.7407 4.37199 16.87 4.34628C16.9993 4.32057 17.1333 4.33377 17.2551 4.38421C17.3769 4.43466 17.481 4.52009 17.5543 4.62969C17.6275 4.7393 17.6666 4.86817 17.6667 5V8.66667C17.6667 8.84348 17.5964 9.01305 17.4714 9.13807C17.3464 9.2631 17.1768 9.33333 17 9.33333H13.3333C13.2015 9.33331 13.0726 9.29419 12.963 9.22093C12.8534 9.14768 12.768 9.04357 12.7175 8.92177C12.6671 8.79997 12.6539 8.66595 12.6796 8.53665C12.7053 8.40734 12.7688 8.28857 12.862 8.19533L13.9193 7.138Z"
      fill="#F2F2F3"
    />
  </Svg>
)
export default Reload
