import React from 'react'

import * as styled from './Links.styled'

import { Button, ButtonVariant, Icon, Name } from 'components'

export const Links: React.FC = () => (
  <styled.LinksWrapper>
    <Button active variant={ButtonVariant.TOP_PANEL_ACTION} leftIcon={<Icon name={Name.ADDITIONAL_YOUTUBE} />}>
      Tutorial
    </Button>
    <Button active variant={ButtonVariant.TOP_PANEL_ACTION} leftIcon={<Icon name={Name.ADDITIONAL_DOCS} />}>
      Docs
    </Button>
  </styled.LinksWrapper>
)
