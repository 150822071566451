import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Github: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.02198 0C3.58984 0 0 3.52967 0 7.88293C0 11.3665 2.2983 14.3206 5.48503 15.3621C5.88613 15.4363 6.0332 15.1923 6.0332 14.9831C6.0332 14.7959 6.02651 14.2998 6.02317 13.6427C3.79172 14.118 3.3211 12.5851 3.3211 12.5851C2.9561 11.6753 2.42865 11.432 2.42865 11.432C1.702 10.9433 2.48481 10.9533 2.48481 10.9533C3.29035 11.0082 3.71351 11.7656 3.71351 11.7656C4.4288 12.9709 5.59132 12.6226 6.04991 12.4214C6.12211 11.9113 6.32867 11.5644 6.55797 11.3671C4.77642 11.1699 2.90396 10.4921 2.90396 7.4718C2.90396 6.61145 3.21481 5.90819 3.72955 5.35668C3.6393 5.15746 3.36856 4.35593 3.79974 3.27029C3.79974 3.27029 4.47158 3.05905 6.00579 4.07851C6.64755 3.90336 7.32941 3.81646 8.01128 3.81245C8.69315 3.81646 9.37502 3.90336 10.0168 4.07851C11.541 3.05905 12.2128 3.27029 12.2128 3.27029C12.644 4.35593 12.3732 5.15746 12.293 5.35668C12.8044 5.90819 13.1153 6.61145 13.1153 7.4718C13.1153 10.5001 11.2401 11.1666 9.45524 11.3605C9.73601 11.5971 9.99672 12.0804 9.99672 12.8191C9.99672 13.874 9.9867 14.7217 9.9867 14.9777C9.9867 15.1843 10.1271 15.4309 10.5382 15.3521C13.7477 14.3172 16.044 11.3611 16.044 7.88293C16.044 3.52967 12.4521 0 8.02198 0Z"
      fill="#7F899E"
    />
  </Svg>
)
