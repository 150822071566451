import React from 'react'
import { ISvg, Svg } from '../Svg'

const File: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g opacity="0.5">
      <path
        d="M10.5 4.57143V2.27L12.775 5H10.9167C10.8062 5 10.7002 4.95485 10.622 4.87447C10.5439 4.7941 10.5 4.68509 10.5 4.57143ZM10.9167 5.85714C10.5851 5.85714 10.2672 5.72168 10.0328 5.48057C9.79836 5.23945 9.66667 4.91242 9.66667 4.57143V2H4.25C3.91848 2 3.60054 2.13546 3.36612 2.37658C3.1317 2.6177 3 2.94472 3 3.28571V12.7143C3 13.0553 3.1317 13.3823 3.36612 13.6234C3.60054 13.8645 3.91848 14 4.25 14H11.75C12.0815 14 12.3995 13.8645 12.6339 13.6234C12.8683 13.3823 13 13.0553 13 12.7143V5.85714H10.9167Z"
        fill="#7F899E"
      />
    </g>
  </Svg>
)

export default File
