/* eslint-disable */
import React, { ReactElement } from 'react'

import * as styled from './Button.styled'
import { ButtonColor } from './Button.styled'

interface Props {
  children: React.ReactNode
  color?: ButtonColor
  height?: number
  disabled?: boolean
  $rightIcon?: ReactElement
  $leftIcon?: ReactElement
  input?: ReactElement
  type?: 'button' | 'submit' | 'reset'
  onClick?: any
  to?: string
  href?: string
  style?: React.CSSProperties
}

export const Button: React.FC<Props> = ({
  children,
  color = ButtonColor.PRIMARY,
  height = 40,
  disabled,
  $rightIcon,
  $leftIcon,
  input,
  type,
  onClick,
  to,
  href,
  style,
}) => {
  if (!!to) {
    return (
      <styled.LinkBox
        color={color}
        disabled={disabled}
        height={height}
        $rightIcon={!!$rightIcon}
        $leftIcon={!!$leftIcon}
        onClick={onClick}
        style={style}
        to={to}
      >
        {input}
        {$leftIcon}
        <styled.Text>{children}</styled.Text>
        {$rightIcon}
      </styled.LinkBox>
    )
  } else if (!!href) {
    return (
      <styled.OutLinkBox
        color={color}
        disabled={disabled}
        height={height}
        $rightIcon={!!$rightIcon}
        $leftIcon={!!$leftIcon}
        type={type}
        onClick={onClick}
        style={style}
        href={href}
      >
        {input}
        {$leftIcon}
        <styled.Text>{children}</styled.Text>
        {$rightIcon}
      </styled.OutLinkBox>
    )
  } else {
    return (
      <styled.Box
        color={color}
        disabled={disabled}
        height={height}
        $rightIcon={!!$rightIcon}
        $leftIcon={!!$leftIcon}
        type={type}
        onClick={onClick}
        style={style}
      >
        {input}
        {$leftIcon}
        <styled.Text>{children}</styled.Text>
        {$rightIcon}
      </styled.Box>
    )
  }
}
