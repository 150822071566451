import React from 'react'
import { ISvg, Svg } from '../Svg'

const Crop: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M5 2V11H14" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
    <path d="M11 14V5H2" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)
export default Crop
