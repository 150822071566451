import React from 'react'
import { ISvg, Svg } from '../Svg'

const LetterSpacing: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <path d="M3 18L3 4" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 18L19 4" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M8.11328 15.0996C8.50586 15.0996 8.72852 14.9062 8.86914 14.4492L9.42578 12.8438H12.584L13.1406 14.4609C13.2754 14.9121 13.5039 15.0996 13.9141 15.0996C14.3535 15.0996 14.6699 14.8125 14.6699 14.4023C14.6699 14.2676 14.6406 14.1328 14.5703 13.9395L12.1094 7.25977C11.8984 6.69141 11.5586 6.44531 11.002 6.44531C10.4512 6.44531 10.1113 6.70312 9.90625 7.26562L7.45117 13.9395C7.38086 14.1211 7.35156 14.2852 7.35156 14.4023C7.35156 14.8301 7.65039 15.0996 8.11328 15.0996ZM9.7832 11.6484L10.9727 8.03906H11.0137L12.2148 11.6484H9.7832Z"
      fill="#7F899E"
    />
  </Svg>
)

export default LetterSpacing
