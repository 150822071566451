import styled, { css } from 'styled-components'
import { ThemeType } from 'theme'

export const Application = styled.div<{ hidePreview?: boolean }>`
  border-radius: 16px;
  padding: ${props => (props.hidePreview ? '7px 20px 10px' : '20px')};
  background: ${props => props.theme.colors.primaryBackground};
`

export const ApplicationImage = styled.div`
  min-width: 300px;
  height: 180px;
  background: ${props => props.theme.colors.secondaryButtonBg1};
  border: 1px solid ${props => props.theme.colors.borderColorToTransparent};
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    & > div {
      opacity: 1;
    }
  }
`

export const ApplicationActions = styled.div`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: ${props => props.theme.transition.primaryOut};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.overlayBg};
  & a {
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    & button {
      width: 182px;
      border: 1px ${props => props.theme.colors.white} solid;
      color: ${props => props.theme.colors.white};
    }
  }
`

export const ApplicationName = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  & a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.primaryText2};
    font-weight: ${props => props.theme.weights.bold};
    font-size: 22px;
    line-height: 33px;
    transition: ${props => props.theme.transition.primary};
    ${props =>
      props.theme.themeType === ThemeType.LIGHT &&
      css`
        &:hover {
          color: ${props.theme.colors.primaryTextHover};
        }
      `}
  }
`

export const ApplicationIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.secondaryButtonBg1};
  margin-right: 10px;
  & svg path {
    fill: ${props => props.theme.colors.primaryText1};
  }
`

export const ActivityHead = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.darkText};
  & a {
    margin-left: 15px;
    color: ${props => props.theme.colors.primaryText};
  }
`
