import React, { useContext } from 'react'

import {
  BrandingColorInput,
  BrandingFontInput,
  ComponentPreview,
  ComponentStylePicker,
  ElementWithLocalizedContent,
  ImageInput,
} from 'components'
import { ProjectContext } from 'context'
import {
  RightSidebarItem,
  SettingField,
  SettingImageAlignment,
  SettingTextAlignment,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import {
  LocalVariable,
  Resource,
  Screen,
  ScreenComponent,
  getAppTextAlignment,
  getCSSTextAlignment,
  getTextareaValue,
} from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  onComponentStyleChange: (config: ScreenComponent) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
  listId?: string
}

export const ButtonSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  onComponentStyleChange,
  screenConfig,
  allLocalVariables,
  listId,
}) => {
  const { language } = useContext(ProjectContext)
  const { id, componentType, name, text, font, textAlignment, textColor, image, imageColor, imageLocation } = component

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <SettingTextField
          key={language}
          label="Text"
          value={getTextareaValue(language, text).value}
          values={text?.values || []}
          limit={30}
          onChange={(value, values) =>
            onComponentPropertiesChange([
              {
                propName: 'text',
                value: {
                  key: getTextareaValue(language, text).key,
                  locales: { ...text?.locales, [language]: value },
                  values,
                },
              },
            ])
          }
          translation={
            text ? (
              <ElementWithLocalizedContent
                title={`${name || componentType}`}
                value={text}
                onChange={value => onComponentPropertiesChange([{ propName: 'text', value }])}
              />
            ) : undefined
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingField
          label="Style"
          leftIcon={<ComponentPreview screenConfig={screenConfig} componentConfig={component} listId={listId} />}
          canOpenStylePickerFromScreen
          optionsContainer={
            <ComponentStylePicker
              componentId={id}
              screenConfig={screenConfig}
              onChange={onComponentStyleChange}
              close={() => {}}
              listId={listId}
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Font" canHide childrenFor={componentType}>
        <BrandingFontInput
          value={font}
          onChange={value => onComponentPropertiesChange([{ propName: 'font', value }])}
        />
        <SettingTextAlignment
          subLabel="Alignment"
          value={getCSSTextAlignment(textAlignment)}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'textAlignment', value: getAppTextAlignment(value) }])
          }
        />
        <BrandingColorInput
          subLabel="Text color"
          value={textColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { constant: value } }])}
          source={textColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Icon" canHide childrenFor={componentType}>
        <ImageInput
          subLabel="Asset"
          value={image?.constant?.resourceId}
          onChange={(value, resources) =>
            onComponentPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
          }
          source={image?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'image', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          sfByDefault
        />
        <BrandingColorInput
          subLabel="Tint color"
          value={imageColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { constant: value } }])}
          source={imageColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingImageAlignment
          subLabel="Alignment"
          value={imageLocation}
          onChange={value => onComponentPropertiesChange([{ propName: 'imageLocation', value }])}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
