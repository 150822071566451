import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  background: ${props => props.theme.colors.darkBlue_50};
  width: 100%;
  @media (max-width: 767px) {
    display: none;
  }
`

export const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 13px;
  object-fit: cover;
  @media (max-width: 767px) {
    width: 34px;
    height: 34px;
    border-radius: 8px;
  }
`

export const Name = styled.h3<{ hasName: boolean }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: ${props => (props.hasName ? props.theme.colors.darkText : props.theme.colors.grey)};
  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 700;
    margin-right: auto;
  }
`
