import styled from 'styled-components'

import { ApplicationStatus, IntegrationStatus } from 'hooks'

export const Description = styled.div`
  color: ${props => props.theme.colors.darkText1};
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 30px;
`

export const Item = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  & > div:first-child > svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
`

export const ItemTitle = styled.div`
  margin-top: 3px;
  color: ${props => props.theme.colors.darkText};
  font-size: 18px;
  line-height: normal;
`

export const ItemDescription = styled.div`
  color: ${props => props.theme.colors.grey1};
  font-size: 14px;
  line-height: 130%;
  padding-bottom: 13px;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`

export const DotStatus = styled.div<{ status?: ApplicationStatus | IntegrationStatus }>`
  width: 6px;
  min-width: 6px;
  height: 6px;
  min-height: 6px;
  border-radius: 50%;
  background: ${props =>
    props.status === ApplicationStatus.DEPLOYING ||
    props.status === ApplicationStatus.REPO_PUSHED ||
    props.status === ApplicationStatus.READY ||
    props.status === IntegrationStatus.READY
      ? '#389E0D'
      : props.status === ApplicationStatus.FAILED || props.status === IntegrationStatus.FAILED
      ? props.theme.colors.red
      : props.status === ApplicationStatus.REPO_CREATED ||
        props.status === IntegrationStatus.OTP ||
        props.status === IntegrationStatus.TEAM
      ? '#FFD701'
      : props.theme.colors.grey} !important;
`

export const Line = styled.i`
  height: 100%;
  width: 2px;
  background: ${props => props.theme.colors.darkBlue_100_noChange};
  border-radius: 100px;
`
