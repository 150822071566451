import React from 'react'

import { ISvg, Svg } from '../Svg'

const Success: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="9" fill="#33C38D" />
    <path d="M8.625 12L11.325 14.25L15.375 9.75" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)

export default Success
