import styled, { css } from 'styled-components'

export const NumberInput = styled.div<{
  leftIcon: boolean
  specialWidth?: string
  fullWidth?: boolean
  hideArrows: boolean
  active?: boolean
}>`
  min-height: 30px;
  min-width: ${props =>
    props.specialWidth || (props.fullWidth ? 'calc(100% - 35% - 8px)' : props.leftIcon ? '100%' : '60px')};
  width: ${props => props.specialWidth || (props.hideArrows && '100%')};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: ${props =>
    props.leftIcon ? '0 7px 0 4px' : props.specialWidth ? '0 1px' : props.hideArrows ? '0 6px 0 10px' : '0 5px 0 10px'};
  border-radius: 8px;
  background: ${props =>
    props.specialWidth
      ? 'transparent'
      : props.active
      ? props.theme.colors.secondaryButtonBg1
      : props.theme.colors.settingField};
  transition: background 300ms ease-out;
  border: 1px solid ${props => (props.active ? props.theme.colors.borderColorToLightBorder : 'transparent')};
  &:hover {
    background: ${props =>
      props.active ? props.theme.colors.secondaryButtonBg1 : props.theme.colors.settingFieldHover};
  }
  & input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: ${props => props.theme.fonts.primary};
    text-align: ${props =>
      props.leftIcon ? 'right' : (props.hideArrows && !props.specialWidth) || props.fullWidth ? 'left' : 'center'};
    width: ${props => (props.specialWidth || props.fullWidth ? '100%' : props.hideArrows ? '100%' : '24px')};
    font-size: 12px;
    line-height: 150%;
    color: ${props => props.theme.colors.darkText};
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    &::placeholder {
      color: ${props => props.theme.colors.grey1};
    }
  }
  & > svg {
    height: 22px;
    min-height: 22px;
    min-width: 22px;
    border-radius: 4px;
  }
`

export const NumberInputIcons = styled.div<{ leftIcon: boolean }>`
  display: flex;
  flex-direction: column;
  & svg {
    height: 14px;
    width: 16px;
    cursor: pointer;
    user-select: none;
    & path {
      transition: 300ms ease-out;
      stroke: ${props => props.theme.colors.grey1};
    }
    &:hover {
      & path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
  ${props =>
    props.leftIcon &&
    css`
      position: absolute;
      right: 4px;
    `}
`

export const Units = styled.div<{ isZero: boolean }>`
  margin-left: -4px;
  font-size: 12px;
  line-height: 150%;
  color: ${props => (props.isZero ? props.theme.colors.grey1 : props.theme.colors.darkText)};
`
