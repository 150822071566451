import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ProjectControllsWrapper = styled.div`
  height: calc(100% - 90px);
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  & nav {
    & hr {
      width: calc(100% - 14px);
      height: 1px;
      border: 0;
      background-color: ${props => props.theme.colors.darkBlue_100};
      align-self: center;
      margin: 6px 0;
    }
  }
`

export const ProjectNavigation = styled.nav`
  width: 100%;
`

export const ProjectLinkBox = styled.div<{ active?: boolean; isNewWorkspace?: boolean }>`
  width: 100%;
  cursor: pointer;
  & a,
  div {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 30px;
    padding: 0 8px;
    border-radius: 8px;
    background-color: ${props => props.active && props.theme.colors.settingField};
    text-decoration: none;
    color: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.darkText1)};
    color: ${props => props.isNewWorkspace && props.theme.colors.grey1};
    font-size: 14px;
    line-height: 150%;

    & > svg {
      margin-right: 8px;
      & * {
        fill: ${props => !props.isNewWorkspace && (props.active ? props.theme.colors.grey2 : props.theme.colors.grey1)};
      }
      & * {
        stroke: ${props => props.isNewWorkspace && props.theme.colors.grey1};
      }
    }

    &:hover {
      background-color: ${props => props.theme.colors.settingField};
      color: ${props => props.theme.colors.darkText};
      & > svg {
        & * {
          fill: ${props => !props.isNewWorkspace && props.theme.colors.grey2};
        }
        & * {
          stroke: ${props => props.isNewWorkspace && props.theme.colors.grey2};
        }
      }
    }
  }
`

export const WorkspaceHideBox = styled.div<{ active?: boolean }>`
  overflow: hidden;
  height: ${props => (props.active ? 200 : 0)}px;
  transition: height 0.3s ease-out;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`

export const WorkspaceName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`

export const WorkspaceIcon = styled.div<{ active?: boolean }>`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: ${props => !props.active && `1.5px solid ${props.theme.colors.grey1}`};
  background-color: ${props => props.active && props.theme.colors.primaryText};
  color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.grey1)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 8px;
  margin-left: ${props => (props.active ? '3px' : '-5px')};
`

export const WorkspaceWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.active ? '15px' : '5px')};
  margin-top: ${props => (props.active ? '3px' : '0px')};
  ${props =>
    !props.active &&
    `
      height: 30px;
      padding: 0 8px;
      border-radius: 8px;
      cursor: pointer;
  `}
  ${WorkspaceName} {
    color: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.darkText1)};
  }

  &:hover {
    ${props =>
      !props.active &&
      `
        background-color: ${props.theme.colors.settingField};
    `}
    ${WorkspaceName} {
      color: ${props => props.theme.colors.darkText};
    }
    ${WorkspaceIcon} {
      border: ${props => !props.active && `1.5px solid ${props.theme.colors.grey2}`};
      color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.grey2)};
    }
  }
`

export const SelectWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    display: flex;
    & > div {
      max-width: 350px;
      width: 100%;
      height: 50px;
      padding: 13px 10px 13px 15px;
      border-radius: 16px;
      background: #eaf6f5;
      justify-content: space-between;
      & > span {
        font-weight: 600;
        line-height: 145%;
      }
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  @media (max-width: 767px) {
    & > div {
      justify-content: center;
    }
  }
`

export const Project = styled(Link)`
  text-decoration: none;
  width: calc(100% / 7);
  min-width: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  & > img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    object-fit: cover;
  }
  & > div {
    width: 80px;
    color: ${props => props.theme.colors.darkText};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    line-height: 150%;
  }
  @media (max-width: 900px) {
    width: calc(100% / 6);
  }
  @media (max-width: 750px) {
    width: calc(100% / 5);
  }
  @media (max-width: 600px) {
    width: calc(100% / 4);
  }
  @media (max-width: 500px) {
    width: calc(100% / 3);
  }
  @media (max-width: 350px) {
    width: calc(100% / 2);
  }
`
