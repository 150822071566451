import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Genkit: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.75 1.14434C7.9047 1.05502 8.0953 1.05502 8.25 1.14434L13.8122 4.35566C13.9669 4.44498 14.0622 4.61004 14.0622 4.78868V11.2113C14.0622 11.39 13.9669 11.555 13.8122 11.6443L8.25 14.8557C8.0953 14.945 7.9047 14.945 7.75 14.8557L2.18782 11.6443C2.03312 11.555 1.93782 11.39 1.93782 11.2113V4.78868C1.93782 4.61004 2.03312 4.44498 2.18782 4.35566L7.75 1.14434Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path
      d="M7.43248 5.34942L7.80402 4.14492C7.81684 4.10298 7.84279 4.06626 7.87805 4.04017C7.9133 4.01408 7.956 4 7.99986 4C8.04372 4 8.08641 4.01408 8.12167 4.04017C8.15692 4.06626 8.18287 4.10298 8.19569 4.14492L8.56723 5.34942C8.71843 5.84001 8.98721 6.28623 9.35017 6.64927C9.71313 7.0123 10.1593 7.28117 10.6498 7.43248L11.8543 7.80358C11.8965 7.81628 11.9334 7.8422 11.9596 7.87751C11.9858 7.91282 12 7.95564 12 7.99963C12 8.04362 11.9858 8.08644 11.9596 8.12175C11.9334 8.15706 11.8965 8.18299 11.8543 8.19569L10.6498 8.56678C10.1593 8.71799 9.71304 8.98676 9.35 9.34972C8.98696 9.71268 8.71809 10.1588 8.56678 10.6494L8.19569 11.8543C8.18299 11.8965 8.15706 11.9334 8.12175 11.9596C8.08644 11.9858 8.04362 12 7.99963 12C7.95564 12 7.91282 11.9858 7.87751 11.9596C7.8422 11.9334 7.81628 11.8965 7.80358 11.8543L7.43248 10.6494C7.28122 10.1589 7.01241 9.71276 6.64946 9.34981C6.2865 8.98685 5.84037 8.71805 5.34987 8.56678L4.14492 8.19524C4.10298 8.18242 4.06626 8.15647 4.04017 8.12122C4.01408 8.08597 4 8.04327 4 7.99941C4 7.95555 4.01408 7.91285 4.04017 7.8776C4.06626 7.84234 4.10298 7.8164 4.14492 7.80358L5.34987 7.43203C5.84037 7.28077 6.2865 7.01197 6.64946 6.64901C7.01241 6.28605 7.28122 5.83993 7.43248 5.34942Z"
      fill="#7F899E"
    />
  </Svg>
)
