import { memo } from 'react'
import AceEditor from 'react-ace'
import { parse, stringify } from 'yaml'

import { SimpleObjectInputWrapper } from '../../FieldTypes.styled'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: object | null
  onChange: (val: object | null) => void
}

export const SimpleObjectInput = memo((props: Props) => {
  const value = stringify(props.value)

  const onChange = (value: string) => {
    try {
      props.onChange(parse(value))
    } catch {}
  }

  return (
    <SimpleObjectInputWrapper>
      <div>Yaml editor</div>
      <AceEditor value={value} onChange={onChange} readOnly={props.disabled} mode="yaml" width="100%" height="300px" />
    </SimpleObjectInputWrapper>
  )
})
