import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import {
  Button,
  ButtonColor,
  ButtonVariant,
  DeviceSettings,
  Icon,
  Name,
  PWAPreview,
  PWAPreviewIframe,
  reloadPWAPreviewIframeEvent,
} from 'components'
import { TopPanelContext } from 'context'

export const RunBtn: React.FC = () => {
  const { setBackButton, setPageLeftButtons, setPageCenterButtons, setPage } = useContext(TopPanelContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setBackButton(
        <Button
          color={ButtonColor.TERTIARY}
          leftIcon={<Icon name={Name.ADDITIONAL_BACK} width={20} height={20} />}
          onClick={() => setOpen(false)}
        >
          Back
        </Button>
      )
      setPageLeftButtons(
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_RELOAD} />}
          onClick={() => document.dispatchEvent(new Event(reloadPWAPreviewIframeEvent))}
        >
          Reload
        </Button>
      )
      setPageCenterButtons(<DeviceSettings topPanel changeDeviceMode />)
      setPage(
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <PWAPreview adaptive>
            <PWAPreviewIframe adaptive />
          </PWAPreview>
        </Box>
      )
      return () => {
        setBackButton(null)
        setPageLeftButtons(null)
        setPageCenterButtons(null)
        setPage(null)
      }
    }
  }, [open])

  return (
    <Button
      color={open ? ButtonColor.BLACK : ButtonColor.SECONDARY1}
      leftIcon={<Icon name={Name.GLOBAL_PLAY} />}
      onClick={() => setOpen(open => !open)}
    />
  )
}
