import styled from 'styled-components'

export const Wrapper = styled.div<{ hasBackButton?: boolean; isMobile?: boolean }>`
  position: ${props => !props.isMobile && props.hasBackButton && 'fixed'};
  top: ${props => !props.isMobile && props.hasBackButton && 0};
  left: ${props => !props.isMobile && props.hasBackButton && 0};
  z-index: ${props => !props.isMobile && props.hasBackButton && 11};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  background: ${props => props.theme.colors.primaryBackground};
  padding: 0 15px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  & > div {
    width: ${props => (props.isMobile ? 'calc(100% / 2)' : 'calc(100% / 3)')};
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
`
export const BackButton = styled.div`
  width: auto !important;
  padding: 15px 15px 15px 0;
  margin-right: 15px;
  border-right: 1px solid ${props => props.theme.colors.borderColor};
`

export const Buttons = styled.div<{ right?: boolean; hidden?: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: ${props => props.right && 'flex-end'};
  gap: 16px;
`

export const Logo = styled.div`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.primaryBackground};
  & > img {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    object-fit: cover;
  }
`

export const Name = styled.h1`
  font-size: 14px;
  font-weight: 700;
  margin-left: -5px;
  color: ${props => props.theme.colors.darkText};
`

export const Page = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 10;
  display: flex;
  background-color: ${props => props.theme.colors.secondaryBackground};
`
