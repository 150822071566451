import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  overflow: hidden auto;
  background-color: ${props => props.theme.colors.secondaryBackground};
  padding: 30px 40px 40px;
  position: relative;
`

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const MonthlyBox = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 0 20px;
  font-size: 14px;
`

interface IVariant {
  choosed: boolean
}

export const Variant = styled.span<IVariant>`
  font-weight: ${props => (props.choosed ? props.theme.weights.bold : props.theme.weights.regular)};
  color: ${props => (props.choosed ? props.theme.colors.darkText : props.theme.colors.darkText1)};
  & > span {
    font-weight: ${props => props.theme.weights.regular};
  }
`

export const CardsBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 37px 0 40px;
  @media (max-width: 1560px) {
    flex-wrap: wrap;
  }
`
