import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Screenshots: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1" y="1" width="9" height="14" rx="2" fill="#7F899E" />
    <path
      d="M11 12.8461C11 13.2217 11.3991 13.4406 11.6167 13.1344C11.7342 12.9692 11.8247 12.7806 11.8802 12.5733L13.915 4.97958C14.1651 4.04601 13.6111 3.08642 12.6775 2.83627L11.3776 2.48797C11.1871 2.43692 11 2.58049 11 2.77775V12.8461Z"
      fill="#7F899E"
    />
  </Svg>
)
