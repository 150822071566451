import styled, { css } from 'styled-components'

import * as text from '../../../TextStyles'

interface IIsShown {
  $isShown?: boolean
}

export const ListItem = styled.li<IIsShown>`
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    border-bottom: ${props =>
      props.$isShown ? '1px solid transparent' : `1px solid ${props.theme.colors.borderColor}`};
    padding-bottom: ${(props: IIsShown) => (props.$isShown ? '20px' : '0')};
    transition: padding-bottom 0.4s ease;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
  }
`

const DropDownStyle = css<IIsShown>`
  transition: ${props => props.theme.transition.primary};
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  opacity: 1;
  border-radius: 8px;
  background: transparent;

  &:hover {
    background: ${props => props.theme.colors.hoverWhite};
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    justify-content: space-between;
    padding: 15px 0;

    &:hover {
      background: transparent;
    }
  }
`

export const DropDown = styled.div<IIsShown>`
  ${DropDownStyle}
`

export const DropDownLink = styled.a`
  ${DropDownStyle}
`

export const DropDownText = styled.h4`
  ${text.FS14}
  font-weight: ${props => props.theme.weights.bold};
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: ${props => props.theme.fontSize.m2}px;
    line-height: ${props => props.theme.lineHeight.m4}px;
  }
`

export const DropDownArrowMobile = styled.img<IIsShown>`
  display: none;
  transition: 0.2s all ease;
  transform: ${(props: IIsShown) =>
    props.$isShown ? 'rotate(0) translate(0, -2px)' : 'rotate(-180deg) translate(0, 0)'};
  margin-top: 5px;
  margin-right: 15px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: block;
    width: 16px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    margin-right: 6px;
  }
`

interface IMenuOpenBox {
  $isVisible?: boolean
  $adaptiveHeight?: number
}

export const MenuOpenBox = styled.div<IMenuOpenBox>`
  width: 100%;
  background: ${props => props.theme.colors.white};
  position: absolute;
  left: 0;
  transition: 0.2s top ease-in-out, 0.2s opacity ease-in-out, 0.2s height ease-in-out, 0.2s padding-top ease-in-out,
    0.2s background-color ease-in-out;
  top: ${props => `${props.theme.headerSize.heightDesktop}px`};
  opacity: ${(props: IMenuOpenBox) => (props.$isVisible ? '1' : '0')};
  pointer-events: ${(props: IMenuOpenBox) => (props.$isVisible ? 'auto' : 'none')};
  border-bottom: 1px solid ${props => props.theme.colors.borderGray};

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    transition: 0.4s top ease-in-out, 0.4s opacity ease-in-out, 0.4s height ease-in-out, 0.4s padding-top ease-in-out,
      0.4s background-color ease-in-out;
    position: static;
    overflow: hidden;
    backdrop-filter: none;
    width: calc(100% + 40px);
    height: ${(props: IMenuOpenBox) => (props.$isVisible ? `${props.$adaptiveHeight}px` : '0')};
    padding: ${(props: IMenuOpenBox) => (props.$isVisible ? '30px 20px' : '0px 20px 20px')};
    background-color: ${props => props.theme.colors.secondaryBlack};
    margin: 0 -20px -20px -20px;
    border-bottom: none;
    box-sizing: border-box;
    & > div {
      padding: 0;
    }
  }
`

export const MenuOpenOverlay = styled.div<{
  $visible?: boolean
  $adaptiveHeight?: number
}>`
  position: absolute;
  top: ${props => `calc(${props.$adaptiveHeight}px + ${props.theme.headerSize.heightDesktop}px)`};
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #0b0b17;
  transition: opacity 0.2s ease-in-out;
  opacity: ${props => (props.$visible ? '0.6' : '0')};
  pointer-events: none;
  z-index: -2;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: none;
    transition: opacity 0.4s ease-in-out;
  }
`

export const MenuOpenContent = styled.div`
  padding: 15px 0px 30px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    padding: 0;
  }
`

export const MenuOpenGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
  margin-left: -10px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    row-gap: 10px;
    column-gap: 0px;
    justify-content: flex-start;
    color: ${props => props.theme.colors.offWhite1};
    margin-left: 0px;
  }
`
