import React from 'react'

import * as styled from './CustomSection.styled'

import { Button, ButtonColor } from 'components'

interface Props {
  cancel: () => void
  onClick: () => void
}

export const CustomSectionActions: React.FC<Props> = ({ cancel, onClick }) => (
  <styled.Actions>
    <Button color={ButtonColor.SECONDARY} onClick={cancel}>
      Cancel
    </Button>
    <Button type="submit" onClick={onClick}>
      Save
    </Button>
  </styled.Actions>
)
