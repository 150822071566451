import styled from 'styled-components'
import { ThemeType } from 'theme'

export const IconWrapper = styled.div`
  & svg {
    & rect:last-child {
      ${props => props.theme.themeType === ThemeType.DARK && 'stroke: transparent;'}
    }
  }
`
