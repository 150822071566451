import styled from 'styled-components'

export const AvatarInput = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  & > button {
    position: relative;
  }
  & input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
`
