import React from 'react'
import { ISvg, Svg } from '../Svg'

const Docs: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M19.6136 4.00115H19.9836C20.192 4.0017 20.3928 4.07961 20.547 4.21979L25.2225 8.42434C25.3096 8.50296 25.3793 8.59894 25.4271 8.70611C25.4749 8.81328 25.4997 8.92927 25.5 9.04661H20.4545C20.2315 9.04661 20.0176 8.95801 19.8599 8.80031C19.7022 8.64261 19.6136 8.42872 19.6136 8.2057V4.00115ZM25.5 10.7284V25.0239C25.4715 25.7161 25.1713 26.3692 24.6644 26.8415C24.1575 27.3137 23.4848 27.5671 22.7923 27.5466H9.70773C9.01521 27.5671 8.34254 27.3137 7.83563 26.8415C7.32873 26.3692 7.02847 25.7161 7 25.0239V6.52388C7.02847 5.83165 7.32873 5.17856 7.83563 4.70629C8.34254 4.23401 9.01521 3.98065 9.70773 4.00115H17.9318V8.2057C17.9318 8.87477 18.1976 9.51643 18.6707 9.98954C19.1438 10.4626 19.7855 10.7284 20.4545 10.7284H25.5ZM20.4545 22.5012C20.4545 22.2781 20.366 22.0642 20.2082 21.9065C20.0505 21.7488 19.8367 21.6602 19.6136 21.6602H12.8864C12.6633 21.6602 12.4495 21.7488 12.2918 21.9065C12.134 22.0642 12.0455 22.2781 12.0455 22.5012C12.0455 22.7242 12.134 22.9381 12.2918 23.0958C12.4495 23.2535 12.6633 23.3421 12.8864 23.3421H19.6136C19.8367 23.3421 20.0505 23.2535 20.2082 23.0958C20.366 22.9381 20.4545 22.7242 20.4545 22.5012ZM20.4545 18.2966C20.4545 18.0736 20.366 17.8597 20.2082 17.702C20.0505 17.5443 19.8367 17.4557 19.6136 17.4557H12.8864C12.6633 17.4557 12.4495 17.5443 12.2918 17.702C12.134 17.8597 12.0455 18.0736 12.0455 18.2966C12.0455 18.5196 12.134 18.7335 12.2918 18.8912C12.4495 19.0489 12.6633 19.1375 12.8864 19.1375H19.6136C19.8367 19.1375 20.0505 19.0489 20.2082 18.8912C20.366 18.7335 20.4545 18.5196 20.4545 18.2966ZM20.4545 14.0921C20.4545 13.869 20.366 13.6552 20.2082 13.4975C20.0505 13.3398 19.8367 13.2512 19.6136 13.2512H12.8864C12.6633 13.2512 12.4495 13.3398 12.2918 13.4975C12.134 13.6552 12.0455 13.869 12.0455 14.0921C12.0455 14.3151 12.134 14.529 12.2918 14.6867C12.4495 14.8444 12.6633 14.933 12.8864 14.933H19.6136C19.8367 14.933 20.0505 14.8444 20.2082 14.6867C20.366 14.529 20.4545 14.3151 20.4545 14.0921Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Docs
