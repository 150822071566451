import styled, { keyframes } from 'styled-components'

const animcircles = keyframes`
    0% {
        transform:translate(0px,0px);
    }
    100% {
        transform:translate(-40px,0px);
    }
`

const animtop = keyframes`
    0%, 100% {
        transform:rotate(0deg);
    }
    50% {
        transform:rotate(-45deg);
    }
`

const animbottom = keyframes`
    0%, 100% {
        transform:rotate(0deg);
    }
    50% {
        transform:rotate(45deg);
    }
`

export const Pacman = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 160px;
  transform: translate(-50%, -50%);

  & > div:first-child {
    position: absolute;
    left: -5px;
    top: 0;
    height: 60px;
    width: 180px;
    animation: ${animcircles} 0.25s infinite linear;

    //dots
    & > span {
      position: absolute;
      top: 25px;
      height: 12px;
      width: 12px;
      border-radius: 12px;
      background-color: ${props => props.theme.colors.pacmanLoader}b3;
      &:first-child {
        right: 80px;
      }
      &:nth-child(2) {
        right: 40px;
      }
      &:nth-child(3) {
        right: 0px;
      }
    }
  }
  & > div:last-child {
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
    width: 60px;
    //eye
    & > div {
      position: absolute;
      top: 10px;
      left: 30px;
      height: 7px;
      width: 7px;
      border-radius: 7px;
      background-color: #a1a1a1;
    }
    //head
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      height: 60px;
      width: 60px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        height: 30px;
        width: 60px;
        background-color: ${props => props.theme.colors.pacmanLoader};
      }
      &:first-child {
        animation: ${animtop} 1s infinite;
        &::before {
          top: 0;
          border-radius: 60px 60px 0px 0px;
        }
      }
      &:nth-child(2) {
        animation: ${animbottom} 1s infinite;
        &::before {
          bottom: 0;
          border-radius: 0px 0px 60px 60px;
        }
      }
      &:nth-child(3) {
        &::before {
          bottom: 0;
          height: 60px;
          width: 30px;
          border-radius: 60px 0px 0px 60px;
        }
      }
    }
  }
`
