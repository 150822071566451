import styled from 'styled-components'

export const Background = styled.div`
  background-image: url(/assets/transparent.png);
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DragNDrop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.settingField};
  border: 1px dashed ${props => props.theme.colors.borderColor};
  text-align: center;
  padding: 30px 0 29px;
  border-radius: 8px;
  position: relative;
  margin: 10px;

  & > svg {
    & path {
      stroke: ${props => props.theme.colors.darkText1};
    }
  }
`

export const DragNDropText = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  max-width: 136px;
  margin: 10px 0 9px;
`

export const DragNDropHint = styled.div`
  font-size: 10px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  max-width: 175px;
`

export const DragNDropInput = styled.input`
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`
