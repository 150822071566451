import styled from 'styled-components'

export const Colors = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
  overflow: auto;
`
export const PickColor = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  transition: ${props => props.theme.transition.primary};
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border: 1px dashed ${props => props.theme.colors.borderColor};
    box-sizing: border-box;
    border-radius: 16px;
    background: ${props => props.theme.colors.white};
    transition: ${props => props.theme.transition.primary};
  }
`

export const Separator = styled.div`
  height: 40px;
  width: 1.5px;
  min-width: 1.5px;
  background: ${props => props.theme.colors.settingField};
`

export const Color = styled.div<{ selected: boolean; color: string }>`
  display: flex;
  align-items: center;
  border: 2px solid ${props => (props.selected ? props.theme.colors.primaryText : 'transparent')};
  transition: ${props => props.theme.transition.primary};
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
  & > div {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    box-sizing: border-box;
    background: ${props => props.color};
    border-radius: 16px;
  }
`

export const SwitchColorWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: -220px;
  width: 300px;
  height: 240px;
  border-radius: 8px;
  background: ${props => props.theme.colors.modalBg};
  border: 1px solid ${props => props.theme.colors.borderColor};
`
