import React from 'react'
import { ISvg, Svg } from '../Svg'

const FolderColored: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4 10.3337C4 9.59734 4.59695 9.00039 5.33333 9.00039H18.6667C19.403 9.00039 20 9.59734 20 10.3337V17.6671C20 18.4034 19.403 19.0004 18.6667 19.0004H5.33333C4.59695 19.0004 4 18.4034 4 17.6671V10.3337Z"
      fill="#737BF8"
    />
    <path
      d="M5.33333 5C4.59695 5 4 5.59695 4 6.33333V8H14.0952L13.6096 5.96704C13.4461 5.39464 12.9229 5 12.3276 5H5.33333Z"
      fill="#737BF8"
    />
  </Svg>
)

export default FolderColored
