import React from 'react'

import * as styled from './Status.styled'

import { RecordStatus } from 'services/cms'

interface Props {
  status: RecordStatus | 'NOT DEPLOYED'
}

export const Status: React.FC<Props> = ({ status }) => <styled.Wrapper status={status}>{status}</styled.Wrapper>
