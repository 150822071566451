import React from 'react'
import { Svg, ISvg } from '../Svg'

const PricingTableCross: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" preserveAspectRatio="none" fill="none" {...props}>
    <path d="M14 6L6 14" stroke="#212133" strokeWidth="1.67" strokeLinecap="round" />
    <path d="M6 6L14 14" stroke="#212133" strokeWidth="1.67" strokeLinecap="round" />
  </Svg>
)

export default PricingTableCross
