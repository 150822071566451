import React from 'react'
import { ISvg, Svg } from '../Svg'

const Id: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="3.25" fill="#E5E5E6" />
    <path
      d="M5.89739 16C6.46087 16 6.79478 15.6682 6.79478 15.0928V6.90724C6.79478 6.33175 6.46087 6 5.89739 6C5.34087 6 5 6.33175 5 6.90724V15.0928C5 15.6682 5.34087 16 5.89739 16Z"
      fill="#7F899E"
    />
    <path
      d="M9.45913 15.8849H12.2278C15.233 15.8849 17 14.0704 17 10.9763C17 7.88219 15.2261 6.1151 12.2278 6.1151H9.45913C8.90261 6.1151 8.56174 6.45362 8.56174 7.02234V14.9777C8.56174 15.5464 8.90261 15.8849 9.45913 15.8849ZM10.3565 14.4089V7.59106H12.04C14.0435 7.59106 15.1704 8.79621 15.1704 10.9831C15.1704 13.2106 14.0574 14.4089 12.04 14.4089H10.3565Z"
      fill="#7F899E"
    />
    <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="3.25" stroke="#81899C" strokeWidth="1.5" />
  </Svg>
)

export default Id
