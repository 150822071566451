import React from 'react'
import { ISvg, Svg } from '../Svg'

const CheckMark: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M8 12L11 15L17 9"
      stroke={props.color || '#212133'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default CheckMark
