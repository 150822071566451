import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ItemText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${props => props.theme.colors.darkText};
  & b {
    color: ${props => props.theme.colors.grey1};
  }
`

export const ItemProgress = styled.div`
  height: 5px;
  border-radius: 100px;
  background: ${props => props.theme.colors.borderColor};
  & > div {
    height: 100%;
    border-radius: 100px;
    background: ${props => props.theme.colors.grey1};
  }
`
