import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import * as styled from './ApplicationItem.styled'

import { Button, ButtonColor, Icon, Image, Name, Switch, SwitchVariant } from 'components'
import { ProgressContext } from 'context'
import { Application, ApplicationId } from 'hooks'
import { ActivityItem, ActivityItemType, Build, Tags } from 'pages/Repository/components'
import { firestore } from 'services/firebase'

interface Props {
  application: Application
  iosIconUrl: string
  androidIconUrl: string
  hidePreview?: boolean
}

export const ApplicationItem: React.FC<Props> = ({ application, iosIconUrl, androidIconUrl, hidePreview }) => {
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const [build, setBuild] = useState<Build | null>(null)
  const isIos = application.id === ApplicationId.IOS
  const isAndroid = application.id === ApplicationId.ANDROID
  const isBackend = application.id === ApplicationId.BACKEND
  const isLanding = application.id === ApplicationId.LANDING
  const isWeb = application.id === ApplicationId.WEB

  useEffect(() => {
    const unsubscribe = firestore
      .collection(`projects/${id}/applications/${application.id}/builds`)
      .orderBy('build_number', 'desc')
      .limit(1)
      .onSnapshot({
        next: res => {
          const builds = res.docs.map(el => ({ id: el.id, ...el.data() })) as Build[]
          setBuild(builds[0])
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  const imageSrc =
    application.thumbnailUrl ||
    (isIos
      ? iosIconUrl
      : isAndroid
      ? androidIconUrl
      : isBackend
      ? '/assets/backend.png'
      : isLanding
      ? '/assets/landing.png'
      : isWeb
      ? '/assets/pwa.png'
      : '')
  const imageWidth = isIos || isAndroid || isBackend ? '90px' : '100%'
  const imageRadius = isIos ? '16px' : isAndroid ? '50%' : ''

  return (
    <styled.Application hidePreview={hidePreview}>
      <Box display="flex" flexWrap="wrap" gap="10px 30px">
        {!hidePreview && (
          <styled.ApplicationImage>
            <Image
              src={imageSrc}
              alt="application-logo"
              height={imageWidth}
              width={imageWidth}
              borderRadius={imageRadius}
            />
            <styled.ApplicationActions>
              {(isIos || isAndroid) && (
                <Link to={`/projects/${id}`}>
                  <Button color={ButtonColor.PICKER}>Edit in Visual Builder</Button>
                </Link>
              )}
              <Link to={`/projects/${id}/repositories/${application.id}/src`}>
                <Button color={ButtonColor.PICKER}>View Source Code</Button>
              </Link>
            </styled.ApplicationActions>
          </styled.ApplicationImage>
        )}
        <Box mt="13px" flex="1">
          <Box display="flex" alignItems="center">
            <styled.ApplicationName>
              <styled.ApplicationIcon>
                <Icon
                  name={
                    isIos
                      ? Name.REPOSITORY_IOS
                      : isAndroid
                      ? Name.REPOSITORY_ANDROID
                      : isLanding || isWeb
                      ? Name.REPOSITORY_LANDING
                      : Name.REPOSITORY_BACKEND
                  }
                  width={24}
                  height={24}
                />
              </styled.ApplicationIcon>
              <Link to={`/projects/${id}/repositories/${application.id}/src`}>{application.name}</Link>
            </styled.ApplicationName>
            <Tags tags={application.tags || []} />
            <Box ml="auto">
              <Switch variant={SwitchVariant.BIG} checked />
            </Box>
          </Box>
          <ActivityItem item={build} type={ActivityItemType.CUSTOM_BUILD} url={application.url} />
        </Box>
      </Box>
    </styled.Application>
  )
}
