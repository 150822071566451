import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { RemoveContent } from 'components'
import { ProgressContext } from 'context'
import { Workspace } from 'hooks'
import { WorkspacesService } from 'services/workspaces'

export const useWorkspacePlan = (
  workspace: Workspace | null,
  onChangePlanAlertClean?: () => void,
  redirectToAnotherPage?: boolean
) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const [wait, setWait] = useState(true)
  const [activeSubscription, setActiveSubscription] = useState<any>()
  const [plans, setPlans] = useState<any[]>([])
  const [priceIdToChange, setPriceIdToChange] = useState('')
  const [lastRefresh, setLastRefresh] = useState(+new Date())

  useEffect(() => {
    setWait(true)
    setActiveSubscription(null)
    setPlans([])
    setPriceIdToChange('')
    if (workspace) {
      const abortController = new AbortController()
      startLoader()
      Promise.all([
        WorkspacesService.getWorkspacePlans(workspace.id, abortController),
        WorkspacesService.getWorkspaceSubscriptions(workspace.id, abortController)
          .then(res => setActiveSubscription(res.data))
          .catch(err => err.statusCode !== 404 && !abortController.signal.aborted && toast(err)),
      ])
        .then(res => {
          const plans = res[0].data.sort((a: any, b: any) => +a.metadata.weight - +b.metadata.weight)
          return Promise.all(
            plans.map((el: any) =>
              WorkspacesService.getProductPrices(workspace.id, workspace.billingAccountId, el.id, abortController)
            )
          ).then(res => {
            res.forEach((el, i) => (plans[i].prices = el.data))
            setPlans(plans)
            setWait(false)
          })
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => stopLoader())
      return () => {
        abortController.abort()
      }
    }
  }, [workspace?.id, workspace?.billingAccountId, lastRefresh])

  const onPlanChange = wait
    ? undefined
    : (priceId: string, callback?: () => void) => {
        if (workspace) {
          const anotherPageUrl = `/workspaces/${workspace.id}/billing/plans`
          if (activeSubscription && !priceIdToChange) {
            setPriceIdToChange(priceId)
            return
          }
          startLoader()
          ;(activeSubscription
            ? WorkspacesService.updateWorkspaceSubscription(workspace.id, activeSubscription.id, priceId)
            : WorkspacesService.postWorkspaceSubscription(
                workspace.id,
                priceId,
                redirectToAnotherPage ? `${window.location.origin}${anotherPageUrl}` : window.location.href
              )
          )
            .then(res =>
              activeSubscription
                ? redirectToAnotherPage
                  ? navigate(anotherPageUrl)
                  : setLastRefresh(+new Date())
                : window.location.replace(res.data.url)
            )
            .catch(err => toast(err))
            .finally(() => {
              callback?.()
              stopLoader()
            })
        }
      }

  const ChangePlanAlert = activeSubscription ? (
    <RemoveContent
      toRemove={priceIdToChange}
      title="Change plan"
      text={
        priceIdToChange &&
        `You are about to change the plan from ${
          plans.find((el: any) => el.prices.find((a: any) => a.id === activeSubscription.plan.id))?.metadata.title
        } to ${plans.find((el: any) => el.prices.find((a: any) => a.id === priceIdToChange))?.metadata.title}`
      }
      clean={() => {
        onChangePlanAlertClean?.()
        setPriceIdToChange('')
      }}
      remove={callback => onPlanChange?.(priceIdToChange, callback)}
      blue
    />
  ) : null

  return { wait, activeSubscription, plans, onPlanChange, ChangePlanAlert }
}
