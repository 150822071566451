import React, { useState } from 'react'

import * as styled from '../../CustomPicker.styled'

import { Icon, Name, Rotation } from 'components'

interface Props {
  children?: React.ReactNode
  ItemsContent?: React.FC<{ search: string }>
  label?: string
  onBack: () => void
  hideLabel?: boolean
}

export const SubPicker: React.FC<Props> = ({ children, ItemsContent, label, onBack, hideLabel }) => {
  const [search, setSearch] = useState('')

  return (
    <>
      {!hideLabel && (
        <styled.ItemsHint pointer onClick={onBack}>
          <styled.Arrow>
            <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={Rotation.DOWN} width={16} height={16} />
          </styled.Arrow>
          {label || 'Back'}
        </styled.ItemsHint>
      )}
      {ItemsContent ? (
        <>
          <styled.Search
            inputEmpty={!search}
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <styled.Items>
            <ItemsContent search={search} />
          </styled.Items>
        </>
      ) : (
        children
      )}
    </>
  )
}
