import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './ProfileBtn.styled'

import { Avatar, Button, ButtonVariant, DropDown, Icon, Name, Rotation } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Permission, useOnClickOutside } from 'hooks'
import { auth, firestore } from 'services/firebase'

export const ProfileBtn: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { toast } = useContext(ProgressContext)
  const { project } = useContext(ProjectContext)
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [workspacePermissions, setWorkspacePermissions] = useState<Permission[]>([])
  const workspaceId = project.workspace.id
  const userId = auth.currentUser?.uid
  const userPhotoUrl = useMemo(() => permissions.find(el => el.id === userId)?.userPhotoUrl, [permissions, userId])
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    const unsubscribePerissions = firestore.collection(`projects/${id}/permissions`).onSnapshot({
      next: res => setPermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as Permission[]),
      error: err => toast(err),
    })
    const unsubscribeWorkspacePerissions = firestore.collection(`workspaces/${workspaceId}/permissions`).onSnapshot({
      next: res => setWorkspacePermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as Permission[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribePerissions()
      unsubscribeWorkspacePerissions()
    }
  }, [])

  const sections: { name: string; permissions: Permission[]; addMode?: boolean }[] = useMemo(
    () => [
      {
        name: 'PROJECT TEAMMATES',
        permissions,
      },
      {
        name: 'WORKSPACE TEAMMATES ',
        permissions: workspacePermissions.filter(a => !permissions.find(b => a.id === b.id)),
        addMode: true,
      },
    ],
    [permissions, workspacePermissions]
  )

  return (
    <div ref={ref}>
      <DropDown
        top="36px"
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <Button
            active
            variant={ButtonVariant.TOP_PANEL_ACTION}
            leftIcon={<Avatar width={22} bR={4} value={userPhotoUrl} />}
            rightIcon={<Icon name={Name.GLOBAL_ARROW_BUTTON} rotation={open ? Rotation.LEFT : undefined} />}
            onClick={() => setOpen(open => !open)}
          />
        }
      >
        <Box display="flex" flexDirection="column" gap="15px" p="0 15px" maxHeight="calc(100vh - 90px)" overflow="auto">
          {sections
            .filter(el => !!el.permissions.length)
            .map(section => (
              <styled.Section key={section.name}>
                <styled.SectionTitle>{section.name}</styled.SectionTitle>
                {section.permissions.map(el => (
                  <styled.User key={el.id}>
                    <Avatar width={24} bR={6} value={el.userPhotoUrl} />
                    <styled.UserName>
                      {el.userName} {el.id === userId && <i>(you)</i>}
                    </styled.UserName>
                    {section.addMode ? (
                      <styled.Add
                        onClick={() => {
                          navigate(`/projects/${id}/settings/teammates`)
                          setOpen(false)
                        }}
                      >
                        <Icon name={Name.ADDITIONAL_PLUS} />
                        add to project
                      </styled.Add>
                    ) : (
                      <styled.Role>{el.role}</styled.Role>
                    )}
                  </styled.User>
                ))}
              </styled.Section>
            ))}
          <Box mt="10px">
            <Button style={{ width: '100%' }} onClick={() => navigate(`/workspaces/${workspaceId}/teammates`)}>
              Manage workspace teammates
            </Button>
          </Box>
        </Box>
      </DropDown>
    </div>
  )
}
