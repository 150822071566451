import React from 'react'
import { ISvg, Svg } from '../Svg'

const FileColored: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15 8V5.315L17.73 8.5H15.5C15.3674 8.5 15.2402 8.44732 15.1464 8.35355C15.0527 8.25979 15 8.13261 15 8ZM15.5 9.5C15.1022 9.5 14.7206 9.34196 14.4393 9.06066C14.158 8.77936 14 8.39782 14 8V5H7.5C7.10218 5 6.72064 5.15804 6.43934 5.43934C6.15804 5.72064 6 6.10218 6 6.5V17.5C6 17.8978 6.15804 18.2794 6.43934 18.5607C6.72064 18.842 7.10218 19 7.5 19H16.5C16.8978 19 17.2794 18.842 17.5607 18.5607C17.842 18.2794 18 17.8978 18 17.5V9.5H15.5Z"
      fill="#7F899E"
    />
  </Svg>
)

export default FileColored
