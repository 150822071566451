import React from 'react'
import { ISvg, Svg } from '../Svg'

const Google: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8171 12.2231C21.8171 11.5269 21.7533 10.8575 21.6349 10.2148H12.1992V14.0127H17.5911C17.3588 15.24 16.6529 16.2798 15.5919 16.976V19.4395H18.8297C20.7241 17.7303 21.8171 15.2132 21.8171 12.2231Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.201 21.818C14.9061 21.818 17.1739 20.9388 18.8315 19.4393L15.5937 16.9758C14.6966 17.5649 13.549 17.913 12.201 17.913C9.59165 17.913 7.383 16.1859 6.59518 13.8652H3.24805V16.4091C4.89656 19.6178 8.28468 21.818 12.201 21.818Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59438 13.8656C6.39401 13.2765 6.28016 12.6472 6.28016 12.0001C6.28016 11.353 6.39401 10.7237 6.59438 10.1346V7.59082H3.24726C2.56872 8.91628 2.18164 10.4158 2.18164 12.0001C2.18164 13.5844 2.56872 15.0839 3.24726 16.4094L6.59438 13.8656Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.201 6.08663C13.672 6.08663 14.9926 6.582 16.0309 7.5549L18.9044 4.73885C17.1694 3.15454 14.9015 2.18164 12.201 2.18164C8.28468 2.18164 4.89656 4.38182 3.24805 7.5906L6.59517 10.1344C7.383 7.81375 9.59165 6.08663 12.201 6.08663Z"
      fill="#EA4335"
    />
  </Svg>
)

export default Google
