import React, { useState, useEffect } from 'react'

import logoImage from 'partials/LandingLayout/components/Header/images/logo-image.svg'
import logoText from 'partials/LandingLayout/components/Header/images/logo-text.svg'
import logoTextMobile from 'partials/LandingLayout/components/Header/images/logo-text-mobile.svg'
import { MENU_LIST_ITEMS } from './components/MenuListItem/constants'

import { Button, ButtonColor } from '../Button'

import {
  HeaderBox,
  FlexContainer,
  Logo,
  LogoImage,
  LogoText,
  LogoTextMobile,
  MenuBox,
  MenuList,
  ButtonBox,
  BurgerBox,
  Burger,
  BurgerOverlay,
  MobileButtonBox,
} from './Header.styled'
import { MenuListItem } from './components'
import Container from '../Container/Container'
import { landingUrl } from 'utils'

const Header: React.FC = () => {
  const [burger, setBurger] = useState<boolean>(false)
  const [shadow, setShadow] = useState<boolean>(false)
  const [zIndex, setZIndex] = useState<boolean>(false)

  useEffect(() => {
    if (burger) {
      document.body.classList.add('lock')
    } else {
      document.body.classList.remove('lock')
    }
  }, [burger])

  const burgerClick = () => {
    setBurger(!burger)
  }

  useEffect(() => {
    const manageShadow = () => {
      setShadow(window.scrollY > 0)
      setZIndex(window.scrollY > 0)
    }

    window.addEventListener('scroll', manageShadow)

    // return window.removeEventListener('scroll', manageShadow);
  }, [])

  return (
    <HeaderBox burgerActive={burger} shadow={shadow} zIndex={zIndex}>
      <Container>
        <FlexContainer>
          <Logo href={landingUrl}>
            <LogoImage src={logoImage} alt="logo image" />
            <LogoText src={logoText} alt="logo text" />
            <LogoTextMobile src={logoTextMobile} alt="logo text mobile" />
          </Logo>
          <MenuBox active={burger}>
            <MenuList active={burger}>
              {MENU_LIST_ITEMS.map(item => (
                <MenuListItem key={item.name} item={item} setBurger={setBurger} burger={burger} setZIndex={setZIndex} />
              ))}
            </MenuList>
            <MobileButtonBox>
              <Button href={`${landingUrl}/pricing`} height={36}>
                Start Building
              </Button>
              <Button color={ButtonColor.SECONDARY} href={`${landingUrl}/request-a-demo`} height={36}>
                Request a demo
              </Button>
            </MobileButtonBox>
          </MenuBox>
          <ButtonBox />
          <BurgerBox onClick={burgerClick} active={burger}>
            <Burger />
          </BurgerBox>
        </FlexContainer>
      </Container>
      <BurgerOverlay active={burger} />
    </HeaderBox>
  )
}

export default Header
