import React from 'react'
import { ISvg, Svg } from '../Svg'

const Desktop: React.FC<ISvg> = props => (
  <Svg viewBox="0 -960 960 960" fill="none" {...props}>
    <path
      d="M40-120v-80h880v80H40Zm120-120q-33 0-56.5-23.5T80-320v-440q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v440q0 33-23.5 56.5T800-240H160Zm0-80h640v-440H160v440Zm0 0v-440 440Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Desktop
