import React from 'react'

import { CustomPicker, Icon, Name, Variable } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { getVariableName, LocalVariable, Screen, VariableSource } from 'utils'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  valueDescription?: string
  source?: VariableSource
  onSourceChange: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  dataMode?: boolean
  disabled?: boolean
  insidePicker?: boolean
}

export const SourceInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  valueDescription,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  dataMode,
  disabled,
  insidePicker,
}) => (
  <SettingField
    big={big}
    small={small}
    label={label}
    subLabel={subLabel}
    insidePicker={insidePicker}
    value={source ? `${valueDescription || ''}${getVariableName(source)}` : undefined}
    leftIcon={insidePicker ? undefined : <Icon name={Name.RIGHT_SIDEBAR_DATA} />}
    disabled={disabled}
    optionsContainer={
      screenConfig && allLocalVariables ? (
        <CustomPicker
          title="Variables"
          close={() => {}}
          optionsContainer={
            <Variable
              value={source}
              onChange={onSourceChange}
              onRemove={() => onSourceChange()}
              close={() => {}}
              dataMode={dataMode}
              screenConfig={screenConfig}
              allLocalVariables={allLocalVariables}
            />
          }
        />
      ) : undefined
    }
    actions={
      source ? (
        <Icon
          name={Name.RIGHT_SIDEBAR_DELETE}
          onClick={() => {
            onSourceChange()
          }}
        />
      ) : undefined
    }
  />
)
