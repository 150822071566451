import { Box } from '@mui/material'

import { Button, ButtonColor, ButtonVariant } from 'components'

interface Props {
  tags: string[]
}

export const Tags: React.FC<Props> = ({ tags }) => (
  <Box display="flex" flexWrap="wrap" gap="10px 12px">
    {tags.map(el => (
      <Button
        key={el}
        variant={ButtonVariant.TAG}
        color={ButtonColor.TAG}
        style={{ borderRadius: '100px', cursor: 'default' }}
        disabled={true}
      >
        {el}
      </Button>
    ))}
  </Box>
)
