import React from 'react'
import { ISvg, Svg } from '../Svg'

const ProvidersPhone: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" stroke="#E1E3E1" />
    <rect x="14" y="11" width="12" height="18" rx="3" stroke="black" strokeWidth="2" />
    <rect x="17.5" y="11.5" width="5" height="2" rx="0.5" fill="black" stroke="black" />
  </Svg>
)

export default ProvidersPhone
