import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Icon, Loader, Name } from 'components'
import { ProgressContext } from 'context'
import { Task } from 'hooks'
import { ProjectsService } from 'services/projects'
import { IconWrapper } from './IconWrapper'

interface Props {
  task?: Task
}

export const CancellLoader: React.FC<Props> = ({ task }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [cancellMode, setCancellMode] = useState(false)
  const [wait, setWait] = useState(false)

  const cancellTask = (task: Task) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!wait) {
      setWait(true)
      startLoader()
      ProjectsService.cancellTask(id as string, task.id)
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  return task ? (
    <IconWrapper onMouseOver={() => setCancellMode(true)} onMouseLeave={() => setCancellMode(false)}>
      {cancellMode ? (
        <Icon name={Name.PICKERS_CANCELL_TASK} onClick={cancellTask(task)} width={16} height={16} />
      ) : (
        <Loader small />
      )}
    </IconWrapper>
  ) : (
    <Loader small />
  )
}
