import React from 'react'
import { ISvg, Svg } from '../Svg'

const TextStyleInverted: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M4.76172 14.6543C5.1543 14.6543 5.37695 14.4609 5.51758 14.0039L6.07422 12.3984H9.23242L9.78906 14.0156C9.92383 14.4668 10.1523 14.6543 10.5625 14.6543C11.002 14.6543 11.3184 14.3672 11.3184 13.957C11.3184 13.8223 11.2891 13.6875 11.2188 13.4941L8.75781 6.81445C8.54688 6.24609 8.20703 6 7.65039 6C7.09961 6 6.75977 6.25781 6.55469 6.82031L4.09961 13.4941C4.0293 13.6758 4 13.8398 4 13.957C4 14.3848 4.29883 14.6543 4.76172 14.6543ZM6.43164 11.2031L7.62109 7.59375H7.66211L8.86328 11.2031H6.43164Z"
      fill="#7F899E"
    />
    <path
      d="M14.0371 14.6543C14.8398 14.6543 15.6191 14.2383 15.9766 13.5645H16.0059V13.998C16.0293 14.4316 16.3047 14.6719 16.7031 14.6719C17.1133 14.6719 17.4121 14.4082 17.4121 13.9395V10.2773C17.4121 9.0293 16.4102 8.21484 14.8691 8.21484C13.6797 8.21484 12.7188 8.67188 12.4023 9.39258C12.3379 9.52734 12.3027 9.65625 12.3027 9.79102C12.3027 10.1426 12.5605 10.377 12.918 10.377C13.1641 10.377 13.3574 10.2832 13.5098 10.084C13.873 9.55078 14.2422 9.35742 14.8105 9.35742C15.5312 9.35742 15.9707 9.73242 15.9707 10.3828V10.8281L14.3242 10.9219C12.8125 11.0156 11.9629 11.6777 11.9629 12.7793C11.9629 13.8984 12.8359 14.6543 14.0371 14.6543ZM14.4824 13.5527C13.8496 13.5527 13.4219 13.2305 13.4219 12.7207C13.4219 12.2285 13.8262 11.9121 14.5293 11.8652L15.9707 11.7773V12.252C15.9707 13.002 15.3027 13.5527 14.4824 13.5527Z"
      fill="#7F899E"
    />
  </Svg>
)

export default TextStyleInverted
