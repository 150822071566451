import { Icon, Name, Rotation } from 'components'
import * as styled from './Menu.styled'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  left: boolean
  right: boolean
  bottom: boolean
}

export const OpenClose: React.FC<Props> = ({ open, setOpen, left, right, bottom }) => (
  <styled.OpenClose onClick={() => setOpen(!open)} left={left} right={right} bottom={bottom}>
    <Icon name={Name.ADDITIONAL_OPEN_NAV_MENU} rotation={right ? Rotation.LEFT : bottom ? Rotation.RIGHT : undefined} />
  </styled.OpenClose>
)
