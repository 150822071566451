import { Route } from 'react-router-dom'

import { CMS, Conversations, Monetization, Repositories, Repository, Settings, Studio } from 'pages'
import {
  Account,
  Billing,
  BillingAccount,
  Projects,
  Teammates,
  Templates,
  Usage,
  WorkspaceBilling,
  WorkspaceSettings,
} from 'pages/Projects/pages'
import { Activities, Builds, Code, Deployments, Logs, Settings as RepositorySettings } from 'pages/Repository/pages'
import {
  AppStore as SettingsAppStore,
  Appetize as SettingsAppetize,
  Authentication as SettingsAuthentication,
  Design as SettingsDesign,
  Domains as SettingsDomains,
  General as SettingsGeneral,
  Genkit as SettingsGenkit,
  Github as SettingsGithub,
  GooglePlay as SettingsGooglePlay,
  Languages as SettingsLanguages,
  Logic as SettingsLogic,
  Plans as SettingsPlans,
  Repositories as SettingsRepositories,
  Screenshots as SettingsScreenshots,
  Stripe as SettingsStripe,
  Teammates as SettingsTeammates,
  Usage as SettingsUsage,
} from 'pages/Settings/pages'
import { LogicItemRecord } from 'pages/Settings/pages/Logic/pages'
import { Icon, Name } from './components'

export const routes: IRoute[] = [
  {
    path: '/workspaces/:workspaceId',
    index: true,
    component: () => <Projects />,
    name: 'Projects',
    projectsPageIcon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_PROJECTS} />,
    childPath: '/workspaces/:workspaceId/templates',
  },
  {
    path: '/workspaces/:workspaceId/templates',
    component: () => <Templates />,
    name: 'Templates',
  },
  {
    path: '/workspaces/:workspaceId/teammates',
    component: () => <Teammates />,
    name: 'Teammates',
    projectsPageIcon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_TEAMMATES} />,
  },
  {
    path: '/workspaces/:workspaceId/usage',
    component: () => <Usage />,
    name: 'Usage',
    projectsPageIcon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_USAGE} />,
  },
  {
    path: '/workspaces/:workspaceId/billing',
    component: () => <Billing />,
    name: 'Billing',
    projectsPageIcon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_PLANS} />,
    innerRoutes: true,
    children: [
      {
        path: `/workspaces/:workspaceId/billing/:billingAccountId`,
        component: () => <BillingAccount />,
      },
      {
        path: `/workspaces/:workspaceId/billing/add`,
        component: () => <BillingAccount />,
      },
    ],
  },
  {
    path: '/workspaces/:workspaceId/billing/plans',
    component: () => <WorkspaceBilling />,
  },
  {
    path: '/workspaces/:workspaceId/billing/projects/:currentProjectId/plans',
    component: () => <SettingsPlans />,
  },
  {
    path: '/workspaces/:workspaceId/settings',
    component: () => <WorkspaceSettings />,
    name: 'Workspace settings',
    projectsPageIcon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_SETTINGS} />,
  },
  {
    path: '/projects/:id',
    component: () => <Studio />,
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_STUDIO} />,
    index: true,
    name: 'Visual builder',
  },
  {
    path: `/projects/:id/collections`,
    component: () => <CMS />,
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_CMS} />,
    innerRoutes: true,
    name: 'Collections',
  },
  {
    path: `/projects/:id/collections/*`,
    component: () => <CMS />,
  },
  {
    path: '/projects/:id/repositories',
    component: () => <Repositories />,
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_CODE} />,
    innerRoutes: true,
    name: 'Repositories',
  },
  {
    path: '/projects/:id/repositories/:repositoryId',
    component: () => <Repository />,
    children: [
      {
        path: '/projects/:id/repositories/:repositoryId/src',
        component: () => <Code />,
        icon: <Icon name={Name.REPOSITORY_CODE} />,
        name: 'Code',
        innerRoutes: true,
        children: [
          {
            path: '*',
            component: () => <Code />,
          },
        ],
      },
      {
        path: '/projects/:id/repositories/:repositoryId/deployments',
        component: () => <Deployments />,
        icon: <Icon name={Name.REPOSITORY_DEPLOYMENTS} />,
        name: 'Deployments',
      },
      {
        path: '/projects/:id/repositories/:repositoryId/builds',
        component: () => <Builds />,
        icon: <Icon name={Name.REPOSITORY_BUILDS} />,
        name: 'Builds',
        childPath: '/projects/:id/repositories/:repositoryId/builds/:buildNumber/logs',
      },
      {
        path: '/projects/:id/repositories/:repositoryId/builds/:buildNumber/logs',
        component: () => <Logs />,
      },
      {
        path: '/projects/:id/repositories/:repositoryId/activities',
        component: () => <Activities />,
        icon: <Icon name={Name.REPOSITORY_ACTIVITIES} />,
        name: 'Activities',
      },
      {
        path: '/projects/:id/repositories/:repositoryId/settings',
        component: () => <RepositorySettings />,
        icon: <Icon name={Name.REPOSITORY_SETTINGS} />,
        name: 'Settings',
      },
    ],
  },
  {
    path: '/projects/:id/monetization',
    component: () => <Monetization />,
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_ANALYTICS} />,
    innerRoutes: true,
    name: 'Monetization',
  },
  {
    path: `/projects/:id/monetization/*`,
    component: () => <Monetization />,
  },
  {
    path: '/projects/:id/conversations',
    component: () => <Conversations />,
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_CONVERSATIONS} />,
    innerRoutes: true,
    name: 'Conversations',
  },
  {
    path: '/projects/:id/settings',
    component: () => <Settings />,
    icon: <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_SETTINGS} />,
    innerRoutes: true,
    name: 'Settings',
    children: [
      {
        path: '/projects/:id/settings',
        component: () => <SettingsGeneral />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_GENERAL} />,
        name: 'General',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/design',
        component: () => <SettingsDesign />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_DESIGN} />,
        name: 'Design',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/authentication',
        component: () => <SettingsAuthentication />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_AUTHENTICATION} />,
        name: 'Users & Authentication',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/languages',
        component: () => <SettingsLanguages />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_LANGUAGES} />,
        name: 'Languages',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/screenshots',
        component: () => <SettingsScreenshots />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_SCREENSHOTS} />,
        name: 'Screenshots',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/domains',
        component: () => <SettingsDomains />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_DOMAINS} />,
        name: 'Domains',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/repositories',
        component: () => <SettingsRepositories />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_REPOSITORIES} />,
        name: 'Repositories',
        parent: 'App',
      },
      {
        path: '/projects/:id/settings/logic',
        component: () => <SettingsLogic />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_LOGIC} />,
        name: 'Logic',
        parent: 'App',
        children: [
          {
            path: `/projects/:id/settings/logic/flows/add`,
            component: () => <LogicItemRecord name="flows" />,
          },
          {
            path: `/projects/:id/settings/logic/flows/:logicItemRecordId`,
            component: () => <LogicItemRecord name="flows" />,
          },
          {
            path: `/projects/:id/settings/logic/prompts/add`,
            component: () => <LogicItemRecord name="prompts" />,
          },
          {
            path: `/projects/:id/settings/logic/prompts/:logicItemRecordId`,
            component: () => <LogicItemRecord name="prompts" />,
          },
        ],
      },
      {
        path: '/projects/:id/settings/appetize',
        component: () => <SettingsAppetize />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_APPETIZE} />,
        name: 'Appetize',
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/app-store',
        component: () => <SettingsAppStore />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_APP_STORE} />,
        name: 'App Store',
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/google-play',
        component: () => <SettingsGooglePlay />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_GOOGLE_PLAY} />,
        name: 'Google Play',
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/stripe',
        component: () => <SettingsStripe />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_STRIPE} />,
        name: 'Stripe',
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/genkit',
        component: () => <SettingsGenkit />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_GENKIT} />,
        name: 'Genkit',
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/github',
        component: () => <SettingsGithub />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_GITHUB} />,
        name: 'Github',
        parent: 'Integrations',
      },
      {
        path: '/projects/:id/settings/usage',
        component: () => <SettingsUsage />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_USAGE} />,
        name: 'Usage',
        parent: 'Project',
      },
      {
        path: '/projects/:id/settings/plans',
        component: () => <SettingsPlans />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_PLANS} />,
        name: 'Plans',
        parent: 'Project',
      },
      {
        path: '/projects/:id/settings/teammates',
        component: () => <SettingsTeammates />,
        icon: <Icon name={Name.LEFT_SIDEBAR_PROJECT_TEAMMATES} />,
        name: 'Teammates',
        parent: 'Project',
      },
    ],
  },
  {
    path: '/account',
    component: () => <Account />,
    name: 'Account settings',
  },
]

export interface IRoute {
  path: string
  component: () => JSX.Element
  children?: IRoute[]
  icon?: JSX.Element
  projectsPageIcon?: JSX.Element
  index?: boolean
  name?: string
  innerRoutes?: boolean
  childPath?: string
  parent?: string
}

const getRoutesWithClildren = (routes: IRoute[]) =>
  routes.map(route => (
    <Route key={route.path} path={route.path} element={route.component()} index={route.index}>
      {route.children && getRoutesWithClildren(route.children)}
    </Route>
  ))

export const getRoutes = () => getRoutesWithClildren(routes)
