import React, { useContext, useEffect, useState } from 'react'

import * as styled from './Templates.styled'

import { ProgressContext } from 'context'
import { ITemplate } from 'context/types'
import { MessageBeforeSend, TemplatesKeyboardPayload } from 'services/conversations'
import firebase, { firestore } from 'services/firebase'
import { Pick } from '../components'

interface Props {
  payload: TemplatesKeyboardPayload
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
  setTemplateId: React.Dispatch<React.SetStateAction<string | null>>
  templateId?: string
}

export const Templates: React.FC<Props> = ({
  payload,
  sendMessage,
  setOnClickActionButton,
  setTemplateId,
  templateId,
}) => {
  const { toast } = useContext(ProgressContext)
  const [templates, setTemplates] = useState<ITemplate[]>([])
  const [selected, setSelected] = useState<ITemplate | null>(null)

  useEffect(() => {
    if (payload.length) {
      const unsubscribe = firestore
        .collection('templates')
        .where(
          firebase.firestore.FieldPath.documentId(),
          'in',
          payload.map(el => el.templateId)
        )
        .onSnapshot({
          next: res => {
            const templates = res.docs.map(el => ({ id: el.id, ...el.data() })) as ITemplate[]
            templates.sort(
              (a, b) =>
                payload.findIndex(el => el.templateId === a.id) - payload.findIndex(el => el.templateId === b.id)
            )
            setTemplates(templates)
            setSelected(selected => (!selected ? templates.find(el => el.id === templateId) || null : selected))
          },
          error: err => toast(err),
        })
      return () => {
        unsubscribe()
      }
    }
  }, [])

  useEffect(() => {
    setTemplateId(selected ? selected.id : null)
    setOnClickActionButton(() =>
      selected
        ? () => sendMessage({ body: selected.title, format: 'text', templateAttachment: { templateId: selected.id } })
        : null
    )
  }, [selected])

  return (
    <styled.Templates>
      {templates.map(el => (
        <styled.Template key={el.id} onClick={() => setSelected(selected => (el.id === selected?.id ? null : el))}>
          <styled.TemplateImage src={el.studioImage?.url} alt="studioImageUrl" />
          <Pick selected={el.id === selected?.id}>
            <i />
            {el.title}
          </Pick>
        </styled.Template>
      ))}
      {/* {!!templates.length && (
        <styled.ExploreTemplates href={`${landingUrl}/templates`} target="_blank" rel="noreferrer">
          Explore Templates
          <Icon name={Name.REPOSITORY_LINK} />
        </styled.ExploreTemplates>
      )} */}
    </styled.Templates>
  )
}
