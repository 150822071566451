import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Repositories: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M3 6L1 8L3 10" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 10L15 8L13 6" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.08497 2.99957L6.53736 13.0235" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)
