import { Box } from '@mui/material'
import React from 'react'

import * as styled from './ScreenName.styled'

import { Icon, Name } from 'components'

interface Props {
  screenName: string
  isScreenActive: boolean
  isComponentOnScreenSelected: boolean
}

export const ScreenName: React.FC<Props> = ({ screenName, isScreenActive, isComponentOnScreenSelected }) => (
  <styled.ScreenName
    y={-50}
    width="100%"
    height="36px"
    isScreenActive={isScreenActive}
    isComponentOnScreenSelected={isComponentOnScreenSelected}
  >
    <Box display="flex" borderRadius="4px" overflow="hidden" gap="1px">
      <Box width="100%">
        <Icon name={Name.ADDITIONAL_SCREEN} width={24} height={24} />
        {screenName}
      </Box>
    </Box>
  </styled.ScreenName>
)
