import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CustomSection, CustomSectionTitle, CustomSectionsWrapper, InputProperty, Text, Type, inputs } from 'components'
import { ProgressContext } from 'context'
import { database } from 'services/firebase'
import { ValueType } from 'utils'
import { SettingsLayout } from '../components'

const properties: InputProperty[] = [
  {
    name: 'screenshots',
    type: ValueType.screenshots,
    position: 0,
    title: 'App Screenshots',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
  },
]

export const Screenshots: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [configWait, setConfigWait] = useState(true)
  const configRef = database.ref(`projects/${id}/configurations/storemanager`)

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    onSubmit: () => {},
  })

  useEffect(() => {
    startLoader()
    configRef.on('value', snapshot => {
      const state = snapshot.val()
      if (state) {
        console.log('GET STOREMANAGER CONFIG')
        formik.setValues({ screenshots: state.screenshots || null })
        setConfigWait(false)
      } else {
        toast('No storemanager config found')
      }
      stopLoader()
    })
    return () => {
      configRef.off('value')
    }
  }, [])

  return (
    <SettingsLayout>
      <Text type={Type.TITLE}>Screenshots</Text>
      <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
        Create panoramic, studio-quality screenshots for your iOS and Android apps.
      </Text>
      {configWait ? (
        <></>
      ) : (
        <CustomSectionsWrapper>
          <CustomSection>
            <CustomSectionTitle mb="-5px">App Screenshots</CustomSectionTitle>
            {inputs(properties, formik)}
          </CustomSection>
        </CustomSectionsWrapper>
      )}
    </SettingsLayout>
  )
}
