import styled from 'styled-components'

export const Path = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 -10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  & b {
    margin-left: 4px;
    color: ${props => props.theme.colors.darkText};
  }
  * {
    white-space: nowrap;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  background: ${props => props.theme.colors.settingField};
  border-radius: 8px;
  padding: 4px;
  margin-right: 10px;
  & svg {
    & g {
      opacity: 1;
    }
    & path,
    & rect {
      &[stroke] {
        stroke: ${props => props.theme.colors.darkText};
      }
      &[fill] {
        fill: ${props => props.theme.colors.darkText};
      }
    }
  }
`
