import styled from 'styled-components'

export const RightIcon = styled.div<{ smallWidth?: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${props => (props.smallWidth ? 'calc(576px - 24px)' : 'calc(100% - 24px)')};
  bottom: 12px;
  & > svg {
    min-width: 16px;
    min-height: 16px;
  }
  &:hover > svg path {
    stroke: ${props => props.theme.colors.iconHover};
  }
`
