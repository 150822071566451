import React from 'react'

import { Name } from 'components'
import { SwitchedEdit } from '../../components'

export const Plugins: React.FC = () => (
  <SwitchedEdit
    title="Plugins"
    subTitle="Manage plugins to extend your application’s capabilities."
    items={[
      {
        id: '0',
        title: 'Dotprompt',
        subTitle: 'Incorporate Dotprompt for streamlined prompt engineering and management.',
        iconName: Name.SECOND_ORDER_PLUGINS_DROPROMPT,
        checked: false,
      },
      {
        id: '1',
        title: 'LangChain',
        subTitle: 'Enable LangChain to build applications powered by language models like GPT-4.',
        iconName: Name.SECOND_ORDER_PLUGINS_LANGCHAIN,
        checked: false,
      },
    ]}
    properties={[]}
  />
)
