import { memo, useEffect, useState } from 'react'
import { usePicker } from '../context'
import { barWrap, barWrapInner, cResize, handle, opacityBg, opacityOverlay, psRl } from '../style'

export const Opacity = memo(() => {
  const { handleOpacity, opacity, tinyColor, squareSize } = usePicker()
  const [dragging, setDragging] = useState(false)
  const { r, g, b } = tinyColor.toRgb()
  const bg = `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(${r},${g},${b},.5) 100%)`

  const stopDragging = () => {
    setDragging(false)
  }

  const handleMove = e => {
    if (dragging) {
      handleOpacity(e)
    }
  }

  const onMouseDown = e => {
    setDragging(true)
    handleOpacity(e)
  }

  useEffect(() => {
    if (dragging) {
      document.body.onmouseup = stopDragging
      return () => {
        document.body.onmouseup = () => {}
      }
    }
  }, [dragging])

  let left = squareSize - 18

  return (
    <div style={{ ...barWrap, marginTop: 6, width: squareSize + 36 }}>
      <div style={{ ...barWrapInner, width: squareSize + 30 }}>
        <div style={{ ...cResize, ...psRl }} onMouseDown={onMouseDown} onMouseMove={handleMove}>
          <div style={{ left: left * opacity, top: -2, ...handle }} />
          <div style={{ background: bg, ...opacityOverlay }} />
          <div style={opacityBg} />
        </div>
      </div>
    </div>
  )
})
