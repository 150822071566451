import React from 'react'
import { ISvg, Svg } from '../Svg'

const BuildPending: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <g clipPath="url(#clip0_14172_35817)">
      <circle cx="20" cy="20" r="19" fill="white" stroke="#737BF8" strokeWidth="2" />
      <path d="M20 8V20L28 23" stroke="#737BF8" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_14172_35817">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default BuildPending
