import React from 'react'
import { ISvg, Svg } from '../Svg'

const SwitchToALargerScreen: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 230 180" fill="none" {...props}>
    <path
      d="M90 165H199C207.837 165 215 157.837 215 149V31C215 22.1634 207.837 15 199 15H31C22.1634 15 15 22.1634 15 31V75M157 40H190V73M186 44L157 73M31 165H56C64.8366 165 72 157.837 72 149V106C72 97.1634 64.8366 90 56 90H31C22.1634 90 15 97.1634 15 106V149C15 157.837 22.1634 165 31 165Z"
      stroke="#917F9E"
      strokeWidth="8"
      strokeLinecap="round"
    />
  </Svg>
)
export default SwitchToALargerScreen
