import React from 'react'
import { ISvg, Svg } from '../Svg'

const Carousel: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="8.75" y="6.75" width="14.5" height="18.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M9 9H7C5.89543 9 5 9.89543 5 11V21C5 22.1046 5.89543 23 7 23H9" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M23 9H25C26.1046 9 27 9.89543 27 11V21C27 22.1046 26.1046 23 25 23H23"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
  </Svg>
)

export default Carousel
