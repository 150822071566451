import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AIModal } from 'components'
import { WebappLayout } from 'partials'

export const AdminConversations: React.FC = () => {
  const navigate = useNavigate()

  const close = () => navigate('/')

  return (
    <WebappLayout>
      <AIModal active close={close} adminMode />
    </WebappLayout>
  )
}
