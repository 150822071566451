import React from 'react'
import { ISvg, Svg } from '../Svg'

const Plus: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M12 18L12 6" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M18 12L6 12" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Plus
