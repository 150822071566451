import React from 'react'
import { ISvg, Svg } from '../Svg'

const ProvidersGoogle: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" stroke="#E1E3E1" />
    <path
      d="M28.3635 29.0959C31.2638 26.4497 32.5528 22.0393 31.7794 17.8179H20.2429V22.4803H26.8168C26.559 23.9924 25.6566 25.2525 24.3677 26.0716L28.3635 29.0959Z"
      fill="#4285F4"
    />
    <path
      d="M9.2865 25.3788C9.39211 25.582 9.50346 25.7822 9.62055 25.9793C9.73772 26.1765 9.86046 26.3702 9.98875 26.5606C10.1171 26.7509 10.2508 26.9376 10.39 27.1205C10.5291 27.3035 10.6733 27.4824 10.8229 27.6574C10.9723 27.8323 11.1267 28.0031 11.2861 28.1695C11.4454 28.3359 11.6095 28.4978 11.7782 28.6552C11.947 28.8125 12.1201 28.9651 12.2976 29.113C12.4751 29.2608 12.6567 29.4035 12.8426 29.5413C13.0284 29.6791 13.218 29.8116 13.4114 29.9389C13.6049 30.0661 13.8019 30.1879 14.0024 30.3043C14.2029 30.4207 14.4066 30.5315 14.6135 30.6367C14.8204 30.7418 15.0302 30.8412 15.2429 30.9347C15.4555 31.0282 15.6707 31.1158 15.8885 31.1974C16.1062 31.2791 16.3261 31.3547 16.5481 31.4242C16.7702 31.4937 16.9942 31.557 17.2199 31.6141C17.4457 31.6712 17.6728 31.7221 17.9016 31.7667C18.1303 31.8112 18.36 31.8494 18.591 31.8813C18.8218 31.9131 19.0535 31.9387 19.2859 31.9577C19.5183 31.9767 19.751 31.9894 19.9841 31.9955C20.2172 32.0018 20.4503 32.0015 20.6834 31.9948C20.9165 31.9882 21.1492 31.975 21.3815 31.9555C21.6138 31.9359 21.8454 31.91 22.0763 31.8777C22.3071 31.8453 22.5368 31.8066 22.7654 31.7615C22.994 31.7165 23.2211 31.6652 23.4467 31.6076C23.6724 31.55 23.8961 31.4862 24.1181 31.4163C24.34 31.3463 24.5597 31.2703 24.7773 31.1882C24.9948 31.106 25.2098 31.018 25.4223 30.924C25.6348 30.83 25.8443 30.7303 26.051 30.6247C26.2577 30.5192 26.4611 30.4079 26.6614 30.2911C26.8616 30.1743 27.0583 30.052 27.2515 29.9244C27.4447 29.7967 27.6341 29.6637 27.8195 29.5256C28.005 29.3875 28.1864 29.2443 28.3636 29.0962L24.3677 26.0719C20.9519 28.277 15.2804 27.458 13.3468 22.2915L9.2865 25.3788Z"
      fill="#34A853"
    />
    <path
      d="M13.3468 22.2917C12.8312 20.7165 12.8312 19.2674 13.3468 17.6923L9.28646 14.605C7.80413 17.5033 7.353 21.5986 9.28646 25.379L13.3468 22.2917Z"
      fill="#FBBC02"
    />
    <path
      d="M13.3468 17.6921C14.7648 13.3447 20.823 10.8245 24.8833 14.5418L28.428 11.1395C23.401 6.4141 13.6047 6.60306 9.2865 14.6048L13.3468 17.6921Z"
      fill="#EA4335"
    />
  </Svg>
)

export default ProvidersGoogle
