import React from 'react'
import { ISvg, Svg } from '../Svg'

const Checkbox: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="1.5" width="13" height="13" rx="2.3" stroke="#7F899E" />
    <path d="M5 7.5L7.625 10L12 5" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Checkbox
