import React from 'react'
import { ISvg, Svg } from '../Svg'

const BuildFailed: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <g clipPath="url(#clip0_14172_35789)">
      <circle cx="20" cy="20" r="19" fill="white" stroke="#F94549" strokeWidth="2" />
      <path d="M14 14L26 26" stroke="#F94549" strokeWidth="2" strokeLinecap="round" />
      <path d="M26 14L14 26" stroke="#F94549" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_14172_35789">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default BuildFailed
