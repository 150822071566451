import React from 'react'
import { ISvg, Svg } from '../Svg'

const DropDownArrow: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7803 6.21639C11.0732 6.50491 11.0732 6.97269 10.7803 7.2612L8 10L5.21967 7.2612C4.92678 6.97269 4.92678 6.50491 5.21967 6.21639C5.51256 5.92787 5.98744 5.92787 6.28033 6.21639L8 7.91037L9.71967 6.21639C10.0126 5.92787 10.4874 5.92787 10.7803 6.21639Z"
      fill="#212133"
    />
  </Svg>
)

export default DropDownArrow
