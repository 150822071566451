import React from 'react'
import { ISvg, Svg } from '../Svg'

const Video: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M10.6768 7.74523C10.8625 7.86433 10.8625 8.13567 10.6768 8.25477L6.21613 11.1163C6.01467 11.2456 5.75 11.1009 5.75 10.8615L5.75 5.13846C5.75 4.89911 6.01467 4.75445 6.21613 4.88368L10.6768 7.74523Z"
      stroke="#7F899E"
    />
    <rect x="1.5" y="1.5" width="13" height="13" rx="2.3" stroke="#7F899E" />
  </Svg>
)

export default Video
