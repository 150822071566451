import React from 'react'
import { ISvg, Svg } from '../Svg'

const Download: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M8 2V10" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4 7L8 11L12 7" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3 14L13 14" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Download
