import React from 'react'
import { ISvg, Svg } from '../Svg'

const ImageCircle: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="4.75" y="4.75" width="22.5" height="22.5" rx="11.25" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M7 22L10.9639 18.2447C11.4052 17.8267 12.0959 17.8256 12.5385 18.2421L13.9548 19.5752C14.4289 20.0215 15.179 19.9839 15.6062 19.4925L20.312 14.0791C20.7933 13.5253 21.6637 13.5598 22.0997 14.1499L26 19.4286"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <circle cx="11.8668" cy="11.8668" r="2.11675" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default ImageCircle
