import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Usage: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.70828 0C3.8955 0 0 3.89484 0 8.70828C0 8.96741 0.210063 9.17747 0.469187 9.17747H8.70828C8.96741 9.17747 9.17747 8.96741 9.17747 8.70828V0.469187C9.17747 0.210063 8.96741 0 8.70828 0ZM5.37019 10.5339C5.32976 10.4601 5.27024 10.3986 5.19785 10.3557C5.12545 10.3128 5.04286 10.2902 4.95872 10.2902H2.18391C2.11026 10.2902 2.03766 10.3075 1.97196 10.3408C1.90626 10.3741 1.84932 10.4224 1.80574 10.4817C1.76216 10.5411 1.73316 10.6099 1.72109 10.6825C1.70903 10.7552 1.71423 10.8296 1.73628 10.8999C2.62 13.7146 5.16894 15.8072 8.23909 16V12.4831C7.64067 12.4087 7.06849 12.1932 6.56969 11.8543C6.07089 11.5154 5.65977 11.0629 5.37019 10.5339ZM10.8999 1.73631C10.8296 1.71424 10.7552 1.70902 10.6825 1.72108C10.6098 1.73314 10.541 1.76214 10.4817 1.80573C10.4223 1.84932 10.374 1.90627 10.3408 1.97198C10.3075 2.03769 10.2902 2.11032 10.2902 2.18397V4.95875C10.2902 5.04288 10.3128 5.12547 10.3557 5.19785C10.3986 5.27024 10.4602 5.32976 10.5339 5.37019C11.0629 5.65979 11.5154 6.07092 11.8543 6.56971C12.1932 7.06851 12.4087 7.64068 12.4831 8.23909H16C15.8067 5.16228 13.7079 2.61797 10.8999 1.73631ZM12.4837 9.17744C12.2708 10.9008 10.9008 12.2708 9.17747 12.4836V15.9996C12.8385 15.7692 15.7689 12.8435 15.9996 9.17744H12.4837Z"
      fill="#7F899E"
    />
  </Svg>
)
