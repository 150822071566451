import React from 'react'
import { ISvg, Svg } from '../Svg'

const ImageLeft: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M12 12L12 11.1111L12 4" fill="#A1AFBE" />
    <path d="M12 12L12 11.1111L12 4" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect width="4" height="4" rx="2" transform="matrix(-1 0 0 1 8 6)" fill="#A1AFBE" />
  </Svg>
)

export default ImageLeft
