import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'
import * as styled from '../../GithubConnectionModal/GithubConnectionModal.styled'

import { Button, ButtonColor, ButtonSize, Loader, LoaderVariant, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { useInputAutoFocusRef } from 'hooks'
import { ProjectsService } from 'services/projects'
import { Terms } from './Terms'

interface Props {
  token?: string
  close: () => void
}

export const Code: React.FC<Props> = ({ token, close }) => {
  const themeContext = useContext(ThemeContext)
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const inputAutoFocusElement = useInputAutoFocusRef(true)
  const [otp, setOtp] = useState('')
  const [wait, setWait] = useState(false)

  const updateAppstoreSession = () => {
    if (otp && token) {
      setWait(true)
      ProjectsService.updateAppstoreSession(id as string, token, otp)
        .catch(err => toast(err))
        .finally(() => setWait(false))
    }
  }

  return wait ? (
    <Box height="388px">
      <Loader variant={LoaderVariant.PACMAN} />
    </Box>
  ) : (
    <>
      <styled.Content>
        <styled.SubTitle>
          Apple requested two-factor verification. You may see a push prompt on your Apple device from an unfamiliar
          location - not to worry, this is from CodePlatform. Accept the prompt and enter the verification code below.
          <a href="/" target="_blank" rel="noreferrer">
            Why Do I Need To Do This?
          </a>
        </styled.SubTitle>
        <Box>
          <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
            Verification Code
          </Text>
          <InputWrapper inputEmpty={!otp}>
            <input value={otp} onChange={e => setOtp(e.target.value)} ref={inputAutoFocusElement} />
          </InputWrapper>
        </Box>
        <Terms mt="48px" />
      </styled.Content>
      <styled.Actions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close} style={{ minWidth: '90px' }}>
          Cancel
        </Button>
        <Button size={ButtonSize.DEFAULT} onClick={updateAppstoreSession} disabled={!otp} style={{ minWidth: '90px' }}>
          Continue
        </Button>
      </styled.Actions>
    </>
  )
}
