import React from 'react'

import * as styled from './Id.styled'

import { Icon, Name } from 'components'
import { SettingField } from 'partials/RightSidebar/components'

interface Props {
  value: string
}

export const Id: React.FC<Props> = ({ value }) => (
  <styled.IconWrapper>
    <SettingField
      value={value}
      leftIcon={<Icon name={Name.RIGHT_SIDEBAR_ID} />}
      actions={<Icon name={Name.RIGHT_SIDEBAR_COPY} onClick={() => navigator.clipboard.writeText(value)} />}
    />
  </styled.IconWrapper>
)
