import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './About.styled'

import { Icon, Name } from 'components'
import { ApplicationContext, ProgressContext } from 'context'
import { ActivityItemSmall, ActivityItemType, Build, Tags } from 'pages/Repository/components'
import { firestore } from 'services/firebase'

interface Props {
  scrollToReadme: () => void
}

export const About: React.FC<Props> = ({ scrollToReadme }) => {
  const { id, repositoryId } = useParams()
  const navigate = useNavigate()
  const { application } = useContext(ApplicationContext)
  const { toast } = useContext(ProgressContext)
  const [builds, setBuilds] = useState<Build[]>([])

  useEffect(() => {
    const unsubscribe = firestore
      .collection(`projects/${id}/applications/${repositoryId}/builds`)
      .orderBy('build_number', 'desc')
      .limit(3)
      .onSnapshot({
        next: res => setBuilds(res.docs.map(el => ({ id: el.id, ...el.data() })) as Build[]),
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <styled.About>
      <h2>About</h2>
      <p>{application?.description}</p>
      <span onClick={scrollToReadme}>
        <Icon name={Name.REPOSITORY_README} />
        Readme
      </span>
      <styled.Tags>
        <Tags tags={application?.tags || []} />
      </styled.Tags>
      {!!builds.length && (
        <Box mt="15px" display="flex" flexDirection="column" gap="5px">
          <h2>Builds</h2>
          {builds.map(el => (
            <ActivityItemSmall
              key={el.id}
              item={el}
              type={ActivityItemType.BUILD}
              onClick={() => navigate(`/projects/${id}/repositories/${repositoryId}/builds/${el.build_number}/logs`)}
            />
          ))}
        </Box>
      )}
    </styled.About>
  )
}
