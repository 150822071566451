import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 90px;
  height: 100vh;
  overflow: hidden scroll;
  min-width: 768px;
  background-color: ${props => props.theme.colors.secondaryBackground};
`

export const MaxWidthContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  & > p {
    max-width: 740px;
  }
`
