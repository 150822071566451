import React from 'react'
import {
  FlexboxProps,
  HeightProps,
  LayoutProps,
  SpaceProps,
  WidthProps,
  PositionProps,
  BorderProps,
  TypographyProps,
} from 'styled-system'
import * as styled from './Text.styled'

export enum Type {
  HUGE_TITLE = 'hugeTitle',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  BODY = 'body',
  H6 = 'h6',
  TITLE = 'title',
  SUB_TITLE = 'subTitle',
}

interface Props
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    WidthProps,
    HeightProps,
    PositionProps,
    BorderProps,
    TypographyProps {
  color?: string
  styles?: React.CSSProperties
  hover?: string
  type: Type
  as?: string | React.ComponentType<any>
  inherit?: boolean
  children?: any
  onClick?: () => void
}

export const Text: React.FC<Props> = ({ color, as, styles, hover, type, inherit, children, ...props }) => {
  switch (type) {
    case Type.HUGE_TITLE:
      return (
        <styled.HugeTitle color={color} style={styles} as={as} {...props}>
          {children}
        </styled.HugeTitle>
      )
    case Type.H1:
      return (
        <styled.H1 color={color} style={styles} as={as} {...props}>
          {children}
        </styled.H1>
      )
    case Type.H2:
      return (
        <styled.H2 color={color} style={styles} as={as} {...props}>
          {children}
        </styled.H2>
      )
    case Type.H3:
      return (
        <styled.H3 color={color} style={styles} as={as} hover={hover} {...props}>
          {children}
        </styled.H3>
      )
    case Type.H4:
      return (
        <styled.H4 color={color} style={styles} as={as} {...props}>
          {children}
        </styled.H4>
      )
    case Type.BODY:
      return (
        <styled.Body color={color} style={styles} as={as} inherit={inherit} {...props}>
          {children}
        </styled.Body>
      )
    case Type.H6:
      return (
        <styled.H6 color={color} style={styles} as={as} {...props}>
          {children}
        </styled.H6>
      )
    case Type.TITLE:
      return (
        <styled.Title style={styles} color={color}>
          {children}
        </styled.Title>
      )
    case Type.SUB_TITLE:
      return (
        <styled.SubTitle style={styles} color={color}>
          {children}
        </styled.SubTitle>
      )
    default:
      return null
  }
}

Text.defaultProps = {
  type: Type.HUGE_TITLE,
}
