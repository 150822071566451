import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CustomSection, CustomSectionActions, CustomSectionTitle, InputProperty, inputs, validate } from 'components'
import { ProjectContext } from 'context'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'name',
    type: ValueType.string,
    position: 0,
    helpText: 'The name of your project.',
    smallWidth: true,
  },
  {
    name: 'id',
    type: ValueType.string,
    position: 1,
    title: 'Project ID',
    helpText:
      'The unique identifier for your project. This identifier may appear in URLs or names for some  resources.',
    smallWidth: true,
    disabled: true,
  },
]

export const Project: React.FC = () => {
  const { project } = useContext(ProjectContext)
  const { id } = useParams()
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const name = project.name

  const formik = useFormik({
    initialValues: { name, id } as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  useEffect(() => {
    formik.setValues({ name, id })
    setValuesBeforeEdit(JSON.stringify({ name, id }))
  }, [name, id])

  const cancel = () => {
    const { name, id } = JSON.parse(valuesBeforeEdit)
    formik.setValues({ name, id })
  }

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions}>
      <CustomSectionTitle mb="-5px">Project</CustomSectionTitle>
      {inputs(properties, formik)}
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.submitForm} />}
    </CustomSection>
  )
}
