import { useEffect, useState } from 'react'

import { LayoutContext } from 'context'
import { ThemeType } from 'theme'

const layoutThemeKey = 'layoutThemeKey'
const getLayoutThemeFromStorage = () => {
  const layoutThemeFromStorage = localStorage.getItem(layoutThemeKey) as ThemeType | null
  return layoutThemeFromStorage || ThemeType.LIGHT
}

interface Props {
  children: React.ReactNode
}

export const LayoutProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState(getLayoutThemeFromStorage())

  useEffect(() => {
    localStorage.setItem(layoutThemeKey, theme)
  }, [theme])

  return <LayoutContext.Provider value={{ theme, setTheme }}>{children}</LayoutContext.Provider>
}
