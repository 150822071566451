import React from 'react'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration, IntegrationAppstore, IntegrationStatus } from 'hooks'
import { AppStoreLink } from './AppStoreLink'
import { ConnectAppStoreButton } from './ConnectAppStoreButton'

export const appStoreDescription =
  'Authorize CodePlatform to access your App Store Connect account to automate app distribution and manage in-app purchases and subscriptions.'

interface Props {
  appstoreIntegration?: Integration
}

export const AppStoreButton: React.FC<Props> = ({ appstoreIntegration }) =>
  appstoreIntegration?.status === IntegrationStatus.READY ? (
    <AppStoreLink>
      <Button
        variant={ButtonVariant.LINK}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.SIZE_24_APP_STORE} />}
      >
        {(appstoreIntegration?.data as IntegrationAppstore | undefined)?.teamName}
      </Button>
    </AppStoreLink>
  ) : (
    <ConnectAppStoreButton disabled={!appstoreIntegration} />
  )
