import React from 'react'
import { ISvg, Svg } from '../Svg'

const Date: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.75 5.27141H13.25M4.10714 1V2.11442M11.75 1V2.11428M11.75 2.11428H4.25C3.00736 2.11428 2 3.11204 2 4.34284V11.7714C2 13.0022 3.00736 14 4.25 14H11.75C12.9926 14 14 13.0022 14 11.7714L14 4.34284C14 3.11204 12.9926 2.11428 11.75 2.11428ZM4.625 7.87143H11.375M4.625 10.8428H11.375"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Date
