import React from 'react'
import { ISvg, Svg } from '../Svg'

const ProvidersVertex: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" stroke="#E1E3E1" />
    <path
      d="M28 21.89C27.8808 21.7469 27.7139 21.6519 27.53 21.6225C27.3461 21.593 27.1579 21.6312 27 21.73L20 26.87V27.09C20.101 27.078 20.2034 27.0876 20.3004 27.1181C20.3974 27.1485 20.4869 27.1992 20.5629 27.2668C20.639 27.3343 20.6998 27.4172 20.7415 27.51C20.7832 27.6027 20.8047 27.7033 20.8047 27.805C20.8047 27.9067 20.7832 28.0072 20.7415 28.1C20.6998 28.1928 20.639 28.2757 20.5629 28.3432C20.4869 28.4108 20.3974 28.4615 20.3004 28.4919C20.2034 28.5224 20.101 28.532 20 28.52C20.1624 28.5207 20.3205 28.468 20.45 28.37L27.86 22.9C28.0044 22.7789 28.0986 22.6086 28.1245 22.422C28.1504 22.2353 28.106 22.0458 28 21.89Z"
      fill="#669DF6"
    />
    <path
      d="M20.0002 28.5202C19.8251 28.4995 19.6638 28.4153 19.5467 28.2835C19.4296 28.1517 19.3649 27.9815 19.3649 27.8052C19.3649 27.629 19.4296 27.4588 19.5467 27.327C19.6638 27.1952 19.8251 27.111 20.0002 27.0902V26.8702L13.0002 21.7302C12.843 21.6294 12.6541 21.5899 12.4697 21.6194C12.2852 21.6489 12.1181 21.7453 12.0002 21.8902C11.8948 22.046 11.8526 22.2361 11.8823 22.4219C11.9121 22.6077 12.0114 22.7751 12.1602 22.8902L19.5702 28.3602C19.6966 28.4568 19.8511 28.5095 20.0102 28.5102L20.0002 28.5202Z"
      fill="#AECBFA"
    />
    <path
      d="M20.0003 26.3398C19.7095 26.3398 19.4253 26.4261 19.1836 26.5876C18.9418 26.7491 18.7534 26.9787 18.6422 27.2473C18.5309 27.5159 18.5018 27.8115 18.5585 28.0966C18.6152 28.3818 18.7552 28.6437 18.9608 28.8493C19.1664 29.0549 19.4283 29.1949 19.7135 29.2516C19.9986 29.3083 20.2942 29.2792 20.5628 29.1679C20.8314 29.0567 21.061 28.8683 21.2225 28.6265C21.3841 28.3848 21.4703 28.1006 21.4703 27.8098C21.4703 27.42 21.3154 27.0461 21.0397 26.7704C20.764 26.4947 20.3901 26.3398 20.0003 26.3398ZM20.0003 28.5198C19.8574 28.5198 19.7179 28.4773 19.5992 28.3978C19.4806 28.3182 19.3884 28.2052 19.3342 28.073C19.28 27.9409 19.2663 27.7956 19.2949 27.6557C19.3235 27.5157 19.3931 27.3875 19.4948 27.2872C19.5965 27.1869 19.7257 27.1191 19.866 27.0925C20.0064 27.0658 20.1514 27.0815 20.2828 27.1376C20.4142 27.1936 20.5259 27.2874 20.6038 27.4071C20.6817 27.5269 20.7223 27.667 20.7203 27.8098C20.7203 27.9039 20.7016 27.9971 20.6653 28.0839C20.629 28.1707 20.5758 28.2494 20.5088 28.3155C20.4418 28.3815 20.3624 28.4336 20.2751 28.4687C20.1878 28.5038 20.0944 28.5212 20.0003 28.5198Z"
      fill="#4285F4"
    />
    <path
      d="M14.0001 14.1098C13.802 14.1073 13.6127 14.0274 13.4727 13.8873C13.3326 13.7472 13.2527 13.558 13.2501 13.3598V11.4798C13.2378 11.3734 13.2482 11.2656 13.2806 11.1634C13.3129 11.0613 13.3665 10.9671 13.4378 10.8871C13.5091 10.8071 13.5965 10.7431 13.6943 10.6993C13.792 10.6555 13.898 10.6328 14.0051 10.6328C14.1123 10.6328 14.2182 10.6555 14.316 10.6993C14.4138 10.7431 14.5012 10.8071 14.5725 10.8871C14.6438 10.9671 14.6973 11.0613 14.7297 11.1634C14.762 11.2656 14.7724 11.3734 14.7601 11.4798V13.3598C14.7575 13.5597 14.6763 13.7504 14.534 13.8908C14.3918 14.0312 14.2 14.1099 14.0001 14.1098Z"
      fill="#AECBFA"
    />
    <path
      d="M13.9797 20.7602C14.3995 20.7602 14.7397 20.42 14.7397 20.0002C14.7397 19.5805 14.3995 19.2402 13.9797 19.2402C13.56 19.2402 13.2197 19.5805 13.2197 20.0002C13.2197 20.42 13.56 20.7602 13.9797 20.7602Z"
      fill="#AECBFA"
    />
    <path
      d="M13.9797 18.5498C14.3995 18.5498 14.7397 18.2095 14.7397 17.7898C14.7397 17.37 14.3995 17.0298 13.9797 17.0298C13.56 17.0298 13.2197 17.37 13.2197 17.7898C13.2197 18.2095 13.56 18.5498 13.9797 18.5498Z"
      fill="#AECBFA"
    />
    <path
      d="M13.9797 16.3301C14.3995 16.3301 14.7397 15.9898 14.7397 15.5701C14.7397 15.1503 14.3995 14.8101 13.9797 14.8101C13.56 14.8101 13.2197 15.1503 13.2197 15.5701C13.2197 15.9898 13.56 16.3301 13.9797 16.3301Z"
      fill="#AECBFA"
    />
    <path
      d="M26 16.3099C25.8002 16.3073 25.6094 16.2261 25.4691 16.0838C25.3287 15.9416 25.25 15.7498 25.25 15.5499V13.6699C25.25 13.471 25.329 13.2802 25.4697 13.1396C25.6103 12.9989 25.8011 12.9199 26 12.9199C26.1989 12.9199 26.3897 12.9989 26.5303 13.1396C26.671 13.2802 26.75 13.471 26.75 13.6699V15.5499C26.7513 15.6493 26.7329 15.7479 26.6958 15.84C26.6587 15.9322 26.6037 16.016 26.5339 16.0867C26.4641 16.1574 26.381 16.2136 26.2893 16.2519C26.1977 16.2902 26.0993 16.3099 26 16.3099Z"
      fill="#4285F4"
    />
    <path
      d="M26.0198 20.77C26.4395 20.77 26.7798 20.4297 26.7798 20.01C26.7798 19.5903 26.4395 19.25 26.0198 19.25C25.6 19.25 25.2598 19.5903 25.2598 20.01C25.2598 20.4297 25.6 20.77 26.0198 20.77Z"
      fill="#4285F4"
    />
    <path
      d="M26.0198 18.52C26.4395 18.52 26.7798 18.1797 26.7798 17.76C26.7798 17.3403 26.4395 17 26.0198 17C25.6 17 25.2598 17.3403 25.2598 17.76C25.2598 18.1797 25.6 18.52 26.0198 18.52Z"
      fill="#4285F4"
    />
    <path
      d="M26.0198 12.2402C26.4395 12.2402 26.7798 11.9 26.7798 11.4802C26.7798 11.0605 26.4395 10.7202 26.0198 10.7202C25.6 10.7202 25.2598 11.0605 25.2598 11.4802C25.2598 11.9 25.6 12.2402 26.0198 12.2402Z"
      fill="#4285F4"
    />
    <path
      d="M20 23C19.8019 22.9974 19.6126 22.9175 19.4725 22.7774C19.3324 22.6373 19.2526 22.4481 19.25 22.25V20.34C19.2714 20.1547 19.3601 19.9837 19.4993 19.8597C19.6385 19.7356 19.8185 19.667 20.005 19.667C20.1915 19.667 20.3715 19.7356 20.5107 19.8597C20.6499 19.9837 20.7386 20.1547 20.76 20.34V22.23C20.7613 22.3306 20.7426 22.4305 20.705 22.5239C20.6674 22.6172 20.6117 22.7022 20.541 22.7738C20.4703 22.8455 20.386 22.9023 20.2932 22.9412C20.2003 22.98 20.1007 23 20 23Z"
      fill="#669DF6"
    />
    <path
      d="M20.0002 25.2099C20.42 25.2099 20.7602 24.8697 20.7602 24.4499C20.7602 24.0302 20.42 23.6899 20.0002 23.6899C19.5805 23.6899 19.2402 24.0302 19.2402 24.4499C19.2402 24.8697 19.5805 25.2099 20.0002 25.2099Z"
      fill="#669DF6"
    />
    <path
      d="M20.0002 18.8999C20.42 18.8999 20.7602 18.5596 20.7602 18.1399C20.7602 17.7201 20.42 17.3799 20.0002 17.3799C19.5805 17.3799 19.2402 17.7201 19.2402 18.1399C19.2402 18.5596 19.5805 18.8999 20.0002 18.8999Z"
      fill="#669DF6"
    />
    <path
      d="M20.0002 16.6802C20.42 16.6802 20.7602 16.3399 20.7602 15.9202C20.7602 15.5004 20.42 15.1602 20.0002 15.1602C19.5805 15.1602 19.2402 15.5004 19.2402 15.9202C19.2402 16.3399 19.5805 16.6802 20.0002 16.6802Z"
      fill="#669DF6"
    />
    <path
      d="M23.0001 18.54C22.802 18.5374 22.6127 18.4576 22.4727 18.3175C22.3326 18.1774 22.2527 17.9881 22.2501 17.79V15.91C22.2378 15.8036 22.2482 15.6957 22.2806 15.5936C22.3129 15.4914 22.3665 15.3973 22.4378 15.3173C22.5091 15.2373 22.5965 15.1733 22.6943 15.1295C22.792 15.0856 22.898 15.063 23.0051 15.063C23.1123 15.063 23.2182 15.0856 23.316 15.1295C23.4138 15.1733 23.5012 15.2373 23.5725 15.3173C23.6438 15.3973 23.6973 15.4914 23.7297 15.5936C23.762 15.6957 23.7724 15.8036 23.7601 15.91V17.79C23.7575 17.9898 23.6763 18.1806 23.534 18.321C23.3918 18.4613 23.2 18.54 23.0001 18.54Z"
      fill="#4285F4"
    />
    <path
      d="M23.01 14.4502C23.4297 14.4502 23.77 14.1099 23.77 13.6902C23.77 13.2704 23.4297 12.9302 23.01 12.9302C22.5903 12.9302 22.25 13.2704 22.25 13.6902C22.25 14.1099 22.5903 14.4502 23.01 14.4502Z"
      fill="#4285F4"
    />
    <path
      d="M23.01 22.9502C23.4297 22.9502 23.77 22.6099 23.77 22.1902C23.77 21.7704 23.4297 21.4302 23.01 21.4302C22.5903 21.4302 22.25 21.7704 22.25 22.1902C22.25 22.6099 22.5903 22.9502 23.01 22.9502Z"
      fill="#4285F4"
    />
    <path
      d="M23.01 20.73C23.4297 20.73 23.77 20.3897 23.77 19.97C23.77 19.5502 23.4297 19.21 23.01 19.21C22.5903 19.21 22.25 19.5502 22.25 19.97C22.25 20.3897 22.5903 20.73 23.01 20.73Z"
      fill="#4285F4"
    />
    <path
      d="M16.9905 22.9502C17.4102 22.9502 17.7505 22.6099 17.7505 22.1902C17.7505 21.7704 17.4102 21.4302 16.9905 21.4302C16.5707 21.4302 16.2305 21.7704 16.2305 22.1902C16.2305 22.6099 16.5707 22.9502 16.9905 22.9502Z"
      fill="#AECBFA"
    />
    <path
      d="M16.9905 16.6802C17.4102 16.6802 17.7505 16.3399 17.7505 15.9202C17.7505 15.5004 17.4102 15.1602 16.9905 15.1602C16.5707 15.1602 16.2305 15.5004 16.2305 15.9202C16.2305 16.3399 16.5707 16.6802 16.9905 16.6802Z"
      fill="#AECBFA"
    />
    <path
      d="M16.9905 14.4502C17.4102 14.4502 17.7505 14.1099 17.7505 13.6902C17.7505 13.2704 17.4102 12.9302 16.9905 12.9302C16.5707 12.9302 16.2305 13.2704 16.2305 13.6902C16.2305 14.1099 16.5707 14.4502 16.9905 14.4502Z"
      fill="#AECBFA"
    />
    <path
      d="M17.0002 20.7301C16.8038 20.7302 16.6149 20.6543 16.4732 20.5182C16.3315 20.3821 16.248 20.1964 16.2402 20.0001V18.1001C16.2402 17.9012 16.3193 17.7104 16.4599 17.5698C16.6006 17.4291 16.7913 17.3501 16.9902 17.3501C17.1891 17.3501 17.3799 17.4291 17.5206 17.5698C17.6612 17.7104 17.7402 17.9012 17.7402 18.1001V20.0001C17.7351 20.1938 17.6552 20.378 17.5172 20.5141C17.3793 20.6502 17.194 20.7276 17.0002 20.7301Z"
      fill="#AECBFA"
    />
  </Svg>
)

export default ProvidersVertex
