import React from 'react'
import { ISvg, Svg } from '../Svg'

const Error: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.27717 1L13.7184 1.00904L18.9737 6.27717L18.9647 13.7184L13.6966 18.9737L6.25535 18.9647L1 13.6966L1.00904 6.25535L6.27717 1Z"
      fill="#DE3030"
    />
    <path d="M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M13 7L7 13" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)
export default Error
