import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { Modal, Text, Type } from 'components'
import { InputWrapper } from 'components/Modal/Modal.styled'
import { ProgressContext } from 'context'
import { useInputAutoFocusRef } from 'hooks'
import { WorkspacesService } from 'services/workspaces'

interface Props {
  active: boolean
  close: () => void
}

export const AddWorkspace: React.FC<Props> = ({ active, close }) => {
  const navigate = useNavigate()
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [name, setName] = useState('')
  const inputAutoFocusElement = useInputAutoFocusRef(active)
  const [wait, setWait] = useState(false)

  const postWorkspace = () => {
    if (name) {
      setWait(true)
      startLoader()
      WorkspacesService.postWorkspace(name)
        .then(res => {
          onClose()
          navigate(`/workspaces/${res.data.id}`)
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  const onClose = () => {
    setName('')
    close()
  }

  return (
    <Modal
      active={active}
      onClose={onClose}
      title="Create new workspace"
      onButtonClick={postWorkspace}
      buttonDisabled={!name}
      buttonTitle="Create workspace"
      buttonLoading={wait}
    >
      <Box>
        <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
          Workspace name
        </Text>
        <InputWrapper inputEmpty={name === ''}>
          <input value={name} onChange={e => setName(e.target.value)} ref={inputAutoFocusElement} />
        </InputWrapper>
      </Box>
    </Modal>
  )
}
