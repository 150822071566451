import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export const Text = styled.span``

interface IBox {
  color?: ButtonColor
  height: number
  $rightIcon: boolean
  $leftIcon: boolean
  disabled?: boolean
  br?: number
}

const ButtonStyle = css<IBox>`
  height: ${(props: IBox) => props.height}px;
  outline: 0;
  border: 0;
  cursor: ${(props: IBox) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props: IBox) => (props.disabled ? 'none' : 'all')};
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  text-decoration: none;
  box-sizing: border-box;
  padding: ${props => (props.$rightIcon || props.$leftIcon ? '0 20px 0 15px' : '0px 15px')};
  border-radius: ${(props: IBox) => (props.br ? `${props.br}px` : '8px')};
  transition: background-color 0.4s ease;
  font-size: 14px;

  ${props => {
    switch (props.color) {
      case ButtonColor.PRIMARY:
        return `
        background-color: ${props.disabled ? `${props.theme.colors.blue}80` : props.theme.colors.blue};
        `
      case ButtonColor.SECONDARY:
        return `
        background-color: ${props.disabled ? props.theme.colors.disabledBgColor : props.theme.colors.white};
        border: 1px solid ${props.theme.colors.borderColor};
        `
      case ButtonColor.TERTIARY:
        return `
        background-color: transparent;
        `
      default:
        return null
    }
  }}

  & input {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &:hover {
    ${props => {
      if (!props.disabled) {
        switch (props.color) {
          case ButtonColor.PRIMARY:
            return `
            background-color: ${props.theme.colors.secondaryBlueHover};
            `
          case ButtonColor.SECONDARY:
            return `
            background-color: ${props.theme.colors.primaryBackground};
            `
          case ButtonColor.TERTIARY:
            return null
          default:
            return null
        }
      }
    }}
  }
  & ${Text} {
    font-size: inherit;
    line-height: 100%;
    font-weight: ${props => props.theme.weights.bold};

    ${props => {
      switch (props.color) {
        case ButtonColor.PRIMARY:
          return `
        color: ${props.disabled ? `${props.theme.colors.white}b3` : props.theme.colors.white};
        `
        case ButtonColor.SECONDARY:
          return `
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.secondaryBlack};
        `
        case ButtonColor.TERTIARY:
          return `
        color: ${props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.secondaryBlack};
        `
        default:
          return null
      }
    }}
  }
`

export const Box = styled.button<IBox>`
  ${ButtonStyle}
`

export const LinkBox = styled(Link)<IBox>`
  ${ButtonStyle}
`

export const OutLinkBox = styled.a<IBox>`
  ${ButtonStyle}
`
