import React, { useContext, useState } from 'react'

import { CustomSection, CustomSectionButton, CustomSectionTitle, RemoveContent } from 'components'
import { ProjectContext } from 'context'
import { Project } from 'hooks'

export const DangerZone: React.FC = () => {
  const { project } = useContext(ProjectContext)
  const [toRemove, setToRemove] = useState<Project | null>(null)

  const remove = (callback: () => void) => {
    if (toRemove) {
      callback()
    }
  }

  return (
    <>
      <CustomSection style={{ paddingBottom: '25px' }}>
        <CustomSectionTitle>Danger zone</CustomSectionTitle>
        <CustomSectionButton
          title="Delete project"
          subTitle="This action cannot be undone."
          onClick={() => setToRemove(project)}
          buttonText="Delete project"
          deleteButton
        />
      </CustomSection>
      <RemoveContent
        toRemove={toRemove}
        title="Delete project"
        text="You are about to delete the project <b>{name}</b>. To make sure, enter the project name below"
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}
