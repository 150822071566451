import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const ContentWrapper = styled.div<{ $containTagsBox?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${props =>
    !props.$containTagsBox &&
    `
  position: relative;
  z-index: 12;
  `}
  & > div:first-child {
    ${props =>
      props.$containTagsBox &&
      `
  position: relative;
  z-index: 12;
  `}
  }
`

export const BlueBoxWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.headerSize.heightDesktop}px;
  left: 0px;
  width: 100vw;
  aspect-ratio: 1/0.35;
  overflow-x: clip;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    top: ${props => props.theme.headerSize.heightMobile}px;
  }

  & > div {
    aspect-ratio: auto;
  }
`

export const BlurBox = styled.div<{ $maxHeightNone?: boolean }>`
  position: absolute;
  top: 25px;
  left: -38vw;
  width: 179vw;
  aspect-ratio: 1/0.2;
  min-height: 400px;
  max-height: ${props => (props.$maxHeightNone ? 'none' : '507px')};
  border-radius: 179vw;
  opacity: 0.9;
  background: rgba(115, 123, 248, 0.15);
  filter: blur(100px);
  will-change: transform;
  z-index: 11;
  pointer-events: none;
`
