import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const AppLandingPage: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16.4273 14.7273C16.5455 13.8182 16.5455 12.9091 16.5455 12C16.5455 11.0909 16.5 10.1818 16.4273 9.27273H21.6091C22.1303 11.0536 22.1303 12.9464 21.6091 14.7273H16.4273ZM14.3909 7.45455C13.8182 3.9 12.6818 2 12 2C11.3182 2 10.1818 3.9 9.60909 7.45455H14.3909ZM9.60909 16.5455C10.1727 20.1 11.3182 22 12 22C12.6818 22 13.8182 20.1 14.3909 16.5455H9.60909ZM14.6091 9.27273H9.39091C9.31818 10.1182 9.27273 11.0182 9.27273 12C9.27273 12.9818 9.31818 13.8818 9.39091 14.7273H14.6091C14.6818 13.8818 14.7273 12.9818 14.7273 12C14.7273 11.0182 14.7273 10.1182 14.6091 9.27273ZM7.45455 12C7.45455 11.0909 7.45455 10.1818 7.57273 9.27273H2.76555C2.5435 9.27273 2.34674 9.41888 2.29263 9.63424C1.87206 11.3082 1.90482 13.0665 2.39091 14.7273H7.57273C7.5 13.8182 7.45455 12.9091 7.45455 12ZM7.78182 16.5455H3.1C3.72693 17.7665 4.5985 18.8454 5.66046 19.715C6.43666 20.3506 7.30242 20.8644 8.22784 21.2408C8.62629 21.4029 8.98905 20.987 8.82982 20.5874C8.31312 19.2906 7.96068 17.9328 7.78182 16.5455ZM16.2182 16.5455C16.0393 17.9328 15.6869 19.2906 15.1702 20.5874C15.011 20.987 15.3737 21.4029 15.7722 21.2408C16.6976 20.8644 17.5633 20.3506 18.3395 19.715C19.4015 18.8454 20.2731 17.7665 20.9 16.5455H16.2182ZM16.2182 7.45455H20.9C20.2731 6.23352 19.4015 5.15462 18.3395 4.28503C17.5633 3.64944 16.6976 3.13562 15.7722 2.75916C15.3737 2.59707 15.011 3.01302 15.1702 3.41262C15.6869 4.70936 16.0393 6.06722 16.2182 7.45455ZM7.78182 7.45455C7.96068 6.06722 8.31312 4.70937 8.82982 3.41262C8.98905 3.01303 8.62629 2.59707 8.22784 2.75916C7.30242 3.13562 6.43666 3.64944 5.66046 4.28503C4.5985 5.15462 3.72693 6.23352 3.1 7.45455H7.78182Z"
      fill="#476CEC"
    />
  </Svg>
)
