import React from 'react'
import { ISvg, Svg } from '../Svg'

const Shadow: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.33333C1 2.04467 2.04467 1 3.33333 1H10.3333C11.622 1 12.6667 2.04467 12.6667 3.33333C13.9553 3.33333 15 4.378 15 5.66667V12.6667C15 13.9553 13.9553 15 12.6667 15H5.66667C4.378 15 3.33333 13.9553 3.33333 12.6667C2.04467 12.6667 1 11.622 1 10.3333V3.33333ZM3.91667 2.16667C2.95017 2.16667 2.16667 2.95017 2.16667 3.91667V9.75C2.16667 10.7165 2.95017 11.5 3.91667 11.5H9.75C10.7165 11.5 11.5 10.7165 11.5 9.75V3.91667C11.5 2.95017 10.7165 2.16667 9.75 2.16667H3.91667Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Shadow
