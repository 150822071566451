import styled from 'styled-components'

export const LeftPanelWrapper = styled.div<{ open: boolean }>`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background: ${props => props.theme.colors.darkBlue_50};
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 350px;
  }
  @media (max-width: 960px) {
    width: 270px;
  }
  @media (max-width: 767px) {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100%;
    height: calc(100% - 60px);
    border-radius: unset;
    display: ${props => !props.open && 'none'};
  }
`

export const ChatWrapper = styled.div<{ open: boolean }>`
  position: relative;
  width: 660px;
  border-radius: 16px;
  background: ${props => props.theme.colors.modalBg};
  @media (max-width: 1100px) {
    width: 560px;
  }
  @media (max-width: 960px) {
    width: 480px;
  }
  @media (max-width: 767px) {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100%;
    height: calc(100% - 60px);
    border-radius: unset;
    display: ${props => !props.open && 'none'};
  }
`

export const TitleBox = styled.div<{ insideChatWrapper?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 45px;
  padding-left: 16px;
  padding-right: 5px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  width: 100%;
  @media (max-width: 767px) {
    display: ${props => props.insideChatWrapper && 'none'};
    position: ${props => !props.insideChatWrapper && 'fixed'};
    top: ${props => !props.insideChatWrapper && 0};
    left: ${props => !props.insideChatWrapper && 0};
    z-index: ${props => !props.insideChatWrapper && 1};
    height: ${props => !props.insideChatWrapper && '60px'};
    background: ${props => !props.insideChatWrapper && props.theme.colors.primaryBackground};
    padding: ${props => !props.insideChatWrapper && '0 15px 0 0'};
    column-gap: ${props => !props.insideChatWrapper && '10px'};
  }
  @media (min-width: 768px) {
    display: ${props => !props.insideChatWrapper && 'none'};
  }
`

export const ArrowControl = styled.div`
  position: absolute;
  display: flex;
  bottom: 5px;
  right: 5px;
  transition: ${props => props.theme.transition.primary};
  transform: translate(0, 0);
  opacity: 0.7;
`

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;
  height: 60px;
  border-right: 1px solid ${props => props.theme.colors.borderColor};
  cursor: pointer;
  &:hover {
    ${ArrowControl} {
      transform: translate(2px, 2px);
      opacity: 1;
    }
  }
`

export const Version = styled.div`
  height: 20px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5f6ff;
  color: #0099ff;
  font-size: 12px;
  font-weight: ${props => props.theme.weights.medium};
  line-height: 150%;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 1px;
`

export const CloseIconBox = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  & svg {
    cursor: pointer;
    & path {
      transition: ${props => props.theme.transition.primary};
    }
    &:hover {
      & path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`

export const AIIcon = styled.div<{ open: boolean; whiteIcon?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg {
    min-width: 16px;
    min-height: 16px;
    & path {
      fill: ${props => (props.whiteIcon ? props.theme.colors.white : props.open && props.theme.colors.iconHover)};
    }
  }
  &:hover > svg path {
    fill: ${props => !props.whiteIcon && props.theme.colors.iconHover};
  }
`
