import React from 'react'
import { ISvg, Svg } from '../Svg'

const ImageTLeft: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M12 12L12 11.1111L12 4" fill="#F5EEFF" />
    <path d="M12 12L12 11.1111L12 4" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 4H14.3333L9 4" fill="#F5EEFF" />
    <path d="M15 4H14.3333L9 4" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="3" y="6" width="4" height="4" rx="2" fill="#A1AFBE" />
  </Svg>
)

export default ImageTLeft
