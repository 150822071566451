import React from 'react'

import * as styled from './Switch.styled'
import { SwitchVariant } from './Switch.styled'

interface Props {
  checked: boolean
  onChange?: (val: boolean) => void
  disabled?: boolean
  variant?: SwitchVariant
}

export const Switch: React.FC<Props> = ({ checked, onChange, disabled, variant = SwitchVariant.DEFAULT }) => (
  <styled.Label variant={variant}>
    <styled.Input type="checkbox" checked={checked} onChange={e => onChange?.(e.target.checked)} disabled={disabled} />
    <styled.SwitchBox />
  </styled.Label>
)
