import styled from 'styled-components'

interface IBoxTitle {
  color: 'Blue' | 'Green' | 'Yellow' | 'Red'
}

export const BoxTitle = styled.div<IBoxTitle>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;
  height: 20px;
  padding: 0 8px;
  font-size: 12px;
  background-color: ${props => props.theme.colors[`notification${props.color}Bg`]};
  color: ${props => props.theme.colors[`notification${props.color}Text`]};
  border: 1px solid ${props => props.theme.colors[`notification${props.color}Border`]};
`
