import React from 'react'
import { ISvg, Svg } from '../Svg'

const Field: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M6 12H1.57335C1.2567 12 1 11.7433 1 11.4266V4.57335C1 4.2567 1.2567 4 1.57335 4H6"
      stroke="#7F899E"
      strokeLinecap="round"
    />
    <path
      d="M10 12H14.4266C14.7433 12 15 11.7433 15 11.4266V4.57335C15 4.2567 14.7433 4 14.4266 4H10"
      stroke="#7F899E"
      strokeLinecap="round"
    />
    <path d="M8 2L8 14" stroke="#7F899E" strokeLinecap="round" />
    <path d="M7 2L9 2" stroke="#7F899E" strokeLinecap="round" />
    <path d="M7 14L9 14" stroke="#7F899E" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.941 6.45461L3.0417 9.25991C3.01508 9.34267 3 9.42329 3 9.4986C3 9.62997 3.04316 9.75945 3.1361 9.85686C3.22913 9.95437 3.35322 10 3.47951 10C3.58733 10 3.69914 9.96807 3.79199 9.87891C3.87605 9.79819 3.92061 9.6929 3.94804 9.59633L4.11507 9.04967H4.88116L5.04967 9.59792C5.07624 9.69402 5.12057 9.79966 5.20608 9.88053C5.30012 9.96949 5.41302 10 5.52049 10C5.7931 10 6 9.7804 6 9.4986C6 9.41607 5.9839 9.34232 5.95915 9.26259L5.05673 6.45155L5.05636 6.45043C5.01712 6.33 4.95657 6.21206 4.85544 6.12534C4.74955 6.03455 4.62513 6 4.5 6C4.3733 6 4.24786 6.03545 4.14169 6.12679C4.04006 6.21422 3.97942 6.33294 3.941 6.45461Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Field
