import styled from 'styled-components'

export const IconWrapper = styled.div<{ absolute?: boolean }>`
  position: ${props => props.absolute && 'absolute'};
  top: ${props => props.absolute && '9px'};
  left: ${props => props.absolute && '18px'};
  display: flex;
  align-items: center;
  margin-right: 5px;
  & svg {
    & g {
      opacity: 1;
    }
    & path {
      fill: ${props => props.theme.colors.darkText};
    }
  }
`
