import Container from '../Container/Container'
import { Caption, FooterBox } from './Footer.styled'

const Footer = () => (
  <FooterBox>
    <Container>
      <Caption>© 2024 Code Platform, Unipessoal LDA</Caption>
    </Container>
  </FooterBox>
)

export default Footer
