import React from 'react'
import { ISvg, Svg } from '../Svg'

const OkFilled: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="12" fill="#747BF0" />
    <path d="M7 11.2667L10.8095 15L17 8" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
  </Svg>
)
export default OkFilled
