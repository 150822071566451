import styled from 'styled-components'

export const StyleItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  margin: 10px;
  overflow: hidden;
  background: ${props => props.theme.colors.settingField};
  &:hover {
    background: ${props => props.theme.colors.settingFieldHover};
  }
`
