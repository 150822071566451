import styled from 'styled-components'
import corner from './Corner.svg'

export const Wrapper = styled.div`
  margin: 8px 8px 0px 8px;
  width: calc(100% - 16px);
  height: 570px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.settingField};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    border-radius: 4px;
    & > div {
      height: 100%;
      width: 100%;
      & > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  & .ReactCrop__crop-selection {
    animation: none !important;
    background-image: none !important;
    border: 1px solid ${props => props.theme.colors.white};
    & .ReactCrop__drag-handle {
      &::after {
        background-color: transparent;
        border: none;
        outline: none;
        background-image: url(${corner});
      }
      &.ord-n {
        display: none;
      }
      &.ord-e {
        display: none;
      }
      &.ord-ne {
        &::after {
          transform: rotate(90deg);
        }
      }
      &.ord-s {
        display: none;
      }
      &.ord-se {
        &::after {
          transform: rotate(180deg);
        }
      }
      &.ord-w {
        display: none;
      }
      &.ord-sw {
        &::after {
          transform: rotate(270deg);
        }
      }
    }
  }
`

export const BottomPannel = styled.div`
  padding: 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;
    & > span {
      height: 16px;
      width: 1.5px;
      background: ${props => props.theme.colors.darkBlue_100};
      border-radius: 5px;
    }
  }
`

interface IBottomElementWrapper {
  icon?: boolean
  active?: boolean
  numbersCount?: number
}

export const BottomElementWrapper = styled.div<IBottomElementWrapper>`
  height: 30px;
  border-radius: 8px;
  padding: ${props => (props.icon ? '7px' : '7px 16px')};
  background-color: ${props => (props.active ? props.theme.colors.settingFieldHover : props.theme.colors.settingField)};
  transition: ${props => props.theme.transition.primary};
  cursor: ${props => (props.icon ? 'pointer' : 'text')};
  display: flex;
  justify-content: center;
  align-items: center;

  & > input {
    background-color: transparent;
    border: none;
    width: ${props => props.numbersCount && `${props.numbersCount * 8}px`};
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.darkText};

    &:focus {
      outline: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & + span {
      font-size: 12px;
      line-height: 18px;
      margin-left: 6px;
      color: ${props => props.theme.colors.grey1};
    }
  }
  & svg path {
    &[stroke] {
      stroke: ${props => props.theme.colors.grey1};
    }
  }

  &:hover {
    background-color: ${props => props.theme.colors.settingFieldHover};
  }
`

export const ButtonIconWrapper = styled.div<{ active?: boolean }>`
  & > button {
    background-color: ${props => props.active && props.theme.colors.settingFieldHover};
    & > span {
      line-height: 0 !important;
      height: fit-content;
    }
  }
`
