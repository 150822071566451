import React from 'react'

import { BooleanInput, BrandingColorInput } from 'components'
import { RightSidebarItem, SettingNumberField, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const SliderSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    componentType,
    name,
    minimumValue,
    maximumValue,
    value,
    primaryColor,
    secondaryColor,
    headerColor,
    discrete,
  } = component

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <BrandingColorInput
          subLabel="Leading track"
          value={primaryColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'primaryColor', value: { constant: value } }])}
          source={primaryColor?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'primaryColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BrandingColorInput
          subLabel="Trailing track"
          value={secondaryColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'secondaryColor', value: { constant: value } }])}
          source={secondaryColor?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'secondaryColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BrandingColorInput
          subLabel="Thumb color"
          value={headerColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'headerColor', value: { constant: value } }])}
          source={headerColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'headerColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Minimum value"
          value={minimumValue?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'minimumValue', value: { constant: value } }])}
          source={minimumValue?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'minimumValue', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Maximum value"
          value={maximumValue?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'maximumValue', value: { constant: value } }])}
          source={maximumValue?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'maximumValue', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Value"
          value={value?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'value', value: { constant: value } }])}
          source={value?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'value', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BooleanInput
          subLabel="Discrete values"
          value={discrete?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'discrete', value: { constant: value } }])}
          source={discrete?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'discrete', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
