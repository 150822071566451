import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 25px;
  background-color: ${props => props.theme.colors.secondaryBackground};
`
