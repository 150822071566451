import React from 'react'
import { ISvg, Svg } from '../Svg'

const Collection: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="3.25" y="3.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="3.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="23.25" y="3.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="3.25" y="13.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="13.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="23.25" y="13.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="3.25" y="13.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="13.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="23.25" y="13.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="3.25" y="23.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="23.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="23.25" y="23.25" width="6.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Collection
