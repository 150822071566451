import { TextareaAutosize } from '@mui/material'
import { memo } from 'react'

import { FieldProps } from '../../types'
import { AI, EditByModal } from '../RightIcons'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const StringInput = memo((props: Props) => (
  <>
    {props.hint}
    <TextareaAutosize
      value={props.value || undefined}
      onChange={({ target: { value } }) => props.onChange(value || null)}
      placeholder={props.placeholder}
      disabled={props.disabled || props.editByModal}
    />
    {props.ai && <AI {...props} />}
    {props.editByModal && <EditByModal {...props} />}
  </>
))
