import React from 'react'

import { ISvg, Svg } from '../Svg'

const Info: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="9" fill="#8A91F9" />
    <path
      d="M13.7369 15.623C13.5433 15.698 12.9303 15.8766 12.6507 15.7176C12.6121 15.6953 12.5796 15.664 12.5558 15.6263C12.5321 15.5887 12.5179 15.5458 12.5145 15.5015C12.4267 14.9443 12.8443 13.6565 13.0701 12.9654C13.1454 12.735 13.1992 12.5671 13.2207 12.4653C13.3838 11.7133 13.3031 11.1382 12.9805 10.756C12.5923 10.2961 11.9036 10.2066 11.3387 10.2899C10.7642 10.3662 10.2028 10.52 9.66995 10.7471C9.6452 10.759 9.62357 10.7765 9.60677 10.7982C9.58998 10.8199 9.57847 10.8452 9.57316 10.8721L9.45666 11.4276C9.45036 11.4588 9.45251 11.4912 9.46291 11.5213C9.4733 11.5514 9.49156 11.5783 9.51581 11.599C9.54035 11.6194 9.56976 11.633 9.60117 11.6386C9.63259 11.6443 9.66493 11.6417 9.69504 11.6312C9.90834 11.5526 10.5751 11.3758 10.8135 11.5937C10.9479 11.7169 10.9103 11.9777 10.8565 12.1724C10.801 12.376 10.7346 12.5956 10.6665 12.826C10.2256 14.321 9.72372 16.0159 10.5195 16.5446C10.8961 16.831 11.3544 16.9905 11.828 17C12.294 17 12.8694 16.8535 13.6975 16.5196C13.7224 16.5099 13.7449 16.4948 13.7632 16.4753C13.7815 16.4558 13.7952 16.4325 13.8032 16.407L13.9735 15.8409C13.9831 15.8086 13.9835 15.7743 13.9747 15.7418C13.9658 15.7093 13.948 15.6799 13.9233 15.6569C13.8986 15.6343 13.868 15.6192 13.835 15.6132C13.802 15.6072 13.768 15.6106 13.7369 15.623ZM12.7009 7C12.4445 7.00018 12.1939 7.07609 11.9808 7.21815C11.7676 7.3602 11.6016 7.56202 11.5035 7.79811C11.4055 8.03419 11.3799 8.29393 11.4299 8.54451C11.48 8.79509 11.6035 9.02525 11.7848 9.20591C11.9661 9.38658 12.1971 9.50963 12.4486 9.55952C12.7 9.60941 12.9607 9.58389 13.1976 9.4862C13.4346 9.38851 13.6371 9.22302 13.7797 9.01066C13.9222 8.79829 13.9984 8.54858 13.9986 8.29309C13.9985 7.95017 13.8617 7.62132 13.6184 7.37884C13.375 7.13636 13.045 7.00009 12.7009 7Z"
      fill="white"
    />
  </Svg>
)

export default Info
