import React, { useContext } from 'react'

import * as styled from 'pages/Settings/pages/Plans/Plans.styled'
import * as general from './index.styled'

import { Crumbs, Interval, PlanCard, Text, Type } from 'components'
import { WorkspaceContext } from 'context'
import { useWorkspacePlan } from 'hooks'

export const WorkspaceBilling: React.FC = () => {
  const { workspace } = useContext(WorkspaceContext)
  const { activeSubscription, plans, onPlanChange, ChangePlanAlert } = useWorkspacePlan(workspace)

  return workspace ? (
    <>
      <general.Container style={{ width: '100%', minWidth: '0px' }}>
        <general.MaxWidthContainer>
          <Crumbs
            firstCrumbTitle="Billing"
            secondCrumbTitle="Workspace plans"
            to={`/workspaces/${workspace.id}/billing`}
          />
          <Text type={Type.SUB_TITLE}>Collaborate effectively on all your apps.</Text>
          <styled.Content>
            <styled.CardsBox>
              {plans.map(el => (
                <PlanCard
                  key={el.id}
                  plan={el}
                  interval={Interval.month}
                  activeSubscription={activeSubscription}
                  onPlanChange={onPlanChange}
                  workspaceCard
                />
              ))}
            </styled.CardsBox>
          </styled.Content>
        </general.MaxWidthContainer>
      </general.Container>
      {ChangePlanAlert}
    </>
  ) : null
}
