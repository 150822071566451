import styled from 'styled-components'

export const ArrowControl = styled.div`
  position: absolute;
  display: flex;
  bottom: 5px;
  right: 5px;
  transition: ${props => props.theme.transition.primary};
  transform: translate(0, 0);
  opacity: 0.7;
`

export const Logo = styled.div<{ wide: boolean }>`
  display: flex;
  justify-content: ${props => !props.wide && 'center'};
  align-items: center;
  position: relative;
  width: 100%;
  border-bottom: ${props => !props.wide && `1px solid ${props.theme.colors.borderColor}`};
  border-right: ${props => !props.wide && `1px solid ${props.theme.colors.borderColor}`};
  background: ${props => !props.wide && props.theme.colors.primaryBackground};
  height: ${props => !props.wide && '60px'};
  min-height: ${props => !props.wide && '60px'};
  cursor: pointer;
  &:hover {
    ${ArrowControl} {
      transform: translate(2px, 2px);
      opacity: 1;
    }
  }
`

export const DropDownWrapper = styled.div`
  width: 100%;
`

export const ThemeTogglerBox = styled.div<{ wide: boolean }>`
  margin-top: ${props => !props.wide && '8px'};
  border-top: ${props => !props.wide && `1px solid ${props.theme.colors.darkBlue_100_lightBorder}`};
  width: 100%;
  height: 99px;
  padding: 15px;
  display: flex;
  column-gap: 10px;
  cursor: default;
`

export const TogglerBox = styled.div<{
  width: number
  text?: boolean
  dark?: boolean
  active?: boolean
}>`
  width: ${props => props.width}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0 8px;
  border: 1px solid
    ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.darkBlue_100_lightBorder)};
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  transition: ${props => props.theme.transition.primary};
  ${props =>
    props.text &&
    `
    padding: 10px;
    justify-content: center;
    & span {
      &:first-child {
        font-weight: ${props.theme.weights.medium};
        margin-bottom: 3px;
        color: ${props.theme.colors.darkText1};
      }
      &:last-child {
        color: ${props.theme.colors.grey1};
        line-height: 14px;
      }
    }
  `}
  ${props =>
    !props.text &&
    `
    & span {
      color: ${props.active ? props.theme.colors.darkText : props.theme.colors.grey1}
    }
    `}

  &:hover {
    background-color: ${props => props.theme.colors.settingField};
  }
  & span {
    transition: ${props => props.theme.transition.primary};
    display: inline-block;
  }
  & svg {
    & path {
      transition: ${props => props.theme.transition.primary};
      fill: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.grey1)};
    }
  }
`
