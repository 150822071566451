import React, { useContext } from 'react'
import { ISvg, Svg } from '../Svg'
import { ThemeContext } from 'styled-components'

const CodePlatformFull: React.FC<ISvg> = props => {
  const themeContext = useContext(ThemeContext)

  return (
    <Svg viewBox="0 0 163 34" fill="none" {...props}>
      <path
        d="M56.3142 20.1175C56.1016 20.8285 55.7157 21.4605 55.1566 22.0134C54.6037 22.5664 53.9292 22.9948 53.1331 23.2986C52.3371 23.6025 51.4894 23.7544 50.5901 23.7544C49.3565 23.7544 48.2354 23.4597 47.2267 22.8702C46.218 22.2747 45.428 21.4635 44.8568 20.4366C44.2856 19.4096 44 18.2763 44 17.0367C44 16.1009 44.1671 15.2198 44.5013 14.3934C44.8416 13.5609 45.3004 12.8469 45.8777 12.2514C46.461 11.6559 47.1598 11.1849 47.9741 10.8386C48.7944 10.4922 49.6664 10.319 50.5901 10.319C51.9148 10.319 53.0936 10.638 54.1267 11.2761C55.1658 11.9081 55.8463 12.7223 56.1684 13.7189L53.7074 14.8947C53.4643 14.3174 53.0663 13.8586 52.5133 13.5184C51.9604 13.1781 51.3193 13.0079 50.5901 13.0079C49.4841 13.0079 48.5818 13.3877 47.8829 14.1473C47.1841 14.9069 46.8347 15.87 46.8347 17.0367C46.8347 18.1973 47.1841 19.1605 47.8829 19.9261C48.5878 20.6918 49.4902 21.0746 50.5901 21.0746C51.3679 21.0746 52.0424 20.8832 52.6136 20.5004C53.1848 20.1175 53.5737 19.6041 53.7803 18.96L56.3142 20.1175Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M61.9564 14.2202C62.6187 14.2202 63.2416 14.3417 63.8249 14.5848C64.4143 14.8279 64.9187 15.1621 65.338 15.5874C65.7634 16.0067 66.0976 16.5141 66.3406 17.1096C66.5898 17.6991 66.7143 18.328 66.7143 18.9964C66.7143 19.8897 66.5047 20.7009 66.0854 21.4301C65.6722 22.1593 65.101 22.7305 64.3718 23.1437C63.6487 23.5508 62.8435 23.7544 61.9564 23.7544C60.6013 23.7544 59.468 23.3017 58.5565 22.3963C57.645 21.4909 57.1893 20.3576 57.1893 18.9964C57.1893 17.6353 57.645 16.4989 58.5565 15.5874C59.468 14.6759 60.6013 14.2202 61.9564 14.2202ZM61.9564 16.7359C61.3305 16.7359 60.8079 16.9516 60.3886 17.3831C59.9754 17.8084 59.7688 18.3462 59.7688 18.9964C59.7688 19.6284 59.9754 20.1601 60.3886 20.5915C60.8079 21.023 61.3305 21.2387 61.9564 21.2387C62.5701 21.2387 63.0866 21.023 63.5059 20.5915C63.9252 20.1601 64.1348 19.6284 64.1348 18.9964C64.1348 18.3462 63.9252 17.8084 63.5059 17.3831C63.0927 16.9516 62.5762 16.7359 61.9564 16.7359Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M74.6899 10.5013H77.3241V23.5721H74.6899V22.4874C74.4651 22.8642 74.1005 23.171 73.5961 23.408C73.0978 23.6389 72.56 23.7544 71.9828 23.7544C71.1989 23.7544 70.4788 23.5417 69.8225 23.1163C69.1663 22.691 68.6497 22.1137 68.273 21.3845C67.8962 20.6492 67.7079 19.8471 67.7079 18.9782C67.7079 17.6535 68.1211 16.5293 68.9475 15.6057C69.78 14.682 70.7917 14.2202 71.9828 14.2202C72.554 14.2202 73.0917 14.3387 73.5961 14.5757C74.1005 14.8127 74.4651 15.1165 74.6899 15.4872V10.5013ZM72.5661 21.2569C73.0097 21.2569 73.4229 21.1506 73.8057 20.9379C74.1946 20.7252 74.4894 20.4518 74.6899 20.1175V17.8753C74.4894 17.5289 74.1946 17.2464 73.8057 17.0276C73.4229 16.8088 73.0097 16.6995 72.5661 16.6995C71.922 16.6995 71.3812 16.9182 70.9437 17.3557C70.5061 17.7872 70.2874 18.328 70.2874 18.9782C70.2874 19.6284 70.5061 20.1722 70.9437 20.6098C71.3812 21.0412 71.922 21.2569 72.5661 21.2569Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M87.87 18.5862C87.87 18.9569 87.8457 19.3002 87.7971 19.6162H81.207C81.3286 20.236 81.5929 20.71 82 21.0382C82.4072 21.3602 82.9267 21.5212 83.5587 21.5212C83.9719 21.5212 84.3669 21.4331 84.7436 21.2569C85.1265 21.0807 85.3969 20.8559 85.5549 20.5824L87.6057 21.8403C87.2715 22.4479 86.7428 22.9189 86.0197 23.2531C85.2966 23.5873 84.458 23.7544 83.504 23.7544C82.155 23.7544 81.0126 23.2926 80.0768 22.3689C79.1471 21.4392 78.6822 20.2968 78.6822 18.9417C78.6822 17.6109 79.141 16.4929 80.0586 15.5874C80.9822 14.6759 82.1125 14.2202 83.4493 14.2202C84.6889 14.2202 85.7341 14.6425 86.5848 15.4872C87.4416 16.3318 87.87 17.3648 87.87 18.5862ZM81.2435 17.9391H85.2358C85.1811 17.4833 84.9685 17.1035 84.5978 16.7997C84.2271 16.4959 83.7987 16.344 83.3126 16.344C82.7657 16.344 82.316 16.4837 81.9636 16.7633C81.6111 17.0428 81.3711 17.4347 81.2435 17.9391Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M94.3781 10.5013C95.6602 10.5013 96.7176 10.8963 97.5501 11.6863C98.3886 12.4762 98.8079 13.4758 98.8079 14.6851C98.8079 15.9065 98.3886 16.9182 97.5501 17.7203C96.7176 18.5224 95.6602 18.9235 94.3781 18.9235H92.1632V23.5721H89.4013V10.5013H94.3781ZM94.3781 16.2711C94.8521 16.2711 95.244 16.1222 95.5539 15.8244C95.8699 15.5206 96.0279 15.1408 96.0279 14.6851C96.0279 14.2475 95.8699 13.8829 95.5539 13.5913C95.244 13.2996 94.8521 13.1538 94.3781 13.1538H92.1632V16.2711H94.3781Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path d="M102.837 10.5013V23.5721H100.203V10.5013H102.837Z" fill={themeContext.colors.darkBlue_800} />
      <path
        d="M111.204 14.4025H113.838V23.5721H111.204V22.4874C110.979 22.8642 110.615 23.171 110.11 23.408C109.612 23.6389 109.074 23.7544 108.497 23.7544C107.713 23.7544 106.993 23.5417 106.337 23.1163C105.681 22.691 105.164 22.1137 104.787 21.3845C104.411 20.6492 104.222 19.8471 104.222 18.9782C104.222 17.6535 104.635 16.5293 105.462 15.6057C106.294 14.682 107.306 14.2202 108.497 14.2202C109.068 14.2202 109.606 14.3387 110.11 14.5757C110.615 14.8127 110.979 15.1165 111.204 15.4872V14.4025ZM109.08 21.2569C109.524 21.2569 109.937 21.1506 110.32 20.9379C110.709 20.7252 111.004 20.4518 111.204 20.1175V17.8753C111.004 17.5289 110.709 17.2464 110.32 17.0276C109.937 16.8088 109.524 16.6995 109.08 16.6995C108.436 16.6995 107.895 16.9182 107.458 17.3557C107.02 17.7872 106.802 18.328 106.802 18.9782C106.802 19.6284 107.02 20.1722 107.458 20.6098C107.895 21.0412 108.436 21.2569 109.08 21.2569Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M121.486 21.1293L121.623 23.4627C121.27 23.6329 120.82 23.7179 120.274 23.7179C119.18 23.7179 118.296 23.3898 117.621 22.7335C116.953 22.0712 116.618 21.1566 116.618 19.9899V16.9091H114.914V14.4025H116.618V11.9962H119.253V14.4025H121.504V16.9091H119.253V19.8532C119.253 20.2725 119.389 20.6128 119.663 20.8741C119.936 21.1293 120.27 21.2569 120.665 21.2569C120.969 21.2569 121.243 21.2144 121.486 21.1293Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M126.873 13.8647V14.4025H129.124V16.9091H126.873V23.5721H124.239V16.9091H122.534V14.4025H124.239V13.7371C124.239 12.5704 124.573 11.6559 125.241 10.9935C125.916 10.3312 126.8 10 127.894 10C128.404 10 128.854 10.0942 129.243 10.2826L129.106 12.5977C128.802 12.5066 128.529 12.461 128.286 12.461C127.891 12.461 127.556 12.5917 127.283 12.853C127.009 13.1143 126.873 13.4515 126.873 13.8647Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M134.347 14.2202C135.009 14.2202 135.632 14.3417 136.215 14.5848C136.805 14.8279 137.309 15.1621 137.729 15.5874C138.154 16.0067 138.488 16.5141 138.731 17.1096C138.98 17.6991 139.105 18.328 139.105 18.9964C139.105 19.8897 138.895 20.7009 138.476 21.4301C138.063 22.1593 137.492 22.7305 136.762 23.1437C136.039 23.5508 135.234 23.7544 134.347 23.7544C132.992 23.7544 131.859 23.3017 130.947 22.3963C130.036 21.4909 129.58 20.3576 129.58 18.9964C129.58 17.6353 130.036 16.4989 130.947 15.5874C131.859 14.6759 132.992 14.2202 134.347 14.2202ZM134.347 16.7359C133.721 16.7359 133.198 16.9516 132.779 17.3831C132.366 17.8084 132.159 18.3462 132.159 18.9964C132.159 19.6284 132.366 20.1601 132.779 20.5915C133.198 21.023 133.721 21.2387 134.347 21.2387C134.961 21.2387 135.477 21.023 135.896 20.5915C136.316 20.1601 136.525 19.6284 136.525 18.9964C136.525 18.3462 136.316 17.8084 135.896 17.3831C135.483 16.9516 134.967 16.7359 134.347 16.7359Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M145.422 14.2202C145.889 14.2202 146.263 14.2749 146.543 14.3843L146.388 16.8544C145.999 16.7146 145.607 16.6448 145.212 16.6448C144.884 16.6448 144.519 16.7724 144.118 17.0276C143.723 17.2767 143.422 17.6109 143.216 18.0302V23.5721H140.582V14.4025H143.216V15.4872C143.428 15.1104 143.747 14.8066 144.173 14.5757C144.604 14.3387 145.02 14.2202 145.422 14.2202Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <path
        d="M158.41 14.2202C159.51 14.2202 160.409 14.5575 161.108 15.232C161.807 15.9004 162.157 16.7815 162.157 17.8753V23.5721H159.504V18.4222C159.504 17.8753 159.361 17.4438 159.076 17.1279C158.79 16.8058 158.404 16.6448 157.918 16.6448C157.596 16.6448 157.283 16.739 156.979 16.9273C156.675 17.1096 156.441 17.3405 156.277 17.6201V23.5721H153.643V18.4222C153.643 17.8814 153.497 17.4499 153.206 17.1279C152.92 16.8058 152.555 16.6448 152.112 16.6448C151.735 16.6448 151.398 16.739 151.1 16.9273C150.802 17.1157 150.581 17.3648 150.435 17.6748V23.5721H147.801V14.4025H150.435V15.4507C150.678 15.1043 151.024 14.8127 151.474 14.5757C151.924 14.3387 152.449 14.2202 153.051 14.2202C154.23 14.2202 155.08 14.6486 155.603 15.5054C155.907 15.1469 156.308 14.8431 156.806 14.5939C157.304 14.3448 157.839 14.2202 158.41 14.2202Z"
        fill={themeContext.colors.darkBlue_800}
      />
      <rect width="34" height="34" rx="8" fill={themeContext.colors.darkBlue_800} />
      <path
        d="M23.8107 7.17666L23.8992 7H24.1057H25.9049H26.3473L26.1998 7.4122L24.9905 10.4448C23.3978 14.3901 23.3978 19.101 24.961 23.0463L26.1998 26.1378L26.3473 26.55H25.9049H24.1057H23.8402L23.7812 26.3733L22.9259 24.1946C21.0677 19.5132 21.0972 13.9485 22.9554 9.29654L23.8107 7.17666Z"
        fill={themeContext.colors.secondaryButtonBg}
      />
      <path
        d="M15.524 14.0368V10.3565C15.524 8.50158 16.6153 7 17.9426 7H19.5648H19.8598V7.29443V9.35542V9.64985H19.5648H17.9426C17.8836 9.67929 17.7066 9.94428 17.7066 10.3859V14.8907V14.979L17.6477 15.0379L16.5268 16.775L17.6477 18.5121L17.7066 18.571V18.6593V23.1641C17.7066 23.6057 17.8836 23.8413 17.9426 23.9002H19.5648H19.8598V24.1946V26.2556V26.55H19.5648H17.9426C16.6153 26.55 15.524 25.0484 15.524 23.1935V19.5132L13.8133 16.9222L13.6953 16.7456L13.8133 16.5689L15.524 14.0368Z"
        fill={themeContext.colors.secondaryButtonBg}
      />
      <path
        d="M7.94339 7H11.6303H11.9252V7.29443V9.35542V9.64985H11.6303H9.86057V23.8707H11.6598H11.9547V24.1651V26.2261V26.5206H11.6598H7.94339H7.64844V26.2261V7.29443V7H7.94339Z"
        fill={themeContext.colors.secondaryButtonBg}
      />
    </Svg>
  )
}

export default CodePlatformFull
