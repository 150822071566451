import React from 'react'
import { ISvg, Svg } from '../Svg'

const Folder: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g opacity="0.5">
      <rect x="2" y="6" width="12" height="7" rx="1" fill="#7F899E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.44772 3 2 3.44772 2 4V5H9.57143L9.20722 3.72528C9.08457 3.29598 8.69218 3 8.2457 3H3Z"
        fill="#7F899E"
      />
    </g>
  </Svg>
)

export default Folder
