import { Box } from '@mui/material'

import * as styled from './ProjectSetup.styled'

import { Icon, Loader, Name } from 'components'
import { ApplicationStatus, IntegrationStatus, useApplications, useProjectIntegrations } from 'hooks'

interface Props {
  id: string
}

export const ProjectSetup: React.FC<Props> = ({ id }) => {
  const { applications, iosApplication, androidApplication, webApplication, landingApplication, backendApplication } =
    useApplications(id)
  const {
    firebaseIntegration,
    firestoreIntegration,
    firebaseAuthIntegration,
    firebaseStorageIntegration,
    firebaseHostingIntegration,
    firebaseFunctionsIntegration,
    cmsIntegration,
  } = useProjectIntegrations(id)

  const firebaseIntegrations = [
    firebaseIntegration,
    firestoreIntegration,
    firebaseAuthIntegration,
    firebaseStorageIntegration,
    firebaseHostingIntegration,
    firebaseFunctionsIntegration,
  ]

  const processes = [
    {
      title: 'Initializing Google Cloud Platform project',
      description: [
        <>
          <styled.DotStatus status={firebaseIntegration?.status} /> Firebase
        </>,
        <>
          <styled.DotStatus status={firestoreIntegration?.status} /> Firestore
        </>,
        <>
          <styled.DotStatus status={firebaseAuthIntegration?.status} /> Firebase Authentication
        </>,
        <>
          <styled.DotStatus status={firebaseStorageIntegration?.status} /> Firebase Storage
        </>,
        <>
          <styled.DotStatus status={firebaseHostingIntegration?.status} /> Firebase Hosting
        </>,
        <>
          <styled.DotStatus status={firebaseFunctionsIntegration?.status} /> Firebase Functions
        </>,
      ],
      ready:
        firebaseIntegrations.filter(el => el?.status === IntegrationStatus.READY).length ===
        firebaseIntegrations.length,
      failed:
        firebaseIntegrations.filter(el => el?.status === IntegrationStatus.FAILED).length ===
        firebaseIntegrations.length,
    },
    {
      title: 'Generating source code',
      description: [
        <>
          <styled.DotStatus status={iosApplication?.status} /> iOS application
        </>,
        <>
          <styled.DotStatus status={androidApplication?.status} /> Android application
        </>,
        <>
          <styled.DotStatus status={webApplication?.status} /> Progressive Web Application
        </>,
        <>
          <styled.DotStatus status={backendApplication?.status} /> Serverless backend
        </>,
        <>
          <styled.DotStatus status={landingApplication?.status} /> App landing page
        </>,
      ],
      ready:
        !!applications.length &&
        applications.filter(el =>
          [ApplicationStatus.REPO_PUSHED, ApplicationStatus.DEPLOYING, ApplicationStatus.READY].includes(el.status)
        ).length === applications.length,
      failed:
        !!applications.length &&
        applications.filter(el => el.status === ApplicationStatus.FAILED).length === applications.length,
    },
    {
      title: 'Generating content',
      description: [
        <>
          <styled.DotStatus status={cmsIntegration?.status} /> Generating database schema and preparing demo content
        </>,
      ],
      ready: cmsIntegration?.status === IntegrationStatus.READY,
      failed: cmsIntegration?.status === IntegrationStatus.FAILED,
    },
  ]

  return (
    <>
      <styled.Description>This process can take up to 10 minutes.</styled.Description>
      <Box display="flex" flexDirection="column" gap="4px" margin="0 -25px" p="0 25px">
        {processes.map((el, i) => (
          <styled.Item key={el.title}>
            <Box display="flex" flexDirection="column" alignItems="center" gap="6px" pt="3px">
              {el.ready ? (
                <Icon name={Name.ADDITIONAL_OK_FILLED} />
              ) : el.failed ? (
                <Icon name={Name.REPOSITORY_BUILD_FAILED_SMALL} />
              ) : (
                <Loader />
              )}
              {i !== processes.length - 1 && <styled.Line />}
            </Box>
            <Box display="flex" flexDirection="column" gap="5px">
              <styled.ItemTitle>{el.title}</styled.ItemTitle>
              <styled.ItemDescription>
                {el.description.map((el, i) => (
                  <div key={i}>{el}</div>
                ))}
              </styled.ItemDescription>
            </Box>
          </styled.Item>
        ))}
      </Box>
    </>
  )
}
