import React from 'react'
import { ISvg, Svg } from '../Svg'

const ShowMore: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8.38461" cy="3.38462" r="1.38462" fill="#7F899E" />
    <circle cx="8.38461" cy="7.99985" r="1.38462" fill="#7F899E" />
    <circle cx="8.38461" cy="12.6151" r="1.38462" fill="#7F899E" />
  </Svg>
)

export default ShowMore
