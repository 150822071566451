import { Box, Portal } from '@mui/material'
import { memo, useContext, useRef, useState } from 'react'

import * as styled from './RefField.styled'

import { FieldWrapper, Icon, Name, RefPicker, RemoveContent } from 'components'
import { CMSContext, ProjectContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { CollectionRecord } from 'pages/CMS/pages'
import { CMSCollectionRecord, collectionNameToFind } from 'services/cms'
import { generateFirestoreId } from 'utils'
import { FieldProps } from '../../types'
import { ReferenceItem, ReferenceItems } from './ReferenceItems'

interface RefFieldProps extends FieldProps {
  value: CMSCollectionRecord | CMSCollectionRecord[] | string | string[] | null
  onChange: (val: CMSCollectionRecord | CMSCollectionRecord[] | string | string[] | null) => void
}

export const RefField = memo((props: RefFieldProps) => {
  const {
    state: {
      data: { collections = [] },
    },
  } = useContext(ProjectContext)
  const { assets } = useContext(CMSContext)
  const value = !props.multiple ? (props.value as CMSCollectionRecord | string | null) : null
  const arrayValue = props.multiple ? (props.value as CMSCollectionRecord[] | string[] | null) || [] : []
  const [toRemove, setToRemove] = useState<CMSCollectionRecord | string | null>(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [openExisting, setOpenExisting] = useState(false)
  const [recordId, setRecordId] = useState('')
  const currentCollection = props.resourceType
    ? assets
    : collections.find(el => collectionNameToFind(el.name) === collectionNameToFind(props.collection || ''))
  const singularName = props.resourceType ? props.resourceType : 'record'
  const pickerTitle = singularName + `${props.multiple ? 's' : ''}`
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpenMenu(false))

  const addRef = (val: CMSCollectionRecord | string) => {
    if (props.multiple) {
      const index = arrayValue.findIndex(el =>
        props.resourceType ? (el as CMSCollectionRecord).id === (val as CMSCollectionRecord).id : el === val
      )
      if (index !== -1) {
        const arrayValueCopy = JSON.parse(JSON.stringify(arrayValue))
        arrayValueCopy.splice(index, 1, val)
        props.onChange(arrayValueCopy)
      } else {
        props.onChange([...arrayValue, val])
      }
    } else {
      props.onChange(val)
    }
  }

  const removeRef = (callback: () => void) => {
    if (toRemove) {
      if (props.multiple) {
        // @ts-ignore
        const value = arrayValue.filter(el =>
          props.resourceType ? (el as CMSCollectionRecord).id !== (toRemove as CMSCollectionRecord).id : el !== toRemove
        )
        props.onChange(value.length ? value : null)
      } else {
        props.onChange(null)
      }
      callback()
    }
  }

  return (
    <>
      <FieldWrapper
        {...props}
        children={props => (
          <>
            {!value ? (
              <styled.RefFieldInput
                onClick={e => {
                  e.preventDefault()
                  setOpenMenu(open => !open)
                }}
                ref={ref}
              >
                <span>
                  <Icon name={Name.RIGHT_SIDEBAR_ADD} />
                  Add {pickerTitle}
                  {openMenu && (
                    <Box>
                      <Box onClick={() => setOpenExisting(true)}>Add existing {pickerTitle}</Box>
                      <Box onClick={() => setRecordId(generateFirestoreId())}>Add new {pickerTitle}</Box>
                    </Box>
                  )}
                </span>
              </styled.RefFieldInput>
            ) : (
              <ReferenceItem
                value={value}
                remove={() => setToRemove(value)}
                open={setRecordId}
                resourceType={props.resourceType}
              />
            )}
            {props.multiple && (
              <ReferenceItems
                values={arrayValue}
                onChange={props.onChange}
                remove={setToRemove}
                open={setRecordId}
                resourceType={props.resourceType}
              />
            )}
          </>
        )}
      />
      {openExisting && currentCollection && (
        <RefPicker
          close={() => setOpenExisting(false)}
          currentRef={currentCollection}
          values={arrayValue}
          onChange={props.onChange}
          resourceType={props.resourceType}
          multiple={props.multiple}
          title={pickerTitle}
        />
      )}
      {recordId && currentCollection && (
        <Portal>
          <CollectionRecord
            recordId={recordId}
            collectionId={currentCollection.name}
            currentCollection={currentCollection}
            resourceType={props.resourceType}
            addRef={addRef}
            close={() => setRecordId('')}
          />
        </Portal>
      )}
      <RemoveContent
        toRemove={toRemove}
        title={`Delete ${singularName}`}
        text={`Are you sure? This action <b>can not be undone</b>. Selected data will be deleted immediately`}
        clean={() => setToRemove(null)}
        remove={removeRef}
      />
    </>
  )
})
