import React from 'react'
import { ISvg, Svg } from '../Svg'

const ThemeSwitch: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="7.5" stroke="#7F899E" />
    <path
      d="M0.5 8C0.5 4.02588 3.59098 0.773695 7.5 0.516402V15.4836C3.59098 15.2263 0.5 11.9741 0.5 8Z"
      fill="#7F899E"
      stroke="#7F899E"
    />
    <circle cx="8" cy="8" r="4" fill="#F0F0F0" />
    <path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4V12Z" fill="#7F899E" />
  </Svg>
)

export default ThemeSwitch
