export const getCaret = (el: HTMLDivElement) => {
  let caretAt = 0
  let focusedCount = 0
  const sel = window.getSelection()
  if (sel && sel.rangeCount !== 0) {
    // @ts-ignore
    if (sel.baseNode === sel.focusNode && sel.baseNode.nodeName === '#text') {
      focusedCount = Math.abs(sel.anchorOffset - sel.focusOffset)
    }
    const range = sel.getRangeAt(0)
    const preRange = range.cloneRange()
    preRange.selectNodeContents(el)
    preRange.setEnd(range.endContainer, range.endOffset)
    caretAt = preRange.toString().length
  }
  return { caretAt, focusedCount }
}

export const setCaret = (el: HTMLDivElement, offset: number) => {
  const sel = window.getSelection()
  if (sel) {
    const range = document.createRange()
    try {
      let length = 0
      for (const node of Array.from(el.childNodes)) {
        const nodeLength = node.textContent?.length || 0
        length += nodeLength
        if (length >= offset) {
          if (node.nodeName === 'DIV') {
            range.setStartAfter(node)
          } else {
            range.setStart(node, nodeLength - (length - offset))
          }
          break
        }
      }
    } catch {}
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
  }
}
