import React, { useState, useRef, useEffect } from 'react'

import arrowMobile from 'partials/LandingLayout/components/Header/images/dropdown-arrow-mobile.svg'

import * as styled from './MenuListItem.styled'

import { useOnClickOutside } from 'partials/LandingLayout/hooks'
import { PRODUCTS } from './constants'
import Container from 'partials/LandingLayout/components/Container/Container'
import { ProductsItem } from './components'

interface IMenuListItem {
  item: {
    name: string
    canOpen: boolean
    href?: string
  }
  setBurger?: React.Dispatch<React.SetStateAction<boolean>>
  burger?: boolean
  setZIndex: React.Dispatch<React.SetStateAction<boolean>>
}

const MOBILE_PADDING = 60
const PRODUCT_ITEM_HEIGHT = 36
const PRODUCT_GAP = 10

export const MenuListItem: React.FC<IMenuListItem> = ({ item, setBurger, burger, setZIndex }) => {
  const [isShown, setIsShown] = useState(false)

  const _setIsShown = () => {
    if (item.canOpen) {
      setIsShown(!isShown)
    }
    if (!item.canOpen && setBurger) {
      setBurger(false)
    }
  }

  useEffect(() => {
    if (!burger) {
      setIsShown(false)
    }
  }, [burger])

  useEffect(() => {
    setZIndex(isShown || burger || window.scrollY > 0)
  }, [isShown, burger])

  const openMenuRef = useRef(null)

  useOnClickOutside(openMenuRef, () => setIsShown(false), true)

  const menuOpenContentRef = useRef(null)

  const [menuOpenBoxHeight, setMenuOpenBoxHeight] = useState<number>(389)

  useEffect(() => {
    if (menuOpenContentRef?.current) {
      setMenuOpenBoxHeight((menuOpenContentRef?.current as HTMLDivElement)?.getBoundingClientRect()?.height)
    }
    // @ts-ignore
  }, [menuOpenContentRef?.current?.getBoundingClientRect()?.height])

  return (
    <styled.ListItem onClick={_setIsShown} ref={openMenuRef} $isShown={isShown}>
      {item.href ? (
        <styled.DropDownLink href={item.href}>
          <styled.DropDownText>{item.name}</styled.DropDownText>
          {item.canOpen && <styled.DropDownArrowMobile src={arrowMobile} $isShown={isShown} />}
        </styled.DropDownLink>
      ) : (
        <styled.DropDown $isShown={isShown}>
          <styled.DropDownText>{item.name}</styled.DropDownText>
          {item.canOpen && <styled.DropDownArrowMobile src={arrowMobile} $isShown={isShown} />}
        </styled.DropDown>
      )}
      {item.canOpen && (
        <>
          <styled.MenuOpenBox
            $isVisible={isShown}
            onClick={e => e.stopPropagation()}
            $adaptiveHeight={
              PRODUCT_ITEM_HEIGHT * PRODUCTS.length + PRODUCT_GAP * (PRODUCTS.length - 1) + MOBILE_PADDING
            }
          >
            <Container>
              <styled.MenuOpenContent ref={menuOpenContentRef}>
                <styled.MenuOpenGrid>
                  {PRODUCTS.map(item => (
                    <ProductsItem key={item.title} item={item} />
                  ))}
                </styled.MenuOpenGrid>
              </styled.MenuOpenContent>
            </Container>
          </styled.MenuOpenBox>
          <styled.MenuOpenOverlay $visible={isShown} $adaptiveHeight={menuOpenBoxHeight} />
        </>
      )}
    </styled.ListItem>
  )
}
