import styled from 'styled-components'
import { ThemeType } from 'theme'

export const Task = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 1px;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText2};
  cursor: pointer;
  & > svg {
    & > circle {
      ${props =>
        props.theme.themeType === ThemeType.LIGHT &&
        `
        stroke: #DE3030;
        stroke-width: 1.5;
        stroke-linecap: round;
      `}
    }
  }
`
