import { useSearchParams } from 'react-router-dom'

import { AppStoreConnectionModal } from 'components'
import { Integration } from 'hooks'

export const connectAppStoreFlag = 'connect-appstore'

export const useAppStoreConnectionModal = (appstoreIntegration?: Integration) => {
  const [searchParams] = useSearchParams()
  const connectAppStore = searchParams.get(connectAppStoreFlag)

  return appstoreIntegration ? (
    <AppStoreConnectionModal appstoreIntegration={appstoreIntegration} active={!!connectAppStore} />
  ) : (
    <></>
  )
}
