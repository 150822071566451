import React from 'react'
import { ISvg, Svg } from '../Svg'

const ProvidersEmail: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <rect width="40" height="40" rx="20" fill="#648099" />
    <path
      d="M30 14C30 12.9 29.1 12 28 12H12C10.9 12 10 12.9 10 14V26C10 27.1 10.9 28 12 28H28C29.1 28 30 27.1 30 26V14ZM28 14L20 19L12 14H28ZM28 26H12V16L20 21L28 16V26Z"
      fill="white"
    />
  </Svg>
)

export default ProvidersEmail
