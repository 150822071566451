import React from 'react'
import { ISvg, Svg } from '../Svg'

const Tasks: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M15 6L9 6" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 6H5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15 10L9 10" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 10H5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15 14L9 14" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 14H5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Tasks
