import React, { useState, useEffect } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Icon, Name, Button } from 'components'

import * as styled from './Cropper.styled'

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number | undefined) => {
  let crop: Crop
  if (aspect) {
    crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  } else {
    crop = {
      unit: '%',
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    }
  }
  return crop
}

interface ICroppper {
  url: string
  onFileUpdate: (newUrl: string) => void
  close: () => void
  initUrl: string
}

export const Cropper: React.FC<ICroppper> = ({ url, onFileUpdate, close, initUrl }) => {
  const [crop, setCrop] = useState<Crop>()
  const [cropWidth, setCropWidth] = useState<number>(0)
  const [cropHeight, setCropHeight] = useState<number>(0)
  const [cropWidthStringLength, setCropWidthStringLength] = useState<number>(1)
  const [cropHeightStringLength, setCropHeightStringLength] = useState<number>(1)
  const [scale, setScale] = useState<number>(1)
  const [aspect, setAspect] = useState<number | undefined>(undefined)
  const [imageWidth, setImageWidth] = useState<number>(0)
  const [imageHeight, setImageHeight] = useState<number>(0)
  const [initialImageWidth, setInitialImageWidth] = useState<number>(0)
  const [initialImageHeight, setInitialImageHeight] = useState<number>(0)

  const updateQueryStringParameter = (uri: string, key: string, value: number) => {
    const queryRegExp = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
    const separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(queryRegExp)) {
      return uri.replace(queryRegExp, `$1${key}=${value}$2`)
    } else {
      return `${uri}${separator}${key}=${value}`
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget
    let initWidth: number
    let initHeight: number
    if (url.endsWith('.svg')) {
      fetch(url)
        .then(res => res.text())
        .then(res => {
          const div = document.createElement('div')
          div.innerHTML = res
          const svg = div.querySelector('svg')
          if (svg) {
            const viewBox = svg.getAttribute('viewBox')?.split(' ')
            if (viewBox) {
              initWidth = Math.round(Number(viewBox[2]))
              initHeight = Math.round(Number(viewBox[3]))
              setInitialImageWidth(initWidth)
              setInitialImageHeight(initHeight)
              setImageWidth(Math.round(Number(viewBox[2])))
              setImageHeight(Math.round(Number(viewBox[3])))
            }
          }
        })
    } else {
      initWidth = naturalWidth
      initHeight = naturalHeight
      setInitialImageWidth(naturalWidth)
      setInitialImageHeight(naturalHeight)
      setImageWidth(naturalWidth)
      setImageHeight(naturalHeight)
    }
    //select-initial-crop-area
    let initScale: number = 1
    if (initUrl.includes('?scale')) {
      initUrl.split('/').forEach(el => {
        if (el.includes('?scale')) {
          let re = new RegExp('([?&])scale=.*?(&|$)', 'i')
          if (el.match(re)) {
            initScale = parseInt(el.match(re)?.[0]?.replace('?scale=', '') as string, 10)
            setScale(initScale)
          }
        }
      })
    }
    if (initUrl.includes('c_crop')) {
      initUrl.split('/').forEach(el => {
        if (el.includes('c_crop')) {
          let initCropHeight: number = 0
          let initCropWidth: number = 0
          let initCropX: number = 0
          let initCropY: number = 0
          el.split(',').forEach(elParameter => {
            if (elParameter.includes('h_')) {
              initCropHeight = Number(elParameter.replace('h_', ''))
            }
            if (elParameter.includes('w_')) {
              initCropWidth = Number(elParameter.replace('w_', ''))
            }
            if (elParameter.includes('x_')) {
              initCropX = Number(elParameter.replace('x_', ''))
            }
            if (elParameter.includes('y_')) {
              initCropY = Number(elParameter.replace('y_', ''))
            }
          })
          const afterScaleWidth = initScale >= 1 ? Math.round(initWidth / initScale) : initWidth
          const scaleWidthGap = (initWidth - afterScaleWidth) / 2
          const afterScaleX = initCropX - scaleWidthGap
          const afterScaleHeight = initScale >= 1 ? Math.round(initHeight / initScale) : initHeight
          const scaleHeightGap = (initHeight - afterScaleHeight) / 2
          const afterScaleY = initCropY - scaleHeightGap
          const crop: Crop = {
            unit: '%',
            width: (initCropWidth / afterScaleWidth) * 100,
            height: (initCropHeight / afterScaleHeight) * 100,
            x: (afterScaleX / afterScaleWidth) * 100,
            y: (afterScaleY / afterScaleHeight) * 100,
          }
          setCrop(crop)
        }
      })
    } else {
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const handleToggleAspectClick = () => {
    if (aspect) {
      setAspect(undefined)
    } else {
      if (cropWidth === 0 || cropHeight === 0) {
        setAspect(1)
      } else {
        setAspect(cropWidth / cropHeight)
      }
    }
  }

  useEffect(() => {
    const afterScaleWidth = Math.round(initialImageWidth / scale)
    const afterScaleHeight = Math.round(initialImageHeight / scale)
    if (scale >= 1) {
      setImageWidth(afterScaleWidth)
      setImageHeight(afterScaleHeight)
    } else {
      setImageWidth(initialImageWidth)
      setImageHeight(initialImageHeight)
    }
  }, [url, scale])

  const onSave = () => {
    if (crop) {
      const newWidth = Math.round((imageWidth * crop.width) / 100)
      const newHeight = Math.round((imageHeight * crop.height) / 100)
      let newX = Math.round((imageWidth * crop.x) / 100)
      let newY = Math.round((imageHeight * crop.y) / 100)
      let newUrl: string
      if (scale >= 1) {
        const afterScaleX = (initialImageWidth - imageWidth) / 2
        const afterScaleY = (initialImageHeight - imageHeight) / 2
        newY = Math.round(afterScaleY + newY)
        newX = Math.round(afterScaleX + newX)
        newUrl = url.replace('upload/', `upload/c_crop,h_${newHeight},w_${newWidth},x_${newX},y_${newY}/`)
        newUrl = updateQueryStringParameter(newUrl, 'scale', scale)
      } else {
        newUrl = url.replace(
          'upload/',
          `upload/w_${scale},c_scale/b_transparent,c_lpad,h_${initialImageHeight},w_${initialImageWidth}/c_crop,h_${newHeight},w_${newWidth},x_${newX},y_${newY}/`
        )
        newUrl = updateQueryStringParameter(newUrl, 'scale', scale)
      }
      onFileUpdate(newUrl)
    }
    close()
  }

  useEffect(() => {
    if (crop) {
      const newWidth = Math.min(imageWidth, Math.max(0, Math.round((imageWidth * crop.width) / 100)))
      if (cropWidthStringLength !== newWidth.toString().length) {
        setCropWidthStringLength(newWidth.toString().length)
      }
      setCropWidth(newWidth)
    }
  }, [crop?.width, imageWidth])

  useEffect(() => {
    if (crop) {
      const newHeight = Math.min(imageHeight, Math.max(0, Math.round((imageHeight * crop.height) / 100)))
      if (cropHeightStringLength !== newHeight.toString().length) {
        setCropHeightStringLength(newHeight.toString().length)
      }
      setCropHeight(newHeight)
    }
  }, [crop?.height, imageHeight])

  enum IProperty {
    WIDTH = 'width',
    HEIGHT = 'height',
  }

  const handleCropValue = (value: string, property: IProperty) => {
    const widthInCrop = (Number(value) / imageWidth) * 100
    const heightInCrop = (Number(value) / imageHeight) * 100
    if (crop) {
      switch (property) {
        case IProperty.WIDTH:
          setCrop({ ...crop, width: widthInCrop })
          break
        case IProperty.HEIGHT:
          setCrop({ ...crop, height: heightInCrop })
          break
      }
    } else {
      switch (property) {
        case IProperty.WIDTH:
          setCrop({
            unit: '%',
            width: widthInCrop,
            height: 50,
            x: 25,
            y: 25,
          })
          break
        case IProperty.HEIGHT:
          setCrop({
            unit: '%',
            width: 50,
            height: heightInCrop,
            x: 25,
            y: 25,
          })
          break
      }
    }
    if (aspect) {
      switch (property) {
        case IProperty.WIDTH:
          setAspect(Number(value) / cropHeight)
          break
        case IProperty.HEIGHT:
          setAspect(cropWidth / Number(value))
          break
      }
    }
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && !e.repeat) {
        setAspect(cropWidth / cropHeight)
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && !e.repeat) {
        setAspect(undefined)
      }
    }
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [cropWidth, cropHeight])

  return (
    <>
      <styled.Wrapper>
        <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} aspect={aspect}>
          <img alt="Crop me" src={url} style={{ transform: `scale(${scale})` }} onLoad={onImageLoad} />
        </ReactCrop>
      </styled.Wrapper>
      <styled.BottomPannel>
        <div>
          <styled.BottomElementWrapper numbersCount={cropWidthStringLength}>
            <input type="number" value={cropWidth} onChange={e => handleCropValue(e.target.value, IProperty.WIDTH)} />
            <span>W</span>
          </styled.BottomElementWrapper>
          <styled.BottomElementWrapper numbersCount={cropHeightStringLength}>
            <input type="number" value={cropHeight} onChange={e => handleCropValue(e.target.value, IProperty.HEIGHT)} />
            <span>H</span>
          </styled.BottomElementWrapper>
          <styled.BottomElementWrapper onClick={handleToggleAspectClick} active={!!aspect} icon>
            <Icon name={Name.ADDITIONAL_FIX} />
          </styled.BottomElementWrapper>
          <span />
          <styled.BottomElementWrapper icon onClick={() => setScale(Number((scale + 0.1).toFixed(1)))}>
            <Icon name={Name.ADDITIONAL_PLUS} />
          </styled.BottomElementWrapper>
          <styled.BottomElementWrapper icon onClick={() => setScale(Math.max(0.1, Number((scale - 0.1).toFixed(1))))}>
            <Icon name={Name.ADDITIONAL_MINUS} />
          </styled.BottomElementWrapper>
        </div>
        <Button style={{ padding: '5px 17.5px' }} onClick={onSave}>
          Crop
        </Button>
      </styled.BottomPannel>
    </>
  )
}
