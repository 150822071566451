import React from 'react'
import { ISvg, Svg } from '../Svg'

const NavigationTreeFolder: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3 3C2.44772 3 2 3.44772 2 4V12C2 12.5523 2.44772 13 3 13H13C13.5523 13 14 12.5523 14 12V6C14 5.44772 13.5523 5 13 5H8.28078C7.82191 5 7.42193 4.6877 7.31063 4.24254L7.18937 3.75746C7.07807 3.3123 6.67809 3 6.21922 3H3Z"
      fill="#B8BDC8"
    />
  </Svg>
)
export default NavigationTreeFolder
