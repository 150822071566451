import React, { ReactElement, cloneElement, useEffect, useRef, useState } from 'react'

import { OptionsContainer } from '../SettingField/SettingField.styled'
import * as styled from './SubWrapper.styled'

import { Icon, Name, Rotation } from 'components'
import { useOnClickOutside, useOptionsContainerPosition } from 'hooks'
import { openAllChildrenSettings } from '../RightSidebarItem'

interface Props {
  children: React.ReactNode
  title?: string
  canHide?: boolean
  onAdd?: () => void
  childrenFor: string
  small?: boolean
  noBorderBottom?: boolean
  customIcon?: ReactElement
  onAddContainer?: ReactElement
  insidePicker?: boolean
}

export const SubWrapper: React.FC<Props> = ({
  children,
  title,
  canHide,
  onAdd,
  childrenFor,
  small,
  noBorderBottom,
  customIcon,
  onAddContainer,
  insidePicker,
}) => {
  const [open, setOpen] = useState(canHide === false ? false : true)
  const [addMode, setAddMode] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const close = () => setAddMode(false)
  useOnClickOutside(ref, close)
  const [top, width, left] = useOptionsContainerPosition(ref, addMode, insidePicker)

  const onAddWrapper = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (onAdd) {
      onAdd()
    } else if (onAddContainer) {
      setAddMode(addMode => !addMode)
    }
  }

  useEffect(() => {
    setOpen(canHide === false ? false : true)
  }, [canHide])

  useEffect(() => {
    const openHandler = () => canHide && setOpen(open => !open)
    document.addEventListener(openAllChildrenSettings + childrenFor, openHandler)
    return () => document.removeEventListener(openAllChildrenSettings + childrenFor, openHandler)
  }, [childrenFor])

  return (
    <styled.Wrapper small={!!small} noBorderBottom={!!noBorderBottom}>
      {title && (
        <styled.TitleWrapper
          ref={ref}
          pointer={!!canHide}
          onClick={canHide ? () => setOpen(open => !open) : undefined}
          small={!!small}
        >
          <styled.Title grey1={!!((onAdd || onAddContainer) && !canHide)}>{title}</styled.Title>
          <styled.Actions>
            {(((onAdd || onAddContainer) && !canHide) || ((onAdd || onAddContainer) && canHide && open)) && (
              <Icon name={Name.RIGHT_SIDEBAR_ADD} onClick={onAddWrapper} />
            )}
            {canHide && <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={open ? undefined : Rotation.RIGHT} />}
            {customIcon}
          </styled.Actions>
          {onAddContainer && addMode && (
            <OptionsContainer
              top={top !== undefined ? top + 10 : top}
              width={width}
              left={left}
              insidePicker={insidePicker}
              onClick={e => e.stopPropagation()}
            >
              {cloneElement(onAddContainer, { close })}
            </OptionsContainer>
          )}
        </styled.TitleWrapper>
      )}
      {open && <styled.ChildrenWrapper small={!!small}>{children}</styled.ChildrenWrapper>}
    </styled.Wrapper>
  )
}
