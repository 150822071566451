import styled from 'styled-components'

export const AssetHead = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  height: 46px;
`

export const AssetBody = styled.div`
  padding: 10px;
`

export const AssetPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 119px;
  width: 100%;
  margin-bottom: 7px;
  & img,
  & video,
  & audio,
  & p {
    max-height: 100%;
    max-width: 100%;
  }
`

export const AssetName = styled.div`
  margin-bottom: 7px;
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  height: 18px;
`

export const AssetId = styled.div`
  margin-bottom: 7px;
  height: 18px;
`

export const AssetInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  & span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
  & svg {
    min-width: 16px;
  }
`

export const AssetOptions = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
`
