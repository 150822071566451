import styled from 'styled-components'

export enum SwitchVariant {
  DEFAULT = 'default',
  BIG = 'big',
  GREEN = 'green',
}

export const Input = styled.input`
  display: none;
  &:disabled + span {
    pointer-events: none;
    opacity: 0.3;
  }
`

export const SwitchBox = styled.span`
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  &:before {
    position: absolute;
    content: '';
    background-color: ${props => props.theme.colors.white};
    border-radius: 50%;
    transition: transform 0.3s ease;
    box-sizing: border-box;
  }
`

export const FormSwitchWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  line-height: 150%;
`

export const Label = styled.label<{ variant: SwitchVariant }>`
  position: relative;
  display: inline-block;
  ${props => {
    switch (props.variant) {
      case SwitchVariant.DEFAULT:
        return `
          width: 22px;
          height: 11px;
        `
      case SwitchVariant.GREEN:
        return `
          width: 30px;
          height: 16px;
        `
      case SwitchVariant.BIG:
        return `
          width: 52px;
          height: 32px;
        `
    }
  }}
  overflow: hidden;

  ${Input} {
    &:checked + span:before {
      ${props => {
        switch (props.variant) {
          case SwitchVariant.DEFAULT:
            return `
              transform: translateX(11px);
              border-color: ${props.theme.colors.darkText5};
            `
          case SwitchVariant.GREEN:
            return `
              transform: translateX(13px);
              border-color: #37b635;
            `
          case SwitchVariant.BIG:
            return `
              transform: translateX(18px);
              border-color: #37b635;
            `
        }
      }}
    }
    &:checked + span {
      ${props => {
        switch (props.variant) {
          case SwitchVariant.DEFAULT:
            return `
              background-color: ${props.theme.colors.darkText5};
              border: 1px solid ${props.theme.colors.darkText5};
            `
          case SwitchVariant.GREEN:
            return `
              background-color: #37b635;
              border: 1px solid #37b635;
            `
          case SwitchVariant.BIG:
            return `
              background-color: #37b635;
              border: 1px solid #37b635;
            `
        }
      }}
    }
  }
  ${SwitchBox} {
    ${props => {
      switch (props.variant) {
        case SwitchVariant.DEFAULT:
          return `
            background-color: ${props.theme.colors.grey1};
            border-radius: 6px;
            border: 1.5px solid ${props.theme.colors.grey1};
          `
        case SwitchVariant.GREEN:
          return `
            background-color: ${props.theme.colors.grey1};
            border-radius: 8px;
            border: 1.5px solid ${props.theme.colors.grey1};
          `
        case SwitchVariant.BIG:
          return `
            background-color: #81899C;
            border: 1px solid #81899C;
            border-radius: 23px;
          `
      }
    }}

    &:before {
      ${props => {
        switch (props.variant) {
          case SwitchVariant.DEFAULT:
            return `
              top: -1px;
              left: -1px;
              width: 11px;
              height: 11px;
              border: 1.5px solid ${props.theme.colors.grey1};
            `
          case SwitchVariant.GREEN:
            return `
              top: 1px;
              left: 2px;
              width: 12px;
              height: 12px;
              border: 1.5px solid ${props.theme.colors.grey1};
            `
          case SwitchVariant.BIG:
            return `
              top: 3px;
              left: 5px;
              width: 24px;
              height: 24px;
              border: 1.5px solid ${props.theme.colors.grey1};
            `
        }
      }}
    }
  }
`
