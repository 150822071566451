import React from 'react'

import * as styled from './LeftSidebar.styled'

import { ProjectMenu, ThemeToggle, Workspaces } from './components'

interface Props {
  wide: boolean
  isMobile: boolean
}

export const LeftSidebar: React.FC<Props> = ({ wide, isMobile }) => (
  <styled.Sidebar wide={wide} isMobile={isMobile}>
    <ThemeToggle wide={wide} />
    {wide ? <Workspaces isMobile={isMobile} /> : !isMobile && <ProjectMenu />}
  </styled.Sidebar>
)
