import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Stripe: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.79059 4.33847C7.03929 4.33847 6.58581 4.56919 6.58581 5.16924C6.58581 6.89238 11.9858 6.07687 12 10.6615C12 13.4153 9.97313 15 7.02524 15C5.80628 15 4.47408 14.7385 3.15593 14.123V10.4616C4.34652 11.1693 5.84884 11.6923 7.02524 11.6923C7.81883 11.6923 8.38581 11.4616 8.38581 10.7539C8.38581 8.9231 3 9.60001 3 5.3539C3 2.63074 4.91337 1 7.79059 1C8.96685 1 10.1291 1.20006 11.3055 1.70771V5.3231C10.2282 4.69226 8.86767 4.33847 7.79059 4.33847Z"
      fill="#7F899E"
    />
  </Svg>
)
