import styled from 'styled-components'
import { unreset } from 'theme'

export const RichTextWrapper = styled.div`
  cursor: auto;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.borderColor};
  transition: border-color 300ms ease-out;
  div[data-editor-block-type='image'] {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  & div[role='toolbar'] {
    position: static;
    background: ${props => props.theme.colors.primaryBackground};
    border-radius: 7px 7px 0 0;
    margin: 0;
    overflow: visible;
    z-index: unset;
    & > div {
      width: 100%;
      & > div,
      & > button {
        display: flex;
        align-items: center;
        & svg {
          width: 20px;
        }
      }
    }
    & + div {
      ${unreset}
    }
  }
  &:hover,
  &:focus-within {
    border-color: ${props => props.theme.colors.blue};
  }
  &:disabled {
    pointer-events: none;
    background: ${props => props.theme.colors.disabledBgColor};
    color: ${props => props.theme.colors.disabledTextColor};
    border-color: ${props => props.theme.colors.borderColor};
  }
`

export const RefFieldsWrapper = styled.div`
  gap: 6px;
  width: 100%;
  justify-content: flex-end;
  & > label {
    margin: 0 !important;
    width: auto;
    & > span {
      display: none;
    }
    & > div {
      height: auto;
      padding: 6px;
      & > span > div {
        right: -8px;
      }
    }
  }
`
