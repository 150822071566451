import styled from 'styled-components'
import { ThemeType } from 'theme'

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 70px;
    color: ${props => props.theme.colors.darkText1};
    text-align: center;
  }
`

export const TableHead = styled.div`
  display: flex;
  padding: 10px 15px;
  border-radius: 8px;
  background: ${props =>
    props.theme.themeType === ThemeType.LIGHT
      ? 'linear-gradient(90deg, #e6e9f0 0%, #eef1f5 100%)'
      : props.theme.colors.topPanelButtonBg};
  & > div {
    width: calc(100% / 4);
    color: ${props => props.theme.colors.darkText};
    & b {
      font-weight: 700;
    }
  }
`

export const Table = styled.div`
  margin: 0 15px 20px;
  & h2 {
    color: ${props => props.theme.colors.grey1};
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    margin: 40px 0 5px;
  }
`

export const TableRow = styled.div`
  display: flex;
  gap: 15px;
  padding: 15px 0;
  color: ${props => props.theme.colors.darkText};
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  & > div {
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    gap: 8px;
    &:first-child {
      font-weight: 700;
    }
    &:not(:first-child) {
      & svg path {
        stroke: ${props => props.theme.colors.darkText1};
      }
    }
    & svg {
      min-width: 20px;
      min-height: 20px;
    }
  }
`

export const TableBox = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
  transition: ${props => props.theme.transition.primary};
`

export const TableRefBox = styled.div``
