import React from 'react'
import { ISvg, Svg } from '../Svg'

const TextMiddle: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M13 8L3 8" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M10 4L8 6L6 4" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 2L8 5" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M10 12L8 10L6 12" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 14L8 11" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default TextMiddle
