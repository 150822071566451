import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Plans: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.12622 3.57143C2.26352 2.53429 2.71041 2.14286 3.64458 2.14286H12.0629C12.2014 2.14948 12.3322 2.2126 12.428 2.31905C12.5238 2.42551 12.5772 2.56709 12.5771 2.71429V3.57143H2.12622ZM13.6539 3.59714V2.71429C13.654 2.26405 13.4871 1.83187 13.1894 1.5111C12.8917 1.19034 12.4871 1.00675 12.0629 1H3.64458C1.30243 1 1.03591 3.02571 1.00091 4.07714C0.999697 4.08758 0.999697 4.09813 1.00091 4.10857V13.2857C1.00091 13.7404 1.17109 14.1764 1.47401 14.4979C1.77694 14.8194 2.18779 15 2.61619 15H13.3847C13.8131 15 14.224 14.8194 14.5269 14.4979C14.8298 14.1764 15 13.7404 15 13.2857V5.28571C14.9996 4.88089 14.8643 4.48928 14.6179 4.18022C14.3715 3.87117 14.03 3.66461 13.6539 3.59714ZM11.904 10.7143C11.6644 10.7143 11.4302 10.6389 11.231 10.4976C11.0318 10.3563 10.8765 10.1555 10.7848 9.92059C10.6931 9.68566 10.6691 9.42715 10.7159 9.17774C10.7626 8.92834 10.878 8.69924 11.0474 8.51943C11.2168 8.33962 11.4327 8.21717 11.6677 8.16756C11.9027 8.11795 12.1463 8.14341 12.3677 8.24073C12.589 8.33804 12.7782 8.50283 12.9113 8.71427C13.0445 8.9257 13.1155 9.17428 13.1155 9.42857C13.1159 9.59752 13.0848 9.76488 13.024 9.92104C12.9633 10.0772 12.874 10.2191 12.7615 10.3385C12.6489 10.458 12.5152 10.5527 12.3681 10.6172C12.2209 10.6817 12.0632 10.7147 11.904 10.7143Z"
      fill="#7F899E"
    />
  </Svg>
)
