import React from 'react'

import * as styled from './SettingProject.styled'

interface Props {
  name: string
  logoUrl: string
}

export const SettingProject: React.FC<Props> = ({ name, logoUrl }) => (
  <styled.Wrapper>
    <styled.Img src={logoUrl} alt="logo" />
    <styled.Title>{name}</styled.Title>
  </styled.Wrapper>
)
