import React from 'react'

import * as styled from './Table.styled'

import { Icon, Name, Popover } from 'components'
import { ITableData } from './constants'

interface Props {
  section: ITableData
}

export const TableBody: React.FC<Props> = ({ section }) => (
  <styled.Table>
    <h2>{section.title}</h2>
    {section.rows.map((el: any, index) => (
      <styled.TableRow key={`${section.title}-${index}`}>
        <div>
          <span>{el.name}</span>
          {!!el.description && <Popover text={el.description} />}
        </div>
        {['professional', 'business', 'custom'].map(key => (
          <div key={key}>
            {typeof el[key] === 'string' ? (
              <span>{el[key]}</span>
            ) : el[key] ? (
              <Icon name={Name.ADDITIONAL_PRICING_TABLE_CHECK_MARK} />
            ) : (
              <Icon name={Name.ADDITIONAL_PRICING_TABLE_CROSS} />
            )}
          </div>
        ))}
      </styled.TableRow>
    ))}
  </styled.Table>
)
