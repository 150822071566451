import React from 'react'
import { ISvg, Svg } from '../Svg'

const Button: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="2.25" y="9.25" width="27.5" height="13.5" rx="1.8967" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M5.42003 17.9357H6.77747C7.63421 17.9357 8.16551 17.5094 8.16551 16.8284C8.16551 16.3164 7.76495 15.933 7.21975 15.8928V15.8713C7.64534 15.8097 7.97357 15.4558 7.97357 15.0322C7.97357 14.4397 7.5007 14.067 6.74687 14.067H5.42003C5.15577 14.067 5 14.2225 5 14.4879V17.5121C5 17.7802 5.15577 17.9357 5.42003 17.9357ZM5.84006 15.6756V14.6702H6.5299C6.92211 14.6702 7.14743 14.8472 7.14743 15.1582C7.14743 15.4853 6.89152 15.6756 6.44367 15.6756H5.84006ZM5.84006 17.3324V16.2091H6.54381C7.03894 16.2091 7.30876 16.4021 7.30876 16.7641C7.30876 17.134 7.04729 17.3324 6.56328 17.3324H5.84006Z"
      fill="#7F899E"
    />
    <path
      d="M10.3268 18C11.3533 18 12.007 17.4209 12.007 16.5684V14.437C12.007 14.1689 11.8512 14.0134 11.5869 14.0134C11.3227 14.0134 11.1669 14.1689 11.1669 14.437V16.4879C11.1669 16.9839 10.8637 17.3083 10.3268 17.3083C9.7872 17.3083 9.48401 16.9839 9.48401 16.4879V14.437C9.48401 14.1689 9.32823 14.0134 9.06398 14.0134C8.79972 14.0134 8.64395 14.1689 8.64395 14.437V16.5684C8.64395 17.4209 9.29764 18 10.3268 18Z"
      fill="#7F899E"
    />
    <path
      d="M14.057 17.9866C14.3213 17.9866 14.4771 17.8311 14.4771 17.5657V14.7373H15.3088C15.5341 14.7373 15.6871 14.6113 15.6871 14.4021C15.6871 14.193 15.5369 14.067 15.3088 14.067H12.8081C12.5772 14.067 12.427 14.193 12.427 14.4021C12.427 14.6113 12.58 14.7373 12.8081 14.7373H13.637V17.5657C13.637 17.8311 13.7928 17.9866 14.057 17.9866Z"
      fill="#7F899E"
    />
    <path
      d="M17.484 17.9866C17.7483 17.9866 17.904 17.8311 17.904 17.5657V14.7373H18.7357C18.9611 14.7373 19.114 14.6113 19.114 14.4021C19.114 14.193 18.9638 14.067 18.7357 14.067H16.235C16.0042 14.067 15.854 14.193 15.854 14.4021C15.854 14.6113 16.007 14.7373 16.235 14.7373H17.064V17.5657C17.064 17.8311 17.2197 17.9866 17.484 17.9866Z"
      fill="#7F899E"
    />
    <path
      d="M21.1196 18C22.3074 18 23.0556 17.2332 23.0556 16.0027C23.0556 14.7694 22.3074 14 21.1196 14C19.9291 14 19.1836 14.7694 19.1836 16.0027C19.1836 17.2332 19.9291 18 21.1196 18ZM21.1196 17.3244C20.4604 17.3244 20.0403 16.8123 20.0403 16.0027C20.0403 15.1903 20.4631 14.6756 21.1196 14.6756C21.7761 14.6756 22.1961 15.1903 22.1961 16.0027C22.1961 16.8123 21.7761 17.3244 21.1196 17.3244Z"
      fill="#7F899E"
    />
    <path
      d="M24.0014 17.9866C24.2517 17.9866 24.4019 17.8391 24.4019 17.5818V15.4021H24.427L26.1683 17.7534C26.2962 17.9249 26.4075 17.9866 26.5883 17.9866C26.8526 17.9866 27 17.8445 27 17.5925V14.4209C27 14.1609 26.8526 14.0134 26.5994 14.0134C26.3463 14.0134 26.1961 14.1609 26.1961 14.4209V16.5871H26.1739L24.4381 14.2493C24.3102 14.0804 24.1933 14.0134 24.0209 14.0134C23.7538 14.0134 23.5981 14.1609 23.5981 14.4182V17.5818C23.5981 17.8391 23.7483 17.9866 24.0014 17.9866Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Button
