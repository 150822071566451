import { Box } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'

import * as styled from './About.styled'

import { Menu, PWAPreview } from 'components'
import { useScreenshotFromVB } from 'hooks'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { Id } from 'partials/RightSidebar/settings/common/Id'
import { Collection } from 'utils'

const cmsOpenRightSidebarKey = 'cmsOpenRightSidebarKey'
const getCMSOpenRightSidebarController = () => JSON.parse(localStorage.getItem(cmsOpenRightSidebarKey) || '{}')
const setCMSOpenRightSidebarController = (controller: any) =>
  localStorage.setItem(cmsOpenRightSidebarKey, JSON.stringify(controller))

interface Props {
  title: string
  currentCollection: Collection
  collectionId: string
  localStorageCollectionId: string
}

export const About: React.FC<Props> = memo(({ title, currentCollection, collectionId, localStorageCollectionId }) => {
  const controller = getCMSOpenRightSidebarController()
  const openByDefault = controller[localStorageCollectionId]
  const [screenPreview, setScreenPreview] = useState('')
  const getScreenshotFromVB = useScreenshotFromVB()
  const previewScreen = currentCollection.previewScreen

  useEffect(() => {
    let abort = false
    setScreenPreview('')
    if (previewScreen) {
      getScreenshotFromVB(previewScreen).then(res => !abort && setScreenPreview(res))
    }
    return () => {
      abort = true
    }
  }, [previewScreen, getScreenshotFromVB])

  const isOpen = (open: boolean) => {
    if (openByDefault !== open) {
      controller[localStorageCollectionId] = open
      setCMSOpenRightSidebarController(controller)
    }
  }

  const sectionName = title

  return (
    <Menu
      right
      openByDefault={openByDefault}
      isOpen={isOpen}
      firstChild={
        <RightSidebarItem title={sectionName}>
          <SubWrapper childrenFor={sectionName}>
            <Id value={collectionId} />
          </SubWrapper>
          <SubWrapper title="About" childrenFor={sectionName}>
            <styled.Text>{currentCollection.helpText || 'No description'}</styled.Text>
          </SubWrapper>
          {screenPreview && (
            <Box p="10px 0">
              <PWAPreview noTransform>
                <img src={screenPreview} alt="preview" />
              </PWAPreview>
            </Box>
          )}
        </RightSidebarItem>
      }
    />
  )
})
