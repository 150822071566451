import React from 'react'

import { Name } from 'components'
import { SwitchedEdit } from '../../components'
import { ValueType } from 'utils'

export const Providers: React.FC = () => (
  <SwitchedEdit
    title="Model Providers"
    subTitle="Choose the AI model providers you’d like to enable for your application."
    items={[
      {
        id: '0',
        title: 'Vertex AI',
        subTitle: 'Access models like PaLM and upcoming ones like Gemini through Google’s Vertex AI.',
        iconName: Name.SECOND_ORDER_PROVIDERS_VERTEX,
        checked: false,
      },
      {
        id: '1',
        title: 'Google AI',
        subTitle: 'Utilize Google’s AI models, including LaMDA, for natural language understanding.',
        iconName: Name.SECOND_ORDER_PROVIDERS_GOOGLE,
        checked: true,
      },
      {
        id: '2',
        title: 'OpenAI',
        subTitle: 'Leverage OpenAI’s GPT-4 and GPT-3.5 models for advanced language processing.',
        iconName: Name.SECOND_ORDER_PROVIDERS_OPEN_AI,
        checked: false,
      },
    ]}
    properties={[
      {
        name: 'GOOGLE_GENAI_API_KEY',
        type: ValueType.variable,
        position: 0,
        helpText: 'Publishable key',
        hideLabel: true,
      },
    ]}
  />
)
