import { Autocomplete, TextField } from '@mui/material'
import { memo } from 'react'

import { AutocompleteWrapper, CustomPaper, ListOption } from '../../FieldTypes.styled'

import { isUrl, ValueType } from 'utils'
import { FieldProps } from '../../types'

interface SimpleArrayInputProps extends FieldProps {
  value: string[] | number[] | boolean[] | null
  onChange: (val: string[] | number[] | boolean[] | null) => void
}

export const SimpleArrayInput = memo((props: SimpleArrayInputProps) => (
  <AutocompleteWrapper>
    <Autocomplete
      size="small"
      fullWidth
      multiple
      freeSolo
      options={props.accept === ValueType.boolean ? [true, false] : []}
      isOptionEqualToValue={() => false}
      // @ts-ignore
      value={props.value || []}
      onChange={(e, v) => {
        const value = (
          props.accept === ValueType.number
            ? v.map(el => +el)
            : props.accept === ValueType.boolean
            ? v
                .filter(el => el === true || el === false || el === 'true' || el === 'false')
                .map(el => (el === 'true' ? true : el === 'false' ? false : el))
            : props.accept === ValueType.url
            ? v.filter(el => isUrl(el as string))
            : v
        ) as string[] | number[] | boolean[]
        props.onChange(value.length ? value : null)
      }}
      PaperComponent={CustomPaper}
      renderInput={params => (
        <TextField
          type={props.accept === ValueType.number ? 'number' : 'text'}
          {...params}
          placeholder={`Type the ${props.accept} value and hit enter`}
        />
      )}
      renderOption={(props, option) => <ListOption {...props}>{String(option)}</ListOption>}
      getOptionLabel={option => String(option)}
    />
  </AutocompleteWrapper>
))

interface MultipleSelectProps extends FieldProps {
  value: string[] | null
  onChange: (val: string[] | null) => void
}

export const MultipleSelect = memo((props: MultipleSelectProps) => (
  <AutocompleteWrapper>
    <Autocomplete
      size="small"
      fullWidth
      multiple
      options={props.optionsToSelect?.filter(el => !props.value?.includes(el.value)) || []}
      // @ts-ignore
      isOptionEqualToValue={(option, value) => option.value === value}
      // @ts-ignore
      value={props.value || []}
      onChange={(e, v) => {
        const value = v.map(el => (el.value ? el.value : el)) as string[]
        props.onChange(value.length ? value : null)
      }}
      PaperComponent={CustomPaper}
      renderInput={params => <TextField type="text" {...params} placeholder={props.placeholder} />}
      renderOption={(props, option) => <ListOption {...props}>{option.label}</ListOption>}
      // @ts-ignore
      getOptionLabel={option => props.optionsToSelect?.find(el => el.value === option)?.label || option.label || option}
    />
  </AutocompleteWrapper>
))

interface SimpleSelectProps extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const SimpleSelect = memo((props: SimpleSelectProps) => (
  <AutocompleteWrapper>
    <Autocomplete
      size="small"
      fullWidth
      options={props.optionsToSelect || []}
      // @ts-ignore
      isOptionEqualToValue={(option, value) => option.value === value}
      // @ts-ignore
      value={props.value || undefined}
      // @ts-ignore
      onChange={(e, v) => props.onChange(v?.value || v || null)}
      PaperComponent={CustomPaper}
      renderInput={params => <TextField type="text" {...params} placeholder={props.placeholder} />}
      renderOption={(props, option) => <ListOption {...props}>{option.label}</ListOption>}
      // @ts-ignore
      getOptionLabel={option => props.optionsToSelect?.find(el => el.value === option)?.label || option.label || option}
    />
  </AutocompleteWrapper>
))
