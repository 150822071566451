import React from 'react'

import { Connections, CustomSectionsWrapper, Notification, NotificationColor, Text, Type } from 'components'
import { Links, SettingsLayout } from '../components'

export const AppStore: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>App Store</Text>
    <Text type={Type.SUB_TITLE}>
      Automate app distribution, manage in-app purchases and subscriptions on the App Store.
    </Text>
    <Links />
    <CustomSectionsWrapper>
      <Notification
        color={NotificationColor.RED}
        open
        inside
        title="2-Factor verification required"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
        onFix={() => {}}
      />
      <Connections appStore />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
