import React, { useContext, useState } from 'react'

import * as styled from '../Account.styled'

import { Button, ButtonColor, CustomSection, CustomSectionTitle, FormSwitch, Icon, Name, getField } from 'components'
import { ProgressContext } from 'context'
import firebase from 'services/firebase'
import { ValueType } from 'utils'

interface Props {
  user: firebase.User
}

export const LoginMethods: React.FC<Props> = ({ user }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [sent, setSent] = useState(false)

  const detectProvider = (providerId: string) => !!user.providerData.find(el => el?.providerId === providerId)

  const sendEmailVerification = () => {
    startLoader()
    user
      .sendEmailVerification()
      .then(() => setSent(true))
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }

  return (
    <CustomSection>
      <CustomSectionTitle mb="0">Login methods</CustomSectionTitle>
      {getField(ValueType.string)?.component({
        name: 'email',
        value: user.email,
        label: 'Email address',
        placeholder: '',
        onChange: () => {},
        smallWidth: true,
        disabled: true,
        hint: (
          <styled.Hint>
            {user.emailVerified ? (
              <span>
                <Icon name={Name.ADDITIONAL_OK_LIGHT} />
                verified
              </span>
            ) : (
              <>
                <span>not verified</span>
                {!sent ? (
                  <Button onClick={sendEmailVerification}>Send verification email</Button>
                ) : (
                  <>
                    <Button onClick={() => window.location.reload()}>Refresh</Button>
                    <Button color={ButtonColor.SECONDARY} onClick={sendEmailVerification}>
                      Resend
                    </Button>
                  </>
                )}
              </>
            )}
          </styled.Hint>
        ),
      })}
      <styled.Switches>
        <FormSwitch label="Password" value={detectProvider('password')} disabled />
        <FormSwitch label="Github" value={detectProvider('github.com')} disabled />
        <FormSwitch label="Google" value={detectProvider('google.com')} disabled />
      </styled.Switches>
    </CustomSection>
  )
}
