import React from 'react'

import { BooleanInput } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen } from 'utils'
import { ScreenSetting } from '../ScreenSetting'

interface Props {
  onScreenPropertiesChange: (props: { propName: keyof Screen; value: any; resources?: Resource[] }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Paywall: React.FC<Props> = ({ onScreenPropertiesChange, screenConfig, allLocalVariables }) => {
  const { enabled, screenName } = screenConfig

  return (
    <>
      <ScreenSetting
        onScreenPropertiesChange={onScreenPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <RightSidebarItem title={screenName} canHide>
        <SubWrapper title="Options" childrenFor={screenName} canHide>
          <BooleanInput
            subLabel="Enable paywall screen"
            value={enabled}
            onChange={value => onScreenPropertiesChange([{ propName: 'enabled', value }])}
          />
          <BooleanInput
            subLabel="Enable special offer screen"
            value={enabled}
            onChange={value => onScreenPropertiesChange([{ propName: 'enabled', value }])}
          />
        </SubWrapper>
      </RightSidebarItem>
    </>
  )
}
