import React from 'react'
import { ISvg, Svg } from '../Svg'

const Screen: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="4.5" y="1.5" width="7" height="13" rx="1.5" stroke="#7F899E" />
    <path d="M7 3H9" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Screen
