import { Box } from '@mui/material'
import React from 'react'

import * as styled from './Settings.styled'

import { AppInfo, Integrations, Variables } from './components'

export const Settings: React.FC = () => (
  <styled.Container>
    <Box display="flex" flexDirection="column" gap="15px" p="30px 0 60px" maxWidth="950px">
      <AppInfo />
      <Integrations />
      <Variables />
    </Box>
  </styled.Container>
)
