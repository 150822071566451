import styled from 'styled-components'
import { flexbox, height, layout, space, width, position, border, typography } from 'styled-system'
import { CodePlatformTheme } from 'theme'

interface IStyledText {
  theme: CodePlatformTheme
  color?: string
  hover?: string
  inherit?: boolean
}

export const HugeTitle = styled.h1<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: 36px/54px ${(props: IStyledText) => props.theme.fonts.primary};
  font-weight: ${(props: IStyledText) => props.theme.weights.medium};
  ${typography}
  @media (max-width: 900px) {
    font-size: 24px;
    font-weight: ${props => props.theme.weights.bold};
  }
`

export const H1 = styled.h1<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: 22px/33px ${(props: IStyledText) => props.theme.fonts.primary};
  ${typography}
`

export const H2 = styled.h2<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: 18px/27px ${(props: IStyledText) => props.theme.fonts.primary};
  font-weight: ${(props: IStyledText) => props.theme.weights.bold};
  ${typography}
`

export const H3 = styled.h3<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: 14px/21px ${(props: IStyledText) => props.theme.fonts.primary};
  font-weight: ${(props: IStyledText) => props.theme.weights.medium};
  ${(props: IStyledText) => {
    if (props.hover) {
      return `&:hover {
        ${props.hover}
      }`
    }
  }}
  ${typography}
`

export const H4 = styled.h4<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: 13px/20px ${(props: IStyledText) => props.theme.fonts.primary};
  ${typography}
`

export const Body = styled.div<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: ${(props: IStyledText) => (props.inherit ? 'inherit' : `12px/18px ${props.theme.fonts.primary}`)};
  font-weight: ${(props: IStyledText) => props.theme.weights.normal};
  ${space}
  ${layout}
  ${flexbox}
  ${width}
  ${height}
  ${position}
  ${border}
  ${typography}
`

export const H6 = styled.h6<IStyledText>`
  color: ${(props: IStyledText) => (props.color ? props.color : props.theme.colors.darkText)};
  font: 10px/15px ${(props: IStyledText) => props.theme.fonts.primary};
  ${typography}
`

export const Title = styled.h1`
  color: ${props => (props.color ? props.color : props.theme.colors.darkText)};
  font-weight: ${props => props.theme.weights.bold};
  font-size: 22px;
  line-height: 33px;
`

export const SubTitle = styled.p`
  color: ${props => (props.color ? props.color : props.theme.colors.darkBlue_500)};
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
`
