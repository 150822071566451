import { useState } from 'react'

export const useHistoryState = (
  initialValue: any
): [any, (val: any | ((prev: any) => any)) => void, () => void, () => void] => {
  const [state, _setState] = useState({
    data: initialValue,
    history: [initialValue],
    pointer: 0,
  })

  const undo = () => {
    _setState(state => {
      const newState = {
        ...state,
        data: state.pointer <= 1 ? state.data : state.history[state.pointer - 1],
        pointer: state.pointer <= 1 ? state.pointer : state.pointer - 1,
      }
      return JSON.stringify(newState) === JSON.stringify(state) ? state : newState
    })
  }

  const redo = () => {
    _setState(state => {
      const newState = {
        ...state,
        data: state.pointer + 1 >= state.history.length ? state.data : state.history[state.pointer + 1],
        pointer: state.pointer + 1 >= state.history.length ? state.pointer : state.pointer + 1,
      }
      return JSON.stringify(newState) === JSON.stringify(state) ? state : newState
    })
  }

  const setState = (value: any[] | ((prev: any[]) => any[])) => {
    _setState(state => {
      let newValue = value
      if (typeof value === 'function') {
        newValue = value(state.data)
      }
      if (JSON.stringify(newValue) === JSON.stringify(state.data)) {
        return state
      }
      return {
        data: newValue,
        history: [...state.history.slice(0, state.pointer + 1), newValue],
        pointer: state.pointer + 1,
      }
    })
  }

  return [state.data, setState, undo, redo]
}
