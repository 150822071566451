import styled from 'styled-components'
import { CodePlatformTheme } from 'theme'

export interface IStyledIcon {
  theme: CodePlatformTheme
  active?: boolean
}

export const IconWrapper = styled.div<IStyledIcon>`
  padding: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.active ? props.theme.colors.settingField : 'transparent')};
  transition: 300ms ease-out;
  & path {
    transition: 300ms ease-out;
    &[stroke] {
      stroke: ${props => props.active && props.theme.colors.iconHover};
    }
    &[fill] {
      fill: ${props => props.active && props.theme.colors.iconHover};
    }
  }
  &:hover {
    background-color: ${props => props.theme.colors.settingField};
    & path {
      &[stroke] {
        stroke: ${props => props.theme.colors.iconHover};
      }
      &[fill] {
        fill: ${props => props.theme.colors.iconHover};
      }
    }
  }
`
