import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const ProjectSettings: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12.5824 1.78307C12.4407 1.54667 12.2389 1.35029 11.9968 1.21306C11.7546 1.07582 11.4804 1.00242 11.2007 1H4.79934C4.51964 1.00242 4.24536 1.07582 4.00321 1.21306C3.76106 1.35029 3.5593 1.54667 3.4176 1.78307L0.216935 7.21693C0.0748182 7.45823 0 7.73195 0 8.01058C0 8.28921 0.0748182 8.56293 0.216935 8.80423L3.4176 14.2169C3.5593 14.4533 3.76106 14.6497 4.00321 14.7869C4.24536 14.9242 4.51964 14.9976 4.79934 15H11.2007C11.4804 14.9976 11.7546 14.9242 11.9968 14.7869C12.2389 14.6497 12.4407 14.4533 12.5824 14.2169L15.7831 8.78307C15.9252 8.54177 16 8.26805 16 7.98942C16 7.71079 15.9252 7.43707 15.7831 7.19577L12.5824 1.78307ZM10.6987 8C10.6987 8.52323 10.5404 9.03471 10.2439 9.46976C9.94735 9.90481 9.52587 10.2439 9.03275 10.4441C8.53963 10.6444 7.99701 10.6967 7.47351 10.5947C6.95001 10.4926 6.46915 10.2406 6.09173 9.87065C5.71431 9.50067 5.45728 9.02929 5.35315 8.51611C5.24902 8.00293 5.30246 7.47101 5.50672 6.98761C5.71098 6.50421 6.05688 6.09104 6.50068 5.80035C6.94448 5.50965 7.46625 5.3545 8 5.3545C8.71574 5.3545 9.40217 5.63322 9.90827 6.12935C10.4144 6.62547 10.6987 7.29837 10.6987 8Z"
      fill="#7F899E"
    />
  </Svg>
)
