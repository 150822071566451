import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { SettingsLayout } from '../components'
import { Limits, Providers, Users } from './sections'

export const Authentication: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Users & Authentication</Text>
    <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
      Manage user accounts and authentication settings for your app.
    </Text>
    <CustomSectionsWrapper>
      <Providers />
      <Limits />
      <Users />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
