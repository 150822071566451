import React, { useEffect, useMemo, useState } from 'react'

import * as styled from './TheadController.styled'

import { Icon, Name, Switch } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { CMSCollectionProperty } from 'services/cms'
import { firstLetterUpperCase, moveTopBottomInList } from 'utils'

interface Props {
  properties: CMSCollectionProperty[]
  setProperties: React.Dispatch<React.SetStateAction<CMSCollectionProperty[]>>
  onStateChange: (val: boolean, propertyId: string) => void
}

export const Movable: React.FC<Props> = ({ properties, setProperties, onStateChange }) => {
  const [copy, setCopy] = useState(properties)

  useEffect(() => {
    setCopy(properties)
  }, [JSON.stringify(properties)])

  const activeColumns = useMemo(() => copy.filter(el => el.activeInTable), [copy])

  const onMovableMouseDown = (e: any, property: CMSCollectionProperty) => {
    const node = e.target?.parentNode?.parentNode?.parentNode as HTMLElement
    moveTopBottomInList(node, 0, property, copy, setCopy, setProperties, activeColumns)
  }

  return (
    <>
      {!!activeColumns.length && (
        <styled.Items>
          {activeColumns.map(el => (
            <SettingField
              leftIcon={
                <styled.PropertyMove onMouseDown={e => onMovableMouseDown(e, el)}>
                  <Icon name={Name.ADDITIONAL_MOVABLE} width={22} height={22} />
                </styled.PropertyMove>
              }
              key={el.id}
              transparent
              value={firstLetterUpperCase(el.name)}
              actions={<Switch checked={!!el.activeInTable} onChange={value => onStateChange(value, el.id)} />}
            />
          ))}
        </styled.Items>
      )}
    </>
  )
}
