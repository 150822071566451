import React, { useContext } from 'react'

import { ProjectContext } from 'context'
import { Config, Resource, Screen, detectEmptyValue, getAllLocalVariables } from 'utils'
import { BrandingColorInput } from './BrandingColorInput'

interface Props {
  big?: boolean
  label?: string
  subLabel?: string
}

export const LaunchScreenBackgroundInput: React.FC<Props> = ({ big, label, subLabel }) => {
  const {
    state: { screens = [] },
    setState,
  } = useContext(ProjectContext)
  const screenConfig = screens.find(el => el.screenName === 'Launch Screen') as Screen
  const { backgroundColor } = screenConfig
  const allLocalVariables = getAllLocalVariables(screenConfig, screenConfig)

  const onScreenPropertiesChange = (props: { propName: keyof Screen; value: any; resources?: Resource[] }[]) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const screen = stateCopy.screens?.find(el => el.screenName === 'Launch Screen')
      if (screen) {
        props.forEach(({ propName, value, resources }) => {
          if (detectEmptyValue(value)) {
            delete screen[propName]
          } else {
            screen[propName] = value as never
          }
          if (resources) {
            stateCopy.resources = resources
          }
        })
      }
      return stateCopy
    })
  }

  return (
    <BrandingColorInput
      big={big}
      label={label}
      subLabel={subLabel}
      value={backgroundColor?.constant}
      onChange={value => onScreenPropertiesChange([{ propName: 'backgroundColor', value: { constant: value } }])}
      source={backgroundColor?.source}
      onSourceChange={value => onScreenPropertiesChange([{ propName: 'backgroundColor', value: { source: value } }])}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  )
}
