import React from 'react'

import { ValueType } from 'utils'
import { SwitchedEdit } from '../../components'

export const Limits: React.FC = () => (
  <SwitchedEdit
    title="Access Limits"
    subTitle="Accept payments in your app with Stripe integration. This also enables credit card, Google Pay, and Apple Pay. Setup is easy and only requires a few extra steps to get started."
    items={[
      {
        id: '0',
        title: 'Allow Sign-in from specific domains',
        subTitle: 'Users get an email with a PIN to sign-in',
        checked: true,
      },
      {
        id: '1',
        title: 'Allow Sign-in if invited ',
        subTitle: 'Users must have entries in the user profile table',
        checked: false,
      },
    ]}
    properties={[
      {
        name: 'domains',
        type: ValueType.array,
        accept: ValueType.string,
        position: 0,
        hideLabel: true,
      },
    ]}
  />
)
