import { useSearchParams } from 'react-router-dom'

export const useParamsFromUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const conversationIdParamName = 'conversationId'
  const successParamName = 'success'
  const canceledParamName = 'canceled'
  const checkoutSessionIdParamName = 'sessionId'
  const intentParamName = 'intent'
  const templateNameParamName = 'templateName'
  const templateIdParamName = 'templateId'
  const workspaceIdParamName = 'workspaceId'
  const planPriceIdName = 'planPriceId'
  const pageParamName = 'page'
  const codeplatform_ga_session_idParamName = 'codeplatform_ga_session_id'
  const userAgentParamName = 'userAgent'
  const initialMessageParamName = 'initialMessage'

  const conversationId = searchParams.get(conversationIdParamName)
  const success = searchParams.get(successParamName)
  const canceled = searchParams.get(canceledParamName)
  const checkoutSessionId = searchParams.get(checkoutSessionIdParamName)
  const intent = searchParams.get(intentParamName)
  const templateName = searchParams.get(templateNameParamName)
  const templateId = searchParams.get(templateIdParamName)
  const workspaceId = searchParams.get(workspaceIdParamName)
  const planPriceId = searchParams.get(planPriceIdName)
  const page = searchParams.get(pageParamName)
  const codeplatform_ga_session_id = searchParams.get(codeplatform_ga_session_idParamName)
  const userAgent = searchParams.get(userAgentParamName)
  const initialMessage = searchParams.get(initialMessageParamName)

  const unsetParams = () => {
    searchParams.delete(conversationIdParamName)
    searchParams.delete(successParamName)
    searchParams.delete(canceledParamName)
    searchParams.delete(checkoutSessionIdParamName)
    searchParams.delete(intentParamName)
    searchParams.delete(templateNameParamName)
    searchParams.delete(templateIdParamName)
    searchParams.delete(workspaceIdParamName)
    searchParams.delete(planPriceIdName)
    searchParams.delete(pageParamName)
    searchParams.delete(codeplatform_ga_session_idParamName)
    searchParams.delete(userAgentParamName)
    searchParams.delete(initialMessageParamName)
  }

  const setConversationIdToUrl = (conversationId: string) => {
    unsetParams()
    searchParams.set(conversationIdParamName, conversationId)
    setSearchParams(searchParams)
  }

  const setIntentToUrl = (intent: string) => {
    searchParams.set(intentParamName, intent)
    setSearchParams(searchParams)
  }

  const setParamsToUrl = (intent: string, templateId: string, workspaceId: string) => {
    searchParams.set(intentParamName, intent)
    searchParams.set(templateIdParamName, templateId)
    searchParams.set(workspaceIdParamName, workspaceId)
    setSearchParams(searchParams)
  }

  const clearCheckoutParams = () => {
    searchParams.delete(successParamName)
    searchParams.delete(canceledParamName)
    searchParams.delete(checkoutSessionIdParamName)
    setSearchParams(searchParams)
  }

  const clearParams = () => {
    unsetParams()
    setSearchParams(searchParams)
  }

  return {
    conversationId,
    success,
    canceled,
    checkoutSessionId,
    intent,
    templateName,
    templateId,
    workspaceId,
    planPriceId,
    page,
    codeplatform_ga_session_id,
    userAgent,
    initialMessage,
    planPriceIdName,
    setConversationIdToUrl,
    setIntentToUrl,
    setParamsToUrl,
    clearCheckoutParams,
    clearParams,
  }
}

export const usePlanParamsFromUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const projectPlanPriceIdName = 'projectPlan'
  const workspacePlanPriceIdName = 'workspacePlan'

  const projectPlanPriceId = searchParams.get(projectPlanPriceIdName)
  const workspacePlanPriceId = searchParams.get(workspacePlanPriceIdName)

  const clearParams = () => {
    searchParams.delete(projectPlanPriceIdName)
    searchParams.delete(workspacePlanPriceIdName)
    setSearchParams(searchParams)
  }

  const addPlanParamsIfExists = () =>
    projectPlanPriceId
      ? `?${projectPlanPriceIdName}=${projectPlanPriceId}`
      : workspacePlanPriceId
      ? `?${workspacePlanPriceIdName}=${workspacePlanPriceId}`
      : ''

  return {
    projectPlanPriceId,
    workspacePlanPriceId,
    clearParams,
    addPlanParamsIfExists,
  }
}
