import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'

import { CommonPicker, ComponentPreview, ComponentStylePicker, Icon, Name, SourceInput } from 'components'
import { ProjectContext } from 'context'
import { useVariable } from 'hooks'
import {
  RightSidebarItem,
  SettingField,
  SettingNumberField,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import {
  ComponentStyle,
  ComponentsDirection,
  LocalVariable,
  Resource,
  Screen,
  ScreenComponent,
  VariableSource,
  findListItemBindedComponents,
  firstLetterUpperCase,
  getAllLocalVariables,
  listEmptyName,
  listFooterName,
  listHeaderName,
  listItemName,
} from 'utils'
import { Actions, Common, Id, LocalVariables } from '../common'
import { getEmptyComponent } from './constants'

const getComponentStyleIconName = (componentStyle?: ComponentStyle) => {
  switch (componentStyle) {
    case ComponentStyle.column:
      return Name.PICKERS_COLUMN
    case ComponentStyle.row:
      return Name.PICKERS_ROW
    case ComponentStyle.grid:
      return Name.PICKERS_GRID
    case ComponentStyle.swipeable:
      return Name.PICKERS_SWIPEABLE
    default:
      return Name.RIGHT_SIDEBAR_DEFAULT_ICON
  }
}

const styleValues = Object.values(ComponentStyle).map(el => ({
  value: el,
  label: firstLetterUpperCase(el),
  iconName: getComponentStyleIconName(el),
}))

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  onSubComponentStyleChange: (componentId: string) => (config: ScreenComponent) => void
  onSubComponentPropertiesChange: (
    componentId: string
  ) => (props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ListSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  onSubComponentStyleChange,
  onSubComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)
  const { removeOldLocalVariable } = useVariable()
  const { id, componentType, name, componentsSpacing, componentStyle, subComponents, listItems, listItemContextKey } =
    component
  const listItem = useMemo(() => subComponents?.find(el => el.name === listItemName), [subComponents])
  const listEmpty = useMemo(() => subComponents?.find(el => el.name === listEmptyName), [subComponents])
  const listHeader = useMemo(() => subComponents?.find(el => el.name === listHeaderName), [subComponents])
  const listFooter = useMemo(() => subComponents?.find(el => el.name === listFooterName), [subComponents])
  const bindedComponents = useMemo(
    () =>
      listItem?.subComponents && listItemContextKey
        ? findListItemBindedComponents(listItem.subComponents, listItemContextKey)
        : [],
    [listItem?.subComponents, listItemContextKey]
  )

  const onDataChange = (value?: VariableSource) => {
    if (listItemContextKey) {
      removeOldLocalVariable(screenConfig, id, listItemContextKey)
    }
    onComponentPropertiesChange([{ propName: 'listItems', value: { source: value } }])
  }

  const onListItemContextKeyChange = (value: string) => {
    if (listItemContextKey) {
      removeOldLocalVariable(screenConfig, id, listItemContextKey)
    }
    onComponentPropertiesChange([{ propName: 'listItemContextKey', value }])
  }

  const setEmptyComponent = (set: boolean) =>
    subComponents &&
    onComponentPropertiesChange([
      {
        propName: 'subComponents',
        value: set
          ? [...subComponents, getEmptyComponent(language)]
          : subComponents.filter(el => el.name !== listEmptyName),
      },
    ])

  const itemsSectionName = 'Item'
  const emptySectionName = 'Empty'
  const headerSectionName = 'Header'
  const footerSectionName = 'Footer'

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <SourceInput
          label="Data"
          source={listItems?.source}
          onSourceChange={onDataChange}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          dataMode
        />
        <SettingTextField label="Item context key" value={listItemContextKey} onChange={onListItemContextKeyChange} />
        <SettingField
          label="Style"
          value={componentStyle ? firstLetterUpperCase(componentStyle) : undefined}
          leftIcon={<Icon name={getComponentStyleIconName(componentStyle)} />}
          optionsContainer={
            <CommonPicker
              title="Styles"
              value={componentStyle}
              onChange={value =>
                onComponentPropertiesChange([
                  { propName: 'componentStyle', value },
                  {
                    propName: 'componentsDirection',
                    value:
                      value === ComponentStyle.column
                        ? ComponentsDirection.vertical
                        : value === ComponentStyle.row
                        ? ComponentsDirection.horizontal
                        : value === ComponentStyle.swipeable
                        ? ComponentsDirection.horizontal
                        : ComponentsDirection.all,
                  },
                ])
              }
              values={styleValues}
              close={() => {}}
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Components" canHide childrenFor={componentType}>
        <Box display="flex" flexDirection="column" gap="12px">
          <RightSidebarItem small title={itemsSectionName}>
            <SubWrapper small childrenFor={itemsSectionName}>
              {listItem && (
                <SettingField
                  label="Style"
                  leftIcon={<ComponentPreview screenConfig={screenConfig} componentConfig={listItem} listId={id} />}
                  canOpenStylePickerFromScreen
                  optionsContainer={
                    <ComponentStylePicker
                      componentId={listItem.id}
                      screenConfig={screenConfig}
                      onChange={onSubComponentStyleChange(listItem.id)}
                      close={() => {}}
                      listId={id}
                    />
                  }
                />
              )}
              {bindedComponents.map((el, i) => {
                // @ts-ignore
                const fieldData = el.component[el.field]
                const source = el.field === 'text' ? fieldData.values[0] : fieldData.source
                const allLocalVariables = getAllLocalVariables(screenConfig, el.component)
                return (
                  <SourceInput
                    key={el.component.id}
                    label={i === 0 ? 'Data' : ''}
                    valueDescription={`<b>${el.component.name || el.component.componentType}</b> - `}
                    source={source}
                    onSourceChange={value =>
                      onSubComponentPropertiesChange(el.component.id)([
                        {
                          propName: el.field as keyof ScreenComponent,
                          value:
                            el.field === 'text' ? { ...fieldData, values: value ? [value] : [] } : { source: value },
                        },
                      ])
                    }
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                )
              })}
            </SubWrapper>
          </RightSidebarItem>
          <RightSidebarItem small title={emptySectionName} switchValue={!!listEmpty} onSwitchChange={setEmptyComponent}>
            <SubWrapper small childrenFor={emptySectionName}>
              <SettingTextField label="Title" value="News" onChange={() => {}} />
            </SubWrapper>
          </RightSidebarItem>
          <RightSidebarItem small title={headerSectionName} switchValue={!!listHeader} onSwitchChange={() => {}}>
            <SubWrapper small childrenFor={headerSectionName}>
              <SettingTextField label="Title" value="News" onChange={() => {}} />
            </SubWrapper>
          </RightSidebarItem>
          <RightSidebarItem small title={footerSectionName} switchValue={!!listFooter} onSwitchChange={() => {}}>
            <SubWrapper small childrenFor={footerSectionName}>
              <SettingTextField label="Title" value="News" onChange={() => {}} />
            </SubWrapper>
          </RightSidebarItem>
        </Box>
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
        hideLocalVariables
        hideActions
        moreSettings={
          <SettingNumberField
            subLabel="Spacing"
            value={componentsSpacing?.constant}
            onChange={value =>
              onComponentPropertiesChange([{ propName: 'componentsSpacing', value: { constant: value } }])
            }
            source={componentsSpacing?.source}
            onSourceChange={value =>
              onComponentPropertiesChange([{ propName: 'componentsSpacing', value: { source: value } }])
            }
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        }
      />
      {listItem && (
        <>
          <LocalVariables
            childrenFor={componentType}
            component={listItem}
            onChange={value => onSubComponentPropertiesChange(listItem.id)([{ propName: 'localVariables', value }])}
            screenConfig={screenConfig}
            allLocalVariables={getAllLocalVariables(screenConfig, listItem)}
          />
          <Actions
            childrenFor={componentType}
            component={listItem}
            onChange={value => onSubComponentPropertiesChange(listItem.id)([{ propName: 'actions', value }])}
            screenConfig={screenConfig}
            allLocalVariables={getAllLocalVariables(screenConfig, listItem)}
          />
        </>
      )}
    </RightSidebarItem>
  )
}
