import React from 'react'
import { ISvg, Svg } from '../Svg'

const ImageTRight: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M4 12L4 11.1111L4 4" fill="#F5EEFF" />
    <path d="M4 12L4 11.1111L4 4" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 4H6.33333L1 4" fill="#F5EEFF" />
    <path d="M7 4H6.33333L1 4" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="9" y="6" width="4" height="4" rx="2" fill="#A1AFBE" />
  </Svg>
)

export default ImageTRight
