import styled from 'styled-components'

export const ScreenName = styled.foreignObject<{ isScreenActive: boolean; isComponentOnScreenSelected: boolean }>`
  & * {
    cursor: default;
    user-select: none;
  }
  & > div {
    border: 1px solid
      ${props => (props.isComponentOnScreenSelected && props.isScreenActive ? props.theme.colors.grey1 : 'transparent')};
    & > div {
      padding: 5px 26px 5px 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      background: ${props =>
        props.isScreenActive && !props.isComponentOnScreenSelected
          ? props.theme.colors.primaryTextHover
          : props.theme.colors.primaryBackground};
      color: ${props =>
        props.isScreenActive && !props.isComponentOnScreenSelected
          ? props.theme.colors.white
          : props.theme.colors.grey1};
      line-height: 150%;
      font-size: 16px;
      font-weight: 400;
      & > svg {
        & *[fill] {
          fill: ${props =>
            props.isScreenActive && !props.isComponentOnScreenSelected
              ? props.theme.colors.white
              : props.theme.colors.grey1};
        }
        & *[stroke] {
          stroke: ${props =>
            props.isScreenActive && !props.isComponentOnScreenSelected
              ? props.theme.colors.white
              : props.theme.colors.grey1};
        }
      }
    }
  }
`
