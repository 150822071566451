import React from 'react'
import { Svg, ISvg } from '../Svg'

const PricingTableCheckMark: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" preserveAspectRatio="none" fill="none" {...props}>
    <path d="M5 9.5L8.80952 14L15 6" stroke="#212133" strokeWidth="1.66667" strokeLinecap="round" />
  </Svg>
)

export default PricingTableCheckMark
