import React from 'react'
import { ISvg, Svg } from '../Svg'

const Copy: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12.7692 5H7.23077C6.55103 5 6 5.61991 6 6.38462V12.6154C6 13.3801 6.55103 14 7.23077 14H12.7692C13.449 14 14 13.3801 14 12.6154V6.38462C14 5.61991 13.449 5 12.7692 5Z"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.69161 11H3.12774C2.82864 11 2.5418 10.8541 2.33031 10.5945C2.11882 10.3348 2 9.98261 2 9.61538V3.38462C2 3.01739 2.11882 2.66521 2.33031 2.40554C2.5418 2.14588 2.82864 2 3.12774 2H8.20257C8.50166 2 8.78851 2.14588 9 2.40554"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Copy
