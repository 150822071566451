import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Teammates: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M5.97134 7.77275C7.84158 7.77275 9.35771 6.25662 9.35771 4.38637C9.35771 2.51613 7.84158 1 5.97134 1C4.10109 1 2.58496 2.51613 2.58496 4.38637C2.58496 6.25662 4.10109 7.77275 5.97134 7.77275Z"
      fill="#7F899E"
    />
    <path
      d="M11.3911 12.5137C11.3911 13.6311 10.4767 14.5455 9.35925 14.5455H2.5865C1.46906 14.5455 0.554688 13.6311 0.554688 12.5137C0.554688 10.6445 2.07178 9.12728 3.94106 9.12728H8.00469C9.87397 9.12728 11.3911 10.6445 11.3911 12.5137ZM13.4229 4.38637C13.4229 6.25548 11.9058 7.77275 10.0365 7.77275C9.80634 7.77275 9.576 7.75226 9.35922 7.69834C10.199 6.84479 10.7138 5.67313 10.7138 4.38637C10.7138 3.09962 10.199 1.92796 9.35922 1.07441C9.576 1.02052 9.80634 1 10.0365 1C11.9058 1.00003 13.4229 2.51727 13.4229 4.38637ZM15.4547 12.5137C15.4547 13.6311 14.5403 14.5455 13.4229 14.5455H12.0683C12.4949 13.9833 12.7456 13.2789 12.7456 12.5137C12.7456 11.1862 12.197 9.98744 11.3167 9.12728H12.0683C13.9376 9.12728 15.4547 10.6445 15.4547 12.5137Z"
      fill="#7F899E"
    />
  </Svg>
)
