import React from 'react'
import { ISvg, Svg } from '../Svg'

const ArrowThickDown: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M5 7L8 10L11 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default ArrowThickDown
