import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'

import { HoveredItem, Item, Items, ItemLabel } from '../CustomPicker.styled'
import * as styled from './ProductsPicker.styled'

import { CustomPicker } from 'components'
import { ProjectContext } from 'context'
import { firstLetterUpperCase, includesSearch, Product } from 'utils'

interface Props {
  onAdd: (val: string) => void
  close: () => void
}

export const ProductsPicker: React.FC<Props> = ({ onAdd, close }) => {
  const {
    state: { products = [] },
  } = useContext(ProjectContext)
  const [search, setSearch] = useState('')
  const [hovered, setHovered] = useState<Product | null>(null)

  const onClick = (val: string) => {
    onAdd(val)
    close()
  }

  return (
    <CustomPicker
      title="Products"
      close={close}
      setSearch={setSearch}
      optionsContainer={
        <Items>
          {products
            .filter(el => includesSearch(el.name, search))
            .map(el => (
              <Item
                key={el.id}
                onClick={() => onClick(el.id)}
                onMouseOver={() => setHovered(el)}
                onMouseLeave={() => setHovered(null)}
              >
                <ItemLabel>{el.name}</ItemLabel>
              </Item>
            ))}
          {hovered && (
            <HoveredItem>
              <Box padding="8px 3px">
                {Object.keys(hovered).map(el => (
                  <styled.ProductDetailsItem key={el}>
                    <b>{firstLetterUpperCase(el)}</b>
                    <span>{hovered[el as keyof Product]}</span>
                  </styled.ProductDetailsItem>
                ))}
              </Box>
            </HoveredItem>
          )}
        </Items>
      }
    />
  )
}
