import { Box } from '@mui/material'
import { useContext, useState } from 'react'

import * as styled from './Section.styled'

import { Icon, Name, Rotation } from 'components'
import { MovableComponentsContext, ProjectContext } from 'context'
import { movableElementId } from 'providers'
import { ComponentForLeftMenu, moveElement, ScreenComponent } from 'utils'

interface Props {
  title: string
  components: ComponentForLeftMenu[]
}

export const Section: React.FC<Props> = ({ title, components }) => {
  const { language } = useContext(ProjectContext)
  const { setMovableComponent, setMovableTabBarComponent, setMovableTopBarComponent } =
    useContext(MovableComponentsContext)
  const [open, setOpen] = useState(true)

  const onComponentMouseDown = (
    e: React.MouseEvent<HTMLElement>,
    config: ScreenComponent | { tabBarMode: true } | { topBarMode: true } | null
  ) => {
    if (config) {
      const target = e.target as HTMLElement
      const { x, y, width, height } = target.getBoundingClientRect()
      const movable = target.cloneNode(true) as HTMLElement
      movable.id = movableElementId
      movable.style.position = 'fixed'
      movable.style.top = y + 'px'
      movable.style.left = x + 'px'
      movable.style.width = width + 'px'
      movable.style.height = height + 'px'
      movable.style.pointerEvents = 'none'
      movable.style.zIndex = '2'
      document.body.appendChild(movable)
      moveElement(movable)
      // @ts-ignore
      if (config.componentType) {
        setMovableComponent(config as ScreenComponent)
        // @ts-ignore
      } else if (config.tabBarMode) {
        setMovableTabBarComponent(true)
        // @ts-ignore
      } else if (config.topBarMode) {
        setMovableTopBarComponent(true)
      }
    }
  }

  return components.length ? (
    <Box>
      <styled.TitleWrapper onClick={() => setOpen(open => !open)}>
        <styled.Title>{title}</styled.Title>
        <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={open ? undefined : Rotation.RIGHT} />
      </styled.TitleWrapper>
      {open && (
        <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(53px, 1fr))" gap="10px" mb="3px">
          {components.map(
            el =>
              el.config(language) && (
                <styled.Component key={el.name} onMouseDown={e => onComponentMouseDown(e, el.config(language))}>
                  {el.icon}
                  <div>{el.name}</div>
                </styled.Component>
              )
          )}
        </Box>
      )}
    </Box>
  ) : (
    <></>
  )
}
