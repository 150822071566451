import { Box } from '@mui/material'
import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import { FetchProjectWrapper, PrivateRoute } from 'components'
import { useIsMobile } from 'hooks'
import { LeftSidebar } from 'partials'
import { LayoutProvider, TopPanelProvider, WorkspaceProvider } from 'providers'
import { GlobalStyle, ThemeProvider } from 'theme'

export const Layout: React.FC = () => {
  const isMobile = useIsMobile()
  const { id, workspaceId } = useParams()
  const { pathname } = useLocation()
  const isWorkspacesPage = pathname === '/' || !!workspaceId
  const wide = isWorkspacesPage || pathname === '/account'

  return (
    <WebappLayout>
      <PrivateRoute>
        <WorkspaceProvider isWorkspacesPage={isWorkspacesPage} wide={wide}>
          <TopPanelProvider>
            <Box height={isMobile ? '100%' : '100vh'} display="flex">
              <LeftSidebar wide={wide} isMobile={isMobile} />
              <Box width={`calc(100% - ${wide ? 266 : 60}px)`}>
                {id ? <FetchProjectWrapper isMobile={isMobile} /> : !isMobile && <Outlet />}
              </Box>
            </Box>
          </TopPanelProvider>
        </WorkspaceProvider>
      </PrivateRoute>
    </WebappLayout>
  )
}

interface Props {
  children: React.ReactNode
}

export const WebappLayout: React.FC<Props> = ({ children }) => (
  <LayoutProvider>
    <ThemeProvider>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  </LayoutProvider>
)
