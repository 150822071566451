import React from 'react'
import { ISvg, Svg } from '../Svg'

const Github: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.9994 4C7.61286 4 4.05366 7.67476 4 12.2283C4.04518 15.9668 6.57552 19.0975 9.99053 19.9946C10.008 19.9436 10.0161 19.8898 10.0161 19.8379C10.0161 19.75 10.0147 19.5975 10.013 19.3942C10.0108 19.1402 10.0079 18.8069 10.0061 18.4211C7.78072 18.9236 7.31138 17.3032 7.31138 17.3032C6.94739 16.3415 6.42139 16.0843 6.42139 16.0843C5.69672 15.5677 6.47739 15.5783 6.47739 15.5783C7.28072 15.6363 7.70272 16.4369 7.70272 16.4369C8.41605 17.711 9.57539 17.3428 10.0327 17.1301C10.1047 16.5909 10.3107 16.2242 10.5394 16.0157C8.76272 15.8073 6.89539 15.0907 6.89539 11.8981C6.89539 10.9887 7.20538 10.2453 7.71872 9.66231C7.62872 9.45174 7.35872 8.60447 7.78872 7.45689C7.78872 7.45689 8.45872 7.23359 9.98872 8.31122C10.6287 8.12608 11.3087 8.03421 11.9887 8.02997C12.6687 8.03421 13.3487 8.12608 13.9887 8.31122C15.5087 7.23359 16.1787 7.45689 16.1787 7.45689C16.6087 8.60447 16.3387 9.45174 16.2587 9.66231C16.7687 10.2453 17.0787 10.9887 17.0787 11.8981C17.0787 15.0992 15.2087 15.8037 13.4287 16.0087C13.7087 16.2588 13.9687 16.7697 13.9687 17.5505C13.9687 18.2976 13.9642 18.9463 13.9613 19.3745C13.9598 19.5855 13.9587 19.743 13.9587 19.8323C13.9587 19.8876 13.9677 19.9456 13.9877 20C17.4131 19.11 19.9535 15.9743 19.9988 12.2283C19.9451 7.67477 16.3839 4 11.9994 4Z"
      fill="#212133"
    />
  </Svg>
)

export default Github
