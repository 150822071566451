import React from 'react'
import { ISvg, Svg } from '../Svg'

const HomeFolder: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g opacity="0.5">
      <path
        d="M10.8125 12H4.71875C3.96008 12 3.30711 11.8052 2.82992 11.437C2.28688 11.0177 2 10.3952 2 9.63637C2 8.2307 3.13531 7.46956 4.29102 7.30366C4.44383 6.36775 4.85352 5.5673 5.48891 4.97503C6.16337 4.3458 7.06408 3.99608 8 4.00003C8.83203 4.00003 9.59937 4.26571 10.2191 4.76821C10.6926 5.15553 11.0573 5.6529 11.2789 6.21389C11.9881 6.32321 12.6254 6.60434 13.0925 7.01752C13.6862 7.54275 14 8.25956 14 9.09092C14 9.9091 13.663 10.6564 13.051 11.1952C12.4616 11.7143 11.6666 12 10.8125 12Z"
        fill="#7F899E"
      />
    </g>
  </Svg>
)

export default HomeFolder
