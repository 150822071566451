import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { ISvg, Svg } from '../Svg'

const DefaultIcon: React.FC<ISvg> = props => {
  const themeContext = useContext(ThemeContext)
  return (
    <Svg viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C9.89543 9 9 9.89543 9 11C9 12.1046 9.89543 13 11 13Z"
        fill={themeContext.colors.grey1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 2.23858 2.23858 0 5 0H17C19.7614 0 22 2.23858 22 5V17C22 19.7614 19.7614 22 17 22H5C2.23858 22 0 19.7614 0 17V5ZM5 1.5H17C18.933 1.5 20.5 3.067 20.5 5V17C20.5 18.933 18.933 20.5 17 20.5H5C3.067 20.5 1.5 18.933 1.5 17V5C1.5 3.067 3.067 1.5 5 1.5Z"
        fill={themeContext.colors.grey1}
      />
    </Svg>
  )
}

export default DefaultIcon
