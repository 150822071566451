import styled from 'styled-components'

export const Wrapper = styled.div<{ small: boolean; noBorderBottom: boolean }>`
  border-bottom: ${props =>
    !props.noBorderBottom &&
    `1px solid
    ${props.small ? props.theme.colors.primaryBackground : props.theme.colors.borderColor}`};
  position: relative;
`

export const TitleWrapper = styled.div<{ pointer: boolean; small: boolean }>`
  padding: ${props => (props.small ? '0 10px' : '0')};
  margin: ${props => (props.small ? '10px 0 8px' : '10px 0')};
  display: flex;
  justify-content: space-between;
  user-select: none;
  cursor: ${props => (props.pointer ? 'pointer' : 'auto')};
  &:hover svg:last-child path {
    stroke: ${props => props.pointer && props.theme.colors.iconHover};
  }
`

export const Title = styled.h4<{ grey1: boolean }>`
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
  color: ${props => (props.grey1 ? props.theme.colors.grey1 : props.theme.colors.darkText)};
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  & svg {
    cursor: pointer;
    user-select: none;
    & path,
    & circle {
      transition: 300ms ease-out;
      stroke: ${props => props.theme.colors.grey1};
    }
    &:hover {
      & path,
      & circle {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`

export const ChildrenWrapper = styled.div<{ small: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.small ? '0 10px' : '0')};
  margin-bottom: ${props => (props.small ? '13px' : '15px')};
  & > div:first-child {
    margin-top: ${props => (props.small ? '7px' : '0')};
  }
`
