import styled from 'styled-components'

export const AutocompleteWrapper = styled.div<{ active: boolean }>`
  width: 100%;
  & > div > div > div {
    border-radius: 8px;
    background: ${props => (props.active ? props.theme.colors.secondaryButtonBg1 : props.theme.colors.settingField)};
    transition: background 300ms ease-out;
    border: 1px solid ${props => (props.active ? props.theme.colors.borderColorToLightBorder : 'transparent')};
    box-sizing: border-box;
    padding: 0 !important;
    font-size: 12px;
    line-height: 150%;
    color: ${props => props.theme.colors.darkText};
    outline: none;
    & fieldset {
      border: none;
    }
    & input {
      padding: 6px 8px !important;
      min-height: 30px;
      box-sizing: border-box;
      &::placeholder {
        color: ${props => props.theme.colors.grey1};
        opacity: 1;
      }
    }
    &:hover {
      background: ${props =>
        props.active ? props.theme.colors.secondaryButtonBg1 : props.theme.colors.settingFieldHover};
    }
  }
`
