import React from 'react'
import { ISvg, Svg } from '../Svg'

const Tab: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="3.5" y="2.5" width="9" height="9" rx="1.5" stroke="#7F899E" />
    <line x1="3.5" y1="13.5" x2="12.5" y2="13.5" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Tab
