import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const Languages: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M4.33432 4.28125H4.1337L3.75879 6.15625H4.70923L4.33432 4.28125ZM11.9176 8.03125C12.12 8.57497 12.3892 9.01509 12.6871 9.38959C12.9851 9.01509 13.2855 8.57494 13.4879 8.03125H11.9176Z"
      fill="#7F899E"
    />
    <path
      d="M14.5935 2.84375H8.7722L9.98601 12.5837C10.0075 12.9821 9.89857 13.3572 9.63857 13.6508L7.58301 16H14.5935C15.369 16 15.9998 15.3692 15.9998 14.5938V4.28125C15.9998 3.50581 15.369 2.84375 14.5935 2.84375ZM14.5935 8.03125H14.4684C14.2016 8.88697 13.7787 9.55656 13.3391 10.0844C13.6834 10.3993 14.0517 10.6575 14.4178 10.9467C14.6196 11.1083 14.6526 11.4031 14.4905 11.6055C14.3292 11.8075 14.0333 11.8403 13.8318 11.6783C13.434 11.3643 13.0607 11.1015 12.6873 10.7588C12.3139 11.1015 11.9718 11.3643 11.574 11.6783C11.3725 11.8403 11.0766 11.8075 10.9153 11.6055C10.7532 11.4031 10.7862 11.1083 10.9881 10.9467C11.3542 10.6575 11.6911 10.3993 12.0355 10.0844C11.5959 9.55659 11.2042 8.887 10.9375 8.03125H10.8123C10.5532 8.03125 10.3435 7.82159 10.3435 7.5625C10.3435 7.30341 10.5532 7.09375 10.8123 7.09375H12.2185V6.625C12.2185 6.36591 12.4282 6.15625 12.6873 6.15625C12.9464 6.15625 13.156 6.36591 13.156 6.625V7.09375H14.5935C14.8526 7.09375 15.0623 7.30341 15.0623 7.5625C15.0623 7.82159 14.8526 8.03125 14.5935 8.03125Z"
      fill="#7F899E"
    />
    <path
      d="M7.63013 1.23184C7.54269 0.529625 6.94256 0 6.23488 0H1.40625C0.630813 0 0 0.630813 0 1.40625V11.7812C0 12.5567 0.630813 13.1875 1.40625 13.1875H8.79847C8.93522 13.0312 9.04984 12.9333 9.05469 12.7293C9.05587 12.6781 7.63647 1.28259 7.63013 1.23184ZM5.74825 8.95959C5.49969 9.01075 5.24791 8.84937 5.19666 8.592L4.89706 7.09375H3.57172L3.27213 8.592C3.22178 8.84559 2.97731 9.01222 2.72053 8.95959C2.46694 8.90878 2.30212 8.66206 2.35294 8.408L3.29041 3.7205C3.33434 3.50169 3.52662 3.34375 3.75 3.34375H4.71875C4.94212 3.34375 5.13441 3.50169 5.17834 3.7205L6.11584 8.408C6.16666 8.66206 6.00187 8.90878 5.74825 8.95959ZM5.47691 14.125L5.55734 14.7682C5.61097 15.1989 5.89716 15.6381 6.36791 15.8574L7.94163 14.125H5.47691Z"
      fill="#7F899E"
    />
  </Svg>
)
