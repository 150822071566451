import styled from 'styled-components'

export const Hints = styled.ul`
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  & li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    & b {
      color: ${props => props.theme.colors.darkText};
    }
    &:before {
      content: '•';
      margin-right: 7px;
      margin-left: -3px;
      color: ${props => props.theme.colors.darkText};
    }
  }
`

export const ChapterTitle = styled.h1`
  font-size: 22px;
  line-height: 150%;
  font-weight: 700;
  color: ${props => props.theme.colors.darkText};
`

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  font-weight: ${props => props.theme.weights.normal};
  width: calc(900 / 1100 * 100%);

  & br {
    display: block;
    content: '';
    margin-bottom: 5px;
  }
`
