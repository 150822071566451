import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { auth, firestore } from 'services/firebase'
import { WorkspacesService } from 'services/workspaces'

export interface Workspace {
  id: string
  memberIds: string[]
  name: string
  plan: WorkspacePlan
  billingAccountId: string
  vendor: string
  subscriptionStatus?: SubscriptionStatus
  subscriptionId?: string
}

export enum WorkspacePlan {
  STARTER = 'STARTER',
  BASIC = 'BASIC',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
  CUSTOM = 'CUSTOM',
}

export enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

export interface Permission {
  id: string
  email: string
  userName: string
  userPhotoUrl: string
  role: WorkspaceRole | ProjectRole
}

export enum WorkspaceRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
  GUEST = 'GUEST',
}

export enum ProjectRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
  GUEST = 'GUEST',
}

export interface Invite {
  id: string
  email: string
  workspace: any
  status: InviteStatus
  message: string
  senderId: string
}

export enum InviteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
}

export const useWorkspaces = (subscribe: boolean) => {
  const { toast } = useContext(ProgressContext)
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const userId = auth.currentUser?.uid

  useEffect(() => {
    if (subscribe) {
      const unsubscribe = firestore
        .collection('workspaces')
        .where('memberIds', 'array-contains', userId)
        .onSnapshot({
          next: res => {
            const workspaces = res.docs.map(el => ({ id: el.id, ...el.data() })) as Workspace[]
            setWorkspaces(workspaces)
            if (!workspaces.length) {
              WorkspacesService.postWorkspace('My workspace').catch(err => toast(err))
            }
          },
          error: err => toast(err),
        })
      return () => {
        unsubscribe()
      }
    }
  }, [subscribe])

  return workspaces
}
