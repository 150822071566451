import { Box } from '@mui/material'
import React from 'react'

import * as styled from '../GithubConnectionModal.styled'

export const Ready: React.FC = () => (
  <styled.Content>
    <styled.Title>Git Repository</styled.Title>
    <Box height="373px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <img src="/assets/ready.svg" alt="ready" />
      <styled.SubTitle>Connected!</styled.SubTitle>
    </Box>
  </styled.Content>
)
