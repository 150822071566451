import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './AddMedia.styled'

import { Button, ButtonColor } from 'components'
import { ButtonWrapper } from 'components/CustomPicker/CustomPicker.styled'
import { ProgressContext } from 'context'
import {
  Component,
  IBackground,
  IDevice,
  IImage,
  IText,
  addDevice,
  backgroundWidth,
  containerHeight,
} from 'pages/ScreenshotsStudio/utils'
import { ProjectsService } from 'services/projects'
import { generateFirestoreId, includesSearch } from 'utils'
import { getDevicesConfigs } from './configs'

interface Props {
  addToState: (state: IImage[] | IDevice[]) => void
  activeElement: IBackground | IImage | IDevice | IText | null
  close: () => void
  search: string
}

export const AddComponents: React.FC<Props> = ({ addToState, activeElement, close, search }) => {
  const { id } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [values, setValues] = useState<any[]>([])
  const cX =
    activeElement?.type === Component.BACKGROUND
      ? +(document.getElementById(activeElement.id)?.getAttribute('x') || 0) + backgroundWidth / 2
      : backgroundWidth / 2
  const cY = containerHeight / 2

  useEffect(() => {
    getValues()
  }, [])

  const getValues = async () => {
    const values = []
    for (const el of getDevicesConfigs(cX, cY)) {
      const device = await addDevice({ ...el, x: 0, y: 0 })
      values.push({ preview: device.outerHTML, config: el })
    }
    setValues(values)
  }

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = ((e.target as HTMLInputElement).files as FileList)[0]
    if (id && image) {
      const formData = new FormData()
      formData.append('resource', image)
      startLoader()
      ProjectsService.uploadResource(id, formData)
        .then(res => {
          const config = {
            type: Component.IMAGE,
            id: generateFirestoreId(),
            angle: 0,
            url: res.data.url,
          }
          const fr = new FileReader()
          fr.onload = () => {
            const img = new Image()
            img.onload = () => {
              const { width, height } = img
              if (width > backgroundWidth) {
                const newWidth = backgroundWidth - 20
                const newHeight = (height * newWidth) / width
                addToState([
                  {
                    ...config,
                    x: cX - newWidth / 2,
                    y: cY - newHeight / 2,
                    width: newWidth,
                    height: newHeight,
                  },
                ])
              } else if (height > containerHeight) {
                const newHeight = containerHeight - 20
                const newWidth = (width * newHeight) / height
                addToState([
                  {
                    ...config,
                    x: cX - newWidth / 2,
                    y: cY - newHeight / 2,
                    width: newWidth,
                    height: newHeight,
                  },
                ])
              } else {
                addToState([
                  {
                    ...config,
                    x: cX - width / 2,
                    y: cY - height / 2,
                    width,
                    height,
                  },
                ])
              }
              close()
              stopLoader()
            }
            img.src = fr.result as string
          }
          fr.readAsDataURL(image)
        })
        .catch(err => toast(err))
    }
  }

  const addNewDevice = (config: IDevice) => {
    addToState([config])
    close()
  }

  return (
    <>
      <ButtonWrapper>
        <Button color={ButtonColor.SECONDARY} input={<input onChange={uploadImage} type="file" accept="image/*" />}>
          Upload Image
        </Button>
      </ButtonWrapper>
      <styled.Devices>
        {values
          .filter(el => includesSearch(el.config.deviceType, search))
          .map(el => (
            <styled.Device key={el.config.id} onClick={() => addNewDevice(el.config)}>
              <svg
                width="54"
                height="102"
                viewBox={`0 0 ${el.config.width} ${el.config.height}`}
                dangerouslySetInnerHTML={{ __html: el.preview }}
              />
              <span>{el.config.deviceType}</span>
            </styled.Device>
          ))}
      </styled.Devices>
    </>
  )
}
