import React from 'react'
import { ISvg, Svg } from '../Svg'

const LogOut: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M8 16L7 16C5.34315 16 4 14.6569 4 13L4 7C4 5.34315 5.34315 4 7 4L8 4"
      stroke="#DF5636"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M9 10L15 10" stroke="#DF5636" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M13 7L16 10L13 13" stroke="#DF5636" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default LogOut
