import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const AppTemplates: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="6" y="2" width="12" height="20" rx="3" fill="#476CEC" />
    <path
      d="M3.98817 4.31632C3.98817 3.98788 3.98817 3.82365 3.91752 3.70528C3.85758 3.60484 3.75444 3.52244 3.64324 3.48616C3.51219 3.44341 3.36118 3.4776 3.05916 3.546C2.58368 3.65367 2.25365 3.77521 1.98692 3.97086C1.56413 4.28099 1.25038 4.717 1.09063 5.21641C0.909011 5.78416 1.02091 6.47037 1.2447 7.8428L2.86287 17.7665C3.08666 19.1389 3.19855 19.8251 3.55112 20.3057C3.70348 20.5134 3.99338 20.3786 3.99087 20.121C3.98817 19.8448 3.98817 19.5395 3.98817 19.1999V4.31632Z"
      fill="#476CEC"
    />
    <path
      d="M20.0177 4.31632C20.0177 3.98788 20.0177 3.82365 20.0883 3.70528C20.1483 3.60484 20.2514 3.52244 20.3626 3.48616C20.4937 3.44341 20.6447 3.4776 20.9467 3.546C21.4222 3.65367 21.7522 3.77521 22.0189 3.97086C22.4417 4.28099 22.7555 4.717 22.9152 5.21641C23.0968 5.78416 22.985 6.47037 22.7612 7.8428L21.143 17.7665C20.9192 19.1389 20.8073 19.8251 20.4547 20.3057C20.3024 20.5134 20.0125 20.3786 20.015 20.121C20.0177 19.8448 20.0177 19.5395 20.0177 19.1999V4.31632Z"
      fill="#476CEC"
    />
  </Svg>
)
