import React from 'react'
import { ISvg, Svg } from '../Svg'

const CloseRound: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 12 12" fill="none" {...props}>
    <rect width="12" height="12" rx="6" fill="#7F899E" />
    <path d="M4 4L8 8M8 4L4 8" stroke="#F2F2F3" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default CloseRound
