import React from 'react'
import { ISvg, Svg } from '../Svg'

const Add: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M8 3V13" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3 8L13 8" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Add
