import React from 'react'
import { ISvg, Svg } from '../Svg'

const ScreenshotsPreview: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 270 150" fill="none" {...props}>
    <rect width="270" height="150" rx="8" fill="#9A9A9A" fillOpacity="0.15" />
    <rect x="8" y="8" width="62" height="134" rx="6" fill="#C9C9C9" />
    <rect x="78" y="8" width="62" height="134" rx="6" fill="#C9C9C9" />
    <rect x="148" y="8" width="62" height="134" rx="6" fill="#C9C9C9" />
    <rect x="218" y="8" width="62" height="134" rx="6" fill="#C9C9C9" />
  </Svg>
)
export default ScreenshotsPreview
