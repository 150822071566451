import styled from 'styled-components'

import { RecordStatus } from 'services/cms'

export const Wrapper = styled.div<{ status?: RecordStatus | 'NOT DEPLOYED' }>`
  background: ${props =>
    props.status === RecordStatus.CHANGED
      ? props.theme.colors.blue_50
      : props.status === RecordStatus.DRAFT || props.status === 'NOT DEPLOYED'
      ? props.theme.colors.notificationYellowBg
      : '#f6ffed'};
  border: 1px solid
    ${props =>
      props.status === RecordStatus.CHANGED
        ? props.theme.colors.blue_200
        : props.status === RecordStatus.DRAFT || props.status === 'NOT DEPLOYED'
        ? props.theme.colors.notificationYellowBorder
        : '#b7eb8f'};
  color: ${props =>
    props.status === RecordStatus.CHANGED
      ? props.theme.colors.blue_500
      : props.status === RecordStatus.DRAFT || props.status === 'NOT DEPLOYED'
      ? props.theme.colors.notificationYellowText
      : '#3ca012'};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: ${props => (props.status === 'NOT DEPLOYED' ? 105 : 80)}px;
  height: 25px;
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
`
