import { Box } from '@mui/material'
import { memo, useEffect, useRef } from 'react'
import { CoordinateConstant, coordinateHandler } from 'utils'

interface Props {
  value: CoordinateConstant | null
  setMarker: React.Dispatch<React.SetStateAction<google.maps.Marker | null>>
}

export const Map = memo(({ value, setMarker }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const center = { lat: 0, lng: 0 }
      if (value) {
        coordinateHandler(value, center)
      }
      const map = new google.maps.Map(ref.current, {
        mapTypeId: 'roadmap',
        center: center,
        zoom: 5,
      })
      setMarker(new google.maps.Marker({ position: center, map, draggable: true }))
    }
  }, [])

  return <Box width="100%" height="100%" ref={ref} />
})
