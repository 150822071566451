import React from 'react'
import { ISvg, Svg } from '../Svg'

const Readme: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2 3H5.60028C6.23685 3 6.84735 3.25754 7.29747 3.71596C7.74759 4.17438 8.00047 4.79614 8.00047 5.44444V14C8.00047 13.5138 7.81081 13.0475 7.47322 12.7036C7.13563 12.3598 6.67776 12.1667 6.20033 12.1667H2V3Z"
      stroke="#212133"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9995 3H10.3992C9.76264 3 9.15214 3.25754 8.70202 3.71596C8.2519 4.17438 7.99902 4.79614 7.99902 5.44444V14C7.99902 13.5138 8.18868 13.0475 8.52627 12.7036C8.86386 12.3598 9.32174 12.1667 9.79916 12.1667H13.9995V3Z"
      stroke="#212133"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Readme
