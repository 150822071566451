export interface ITableData {
  title: string
  rows: {
    name: string
    description?: string
    professional: string | boolean
    business: string | boolean
    custom: string | boolean
  }[]
}

export const TABLE_DATA: ITableData[] = [
  {
    title: 'Features',
    rows: [
      {
        name: 'Visual & AI App Builder',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Native Swift & Kotlin apps',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Progressive Web App',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Serverless App Backend',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'App Landing page',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Code download & Github integrations',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Embedded CI/CD pipelines',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'App Store & Google Play integrations',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Custom domain & SSL',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: '“Pay As You Go” cloud project',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Cloud project ownership transfer',
        professional: false,
        business: true,
        custom: true,
      },
    ],
  },
  {
    title: 'Usage',
    rows: [
      {
        name: 'CI/CD build minutes',
        professional: '$0.02/min',
        business: '$0.02/min',
        custom: 'Custom',
      },
      {
        name: 'Cloud project',
        professional: 'Pay As You Go',
        description:
          'Your project uses a serverless architecture, with costs determined by Google Cloud Platform rates based on service usage and the region of your data. Full pricing details can be found <a href="https://cloud.google.com/products?hl=en" target="_blank" rel="noreferrer">here</a>.',
        business: 'Direct Google Cloud billing',
        custom: 'Direct Google Cloud billing',
      },
      {
        name: 'Volume discounts for high-scale usage',
        professional: false,
        business: false,
        custom: true,
      },
    ],
  },
  {
    title: 'Support',
    rows: [
      {
        name: 'Setup call',
        professional: false,
        business: true,
        custom: true,
      },
      {
        name: '“Done for you” publishing',
        professional: false,
        business: true,
        custom: true,
      },
      {
        name: 'Email support',
        professional: true,
        business: true,
        custom: true,
      },
      {
        name: 'Priority live chat support',
        professional: false,
        business: true,
        custom: true,
      },
      {
        name: 'Dedicated account manager',
        professional: false,
        business: true,
        custom: true,
      },
      {
        name: 'Priority feature request',
        professional: false,
        business: true,
        custom: true,
      },
      {
        name: 'UX/UI Design',
        professional: false,
        business: false,
        custom: true,
      },
      {
        name: 'Custom integrations',
        professional: false,
        business: false,
        custom: true,
      },
      {
        name: 'Legacy apps migration',
        professional: false,
        business: false,
        custom: true,
      },
    ],
  },
]
