import { Box } from '@mui/material'
import React, { useContext, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CommonPicker, Name } from 'components'
import { TopPanelContext } from 'context'
import { useOnClickOutside, useParamsFromUrl } from 'hooks'

interface Props {
  close: () => void
  cms?: boolean
}

export const Actions: React.FC<Props> = ({ close, cms }) => {
  const { setOpenLogicItem } = useContext(TopPanelContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const { setIntentToUrl } = useParamsFromUrl()
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  const values = [
    {
      value: '0',
      label: 'Generate screens',
      iconName: Name.PICKERS_GENERATE_SCREENS,
      onClick: () => setIntentToUrl('generate-screen'),
      itemsCategory: 'AI',
    },
    {
      value: '1',
      label: 'Refactor',
      iconName: Name.PICKERS_REFACTOR,
      onClick: () => setIntentToUrl('refactor'),
      itemsCategory: 'AI',
    },
    {
      value: '2',
      label: 'Generate collection',
      iconName: Name.PICKERS_GENERATE_COLLECTION,
      onClick: () => setIntentToUrl('generate-collection'),
      itemsCategory: 'AI',
    },
    {
      value: '3',
      label: 'Import data',
      iconName: Name.PICKERS_IMPORT_DATA,
      onClick: () => setIntentToUrl('import-data'),
      itemsCategory: 'AI',
    },
    {
      value: '4',
      label: 'Generate content',
      iconName: Name.PICKERS_GENERATE_CONTENT,
      onClick: () => setIntentToUrl('generate-content'),
      itemsCategory: 'AI',
    },
    {
      value: '5',
      label: 'Explain this',
      iconName: Name.PICKERS_QA,
      onClick: () => setIntentToUrl('explain'),
      itemsCategory: 'AI',
    },
    {
      value: '6',
      label: 'Browse flows',
      iconName: Name.PICKERS_GENERATE_SCREENS,
      onClick: () => setOpenLogicItem('flows'),
      itemsCategory: 'LOGIC',
    },
    {
      value: '7',
      label: 'Browse prompts',
      iconName: Name.PICKERS_REFACTOR,
      onClick: () => setOpenLogicItem('prompts'),
      itemsCategory: 'LOGIC',
    },
    {
      value: '8',
      label: 'Edit localizations',
      iconName: Name.PICKERS_GENERATE_SCREENS,
      onClick: () => navigate(`/projects/${id}/settings/languages`),
      itemsCategory: 'PROJECT',
    },
    {
      value: '9',
      label: 'Invite teammates',
      iconName: Name.PICKERS_REFACTOR,
      onClick: () => navigate(`/projects/${id}/settings/teammates`),
      itemsCategory: 'PROJECT',
    },
    {
      value: '10',
      label: 'Update plan',
      iconName: Name.PICKERS_REFACTOR,
      onClick: () => navigate(`/projects/${id}/settings/plans`),
      itemsCategory: 'PROJECT',
    },
  ].filter((el, i) => (cms ? [2, 3, 4, 5, 6, 7, 8, 9, 10].includes(i) : true))

  return (
    <Box zIndex="10" width="240px" position="fixed" top="52px" left={cms ? '326px' : '259px'} ref={ref}>
      <CommonPicker
        height="359px"
        title="Actions"
        onChange={value => values.find(el => value === el.value)?.onClick()}
        values={values}
        close={close}
      />
    </Box>
  )
}
