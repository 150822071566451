import React from 'react'

import * as styled from './DragNDrop.styled'

import { Icon, Name } from 'components'

interface Props {
  onFileChange: (file: any) => void
}

export const DragNDrop: React.FC<Props> = ({ onFileChange }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => e.target.files?.[0] && onFileChange(e.target.files[0])

  return (
    <styled.DragNDrop>
      <Icon name={Name.ADDITIONAL_UPLOAD_IMAGE} />
      <styled.DragNDropText>
        Drag and drop an image, or <u>Browse</u>
      </styled.DragNDropText>
      <styled.DragNDropHint>Minimum 1600px width recommended. Max 10MB</styled.DragNDropHint>
      <styled.DragNDropInput onChange={onChange} type="file" accept="image/*" />
    </styled.DragNDrop>
  )
}
