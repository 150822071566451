import React from 'react'
import { ISvg, Svg } from '../Svg'

const Paywall: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="4.75" y="4.75" width="22.5" height="22.5" rx="11.25" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M15.9882 22C16.2395 22 16.4279 21.8502 16.4279 21.6118V20.7537C18.4298 20.6311 20 19.6913 20 18.0159C20 16.5653 18.8695 15.8297 16.8047 15.4143L16.4279 15.3326V12.4926C17.2993 12.588 17.8646 12.9966 18.1943 13.7049C18.3592 14.0318 18.6183 14.2293 19.0265 14.2293C19.4818 14.2293 19.7959 13.9773 19.7959 13.6095C19.7959 13.4869 19.7802 13.378 19.7409 13.2622C19.3719 12.1385 18.1001 11.3689 16.4279 11.2531V10.3882C16.4279 10.1498 16.2395 10 15.9882 10C15.7291 10 15.5407 10.1498 15.5407 10.3882V11.2531C13.5623 11.3825 12.1492 12.3837 12.1492 13.916C12.1492 15.2985 13.2247 16.0749 15.2188 16.4767L15.5407 16.5448V19.5142C14.4181 19.412 13.8606 18.9353 13.6016 18.2951C13.4367 17.9342 13.1619 17.7639 12.7851 17.7639C12.3062 17.7639 12 18.0295 12 18.4177C12 18.5267 12.0236 18.6493 12.0628 18.765C12.4396 19.9024 13.8057 20.6447 15.5407 20.7537V21.6118C15.5407 21.8502 15.7291 22 15.9882 22ZM13.8135 13.8002C13.8135 13.1737 14.4102 12.6084 15.5407 12.4926V15.1555C14.2846 14.8763 13.8135 14.42 13.8135 13.8002ZM18.3356 18.1521C18.3356 18.8808 17.7704 19.4325 16.4279 19.521V16.7287C17.8881 17.0284 18.3356 17.4438 18.3356 18.1521Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Paywall
