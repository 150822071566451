import styled from 'styled-components'

export const Devices = styled.div`
  margin: 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 167px;
`

export const Device = styled.div`
  cursor: pointer;
  width: 167px;
  height: 167px;
  background: ${props => props.theme.colors.primaryBackground};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > span {
    margin-top: 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.theme.colors.darkText};
  }
`

export const Screens = Devices

export const Screen = styled(Device)`
  height: 200px;
  & > span {
    margin-top: 8px;
  }
`
