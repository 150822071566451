import styled from 'styled-components'

const Container = styled.div`
  max-width: 1450px;
  margin: 0px auto;
  padding: 0 60px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    max-width: 1386px;
    padding: 0 28px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    max-width: 1370px;
    padding: 0 20px;
  }
`

export default Container
