export const df = {
  display: 'flex',
}

export const ac = {
  alignItems: 'center',
}

export const jc = {
  justifyContent: 'center',
}

export const jfe = {
  justifyContent: 'flex-end',
}

export const jsb = {
  justifyContent: 'space-between',
}

export const psRl = {
  position: 'relative',
}

export const cCross = {
  cursor: 'crosshair',
}

export const cResize = {
  cursor: 'ew-resize',
}

export const opacityOverlay = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 10,
}

export const opacityBg = {
  height: 14,
  borderRadius: 10,
}

export const handle = {
  position: 'absolute',
  border: '2px solid white',
  borderRadius: '50%',
  boxShadow: '0px 0px 3px rgba(0,0,0,.5)',
  width: 18,
  height: 18,
  zIndex: 1000,
  transition: 'all 10ms linear',
}

export const gradientHandleWrap = {
  position: 'absolute',
  zIndex: 10000,
  paddingLeft: 2,
  paddingRight: 2,
  top: 4,
}

export const gradientHandle = {
  border: '2px solid white',
  borderRadius: '50%',
  boxShadow: '0px 0px 3px rgba(0,0,0,.5)',
  width: 18,
  height: 18,
}

export const npe = {
  pointerEvents: 'none',
}

export const inputWrap = {
  height: 26,
  borderRadius: 1,
  border: '1px solid #d0d0d0',
  width: '100%',
  padding: 2,
  outline: 'none',
  fontWeight: 400,
  fontSize: 12,
  textAlign: 'center',
  color: '#27334D',
  boxSizing: 'border-box',
}

export const inputLabel = {
  textAlign: 'center',
  lineHeight: 1.2,
  fontWeight: 700,
  color: 'rgb(86,86,86)',
  fontSize: 11,
}

export const controlBtn = {
  lineHeight: 1,
  borderRadius: 4,
  color: '#27334D',
  fontSize: 12,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '33.3%',
}

export const barWrap = {
  marginLeft: -18,
  padding: 3,
  marginTop: 2,
}

export const barWrapInner = {
  paddingLeft: 15,
  paddingRight: 15,
}

export const controlBtnsWrap = {
  height: 28,
  borderRadius: 6,
  padding: 2,
  cursor: 'pointer',
}

export const input = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
}

export const degreeRange = {
  width: 80,
}

export const degreeInput = {
  height: 24,
  borderRadius: 4,
  border: 'none',
  outline: 'none',
  textAlign: 'center',
  width: 34,
  fontWeight: 500,
  color: 'rgb(50, 49, 54)',
  fontSize: 13,
  background: 'transparent',
}

export const imageInputWrapper = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F8F8F8',
  border: '1px dashed #E4E4E4',
  textAlign: 'center',
  padding: '12px 0 14px',
  borderRadius: 8,
  position: 'relative',
}

export const imageInput = {
  opacity: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
}
