import styled from 'styled-components'

import { AIIcon } from 'components/AIModal/AIModal.styled'

import { BarComponentType, ComponentType } from 'utils'
import { unreset } from 'theme'

export const Container = styled.svg<{ disabled: boolean }>`
  opacity: ${props => props.disabled && '0.5'};
  *::-webkit-scrollbar {
    display: none;
  }
  *[hidden] {
    display: none !important;
  }
`

export const SvgContainer = styled.foreignObject<{
  isScreenActive: boolean
  isComponentOnScreenSelected: boolean
  disabled: boolean
}>`
  ${unreset}
  outline: 1px solid
    ${props =>
    props.isComponentOnScreenSelected && props.isScreenActive
      ? props.theme.colors.grey1
      : props.isScreenActive
      ? props.theme.colors.primaryTextHover
      : props.theme.colors.darkBlue_100};
  & * {
    cursor: default;
    user-select: none;
    pointer-events: ${props => props.disabled && 'none'};
  }
  & #hoverHintLabel,
  & #selectedHintLabel {
    pointer-events: none;
    position: absolute;
    display: flex;
    gap: 1px;
    background: ${props => props.theme.colors.white};
    & > div {
      line-height: 26px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: unset;
      white-space: nowrap;
      padding: 0 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      & svg {
        width: 20px;
        height: 20px;
      }
      &:not(:first-child) {
        & > i {
          height: 70%;
          width: 1px;
          background: ${props => props.theme.colors.white}60;
        }
        & > svg {
          cursor: pointer;
          & * {
            cursor: pointer;
          }
        }
      }
    }
  }
  & #hoverHintLabel {
    z-index: 2;
    & > div {
      color: ${props => props.theme.colors.primaryText};
      & > svg {
        & *[fill] {
          fill: ${props => props.theme.colors.primaryText};
        }
        & *[stroke] {
          stroke: ${props => props.theme.colors.primaryText};
        }
      }
    }
  }
  & #selectedHintLabel {
    z-index: 1;
    & > div {
      background: ${props => props.theme.colors.primaryTextHover};
      color: ${props => props.theme.colors.white};
      & > svg {
        & *[fill] {
          fill: ${props => props.theme.colors.white};
        }
        & *[stroke] {
          stroke: ${props => props.theme.colors.white};
        }
      }
    }
  }
  .hoveredComponent:after,
  .selectedComponent:after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .hoveredComponent:after {
    outline: 2px solid ${props => props.theme.colors.screenHover};
    outline-offset: -1px;
    z-index: 2;
  }
  .selectedComponent:after {
    outline: 2px solid ${props => props.theme.colors.primaryTextHover};
    outline-offset: -1px;
    z-index: 1;
  }
  .pointerLine {
    pointer-events: none;
    background: ${props => props.theme.colors.primaryTextHover};
  }
  & [id^='${ComponentType.carousel}'],
  & [id^='${ComponentType.list}'],
  & [id^='${BarComponentType.tabBar}'],
  & [id^='${BarComponentType.topBar}'] {
    & .screenComponent {
      pointer-events: none;
    }
  }
  .innerModeComponent {
    box-shadow: 0px 0px 0px 1000px #000000de;
    z-index: 1;
    & .screenComponent {
      pointer-events: auto !important;
      & #hoverHintLabel {
        z-index: 3;
      }
      & #selectedHintLabel {
        z-index: 2;
      }
    }
  }
`

export const AIBtnWrapper = styled.div<{ compression: number }>`
  position: absolute;
  top: ${props => `-${40 * props.compression}px`};
  right: ${props => `${8 * props.compression}px`};
  ${AIIcon} {
    & > svg {
      width: ${props => props.compression * 16}px;
      height: ${props => props.compression * 16}px;
      min-width: ${props => props.compression * 16}px;
      min-height: ${props => props.compression * 16}px;
    }
  }
`
