import React from 'react'
import { ISvg, Svg } from '../Svg'

const TextLeft: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M3 4H13" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3 12H11" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3 8H9" stroke="#A1AFBE" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default TextLeft
