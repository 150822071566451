import { Icon, Name, Rotation } from 'components'
import React, { useState } from 'react'

import * as styled from '../../CustomPicker.styled'

interface Props {
  error: {
    label: string
    values: {
      label: string
    }[]
  }
}

export const ErrorsItem: React.FC<Props> = ({ error }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <styled.Item onClick={() => setOpen(open => !open)} active={open} color={open ? 'darkText' : 'darkText1'}>
        <styled.Arrow>
          <Icon name={Name.PICKERS_ARROW} rotation={open ? Rotation.DOWN : undefined} />
        </styled.Arrow>
        <Icon name={Name.GLOBAL_ERROR} />
        <styled.ItemLabel>{error.label}</styled.ItemLabel>
      </styled.Item>
      {open &&
        error.values.map(el => (
          <styled.Item key={el.label} warning color="darkText1">
            <Icon name={Name.GLOBAL_ERROR} />
            <styled.ItemLabel warning dangerouslySetInnerHTML={{ __html: el.label }} />
          </styled.Item>
        ))}
    </>
  )
}
