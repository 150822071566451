import {
  backgroundWidth,
  Component,
  Device,
  iPhone13ProHeight,
  iPhone13ProWidth,
  iPhone14ProHeight,
  iPhone14ProWidth,
  marginWidth,
} from 'pages/ScreenshotsStudio/utils'
import { BrandingColorType, generateFirestoreId } from 'utils'

const screenText = 'Support creators you love'

export const getScreensConfigs = (lastScreenX: number, lastScreenConfig: any[]) => {
  const newX = lastScreenX + backgroundWidth + marginWidth
  return [
    {
      name: 'Blank Screen',
      config: [
        {
          type: Component.BACKGROUND,
          id: generateFirestoreId(),
          background: '',
          colorName: `@${BrandingColorType.primary}`,
        },
        {
          type: Component.TEXT,
          id: generateFirestoreId(),
          x: newX + 41.531,
          y: 50,
          width: 228,
          height: 70,
          angle: 0,
          value: screenText,
          color: '',
          colorName: `@${BrandingColorType.onPrimary}`,
          fontFamily: 'Roboto',
          fontWeight: '700',
          fontStyle: 'normal',
          fontSize: '30',
          textAlign: 'center',
          verticalAlign: 'center',
          lineHeight: '100',
          letterSpacing: '0',
        },
        {
          type: Component.DEVICE,
          id: generateFirestoreId(),
          x: newX + 49.531000000000006,
          y: 168,
          width: iPhone14ProWidth,
          height: iPhone14ProHeight,
          angle: 0,
          deviceType: Device.iPhone14Pro,
          screenshotName: 'LoggedIn Profile',
          image: '',
          color: '',
          colorName: `@${BrandingColorType.onPrimary}`,
        },
      ],
    },
    {
      name: 'Last Screen Style',
      config: lastScreenConfig,
    },
    {
      name: 'Text at the Bottom',
      config: [
        {
          type: Component.BACKGROUND,
          id: generateFirestoreId(),
          background: '',
          colorName: `@${BrandingColorType.primary}`,
        },
        {
          type: Component.TEXT,
          id: generateFirestoreId(),
          x: newX + 41.531,
          y: 530,
          width: 228,
          height: 70,
          angle: 0,
          value: screenText,
          color: '',
          colorName: `@${BrandingColorType.onPrimary}`,
          fontFamily: 'Roboto',
          fontWeight: '700',
          fontStyle: 'normal',
          fontSize: '30',
          textAlign: 'center',
          verticalAlign: 'center',
          lineHeight: '100',
          letterSpacing: '0',
        },
        {
          type: Component.DEVICE,
          id: generateFirestoreId(),
          x: newX + 49.531000000000006,
          y: 60,
          width: iPhone14ProWidth,
          height: iPhone14ProHeight,
          angle: 0,
          deviceType: Device.iPhone14Pro,
          screenshotName: 'LoggedIn Profile',
          image: '',
          color: '',
          colorName: `@${BrandingColorType.onPrimary}`,
        },
      ],
    },
    {
      name: 'Gradient #1',
      config: [
        {
          type: Component.BACKGROUND,
          id: generateFirestoreId(),
          background: 'linear-gradient(90deg, rgb(222, 0, 159) 0%, rgb(119, 9, 121) 35%, rgb(0, 212, 255) 100%)',
          colorName: '',
        },
        {
          type: Component.TEXT,
          id: generateFirestoreId(),
          x: newX + 41.531,
          y: 530,
          width: 228,
          height: 70,
          angle: 0,
          value: screenText,
          color: '',
          colorName: `@${BrandingColorType.onPrimary}`,
          fontFamily: 'Roboto',
          fontWeight: '700',
          fontStyle: 'normal',
          fontSize: '30',
          textAlign: 'center',
          verticalAlign: 'center',
          lineHeight: '100',
          letterSpacing: '0',
        },
        {
          type: Component.DEVICE,
          id: generateFirestoreId(),
          x: newX + 49.531000000000006,
          y: 60,
          width: iPhone14ProWidth,
          height: iPhone14ProHeight,
          angle: 0,
          deviceType: Device.iPhone14Pro,
          screenshotName: 'LoggedIn Profile',
          image: '',
          color: '',
          colorName: `@${BrandingColorType.onPrimary}`,
        },
      ],
    },
  ]
}

export const getDevicesConfigs = (cX: number, cY: number) => [
  {
    type: Component.DEVICE,
    id: generateFirestoreId(),
    x: cX - iPhone13ProWidth / 2,
    y: cY - iPhone13ProHeight / 2,
    width: iPhone13ProWidth,
    height: iPhone13ProHeight,
    angle: 0,
    deviceType: Device.iPhone13Pro,
    screenshotName: '',
    color: '',
    colorName: `@${BrandingColorType.onPrimary}`,
    image: '',
  },
  {
    type: Component.DEVICE,
    id: generateFirestoreId(),
    x: cX - iPhone14ProWidth / 2,
    y: cY - iPhone14ProHeight / 2,
    width: iPhone14ProWidth,
    height: iPhone14ProHeight,
    angle: 0,
    deviceType: Device.iPhone14Pro,
    screenshotName: '',
    color: '',
    colorName: `@${BrandingColorType.onPrimary}`,
    image: '',
  },
]
