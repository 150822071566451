import React from 'react'
import { ISvg, Svg } from '../Svg'

const Paywall: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#7F899E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35301 11.2054C6.27911 11.0743 5.34797 10.6009 5.05946 9.79819C5.02445 9.70249 5 9.59294 5 9.48456C5 9.27606 5.09226 9.08592 5.26308 8.95294C5.42879 8.82394 5.64328 8.76731 5.8635 8.76731C6.04134 8.76731 6.21672 8.80495 6.37244 8.89744C6.52814 8.98991 6.64036 9.12308 6.71699 9.27808L6.72322 9.2907C6.78712 9.43658 6.88249 9.55535 7.02727 9.64622C7.11066 9.69856 7.21685 9.74571 7.35301 9.78187V8.59083C6.69515 8.46329 6.14428 8.27191 5.74962 7.97834C5.32594 7.66319 5.09913 7.24188 5.09913 6.72035C5.09913 6.14055 5.39344 5.66272 5.86756 5.32865C6.26223 5.05056 6.77679 4.87363 7.35301 4.8V4.55415C7.35301 4.39092 7.42627 4.24211 7.55816 4.13901C7.68461 4.04016 7.84237 4 7.99217 4C8.14172 4 8.29828 4.04138 8.42321 4.14026C8.55314 4.2431 8.62612 4.39115 8.62612 4.55415V4.80165C9.66723 4.94177 10.5309 5.43523 10.8103 6.21902C10.8494 6.32605 10.8643 6.42722 10.8643 6.53217C10.8643 6.74308 10.7633 6.92781 10.5947 7.05316C10.4316 7.17435 10.2231 7.22851 10.0113 7.22851C9.81785 7.22851 9.63939 7.18441 9.48554 7.09037C9.33381 6.99762 9.2277 6.86962 9.15308 6.73292L9.14825 6.72406C9.0604 6.54971 8.94836 6.42478 8.81121 6.33669C8.75669 6.30167 8.69539 6.27065 8.62612 6.24441V7.33525C9.31867 7.46577 9.90277 7.65342 10.3198 7.95069C10.762 8.26589 11 8.69337 11 9.23783C11 9.8663 10.6746 10.3587 10.169 10.6928C9.74987 10.9698 9.21098 11.1372 8.62612 11.2063V11.4458C8.62612 11.6088 8.55314 11.7569 8.42321 11.8597C8.29828 11.9586 8.14172 12 7.99217 12C7.84237 12 7.68461 11.9598 7.55816 11.861C7.42627 11.7579 7.35301 11.6091 7.35301 11.4458V11.2054ZM7.35301 5.90277C6.82377 6.03265 6.54697 6.32782 6.54697 6.64926C6.54697 6.97261 6.77298 7.2235 7.35301 7.39679C7.45562 7.42745 7.56931 7.45568 7.69479 7.48145V5.84635C7.5712 5.85804 7.45721 5.8772 7.35301 5.90277ZM8.62612 10.1235C8.52188 10.1415 8.40808 10.1545 8.28434 10.162V8.44746C8.40913 8.47112 8.52279 8.49595 8.62612 8.5222C9.32633 8.70004 9.55215 8.94248 9.55215 9.32147C9.55215 9.70687 9.27348 10.0116 8.62612 10.1235Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Paywall
