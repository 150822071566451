import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import * as styled from './Crumbs.styled'

import { Icon, Name, Rotation } from 'components'

interface Props {
  icon?: ReactElement | null
  firstCrumbTitle?: string
  onFirstCrumbClick?: () => void
  secondCrumbTitle?: string
  to?: string
  reverseColors?: boolean
  small?: boolean
}

export const Crumbs: React.FC<Props> = ({
  icon = <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={Rotation.DOWN} />,
  firstCrumbTitle,
  onFirstCrumbClick,
  secondCrumbTitle,
  to,
  reverseColors,
  small,
}) => {
  const navigate = useNavigate()

  return (
    <styled.Crumbs>
      <styled.Crumb
        reverseColors={reverseColors}
        clickable={!!onFirstCrumbClick || !!secondCrumbTitle}
        onClick={onFirstCrumbClick || (!!secondCrumbTitle && to ? () => navigate(to) : undefined)}
        small={small}
      >
        {icon && <styled.IconWrapper>{icon}</styled.IconWrapper>}
        {firstCrumbTitle} {secondCrumbTitle && '/ '}
      </styled.Crumb>
      {secondCrumbTitle && (
        <styled.Crumb reverseColors={reverseColors} small={small}>
          {secondCrumbTitle}
        </styled.Crumb>
      )}
    </styled.Crumbs>
  )
}
