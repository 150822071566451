import React from 'react'
import { ISvg, Svg } from '../Svg'

const SmallArrowThinDown: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 8" fill="none" {...props}>
    <path d="M5 0L8 3" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M11 0L8 3" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default SmallArrowThinDown
