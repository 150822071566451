import React from 'react'
import { ISvg, Svg } from '../Svg'

const OpenNavMenu: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="1.25" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="3" y="3" width="4" height="10" rx="1" fill="#7F899E" />
  </Svg>
)

export default OpenNavMenu
