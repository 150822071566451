import React from 'react'
import { ISvg, Svg } from '../Svg'

const Column: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_15106_39495)">
      <rect
        x="9.75"
        y="15.25"
        width="3.5"
        height="3.5"
        rx="1"
        transform="rotate(180 9.75 15.25)"
        stroke="#7F899E"
        strokeWidth="1.5"
      />
      <rect
        x="9.75"
        y="9.75"
        width="3.5"
        height="3.5"
        rx="1"
        transform="rotate(180 9.75 9.75)"
        stroke="#7F899E"
        strokeWidth="1.5"
      />
      <rect
        x="9.75"
        y="4.25"
        width="3.5"
        height="3.5"
        rx="1"
        transform="rotate(180 9.75 4.25)"
        stroke="#7F899E"
        strokeWidth="1.5"
      />
      <path d="M13 14L13 1.95964" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 14L3 1.95964" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_15106_39495">
        <rect width="16" height="16" fill="white" transform="translate(0 16) rotate(-90)" />
      </clipPath>
    </defs>
  </Svg>
)

export default Column
