import { useEffect, useState } from 'react'

const mobileBreakpoint = 1033

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint)

  useEffect(() => {
    const resize = () => setIsMobile(window.innerWidth <= mobileBreakpoint)
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return isMobile
}
