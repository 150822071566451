import React from 'react'
import { ISvg, Svg } from '../Svg'

const AISmall: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M5.34783 0L6.88493 2.77057L9.69565 4.28571L6.88493 5.80086L5.34783 8.57143L3.81072 5.80086L1 4.28571L3.81072 2.77057L5.34783 0ZM11.4348 7.14286L12.4597 8.98971L14.3333 10L12.4597 11.0103L11.4348 12.8571L10.4099 11.0103L8.53623 10L10.4099 8.98971L11.4348 7.14286ZM5.34783 13.1429L5.86029 14.0663L6.7971 14.5714L5.86029 15.0766L5.34783 16L4.83536 15.0766L3.89855 14.5714L4.83536 14.0663L5.34783 13.1429Z"
      fill="#7F899E"
    />
  </Svg>
)
export default AISmall
