import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './Plans.styled'

import { Crumbs, Interval, PlanCard, Switch, SwitchVariant, Text, Type } from 'components'
import { ProjectContext } from 'context'
import { useProjectPlan } from 'hooks'
import { SettingsLayout } from '../components'
import { Table } from './components'

export const Plans: React.FC = () => {
  const { id, currentProjectId, workspaceId } = useParams()
  const { project } = useContext(ProjectContext)
  const { activeSubscription, plans, onPlanChange, ChangePlanAlert } = useProjectPlan(
    workspaceId || project.workspace.id,
    currentProjectId || id
  )
  const [interval, setInterval] = useState<Interval>(Interval.month)

  return (
    <>
      <SettingsLayout maxWidth={workspaceId ? '1280px' : '980px'} height={workspaceId ? '100vh' : undefined}>
        <styled.Content>
          <styled.TitleBox>
            {workspaceId ? (
              <Crumbs
                firstCrumbTitle="Billing"
                secondCrumbTitle="Project plans"
                to={`/workspaces/${workspaceId}/billing`}
              />
            ) : (
              <Text type={Type.TITLE}>Project plans</Text>
            )}
            <styled.MonthlyBox>
              <styled.Variant choosed={interval === Interval.month} style={{ marginRight: '10px' }}>
                Monthly
              </styled.Variant>
              <Switch
                checked={interval === Interval.year}
                variant={SwitchVariant.GREEN}
                onChange={value => setInterval(value ? Interval.year : Interval.month)}
              />
              <styled.Variant choosed={interval === Interval.year} style={{ marginLeft: '10px' }}>
                Yearly
                <span>&nbsp;(save up to 20%)</span>
              </styled.Variant>
            </styled.MonthlyBox>
          </styled.TitleBox>
          <Text type={Type.SUB_TITLE} styles={{ maxWidth: '700px' }}>
            Select your app’s plan.
          </Text>
          {!!plans.length && (
            <>
              <styled.CardsBox>
                {plans.map(el => (
                  <PlanCard
                    key={el.id}
                    plan={el}
                    interval={interval}
                    activeSubscription={activeSubscription}
                    onPlanChange={onPlanChange}
                  />
                ))}
              </styled.CardsBox>
              <Table />
            </>
          )}
        </styled.Content>
      </SettingsLayout>
      {ChangePlanAlert}
    </>
  )
}
