import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden auto;
  background-color: ${props => props.theme.colors.secondaryBackground};
  padding: 30px 40px 60px;
  margin-bottom: 100px;
`

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`
