import styled from 'styled-components'

import * as text from 'partials/LandingLayout/components/TextStyles'

interface IContentBox {
  maxHeight: number
  maxHeightMobileLg: number
  maxHeightMobileMd: number
}

export const ContentBox = styled.div<IContentBox>`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.headerSize.heightDesktop}px;

  @media (min-width: ${props => props.theme.breakpoints.lg + 1}px) {
    @media (max-height: ${(props: IContentBox) => props.maxHeight}px) {
      margin-top: calc(${props => props.theme.headerSize.heightDesktop}px + 10px);
      align-items: flex-start;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    @media (max-height: ${(props: IContentBox) => props.maxHeightMobileLg}px) {
      margin-top: calc(${props => props.theme.headerSize.heightMobile}px + 10px);
      align-items: flex-start;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    padding-bottom: ${props => props.theme.spacing.s1}px;
    margin-top: 40px;
    align-items: center;
    @media (max-height: ${(props: IContentBox) => props.maxHeightMobileMd}px) {
      margin-top: calc(${props => props.theme.headerSize.heightMobile}px + 10px);
      align-items: flex-start;
    }
  }
`

export const LoginWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.headerSize.heightDesktop}px;
  padding-top: 100px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    margin-top: ${props => props.theme.headerSize.heightMobile}px;
    padding-top: 50px;
  }
`

export const AuthenticateWrapper = styled.div`
  & > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    ${text.FS50}
    margin-bottom: 20px;
    font-weight: 800;
    & figure {
      transform: scale(1);
      margin: 0 30px 0 0;
      position: relative;
      width: 30px;
      height: 40px;
      cursor: pointer;
      transition: ${props => props.theme.transition.primary};
      &:hover {
        transform: translateX(-15px);
        & > div {
          width: 40px;
        }
      }
      & * {
        position: absolute;
        height: 3px;
        background-color: ${props => props.theme.colors.black};
        border-radius: 0 3px 3px 0;
      }
      & > span {
        width: 15px;
        left: 0;
        &:first-child {
          transform: rotate(-45deg);
          top: 15.6px;
        }
        &:nth-child(2) {
          top: 24.4px;
          transform: rotate(45deg);
        }
      }
      & > div {
        width: 25px;
        left: 5px;
        top: 20px;
        transition: ${props => props.theme.transition.primary};
      }
    }
  }
  & > p {
    ${text.FS17}
    max-width: 655px;
    margin: 0 auto;
    text-align: center;
    @media (max-width: ${props => props.theme.breakpoints.sm}px) {
      font-size: 15px;
    }
  }
  & > div {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > form {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      & > button {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.darkText};
        border-radius: 16px;
        font-size: 15px;
        line-height: 100%;
        font-weight: ${props => props.theme.weights.bold};
        color: ${props => props.theme.colors.black};
        cursor: pointer;
        &:hover {
          background: ${props => props.theme.colors.primaryButtonHover};
        }
        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        & svg {
          position: absolute;
          top: 11px;
          left: 12px;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      & > button[name='secondary'] {
        background: ${props => props.theme.colors.blue};
        border: 1px solid;
        font-weight: ${props => props.theme.weights.regular};
        color: ${props => props.theme.colors.white};
        &:hover {
          background: ${props => props.theme.colors.secondaryButtonHover};
        }
      }
      & > input {
        height: 56px;
        width: 100%;
        border: 1px solid ${props => props.theme.colors.borderColor};
        border-radius: 16px;
        ${text.FS17_NOT_CHANGE}
        padding: 15px;
        margin-bottom: 28px;
        background: ${props => props.theme.colors.white};
        box-sizing: border-box;
        display: block;
        font-family: 'Averta', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif,
          Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: ${props => props.theme.colors.grayText};
        }
        & + button {
          height: 56px;
        }
      }
      & > p {
        text-align: center;
        color: ${props => props.theme.colors.grayText};
        text-decoration: underline;
        cursor: pointer;
        margin-top: 20px;
        ${text.FS17}
      }
      & > section {
        color: ${props => props.theme.colors.red};
        font-size: ${props => props.theme.fontSize.s2}px;
        font-weight: ${props => props.theme.weights.regular};
        background-color: ${props => props.theme.colors.red}20;
        padding: 12px 15px;
        max-width: 400px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 6px;
        margin-bottom: 15px;
        & > a {
          text-decoration: underline;
        }
      }
      & > article {
        margin: 5px 0 15px;
        color: ${props => props.theme.colors.greyText};
        font-size: 10px;
        line-height: 13px;
        width: 417px;
        max-width: 100%;
        text-align: center;
        font-weight: ${props => props.theme.weights.regular};
        & a {
          color: ${props => props.theme.colors.blueText};
          text-decoration: none;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    & > h2 {
      margin-bottom: 15px;
      & figure {
        margin-right: 15px;
        transform: scale(0.7);
        &:hover {
          transform: scale(0.7) translateX(-15px);
        }
      }
    }
    & > div {
      margin-top: 30px;
      & > form {
        width: calc(100vw - 40px);
        max-width: 400px;
        & input {
          margin-bottom: 10px;
          height: 50px;
          & + button {
            height: 50px;
          }
        }
        & > p {
          margin-top: 10px;
        }
      }
    }
  }
`
