import { Name } from 'components'
import { landingUrl } from 'utils'

export const MENU_LIST_ITEMS = [
  { name: 'Features', canOpen: true },
  { name: 'Templates', canOpen: false, href: `${landingUrl}/templates` },
  { name: 'Experts', canOpen: false, href: `${landingUrl}/experts` },
  { name: 'Blog', canOpen: false, href: `${landingUrl}/blog` },
  { name: 'Pricing', canOpen: false, href: `${landingUrl}/pricing` },
]

export interface IProduct {
  icon: Name
  title: string
  description: string
  href?: string
}

export const PRODUCTS: IProduct[] = [
  {
    icon: Name.LANDING_PRODUCTS_CODEPLATFORM_AI,
    title: 'AI App Builder',
    description: 'Generate code, assets and appstores content with AI in minutes',
    href: `${landingUrl}/ai`,
  },
  {
    icon: Name.LANDING_PRODUCTS_APP_TEMPLATES,
    title: 'App Templates',
    description: 'Well-architected iOS Swift & Android Kotlin project templates bundled into pre-built modules',
    href: `${landingUrl}/templates`,
  },
  {
    icon: Name.LANDING_PRODUCTS_VISUAL_APP_BUILDER,
    title: 'Visual App Builder',
    description: 'A low-code way to tune your app template and update the source code',
  },
  {
    icon: Name.LANDING_PRODUCTS_IN_BROWSER_APP_PREVIEW,
    title: 'In-Browser Apps preview',
    description: 'Preview native apps in browser without opening Xcode or Android Studio',
  },
  {
    icon: Name.LANDING_PRODUCTS_CMS,
    title: 'Content Management System',
    description: 'Headless CMS for your app with content models and digital assets management',
  },
  {
    icon: Name.LANDING_PRODUCTS_CONTINIOUS_INTEGRATION,
    title: 'Continuous Integration',
    description: 'Build and deploy everything your app needs. No additional DevOps',
  },
  {
    icon: Name.LANDING_PRODUCTS_SCREENSHOTS_GENERATOR,
    title: 'Screenshots Generator',
    description: 'A professional app store screenshots editor. Create eye-catching screenshots and get more downloads',
  },
  {
    icon: Name.LANDING_PRODUCTS_APP_LANDING_PAGE,
    title: 'App Landing Page',
    description: 'Get your own appstore-optimized application homepage with all the necessary content',
  },
  {
    icon: Name.LANDING_PRODUCTS_STORE_MANAGER,
    title: 'Store Manager',
    description: 'Publishing process made easy. Generate and control app stores content all in one place',
  },
  {
    icon: Name.LANDING_PRODUCTS_BACKEND_AS_A_SERVICE,
    title: 'Backend-as-a-service',
    description: 'Generate and deploy serverless app backend in a click. Pay as you go',
  },
  {
    icon: Name.LANDING_PRODUCTS_TERMS_AND_POLICES_GENERATOR,
    title: 'Terms & Policies Generator',
    description: 'A wizard to create humanly understandable terms and policies for your app',
  },
]
