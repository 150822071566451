import {
  BrandingColorType,
  ComponentAlignment,
  ComponentType,
  ComponentsDirection,
  ScreenComponent,
  TextAlignment,
  generateFirestoreId,
  listEmptyName,
} from 'utils'

export const getEmptyComponent = (language: string): ScreenComponent => ({
  id: generateFirestoreId(),
  componentAlignment: ComponentAlignment.fill,
  name: listEmptyName,
  componentType: ComponentType.overlay,
  subComponents: [
    {
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.center,
      name: 'stack 1',
      componentType: ComponentType.stack,
      componentsSpacing: { constant: '16' },
      componentsDirection: ComponentsDirection.vertical,
      width: { constant: '250' },
      subComponents: [
        {
          id: generateFirestoreId(),
          componentAlignment: ComponentAlignment.center,
          name: 'image 1',
          componentType: ComponentType.image,
          image: { constant: { resourceId: 'yvbz6jg4vjjfx6hz9bql' } },
          width: { constant: '30' },
          imageColor: { constant: `@${BrandingColorType.onBackground}` },
        },
        {
          id: generateFirestoreId(),
          componentAlignment: ComponentAlignment.fill,
          name: 'text 1',
          componentType: ComponentType.text,
          text: { key: 'key', locales: { [language]: 'This is where your content will be displayed' } },
          textAlignment: TextAlignment.center,
          textColor: { constant: `@${BrandingColorType.onBackground}` },
          font: '@Body Bold',
        },
      ],
    },
  ],
})
