import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'

import { Button, ButtonColor, Modal, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { Integration, IntegrationAppstore, IntegrationGithub, IntegrationId } from 'hooks'
import { ProjectsService } from 'services/projects'

interface Props {
  integration: Integration
  applicationIntegration?: boolean
}

export const Unlink: React.FC<Props> = ({ integration, applicationIntegration }) => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id, repositoryId } = useParams()
  const [open, setOpen] = useState(false)
  const [wait, setWait] = useState(false)

  const unlink = () => {
    setWait(true)
    startLoader()
    ;(applicationIntegration
      ? ProjectsService.deleteApplicationIntegration(id as string, repositoryId as string, integration.id)
      : ProjectsService.deleteIntegration(id as string, integration.id)
    )
      .then(() => setOpen(false))
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
  }

  return (
    <>
      <Button color={ButtonColor.CUSTOM_DELETE} onClick={() => setOpen(true)}>
        Unlink
      </Button>
      <Modal
        active={open}
        onClose={() => setOpen(false)}
        title={`Unlink from ${
          integration.id === IntegrationId.github
            ? 'GitHub'
            : integration.id === IntegrationId.appstore
            ? 'App Store'
            : ''
        }`}
        onButtonClick={unlink}
        buttonTitle="Unlink"
        buttonDisabled={wait}
        deleteButton
      >
        <Box>
          <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
            {`Unlink this app from the connected ${
              integration.id === IntegrationId.github
                ? 'GitHub repository'
                : integration.id === IntegrationId.appstore
                ? 'App Store account'
                : ''
            }?`}
          </Text>
          <InputWrapper>
            <input
              value={
                integration.id === IntegrationId.github
                  ? (integration.data as IntegrationGithub | undefined)?.slug
                  : integration.id === IntegrationId.appstore
                  ? 'App Store'
                  : (integration.data as IntegrationAppstore | undefined)?.teamName
              }
              disabled
            />
          </InputWrapper>
        </Box>
      </Modal>
    </>
  )
}
