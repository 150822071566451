import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router'
import { Link, useNavigate, useParams } from 'react-router-dom'

import * as styled from './Workspaces.styled'

import { Select } from 'components'
import { WorkspaceContext } from 'context'
import { hasUpdatedAtSort, updatedAtSort } from 'pages/Projects/pages'
import { IRoute, routes } from 'routes'
import { Notifications } from '../Notifications'
import { Profile } from '../Profile'
import { NewWorkspace } from './components'

interface Props {
  isMobile: boolean
}

export const Workspaces: React.FC<Props> = ({ isMobile }) => {
  const { workspaceId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { workspace, workspaces, projects } = useContext(WorkspaceContext)

  const replacedPath = (path: string) => {
    if (workspaceId) {
      path = path.replace(':workspaceId', workspaceId)
    }
    return path
  }

  const checkActive = (route: IRoute) => {
    const path = replacedPath(route.path)
    const childPath = replacedPath(route.childPath || '')

    return childPath
      ? pathname === childPath || pathname === path
      : route.innerRoutes
      ? pathname.startsWith(path)
      : pathname === path
  }

  const sortedProjects = useMemo(
    () =>
      projects
        .slice()
        .sort(hasUpdatedAtSort)
        .sort((a, b) => -updatedAtSort(a, b)),
    [projects]
  )

  return (
    <styled.ProjectControllsWrapper>
      {isMobile ? (
        <Box
          overflow="auto"
          display="flex"
          flexDirection="column"
          gap="30px"
          margin="0 -13px 13px"
          padding="0 13px"
          height="100%"
        >
          <styled.SelectWrapper>
            {!!workspaces.length && (
              <Select
                options={workspaces.map(el => ({ value: el.name, onClick: () => navigate(`/workspaces/${el.id}`) }))}
              />
            )}
          </styled.SelectWrapper>
          <Box display="flex" flexWrap="wrap" rowGap="40px">
            {sortedProjects.map(el => (
              <styled.Project key={el.id} to={`/projects/${el.id}`}>
                <img src={el.logoUrl} alt={el.name} />
                <div>{el.name}</div>
              </styled.Project>
            ))}
          </Box>
        </Box>
      ) : (
        <Box overflow="auto" margin="0 -13px 20px" padding="0 10px">
          {workspaces.map(el => {
            const active = !!workspaceId && workspace?.id === el.id
            return (
              <styled.ProjectNavigation key={el.id}>
                <styled.WorkspaceWrapper active={active} onClick={() => navigate(`/workspaces/${el.id}`)}>
                  <styled.WorkspaceIcon active={active}>{el.name[0]}</styled.WorkspaceIcon>
                  <styled.WorkspaceName>{el.name}</styled.WorkspaceName>
                </styled.WorkspaceWrapper>
                <styled.WorkspaceHideBox active={active}>
                  {routes.map(
                    el =>
                      el.projectsPageIcon && (
                        <styled.ProjectLinkBox key={el.path} active={checkActive(el)}>
                          <Link to={replacedPath(el.path)}>
                            {el.projectsPageIcon} {el.name}
                          </Link>
                        </styled.ProjectLinkBox>
                      )
                  )}
                  {active && <hr />}
                </styled.WorkspaceHideBox>
              </styled.ProjectNavigation>
            )
          })}
          <NewWorkspace />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap="12px">
        {!isMobile && <Notifications wide />}
        <Profile isMobile={isMobile} />
      </Box>
    </styled.ProjectControllsWrapper>
  )
}
