import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './Section.styled'

interface SectionProps {
  children: React.ReactNode
  title: string
  subTitle?: string
  noFlex?: boolean
  hints?: string[]
}

export const Section: React.FC<SectionProps> = ({ children, title, subTitle, noFlex, hints }) => {
  const themeContext = useContext(ThemeContext)

  return (
    <Box p="0 16px" mb="80px">
      <Typography
        marginBottom="5px"
        fontSize="18px"
        lineHeight="150%"
        color={themeContext.colors.darkText}
        fontWeight={themeContext.weights.bold}
        variant="h2"
      >
        {title}
      </Typography>
      {subTitle && <styled.SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />}
      {hints && (
        <styled.Hints>
          {hints.map(el => (
            <li key={el} dangerouslySetInnerHTML={{ __html: el }} />
          ))}
        </styled.Hints>
      )}
      <Box mt="75px" display="flex">
        {noFlex ? (
          children
        ) : (
          <Box display="flex" columnGap="40px" rowGap="86px" flexWrap="wrap">
            {children}
          </Box>
        )}
      </Box>
    </Box>
  )
}

interface ChapterTitleProps {
  children: React.ReactNode
  id?: string
}

export const ChapterTitle: React.FC<ChapterTitleProps> = ({ children, id }) => (
  <Box id={id} display="flex" marginBottom="15px" p="4px 16px" flexDirection="column" justifyContent="center">
    <styled.ChapterTitle>{children}</styled.ChapterTitle>
  </Box>
)
