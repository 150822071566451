import React from 'react'
import { ISvg, Svg } from '../Svg'

const Arrow: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M8 10L12 14L16 10" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Arrow
