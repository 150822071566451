import { Box } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'

import {
  acceptDataTypesValues,
  AnyVariableField,
  Button,
  ButtonColor,
  CustomPicker,
  dataTypes,
  getValueType,
  SelectStringValueField,
  StringField,
  VariableField,
} from 'components'
import { LocalVariable, Screen, ValueType, VariableSourceType } from 'utils'

const buttonPadding = { padding: '9.5px' }

interface Props {
  close: () => void
  value: LocalVariable
  onChange: (val: LocalVariable) => void
  onRemove?: () => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const LocalVariablePicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  onRemove,
  screenConfig,
  allLocalVariables,
}) => {
  const [subPicker, setSubPicker] = useState<ReactElement | null>(null)
  const [localVariable, setLocalVariable] = useState(JSON.parse(JSON.stringify(value)) as LocalVariable)
  const { name, variable, type, accept, collection } = localVariable
  const localType = type || getValueType(variable)

  useEffect(() => {
    setLocalVariable(JSON.parse(JSON.stringify(value)) as LocalVariable)
  }, [value])

  const onConfirm = () => {
    onChange(localVariable)
    close()
  }

  const onDelete =
    value && onRemove
      ? () => {
          onRemove()
          close()
        }
      : undefined

  const confirmDisabled =
    !name ||
    (type === ValueType.array && !accept) ||
    ((type === ValueType.record || accept === ValueType.record) && !collection)

  return (
    <CustomPicker
      title="Local variables"
      close={close}
      optionsContainer={
        <Box maxHeight="500px" overflow="auto">
          {subPicker || (
            <>
              <StringField
                label="Name"
                value={name}
                onChange={value => setLocalVariable(localVariable => ({ ...localVariable, name: value }))}
                setSubPicker={setSubPicker}
              />
              <SelectStringValueField
                label="Type"
                value={localType}
                onChange={value =>
                  setLocalVariable(localVariable => ({ name: localVariable.name, type: value as ValueType }))
                }
                setSubPicker={setSubPicker}
                options={dataTypes.map(el => el.value)}
              />
              {type === ValueType.array && (
                <SelectStringValueField
                  label="Accept"
                  value={accept}
                  onChange={value =>
                    setLocalVariable(localVariable => ({
                      name: localVariable.name,
                      type: localVariable.type,
                      accept: value as ValueType,
                    }))
                  }
                  setSubPicker={setSubPicker}
                  options={acceptDataTypesValues.map(el => el.value)}
                />
              )}
              {(type === ValueType.record || accept === ValueType.record) && (
                <VariableField
                  label="Collection"
                  source={
                    collection ? { type: VariableSourceType.collection, collection: { name: collection } } : undefined
                  }
                  onSourceChange={value =>
                    setLocalVariable(localVariable => ({
                      name: localVariable.name,
                      type: localVariable.type,
                      accept: localVariable.accept,
                      collection: value?.collection?.name,
                    }))
                  }
                  setSubPicker={setSubPicker}
                  collectionMode
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {localType && (
                <AnyVariableField
                  valueType={localType}
                  label="Value"
                  value={variable}
                  onChange={value =>
                    setLocalVariable(localVariable =>
                      value ? { name: localVariable.name, variable: value } : { name: localVariable.name }
                    )
                  }
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              <Box
                padding="15px 10px"
                display="flex"
                justifyContent="space-between"
                gap="8px"
                flexWrap="wrap-reverse"
                width="100%"
              >
                {onDelete ? (
                  <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
                    Remove
                  </Button>
                ) : (
                  <span />
                )}
                <Box display="flex" gap="8px">
                  <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
    />
  )
}
