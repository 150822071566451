import React from 'react'
import { ISvg, Svg } from '../Svg'

const TopBar: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="0.75" y="6.75" width="30.5" height="18.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M9 13L6 16L9 19" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M15.7344 21C16.1148 21 16.3311 20.8102 16.4689 20.3695L17.1836 18.2678H20.8033L21.5246 20.3763C21.6557 20.8102 21.8721 21 22.2656 21C22.6918 21 23 20.7085 23 20.3085C23 20.1797 22.9738 20.0508 22.9082 19.861L20.023 11.7864C19.8262 11.2441 19.5115 11 19 11C18.482 11 18.1607 11.2508 17.9705 11.7932L15.0918 19.861C15.0262 20.0441 15 20.1932 15 20.3085C15 20.722 15.2951 21 15.7344 21ZM17.5574 17.0271L18.9672 12.7356H19.0066L20.4295 17.0271H17.5574Z"
      fill="#7F899E"
    />
  </Svg>
)

export default TopBar
