import { withErrorBoundary } from '@sentry/react'
import React, { memo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { VersionController } from 'components'
import { AdminConversations, AI, Bridge, GithubConnection } from 'pages'
import { Layout } from 'partials'
import { LogIn, NotFound, SomethingWentWrong } from 'partials/LandingLayout/pages'
import { ProgressProvider } from 'providers'
import { getRoutes } from 'routes'

const Root: React.FC = () => (
  <BrowserRouter>
    <ProgressProvider>
      <VersionController />
      <Routes>
        <Route path="/" element={<Layout />}>
          {getRoutes()}
        </Route>
        <Route path="/ai" element={<AI />} />
        <Route path="/admin/conversations" element={<AdminConversations />} />
        <Route path="/bridge" element={<Bridge />} />
        <Route path="/connected" element={<GithubConnection />} />
        <Route path="/auth" element={<LogIn />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ProgressProvider>
  </BrowserRouter>
)

export default withErrorBoundary(memo(Root), { fallback: <SomethingWentWrong /> })
