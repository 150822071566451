import styled from 'styled-components'

export const FileInputBottomIcons = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
  & > div {
    border: 1px solid ${props => props.theme.colors.borderColor};
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }
`

export const FileInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: 1px dashed ${props => props.theme.colors.borderColor};
  border-radius: 8px;
  transition: 300ms ease-out;
  height: 140px;
  &:hover {
    border-color: ${props => props.theme.colors.blue};
    & > span {
      color: ${props => props.theme.colors.iconHover};
      & svg path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
  & > img,
  & > video,
  & > audio,
  & > p {
    pointer-events: none;
    z-index: 2;
    height: 114px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
    text-align: center;
    object-fit: contain;
    font-size: 14px;
  }
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: ${props => props.theme.weights.medium};
    font-size: 12px;
    line-height: 150%;
    color: ${props => props.theme.colors.grey1};
    text-transform: uppercase;
  }
  & input {
    position: absolute;
    opacity: 0;
    height: 140px;
    cursor: pointer;
  }
`

export const Info = styled.div`
  z-index: 2;
  height: 114px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  & > div {
    display: flex;
    & > div:first-child {
      width: 200px;
    }
  }
`
