import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { BrandingColorInput, Button, CommonPicker } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { BackgroundGradient, BackgroundGradientDirection } from 'utils'

const gradientDirectionValues = Object.values(BackgroundGradientDirection).map(el => ({ value: el, label: el }))

interface Props {
  value?: BackgroundGradient
  onChange: (val: BackgroundGradient) => void
  close: () => void
}

const buttonPadding = { padding: '9.5px' }

export const OptionsContainer: React.FC<Props> = ({ value, onChange, close }) => {
  const [gradient, setGradient] = useState(value)

  useEffect(() => {
    setGradient(value)
  }, [value])

  const onConfirm = () => {
    if (gradient) {
      onChange(gradient)
      close()
    }
  }

  const confirmDisabled = !gradient || !gradient.direction || !gradient.start || !gradient.end

  return (
    <Box maxHeight="500px" overflow="auto">
      <Box p="0 10px 129px">
        <SettingField
          insidePicker
          small
          subLabel="Direction"
          value={gradient?.direction}
          optionsContainer={
            <CommonPicker
              title="Directions"
              value={gradient?.direction}
              onChange={val => setGradient(gradient => ({ ...gradient, direction: val } as BackgroundGradient))}
              values={gradientDirectionValues}
              close={() => {}}
            />
          }
        />
        <BrandingColorInput
          insidePicker
          small
          subLabel="Start"
          value={gradient?.start}
          onChange={val => setGradient(gradient => ({ ...gradient, start: val } as BackgroundGradient))}
        />
        <BrandingColorInput
          insidePicker
          small
          subLabel="End"
          value={gradient?.end}
          onChange={val => setGradient(gradient => ({ ...gradient, end: val } as BackgroundGradient))}
        />
      </Box>
      <Box padding="15px 10px" display="flex" justifyContent="flex-end" width="100%">
        <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
          Confirm
        </Button>
      </Box>
    </Box>
  )
}
