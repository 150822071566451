import styled, { css } from 'styled-components'

export const Sidebar = styled.div<{ wide: boolean; isMobile: boolean }>`
  width: ${props => (props.wide ? '266px' : '60px')};
  min-width: ${props => (props.wide ? '266px' : '60px')};
  min-height: 100%;
  box-sizing: ${props => (props.wide ? 'border-box' : 'content-box')};
  background: ${props => (props.wide ? props.theme.colors.primaryBackground : props.theme.colors.secondaryBackground)};
  padding: ${props => props.wide && '13px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  ${props =>
    props.wide &&
    props.isMobile &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${props => props.theme.colors.secondaryBackground};
    `}
`
