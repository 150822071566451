import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
`

export const TableWrapper = styled.div`
  max-width: 996px;
  width: 100%;
`

export const TableDropDowns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const Table = styled.table`
  max-width: 996px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  border-collapse: initial;
  border-radius: 8px;
  overflow: hidden;
  * {
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.grey1};
    font-weight: ${props => props.theme.weights.normal};
  }
  & thead {
    background-color: ${props => props.theme.colors.primaryBackground};
    height: 41px;
    & th {
      vertical-align: middle;
      padding: 10px 15px;
      &:nth-child(1) {
        position: relative;
        & div {
          position: absolute;
          width: 288px;
          top: 11px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @media (max-width: 1080px) {
            position: static;
            width: auto;
          }
        }
      }
      &:nth-child(2) {
        width: 60%;
      }
      &:last-child {
        & svg path {
          fill: ${props => props.theme.colors.darkText1};
        }
      }
      & span {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & svg {
          margin-right: 6px;
        }
      }
      & b {
        color: ${props => props.theme.colors.darkText};
        font-weight: ${props => props.theme.weights.medium};
        margin-right: 4px;
      }
    }
  }
  & a {
    color: ${props => props.theme.colors.darkText};
    text-decoration: none;
    display: flex;
    align-items: center;
    & svg {
      margin-right: 10px;
    }
  }
  & td {
    & > a {
      padding: 10px 15px;
    }
    border-top: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
    vertical-align: middle;
  }
  & tr td:nth-child(1) {
    & svg {
      min-width: 16px;
    }
  }
  & tr td:nth-child(2) {
    position: relative;
    & a {
      width: calc(100% - 40px);
      left: 0;
      top: 2px;
      position: absolute;
      & div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  & tr td:nth-child(3) {
    white-space: nowrap;
  }
  & tr {
    transition: ${props => props.theme.transition.primary};
    &:hover {
      background-color: ${props => props.theme.colors.primaryBackground};
    }
  }
`
