import React from 'react'
import { ISvg, Svg } from '../Svg'

const Conversations: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="3.25" y="3.25" width="6.5" height="6.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="3.25" width="15.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M16 6L24 6" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M16 15L20 15" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M16 24L24 24" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <rect x="3.25" y="12.25" width="6.5" height="6.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="12.25" width="15.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="3.25" y="21.25" width="6.5" height="6.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="13.25" y="21.25" width="15.5" height="6.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Conversations
