import { Box } from '@mui/material'
import React, { useRef, useState } from 'react'

import * as styled from './TheadController.styled'

import { CustomPicker, Icon, Name, Switch } from 'components'
import { useOnClickOutside } from 'hooks'
import { SettingField } from 'partials/RightSidebar/components'
import { CMSCollectionProperty } from 'services/cms'
import { firstLetterUpperCase } from 'utils'
import { Movable } from './Movable'

interface Props {
  properties: CMSCollectionProperty[]
  setProperties: React.Dispatch<React.SetStateAction<CMSCollectionProperty[]>>
  activeColumns: CMSCollectionProperty[]
  inactiveColumns: CMSCollectionProperty[]
}

export const TheadController: React.FC<Props> = ({ properties, setProperties, activeColumns, inactiveColumns }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  const onStateChange = (val: boolean, propertyId: string) => {
    const property = properties.find(el => el.id === propertyId)
    if (property) {
      property.activeInTable = val
      setProperties([
        ...properties.slice().sort((a, b) => (a.activeInTable === b.activeInTable ? 0 : b.activeInTable ? 1 : -1)),
      ])
    }
  }

  return (
    <styled.TheadController ref={ref}>
      <Icon name={Name.REPOSITORY_BACKEND} onClick={() => setOpen(open => !open)} />
      {open && (
        <Box position="absolute" right="0px" top="16px" zIndex="1" width="250px">
          <CustomPicker
            title="Columns"
            close={() => setOpen(false)}
            optionsContainer={
              <Box maxHeight="500px" overflow="auto">
                <Movable properties={properties} setProperties={setProperties} onStateChange={onStateChange} />
                {!!inactiveColumns.length && (
                  <styled.Items borderTop={!!activeColumns.length}>
                    {inactiveColumns.map(el => (
                      <SettingField
                        key={el.id}
                        transparent
                        value={firstLetterUpperCase(el.name)}
                        actions={
                          <Switch checked={!!el.activeInTable} onChange={value => onStateChange(value, el.id)} />
                        }
                      />
                    ))}
                  </styled.Items>
                )}
              </Box>
            }
          />
        </Box>
      )}
    </styled.TheadController>
  )
}
