import React from 'react'
import { ISvg, Svg } from '../Svg'

const Counter: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M6.3075 16.0538H9.7476" stroke="#7F899E" strokeWidth="1.1467" strokeLinecap="round" />
    <path d="M22.3608 16.0538H25.8009" stroke="#7F899E" strokeWidth="1.1467" strokeLinecap="round" />
    <path d="M24.0809 17.7739L24.0809 14.3338" stroke="#7F899E" strokeWidth="1.1467" strokeLinecap="round" />
    <path
      d="M15.7634 19.6402C17.3558 19.6402 18.4846 18.7634 18.4846 17.4734C18.4846 16.4605 17.7741 15.8155 16.7007 15.7147V15.6895C17.5775 15.5081 18.2276 14.8983 18.2276 13.9862C18.2276 12.8625 17.2601 12.0764 15.7533 12.0764C14.5691 12.0764 13.7629 12.6055 13.4202 13.3261C13.3295 13.5025 13.2942 13.6637 13.2942 13.8451C13.2942 14.1777 13.4857 14.3894 13.8485 14.3894C14.1509 14.3894 14.292 14.2735 14.4331 13.9611C14.675 13.3916 15.0731 13.1094 15.7584 13.1094C16.5647 13.1094 16.9779 13.5327 16.9779 14.1626C16.9779 14.8076 16.4437 15.2662 15.6425 15.2662H15.2595C14.937 15.2662 14.7455 15.4627 14.7455 15.75C14.7455 16.0372 14.937 16.2337 15.2595 16.2337H15.6727C16.605 16.2337 17.1694 16.6671 17.1643 17.4431C17.1643 18.1033 16.605 18.577 15.7785 18.577C14.9823 18.577 14.5439 18.2494 14.292 17.7001C14.1559 17.4331 13.9947 17.3172 13.7276 17.3172C13.3648 17.3172 13.1481 17.5339 13.1481 17.8765C13.1481 18.0479 13.1884 18.2141 13.2841 18.3905C13.6621 19.1212 14.4885 19.6402 15.7634 19.6402Z"
      fill="#7F899E"
    />
    <rect x="2.25" y="9.25" width="27.5" height="13.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Counter
