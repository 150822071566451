import React from 'react'
import { ISvg, Svg } from '../Svg'

const Map: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.94066 14.6287C9.59394 13.8008 10.7191 12.3239 11.6834 10.8001C12.1652 10.0387 12.6132 9.25564 12.9423 8.52949C13.2653 7.81707 13.5 7.10404 13.5 6.5C13.5 3.69033 10.9867 1.5 8 1.5C5.01329 1.5 2.5 3.69033 2.5 6.5C2.5 7.10404 2.73473 7.81707 3.05766 8.5295C3.38681 9.25564 3.8348 10.0387 4.31664 10.8001C5.28089 12.3239 6.40607 13.8008 7.05934 14.6287C7.54226 15.2407 8.45774 15.2407 8.94066 14.6287Z"
      stroke="#7F899E"
    />
    <circle cx="8" cy="7" r="2.5" stroke="#7F899E" />
  </Svg>
)

export default Map
