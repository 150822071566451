import styled from 'styled-components'

export const Modal = styled.div<{ active: boolean; width?: string; hasLeftPanel?: boolean }>`
  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);
  height: ${props => props.hasLeftPanel && '850px'};
  z-index: 3;
  width: ${props => (props.hasLeftPanel ? 'auto' : props.width || '490px')};
  border-radius: ${props => !props.hasLeftPanel && '16px'};
  background-color: ${props => !props.hasLeftPanel && props.theme.colors.modalBg};
  padding: ${props => !props.hasLeftPanel && '20px 25px 30px'};
  overflow: hidden;
  transform: ${props => (props.active ? 'scale(1)' : 'scale(0)')};
  transition: 0.4s all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & label {
    & > span {
      cursor: default;
      font-weight: ${props => props.theme.weights.normal};
    }
    & > input {
      background-color: ${props => props.theme.colors.settingField};
      padding: 9px 12px;
      border: none;
    }
  }
  @media (max-width: 960px) {
    height: ${props => props.hasLeftPanel && '690px'};
  }
  @media (max-width: 767px) {
    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;
  }
`

export const Overlay = styled.div<{ active: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.overlayBg};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.active ? '1' : '0')};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: background-color 0.4s ease, opacity 0.4s ease;
`

export const InputWrapper = styled.div<{ inputEmpty?: boolean; hasIcon?: boolean }>`
  position: relative;
  margin-top: 8px;
  & input,
  & select {
    font-family: ${props => props.theme.fonts.primary};
    background-color: ${props => props.theme.colors.settingField} !important;
    color: ${props => props.theme.colors.darkText}!important;
    width: 100% !important;
    height: 40px !important;
    border: 0 !important;
    outline: 0 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    padding: ${props => (props.hasIcon ? '8px 28px 8px 12px' : '8px 12px')};
    transition: 0.4s background-color ease !important;
    font-size: 14px !important;
    line-height: 150% !important;
    &:hover {
      background-color: ${props => props.theme.colors.settingFieldHover} !important;
    }
    &:focus {
      background-color: ${props =>
        props.inputEmpty ? props.theme.colors.settingFieldHover : props.theme.colors.settingField} !important;
    }
    &:disabled {
      color: ${props => props.theme.colors.disabledTextColor};
      background-color: ${props => props.theme.colors.disabledBgColor}80;
      border: 1px solid ${props => props.theme.colors.borderColor} !important;
    }
    &::placeholder {
      color: ${props => props.theme.colors.grey1};
    }
    & + div {
      background: transparent !important;
      border: 0 !important;
      border-radius: 8px !important;
      & > div {
        border-radius: 8px !important;
      }
      &:hover > div {
        background: ${props => props.theme.colors.settingFieldHover} !important;
      }
    }
  }
  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-size: 20px;
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }
  }
  & ul {
    top: -108px !important;
  }
  & > svg {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
`

export const CloseIconBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  & svg {
    & circle,
    & path {
      fill-opacity: 1;
      transition: 300ms ease-out;
    }
    & circle {
      fill: ${props => props.theme.colors.settingField};
    }
  }
  &:hover svg {
    & circle {
      fill: ${props => props.theme.colors.settingFieldHover};
    }
    & path {
      stroke: ${props => props.theme.colors.darkText};
    }
  }
`

export const PickerWrapper = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

export const PickerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.overlayBg};
`

export const WithLeftPanelWrapper = styled.div`
  display: flex;
  gap: 15px;
  height: 100%;
  overflow: hidden;
  @media (max-width: 1100px) {
    gap: 5px;
  }
`
