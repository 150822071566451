import { Box } from '@mui/material'
import React, { useState } from 'react'

import * as styled from '../../Workspaces.styled'

import { Icon, Name } from 'components'
import { AddWorkspace } from './AddWorkspace'

export const NewWorkspace: React.FC = () => {
  const [active, setActive] = useState(false)

  return (
    <>
      <styled.ProjectNavigation>
        <styled.ProjectLinkBox isNewWorkspace>
          <Box onClick={() => setActive(true)}>
            <Icon name={Name.LEFT_SIDEBAR_PROJECT_NEW_WORKSPACE} /> New workspace
          </Box>
        </styled.ProjectLinkBox>
      </styled.ProjectNavigation>
      <AddWorkspace active={active} close={() => setActive(false)} />
    </>
  )
}
