import React from 'react'

import * as styled from './Suggestions.styled'

import { MessageBeforeSend } from 'services/conversations'

interface Props {
  suggestions: string[]
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
}

export const Suggestions: React.FC<Props> = ({ suggestions, sendMessage }) => (
  <styled.Suggestions scrollX>
    {suggestions.map(el => (
      <span key={el} onClick={() => sendMessage({ body: el, format: 'text' })}>
        {el}
      </span>
    ))}
  </styled.Suggestions>
)
