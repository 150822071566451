import styled from 'styled-components'

export const Container = styled.div<{ wide?: boolean }>`
  padding: ${props => (props.wide ? '0 20px' : '0 20px 300px')};
  width: ${props => (props.wide ? 1066 : 750)}px;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.modalBg};
  display: flex;
  flex-direction: column;
  cursor: auto;
`

export const TitleWrapper = styled.div`
  z-index: 5;
  position: sticky;
  height: 60px;
  top: 0;
  background-color: ${props => props.theme.colors.modalBg};
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
