import { Autocomplete, TextField } from '@mui/material'
import { memo, useContext } from 'react'

import { AutocompleteWrapper, CustomPaper, ListOption } from '../../FieldTypes.styled'
import * as styled from './FolderInput.styled'

import { Icon, Name } from 'components'
import { CMSContext } from 'context'
import { rootFolderName } from 'services/cms'
import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const FolderInput = memo((props: Props) => {
  const { folders } = useContext(CMSContext)

  return !!folders.length ? (
    <AutocompleteWrapper folder>
      <styled.IconWrapper absolute>
        <Icon width={24} height={24} name={Name.ADDITIONAL_HOME_FOLDER} />
      </styled.IconWrapper>
      <Autocomplete
        size="small"
        fullWidth
        options={folders}
        value={folders.find(el => el.name === props.value)}
        onChange={(e, option) => props.onChange(option?.name || rootFolderName)}
        renderInput={params => <TextField {...params} />}
        getOptionLabel={option => option.name.split('/').join(' / ')}
        PaperComponent={CustomPaper}
        renderOption={(props, option) => (
          <ListOption {...props}>
            <styled.IconWrapper>
              <Icon width={24} height={24} name={Name.ADDITIONAL_HOME_FOLDER} />
            </styled.IconWrapper>
            {option.name.split('/').join(' / ')}
          </ListOption>
        )}
      />
    </AutocompleteWrapper>
  ) : (
    <></>
  )
})
