import styled from 'styled-components'

export const Row = styled.tr`
  color: ${props => props.theme.colors.grey1};
  font-size: 14px;
  line-height: 150%;
  &:not(:last-child) {
    & > td {
      border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    }
  }
  & > td {
    padding: 15px 5px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    & > b {
      padding: 1px 8px;
      border-radius: 100px;
      background-color: ${props => props.theme.colors.settingField};
    }
    & > a {
      display: flex;
      align-items: center;
      & svg path {
        transition: 300ms ease-out;
        stroke: ${props => props.theme.colors.grey1};
      }
      &:hover svg path {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`
