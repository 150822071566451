import React from 'react'

import { CommonPicker, Icon, Name } from 'components'
import { RightSidebarItem, SettingField, SettingNumberField, SubWrapper } from 'partials/RightSidebar/components'
import { ComponentsDirection, LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Common, Id } from '../common'

const componentsDirectionValues = Object.values(ComponentsDirection).map(el => ({ value: el, label: el }))

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const StackSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { componentType, name, componentsDirection, componentsSpacing } = component

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <SettingField
          subLabel="Axis"
          value={componentsDirection}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Components directions"
              value={componentsDirection}
              onChange={value => onComponentPropertiesChange([{ propName: 'componentsDirection', value }])}
              values={componentsDirectionValues}
              close={() => {}}
            />
          }
        />
        <SettingNumberField
          subLabel="Spacing"
          value={componentsSpacing?.constant}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'componentsSpacing', value: { constant: value } }])
          }
          source={componentsSpacing?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'componentsSpacing', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
