import React, { useContext, useEffect } from 'react'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { TopPanelContext } from 'context'
import { IBackground, IDevice, IImage, IText, Mode } from 'pages/ScreenshotsStudio/utils'
import { AddMedia } from './components'

export const downloadEvent = 'downloadEvent'
export const promoteEvent = 'promoteEvent'

interface Props {
  mode: Mode
  setMode: (mode: Mode) => void
  addToState: (state: any[]) => void
  activeElement: IBackground | IImage | IDevice | IText | null
  getLastScreen: () => { lastScreenX: number; lastScreenConfig: any[] }
}

export const TopPanelScreenshotsStudio: React.FC<Props> = ({
  mode,
  setMode,
  addToState,
  activeElement,
  getLastScreen,
}) => {
  const { setPageLeftButtons, setRightButtons } = useContext(TopPanelContext)

  const download = () => document.dispatchEvent(new Event(downloadEvent))

  const promote = () => document.dispatchEvent(new Event(promoteEvent))

  useEffect(() => {
    setPageLeftButtons(
      <>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_POINTER} />}
          active={mode === Mode.POINTER}
          onClick={() => setMode(Mode.POINTER)}
        >
          Select
        </Button>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_TEXT} />}
          active={mode === Mode.ADD_TEXT}
          onClick={() => setMode(Mode.ADD_TEXT)}
        >
          Text
        </Button>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_ADD} />}
          active={mode === Mode.ADD_MEDIA}
          onClick={() => setMode(Mode.ADD_MEDIA)}
        >
          Add
        </Button>
        {mode === Mode.ADD_MEDIA && (
          <AddMedia
            addToState={addToState}
            activeElement={activeElement}
            close={() => setMode(Mode.POINTER)}
            getLastScreen={getLastScreen}
          />
        )}
      </>
    )
    return () => setPageLeftButtons(null)
  }, [mode])

  useEffect(() => {
    setRightButtons(
      <>
        <Button color={ButtonColor.SECONDARY} onClick={download}>
          Download
        </Button>
        <Button onClick={promote}>Promote</Button>
      </>
    )
    return () => setRightButtons(null)
  }, [])

  return <></>
}
