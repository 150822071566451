import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const BackendAsAService: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.57125 9.92154C1.80958 11.2077 1.80958 12.7923 2.57125 14.0785L5.43932 18.9215C6.20099 20.2077 7.6086 21 9.13192 21H14.8681C16.3914 21 17.799 20.2077 18.5607 18.9215L21.4288 14.0785C22.1904 12.7923 22.1904 11.2077 21.4288 9.92154L18.5607 5.07846C17.799 3.7923 16.3914 3 14.8681 3L9.13192 3C7.6086 3 6.20099 3.79231 5.43932 5.07846L2.57125 9.92154ZM12 15.6C14.0083 15.6 15.6364 13.9882 15.6364 12C15.6364 10.0118 14.0083 8.4 12 8.4C9.99169 8.4 8.36364 10.0118 8.36364 12C8.36364 13.9882 9.99169 15.6 12 15.6Z"
      fill="#476CEC"
    />
  </Svg>
)
