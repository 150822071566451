import { Box } from '@mui/material'
import React from 'react'

import { CustomPicker, Filters, IPickerItem } from 'components'
import { VariableSource } from 'utils'

interface Props {
  close: () => void
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  currentVariableCollectionFieldsValues: IPickerItem[]
}

export const FiltersPicker: React.FC<Props> = ({
  close,
  currentVariable,
  setCurrentVariable,
  currentVariableCollectionFieldsValues,
}) => (
  <CustomPicker
    title="Filters"
    close={close}
    optionsContainer={
      <Box maxHeight="500px" minHeight="349px" overflow="auto">
        <Filters
          currentVariable={currentVariable}
          setCurrentVariable={setCurrentVariable}
          currentVariableCollectionFieldsValues={currentVariableCollectionFieldsValues}
        />
      </Box>
    }
  />
)
