import throttle from 'lodash.throttle'
import { memo, useEffect, useRef, useState } from 'react'
import { usePicker } from '../context'
import { usePaintSquare } from '../hooks/usePaintSquare'
import { cCross, handle, psRl } from '../style'

export const Square = memo(() => {
  const { handleColor, x, y, internalHue, squareSize, squareHeight } = usePicker()
  const [dragging, setDragging] = useState(false)
  const canvas = useRef(null)
  usePaintSquare(canvas, internalHue, squareSize, squareHeight)

  const handleChange = e => {
    const ctx = canvas?.current?.getContext('2d')
    const onMouseMove = throttle(() => handleColor(e, ctx), 250)
    onMouseMove()
  }

  const stopDragging = () => {
    setDragging(false)
  }

  const handleMove = e => {
    if (dragging) {
      handleChange(e)
    }
  }

  const onMouseDown = e => {
    setDragging(true)
    handleChange(e)
  }

  useEffect(() => {
    if (dragging) {
      document.body.onmouseup = stopDragging
      return () => {
        document.body.onmouseup = () => {}
      }
    }
  }, [dragging])

  return (
    <div style={psRl}>
      <div style={{ ...psRl, ...cCross }} onMouseDown={onMouseDown} onMouseMove={handleMove}>
        <div style={{ left: x, top: y, ...handle }} />
        <canvas style={{ borderRadius: '6px' }} ref={canvas} width={`${squareSize}px`} height={`${squareHeight}px`} />
      </div>
    </div>
  )
})
