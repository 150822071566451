import { Box } from '@mui/material'
import { marked } from 'marked'
import React, { ReactElement } from 'react'

import * as styled from './ChatMessage.styled'

import { Icon, Name } from 'components'
import { Message } from 'services/conversations'

const getText = (message: Message) => {
  const attachmentStyle = 'width: 32px; height: 32px; border-radius: 6px; margin: -5px 10px -5px -5px; float: left;'
  let text = message.body || ''
  if (message.status === 'generating') {
    text += '⚫'
  }
  if (message.format === 'markdown') {
    text = marked(text, { async: false }) as string
  }
  if (message.iconAttachment) {
    text = `<img src="${message.iconAttachment.url}" alt="icon" style="${attachmentStyle}" />` + text
  }
  if (message.colorAttachment) {
    text = `<div style="background: ${message.colorAttachment.color}; ${attachmentStyle}"></div>` + text
  }
  return text
}

interface ChatMessageProps {
  message: Message
  widgetElement?: ReactElement
  buttons?: ReactElement
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ message, widgetElement, buttons }) => (
  <Box display="flex" flexDirection="column" gap="10px">
    <styled.ChatMessage>
      {message.authorId === 'ai-agent' ? (
        <img src="/assets/chat-codeplatform.svg" alt="AI App Builder" />
      ) : (
        <img src="/assets/emoji/1.png" alt="User" />
      )}
      <styled.ChatMessageText dangerouslySetInnerHTML={{ __html: getText(message) }} />
    </styled.ChatMessage>
    {widgetElement}
    {buttons}
  </Box>
)

interface UserMessageProps {
  message: Message
  editMode: boolean
  setEditMode?: () => void
}

export const UserMessage: React.FC<UserMessageProps> = ({ message, editMode, setEditMode }) => (
  <styled.UserMessage>
    {!editMode && setEditMode && <Icon name={Name.REPOSITORY_EDIT} onClick={setEditMode} />}
    <styled.UserMessageText
      dangerouslySetInnerHTML={{ __html: getText(message) }}
      pending={message.status === 'pending'}
      error={message.status === 'error' || message.status === 'failed'}
    />
  </styled.UserMessage>
)
