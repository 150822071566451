import { Box } from '@mui/material'

import { ContentBox, Title, UnderTitle } from './NotFound.styled'

import { Icon, Name } from 'components'
import { LandingLayout } from 'partials/LandingLayout'
import { BlurBox } from 'partials/LandingLayout/components/BlurBox'
import { Button } from '../../components/Button'
import Container from '../../components/Container/Container'

export const NotFound = () => (
  <LandingLayout>
    <ContentBox maxHeight={780} maxHeightMobileLg={690} maxHeightMobileMd={630}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <BlurBox $hasWrapper>
            <Icon name={Name.ADDITIONAL_NOT_FOUND} width={226} height={149} />
            <Title>404 ERROR.</Title>
            <UnderTitle>We can't find the page that you're looking for.</UnderTitle>
            <a href="/">
              <Button>Back to home</Button>
            </a>
          </BlurBox>
        </Box>
      </Container>
    </ContentBox>
  </LandingLayout>
)
