import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { DeploymentStatus, Links, SettingsLayout } from '../components'
import { Plugins, Providers } from './sections'

export const Genkit: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Genkit</Text>
    <Text type={Type.SUB_TITLE}>Add AI-powered features to your app using the Genkit framework.</Text>
    <Links />
    <CustomSectionsWrapper>
      <DeploymentStatus description="Deploy your changes to update your application with the latest configurations." />
      <Providers />
      <Plugins />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
