import React from 'react'
import { ISvg, Svg } from '../Svg'

const Close: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_17261_43026)">
      <circle cx="12" cy="12" r="12" fill="#9A9A9A" fillOpacity="0.15" />
      <path d="M15 9L9 15" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
      <path d="M9 9L15 15" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_17261_43026">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default Close
