import React from 'react'
import { ISvg, Svg } from '../Svg'

const Slider: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M1 8L3.91667 8" stroke="#7F899E" strokeLinecap="round" />
    <path d="M12.0834 8L15 8" stroke="#7F899E" strokeLinecap="round" />
    <circle cx="7.99996" cy="8" r="2.33333" fill="#7F899E" />
  </Svg>
)

export default Slider
