import React from 'react'
import { ISvg, Svg } from '../Svg'

const Search: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="6.5" cy="6.5" r="4.75" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M14 14L12 12" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Search
