import { argbFromHex, CorePalette, hexFromArgb } from '@material/material-color-utilities'
import { BrandingColorStyles, BrandingColorType } from './configTypes'

export type ColorVariant = 'color' | 'onColor' | 'colorContainer' | 'onColorContainer'

export const getColorVariantName = (colorType: BrandingColorType, variant: ColorVariant) =>
  variant === 'onColor'
    ? (('on' + firstLetterUpperCase(colorType)) as BrandingColorType)
    : variant === 'colorContainer'
    ? ((colorType + 'Container') as BrandingColorType)
    : variant === 'onColorContainer'
    ? (('on' + firstLetterUpperCase(colorType) + 'Container') as BrandingColorType)
    : colorType

export const getCustomColors = (source: string, colorType: BrandingColorType): BrandingColorStyles => {
  try {
    const core = CorePalette.of(argbFromHex(source))
    return (
      colorType === BrandingColorType.neutral
        ? {
            [BrandingColorType.neutral]: {
              light: source,
              dark: source,
            },
            [BrandingColorType.background]: {
              light: hexFromArgb(core.n1.tone(99)),
              dark: hexFromArgb(core.n1.tone(10)),
            },
            [BrandingColorType.onBackground]: {
              light: hexFromArgb(core.n1.tone(10)),
              dark: hexFromArgb(core.n1.tone(90)),
            },
            [BrandingColorType.surface]: {
              light: hexFromArgb(core.n1.tone(99)),
              dark: hexFromArgb(core.n1.tone(10)),
            },
            [BrandingColorType.onSurface]: {
              light: hexFromArgb(core.n1.tone(10)),
              dark: hexFromArgb(core.n1.tone(90)),
            },
            [BrandingColorType.surfaceVariant]: {
              light: hexFromArgb(core.n2.tone(90)),
              dark: hexFromArgb(core.n2.tone(30)),
            },
            [BrandingColorType.onSurfaceVariant]: {
              light: hexFromArgb(core.n2.tone(30)),
              dark: hexFromArgb(core.n2.tone(80)),
            },
            [BrandingColorType.outline]: {
              light: hexFromArgb(core.n2.tone(50)),
              dark: hexFromArgb(core.n2.tone(60)),
            },
            [BrandingColorType.shadow]: {
              light: hexFromArgb(core.n1.tone(0)),
              dark: hexFromArgb(core.n1.tone(0)),
            },
            [BrandingColorType.inverseSurface]: {
              light: hexFromArgb(core.n1.tone(20)),
              dark: hexFromArgb(core.n1.tone(90)),
            },
            [BrandingColorType.inverseOnSurface]: {
              light: hexFromArgb(core.n1.tone(95)),
              dark: hexFromArgb(core.n1.tone(20)),
            },
          }
        : {
            [colorType]: {
              light: source,
              dark: hexFromArgb(core.a1.tone(80)),
            },
            [getColorVariantName(colorType, 'onColor')]: {
              light: hexFromArgb(core.a1.tone(100)),
              dark: hexFromArgb(core.a1.tone(20)),
            },
            [getColorVariantName(colorType, 'colorContainer')]: {
              light: hexFromArgb(core.a1.tone(90)),
              dark: hexFromArgb(core.a1.tone(30)),
            },
            [getColorVariantName(colorType, 'onColorContainer')]: {
              light: hexFromArgb(core.a1.tone(10)),
              dark: hexFromArgb(core.a1.tone(90)),
            },
          }
    ) as BrandingColorStyles
  } catch {
    return {} as BrandingColorStyles
  }
}

export const firstLetterUpperCase = (str: string) => str[0]?.toUpperCase() + str.slice(1)

export const convertSvgStringToImage = (svg: string) =>
  'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)))

export const urlToBase64 = async (url: string, callback: (res: string) => void) =>
  new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(callback(String(reader.result)))
      reader.readAsDataURL(xhr.response)
    }
    xhr.onerror = () => resolve(callback(''))
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  })

export const moveElement = (element: HTMLElement | null) => {
  if (element) {
    document.body.style.userSelect = 'none'

    let [movementX, movementY] = element.style.transform
      ? element.style.transform
          .replace('translate(', '')
          .replace(')', '')
          .replaceAll('px', '')
          .replaceAll(' ', '')
          .split(',')
          .map(el => +el)
      : [0, 0]

    document.onmousemove = e => {
      movementY += e.movementY || 0
      movementX += e.movementX || 0
      element.style.transform = `translate(${movementX}px, ${movementY}px)`
    }
    document.onmouseup = () => {
      document.onmousemove = () => {}
      document.body.style.removeProperty('user-select')
    }
  }
}

export const includesSearch = (el: string, search: string) => el.toLowerCase().includes(search.toLowerCase())

export const moveTopBottomInList = (
  node: HTMLElement,
  marginBottom: number,
  item: any,
  listItems: any[],
  setListItems: (val: any[]) => void,
  set: (val: any[]) => void,
  allowedToMoveItems?: any[],
  skip?: number
) => {
  let movementY = 0
  let movementX = 0
  document.onmousemove = (e: MouseEvent) => {
    document.body.style.userSelect = 'none'
    movementY += e.movementY
    movementX += e.movementX
    node.style.transform = `translate(${movementX}px, ${movementY}px)`
    const replace = (indexOfNeighbor: number) => {
      const neighbor = JSON.parse(JSON.stringify(listItems[indexOfNeighbor]))
      listItems[indexOfNeighbor] = item
      listItems[index] = neighbor
      const editedItems = [...listItems]
      setListItems(editedItems)
      movementY = movementY < 0 ? movementY + (height + marginBottom) : movementY - (height + marginBottom)
      node.style.transform = `translate(${movementX}px, ${movementY}px)`
    }
    const index = listItems.indexOf(item)
    const height = node.getBoundingClientRect().height
    if (movementY > height + marginBottom && index < (allowedToMoveItems || listItems).length - 1) {
      replace(index + 1)
    } else if (movementY < -(height + marginBottom) && index > (skip || 0)) {
      replace(index - 1)
    }
  }
  document.onmouseup = () => {
    set([...listItems])
    document.body.style.removeProperty('user-select')
    document.onmousemove = () => {}
    document.onmouseup = () => {}
    node.style.removeProperty('transform')
  }
}

export const isUrl = (str: string) =>
  str.match(
    //eslint-disable-next-line
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  )

export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false
  }
  const d = new Date(str)
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str
}

export const getReadableTimeFromSeconds = (allSeconds: number) => {
  const minutes = Math.floor(allSeconds / 60)
  const seconds = allSeconds - minutes * 60
  return minutes && seconds ? `${minutes}m ${seconds}s` : minutes ? `${minutes}m` : `${seconds}s`
}

export const getMoney = (
  currency: string,
  amount: number,
  interval?: string,
  percentOff?: number,
  amountOf?: number,
  amountOfCurrency?: string
): string =>
  currency
    ? `${currency === 'eur' ? '€' : currency === 'usd' ? '$' : currency}${(
        (percentOff ? (amount / 100) * percentOff : amountOf ? amount - amountOf : amount) / 100
      ).toFixed(2)}${interval ? `/${interval}` : ''}${percentOff ? ` (${percentOff}% off)` : ''}${
        amountOfCurrency && amountOf ? ` (${getMoney(amountOfCurrency, amountOf)} off)` : ''
      }`
    : '-'

export const detectEmptyValue = (value: any) =>
  value === undefined ||
  value === '' ||
  (value && typeof value === 'object'
    ? Object.keys(value).filter(el => value[el] !== undefined && value[el] !== '').length === 0
    : false) ||
  (value.constant && typeof value.constant === 'object'
    ? Object.keys(value.constant).filter(el => value.constant[el] !== undefined && value.constant[el] !== '').length ===
      0
    : false)

export const onContentEditablePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
  e.preventDefault()
  document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
}
