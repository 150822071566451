import React from 'react'
import { ISvg, Svg } from '../Svg'

const Counter: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M2.78101 8.25977H4.63337" stroke="#7F899E" strokeLinecap="round" />
    <path d="M11.425 8.25977H13.2774" stroke="#7F899E" strokeLinecap="round" />
    <path d="M12.3513 9.18594L12.3513 7.33358" stroke="#7F899E" strokeLinecap="round" />
    <rect x="0.5" y="3.5" width="15" height="9" rx="1.03846" stroke="#7F899E" />
    <path
      d="M7.92851 11C9.21197 11 10 10.3106 10 9.22324C10 8.46684 9.5478 7.99015 8.81629 7.89954V7.86802C9.34497 7.74196 9.8005 7.28496 9.8005 6.54826C9.8005 5.53578 8.98919 5 7.92186 5C6.89111 5 6.3059 5.60276 6.13633 6.15036C6.10308 6.26067 6.08978 6.34734 6.08978 6.45765C6.08978 6.82797 6.2793 7.09586 6.665 7.09586C6.97091 7.09586 7.14381 6.97374 7.30341 6.63887C7.43973 6.35522 7.64256 6.21733 7.92851 6.21733C8.28429 6.21733 8.50374 6.43795 8.50374 6.78858C8.50374 7.15496 8.25104 7.37951 7.81546 7.37951H7.78221C7.45303 7.37951 7.28678 7.60801 7.28678 7.92712C7.28678 8.26198 7.45303 8.47866 7.78221 8.47866H7.81546C8.30424 8.47866 8.56692 8.69534 8.56692 9.10112C8.56692 9.45174 8.31421 9.70387 7.96176 9.70387C7.54281 9.70387 7.36658 9.51871 7.18703 9.20749C7.04738 8.96717 6.87448 8.86474 6.6118 8.86474C6.23275 8.86474 6 9.12081 6 9.53447C6 9.62114 6.0133 9.73145 6.04323 9.82994C6.20615 10.3697 6.81131 11 7.92851 11Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Counter
