import React from 'react'
import { ISvg, Svg } from '../Svg'

const Image: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M7 21L10.7407 17.675C11.1741 17.2897 11.8269 17.2887 12.2615 17.6725L13.5725 18.8303C14.0392 19.2424 14.7496 19.2068 15.1727 18.7501L19.5881 13.9843C20.0669 13.4674 20.8942 13.5006 21.3302 14.0542L25 18.7143"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="11.8668" cy="11.8668" r="2.11675" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="6.75" y="6.75" width="18.5" height="18.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Image
