import React from 'react'
import { ISvg, Svg } from '../Svg'

const Close: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_17442_43457)">
      <path d="M16 8L8 16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 8L16 16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_17442_43457">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default Close
