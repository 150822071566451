import React, { ReactElement, useMemo, useState } from 'react'

import * as styled from '../CustomPicker.styled'

import { CustomPicker, Icon, Name, Switch } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { ValueType, includesSearch } from 'utils'

export interface IPickerItem {
  value: string
  label: string
  labelRight?: string | ReactElement
  iconName?: Name
  type?: ValueType
  accept?: ValueType
  bigIcon?: ReactElement
  description?: ReactElement
  itemsCategory?: string
}

interface Props {
  title?: string
  value?: any
  onChange: (val: any) => void
  values: IPickerItem[]
  close: () => void
  noSearchMode?: boolean
  multiple?: boolean
  noCloseAfterChange?: boolean
  height?: string
}

export const CommonPicker: React.FC<Props> = ({
  title,
  value,
  onChange,
  close,
  values,
  noSearchMode,
  multiple,
  noCloseAfterChange,
  height,
}) => {
  const [search, setSearch] = useState('')

  return (
    <CustomPicker
      title={title}
      close={close}
      setSearch={noSearchMode ? undefined : setSearch}
      optionsContainer={
        <OptionsContainer
          search={search}
          value={value}
          onChange={onChange}
          close={close}
          values={values}
          noSearchMode={noSearchMode}
          multiple={multiple}
          noCloseAfterChange={noCloseAfterChange}
          height={height}
        />
      }
    />
  )
}

interface OptionsContainerProps {
  search: string
  value?: any
  onChange: (val: any) => void
  values: IPickerItem[]
  close: () => void
  noSearchMode?: boolean
  multiple?: boolean
  noCloseAfterChange?: boolean
  height?: string
}

export const OptionsContainer: React.FC<OptionsContainerProps> = ({
  search,
  value,
  onChange,
  close,
  values,
  noSearchMode,
  multiple,
  noCloseAfterChange,
  height,
}) => {
  const items = useMemo(() => {
    const filteredValues = values.filter(el => includesSearch(el.label, search))
    const categories = filteredValues
      .filter((a, i, arr) => arr.findIndex(b => a.itemsCategory === b.itemsCategory) === i)
      .map(el => el.itemsCategory)
    return categories.map(a => ({ itemsCategory: a, values: filteredValues.filter(b => a === b.itemsCategory) }))
  }, [values, search])

  const onClick = (val: string) => {
    onChange(val)
    if (!noCloseAfterChange) {
      close()
    }
  }

  return (
    <styled.Items noSearchMode={!!noSearchMode} multiple={!!multiple} height={height}>
      {items.map(el => (
        <React.Fragment key={el.itemsCategory || 'noItemsCategory'}>
          {el.itemsCategory && <styled.ItemsCategory>{el.itemsCategory}</styled.ItemsCategory>}
          {el.values.map(el =>
            multiple ? (
              <SettingField
                key={el.value}
                value={el.label}
                transparent
                actions={
                  <Switch
                    checked={!!value?.includes(el.value)}
                    onChange={val =>
                      val
                        ? onChange(value ? [...value, el.value] : [el.value])
                        : onChange(value.filter((a: string) => a !== el.value))
                    }
                  />
                }
              />
            ) : (
              <styled.Item
                key={el.value}
                onClick={() => onClick(el.value)}
                active={el.value === value}
                hasBigIcon={!!el.bigIcon}
                color="darkText1"
              >
                {el.bigIcon && <styled.ItemBigIcon>{el.bigIcon}</styled.ItemBigIcon>}
                {el.iconName && <Icon name={el.iconName} />}
                {el.description ? (
                  <styled.ItemLabelWithDescription>
                    <styled.ItemLabel>{el.label}</styled.ItemLabel>
                    <styled.ItemDescription>{el.description}</styled.ItemDescription>
                  </styled.ItemLabelWithDescription>
                ) : (
                  <styled.ItemLabel>{el.label}</styled.ItemLabel>
                )}
                {el.labelRight && (
                  <styled.ItemLabel right color="grey4">
                    {el.labelRight}
                  </styled.ItemLabel>
                )}
              </styled.Item>
            )
          )}
        </React.Fragment>
      ))}
    </styled.Items>
  )
}
