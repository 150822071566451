import { useContext, useEffect, useState } from 'react'

import * as styled from 'components/Menu/Menu.styled'

import { NavHeadSearch } from 'components'
import { DeviceSettingsContext, MovableComponentsContext } from 'context'
import { updateNavigationTreeEvent, useLastUpdate } from 'hooks'
import {
  ScreenComponent,
  getComponentNameById,
  includesSearch,
  listItemNameCopy,
  screenComponentClassName,
} from 'utils'
import { Chapter, NavNode } from './NavNode'

const getChildSvgNodes = (parent: HTMLElement, parentScreen: HTMLElement): Chapter[] =>
  Array.from(parent.querySelectorAll(`:scope > .${screenComponentClassName}:not([id*="${listItemNameCopy}"])`)).map(
    el => ({
      element: el,
      name: getComponentNameById(el.id),
      children: getChildSvgNodes(el as HTMLElement, parentScreen),
      parentScreen,
    })
  ) as Chapter[]

const getNavTree = () => {
  const getId = (str: string) => str.split(' ').join('-').toLowerCase().replaceAll('&', '')

  return Array.from(document.getElementsByTagName('h1')).map(a => {
    const aId = getId(a.innerText)
    if (a?.parentElement) {
      a.parentElement.id = aId
    }
    return {
      element: a,
      name: a.innerText,
      folder: true,
      children: Array.from(document.querySelectorAll(`#${aId} + div h2`) as NodeListOf<HTMLElement>).map(b => {
        const bId = `${aId}-${getId(b.innerText)}`
        if (b?.parentElement) {
          b.parentElement.id = bId
        }
        return {
          element: b,
          name: b.innerText,
          folder: true,
          children: Array.from(
            document.querySelectorAll(
              `#${bId} svg > foreignObject > div[id^="screen"], #${bId} h3`
            ) as NodeListOf<HTMLElement>
          ).map(c => {
            const screen = c.tagName === 'DIV'
            return {
              element: c,
              name: screen ? getComponentNameById(c.id) : c.innerText,
              screen: screen,
              children: screen ? getChildSvgNodes(c, c) : [],
            }
          }),
        }
      }),
    }
  })
}

const filter = (chapters: Chapter[], search: string): Chapter[] =>
  chapters.filter(el => {
    const includes = includesSearch(el.name, search)
    if (includes) {
      el.active = false
      return true
    } else if (el.children.length) {
      el.children = filter(el.children, search)
      if (el.children.length) {
        el.defaultOpen = true
        return true
      } else {
        return false
      }
    } else {
      el.active = false
      return false
    }
  })

const unsetActiveChildren = (children: Chapter[]) =>
  children.map(el => {
    el.active = false
    if (el.children.length) {
      unsetActiveChildren(el.children)
    }
    return el
  })

const setActiveElement = (
  chapters: Chapter[],
  activeElementId: string,
  movableComponent: ScreenComponent | null,
  screenId: string
) =>
  chapters.map(el => {
    const isActive =
      activeElementId &&
      el.element.id.endsWith(activeElementId) &&
      (!movableComponent && el.parentScreen && screenId ? el.parentScreen.id.endsWith(screenId) : true)
    if (isActive) {
      el.active = true
      el.defaultOpen = true
      if (el.children.length) {
        unsetActiveChildren(el.children)
      }
      return el
    } else if (el.children.length) {
      el.children = setActiveElement(el.children, activeElementId, movableComponent, screenId)
      if (el.children.filter(el => el.defaultOpen).length) {
        el.active = false
        el.defaultOpen = true
        return el
      } else {
        el.active = false
        return el
      }
    } else {
      el.active = false
      return el
    }
  })

export const NavigationTree = () => {
  const [chapters, setChapters] = useState<Chapter[]>([])
  const [search, setSearch] = useState('')
  const {
    selected: { tabScreen, tabBarId, topBarId, subComponentId, componentId, screenId },
  } = useContext(DeviceSettingsContext)
  const { movableComponent } = useContext(MovableComponentsContext)
  const activeElementId =
    movableComponent?.id || tabScreen || tabBarId || subComponentId || topBarId || componentId || screenId
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [timerForPasteInside, setTimerForPasteInside] = useState<NodeJS.Timeout | null>(null)

  useLastUpdate(updateNavigationTreeEvent, () => setLastUpdate(+new Date()))

  useEffect(() => {
    const timeout = setTimeout(
      () => setChapters(setActiveElement(getNavTree(), activeElementId, movableComponent, screenId)),
      0
    )
    return () => clearTimeout(timeout)
  }, [lastUpdate])

  useEffect(() => {
    if (chapters.length) {
      setChapters(setActiveElement(chapters, activeElementId, movableComponent, screenId))
    }
  }, [activeElementId, screenId])

  useEffect(() => {
    if (search) {
      setChapters(filter(getNavTree(), search))
    } else if (chapters.length) {
      setLastUpdate(+new Date())
    }
  }, [search])

  return (
    <>
      <NavHeadSearch title="Tree" search={search} setSearch={setSearch} />
      <styled.NavBody paddingBottom={100}>
        {chapters.map((el, i) => (
          <NavNode
            key={el.name + el.element.id + i}
            chapter={el}
            search={search}
            lvl={0}
            timer={timerForPasteInside}
            setTimer={setTimerForPasteInside}
          />
        ))}
      </styled.NavBody>
    </>
  )
}
