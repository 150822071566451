import React from 'react'

import { AppIconInput } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'

export const AppIconSettings: React.FC = () => {
  const sectionName = 'App icon'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon>
      <SubWrapper childrenFor={sectionName}>
        <AppIconInput subLabel="Asset" />
      </SubWrapper>
    </RightSidebarItem>
  )
}
