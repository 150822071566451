import React from 'react'
import { ISvg, Svg } from '../Svg'

const Info: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="6" fill="#B9BDC5" />
    <path
      d="M7.80225 9.19824C8.08789 9.19824 8.21973 9.01367 8.2417 8.73242C8.26367 8.30176 8.41309 8.07764 8.97998 7.74365C9.56006 7.39648 9.90283 6.93066 9.90283 6.24951C9.90283 5.27832 9.10742 4.60596 7.96045 4.60596C7.08154 4.60596 6.41357 4.9751 6.14551 5.58154C6.06641 5.75293 6.02686 5.90674 6.02686 6.0957C6.02686 6.36377 6.18066 6.52637 6.45312 6.52637C6.67285 6.52637 6.80908 6.42969 6.89697 6.15723C7.0376 5.67822 7.38916 5.41455 7.9209 5.41455C8.50537 5.41455 8.90527 5.77051 8.90527 6.28906C8.90527 6.71973 8.71631 6.9834 8.18457 7.3042C7.61328 7.64697 7.34082 8.02051 7.34082 8.58301V8.68408C7.34082 8.97412 7.49463 9.19824 7.80225 9.19824ZM7.80225 11.0659C8.13184 11.0659 8.38232 10.8198 8.38232 10.499C8.38232 10.1782 8.13184 9.93213 7.80225 9.93213C7.48145 9.93213 7.22656 10.1782 7.22656 10.499C7.22656 10.8198 7.48145 11.0659 7.80225 11.0659Z"
      fill="#F8F8F8"
    />
  </Svg>
)

export default Info
