import React from 'react'

import { LaunchScreenBackgroundInput, LaunchScreenIconInput } from 'components'
import { RightSidebarItem, SubWrapper } from 'partials/RightSidebar/components'
import { InnerLaunchScreenIcon, LocalVariable, Resource, Screen } from 'utils'
import { Actions, Id, InputParameters, LocalVariables } from '../common'

interface Props {
  onScreenPropertiesChange: (props: { propName: keyof Screen; value: any; resources?: Resource[] }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const LaunchScreen: React.FC<Props> = ({ onScreenPropertiesChange, screenConfig, allLocalVariables }) => {
  const { screenName } = screenConfig

  const sectionName = 'Screen'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon>
      <SubWrapper childrenFor={sectionName}>
        <Id value={screenName} />
        <LaunchScreenIconInput label="Splash icon" subLabel="Asset" iconName={InnerLaunchScreenIcon.splashIcon} />
        <LaunchScreenIconInput label="Brand logo" subLabel="Asset" iconName={InnerLaunchScreenIcon.brandLogo} />
        <LaunchScreenBackgroundInput label="Background" subLabel="Fill" />
      </SubWrapper>
      <InputParameters
        childrenFor={sectionName}
        onChange={value => onScreenPropertiesChange([{ propName: 'inputParameters', value }])}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <LocalVariables
        childrenFor={sectionName}
        onChange={value => onScreenPropertiesChange([{ propName: 'localVariables', value }])}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <Actions
        childrenFor={sectionName}
        onChange={value => onScreenPropertiesChange([{ propName: 'onScreenVisitActions', value }])}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
