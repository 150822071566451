import React from 'react'
import { ISvg, Svg } from '../Svg'

const SwitchProvider: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M19 8L11 8" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 12L11 12" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 16L11 16" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 8L5 8" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 12L5 12" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 16L5 16" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default SwitchProvider
