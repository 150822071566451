import React from 'react'
import { ISvg, Svg } from '../Svg'

const Pager: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="0.5" y="4.5" width="15" height="7" rx="3.5" stroke="#7F899E" />
    <circle cx="3.7333" cy="7.99999" r="1.6" fill="#7F899E" />
    <circle cx="12.2666" cy="7.99999" r="1.1" stroke="#7F899E" />
    <circle cx="8.00002" cy="7.99999" r="1.1" stroke="#7F899E" />
  </Svg>
)

export default Pager
