import React from 'react'
import { ISvg, Svg } from '../Svg'

const Pager: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="1.75" y="10.75" width="28.5" height="10.5" rx="5.25" stroke="#7F899E" strokeWidth="1.5" />
    <circle cx="8" cy="16" r="3" fill="#7F899E" />
    <circle cx="24" cy="16" r="2.25" stroke="#7F899E" strokeWidth="1.5" />
    <circle cx="16" cy="16" r="2.25" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Pager
