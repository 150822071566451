import React from 'react'
import { ISvg, Svg } from '../Svg'

const UploadImage: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9.32007 6.5L11.8801 3.94L14.4401 6.5"
      stroke="#212133"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8799 14.18V4.01"
      stroke="#212133"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12"
      stroke="#212133"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
export default UploadImage
