import React from 'react'
import { ISvg, Svg } from '../Svg'

const Column: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="10.5" y="14.5" width="5" height="3" rx="0.770764" transform="rotate(180 10.5 14.5)" stroke="#7F899E" />
    <rect x="10.5" y="9.5" width="5" height="3" rx="0.770764" transform="rotate(180 10.5 9.5)" stroke="#7F899E" />
    <rect x="10.5" y="4.5" width="5" height="3" rx="0.770764" transform="rotate(180 10.5 4.5)" stroke="#7F899E" />
    <path d="M2 15L2 0.999999" stroke="#7F899E" strokeLinecap="round" />
    <path d="M14 15L14 0.999999" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Column
