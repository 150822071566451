import styled from 'styled-components'

export const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 30px;
`

export const IconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;

  & svg {
    cursor: pointer;
    user-select: none;
    & path,
    & rect {
      transition: 300ms;
      &[fill] {
        fill: ${props => (props.active ? props.theme.colors.iconHover : props.theme.colors.grey1)};
      }
      &[stroke] {
        stroke: ${props => (props.active ? props.theme.colors.iconHover : props.theme.colors.grey1)};
      }
    }
    &:hover {
      & path,
      & rect {
        &[fill] {
          fill: ${props => props.theme.colors.iconHover};
        }
        &[stroke] {
          stroke: ${props => props.theme.colors.iconHover};
        }
      }
    }
  }
`
