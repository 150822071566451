import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'

export interface Integration {
  id: IntegrationId
  status: IntegrationStatus
  data?: IntegrationGithub | IntegrationAppstore | IntegrationGooglePlay | IntegrationStripe
  error?: string
  token?: string
  isHidden?: boolean
}

export enum IntegrationId {
  firebase = 'firebase',
  firestore = 'firestore',
  firebaseAuth = 'firebase-auth',
  firebaseStorage = 'firebase-storage',
  firebaseHosting = 'firebase-hosting',
  firebaseFunctions = 'firebase-functions',
  cms = 'cms',
  monetization = 'monetization',
  analytics = 'analytics',
  appetize = 'appetize',
  github = 'github',
  appstore = 'appstore',
  googleplay = 'googleplay',
  airtable = 'airtable',
  chatgpt = 'chatgpt',
  gemini = 'gemini',
  stripe = 'stripe',
  webhooks = 'webhooks',
  twilio = 'twilio',
  supabase = 'supabase',
}

export interface IntegrationGithub {
  installationId: string
  slug: string
}

export interface IntegrationAppstore {
  username: string
  teamId?: string
  teamName?: string
  secretId?: string
  signature?: string
  teams?: { id: string; name: string }[]
}

export interface IntegrationGooglePlay {
  username: string
  teamId: string
  teamName: string
}

// TODO
export interface IntegrationStripe {
  username: string
  teamId: string
  teamName: string
}

export enum IntegrationStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  FAILED = 'FAILED',
  OTP = 'OTP',
  TEAM = 'TEAM',
}

export const useProjectIntegrations = (id: string) => {
  const { toast } = useContext(ProgressContext)
  const [integrations, setIntegrations] = useState<Integration[]>([])
  const appstoreIntegration = integrations.find(el => el.id === IntegrationId.appstore)
  const googleplayIntegration = integrations.find(el => el.id === IntegrationId.googleplay)
  const stripeIntegration = integrations.find(el => el.id === IntegrationId.stripe)
  const firebaseIntegration = integrations.find(el => el.id === IntegrationId.firebase)
  const firestoreIntegration = integrations.find(el => el.id === IntegrationId.firestore)
  const firebaseAuthIntegration = integrations.find(el => el.id === IntegrationId.firebaseAuth)
  const firebaseStorageIntegration = integrations.find(el => el.id === IntegrationId.firebaseStorage)
  const firebaseHostingIntegration = integrations.find(el => el.id === IntegrationId.firebaseHosting)
  const firebaseFunctionsIntegration = integrations.find(el => el.id === IntegrationId.firebaseFunctions)
  const cmsIntegration = integrations.find(el => el.id === IntegrationId.cms)

  useEffect(() => {
    const integrationsRef = firestore.collection(`projects/${id}/integrations`)
    const unsubscribe = integrationsRef.onSnapshot({
      next: res => setIntegrations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Integration[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    integrations,
    appstoreIntegration,
    googleplayIntegration,
    stripeIntegration,
    firebaseIntegration,
    firestoreIntegration,
    firebaseAuthIntegration,
    firebaseStorageIntegration,
    firebaseHostingIntegration,
    firebaseFunctionsIntegration,
    cmsIntegration,
  }
}
