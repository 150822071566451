import { Box } from '@mui/material'
import { memo, useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'
import * as styled from './RightIcons.styled'

import { Icon, Modal, Name, Text, Type } from 'components'
import { useInputAutoFocusRef } from 'hooks'
import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const EditByModal = memo((props: Props) => {
  const themeContext = useContext(ThemeContext)
  const [active, setActive] = useState(false)
  const inputAutoFocusElement = useInputAutoFocusRef(active)
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onClick = () => {
    props.onChange(value)
    setActive(false)
  }

  return (
    <>
      <styled.RightIcon
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          setActive(true)
        }}
      >
        <Icon name={Name.REPOSITORY_EDIT} />
      </styled.RightIcon>
      <Modal
        active={active}
        onClose={() => setActive(false)}
        title="Edit field"
        onButtonClick={onClick}
        buttonDisabled={!value}
        buttonTitle="Save"
      >
        <Box>
          <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
            {props.label}
          </Text>
          <InputWrapper inputEmpty={!value}>
            <input
              value={value || undefined}
              onChange={({ target: { value } }) => setValue(value || null)}
              ref={inputAutoFocusElement}
            />
          </InputWrapper>
        </Box>
      </Modal>
    </>
  )
})
