import React from 'react'
import { ISvg, Svg } from '../Svg'

const Scroll: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="1.5" width="13" height="13" rx="2.3" stroke="#7F899E" />
    <line x1="11.5" y1="9.5" x2="11.5" y2="4.5" stroke="#7F899E" strokeLinecap="round" />
    <line x1="10.5" y1="11.5" x2="5.5" y2="11.5" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Scroll
