import styled from 'styled-components'

export const Items = styled.div`
  padding: 0 10px;
  height: 185px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const ItemImg = styled.div<{ isSFSymbols: boolean }>`
  background: ${props => props.theme.colors.settingField};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  height: ${props => props.isSFSymbols && '70px'};
  padding: ${props => props.isSFSymbols && '18px'};
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const Item = styled.div<{ isSFSymbols: boolean }>`
  align-self: center;
  width: ${props => (props.isSFSymbols ? 'calc((100% - 20px) / 3)' : 'calc((100% - 10px) / 2)')};
  height: ${props => props.isSFSymbols && '90px'};
  cursor: pointer;
  &:hover {
    & ${ItemImg} {
      background: ${props => props.theme.colors.settingFieldHover};
    }
  }
`

export const ItemText = styled.div`
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.colors.grey1};
`

export const Hint = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.colors.darkText};
`
