import React from 'react'
import { ISvg, Svg } from '../Svg'

const Settings: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.625 2.08253C7.85705 1.94856 8.14295 1.94856 8.375 2.08253L12.9372 4.71651C13.1692 4.85048 13.3122 5.09808 13.3122 5.36603V10.634C13.3122 10.9019 13.1692 11.1495 12.9372 11.2835L8.375 13.9175C8.14295 14.0514 7.85705 14.0514 7.625 13.9175L3.06282 11.2835C2.83077 11.1495 2.68782 10.9019 2.68782 10.634V5.36603C2.68782 5.09808 2.83077 4.85048 3.06282 4.71651L7.625 2.08253Z"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <circle cx="8" cy="8" r="1.75" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Settings
