import React from 'react'
import { ISvg, Svg } from '../Svg'

const Scroll: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="5.25" y="5.25" width="21.5" height="21.5" rx="3.55" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M12 23L21 23" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M23 20L23 11" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Scroll
