import styled from 'styled-components'

export const Screenshots = styled.div`
  background-color: ${props => props.theme.colors.primaryBackground};
  border-radius: 16px;
  padding: 35px 17px 30px;
  margin-top: 10px;
  width: 100%;
  & div:first-child {
    overflow: auto;
    display: flex;
    padding-bottom: 12px;
    & img {
      margin: 0 6px;
      width: 186px;
      border-radius: 20px;
    }
  }
  & div:last-child {
    margin: 30px 17px 0;
    margin-top: 26px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.darkText};
    & > i {
      color: ${props => props.theme.colors.grey1}50;
      font-size: 12px;
    }
    & > span {
      color: ${props => props.theme.colors.primaryText};
      &:hover {
        color: ${props => props.theme.colors.primaryTextHover};
      }
    }
  }
`
