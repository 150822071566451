import { memo, useContext, useEffect, useMemo, useState } from 'react'

import * as styled from './FileInput.styled'

import { Icon, Name } from 'components'
import { ProgressContext } from 'context'
import { ResourceTypes } from 'services/cms'
import { ValueType, urlToBase64 } from 'utils'
import { FieldProps } from '../../types'

export const FileInput = memo((props: FieldProps) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [openInfo, setOpenInfo] = useState(false)
  const { name, size, thumbnailUrl, src, type, resourceType } = useMemo(() => {
    const name = props.value
      ? props.value.url
        ? decodeURI(props.value.url).split('/').slice(-1)[0]
        : props.value.name
      : ''
    const size = props.value ? props.value.size : ''
    const thumbnailUrl = props.value ? props.value.thumbnailUrl : ''
    const src = props.value ? props.value.url || URL.createObjectURL(props.value) : ''
    const type = props.value ? props.value.type : ''
    const resourceType = type ? (type.split('/')[0] as ResourceTypes) : undefined
    return { name, size, thumbnailUrl, src, type, resourceType }
  }, [props.value])
  const [rT, setRT] = useState(resourceType || props.resourceType)

  useEffect(() => {
    if (resourceType || props.resourceType) {
      setRT(resourceType || props.resourceType)
    }
  }, [resourceType, props.resourceType])

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file && (!rT || rT === ValueType.file || file.type.startsWith(rT))) {
      props.onChange(file)
    } else {
      toast(`Please pick ${rT}`)
    }
  }

  const open = () => window.open(src, '_blank', 'noopener,noreferrer')

  const deleteFile = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    props.onChange(null)
  }

  const download = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    startLoader()
    await urlToBase64(src, res => {
      const link = document.createElement('a')
      link.href = res
      link.download = name
      link.click()
      stopLoader()
    })
  }

  return (
    <>
      {props.value && (
        <styled.FileInputBottomIcons>
          <Icon name={Name.ADDITIONAL_DOWNLOAD} hasWrapper onClick={download} />
          {/* <Icon name={Name.ADDITIONAL_CROP} hasWrapper /> */}
          <Icon name={Name.RIGHT_SIDEBAR_INFO} hasWrapper onClick={() => setOpenInfo(open => !open)} />
          <Icon name={Name.RIGHT_SIDEBAR_DELETE} hasWrapper onClick={deleteFile} />
        </styled.FileInputBottomIcons>
      )}
      <styled.FileInput onClick={src ? open : undefined}>
        {props.value ? (
          openInfo ? (
            <styled.Info>
              {name && (
                <div>
                  <div>Filename</div>
                  <div>{name}</div>
                </div>
              )}
              {type && (
                <div>
                  <div>Type</div>
                  <div>{type}</div>
                </div>
              )}
              {size && (
                <div>
                  <div>Size</div>
                  <div>{size}B</div>
                </div>
              )}
            </styled.Info>
          ) : rT === ValueType.image ? (
            <img
              src={thumbnailUrl || src}
              alt="Loading..."
              onError={(e: any) => setTimeout(() => (e.target.src = src), 1000)}
            />
          ) : rT === ValueType.video ? (
            <video controls preload="none" poster={thumbnailUrl}>
              <source src={src} />
            </video>
          ) : rT === ValueType.audio ? (
            <audio controls preload="none">
              <source src={src} />
            </audio>
          ) : (
            <p>
              <Icon name={Name.REPOSITORY_FILE} width={60} height={60} />
              {name}
            </p>
          )
        ) : (
          <span>
            <Icon name={Name.RIGHT_SIDEBAR_ADD} />
            Add {rT || 'asset'}
          </span>
        )}
        {!props.value && (
          <input type="file" accept={!rT || rT === ValueType.file ? '*' : `${rT}/*`} onChange={onFileChange} />
        )}
      </styled.FileInput>
    </>
  )
})
