import React, { useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './Repositories.styled'

import { Text, Type } from 'components'
import { RepositoriesList } from './components'

export const Repositories: React.FC = () => {
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    document.title = 'Repositories | CodePlatform'
  }, [])

  return (
    <styled.Container>
      <styled.Content>
        <Text type={Type.TITLE}>Repositories</Text>
        <Text type={Type.SUB_TITLE} color={themeContext.colors.darkBlue_500_1}>
          Source code & pipelines
        </Text>
        <RepositoriesList />
      </styled.Content>
    </styled.Container>
  )
}
