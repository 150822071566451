import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { Container } from '../SettingField.styled'
import * as styled from './SpacingField.styled'

import { useOnClickOutside, useOptionsContainerPosition } from 'hooks'
import { InsetsConstant } from 'utils'
import { NumberField } from '../common'

const fields: (keyof InsetsConstant)[] = ['top', 'trailing', 'bottom', 'leading']

interface Props {
  margins?: InsetsConstant
  onMarginsChange: (val: InsetsConstant) => void
  paddings?: InsetsConstant
  onPaddingsChange: (val: InsetsConstant) => void
  insidePicker?: boolean
}

export const SpacingField: React.FC<Props> = ({
  margins,
  onMarginsChange,
  paddings,
  onPaddingsChange,
  insidePicker,
}) => {
  const [marginsState, setMarginsState] = useState(margins)
  const [paddingsState, setPaddingsState] = useState(paddings)
  const [optionsContainer, setOptionsContainer] = useState<((top?: number, width?: number) => ReactElement) | null>(
    null
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOptionsContainer(null))
  const [top, width] = useOptionsContainerPosition(ref, !!optionsContainer, insidePicker)

  useEffect(() => {
    setMarginsState(margins)
  }, [margins])

  useEffect(() => {
    setPaddingsState(paddings)
  }, [paddings])

  useEffect(() => {
    if (marginsState && JSON.stringify(marginsState) !== JSON.stringify(margins)) {
      const debounceFn = setTimeout(() => {
        fields.forEach(el => !marginsState[el] && delete marginsState[el])
        onMarginsChange(marginsState)
      }, 600)
      return () => clearTimeout(debounceFn)
    }
  }, [marginsState])

  useEffect(() => {
    if (paddingsState && JSON.stringify(paddingsState) !== JSON.stringify(paddings)) {
      const debounceFn = setTimeout(() => {
        fields.forEach(el => !paddingsState[el] && delete paddingsState[el])
        onPaddingsChange(paddingsState)
      }, 600)
      return () => clearTimeout(debounceFn)
    }
  }, [paddingsState])

  return (
    <Container withLabel ref={ref}>
      <styled.MarginsWrapper>
        {fields.map((el, i) => (
          <NumberField
            key={el}
            onChange={value => setMarginsState(margins => ({ ...margins, [el]: value }))}
            value={marginsState?.[el]}
            setOptionsContainer={setOptionsContainer}
            optionsContainerOffsetTop={i === 0 ? 117 : i === 2 ? 2 : 60}
            specialWidth={!(i % 2) ? 'calc(100% - 80px)' : '40px'}
            sync
          />
        ))}
        <styled.Title>MARGIN</styled.Title>
        <styled.Triangle />
        <styled.Triangle bottom />
        <styled.PaddingsWrapper>
          {fields.map((el, i) => (
            <NumberField
              key={el}
              onChange={value => setPaddingsState(paddings => ({ ...paddings, [el]: value }))}
              value={paddingsState?.[el]}
              setOptionsContainer={setOptionsContainer}
              optionsContainerOffsetTop={i === 0 ? 84 : i === 2 ? 35 : 60}
              specialWidth={!(i % 2) ? 'calc(100% - 80px)' : '40px'}
              sync
            />
          ))}
          <styled.Title>PADDING</styled.Title>
          <styled.Center />
        </styled.PaddingsWrapper>
      </styled.MarginsWrapper>
      {!!optionsContainer && optionsContainer(top, width)}
    </Container>
  )
}
