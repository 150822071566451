import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { SettingsLayout } from '../components'
import { AppIcon, LaunchScreen, PrimaryColor } from './sections'

export const Design: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Design</Text>
    <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
      Customize your app’s theme and branding.
    </Text>
    <CustomSectionsWrapper>
      <AppIcon />
      <PrimaryColor />
      <LaunchScreen />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
