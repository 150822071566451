import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const SectionTitle = styled.div`
  z-index: 1;
  background-color: ${props => props.theme.colors.selectBackground};
  color: ${props => props.theme.colors.grey};
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
  position: sticky;
  top: 0;
`
