import React from 'react'

import * as styled from './Suggestions.styled'

import { MessageBeforeSend, RegionsKeyboardPayload } from 'services/conversations'

interface Props {
  payload: RegionsKeyboardPayload
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
}

export const RegionsSuggestions: React.FC<Props> = ({ payload, sendMessage }) => (
  <styled.Suggestions>
    {payload.map(el => (
      <span
        key={el.regionId}
        onClick={() => sendMessage({ body: el.name, format: 'text', regionAttachment: { regionId: el.regionId } })}
      >
        {el.flag} {el.name}
      </span>
    ))}
  </styled.Suggestions>
)
