import React from 'react'
import { ISvg, Svg } from '../Svg'

const BackToProjects: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M6 10H15" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M9 6L5 10L9 14" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default BackToProjects
