import { Box } from '@mui/material'
import React from 'react'

import * as styled from './PlanName.styled'

import { landingUrl } from 'utils'

interface Props {
  planTitle: string
  plan: string
}

export const PlanName: React.FC<Props> = ({ planTitle, plan }) => (
  <Box display="flex" alignItems="center" columnGap="10px" marginTop="25px">
    <styled.PlanName>{plan}</styled.PlanName>
    <styled.PlanDetails href={`${landingUrl}/pricing?#applans`} target="_blank" rel="noreferrer">
      {planTitle}
    </styled.PlanDetails>
  </Box>
)
