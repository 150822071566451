import React from 'react'
import { ISvg, Svg } from '../Svg'

const AIRegenerate: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19 7.36253V9.85625C19 10.3395 18.6082 10.7313 18.125 10.7313H15.6313C15.148 10.7313 14.7563 10.3395 14.7563 9.85625C14.7563 9.373 15.148 8.98125 15.6313 8.98125H16.2998C15.3336 7.59643 13.7502 6.75 12 6.75C9.82237 6.75 7.90259 8.06012 7.10913 10.0877C6.93298 10.5377 6.42537 10.7597 5.97541 10.5836C5.52538 10.4075 5.30335 9.89989 5.47947 9.44989C5.98695 8.15317 6.86162 7.04621 8.00893 6.24868C9.18409 5.43179 10.5642 5 12 5C13.4358 5 14.8159 5.43179 15.9911 6.24865C16.458 6.57322 16.8798 6.94909 17.25 7.36811V7.36255C17.25 6.87931 17.6418 6.48755 18.125 6.48755C18.6082 6.48755 19 6.87928 19 7.36253ZM18.0246 13.4164C17.5746 13.2403 17.067 13.4624 16.8909 13.9124C16.0974 15.9399 14.1776 17.25 12 17.25C10.287 17.25 8.73368 16.4391 7.76254 15.1062H8.36872C8.85197 15.1062 9.24372 14.7145 9.24372 14.2312C9.24372 13.748 8.85197 13.3562 8.36872 13.3562H5.875C5.39175 13.3562 5 13.748 5 14.2312V16.725C5 17.2082 5.39175 17.6 5.875 17.6C6.35825 17.6 6.75 17.2082 6.75 16.725V16.6319C7.12023 17.0509 7.54201 17.4268 8.00893 17.7513C9.18409 18.5682 10.5642 19 12 19C13.4358 19 14.8159 18.5682 15.9911 17.7513C17.1384 16.9538 18.0131 15.8469 18.5205 14.5501C18.6966 14.1001 18.4746 13.5926 18.0246 13.4164Z"
      fill="#7F899E"
    />
  </Svg>
)
export default AIRegenerate
