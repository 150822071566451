import React from 'react'
import { ISvg, Svg } from '../Svg'

const SystemFolder: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g opacity="0.5">
      <path
        d="M11.0156 13H3.39844C2.4501 13 1.63389 12.7565 1.0374 12.2963C0.358594 11.7722 0 10.994 0 10.0455C0 8.28837 1.41914 7.33696 2.86377 7.12957C3.05479 5.95969 3.56689 4.95912 4.36113 4.21879C5.20422 3.43226 6.3301 2.9951 7.5 3.00004C8.54004 3.00004 9.49922 3.33214 10.2738 3.96026C10.8658 4.44441 11.3216 5.06613 11.5986 5.76736C12.4852 5.90401 13.2817 6.25543 13.8656 6.7719C14.6077 7.42843 15 8.32445 15 9.36365C15 10.3864 14.5787 11.3205 13.8138 11.994C13.077 12.6429 12.0832 13 11.0156 13Z"
        fill="#7F899E"
      />
    </g>
  </Svg>
)

export default SystemFolder
