import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import * as styled from './LeftPanel.styled'

import { DeviceMode, Icon, Name, PWAPreview } from 'components'
import { ProgressContext } from 'context'
import { ITemplate } from 'context/types'
import firebase, { firestore } from 'services/firebase'
import { BrandingColorType, getCustomColors, Resource } from 'utils'
import { AppName } from '../AppName'

interface Props {
  templateId: string | null
  name: string | null
  icon: Resource | null
  color: string | null
}

export const LeftPanelPreview: React.FC<Props> = ({ templateId, name, icon, color }) => {
  const { toast } = useContext(ProgressContext)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [previewIframeUrl, setPreviewIframeUrl] = useState<string | null>(null)
  const [deviceMode, setDeviceMode] = useState(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
  const inDevice = deviceMode === DeviceMode.MOBILE
  const message = useMemo(
    () => ({ inDevice, name, icon, customColors: color ? getCustomColors(color, BrandingColorType.primary) : null }),
    [inDevice, name, icon, color]
  )
  const key = JSON.stringify(message) + previewIframeUrl

  useEffect(() => {
    const onMessage = (e: any) =>
      e.data === 'READY' && previewIframeUrl && iframeRef.current?.contentWindow?.postMessage(message, previewIframeUrl)
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [key])

  useEffect(() => {
    setPreviewIframeUrl(null)
    const unsubscribe = firestore
      .collection('templates')
      .where(templateId ? firebase.firestore.FieldPath.documentId() : 'default', '==', templateId ? templateId : true)
      .limit(1)
      .onSnapshot({
        next: res => {
          const templates = res.docs.map(el => ({ id: el.id, ...el.data() })) as ITemplate[]
          const template = templates[0]
          if (template) {
            setPreviewIframeUrl(template.previewUrl)
          }
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [templateId])

  useEffect(() => {
    const onResize = () => setDeviceMode(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <>
      <AppName name={name} logoUrl={icon?.url} />
      <styled.PWAPreviewWrapper>
        <PWAPreview adaptive>
          {previewIframeUrl && (
            <iframe ref={iframeRef} key={key} title="Preview" src={previewIframeUrl} width="100%" height="100%" />
          )}
        </PWAPreview>
      </styled.PWAPreviewWrapper>
      <styled.TouchWrapper>
        <Icon name={Name.ADDITIONAL_LIVE_PREVIEW} width={24} height={24} />
        Your app live preview
      </styled.TouchWrapper>
    </>
  )
}
