import React from 'react'
import { ISvg, Svg } from '../Svg'

const Edit: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M2 14L14 14" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M5.83678 9.86681L4.8188 10.0385L4.97018 9.01727L5.19585 7.49499L5.93774 7.60497L5.19585 7.49499C5.23455 7.23388 5.35493 6.99173 5.53971 6.80323L10.4128 1.83232C10.896 1.33934 11.6875 1.33147 12.1804 1.81475L12.8945 2.51479C13.3875 2.99807 13.3954 3.78949 12.9121 4.28247L8.03906 9.25338C7.85428 9.44188 7.61457 9.56704 7.35428 9.61093L7.22957 8.87137L7.35428 9.61093L5.83678 9.86681Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
  </Svg>
)

export default Edit
