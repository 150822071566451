import { Box } from '@mui/material'
import React, { memo, useState } from 'react'

import { InputWrapper } from 'components/Modal/Modal.styled'
import * as styled from './RemoveContent.styled'

import { Modal } from 'components'
import { useInputAutoFocusRef } from 'hooks'

interface Props {
  title: string
  toRemove: any
  clean: () => void
  remove: (callback: () => void) => void
  text: string
  buttonTitle?: string
  nameConfirmation?: boolean
  blue?: boolean
}

export const RemoveContent: React.FC<Props> = memo(
  ({ title, toRemove, clean, remove, text, buttonTitle, nameConfirmation, blue }) => {
    const inputAutoFocusElement = useInputAutoFocusRef(!!toRemove)
    const [toRemoveName, setToRemoveName] = useState('')
    const name = nameConfirmation ? toRemove?.name : ''
    const [wait, setWait] = useState(false)

    const onButtonClick = () => {
      setWait(true)
      remove(() => {
        onClose()
        setWait(false)
      })
    }

    const onClose = () => {
      setToRemoveName('')
      clean()
    }

    return (
      <Modal
        active={!!toRemove}
        onClose={onClose}
        title={title}
        onButtonClick={onButtonClick}
        buttonTitle={buttonTitle || title}
        buttonDisabled={nameConfirmation ? name !== toRemoveName.trim() : false}
        deleteButton={!blue}
        buttonLoading={wait}
      >
        <Box>
          <styled.Text dangerouslySetInnerHTML={{ __html: nameConfirmation ? text.replace('{name}', name) : text }} />
          {nameConfirmation && (
            <InputWrapper inputEmpty={toRemoveName === ''}>
              <input value={toRemoveName} onChange={e => setToRemoveName(e.target.value)} ref={inputAutoFocusElement} />
            </InputWrapper>
          )}
        </Box>
      </Modal>
    )
  }
)
