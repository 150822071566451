import React from 'react'

import { BrandingFontPicker, Icon, Name } from 'components'
import { SettingField } from 'partials/RightSidebar/components'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  value?: string
  onChange: (val?: string) => void
  canOpenStylePickerFromScreen?: boolean
  disabled?: boolean
  insidePicker?: boolean
}

export const BrandingFontInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  value,
  onChange,
  canOpenStylePickerFromScreen,
  disabled,
  insidePicker,
}) => (
  <SettingField
    big={big}
    small={small}
    label={label}
    subLabel={subLabel}
    insidePicker={insidePicker}
    value={value}
    canOpenStylePickerFromScreen={canOpenStylePickerFromScreen}
    brandingFontInput
    disabled={disabled}
    leftIcon={insidePicker ? undefined : <Icon name={Name.RIGHT_SIDEBAR_TEXT_STYLE} />}
    optionsContainer={<BrandingFontPicker close={() => {}} onChange={onChange} />}
    actions={value ? <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onChange()} /> : undefined}
  />
)
