import React from 'react'
import { ISvg, Svg } from '../Svg'

const Tab: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="7.25" y="5.25" width="17.5" height="17.5" rx="2.75" stroke="#7F899E" strokeWidth="1.5" />
    <line x1="7.75" y1="26.25" x2="24.25" y2="26.25" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Tab
