import React from 'react'

import * as styled from './Card.styled'

import { Button, ButtonColor } from 'components'

interface Props {
  children: React.ReactNode
  title: string
  buttonTitle?: string
  onClick?: () => void
}

export const Card: React.FC<Props> = ({ children, title, buttonTitle, onClick }) => (
  <styled.Wrapper>
    <styled.Head>
      {title}
      {buttonTitle && onClick && (
        <Button color={ButtonColor.SECONDARY} onClick={onClick}>
          {buttonTitle}
        </Button>
      )}
    </styled.Head>
    {children}
  </styled.Wrapper>
)
