import React from 'react'
import { ISvg, Svg } from '../Svg'

const PickerUndo: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3 5H7.22222H8.5C10.9853 5 13 7.01472 13 9.5V9.5C13 11.9853 10.9853 14 8.5 14H8.11111"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M6 2L3 5L6 8" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default PickerUndo
