import React from 'react'

import * as styled from '../../../Settings.styled'
import { Connected } from './index.styled'

import { ConnectStripeButton, Icon, Name, stripeDescription, StripeLink, Unlink } from 'components'
import { Integration, IntegrationStatus, IntegrationStripe } from 'hooks'

interface Props {
  integration: Integration
}

export const Stripe: React.FC<Props> = ({ integration }) => (
  <styled.Block>
    <styled.Title>Stripe Integration</styled.Title>
    <styled.Paragraph mb="25px">{stripeDescription}</styled.Paragraph>
    {integration.status === IntegrationStatus.READY ? (
      <>
        <styled.Paragraph mb="10px" grey1>
          Connected to:
        </styled.Paragraph>
        <Connected>
          <StripeLink>
            <Icon name={Name.LEFT_SIDEBAR_PROJECT_STRIPE} />
            {(integration.data as IntegrationStripe | undefined)?.teamName}
          </StripeLink>
          <Unlink integration={integration} />
        </Connected>
      </>
    ) : (
      <ConnectStripeButton />
    )}
  </styled.Block>
)
