import React, { useContext, useEffect, useState } from 'react'

import * as styled from '../../Account.styled'

import { CustomSection, CustomSectionActions, CustomSectionTitle, FormSwitch, Icon, Name, getField } from 'components'
import { ProgressContext } from 'context'
import { multiFactor } from 'firebase/auth'
import { updateUserInfo } from 'hooks'
import firebase from 'services/firebase'
import { ValueType } from 'utils'
import { CheckCode, SendCode } from './components'

interface Props {
  user: firebase.User
}

export const MultiFactor: React.FC<Props> = ({ user }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [sendCodeModal, setSendCodeModal] = useState(false)
  const [verificationId, setVerificationId] = useState('')
  const multiFactorUser = multiFactor(user)
  // @ts-ignore
  const multiFactorPhoneNumber = multiFactorUser.enrolledFactors[0]?.phoneNumber || ''
  const [phoneNumber, setPhoneNumber] = useState(multiFactorPhoneNumber)

  useEffect(() => {
    const phoneNumber = multiFactorPhoneNumber
    setPhoneNumber(phoneNumber)
    setValuesBeforeEdit(JSON.stringify({ phoneNumber }))
  }, [multiFactorPhoneNumber])

  const onMultiFactorChange = () => {
    if (!multiFactorUser.enrolledFactors.length) {
      setSendCodeModal(true)
    } else {
      if (phoneNumber) {
        setPhoneNumber('')
      } else {
        setPhoneNumber(multiFactorPhoneNumber)
      }
    }
  }

  const cancel = () => {
    const { phoneNumber } = JSON.parse(valuesBeforeEdit)
    setPhoneNumber(phoneNumber)
  }

  const save = () => {
    startLoader()
    Promise.all(multiFactorUser.enrolledFactors.map(el => multiFactorUser.unenroll(el)))
      .then(() => updateUserInfo())
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }

  const showActions = valuesBeforeEdit !== JSON.stringify({ phoneNumber })

  return (
    <>
      <CustomSection showActions={showActions}>
        <CustomSectionTitle mb="0">SMS Multi-factor Authentication</CustomSectionTitle>
        {getField(ValueType.string)?.component({
          name: 'phoneNumber',
          value: phoneNumber,
          label: 'Phone number',
          placeholder: '',
          onChange: () => {},
          smallWidth: true,
          disabled: true,
          hint: (
            <styled.Hint>
              {!!phoneNumber ? (
                <span>
                  <Icon name={Name.ADDITIONAL_OK_LIGHT} />
                  verified
                </span>
              ) : (
                <span>not verified</span>
              )}
            </styled.Hint>
          ),
        })}
        <styled.Switches>
          <FormSwitch label="Enable" value={!!phoneNumber} onChange={onMultiFactorChange} />
        </styled.Switches>
        {showActions && <CustomSectionActions cancel={cancel} onClick={save} />}
      </CustomSection>
      <SendCode
        multiFactorUser={multiFactorUser}
        sendCodeModal={sendCodeModal}
        setSendCodeModal={setSendCodeModal}
        setVerificationId={setVerificationId}
      />
      <CheckCode
        multiFactorUser={multiFactorUser}
        verificationId={verificationId}
        setVerificationId={setVerificationId}
      />
    </>
  )
}
