import React from 'react'

import * as styled from './Suggestions.styled'

import { useWorkspaces } from 'hooks'
import { MessageBeforeSend } from 'services/conversations'

interface Props {
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
}

export const WorkspacesSuggestions: React.FC<Props> = ({ sendMessage }) => {
  const workspaces = useWorkspaces(true)

  return (
    <styled.Suggestions>
      {workspaces.map(el => (
        <span
          key={el.id}
          onClick={() => sendMessage({ body: el.name, format: 'text', workspaceAttachment: { workspaceId: el.id } })}
        >
          <i>{el.name[0]}</i>
          {el.name}
        </span>
      ))}
    </styled.Suggestions>
  )
}
