import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { desktopModeEvent, DeviceMode, mobileModeEvent, tabletModeEvent } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { Variable } from 'context/types'
import { ApplicationId } from 'hooks'
import { ProjectsService } from 'services/projects'

interface Variables {
  [key: string]: string
}

export const reloadPWAPreviewIframeEvent = 'reloadPWAPreviewIframeEvent'

interface Props {
  adaptive?: boolean
  page?: string
  email?: string
  token?: string
}

export const PWAPreviewIframe: React.FC<Props> = ({ adaptive, page, email, token }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { state: config, language, theme } = useContext(ProjectContext)
  const { id } = useParams()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const previewIframeUrl = `https://${id}.preview.codeplatform.com`
  const [variables, setVariables] = useState<Variables>({})
  const [lastRefresh, setLastRefresh] = useState(+new Date())
  const [deviceMode, setDeviceMode] = useState(
    adaptive && window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE
  )
  const inDevice = !!(adaptive && deviceMode === DeviceMode.MOBILE)
  const message = useMemo(
    () => ({ inDevice, variables, config, language, theme, email, token }),
    [inDevice, variables, config, language, theme, email, token]
  )
  const key = JSON.stringify(message) + previewIframeUrl + lastRefresh

  useEffect(() => {
    const onMessage = (e: any) =>
      e.data === 'READY' && iframeRef.current?.contentWindow?.postMessage(message, previewIframeUrl)
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [key])

  useEffect(() => {
    startLoader()
    ProjectsService.getVariables(id as string, ApplicationId.WEB)
      .then(res =>
        setVariables(
          (res.data.values as Variable[]).map(el => ({ [el.key]: el.value })).reduce((a, b) => ({ ...a, ...b }), {})
        )
      )
      .catch(err => toast(err))
      .finally(() => stopLoader())
    const refresh = () => setLastRefresh(+new Date())
    document.addEventListener(reloadPWAPreviewIframeEvent, refresh)
    return () => {
      document.removeEventListener(reloadPWAPreviewIframeEvent, refresh)
    }
  }, [])

  useEffect(() => {
    if (adaptive) {
      const setMobileModeFromListener = () => setDeviceMode(DeviceMode.MOBILE)
      const setTabletModeFromListener = () => setDeviceMode(DeviceMode.TABLET)
      const setDesktopModeFromListener = () => setDeviceMode(DeviceMode.DESKTOP)
      const onResize = () => setDeviceMode(window.innerWidth < 768 ? DeviceMode.DESKTOP : DeviceMode.MOBILE)
      document.addEventListener(mobileModeEvent, setMobileModeFromListener)
      document.addEventListener(tabletModeEvent, setTabletModeFromListener)
      document.addEventListener(desktopModeEvent, setDesktopModeFromListener)
      window.addEventListener('resize', onResize)
      return () => {
        document.removeEventListener(mobileModeEvent, setMobileModeFromListener)
        document.removeEventListener(tabletModeEvent, setTabletModeFromListener)
        document.removeEventListener(desktopModeEvent, setDesktopModeFromListener)
        window.removeEventListener('resize', onResize)
      }
    }
  }, [adaptive])

  return Object.keys(variables).length ? (
    <iframe
      ref={iframeRef}
      key={key}
      title="Preview"
      src={page ? `${previewIframeUrl}/${page}` : previewIframeUrl}
      width="100%"
      height="100%"
    />
  ) : (
    <></>
  )
}
