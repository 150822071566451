import React from 'react'
import { ISvg, Svg } from '../Svg'

const Divider: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M5 16L27 16" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Divider
