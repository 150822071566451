import React from 'react'

import * as styled from './GenerateComponents.styled'

import { ComponentPreview } from 'components'
import { GenerateComponentsKeyboardPayload } from 'services/conversations'
import { BrandingColorType, Module, Screen, ScreenSize } from 'utils'
import { constants } from './constants'

const screenConfig: Screen = {
  path: '',
  id: '',
  screenName: '',
  size: ScreenSize.compact,
  module: Module.others,
  components: [],
  backgroundColor: { constant: `@${BrandingColorType.neutral}` },
}

interface Props {
  payload: GenerateComponentsKeyboardPayload
}

export const GenerateComponents: React.FC<Props> = ({ payload }) => (
  <styled.Components>
    {(payload || constants).map(el => (
      <ComponentPreview key={el.id} screenConfig={screenConfig} componentConfig={el} />
    ))}
  </styled.Components>
)
