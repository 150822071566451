import React from 'react'
import { Link } from 'react-router-dom'
import ReactTimeago from 'react-timeago'

import * as styled from './ActivityItem.styled'
import { BuildStatus } from './ActivityItem.styled'

import { Icon, Name } from 'components'
import { getReadableTimeFromSeconds } from 'utils'

const getBuildStatus = (build: Build, dot?: boolean) => (
  <styled.Status status={build.state.result?.name} dot={dot}>
    {build.state.result?.name === BuildStatus.SUCCESSFUL
      ? 'Succeeded'
      : build.state.result?.name === BuildStatus.FAILED
      ? 'Failed'
      : 'In progress'}
  </styled.Status>
)

export interface Build {
  id: string
  build_number: number
  build_seconds_used: number
  completed_on: string
  created_on: string
  creator: {
    display_name: string
  }
  duration_in_seconds: number
  expired: false
  first_successful: false
  has_variables: false
  links: any
  repository: any
  run_number: number
  state: {
    name: string
    type: string
    result?: {
      name: BuildStatus
      type: string
    }
  }
  target: {
    ref_name: string
    selector?: {
      pattern: string
    }
    commit: {
      hash: string
    }
  }
  trigger: any
  type: string
  uuid: string
}

export interface Activity {
  id: string
  payload: any
  time: any
  type: string
}

export enum ActivityItemType {
  ACTIVITY = 'ACTIVITY',
  BUILD = 'BUILD',
  CUSTOM_BUILD = 'CUSTOM_BUILD',
  TASK = 'TASK',
  PIPELINE = 'PIPELINE',
  STEP = 'STEP',
  DEPLOY = 'DEPLOY',
}

interface Props {
  item: any
  type: ActivityItemType
  url?: string
  noView?: boolean
}

export const ActivityItem: React.FC<Props> = ({ item, type, url, noView }) => {
  const buildStatus = BuildStatus.SUCCESSFUL
  const build = item as Build

  switch (type) {
    case ActivityItemType.ACTIVITY:
      const activity = item as Activity
      return (
        <styled.Item>
          <styled.ItemTop>
            {buildStatus === BuildStatus.SUCCESSFUL ? (
              <Icon name={Name.REPOSITORY_BUILD_SUCCESS} width={40} height={40} />
            ) : buildStatus === BuildStatus.FAILED ? (
              <Icon name={Name.REPOSITORY_BUILD_FAILED} width={40} height={40} />
            ) : (
              <Icon name={Name.REPOSITORY_BUILD_PENDING} width={40} height={40} />
            )}
            <div>
              <div>
                <b>{activity.payload.actor.display_name}:</b> <i>{activity.type}</i>
              </div>
              <div>
                <i>
                  <ReactTimeago date={activity.time.toDate()} /> ·
                </i>{' '}
                <Link to="/">View</Link>
              </div>
            </div>
          </styled.ItemTop>
        </styled.Item>
      )
    case ActivityItemType.BUILD:
      return (
        <styled.Item>
          <styled.ItemTop build>
            {build.state.result?.name === BuildStatus.SUCCESSFUL ? (
              <Icon name={Name.REPOSITORY_BUILD_SUCCESS} width={40} height={40} />
            ) : build.state.result?.name === BuildStatus.FAILED ? (
              <Icon name={Name.REPOSITORY_BUILD_FAILED} width={40} height={40} />
            ) : (
              <Icon name={Name.REPOSITORY_BUILD_PENDING} width={40} height={40} />
            )}
            <div>
              <div>
                <b>Build {build.build_number}</b>
                {getBuildStatus(build)}
              </div>
              <div>
                <i>
                  <ReactTimeago date={new Date(build.created_on)} /> ·{' '}
                  <>
                    {build.creator.display_name}
                    {!noView && ' · '}
                  </>
                </i>
                {!noView && <Link to={`${build.build_number}/logs`}>View build log</Link>}
              </div>
            </div>
          </styled.ItemTop>
          <styled.ItemBottom>
            <styled.ItemColumns>
              {[
                {
                  title: 'Duration',
                  value: getReadableTimeFromSeconds(build.duration_in_seconds),
                },
                {
                  title: 'Version',
                  value: `v${build.build_number}`,
                },
                {
                  title: 'Pipeline',
                  value: build.target.selector?.pattern || '—',
                },
                {
                  title: 'Branch',
                  value: build.target.ref_name,
                },
              ].map(el => (
                <div key={el.title}>
                  <i>{el.title}</i>
                  <span>{el.value}</span>
                </div>
              ))}
            </styled.ItemColumns>
          </styled.ItemBottom>
        </styled.Item>
      )
    case ActivityItemType.CUSTOM_BUILD:
      return (
        <styled.Item customBuild>
          <styled.ItemTop customBuild>
            <div>
              <div>
                <i>Deployment</i>
              </div>
              <div>
                <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                  {url || '—'}
                  {url && <Icon name={Name.REPOSITORY_LINK} />}
                </a>
              </div>
            </div>
          </styled.ItemTop>
          <styled.ItemBottom customBuild>
            <styled.ItemColumns customBuild>
              {[
                {
                  title: '#',
                  value: !build ? '—' : build.build_number,
                },
                {
                  title: 'Status',
                  value: !build ? '—' : getBuildStatus(build, true),
                },
                {
                  title: 'Version',
                  value: !build ? '—' : `v${build.build_number}`,
                },
                {
                  title: 'Pipeline',
                  value: !build ? '—' : build.target.selector?.pattern || '—',
                },
                {
                  title: 'Commit',
                  value: !build ? '—' : build.target.commit.hash.slice(0, 7),
                },
                {
                  title: 'Source',
                  value: !build ? '—' : build.target.ref_name,
                },
                {
                  title: 'Duration',
                  value: !build ? '—' : getReadableTimeFromSeconds(build.duration_in_seconds),
                },
                { title: 'Started', value: !build ? '—' : <ReactTimeago date={new Date(build.created_on)} /> },
              ].map(el => (
                <div key={el.title}>
                  <i>{el.title}</i>
                  <span>{el.value}</span>
                </div>
              ))}
            </styled.ItemColumns>
          </styled.ItemBottom>
        </styled.Item>
      )
    default:
      return <></>
  }
}
