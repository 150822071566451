import React from 'react'
import { ISvg, Svg } from '../Svg'

const GenerateComponents: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="1.75" y="1.75" width="16.5" height="16.5" rx="3.25" stroke="#212133" strokeWidth="1.5" />
    <path
      d="M13.8263 9.62547C13.9033 9.54771 13.9577 9.4489 13.9834 9.34034C14.0091 9.23178 14.0049 9.11788 13.9714 9.01167C13.9387 8.90515 13.8779 8.81041 13.796 8.73831C13.714 8.66621 13.6143 8.61967 13.5082 8.60402L11.5712 8.31025C11.5306 8.30409 11.492 8.2877 11.4588 8.2625C11.4256 8.23731 11.3987 8.20406 11.3806 8.16562L10.5146 6.33374C10.4675 6.23328 10.3943 6.14869 10.3033 6.08967C10.2123 6.03064 10.1072 5.99957 10.0001 6C9.89297 5.9996 9.78792 6.03069 9.69695 6.08971C9.60598 6.14873 9.53278 6.23331 9.48572 6.33374L8.61959 8.16579C8.58275 8.24387 8.51131 8.29788 8.42881 8.31042L6.49183 8.60419C6.38572 8.61984 6.28598 8.66638 6.20404 8.73848C6.12211 8.81058 6.06129 8.90531 6.02857 9.01183C5.99509 9.11804 5.99094 9.23195 6.01661 9.34051C6.04228 9.44906 6.09672 9.54788 6.1737 9.62563L7.57517 11.0515C7.63492 11.1124 7.66232 11.2 7.64822 11.2856L7.31759 13.2991C7.30264 13.3846 7.3061 13.4726 7.32774 13.5566C7.34938 13.6406 7.38865 13.7185 7.4427 13.7846C7.61346 13.9965 7.91157 14.061 8.14992 13.9303L9.88218 12.9796C9.91869 12.9601 9.95909 12.95 10.0001 12.95C10.0411 12.95 10.0815 12.9601 10.118 12.9796L11.8504 13.9303C11.9325 13.976 12.0242 13.9999 12.1173 14C12.2866 14 12.4471 13.9214 12.5575 13.7846C12.6115 13.7185 12.6508 13.6406 12.6724 13.5566C12.6941 13.4726 12.6975 13.3846 12.6826 13.2991L12.3518 11.2856C12.3449 11.2434 12.3479 11.2 12.3606 11.1592C12.3733 11.1184 12.3954 11.0815 12.4248 11.0515L13.8263 9.62547Z"
      fill="#212133"
    />
  </Svg>
)

export default GenerateComponents
