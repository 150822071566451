import React, { useEffect, useState } from 'react'

import * as styled from './AppPlans.styled'

import { Interval, PlanCard } from 'components'
import { useParamsFromUrl, useProjectPlan } from 'hooks'
import { MessageBeforeSend } from 'services/conversations'
import { getMoney } from 'utils'
import { Pick } from '../components'

interface PlansProps {
  parentWindowStripeRedirect?: (url: string) => void
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
  planPriceId?: string
  workspaceId?: string
}

export const AppPlans: React.FC<PlansProps> = ({
  parentWindowStripeRedirect,
  sendMessage,
  setOnClickActionButton,
  planPriceId,
  workspaceId,
}) => {
  const { success, checkoutSessionId, clearCheckoutParams } = useParamsFromUrl()
  const [selected, setSelected] = useState(planPriceId || null)
  const { plans, onPlanChange } = useProjectPlan(
    workspaceId || '-',
    '-',
    undefined,
    undefined,
    parentWindowStripeRedirect
  )

  useEffect(() => {
    return () => {
      clearCheckoutParams()
    }
  }, [])

  useEffect(() => {
    const plan = plans.find(el => el.prices.find((el: any) => el.id === selected))
    const price = plan?.prices.find((el: any) => el.id === selected)
    setOnClickActionButton(() =>
      price
        ? !workspaceId
          ? () =>
              sendMessage({
                body: `${plan.metadata.title} plan: ${getMoney(
                  price.currency,
                  price.recurring.interval === Interval.year ? price.unit_amount / 12 : price.unit_amount
                )}/${price.recurring.interval}`,
                format: 'text',
                planAttachment: { checkoutSessionId: 'cs_test_XXXXXXXXXXXXXXXXXXXXXXXXXXXXX' },
              })
          : onPlanChange
          ? () => onPlanChange(price.id)
          : null
        : null
    )
  }, [selected, plans, onPlanChange])

  useEffect(() => {
    if (success && checkoutSessionId) {
      sendMessage({ body: 'Plan selected', format: 'text', planAttachment: { checkoutSessionId } })
    }
  }, [success, checkoutSessionId])

  return (
    <styled.Plans>
      {plans.map(el => (
        <Plan key={el.id} plan={el} selected={selected} setSelected={setSelected} planPriceId={planPriceId} />
      ))}
    </styled.Plans>
  )
}

interface PlanProps {
  plan: any
  selected: string | null
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  planPriceId?: string
}

export const Plan: React.FC<PlanProps> = ({ plan, selected, setSelected, planPriceId }) => {
  const intervalForPlanPriceId = plan.prices.find((el: any) => el.id === planPriceId)?.recurring.interval
  const [interval, setInterval] = useState(intervalForPlanPriceId || Interval.month)
  const [canSelect, setCanSelect] = useState(false)
  const [priceId, setPriceId] = useState('')

  const onSetInterval = (value: Interval) => {
    if (value !== interval) {
      setInterval(value)
      setSelected(null)
    }
  }

  return (
    <styled.Plan
      onClick={canSelect ? () => setSelected(selected => (priceId === selected ? null : priceId)) : undefined}
      canSelect={canSelect}
    >
      <styled.PlanCardWrapper selected={priceId === selected}>
        <PlanCard
          plan={plan}
          interval={interval}
          setInterval={onSetInterval}
          setCanSelect={setCanSelect}
          setPriceId={setPriceId}
        />
      </styled.PlanCardWrapper>
      <Pick selected={priceId === selected} transparent={!canSelect}>
        <i />
        {plan.metadata.title}
      </Pick>
    </styled.Plan>
  )
}
