import React from 'react'
import { ISvg, Svg } from '../Svg'

const Android: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M13.8366 22.0005C14.5038 22.0005 15.0857 21.4173 15.0857 20.7486V17.8249H15.9198C16.4203 17.8249 16.7539 17.4905 16.7539 16.989V8.63867H6.75293V16.9891C6.75293 17.4906 7.08656 17.825 7.587 17.825H8.42114V20.7487C8.42114 21.4174 9.00289 22.0005 9.67028 22.0005C10.3375 22.0005 10.9193 21.4174 10.9193 20.7487V17.825H12.5875V20.7487C12.5874 21.4173 13.1693 22.0005 13.8366 22.0005Z"
      fill="#212133"
    />
    <path
      d="M18.8351 16.9891C19.5023 16.9891 20.0841 16.406 20.0841 15.7372V9.89001C20.0841 9.22483 19.5023 8.63867 18.8351 8.63867C18.1678 8.63867 17.5859 9.22483 17.5859 9.89001V15.7372C17.5859 16.406 18.1677 16.9891 18.8351 16.9891Z"
      fill="#212133"
    />
    <path
      d="M4.66698 16.9891C5.3343 16.9891 5.91612 16.406 5.91612 15.7372V9.89001C5.91612 9.22483 5.33437 8.63867 4.66698 8.63867C3.99972 8.63867 3.41797 9.22483 3.41797 9.89001V15.7372C3.41797 16.406 3.99972 16.9891 4.66698 16.9891Z"
      fill="#212133"
    />
    <path
      d="M15.7529 2.12613C15.5862 1.95796 15.3379 1.95796 15.1712 2.12613L14.0525 3.24353L14.0009 3.29518C13.3375 2.96229 12.5938 2.79605 11.7656 2.79445C11.7616 2.79445 11.7576 2.79431 11.7535 2.79431H11.7534C11.7492 2.79431 11.7453 2.79445 11.7411 2.79445C10.913 2.79605 10.1693 2.96229 9.506 3.29518L9.45426 3.24353L8.33564 2.12613C8.16875 1.95796 7.92063 1.95796 7.75388 2.12613C7.587 2.29338 7.587 2.54158 7.75388 2.70869L8.83601 3.79338C8.48733 4.02669 8.17115 4.3122 7.89619 4.63875C7.23792 5.42071 6.81806 6.4384 6.76032 7.54104C6.75979 7.55252 6.75866 7.56387 6.75812 7.57535C6.75459 7.65042 6.75293 7.72591 6.75293 7.80165H16.7539C16.7539 7.72591 16.7521 7.65042 16.7487 7.57535C16.7481 7.56387 16.747 7.55252 16.7463 7.54104C16.6887 6.4384 16.2687 5.42065 15.6105 4.63881C15.3356 4.31227 15.0193 4.02676 14.6707 3.79345L15.7529 2.70876C15.9198 2.54158 15.9198 2.29338 15.7529 2.12613ZM9.66868 6.34216C9.3236 6.34216 9.04385 6.0618 9.04385 5.71596C9.04385 5.37013 9.3236 5.08976 9.66868 5.08976C10.0138 5.08976 10.2935 5.37013 10.2935 5.71596C10.2935 6.0618 10.0138 6.34216 9.66868 6.34216ZM13.8381 6.34216C13.493 6.34216 13.2133 6.0618 13.2133 5.71596C13.2133 5.37013 13.493 5.08976 13.8381 5.08976C14.1832 5.08976 14.463 5.37013 14.463 5.71596C14.463 6.0618 14.1832 6.34216 13.8381 6.34216Z"
      fill="#212133"
    />
  </Svg>
)

export default Android
