export const githubPopup = (url: string) => {
  const authWindow = window.open(
    url,
    'producthuntPopup',
    `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,width=563,height=${
      window.innerHeight - 100
    },left=0,top=100`
  )
  return new Promise(resolve => {
    window.addEventListener(
      'message',
      () => {
        authWindow?.close()
        resolve('')
      },
      false
    )
  })
}

if (window.opener) {
  window.opener.postMessage({}, window.opener.location)
}
