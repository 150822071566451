import { Box } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactTimeago from 'react-timeago'

import * as styled from '../GithubConnectionModal.styled'

import { Button, ButtonColor, ButtonSize, Icon, Loader, LoaderVariant, Name, Rotation } from 'components'
import { ProgressContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { GithubParamsName } from 'pages'
import { ProjectsService } from 'services/projects'
import { githubPopup } from './GithubConnectionPopup'

interface Installation {
  id: number
  account: {
    login: string
  }
}

interface Repository {
  id: number
  name: string
  private: boolean
  created_at: string
}

interface Props {
  close: () => void
  stateParam: number
  name?: string
  githubToken: string
}

export const ConnectGitRepository: React.FC<Props> = ({ close, name, stateParam, githubToken }) => {
  const { id, repositoryId } = useParams()
  const { toast } = useContext(ProgressContext)
  const [installations, setInstallations] = useState<Installation[]>([])
  const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null)
  const [repositories, setRepositories] = useState<Repository[]>([])
  const [selectedRepository, setSelectedRepository] = useState(name)
  const [wait, setWait] = useState(false)
  const [installationsOpen, setInstallationsOpen] = useState(false)
  const [repositoriesOpen, setRepositoriesOpen] = useState(false)
  const installationsRef = useRef<HTMLDivElement>(null)
  const repositoriesRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(installationsRef, () => setInstallationsOpen(false))
  useOnClickOutside(repositoriesRef, () => setRepositoriesOpen(false))

  const isNewRepository = !repositories.find(el => el.name === selectedRepository)

  useEffect(() => {
    getInstallations()
  }, [])

  useEffect(() => {
    setSelectedRepository(name)
    setRepositories([])
    if (selectedInstallation) {
      ProjectsService.getGithubRepositories(id as string, repositoryId as string, selectedInstallation.id, githubToken)
        .then(res => setRepositories(res.data.repositories))
        .catch(err => toast(err))
    }
  }, [selectedInstallation])

  useEffect(() => {
    if (!repositoriesOpen && !selectedRepository) {
      setSelectedRepository(name)
    }
  }, [repositoriesOpen, selectedRepository])

  const getInstallations = () => {
    ProjectsService.getGithubInstallations(id as string, repositoryId as string, githubToken).then(res => {
      if (res.data.installations.length) {
        const installations = res.data.installations
        setInstallations(installations)
        const installation_id = localStorage.getItem(GithubParamsName.INSTALLATION_ID)
        if (installation_id) {
          localStorage.removeItem(GithubParamsName.INSTALLATION_ID)
          setSelectedInstallation(installations.find((el: Installation) => el.id === +installation_id))
        } else {
          setSelectedInstallation(installations[0])
        }
      }
    })
  }

  const createGithubIntegration = () => {
    if (selectedInstallation && selectedRepository) {
      setWait(true)
      const {
        id: installationId,
        account: { login },
      } = selectedInstallation
      ProjectsService.createGithubIntegration(id as string, repositoryId as string, githubToken, {
        installationId,
        repositorySlug: `${login}/${selectedRepository}`,
      })
        .catch(err => toast(err))
        .finally(() => setWait(false))
    }
  }

  const addAccount = () =>
    githubPopup(`https://github.com/apps/codeplatform-dev/installations/new?state=${stateParam + 'add'}`)
      .then(() => setTimeout(getInstallations, 0))
      .catch(err => toast(err))

  return wait ? (
    <styled.Content>
      <styled.Title>Connect Git Repository</styled.Title>
      <Box height="373px">
        <Loader variant={LoaderVariant.PACMAN} />
      </Box>
    </styled.Content>
  ) : (
    <>
      <styled.Content>
        <styled.Title>Connect Git Repository</styled.Title>
        <styled.SubTitle>
          Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.
        </styled.SubTitle>
        <styled.Selects>
          <div>
            <h4>Organization</h4>
            <styled.Select ref={installationsRef}>
              <styled.SelectLabel onClick={() => setInstallationsOpen(!installationsOpen)}>
                <Icon name={Name.SIZE_24_GITHUB} />
                <span>{selectedInstallation?.account.login || 'organization-name'}</span>
                <styled.SelectIcon>
                  <Icon name={Name.SIZE_24_ARROW} rotation={installationsOpen ? Rotation.LEFT : undefined} />
                </styled.SelectIcon>
              </styled.SelectLabel>
              {installationsOpen && (
                <styled.SelectVariants>
                  {installations.map(el => (
                    <styled.Variant
                      active={el.id === selectedInstallation?.id}
                      key={el.id}
                      onClick={() => {
                        setSelectedInstallation(el)
                        setInstallationsOpen(false)
                      }}
                    >
                      <Icon name={Name.SIZE_24_GITHUB} />
                      {el.account.login}
                      {el.id === selectedInstallation?.id && <Icon name={Name.SIZE_24_CHECK_MARK} />}
                    </styled.Variant>
                  ))}
                  <styled.Variant onClick={addAccount}>
                    <Icon name={Name.SIZE_24_PLUS} />
                    Add GitHub Account
                  </styled.Variant>
                  <styled.Variant onClick={() => {}}>
                    <Icon name={Name.SIZE_24_SWITCH_PROVIDER} />
                    Switch Git Provider
                  </styled.Variant>
                </styled.SelectVariants>
              )}
            </styled.Select>
          </div>
          <div>
            <h4>Repository name</h4>
            <styled.Select ref={repositoriesRef}>
              <styled.SelectLabel>
                <input
                  value={selectedRepository}
                  onChange={e => setSelectedRepository(e.target.value)}
                  onFocus={() => setRepositoriesOpen(true)}
                />
                <styled.SelectIcon>
                  <Icon name={Name.SIZE_24_SEARCH} />
                </styled.SelectIcon>
              </styled.SelectLabel>
              {repositoriesOpen && (
                <styled.SelectVariants>
                  {isNewRepository && selectedRepository && (
                    <styled.Variant onClick={() => setRepositoriesOpen(false)}>
                      <Icon name={Name.SIZE_24_PLUS} />
                      {selectedRepository}
                      <styled.LockIcon>
                        <Icon name={Name.SIZE_24_LOCK} />
                      </styled.LockIcon>
                      <time>new</time>
                    </styled.Variant>
                  )}
                  {!!repositories.length && <p>CONNECT TO EXISTING REPOSITORY</p>}
                  {repositories.map(el => (
                    <styled.Variant
                      onClick={() => {
                        setSelectedRepository(el.name)
                        setRepositoriesOpen(false)
                      }}
                      key={el.id}
                    >
                      <Icon name={Name.SIZE_24_GITHUB} />
                      {el.name.length > 20 ? `${el.name.slice(0, 20)}...` : el.name}
                      {el.private && (
                        <styled.LockIcon>
                          <Icon name={Name.SIZE_24_LOCK} />
                        </styled.LockIcon>
                      )}
                      <ReactTimeago date={el.created_at} />
                    </styled.Variant>
                  ))}
                </styled.SelectVariants>
              )}
            </styled.Select>
          </div>
        </styled.Selects>
        <styled.Info connect>
          <p>
            We'll {isNewRepository ? 'create' : 'import'} <b>{selectedRepository}</b> repository under the{' '}
            <b>{selectedInstallation?.account.login || 'organization-name'}</b> account.
          </p>
        </styled.Info>
      </styled.Content>
      <styled.Actions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close} style={{ minWidth: '90px' }}>
          Cancel
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          onClick={createGithubIntegration}
          disabled={!selectedInstallation}
          style={{ minWidth: '90px' }}
        >
          Continue
        </Button>
      </styled.Actions>
    </>
  )
}
