import { Box, rgbToHex } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import * as styled from './Colors.styled'

import { Icon, Name, SwitchColor } from 'components'
import { useOnClickOutside } from 'hooks'
import { ColorsKeyboardPayload, MessageBeforeSend } from 'services/conversations'

interface Props {
  payload: ColorsKeyboardPayload
  changePayload: (payload: ColorsKeyboardPayload) => void
  sendMessage: (messageBeforeSend: MessageBeforeSend) => void
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
  setColor: React.Dispatch<React.SetStateAction<string | null>>
  color?: string
}

export const Colors: React.FC<Props> = ({
  payload,
  changePayload,
  sendMessage,
  setOnClickActionButton,
  setColor,
  color,
}) => {
  const [colors, setColors] = useState(payload)
  const [currentColor, setCurrentColor] = useState('')
  const [selected, setSelected] = useState(color || null)
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    if (JSON.stringify(colors) !== JSON.stringify(payload)) {
      changePayload(colors)
    }
  }, [colors, payload])

  useEffect(() => {
    if (!open && currentColor) {
      setColors(colors => [{ color: currentColor }, ...colors.filter(el => el.color !== currentColor)])
      setSelected(currentColor)
    }
  }, [open])

  useEffect(() => {
    setColor(selected)
    setOnClickActionButton(() =>
      selected ? () => sendMessage({ body: selected, format: 'text', colorAttachment: { color: selected } }) : null
    )
  }, [selected])

  return (
    <Box position="relative">
      <styled.Colors>
        <styled.PickColor onClick={() => setOpen(open => !open)}>
          <div>
            <Icon name={Name.ADDITIONAL_PLUS} />
          </div>
        </styled.PickColor>
        <styled.Separator />
        <Box display="flex" gap="6px">
          {colors.map(el => (
            <styled.Color
              key={el.color}
              onClick={() => setSelected(selected => (selected === el.color ? null : el.color))}
              selected={selected === el.color}
              color={el.color}
            >
              <div />
            </styled.Color>
          ))}
        </Box>
      </styled.Colors>
      {open && (
        <styled.SwitchColorWrapper ref={ref}>
          <SwitchColor
            onlySolid
            withoutOpacity
            value={currentColor || '#ffffff'}
            onChange={(color: string) => setCurrentColor(rgbToHex(color).slice(0, -2))}
          />
        </styled.SwitchColorWrapper>
      )}
    </Box>
  )
}
