import React from 'react'
import { ISvg, Svg } from '../Svg'

const CornerArrow: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 7 7" fill="none" {...props}>
    <path
      d="M7 1.41421V6C7 6.55228 6.55228 7 6 7H1.41421C0.523309 7 0.0771399 5.92286 0.707105 5.2929L5.29289 0.707107C5.92286 0.077142 7 0.523309 7 1.41421Z"
      fill="#B1B9C8"
    />
  </Svg>
)

export default CornerArrow
