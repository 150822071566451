import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import * as styled from './Navigation.styled'

import { IRoute, routes } from 'routes'

export const Navigation: React.FC = () => {
  const navRoutes = (routes.find(el => el.path === '/projects/:id/repositories/:repositoryId')?.children || []) as {
    path: string
    component: () => JSX.Element
    icon: JSX.Element
    name: string
  }[]

  const { pathname } = useLocation()
  const { id, repositoryId, buildNumber } = useParams()

  const replacedPath = (path: string) => {
    if (id) {
      path = path.replace(':id', id)
    }
    if (repositoryId) {
      path = path.replace(':repositoryId', repositoryId)
    }
    if (buildNumber) {
      path = path.replace(':buildNumber', buildNumber)
    }
    return path
  }

  const checkActive = (route: IRoute) => {
    const path = replacedPath(route.path)
    const childPath = replacedPath(route.childPath || '')

    return childPath
      ? pathname === childPath || pathname === path
      : route.innerRoutes
      ? pathname.startsWith(path)
      : pathname === path
  }

  return (
    <styled.NavWrapper>
      <nav>
        {navRoutes.map(
          el =>
            el.icon && (
              <styled.NavLink active={checkActive(el)} key={el.path}>
                <Link to={replacedPath(el.path)}>
                  {el.icon}
                  {el.name}
                </Link>
              </styled.NavLink>
            )
        )}
      </nav>
    </styled.NavWrapper>
  )
}
