import { httpClient } from 'utils'
import { host } from './projects'

const postWorkspace = (name: string) => {
  const url = `${host}/workspaces`
  return httpClient.post(url, { name, vendor: process.env.REACT_APP_ORGANIZATION_ID })
}

const getInvites = (id: string) => {
  const url = `${host}/workspaces/${id}/invites`
  return httpClient.get(url)
}

const invite = (id: string, email: string) => {
  const url = `${host}/workspaces/${id}/invites`
  return httpClient.post(url, { email })
}

const accept = (id: string) => {
  const url = `${host}/invites/${id}/accept`
  return httpClient.post(url)
}

const decline = (id: string) => {
  const url = `${host}/invites/${id}/decline`
  return httpClient.post(url)
}

const postBillingAccount = (id: string, values: any) => {
  const url = `${host}/workspaces/${id}/billing-accounts`
  return httpClient.post(url, { vendor: process.env.REACT_APP_ORGANIZATION_ID, ...values })
}

const getBillingAccount = (workspaceId: string, id: string, abortController?: AbortController) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${id}`
  return httpClient.get(url, { signal: abortController?.signal })
}

const updateBillingAccount = (workspaceId: string, id: string, values: any) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${id}`
  return httpClient.put(url, { vendor: process.env.REACT_APP_ORGANIZATION_ID, ...values })
}

const getPaymentMethods = (workspaceId: string, id: string, abortController?: AbortController) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${id}/payment-methods`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getSubscriptions = (workspaceId: string, id: string, abortController?: AbortController) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${id}/subscriptions`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getInvoices = (workspaceId: string, id: string, abortController?: AbortController) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${id}/invoices`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getProducts = (workspaceId: string, id: string, ids?: string, abortController?: AbortController) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${id}/products${ids ? `?ids=${ids}` : ''}`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getProductPrices = (
  workspaceId: string,
  billingAccountId: string,
  id: string,
  abortController: AbortController
) => {
  const url = `${host}/workspaces/${workspaceId}/billing-accounts/${billingAccountId}/products/${id}/prices`
  return httpClient.get(url, { signal: abortController?.signal })
}

const getProjectSubscriptions = (id: string, abortController: AbortController) => {
  const url = `${host}/projects/${id}/subscriptions/current`
  return httpClient.get(url, { signal: abortController.signal })
}

const getProjectPlans = (id: string, abortController: AbortController) => {
  const url = `${host}/projects/${id}/plans`
  return httpClient.get(url, { signal: abortController.signal })
}

const getWorkspaceSubscriptions = (id: string, abortController: AbortController) => {
  const url = `${host}/workspaces/${id}/subscriptions/current`
  return httpClient.get(url, { signal: abortController.signal })
}

const getWorkspacePlans = (id: string, abortController: AbortController) => {
  const url = `${host}/workspaces/${id}/plans`
  return httpClient.get(url, { signal: abortController.signal })
}

const postProjectSubscription = (id: string, workspaceId: string, priceId: string, redirectUrl: string) => {
  const url = `${host}/projects/${id}/subscriptions`
  return httpClient.post(url, { workspaceId, priceId, redirectUrl })
}

const updateProjectSubscription = (projectId: string, id: string, priceId: string) => {
  const url = `${host}/projects/${projectId}/subscriptions/${id}`
  return httpClient.put(url, { priceId })
}

const postWorkspaceSubscription = (id: string, priceId: string, redirectUrl: string) => {
  const url = `${host}/workspaces/${id}/subscriptions`
  return httpClient.post(url, { priceId, redirectUrl })
}

const updateWorkspaceSubscription = (workspaceId: string, id: string, priceId: string) => {
  const url = `${host}/workspaces/${workspaceId}/subscriptions/${id}`
  return httpClient.put(url, { priceId })
}

export const WorkspacesService = {
  postWorkspace,
  getInvites,
  invite,
  accept,
  decline,
  postBillingAccount,
  getBillingAccount,
  updateBillingAccount,
  getPaymentMethods,
  getSubscriptions,
  getInvoices,
  getProducts,
  getProductPrices,
  getProjectSubscriptions,
  getProjectPlans,
  getWorkspaceSubscriptions,
  getWorkspacePlans,
  postProjectSubscription,
  updateProjectSubscription,
  postWorkspaceSubscription,
  updateWorkspaceSubscription,
}
