import styled, { css } from 'styled-components'

export const ControllsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0 13px;
  width: 100%;
  border-right: 1px solid ${props => props.theme.colors.borderColor};
  background: ${props => props.theme.colors.primaryBackground};
`

export const Hint = styled.div`
  margin-top: 2px;
  opacity: 0;
  pointer-events: none;
  transition: 180ms ease-out;
  position: absolute;
  background: ${props => props.theme.colors.darkText};
  left: 54px;
  border-radius: 8px;
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.secondaryBackground};
  z-index: 6;
`

export const LinkBox = styled.div<{ active?: boolean; hasHover?: boolean; darkBlue_200?: boolean }>`
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  & img {
    border-radius: 50%;
  }
  & > a,
  & > i {
    position: relative;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      & path {
        &[fill] {
          fill: ${props => (props.darkBlue_200 ? props.theme.colors.darkBlue_200 : props.theme.colors.darkBlue_500_1)};
        }
        &[stroke] {
          stroke: ${props =>
            props.darkBlue_200 ? props.theme.colors.darkBlue_200 : props.theme.colors.darkBlue_500_1};
        }
      }
    }
  }
  ${props => {
    return props.active
      ? `
        background: ${props.theme.colors.primaryText};
        &:hover {
          background: ${props.theme.colors.primaryText};
        }
        & > a {
          &:hover {
            opacity: 1;
          }
          & > svg {
            & path {
              &[fill] {
                fill: ${props.theme.colors.white};
              }
              &[stroke] {
                stroke: ${props.theme.colors.white};
              }
            }
          }
        }
      `
      : `
      &:hover {
          ${props.hasHover && `background: ${props.theme.colors.settingField};`}
          & > a,
          i {
            & > svg {
              & path {
                &[fill] {
                  fill: ${!props.darkBlue_200 && props.theme.colors.darkText};
                }
                &[stroke] {
                  stroke: ${!props.darkBlue_200 && props.theme.colors.darkText};
                }
              }
            }
          }
          & ${Hint} {
            opacity: 1;
            padding: 4px 12px;
            font-size: 14px;
          }
        }
      `
  }}
`

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > ${LinkBox} {
    margin-bottom: 18px;
  }
`

export const HugeLinkBox = styled.div<{ isProfile?: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: center;
  height: ${props => !props.isMobile && '50px'};
  padding: ${props => !props.isMobile && '7px'};
  margin: ${props => !props.isMobile && '0 -8px'};
  cursor: pointer;
  border-radius: 8px;
  background-color: transparent;
  transition: ${props => props.theme.transition.primary};
  font-size: 14px;
  line-height: 150%;
  & button {
    padding: 4px;
  }
  & > div {
    & svg {
      & path {
        stroke: ${props => props.theme.colors.grey1};
      }
    }
  }
  & > div:last-child:not(.MuiAvatar-root) {
    color: ${props => props.theme.colors.darkText1};
  }
  ${props =>
    !props.isMobile &&
    css`
      &:hover {
        background-color: ${props.theme.colors.settingField};
        & > div {
          & svg {
            & path {
              stroke: ${props.theme.colors.grey2};
            }
            & circle {
              fill: ${props.isProfile && props.theme.colors.grey2};
            }
          }
        }
        & > div:last-child {
          color: ${props.theme.colors.darkText};
          & > button {
            color: ${props.theme.colors.darkText};
          }
        }
      }
    `}
`
