import React from 'react'
import { ISvg, Svg } from '../Svg'

const Column: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect
      x="19.75"
      y="27.75"
      width="7.5"
      height="5.5"
      rx="1.25"
      transform="rotate(180 19.75 27.75)"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <rect
      x="19.75"
      y="18.75"
      width="7.5"
      height="5.5"
      rx="1.25"
      transform="rotate(180 19.75 18.75)"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <rect
      x="19.75"
      y="9.75"
      width="7.5"
      height="5.5"
      rx="1.25"
      transform="rotate(180 19.75 9.75)"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path d="M25.0403 28.0403L25.0403 3.95963" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7.04041 28.0403L7.0404 3.95963" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Column
