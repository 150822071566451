import React from 'react'
import { ISvg, Svg } from '../Svg'

const Builds: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M4.87855 4.12604C4.59962 3.64286 4.09739 3.36309 3.58149 3.37321C3.03431 3.38393 2.51573 3.72746 2.34183 4.34404C2.02628 5.46277 2.13421 6.6934 2.7549 7.76846C3.60306 9.23752 5.17173 10.0161 6.7766 9.98116L8.67861 13.2756C9.32323 14.3921 10.7509 14.7746 11.8674 14.13C12.9839 13.4854 13.3665 12.0577 12.7218 10.9412L10.8198 7.6468C11.6525 6.2744 11.7626 4.52662 10.9144 3.05756C10.2936 1.98231 9.28158 1.27346 8.1547 0.987461C7.53379 0.829875 6.97706 1.10725 6.69422 1.57575C6.42755 2.01747 6.41874 2.59227 6.6977 3.07539L7.0153 3.62542C7.0153 3.62543 7.01531 3.62544 7.01531 3.62545C7.30535 4.12785 7.13321 4.77025 6.63082 5.06031C6.12843 5.35036 5.48602 5.17824 5.19595 4.67586C5.19595 4.67585 5.19594 4.67584 5.19593 4.67583L4.87855 4.12604Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
  </Svg>
)

export default Builds
