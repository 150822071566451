import styled from 'styled-components'

export const TitleWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
  & svg {
    cursor: pointer;
    & path {
      transition: 300ms ease-out;
      stroke: ${props => props.theme.colors.grey1};
    }
  }
  &:hover svg path {
    stroke: ${props => props.theme.colors.iconHover};
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  margin-left: 4px;
  color: ${props => props.theme.colors.grey2};
`

export const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: grab;
  background: ${props => props.theme.colors.settingField};
  border-radius: 4px;
  height: 54px;
  padding: 0 2px;
  &:hover {
    background: ${props => props.theme.colors.settingFieldHover};
  }
  & svg {
    width: 32px;
    height: 32px;
    & * {
      &[fill] {
        fill: ${props => props.theme.colors.grey1};
      }
      &[stroke] {
        stroke: ${props => props.theme.colors.grey1};
      }
    }
  }
  & div {
    text-align: center;
    color: ${props => props.theme.colors.grey1};
    font-size: 10px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  * {
    pointer-events: none;
  }
`
