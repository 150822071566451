import React from 'react'
import { ISvg, Svg } from '../Svg'

const Calendar: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="5.75" y="6.75" width="20.5" height="18.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M10 5L10 9" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M22 5L22 9" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 12H26" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Calendar
