import React from 'react'
import { ISvg, Svg } from '../Svg'

const Email: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="2.67344"
      y="20.2357"
      width="16.4727"
      height="18.6545"
      rx="2.78182"
      transform="rotate(-90 2.67344 20.2357)"
      stroke="#212133"
      strokeWidth="1.2"
    />
    <path
      d="M6.54688 8.72656L10.4586 12.6383C11.3107 13.4904 12.6921 13.4904 13.5442 12.6383L17.456 8.72656"
      stroke="#212133"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </Svg>
)

export default Email
