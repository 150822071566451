import { memo, useContext, useEffect, useState } from 'react'

import * as styled from './Screenshots.styled'

import { Button, ButtonColor, Icon, Name } from 'components'
import { TopPanelContext } from 'context'
import { ScreenshotsStudio } from 'pages'
import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string[] | null
}

export const Screenshots = memo((props: Props) => {
  const { setBackButton, setPage } = useContext(TopPanelContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setBackButton(
        <Button
          color={ButtonColor.TERTIARY}
          leftIcon={<Icon name={Name.ADDITIONAL_BACK} width={20} height={20} />}
          onClick={() => setOpen(false)}
        >
          Back
        </Button>
      )
      setPage(<ScreenshotsStudio />)
      return () => {
        setBackButton(null)
        setPage(null)
      }
    }
  }, [open])

  return (
    <styled.Screenshots>
      <div>
        {props.value?.map((el: any) => (
          <img key={el} src={el} alt="img" />
        ))}
      </div>
      <div>
        {props.value?.length || 0} of 10 Screenshots
        <i>|</i>
        <span onClick={() => setOpen(true)}>Open Screenshots Editor</span>
      </div>
    </styled.Screenshots>
  )
})
