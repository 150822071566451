import React from 'react'
import { ISvg, Svg } from '../Svg'

const Reference: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11 4.75V3.5V3C11 1.89543 10.1046 1 9 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H4.75"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <rect x="7.25" y="7.25" width="7.5" height="7.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Reference
