import React from 'react'
import { ISvg, Svg } from '../Svg'

const Github: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9989 2.18164C6.57736 2.18164 2.18164 6.6886 2.18164 12.2486C2.18164 16.6962 4.99459 20.469 8.89606 21.8008C9.38729 21.8929 9.5663 21.582 9.5663 21.315C9.5663 21.0765 9.55786 20.4431 9.55304 19.6032C6.82206 20.2113 6.24585 18.2536 6.24585 18.2536C5.79923 17.0911 5.15551 16.7815 5.15551 16.7815C4.26408 16.1567 5.22302 16.1691 5.22302 16.1691C6.20848 16.2408 6.72683 17.2067 6.72683 17.2067C7.6026 18.7449 9.02504 18.3005 9.58438 18.0434C9.67358 17.3927 9.92673 16.949 10.2076 16.6975C8.02753 16.4435 5.73534 15.5795 5.73534 11.7221C5.73534 10.6233 6.11807 9.72476 6.74612 9.02087C6.64486 8.76626 6.30794 7.74288 6.84195 6.35674C6.84195 6.35674 7.66649 6.08606 9.54219 7.38877C10.3251 7.16568 11.1653 7.05383 12.0001 7.05012C12.8337 7.05383 13.6739 7.16568 14.4581 7.38877C16.3326 6.08606 17.1553 6.35674 17.1553 6.35674C17.6911 7.74288 17.3542 8.76626 17.2529 9.02087C17.8822 9.72476 18.2625 10.6233 18.2625 11.7221C18.2625 15.5894 15.9667 16.4404 13.7794 16.6894C14.132 17.0003 14.446 17.6146 14.446 18.5539C14.446 19.8992 14.4339 20.985 14.4339 21.315C14.4339 21.5845 14.6105 21.8978 15.109 21.7995C19.0075 20.4653 21.818 16.695 21.818 12.2486C21.818 6.6886 17.4217 2.18164 11.9989 2.18164Z"
      fill="black"
    />
  </Svg>
)

export default Github
