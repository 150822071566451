import React from 'react'
import { ISvg, Svg } from '../Svg'

const BuildFailedSmall: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_14170_36012)">
      <circle cx="8" cy="8" r="7.25" fill="white" stroke="#F94549" strokeWidth="1.5" />
      <path d="M6 6L10 10" stroke="#F94549" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10 6L6 10" stroke="#F94549" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_14170_36012">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default BuildFailedSmall
