import styled from 'styled-components'

export const BuildVariables = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  & > div {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
`

export const InputsBox = styled.div`
  width: 90%;
  display: flex;
  column-gap: 15px;
`

export const Input = styled.input`
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  height: 30px;
  width: 49%;
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 8px;
  outline: none;
  padding: 6px 10px;
  box-sizing: border-box;
  &:disabled {
    color: ${props => props.theme.colors.disabledTextColor};
    background-color: ${props => props.theme.colors.disabledBgColor}80;
  }
`

export const Actions = styled.div`
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  & svg {
    cursor: pointer;
    user-select: none;
    & path,
    & circle {
      transition: 300ms ease-out;
    }
    &:hover {
      & path,
      & circle {
        stroke: ${props => props.theme.colors.darkText};
      }
    }
  }
`
