import React, { useEffect } from 'react'

export const useOnClickOutside = (ref: React.RefObject<HTMLDivElement>, handler: Function) => {
  useEffect(() => {
    if (handler) {
      const listener = (e: any) => {
        if (!ref.current || ref.current.contains(e.target)) {
          return
        }
        handler(e)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [handler])
}
