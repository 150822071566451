import React from 'react'
import { ISvg, Svg } from '../Svg'

const Arrow: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M8 5L13 10L8 15" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Arrow
