import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Loader, LoaderVariant } from 'components'
import { usePlanParamsFromUrl } from 'hooks'
import firebase, { auth } from 'services/firebase'

interface Props {
  children: React.ReactNode
}

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { addPlanParamsIfExists } = usePlanParamsFromUrl()
  const [wait, setWait] = useState(true)
  const [user, setUser] = useState<firebase.User | null>(null)
  const authorized = user && !user.isAnonymous

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user)
      setWait(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return wait ? (
    <Loader variant={LoaderVariant.PACMAN} />
  ) : authorized ? (
    <>{children}</>
  ) : (
    <Navigate to={`/auth${addPlanParamsIfExists()}`} />
  )
}
