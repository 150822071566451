import { Box } from '@mui/material'
import React from 'react'

import { BrandingColorInput, BrandingFontInput, CommonPicker, DateInput, Icon, Name, SourceInput } from 'components'
import { useVariable } from 'hooks'
import { RightSidebarItem, SettingField, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import {
  CalendarStyleConstant,
  getAllLocalVariables,
  LocalVariable,
  Resource,
  Screen,
  ScreenComponent,
  VariableSource,
} from 'utils'
import { Common, Id } from '../common'

const calendarStyleValues = Object.values(CalendarStyleConstant).map(el => ({ value: el, label: el }))

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const CalendarSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { removeOldLocalVariable } = useVariable()
  const {
    id,
    componentType,
    name,
    calendarStyle,
    showWeekdays,
    font,
    textColor,
    showHeader,
    headerFont,
    headerColor,
    date,
    displayDate,
    listItems,
    listItemContextKey,
    listItemDate,
  } = component

  const onDataChange = (value?: VariableSource) => {
    if (listItemContextKey) {
      removeOldLocalVariable(screenConfig, id, listItemContextKey)
    }
    onComponentPropertiesChange([{ propName: 'listItems', value: { source: value } }])
  }

  const onListItemContextKeyChange = (value: string) => {
    if (listItemContextKey) {
      removeOldLocalVariable(screenConfig, id, listItemContextKey)
    }
    onComponentPropertiesChange([{ propName: 'listItemContextKey', value }])
  }

  const itemsSectionName = 'Item'
  const weekdaysSectionName = 'Weekdays'
  const headerSectionName = 'Header'

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <DateInput
          label="Date"
          value={date?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'date', value: { constant: value } }])}
          source={date?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'date', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <DateInput
          label="Display date"
          value={displayDate?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'displayDate', value: { constant: value } }])}
          source={displayDate?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'displayDate', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SourceInput
          label="Data"
          source={listItems?.source}
          onSourceChange={onDataChange}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          dataMode
        />
        <SettingTextField label="Item context key" value={listItemContextKey} onChange={onListItemContextKeyChange} />
        <SettingField
          label="Style"
          value={calendarStyle?.constant}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Styles"
              value={calendarStyle?.constant}
              onChange={value =>
                onComponentPropertiesChange([{ propName: 'calendarStyle', value: { constant: value } }])
              }
              values={calendarStyleValues}
              close={() => {}}
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Components" canHide childrenFor={componentType}>
        <Box display="flex" flexDirection="column" gap="12px">
          <RightSidebarItem small title={itemsSectionName}>
            <SubWrapper small childrenFor={itemsSectionName}>
              <DateInput
                label="Data"
                valueDescription={`<b>date</b> - `}
                value={listItemDate?.constant}
                onChange={value =>
                  onComponentPropertiesChange([{ propName: 'listItemDate', value: { constant: value } }])
                }
                source={listItemDate?.source}
                onSourceChange={value =>
                  onComponentPropertiesChange([{ propName: 'listItemDate', value: { source: value } }])
                }
                screenConfig={screenConfig}
                allLocalVariables={getAllLocalVariables(screenConfig, component, true)}
              />
            </SubWrapper>
          </RightSidebarItem>
          <RightSidebarItem
            small
            title={weekdaysSectionName}
            switchValue={showWeekdays?.constant}
            onSwitchChange={value =>
              onComponentPropertiesChange([{ propName: 'showWeekdays', value: { constant: value } }])
            }
          >
            <SubWrapper small childrenFor={weekdaysSectionName}>
              <BrandingFontInput
                subLabel="Font"
                value={font}
                onChange={value => onComponentPropertiesChange([{ propName: 'font', value }])}
              />
              <BrandingColorInput
                subLabel="Color"
                value={textColor?.constant}
                onChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { constant: value } }])}
                source={textColor?.source}
                onSourceChange={value =>
                  onComponentPropertiesChange([{ propName: 'textColor', value: { source: value } }])
                }
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            </SubWrapper>
          </RightSidebarItem>
          <RightSidebarItem
            small
            title={headerSectionName}
            switchValue={showHeader?.constant}
            onSwitchChange={value =>
              onComponentPropertiesChange([{ propName: 'showHeader', value: { constant: value } }])
            }
          >
            <SubWrapper small childrenFor={headerSectionName}>
              <BrandingFontInput
                subLabel="Header font"
                value={headerFont}
                onChange={value => onComponentPropertiesChange([{ propName: 'headerFont', value }])}
              />
              <BrandingColorInput
                subLabel="Header color"
                value={headerColor?.constant}
                onChange={value =>
                  onComponentPropertiesChange([{ propName: 'headerColor', value: { constant: value } }])
                }
                source={headerColor?.source}
                onSourceChange={value =>
                  onComponentPropertiesChange([{ propName: 'headerColor', value: { source: value } }])
                }
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            </SubWrapper>
          </RightSidebarItem>
        </Box>
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
        hideLocalVariables
        hideActions
      />
    </RightSidebarItem>
  )
}
