import React from 'react'
import { ISvg, Svg } from '../Svg'

const Conversations: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M5 3C3.34315 3 2 4.34315 2 6V15C2 16.6569 3.34315 18 5 18H7.59246C7.88083 18 8.15515 18.1245 8.34504 18.3415L10.4948 20.7984C11.2917 21.7091 12.7083 21.7091 13.5052 20.7984L15.655 18.3415C15.8448 18.1245 16.1192 18 16.4075 18H19C20.6569 18 22 16.6569 22 15V6C22 4.34315 20.6569 3 19 3H5Z"
      stroke="#212133"
      strokeWidth="2"
    />
    <path d="M6 8H18" stroke="#212133" strokeWidth="2" strokeLinecap="round" />
    <path d="M6 12H12" stroke="#212133" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)

export default Conversations
