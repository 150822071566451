import React from 'react'
import { ISvg, Svg } from '../Svg'

const Spacer: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M1.5 9.5L1.5 14.5H6.5M2 14L7 9" stroke="#7F899E" strokeLinecap="round" />
    <path d="M14.5 6.5L14.5 1.5L9.5 1.5M14 2L9 7" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Spacer
