import React from 'react'

import * as styled from './Card.styled'

import { Icon, Name } from 'components'

interface Props {
  name: string
  link?: string
  value: string
  description: string
}

export const Card: React.FC<Props> = ({ name, link, value, description }) => (
  <styled.Wrapper>
    <styled.TitleWrapper>
      <styled.Title>{name}</styled.Title>
      {link && (
        <styled.TitleAction to={link}>
          <Icon name={Name.REPOSITORY_LINK} width={24} height={24} />
        </styled.TitleAction>
      )}
    </styled.TitleWrapper>
    <styled.Value>{value}</styled.Value>
    <styled.Description>{description}</styled.Description>
  </styled.Wrapper>
)
