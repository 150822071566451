import styled from 'styled-components'

export const LocalVariables = styled.div`
  & > div {
    margin-top: 1px;
    &:first-child > div > div {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child > div > div {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`
