import { memo } from 'react'

import { usePicker } from '../context'
import { ac, controlBtn, controlBtnsWrap, degreeInput, degreeRange, df, jc, jsb, psRl } from '../style'
import { formatInputValues } from '../utils/utils'
import { controlBtnStyles } from './Controls'
import { LinearIcon, RadialIcon, StopIcon, TrashIcon } from './icon'

export const GradientControls = memo(() => {
  const { gradientType } = usePicker()

  return (
    <div style={{ ...df, ...jsb, marginTop: 5, marginBottom: 4, background: '#e9e9f5', borderRadius: 6 }}>
      <GradientType />
      <div style={{ width: 120 }}>{gradientType === 'linear-gradient' && <DegreePicker />}</div>
      <StopPicker />
      <DeleteBtn />
    </div>
  )
})

const GradientType = memo(() => {
  const { gradientType, onChange, value } = usePicker()
  let isLinear = gradientType === 'linear-gradient'
  let isRadial = gradientType === 'radial-gradient'

  const handleLinear = () => {
    const remaining = value.split(/,(.+)/)[1]
    onChange(`linear-gradient(90deg, ${remaining}`)
  }

  const handleRadial = () => {
    const remaining = value.split(/,(.+)/)[1]
    onChange(`radial-gradient(circle, ${remaining}`)
  }

  return (
    <div style={{ ...df, ...ac, ...controlBtnsWrap }}>
      <div onClick={handleLinear} style={{ ...controlBtnStyles(isLinear), ...controlBtn, width: 'auto' }}>
        <LinearIcon color={isLinear ? '#568CF5' : ''} />
      </div>
      <div onClick={handleRadial} style={{ ...controlBtnStyles(isRadial), ...controlBtn, width: 'auto' }}>
        <RadialIcon color={isRadial ? '#568CF5' : ''} />
      </div>
    </div>
  )
})

const StopPicker = memo(() => {
  const { currentLeft, handleGradient, currentColor } = usePicker()

  const handleMove = newVal => {
    handleGradient(currentColor, formatInputValues(newVal, 0, 100))
  }

  return (
    <div style={{ ...df, ...ac, ...controlBtnsWrap, paddingLeft: 8 }}>
      <StopIcon />
      <input style={degreeInput} value={currentLeft} onChange={e => handleMove(e.target.value)} />
    </div>
  )
})

const DegreePicker = memo(() => {
  const { degrees, onChange, value } = usePicker()

  const handleDegrees = e => {
    let newValue = formatInputValues(e.target.value, 0, 360)
    const remaining = value.split(/,(.+)/)[1]
    onChange(`linear-gradient(${newValue || 0}deg, ${remaining}`)
  }

  return (
    <div style={{ ...psRl, ...controlBtnsWrap, ...df, ...ac }}>
      <input style={degreeRange} type="range" value={degrees} onChange={e => handleDegrees(e)} min={0} max={360} />
      <input style={degreeInput} value={degrees} onChange={e => handleDegrees(e)} min={0} max={360} />
      <div
        style={{
          position: 'absolute',
          right: degrees > 99 ? 0 : degrees < 10 ? 7 : 3,
          top: 1,
          fontWeight: 400,
          fontSize: 13,
        }}
      >
        °
      </div>
    </div>
  )
})

const DeleteBtn = memo(() => {
  const { deletePoint } = usePicker()

  return (
    <div
      onClick={deletePoint}
      style={{ ...df, ...jc, ...ac, ...controlBtnsWrap, width: 30, ...controlBtnStyles(false), marginRight: 1 }}
    >
      <TrashIcon />
    </div>
  )
})
