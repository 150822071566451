import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import * as styled from './LogIn.styled'

import { LogInForm } from 'components'
import { usePlanParamsFromUrl } from 'hooks'
import { LandingLayout } from 'partials/LandingLayout'
import { BlurBox } from 'partials/LandingLayout/components/BlurBox'
import firebase, { auth } from 'services/firebase'
import Container from '../../components/Container/Container'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

export const LogIn = () => {
  const { addPlanParamsIfExists } = usePlanParamsFromUrl()
  const [user, setUser] = useState<firebase.User | null>(null)
  const authorized = user && !user.isAnonymous

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser)
    return () => {
      unsubscribe()
    }
  }, [])

  if (authorized) {
    return <Navigate to={`/${addPlanParamsIfExists()}`} replace />
  }

  return (
    <LandingLayout>
      <Header />
      <styled.LoginWrapper>
        <Container>
          <BlurBox $hasWrapper>
            <styled.AuthenticateWrapper>
              <LogInForm />
            </styled.AuthenticateWrapper>
          </BlurBox>
        </Container>
      </styled.LoginWrapper>
      <Footer />
    </LandingLayout>
  )
}
