import React from 'react'

import * as styled from './Switch.styled'
import { SwitchVariant } from './Switch.styled'

import { Switch } from './Switch'

interface Props {
  value: boolean
  onChange?: (val: boolean) => void
  label: string
  disabled?: boolean
}

export const FormSwitch: React.FC<Props> = ({ value, onChange, label, disabled }) => (
  <styled.FormSwitchWrapper>
    <Switch checked={value} disabled={disabled} variant={SwitchVariant.GREEN} onChange={onChange} />
    {label}
  </styled.FormSwitchWrapper>
)
