import { Box } from '@mui/material'
import React from 'react'

import * as styled from './CustomSection.styled'

import { Button, ButtonColor } from 'components'

interface Props {
  title: string
  subTitle: string
  onClick: () => void
  buttonText: string
  deleteButton?: boolean
}

export const CustomSectionButton: React.FC<Props> = ({ title, subTitle, onClick, buttonText, deleteButton }) => (
  <>
    <styled.CustomSectionSubTitle>
      <b>{title}</b>
    </styled.CustomSectionSubTitle>
    <Box mt="-5px">
      <styled.CustomSectionSubTitle secondary>{subTitle}</styled.CustomSectionSubTitle>
      <Box mt="5px">
        <Button color={deleteButton ? ButtonColor.DELETE : ButtonColor.PRIMARY} onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  </>
)
