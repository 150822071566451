import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const CodeplatformAI: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M8.52174 0L10.8274 4.15586L15.0435 6.42857L10.8274 8.70129L8.52174 12.8571L6.21609 8.70129L2 6.42857L6.21609 4.15586L8.52174 0ZM17.6522 10.7143L19.1896 13.4846L22 15L19.1896 16.5154L17.6522 19.2857L16.1148 16.5154L13.3043 15L16.1148 13.4846L17.6522 10.7143ZM8.52174 19.7143L9.29043 21.0994L10.6957 21.8571L9.29043 22.6149L8.52174 24L7.75304 22.6149L6.34783 21.8571L7.75304 21.0994L8.52174 19.7143Z"
      fill="#476CEC"
    />
  </Svg>
)
