import { rgbToHex } from '@mui/material'
import { SettingTextField } from 'partials/RightSidebar/components'
import { memo } from 'react'
import { usePicker } from '../context'
import { Controls } from './Controls'
import { GradientBar } from './GradientBar'
import { GradientControls } from './GradientControls'
import { Hue } from './Hue'
import { ImageInput } from './ImageInput'
import { Opacity } from './Opacity'
import { Square } from './Square'

const isColor = (color, withoutOpacity) =>
  color.match(/^#(?:[0-9a-fA-F]{3,4}){1,2}$/) && (color.length === 7 || (!withoutOpacity && color.length === 9))

export const Picker = memo(({ onlySolid, withoutOpacity, preview }) => {
  const { isGradient, isImage, value, onChange } = usePicker()

  const handleColorChange = val => {
    if (isColor(val, withoutOpacity) && val !== rgbToHex(value)) {
      onChange(withoutOpacity ? `${val}00` : val)
    }
  }

  return (
    <div style={{ userSelect: 'none' }}>
      {!onlySolid && <Controls />}
      {isImage ? (
        <ImageInput />
      ) : (
        <>
          <Square />
          {isGradient && (
            <>
              <GradientControls />
              <GradientBar />
            </>
          )}
          <Hue />
          {!withoutOpacity && <Opacity />}
          {!isGradient && (
            <SettingTextField subLabel="Selected color" value={rgbToHex(value)} onChange={handleColorChange} />
          )}
          {preview}
        </>
      )}
    </div>
  )
})
