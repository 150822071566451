import React from 'react'
import { ISvg, Svg } from '../Svg'

const Light: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_17442_43929)">
      <path
        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
        fill="#7F899E"
      />
      <path
        d="M12 3.59958C11.3373 3.59958 10.8 3.06225 10.8 2.39958V1.2C10.8 0.537328 11.3373 0 12 0C12.6627 0 13.2 0.537328 13.2 1.2V2.39958C13.2 3.06225 12.6627 3.59958 12 3.59958ZM18.7887 6.41147C18.4816 6.41147 18.1746 6.29442 17.9402 6.06019C17.4715 5.59172 17.4715 4.83192 17.9397 4.36317L18.7875 3.51487C19.2562 3.04612 20.0162 3.04598 20.4849 3.51445C20.9537 3.98292 20.9537 4.74272 20.4855 5.21147L19.6376 6.05977C19.4033 6.29428 19.0957 6.41147 18.7887 6.41147ZM22.8 13.2H21.6006C20.9379 13.2 20.4006 12.6627 20.4006 12C20.4006 11.3373 20.9379 10.8 21.6006 10.8H22.8C23.4627 10.8 24 11.3373 24 12C24 12.6627 23.4627 13.2 22.8 13.2ZM19.6365 20.8368C19.3295 20.8368 19.0219 20.7196 18.7875 20.4851L17.9396 19.6368C17.4714 19.1681 17.4714 18.4083 17.9402 17.9398C18.4089 17.4713 19.1689 17.4712 19.6376 17.9402L20.4855 18.7885C20.9537 19.2573 20.9537 20.0171 20.4849 20.4855C20.3736 20.5971 20.2414 20.6855 20.0958 20.7458C19.9502 20.8061 19.7941 20.837 19.6365 20.8368ZM12 24C11.3373 24 10.8 23.4627 10.8 22.8V21.6004C10.8 20.9377 11.3373 20.4004 12 20.4004C12.6627 20.4004 13.2 20.9377 13.2 21.6004V22.8C13.2 23.4627 12.6627 24 12 24ZM4.36345 20.8368C4.05642 20.8368 3.74939 20.7198 3.51502 20.4855C3.04627 20.0171 3.04627 19.2573 3.51445 18.7885L4.36233 17.9402C4.83108 17.4712 5.59106 17.4713 6.05981 17.9398C6.52856 18.4083 6.52856 19.1681 6.06037 19.6368L5.2125 20.4851C4.97812 20.7195 4.67048 20.8368 4.36345 20.8368ZM2.39944 13.2H1.2C0.537328 13.2 0 12.6627 0 12C0 11.3373 0.537328 10.8 1.2 10.8H2.39944C3.06211 10.8 3.59944 11.3373 3.59944 12C3.59944 12.6627 3.06211 13.2 2.39944 13.2ZM5.21133 6.41147C4.9043 6.41147 4.5967 6.29428 4.36228 6.05977L3.51441 5.21147C3.04622 4.74272 3.04622 3.98292 3.51497 3.51445C3.98372 3.04598 4.7437 3.04584 5.21245 3.51487L6.06033 4.36317C6.52852 4.83192 6.52852 5.59172 6.05977 6.06019C5.94845 6.17173 5.81619 6.26019 5.67059 6.32047C5.52499 6.38075 5.36892 6.41168 5.21133 6.41147Z"
        fill="#7F899E"
      />
    </g>
    <defs>
      <clipPath id="clip0_17442_43929">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default Light
