import { Dispatch, SetStateAction } from 'react'

import { GetVariable, GetVariableValue, setLocalVariables } from 'hooks'
import {
  BrandingColorStyles,
  BrandingFontStyle,
  BrandingThemeType,
  ComponentAlignment,
  ComponentType,
  ComponentsDirection,
  ISize,
  Product,
  Resource,
  Screen,
  ScreenComponent,
  createButton,
  createCalendar,
  createCarousel,
  createImage,
  createList,
  createMap,
  createNotificationsSettings,
  createProduct,
  createProgressIndicator,
  createSlider,
  createSpacer,
  createText,
  createTextInput,
  createThemePicker,
  createToggle,
  createVideo,
  getCSSTextAlignment,
  getColor,
  getComponentTypeById,
  getElementIdFromConfig,
  getRealHeight,
  getRealWidth,
  isParentComponent,
  screenHeight,
  screenWidth,
  setColors,
} from 'utils'

export const setInnerModeEvent = 'setInnerModeEvent'
export const screenComponentClassName = 'screenComponent'

export const createElement = async (
  setSubComponents: (element: HTMLElement, component: ScreenComponent, renderAnyway?: boolean) => void,
  component: ScreenComponent,
  parent: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  products: Product[],
  compression: number,
  firstChildOfScreen: boolean,
  onlyChild: boolean,
  screenConfig: Screen,
  language: string,
  getPxValue: (val?: string) => string,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  setScroll?: (func: () => void) => void,
  onClickComponent?: (componentId: string, subComponentId: string) => void,
  setHoveredId?: Dispatch<SetStateAction<string>>,
  setMovableComponent?: (config: ScreenComponent) => void,
  innerModeComponentId?: string
) => {
  const fromVB = !!(onClickComponent && setMovableComponent && setHoveredId && setScroll)
  const parentComponentType = getComponentTypeById(parent.id)
  const {
    visible,
    id,
    name,
    backgroundColor,
    borderColor,
    borderWidth,
    cornersRadius,
    font,
    textColor,
    textAlignment,
    componentsDirection,
    reversed,
    contentPadding,
    componentType,
    componentsSpacing,
    margins,
    width,
    height,
    product,
    componentAlignment,
    backgroundGradient,
    backgroundPattern,
    widthHeightRatio,
    localVariables,
  } = component

  const element = document.createElement(
    componentType === ComponentType.textInput || componentType === ComponentType.datePicker ? 'form' : 'div'
  )
  parent.appendChild(element)

  const elementId = getElementIdFromConfig(component)
  element.id = elementId
  element.className = screenComponentClassName
  element.style.fontFamily = fontFamily
  element.style.position = 'relative'
  element.style.boxSizing = 'border-box'

  await setLocalVariables(getVariableValue, localVariables)

  if (visible) {
    const notVisibleValue = visible.source
      ? (await getVariableValue(visible.source)) === 'false'
      : visible.constant === false
    if (notVisibleValue) {
      element.hidden = true
    }
  }

  if (fromVB) {
    element.ondblclick = e => {
      e.stopPropagation()
      if (componentType === ComponentType.carousel || componentType === ComponentType.list) {
        document.dispatchEvent(new Event(setInnerModeEvent))
      }
    }
    let timer: NodeJS.Timeout | null = null
    const componentCopy = JSON.parse(JSON.stringify(component)) as ScreenComponent
    element.onmousedown = e => {
      e.stopPropagation()
      onClickComponent(innerModeComponentId || id, innerModeComponentId ? id : '')
      timer = setTimeout(() => setMovableComponent(componentCopy), 600)
    }
    element.onmouseup = () => timer && clearTimeout(timer)
    element.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(id)
    }
    element.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
    if (
      (firstChildOfScreen &&
        onlyChild &&
        (componentType === ComponentType.scroll || componentType === ComponentType.list)) ||
      (name === 'Messages' && componentType === ComponentType.list)
    ) {
      element.onscroll = () => {
        const scrollTop = element.scrollTop
        setScroll(() => {
          const scrollElement = document.getElementById(elementId)
          if (scrollElement) {
            scrollElement.scrollTop = scrollTop
          }
        })
      }
    }
  }

  if (!fromVB) {
    const { x, y, height, width } = element.getBoundingClientRect()
    if (x > screenWidth || y > screenHeight || x + width < 0 || y + height < 0) {
      return element
    }
  }

  if (parentComponentType === ComponentType.overlay) {
    switch (componentAlignment) {
      case ComponentAlignment.fill:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '0'
        element.style.bottom = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.leading:
        element.style.position = 'absolute'
        element.style.left = '0'
        break
      case ComponentAlignment.trailing:
        element.style.position = 'absolute'
        element.style.right = '0'
        break
      case ComponentAlignment.top:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.topLeading:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '0'
        break
      case ComponentAlignment.topCenter:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '50%'
        element.style.transform = 'translateX(-50%)'
        break
      case ComponentAlignment.topTrailing:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.centerLeading:
        element.style.position = 'absolute'
        element.style.top = '50%'
        element.style.left = '0'
        element.style.transform = 'translateY(-50%)'
        break
      case ComponentAlignment.center:
        element.style.position = 'absolute'
        element.style.top = '50%'
        element.style.left = '50%'
        element.style.transform = 'translate(-50%, -50%)'
        break
      case ComponentAlignment.centerTrailing:
        element.style.position = 'absolute'
        element.style.top = '50%'
        element.style.right = '0'
        element.style.transform = 'translateY(-50%)'
        break
      case ComponentAlignment.bottom:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.left = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.bottomLeading:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.left = '0'
        break
      case ComponentAlignment.bottomCenter:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.left = '50%'
        element.style.transform = 'translateX(-50%)'
        break
      case ComponentAlignment.bottomTrailing:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.right = '0'
        break
    }
  } else {
    switch (componentAlignment) {
      case ComponentAlignment.fill:
        if (
          parentComponentType !== ComponentType.scroll &&
          parentComponentType !== ComponentType.list &&
          onlyChild &&
          (isParentComponent(componentType) || componentType === ComponentType.map)
        ) {
          element.style.width = getRealWidth(getPxValue, margins)
          element.style.height = getRealHeight(getPxValue, margins)
        }
        element.style.alignSelf = 'stretch'
        break
      case ComponentAlignment.start:
        element.style.alignSelf = 'flex-start'
        break
      case ComponentAlignment.end:
        element.style.alignSelf = 'flex-end'
        break
      case ComponentAlignment.center:
        element.style.alignSelf = 'center'
        break
    }
  }

  if (width) {
    const widthValue = width.source ? await getVariableValue(width.source) : getPxValue(width.constant)
    element.style.width = `${widthValue}px`
    element.style.minWidth = `${widthValue}px`
  }

  if (height) {
    const heightValue = height.source ? await getVariableValue(height.source) : getPxValue(height.constant)
    element.style.height = `${heightValue}px`
    element.style.minHeight = `${heightValue}px`
  }

  if (widthHeightRatio) {
    const widthHeightRatioValue = widthHeightRatio.source
      ? await getVariableValue(widthHeightRatio.source)
      : getPxValue(widthHeightRatio.constant)
    const { width, height } = element.getBoundingClientRect()
    if (!height && width) {
      const heightValue = width / compression / +widthHeightRatioValue
      element.style.height = `${heightValue}px`
      element.style.minHeight = `${heightValue}px`
    }
    if (!width && height) {
      const widthValue = (height / compression) * +widthHeightRatioValue
      element.style.width = `${widthValue}px`
      element.style.minWidth = `${widthValue}px`
    }
  }

  if (componentType === ComponentType.text) {
    await createText(element, component, language, getVariableValue)
  }

  if (componentType === ComponentType.button) {
    await createButton(fromVB, element, component, resources, colorStyles, theme, language, getVariableValue)
  }

  if (componentType === ComponentType.image) {
    await createImage(fromVB, element, component, resources, colorStyles, theme, getVariableValue)
  }

  if (componentType === ComponentType.videoPlayer) {
    await createVideo(element, component, resources, getVariableValue)
  }

  if (componentType === ComponentType.themePicker) {
    createThemePicker(element, colorStyles, theme)
  }

  if (componentType === ComponentType.notificationsSettings) {
    createNotificationsSettings(element, colorStyles, theme)
  }

  if (componentType === ComponentType.carousel) {
    await createCarousel(element, component, colorStyles, theme, getVariableValue)
  }

  if (componentType === ComponentType.view) {
    if (!height && !width && !widthHeightRatio) {
      createSpacer(fromVB, element, false)
    }
  }

  if (componentType === ComponentType.spacer) {
    createSpacer(fromVB, element, !!(height || width))
  }

  if (componentType === ComponentType.scroll || componentType === ComponentType.list) {
    if (componentsDirection !== ComponentsDirection.horizontal) {
      element.style.flexGrow = '1'
    }
    element.style.overflow =
      componentsDirection === ComponentsDirection.all
        ? 'auto'
        : componentsDirection === ComponentsDirection.vertical
        ? 'hidden auto'
        : 'auto hidden'
  }

  if (componentType === ComponentType.map) {
    await createMap(element, component, screenConfig, getVariable, getVariableValue)
  }

  if (componentType === ComponentType.calendar) {
    await createCalendar(
      element,
      component,
      fontStyles,
      colorStyles,
      theme,
      screenConfig,
      getVariable,
      getVariableValue
    )
  }

  if (componentType === ComponentType.slider) {
    await createSlider(element, component, colorStyles, theme, getVariableValue)
  }

  if (componentType === ComponentType.toggle) {
    await createToggle(element, component, colorStyles, theme, getVariableValue)
  }

  if (componentType === ComponentType.progressIndicator) {
    await createProgressIndicator(element, component, colorStyles, theme, getVariableValue)
  }

  if (componentsDirection) {
    if (componentsDirection === ComponentsDirection.all) {
      const componentsSpacingValue = componentsSpacing?.source
        ? await getVariableValue(componentsSpacing.source)
        : getPxValue(componentsSpacing?.constant)
      element.style.display = 'grid'
      const columnWidth = `calc(50% - ${+componentsSpacingValue / 2}px)`
      element.style.gridTemplateColumns = `${columnWidth} ${columnWidth}`
    } else {
      const reversedValue = reversed?.source
        ? (await getVariableValue(reversed.source)) === 'true'
        : !!reversed?.constant
      element.style.display = 'flex'
      element.style.flexDirection = `${componentsDirection === ComponentsDirection.vertical ? 'column' : 'row'}${
        reversedValue ? '-reverse' : ''
      }`
    }
  }

  if (textAlignment) {
    element.style.textAlign = getCSSTextAlignment(textAlignment)
  }

  const currentFont = fontStyles.find(el => el.styleName === font?.slice(1))?.ios
  if (currentFont) {
    element.style.fontSize = `${currentFont.fontSize}px`
    element.style.fontWeight = `${currentFont.fontWeight}`
    element.style.fontStyle = currentFont.fontStyle
    element.style.letterSpacing = `${currentFont.letterSpacing}px`
    element.style.lineHeight = `${currentFont.lineHeight}%`
  }

  await setColors(
    element,
    colorStyles,
    theme,
    resources,
    getVariableValue,
    backgroundColor,
    backgroundGradient,
    backgroundPattern,
    textColor,
    borderColor
  )

  if (borderWidth) {
    const borderWidthValue = borderWidth.source
      ? await getVariableValue(borderWidth.source)
      : getPxValue(borderWidth.constant)
    element.style.borderWidth = `${borderWidthValue}px`
    element.style.borderStyle = 'solid'
  }

  if (contentPadding) {
    const { top, trailing, bottom, leading } = contentPadding.constant || {}
    element.style.padding = `${getPxValue(top)}px ${getPxValue(trailing)}px ${getPxValue(bottom)}px ${getPxValue(
      leading
    )}px`
  }

  if (margins) {
    const { top, trailing, bottom, leading } = margins
    element.style.margin = `${getPxValue(top)}px ${getPxValue(trailing)}px ${getPxValue(bottom)}px ${getPxValue(
      leading
    )}px`
  }

  if (componentsSpacing) {
    const componentsSpacingValue = componentsSpacing.source
      ? await getVariableValue(componentsSpacing.source)
      : getPxValue(componentsSpacing.constant)
    element.style.gap = `${componentsSpacingValue}px`
  }

  if (cornersRadius) {
    if (isParentComponent(componentType) || componentType === ComponentType.map) {
      element.style.overflow = 'hidden'
      if (
        parentComponentType === ComponentType.scroll ||
        parentComponentType === ComponentType.list ||
        parent.style.flexDirection === 'column'
      ) {
        element.style.flexShrink = '0'
      }
    }
    if (cornersRadius.constant === ISize.max) {
      const { height } = element.getBoundingClientRect()
      element.style.borderRadius = `${height / compression / 2}px`
    } else {
      const cornersRadiusValue = cornersRadius.source
        ? await getVariableValue(cornersRadius.source)
        : getPxValue(cornersRadius.constant)
      element.style.borderRadius = `${cornersRadiusValue}px`
    }
  }

  if (componentType === ComponentType.textInput || componentType === ComponentType.datePicker) {
    await createTextInput(
      parent,
      element,
      component,
      fontStyles,
      colorStyles,
      theme,
      componentType === ComponentType.datePicker,
      language,
      getVariableValue
    )
  }

  if (componentType === ComponentType.product) {
    const currentProduct = products.find(el => el.id === product)
    if (currentProduct) {
      const borderWidthValue = borderWidth?.source
        ? await getVariableValue(borderWidth.source)
        : getPxValue(borderWidth?.constant)
      createProduct(
        element,
        component,
        currentProduct,
        backgroundColor ? await getColor(backgroundColor, colorStyles, theme, getVariableValue) : 'transparent',
        borderColor ? await getColor(borderColor, colorStyles, theme, getVariableValue) : 'transparent',
        borderWidthValue || '2'
      )
    }
  }

  if (componentType === ComponentType.list) {
    await createList(element, component, getVariableValue, setSubComponents, 30)
  }

  return element
}
