import { Icon, Name } from 'components'
import { CMSCollectionRecord } from 'services/cms'
import { ValueType } from 'utils'

const blackImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='

interface Props {
  asset: CMSCollectionRecord
  small?: boolean
}

export const Preview: React.FC<Props> = ({ asset, small }) =>
  asset.resourceType === ValueType.image ? (
    <img
      src={asset.thumbnailUrl || asset.url}
      alt="Loading..."
      onError={(e: any) => setTimeout(() => (e.target.src = asset.url), 1000)}
      style={
        small
          ? { height: '60px', maxWidth: '120px', borderRadius: '6px', objectFit: 'cover' }
          : { maxWidth: '100%', maxHeight: '100%' }
      }
    />
  ) : asset.resourceType === ValueType.video ? (
    small ? (
      <img
        src={asset.thumbnailUrl || blackImage}
        alt="Loading..."
        onError={(e: any) => (e.target.src = blackImage)}
        style={{ width: '100px', height: '60px', borderRadius: '6px', objectFit: 'cover' }}
      />
    ) : (
      <video controls style={{ maxWidth: '100%', maxHeight: '100%' }} preload="none" poster={asset.thumbnailUrl}>
        <source src={asset.url} />
      </video>
    )
  ) : asset.resourceType === ValueType.audio ? (
    <audio controls style={{ maxWidth: '100%', maxHeight: '100%' }} preload="none">
      <source src={asset.url} />
    </audio>
  ) : (
    <p style={{ display: 'flex', alignItems: 'center' }}>
      <Icon name={Name.REPOSITORY_FILE} width={60} height={60} />
      {decodeURI(asset.url).split('/').slice(-1)[0]}
    </p>
  )
