import React from 'react'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import * as styled from './ProjectMenu.styled'

import { Icon, Name } from 'components'
import { IRoute, routes } from 'routes'
import { Notifications } from '../Notifications'

export const ProjectMenu: React.FC = () => {
  const { pathname } = useLocation()
  const { id } = useParams()

  const replacedPath = (path: string) => {
    if (id) {
      path = path.replace(':id', id)
    }
    return path
  }

  const checkActive = (route: IRoute) => {
    const path = replacedPath(route.path)
    const childPath = replacedPath(route.childPath || '')

    return childPath
      ? pathname === childPath || pathname === path
      : route.innerRoutes
      ? pathname.startsWith(path)
      : pathname === path
  }

  return (
    <styled.ControllsWrapper>
      <styled.Navigation>
        {id &&
          routes.map(
            route =>
              route.icon && (
                <styled.LinkBox key={route.path} hasHover active={checkActive(route)}>
                  <styled.Hint>{route.name}</styled.Hint>
                  <Link to={replacedPath(route.path)}>{route.icon}</Link>
                </styled.LinkBox>
              )
          )}
      </styled.Navigation>
      <styled.Navigation>
        <Notifications />
        <styled.LinkBox hasHover darkBlue_200>
          <Link to="/">
            <Icon width={24} height={24} name={Name.LEFT_SIDEBAR_HELP} />
          </Link>
        </styled.LinkBox>
      </styled.Navigation>
    </styled.ControllsWrapper>
  )
}
