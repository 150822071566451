import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const VisualAppBuilder: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M10 3H5C3.89543 3 3 3.89543 3 5V10" stroke="#476CEC" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M14 3H19C20.1046 3 21 3.89543 21 5V10" stroke="#476CEC" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M10 21H5C3.89543 21 3 20.1046 3 19V14" stroke="#476CEC" strokeWidth="2.5" strokeLinecap="round" />
    <path
      d="M10.2761 11.589C9.94663 10.7653 10.764 9.94787 11.5877 10.2773L21.0344 14.056C21.9163 14.4088 21.8667 15.6735 20.96 15.9562L17.6498 16.9881C17.3333 17.0867 17.0855 17.3346 16.9868 17.6511L15.9549 20.9612C15.6723 21.868 14.4075 21.9175 14.0548 21.0356L10.2761 11.589Z"
      fill="#476CEC"
    />
  </Svg>
)
