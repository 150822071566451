import { ReactElement } from 'react'

import { Icon, Name, Rotation } from 'components'
import { generateFirestoreId, listItemName } from 'utils'
import {
  BrandingColorType,
  CalendarStyleConstant,
  ComponentAlignment,
  ComponentStyle,
  ComponentType,
  ComponentsDirection,
  ImageLayout,
  MapStyle,
  ScreenComponent,
  TextAlignment,
  VariableSourceType,
  VariableTransformTransform,
} from '../configTypes'
import { BarComponentType, ISize } from './utils'

export interface ComponentForLeftMenu {
  name: string
  icon: ReactElement
  smallIcon: ReactElement
  componentType: ComponentType | BarComponentType | null
  config: (language: string) => ScreenComponent | { tabBarMode: true } | { topBarMode: true } | null
}

export const baseComponentsForLeftMenu: ComponentForLeftMenu[] = [
  {
    name: 'Column',
    icon: <Icon name={Name.LEFT_MENU_COLUMN} />,
    smallIcon: <Icon name={Name.LEFT_MENU_COLUMN_SMALL} />,
    componentType: ComponentType.stack,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.stack,
      componentsDirection: ComponentsDirection.vertical,
      contentPadding: { constant: { leading: '10', trailing: '10', top: '10', bottom: '10' } },
    }),
  },
  {
    name: 'Row',
    icon: <Icon name={Name.LEFT_MENU_ROW} />,
    smallIcon: <Icon name={Name.LEFT_MENU_ROW_SMALL} />,
    componentType: ComponentType.stack,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.stack,
      componentsDirection: ComponentsDirection.horizontal,
      contentPadding: { constant: { leading: '10', trailing: '10', top: '10', bottom: '10' } },
    }),
  },
  {
    name: 'Overlay',
    icon: <Icon name={Name.LEFT_MENU_OVERLAY} />,
    smallIcon: <Icon name={Name.LEFT_MENU_OVERLAY_SMALL} />,
    componentType: ComponentType.overlay,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.overlay,
    }),
  },
  {
    name: 'Button',
    icon: <Icon name={Name.LEFT_MENU_BUTTON} />,
    smallIcon: <Icon name={Name.LEFT_MENU_BUTTON_SMALL} />,
    componentType: ComponentType.button,
    config: (language: string) => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.button,
      backgroundColor: { constant: `@${BrandingColorType.primary}` },
      cornersRadius: { constant: ISize.max },
      height: { constant: ISize.buttonHeight },
      text: { key: 'key', locales: { [language]: 'Text' } },
      textAlignment: TextAlignment.center,
      textColor: { constant: `@${BrandingColorType.onPrimary}` },
      font: '@Body Bold',
    }),
  },
  {
    name: 'FAB',
    icon: <Icon name={Name.LEFT_MENU_FAB} />,
    smallIcon: <Icon name={Name.LEFT_MENU_FAB_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Text',
    icon: <Icon name={Name.LEFT_MENU_TEXT} />,
    smallIcon: <Icon name={Name.LEFT_MENU_TEXT_SMALL} />,
    componentType: ComponentType.text,
    config: (language: string) => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.text,
      text: { key: 'key', locales: { [language]: 'Text' } },
      textAlignment: TextAlignment.leading,
      textColor: { constant: `@${BrandingColorType.onBackground}` },
      font: '@Body',
    }),
  },
  {
    name: 'Text Input',
    icon: <Icon name={Name.LEFT_MENU_FIELD} />,
    smallIcon: <Icon name={Name.LEFT_MENU_FIELD_SMALL} />,
    componentType: ComponentType.textInput,
    config: (language: string) => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      backgroundColor: { constant: `@${BrandingColorType.background}` },
      borderColor: { constant: `@${BrandingColorType.neutral}` },
      borderWidth: { constant: '1' },
      componentType: ComponentType.textInput,
      cornersRadius: { constant: '8' },
      font: '@Body',
      height: { constant: ISize.buttonHeight },
      multiline: { constant: false },
      placeholderText: { key: 'key', locales: { [language]: 'Type here' } },
      placeholderTextColor: { constant: `@${BrandingColorType.neutral}` },
      placeholderTextFont: '@Body',
      textAlignment: TextAlignment.leading,
      textColor: { constant: `@${BrandingColorType.onBackground}` },
    }),
  },
  {
    name: 'List',
    icon: <Icon name={Name.LEFT_MENU_COLLECTION} />,
    smallIcon: <Icon name={Name.LEFT_MENU_COLLECTION_SMALL} />,
    componentType: ComponentType.list,
    config: (language: string) => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.list,
      subComponents: [
        {
          id: generateFirestoreId(),
          componentAlignment: ComponentAlignment.fill,
          componentType: ComponentType.stack,
          height: { constant: '75' },
          margins: { leading: '20', trailing: '20' },
          subComponents: [
            {
              id: generateFirestoreId(),
              componentAlignment: ComponentAlignment.fill,
              name: '',
              componentType: ComponentType.overlay,
              cornersRadius: { constant: '8' },
              width: { constant: '130' },
              height: { constant: '75' },
              subComponents: [
                {
                  id: generateFirestoreId(),
                  name: '',
                  componentType: ComponentType.image,
                  componentAlignment: ComponentAlignment.fill,
                  image: {
                    source: { type: VariableSourceType.localVariable, variableName: 'contextKey', fieldName: 'cover' },
                  },
                  imageLayout: ImageLayout.fill,
                },
                {
                  id: generateFirestoreId(),
                  name: '',
                  componentType: ComponentType.overlay,
                  backgroundColor: { constant: `@${BrandingColorType.semiTransparentBackground}` },
                  cornersRadius: { constant: '3' },
                  margins: { trailing: '5', bottom: '5' },
                  subComponents: [
                    {
                      id: generateFirestoreId(),
                      name: '',
                      componentType: ComponentType.text,
                      margins: { leading: '4', trailing: '4', top: '2', bottom: '2' },
                      componentAlignment: ComponentAlignment.fill,
                      text: {
                        key: 'key',
                        locales: { [language]: '$1' },
                        values: [
                          {
                            type: VariableSourceType.localVariable,
                            variableName: 'contextKey',
                            fieldName: 'video',
                            transforms: [
                              { transform: VariableTransformTransform.field, fieldName: 'duration' },
                              { transform: VariableTransformTransform.formatTimeInterval },
                            ],
                          },
                        ],
                      },
                      textAlignment: TextAlignment.leading,
                      textColor: { constant: `@${BrandingColorType.white}` },
                      font: '@Footnote',
                      numberOfLines: { constant: '3' },
                    },
                  ],
                  componentAlignment: ComponentAlignment.bottomTrailing,
                },
              ],
            },
            {
              id: generateFirestoreId(),
              componentAlignment: ComponentAlignment.fill,
              name: '',
              componentType: ComponentType.text,
              text: {
                key: 'key',
                locales: { [language]: '$1' },
                values: [{ type: VariableSourceType.localVariable, variableName: 'contextKey', fieldName: 'title' }],
              },
              textAlignment: TextAlignment.leading,
              textColor: { constant: `@${BrandingColorType.onBackground}` },
              font: '@Headline Semibold',
              numberOfLines: { constant: '3' },
            },
          ],
          componentsSpacing: { constant: '10' },
          componentsDirection: ComponentsDirection.horizontal,
          name: listItemName,
        },
      ],
      componentsSpacing: { constant: '10' },
      componentsDirection: ComponentsDirection.vertical,
      showScrollBar: { constant: false },
      listItemContextKey: 'contextKey',
      listItems: { source: { type: VariableSourceType.collection, collection: { name: 'promos' } } },
      componentStyle: ComponentStyle.column,
    }),
  },
  {
    name: 'Image',
    icon: <Icon name={Name.LEFT_MENU_IMAGE} />,
    smallIcon: <Icon name={Name.LEFT_MENU_IMAGE_SMALL} />,
    componentType: ComponentType.image,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.image,
    }),
  },
  {
    name: 'Image Circle',
    icon: <Icon name={Name.LEFT_MENU_IMAGE_CIRCLE} />,
    smallIcon: <Icon name={Name.LEFT_MENU_IMAGE_CIRCLE_SMALL} />,
    componentType: ComponentType.image,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.image,
      cornersRadius: { constant: ISize.max },
      widthHeightRatio: { constant: '1' },
    }),
  },
  {
    name: 'Video',
    icon: <Icon name={Name.LEFT_MENU_VIDEO} />,
    smallIcon: <Icon name={Name.LEFT_MENU_VIDEO_SMALL} />,
    componentType: ComponentType.videoPlayer,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.videoPlayer,
      backgroundColor: { constant: `@${BrandingColorType.black}` },
    }),
  },
  {
    name: 'Carousel',
    icon: <Icon name={Name.LEFT_MENU_CAROUSER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_CAROUSER_SMALL} />,
    componentType: ComponentType.carousel,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.carousel,
    }),
  },
  {
    name: 'Toggle',
    icon: <Icon name={Name.LEFT_MENU_TOGGLE} />,
    smallIcon: <Icon name={Name.LEFT_MENU_TOGGLE_SMALL} />,
    componentType: ComponentType.toggle,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.start,
      name: '',
      componentType: ComponentType.toggle,
    }),
  },
  {
    name: 'Checkbox',
    icon: <Icon name={Name.LEFT_MENU_CHECKBOX} />,
    smallIcon: <Icon name={Name.LEFT_MENU_CHECKBOX_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'View',
    icon: <Icon name={Name.LEFT_MENU_SPACER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_SPACER_SMALL} />,
    componentType: ComponentType.view,
    config: () => null,
  },
  {
    name: 'Spacer',
    icon: <Icon name={Name.LEFT_MENU_SPACER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_SPACER_SMALL} />,
    componentType: ComponentType.spacer,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.spacer,
    }),
  },
  {
    name: 'Divider',
    icon: <Icon name={Name.LEFT_MENU_DIVIDER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_DIVIDER_SMALL} />,
    componentType: null,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      backgroundColor: { constant: `@${BrandingColorType.surfaceVariant}` },
      componentType: ComponentType.view,
      height: { constant: ISize.thinSeparator },
    }),
  },
  {
    name: 'DividerVertical',
    icon: <Icon name={Name.LEFT_MENU_DIVIDER} rotation={Rotation.DOWN} />,
    smallIcon: <Icon name={Name.LEFT_MENU_DIVIDER_SMALL} rotation={Rotation.DOWN} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Subscription',
    icon: <Icon name={Name.LEFT_MENU_SUBSCRIPTION} />,
    smallIcon: <Icon name={Name.LEFT_MENU_SUBSCRIPTION_SMALL} />,
    componentType: ComponentType.product,
    config: () => ({
      componentType: ComponentType.product,
      name: '',
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      backgroundColor: { constant: `@${BrandingColorType.primaryContainer}` },
      borderColor: { constant: `@${BrandingColorType.onPrimaryContainer}` },
      product: 'purchase.recurrent.1',
      selected: { constant: true },
      showDiscount: { constant: true },
    }),
  },
  {
    name: 'Paywall',
    icon: <Icon name={Name.LEFT_MENU_PAYWALL} />,
    smallIcon: <Icon name={Name.LEFT_MENU_PAYWALL_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Pager',
    icon: <Icon name={Name.LEFT_MENU_PAGER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_PAGER_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Shadow',
    icon: <Icon name={Name.LEFT_MENU_SHADOW} />,
    smallIcon: <Icon name={Name.LEFT_MENU_SHADOW_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'WebView',
    icon: <Icon name={Name.LEFT_MENU_WEBVIEW} />,
    smallIcon: <Icon name={Name.LEFT_MENU_WEBVIEW_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Map',
    icon: <Icon name={Name.LEFT_MENU_MAP} />,
    smallIcon: <Icon name={Name.LEFT_MENU_MAP_SMALL} />,
    componentType: ComponentType.map,
    config: () => ({
      componentType: ComponentType.map,
      name: '',
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      mapStyle: MapStyle.satellite,
      showUserLocation: { constant: true },
      coordinate: { constant: { latitude: '38.736946', longitude: '-9.142685' } },
      zoom: { constant: '5' },
      listItemContextKey: 'contextKey',
    }),
  },
  {
    name: 'Calendar',
    icon: <Icon name={Name.LEFT_MENU_CALENDAR} />,
    smallIcon: <Icon name={Name.LEFT_MENU_CALENDAR_SMALL} />,
    componentType: ComponentType.calendar,
    config: () => ({
      componentType: ComponentType.calendar,
      name: 'calendar',
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      showHeader: { constant: true },
      calendarStyle: { constant: CalendarStyleConstant.weekly },
      contentPadding: { constant: { leading: '16', trailing: '16' } },
      showWeekdays: { constant: true },
      headerFont: '@Title 3 Bold',
      font: '@Footnote Bold',
      textColor: { constant: '@onBackground' },
      headerColor: { constant: '@onBackground' },
      listItemContextKey: 'contextKey',
    }),
  },
  {
    name: 'Counter',
    icon: <Icon name={Name.LEFT_MENU_COUNTER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_COUNTER_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Slider',
    icon: <Icon name={Name.LEFT_MENU_SLIDER} />,
    smallIcon: <Icon name={Name.LEFT_MENU_SLIDER_SMALL} />,
    componentType: ComponentType.slider,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.slider,
      value: { constant: '20' },
    }),
  },
  {
    name: 'Progress',
    icon: <Icon name={Name.LEFT_MENU_PROGRESS} />,
    smallIcon: <Icon name={Name.LEFT_MENU_PROGRESS_SMALL} />,
    componentType: ComponentType.progressIndicator,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.progressIndicator,
    }),
  },
  {
    name: 'Scroll',
    icon: <Icon name={Name.LEFT_MENU_SCROLL} />,
    smallIcon: <Icon name={Name.LEFT_MENU_SCROLL_SMALL} />,
    componentType: ComponentType.scroll,
    config: () => ({
      id: generateFirestoreId(),
      componentAlignment: ComponentAlignment.fill,
      name: '',
      componentType: ComponentType.scroll,
      componentsDirection: ComponentsDirection.vertical,
    }),
  },
  {
    name: 'Tab',
    icon: <Icon name={Name.LEFT_MENU_TAB} />,
    smallIcon: <Icon name={Name.LEFT_MENU_TAB_SMALL} />,
    componentType: BarComponentType.tab,
    config: () => null,
  },
]

export const systemComponentsForLeftMenu: ComponentForLeftMenu[] = [
  {
    name: 'TabBar',
    icon: <Icon name={Name.LEFT_MENU_TABBAR} />,
    smallIcon: <Icon name={Name.LEFT_MENU_TABBAR_SMALL} />,
    componentType: BarComponentType.tabBar,
    config: () => ({
      tabBarMode: true,
    }),
  },
  {
    name: 'TopBar',
    icon: <Icon name={Name.LEFT_MENU_TOPBAR} />,
    smallIcon: <Icon name={Name.LEFT_MENU_TOPBAR_SMALL} />,
    componentType: BarComponentType.topBar,
    config: () => ({
      topBarMode: true,
    }),
  },
  {
    name: 'Chat',
    icon: <Icon name={Name.LEFT_MENU_CHAT} />,
    smallIcon: <Icon name={Name.LEFT_MENU_CHAT_SMALL} />,
    componentType: null,
    config: () => null,
  },
  {
    name: 'Conversations',
    icon: <Icon name={Name.LEFT_MENU_CONVERSATIONS} />,
    smallIcon: <Icon name={Name.LEFT_MENU_CONVERSATIONS_SMALL} />,
    componentType: null,
    config: () => null,
  },
]

const componentsForLeftMenu = [...baseComponentsForLeftMenu, ...systemComponentsForLeftMenu]

export const getComponentSmallIconByType = (componentType: ComponentType | BarComponentType) =>
  componentsForLeftMenu.find(el => el.componentType === componentType)?.smallIcon || (
    <Icon name={Name.LEFT_MENU_CONTAINER_SMALL} />
  )
