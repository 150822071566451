import React, { useState } from 'react'

import { CustomSection, CustomSectionButton, CustomSectionTitle, RemoveContent } from 'components'
import { Workspace } from 'hooks'

interface Props {
  workspace: Workspace
}

export const DangerZone: React.FC<Props> = ({ workspace }) => {
  const [toRemove, setToRemove] = useState<Workspace | null>(null)

  const remove = (callback: () => void) => {
    if (toRemove) {
      callback()
    }
  }

  return (
    <>
      <CustomSection style={{ paddingBottom: '25px' }}>
        <CustomSectionTitle>Danger zone</CustomSectionTitle>
        <CustomSectionButton
          title="Delete workspace"
          subTitle="This action cannot be undone."
          onClick={() => setToRemove(workspace)}
          buttonText="Delete workspace"
          deleteButton
        />
      </CustomSection>
      <RemoveContent
        toRemove={toRemove}
        title="Delete workspace"
        text="You are about to delete the workspace <b>{name}</b>. To make sure, enter the workspace name below"
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}
