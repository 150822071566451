import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './Builds.styled'

import { NoContent } from 'components'
import { ProgressContext } from 'context'
import { ActivityItem, ActivityItemType, Build } from 'pages/Repository/components'
import { firestore } from 'services/firebase'

export const Builds: React.FC = () => {
  const { id, repositoryId } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [builds, setBuilds] = useState<Build[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    startLoader()
    const unsubscribe = firestore.collection(`projects/${id}/applications/${repositoryId}/builds`).onSnapshot({
      next: res => {
        const builds = res.docs.map(el => ({ id: el.id, ...el.data() })) as Build[]
        builds.sort((a, b) => +new Date(b.created_on) - +new Date(a.created_on))
        setBuilds(builds)
        stopLoader()
        setWait(false)
      },
      error: err => {
        toast(err)
        stopLoader()
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <styled.Content>
      {!wait && !builds.length ? (
        <NoContent text="No builds yet." />
      ) : (
        <Box display="flex" flexDirection="column" gap="15px" p="30px 0 60px" maxWidth="950px">
          {builds.map(el => (
            <ActivityItem key={el.id} item={el} type={ActivityItemType.BUILD} />
          ))}
        </Box>
      )}
    </styled.Content>
  )
}
