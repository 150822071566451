import React from 'react'
import { ISvg, Svg } from '../Svg'

const Subscription: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M11.3068 18.4281C12.4726 18.4281 13.2016 17.8435 13.2016 16.9163C13.2016 16.1974 12.7715 15.7875 11.7503 15.5759L11.2295 15.4684C10.6248 15.3407 10.383 15.1291 10.383 14.7998C10.383 14.3933 10.7659 14.138 11.3035 14.138C11.757 14.138 12.103 14.3127 12.2676 14.7226C12.3583 14.9107 12.4826 14.998 12.6842 14.998C12.9093 14.998 13.0605 14.8536 13.0605 14.6419C13.0605 14.5613 13.0504 14.4975 13.0302 14.4437C12.832 13.8021 12.1668 13.4191 11.3102 13.4191C10.2587 13.4191 9.5095 13.9969 9.5095 14.8502C9.5095 15.5591 9.96303 16.016 10.9339 16.2142L11.4546 16.3217C12.0862 16.4527 12.3281 16.6677 12.3281 17.0171C12.3281 17.4202 11.9182 17.7091 11.3337 17.7091C10.8163 17.7091 10.3998 17.521 10.2486 17.1011C10.1545 16.9062 10.0336 16.829 9.84209 16.829C9.61364 16.829 9.45911 16.9835 9.45911 17.2254C9.45911 17.306 9.4759 17.3933 9.50614 17.474C9.69427 18.0249 10.3393 18.4281 11.3068 18.4281Z"
      fill="#7F899E"
    />
    <path
      d="M15.8455 18.4281C17.075 18.4281 17.8376 17.6957 17.8376 16.6375V13.8928C17.8376 13.6072 17.6764 13.4426 17.4076 13.4426C17.1355 13.4426 16.9743 13.6072 16.9743 13.8928V16.5602C16.9743 17.222 16.5644 17.6722 15.8455 17.6722C15.1265 17.6722 14.72 17.222 14.72 16.5602V13.8928C14.72 13.6072 14.5588 13.4426 14.2867 13.4426C14.0179 13.4426 13.8533 13.6072 13.8533 13.8928V16.6375C13.8533 17.6957 14.6193 18.4281 15.8455 18.4281Z"
      fill="#7F899E"
    />
    <path
      d="M19.131 18.3474H20.7301C21.7649 18.3474 22.3931 17.8234 22.3931 16.9701C22.3931 16.3284 21.9328 15.8547 21.2744 15.7909V15.764C21.7716 15.6834 22.1579 15.2298 22.1579 14.7091C22.1579 13.9734 21.5902 13.4997 20.71 13.4997H19.131C18.8623 13.4997 18.6977 13.6677 18.6977 13.9499V17.8973C18.6977 18.1795 18.8623 18.3474 19.131 18.3474ZM19.5644 15.5423V14.175H20.4916C21.009 14.175 21.3013 14.4135 21.3013 14.8368C21.3013 15.287 20.9619 15.5423 20.3572 15.5423H19.5644ZM19.5644 17.6722V16.1571H20.495C21.1602 16.1571 21.5095 16.4157 21.5095 16.9096C21.5095 17.4068 21.1702 17.6722 20.5319 17.6722H19.5644Z"
      fill="#7F899E"
    />
    <rect x="2.25" y="9.25" width="27.5" height="13.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Subscription
