import { useSearchParams } from 'react-router-dom'

import { GithubConnectionModal } from 'components'
import { Integration } from 'hooks'

export const connectGithubFlag = 'connect-github'

export const useGithubConnectionModal = (github?: Integration, name?: string) => {
  const [searchParams] = useSearchParams()
  const connectGithub = searchParams.get(connectGithubFlag)

  return github ? <GithubConnectionModal github={github} name={name} active={!!connectGithub} /> : <></>
}
