import React from 'react'

import * as styled from './Checkbox.styled'

interface Props {
  value?: boolean
  onChange?: (val: boolean) => void
  disabled?: boolean
}

export const Checkbox: React.FC<Props> = ({ value, onChange, disabled }) => (
  <styled.Checkbox type="checkbox" checked={value} onChange={e => onChange?.(e.target.checked)} disabled={disabled} />
)
