import styled from 'styled-components'

export const Checkbox = styled.input`
  margin: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 3px;
  -webkit-appearance: none;
  background-color: transparent;
  &::before {
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1.5px ${props => props.theme.colors.black_300};
  }
  &:checked {
    background-color: ${props => props.theme.colors.darkText};
  }
  &:checked::before {
    box-shadow: none;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }
  &:disabled {
    opacity: 0.84;
    cursor: not-allowed;
  }
`
