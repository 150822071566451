import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ProjectPlan, WorkspacePlan } from 'hooks'

export const Plan = styled(Link)<{ plan: ProjectPlan | WorkspacePlan }>`
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  color: ${props =>
    props.plan === ProjectPlan.BASIC || props.plan === WorkspacePlan.BASIC
      ? '#0099ff'
      : props.theme.colors.darkBlue_800_const};
  font-size: 14px;
  font-weight: ${props => props.theme.weights.medium};
  text-transform: uppercase;
  text-decoration: none;
`
