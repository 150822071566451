import React from 'react'
import { ISvg, Svg } from '../Svg'

const ProvidersApple: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="black" stroke="#E1E3E1" />
    <path
      d="M23.7764 10H23.916C24.028 11.4104 23.4997 12.4643 22.8577 13.2275C22.2277 13.9854 21.3651 14.7205 19.9698 14.609C19.8768 13.2187 20.4059 12.243 21.0471 11.4816C21.6417 10.772 22.7319 10.1405 23.7764 10ZM28 24.6805V24.7201C27.6079 25.9303 27.0486 26.9675 26.366 27.93C25.743 28.8038 24.9794 29.9798 23.6161 29.9798C22.438 29.9798 21.6555 29.2078 20.4481 29.1868C19.171 29.1657 18.4686 29.8323 17.3009 30H16.9027C16.0452 29.8735 15.3532 29.1815 14.8491 28.5579C13.3625 26.7154 12.2137 24.3354 12 21.2897V20.3948C12.0905 18.215 13.1298 16.4427 14.5113 15.5838C15.2403 15.1271 16.2426 14.7381 17.3586 14.912C17.8369 14.9875 18.3255 15.1543 18.7539 15.3195C19.1598 15.4784 19.6673 15.7603 20.1482 15.7454C20.474 15.7357 20.798 15.5627 21.1264 15.4407C22.0881 15.0867 23.0309 14.681 24.2736 14.8716C25.7671 15.1017 26.8271 15.7779 27.4821 16.8212C26.2187 17.6406 25.2199 18.8754 25.3905 20.9841C25.5422 22.8995 26.6349 24.0201 28 24.6805Z"
      fill="white"
    />
  </Svg>
)

export default ProvidersApple
