import styled from 'styled-components'

export const PreviewWrapper = styled.div`
  position: absolute;
  top: -60px;
  right: 0px;
  pointer-events: none;
  transform: scale(0.77);
  @media (max-height: 940px) {
    top: -93px;
  }
  @media (max-height: 850px) {
    top: -126px;
  }
  @media (max-height: 760px) {
    top: -160px;
  }
  @media (max-height: 670px) {
    top: -193px;
  }
`

export const Lang = styled.span`
  font-weight: 400;
  color: ${props => props.theme.colors.grey1};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${props => props.theme.colors.iconHover};
  }
`
