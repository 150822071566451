import React from 'react'
import { ISvg, Svg } from '../Svg'

const Fab: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="6.5625" stroke="#7F899E" strokeWidth="0.875" />
    <path d="M8 10.8L8 5.20002" stroke="#7F899E" strokeWidth="0.875" strokeLinecap="round" />
    <path d="M10.7999 8L5.19993 8" stroke="#7F899E" strokeWidth="0.875" strokeLinecap="round" />
  </Svg>
)

export default Fab
