import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { AICommon, Loader, LoaderVariant } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { clearVariables, useHistoryState, useProject } from 'hooks'
import { EmptyTopPanel, TopPanel } from 'partials'
import { database } from 'services/firebase'
import {
  Config,
  getLanguageFromStorage,
  getLanguagesController,
  getThemeFromStorage,
  isValidConfig,
  setLanguagesController,
  themeKey,
  Warning,
} from 'utils'
import { MobileMode } from './components'

interface Props {
  isMobile: boolean
}

export const FetchProjectWrapper: React.FC<Props> = ({ isMobile }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const themeContext = useContext(ThemeContext)
  const { id } = useParams()
  const { project, projectWait } = useProject(id as string)
  const [s, setState, undo, redo] = useHistoryState({})
  const state: Config = s
  const [warnings, setWarnings] = useState<Warning[]>([])
  const [configWait, setConfigWait] = useState(true)
  const [isConfigSnapshot, setIsConfigSnapshot] = useState(false)
  const configRef = database.ref(`projects/${id}/configurations/main`)
  const [language, setLanguage] = useState(getLanguageFromStorage(id as string))
  const [theme, setTheme] = useState(getThemeFromStorage())

  useEffect(() => {
    if (state.localization) {
      const { languages = [], primaryLanguage } = state.localization
      if (!languages.includes(language)) {
        setLanguage(primaryLanguage)
      }
    }
  }, [state.localization])

  useEffect(() => {
    const controller = getLanguagesController()
    controller[id as string] = language
    setLanguagesController(controller)
  }, [language])

  useEffect(() => {
    localStorage.setItem(themeKey, theme)
  }, [theme])

  useEffect(() => {
    startLoader()
    configRef.on('value', snapshot => {
      const state = snapshot.val()
      if (state) {
        console.log('GET MAIN CONFIG')
        setIsConfigSnapshot(true)
        setState(state)
        setWarnings(isValidConfig(state))
        setConfigWait(false)
        setTimeout(() => setIsConfigSnapshot(false), 0)
      } else {
        toast('No main config found')
      }
      stopLoader()
    })
    return () => {
      configRef.off('value')
      clearVariables()
    }
  }, [])

  useEffect(() => {
    if (!isConfigSnapshot && !configWait) {
      const timer = setTimeout(() => {
        startLoader()
        console.log('SET MAIN CONFIG')
        configRef
          .set(JSON.parse(JSON.stringify(state)) as Config)
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(state)])

  return projectWait || configWait ? (
    <>
      <EmptyTopPanel />
      <Box height="100%" bgcolor={themeContext.colors.secondaryBackground}>
        <Loader variant={LoaderVariant.PACMAN} />
      </Box>
    </>
  ) : (
    <ProjectContext.Provider
      value={{ project, state, setState, undo, redo, language, setLanguage, theme, setTheme, warnings }}
    >
      <TopPanel isMobile={isMobile} />
      {isMobile ? <MobileMode /> : <Outlet />}
      <AICommon />
    </ProjectContext.Provider>
  )
}
