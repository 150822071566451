import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { Navigation } from './components'

export const Settings: React.FC = () => {
  useEffect(() => {
    document.title = 'Settings | CodePlatform'
  }, [])

  return (
    <Box display="flex" justifyContent="space-between">
      <Navigation />
      <Outlet />
    </Box>
  )
}
