import React from 'react'
import { ISvg, Svg } from '../Svg'

const Delete: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M4 7V12C4 13.1046 4.89543 14 6 14H10C11.1046 14 12 13.1046 12 12V7"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 4L10 2.5C10 2.22386 9.77614 2 9.5 2L6.5 2C6.22386 2 6 2.22386 6 2.5L6 4"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M3 4H13" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Delete
