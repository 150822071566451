import React from 'react'
import { ISvg, Svg } from '../Svg'

const WebView: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.0031 9.69203C2.3408 9.69203 2.53989 9.51814 2.63818 9.13507L3.05148 7.54737H3.07164L3.48495 9.13507C3.58828 9.52822 3.77729 9.69203 4.12759 9.69203C4.46025 9.69203 4.64674 9.5257 4.75259 9.14011L5.38515 6.78628C5.42295 6.64767 5.44564 6.49898 5.44564 6.40574C5.44564 6.10332 5.25158 5.92943 4.91892 5.92943C4.63918 5.92943 4.47285 6.08316 4.40985 6.40322L4.03687 8.29082H4.0167L3.56055 6.35282C3.49503 6.0756 3.32366 5.92943 3.06156 5.92943C2.78939 5.92943 2.63062 6.063 2.56257 6.35282L2.10642 8.29082H2.08626L1.71328 6.40322C1.65027 6.08316 1.48394 5.92943 1.2042 5.92943C0.871543 5.92943 0.67749 6.10332 0.67749 6.40574C0.67749 6.5015 0.702692 6.65271 0.737974 6.78628L1.36801 9.13255C1.47386 9.52822 1.66035 9.69203 2.0031 9.69203Z"
      fill="#7F899E"
    />
    <path
      d="M6.9416 9.69203C7.2793 9.69203 7.4784 9.51814 7.57668 9.13507L7.98999 7.54737H8.01015L8.42346 9.13507C8.52678 9.52822 8.7158 9.69203 9.0661 9.69203C9.39876 9.69203 9.58525 9.5257 9.6911 9.14011L10.3237 6.78628C10.3615 6.64767 10.3841 6.49898 10.3841 6.40574C10.3841 6.10332 10.1901 5.92943 9.85743 5.92943C9.57769 5.92943 9.41136 6.08316 9.34836 6.40322L8.97537 8.29082H8.95521L8.49906 6.35282C8.43354 6.0756 8.26217 5.92943 8.00007 5.92943C7.72789 5.92943 7.56912 6.063 7.50108 6.35282L7.04493 8.29082H7.02477L6.65178 6.40322C6.58878 6.08316 6.42245 5.92943 6.14271 5.92943C5.81005 5.92943 5.616 6.10332 5.616 6.40574C5.616 6.5015 5.6412 6.65271 5.67648 6.78628L6.30652 9.13255C6.41237 9.52822 6.59886 9.69203 6.9416 9.69203Z"
      fill="#7F899E"
    />
    <path
      d="M11.8801 9.69203C12.2178 9.69203 12.4169 9.51814 12.5152 9.13507L12.9285 7.54737H12.9487L13.362 9.13507C13.4653 9.52822 13.6543 9.69203 14.0046 9.69203C14.3373 9.69203 14.5238 9.5257 14.6296 9.14011L15.2622 6.78628C15.3 6.64767 15.3227 6.49898 15.3227 6.40574C15.3227 6.10332 15.1286 5.92943 14.7959 5.92943C14.5162 5.92943 14.3499 6.08316 14.2869 6.40322L13.9139 8.29082H13.8937L13.4376 6.35282C13.372 6.0756 13.2007 5.92943 12.9386 5.92943C12.6664 5.92943 12.5076 6.063 12.4396 6.35282L11.9834 8.29082H11.9633L11.5903 6.40322C11.5273 6.08316 11.361 5.92943 11.0812 5.92943C10.7486 5.92943 10.5545 6.10332 10.5545 6.40574C10.5545 6.5015 10.5797 6.65271 10.615 6.78628L11.245 9.13255C11.3509 9.52822 11.5374 9.69203 11.8801 9.69203Z"
      fill="#7F899E"
    />
  </Svg>
)

export default WebView
