import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './Fields.styled'

import { Crumbs, Icon, Name, RemoveContent, Text, Type } from 'components'
import { CMSCollectionProperty } from 'services/cms'
import { Collection, ValueType, generateFirestoreId } from 'utils'
import { Movable } from './Movable'

interface Props {
  currentColection: Collection
  properties: CMSCollectionProperty[]
  setProperties: React.Dispatch<React.SetStateAction<CMSCollectionProperty[]>>
  propertiesToRemoveIds: string[]
  setPropertiesToRemoveIds: React.Dispatch<React.SetStateAction<string[]>>
  activePropertyId: string
  setActivePropertyId: React.Dispatch<React.SetStateAction<string>>
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const Fields: React.FC<Props> = ({
  currentColection,
  properties,
  setProperties,
  propertiesToRemoveIds,
  setPropertiesToRemoveIds,
  activePropertyId,
  setActivePropertyId,
  setIsSchemaPage,
}) => {
  const themeContext = useContext(ThemeContext)
  const [toRemove, setToRemove] = useState<CMSCollectionProperty | null>(null)

  const addField = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const newProperty: CMSCollectionProperty = {
      id: generateFirestoreId(),
      name: `field${properties.length + 1}`,
      type: ValueType.string,
      position: 0,
    }
    setProperties(properties => [...properties, newProperty])
    setActivePropertyId(newProperty.id)
    ;(e.target as HTMLDivElement).scrollIntoView({ behavior: 'smooth' })
  }

  const removeField = (callback: () => void) => {
    if (toRemove) {
      setPropertiesToRemoveIds(ids => [...ids, toRemove.id])
      callback()
    }
  }

  const restoreField = (propertyId: string) => setPropertiesToRemoveIds(ids => ids.filter(el => el !== propertyId))

  return (
    <>
      <styled.Container onClick={() => setActivePropertyId('')}>
        <Box width="100%" maxWidth="800px">
          <Crumbs
            firstCrumbTitle={currentColection.name.split('/').slice(-3).join(' / ')}
            secondCrumbTitle="Fields"
            onFirstCrumbClick={() => setIsSchemaPage(false)}
          />
          <Text type={Type.SUB_TITLE} color={themeContext.colors.darkText1}>
            Manage collection schema. Select filed to get more settings.
          </Text>
        </Box>
        <Movable
          properties={properties}
          setProperties={setProperties}
          propertiesToRemoveIds={propertiesToRemoveIds}
          activePropertyId={activePropertyId}
          setActivePropertyId={setActivePropertyId}
          restoreField={restoreField}
          setToRemove={setToRemove}
        />
        <styled.AddField onClick={addField}>
          <styled.Property>
            <Icon name={Name.RIGHT_SIDEBAR_ADD} /> ADD FIELD
          </styled.Property>
        </styled.AddField>
      </styled.Container>
      <RemoveContent
        toRemove={toRemove}
        title="Delete schema field"
        text={`You are about to delete the field <b>{name}</b> in all records associated with <b>${currentColection.name}</b>. To make sure, enter the field name below`}
        buttonTitle="Delete field"
        clean={() => setToRemove(null)}
        remove={removeField}
        nameConfirmation
      />
    </>
  )
}
