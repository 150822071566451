import { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from 'components/Menu/Menu.styled'

import { Icon, Name, RemoveContent } from 'components'
import { ProgressContext } from 'context'
import { CMSService, assetsId, getParentFolderName, rootFolderName } from 'services/cms'
import { Collection } from 'utils'
import { AddCollection } from '../AddCollection'
import { Branch, FoldersItem } from './FoldersItem'

interface Props {
  folders: Collection[]
  currentFolder?: Collection
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const FoldersItems: React.FC<Props> = ({ folders, currentFolder, setIsSchemaPage }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [addFolder, setAddFolder] = useState(false)
  const [toRemove, setToRemove] = useState<Collection | null>(null)

  const getFoldersTree = (lvl: number, root?: string): Branch[] =>
    folders
      .filter(el => el.name.split('/').length === lvl + 1 && (root ? el.name.startsWith(`${root}/`) : true))
      .map(
        el =>
          ({
            name: el.name,
            isSystem: !!el.isSystem,
            children: getFoldersTree(lvl + 1, el.name),
          } as Branch)
      )

  const foldersTree = useMemo(() => getFoldersTree(0), [folders])

  const openRecords = (fId: string) => {
    setIsSchemaPage(false)
    navigate(`/projects/${id}/collections/${assetsId}?folder=${fId}`)
  }

  const postFolder = (name: string, callback: () => void) => {
    if (name) {
      const fullName = currentFolder?.name ? `${currentFolder.name}/${name}` : `${rootFolderName}/${name}`
      if (folders.find(el => el.name === fullName)) {
        toast(`You cannot create folder named ${name}`)
        callback()
      } else {
        startLoader()
        CMSService.postFolder(id as string, fullName)
          .then(res => navigate(`/projects/${id}/collections/${assetsId}?folder=${res.data.id}`))
          .catch(err => toast(err))
          .finally(() => {
            callback()
            stopLoader()
          })
      }
    }
  }

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      CMSService.deleteFolder(id as string, toRemove.name)
        .then(() => {
          if (currentFolder?.name === toRemove.name) {
            const parentFolderId = folders.find(el => el.name === getParentFolderName(currentFolder.name))?.name
            navigate(`/projects/${id}/collections/${assetsId}?folder=${parentFolderId || rootFolderName}`)
          }
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <styled.NavHead>
        <styled.Title>Assets</styled.Title>
        <Icon name={Name.RIGHT_SIDEBAR_ADD} onClick={() => setAddFolder(true)} />
      </styled.NavHead>
      <styled.NavBody>
        {foldersTree.map(el => (
          <FoldersItem
            key={el.name}
            folder={el}
            currentFolder={currentFolder}
            openRecords={openRecords}
            setToRemove={setToRemove}
            lvl={0}
          />
        ))}
      </styled.NavBody>
      <AddCollection
        active={addFolder}
        close={() => setAddFolder(false)}
        onAdd={postFolder}
        folder
        currentFolder={currentFolder}
      />
      <RemoveContent
        toRemove={toRemove}
        title="Delete folder"
        text="You are about to delete the folder <b>{name}</b>. To make sure, enter the folder name below"
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}
