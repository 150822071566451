import React from 'react'
import { ISvg, Svg } from '../Svg'

const AddAsset: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#9A9A9A" fillOpacity="0.15" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 5.25C11.4142 5.25 11.75 5.58579 11.75 6V10.25H16C16.4142 10.25 16.75 10.5858 16.75 11C16.75 11.4142 16.4142 11.75 16 11.75H11.75V16C11.75 16.4142 11.4142 16.75 11 16.75C10.5858 16.75 10.25 16.4142 10.25 16V11.75H6C5.58579 11.75 5.25 11.4142 5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H10.25V6C10.25 5.58579 10.5858 5.25 11 5.25Z"
      fill="#7F899E"
    />
  </Svg>
)

export default AddAsset
