import { Box } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as styled from '../../GithubConnectionModal/GithubConnectionModal.styled'

import { Button, ButtonColor, ButtonSize, Icon, Loader, LoaderVariant, Name, Rotation, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { ProjectsService } from 'services/projects'
import { Terms } from './Terms'

interface Props {
  token?: string
  teams?: { id: string; name: string }[]
  close: () => void
}

export const Team: React.FC<Props> = ({ token, teams, close }) => {
  const themeContext = useContext(ThemeContext)
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const [teamId, setTeamId] = useState(teams?.[0]?.id || '')
  const [wait, setWait] = useState(false)
  const [teamsOpen, setTeamsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setTeamsOpen(false))

  const createAppstoreIntegration = () => {
    if (teamId && token) {
      setWait(true)
      ProjectsService.createAppstoreIntegration(id as string, token, teamId)
        .catch(err => toast(err))
        .finally(() => setWait(false))
    }
  }

  return wait ? (
    <Box height="388px">
      <Loader variant={LoaderVariant.PACMAN} />
    </Box>
  ) : (
    <>
      <styled.Content>
        <styled.SubTitle>
          Select a default team to work with. The team will be used with current application
        </styled.SubTitle>
        <Box>
          <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
            Team
          </Text>
          <styled.Select ref={ref} style={{ marginTop: '15px' }}>
            <styled.SelectLabel onClick={() => setTeamsOpen(teamsOpen => !teamsOpen)}>
              <span>{teams?.find(el => el.id === teamId)?.name}</span>
              <styled.SelectIcon>
                <Icon name={Name.SIZE_24_ARROW} rotation={teamsOpen ? Rotation.LEFT : undefined} />
              </styled.SelectIcon>
            </styled.SelectLabel>
            {teamsOpen && (
              <styled.SelectVariants>
                {teams?.map(el => (
                  <styled.Variant
                    active={el.id === teamId}
                    key={el.id}
                    onClick={() => {
                      setTeamId(el.id)
                      setTeamsOpen(false)
                    }}
                  >
                    {el.name}
                    {el.id === teamId && <Icon name={Name.SIZE_24_CHECK_MARK} />}
                  </styled.Variant>
                ))}
              </styled.SelectVariants>
            )}
          </styled.Select>
        </Box>
        <Terms mt="116px" />
      </styled.Content>
      <styled.Actions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close} style={{ minWidth: '90px' }}>
          Cancel
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          onClick={createAppstoreIntegration}
          disabled={!teamId}
          style={{ minWidth: '90px' }}
        >
          Continue
        </Button>
      </styled.Actions>
    </>
  )
}
