import { Box } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from './WorkspaceQuotas.styled'

import { WorkspaceContext } from 'context'
import { Card } from '../Card'

export const WorkspaceQuotas: React.FC = () => {
  const { workspace, projects } = useContext(WorkspaceContext)

  return workspace ? (
    <Card title="Workspace quotas">
      <Box p="20px 20px 25px" display="flex" flexDirection="column" gap="25px">
        <styled.Item>
          <styled.ItemText>
            Workspace members
            <span>
              {workspace.memberIds.length} <b>/ 2</b>
            </span>
          </styled.ItemText>
          <styled.ItemProgress>
            <Box width="10%" />
          </styled.ItemProgress>
        </styled.Item>
        <styled.Item>
          <styled.ItemText>
            Projects
            <span>
              {projects.length} <b>/ 3</b>
            </span>
          </styled.ItemText>
          <styled.ItemProgress>
            <Box width="50%" />
          </styled.ItemProgress>
        </styled.Item>
        <styled.Item>
          <styled.ItemText>
            File storage
            <span>
              186 mb <b>/ 200 mb</b>
            </span>
          </styled.ItemText>
          <styled.ItemProgress>
            <Box width="90%" />
          </styled.ItemProgress>
        </styled.Item>
      </Box>
    </Card>
  ) : null
}
