import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'
import { Block, Paragraph, Title } from '../../Settings.styled'
import * as styled from './Variables.styled'

import { Button, Icon, Modal, Name, RemoveContent, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { Variable } from 'context/types'
import { useInputAutoFocusRef } from 'hooks'
import { ProjectsService } from 'services/projects'

export const Variables: React.FC = () => {
  const { id, repositoryId } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const themeContext = useContext(ThemeContext)
  const [variables, setVariables] = useState<Variable[]>([])
  const [wait, setWait] = useState(true)
  const [toRemove, setToRemove] = useState<Variable | null>(null)
  const [newVariable, setNewVariable] = useState({ key: '', value: '' })
  const [toEdit, setToEdit] = useState<Variable | null>(null)
  const inputAutoFocusElement = useInputAutoFocusRef(!!toEdit)

  const getVariables = () =>
    ProjectsService.getVariables(id as string, repositoryId as string).then(res => {
      setVariables(res.data.values)
      setWait(false)
    })

  useEffect(() => {
    getVariables().catch(err => toast(err))
  }, [])

  const postVariable = () => {
    const { key, value } = newVariable
    if (key && value) {
      setWait(true)
      startLoader()
      ProjectsService.postVariable(id as string, repositoryId as string, { key, value, secured: false })
        .then(() => {
          setNewVariable({ key: '', value: '' })
          return getVariables()
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  const putVariable = () => {
    if (toEdit) {
      const { uuid, key, value, secured } = toEdit
      setWait(true)
      startLoader()
      ProjectsService.putVariable(id as string, repositoryId as string, uuid, { key, value, secured })
        .then(() => {
          setToEdit(null)
          return getVariables()
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  const deleteVariable = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      ProjectsService.deleteVariable(id as string, repositoryId as string, toRemove.uuid)
        .then(() => getVariables())
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <Block>
        <Title>Build Variables</Title>
        <Paragraph mb="20px">Review app config vars</Paragraph>
        <styled.BuildVariables>
          {variables.map(el => (
            <div key={el.uuid}>
              <styled.InputsBox>
                <styled.Input disabled value={el.key} />
                <styled.Input disabled value={el.secured ? '*********' : el.value} />
              </styled.InputsBox>
              {!el.secured && !el.system && (
                <styled.Actions>
                  <Icon name={Name.REPOSITORY_EDIT} onClick={() => setToEdit(el)} />
                  <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => setToRemove(el)} />
                </styled.Actions>
              )}
            </div>
          ))}
          {!wait && (
            <div>
              <styled.InputsBox>
                <styled.Input
                  value={newVariable.key}
                  onChange={e => setNewVariable(newVariable => ({ ...newVariable, key: e.target.value }))}
                />
                <styled.Input
                  value={newVariable.value}
                  onChange={e => setNewVariable(newVariable => ({ ...newVariable, value: e.target.value }))}
                />
              </styled.InputsBox>
              <styled.Actions>
                <Button disabled={!newVariable.key || !newVariable.value} onClick={() => postVariable()}>
                  Add
                </Button>
              </styled.Actions>
            </div>
          )}
        </styled.BuildVariables>
      </Block>
      <Modal
        active={!!toEdit}
        onClose={() => setToEdit(null)}
        title="Edit variable"
        onButtonClick={putVariable}
        buttonTitle="Save"
        buttonLoading={wait}
      >
        {toEdit && (
          <Box display="flex" flexDirection="column" gap="30px">
            <Box>
              <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
                Key
              </Text>
              <InputWrapper inputEmpty={toEdit.key === ''}>
                <input
                  value={toEdit.key}
                  onChange={e => setToEdit(toEdit => ({ ...(toEdit as Variable), key: e.target.value }))}
                  ref={inputAutoFocusElement}
                />
              </InputWrapper>
            </Box>
            <Box>
              <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
                Value
              </Text>
              <InputWrapper inputEmpty={toEdit.value === ''}>
                <input
                  value={toEdit.value}
                  onChange={e => setToEdit(toEdit => ({ ...(toEdit as Variable), value: e.target.value }))}
                />
              </InputWrapper>
            </Box>
          </Box>
        )}
      </Modal>
      <RemoveContent
        toRemove={toRemove}
        title="Delete variable"
        text="Are you sure? This action <b>can not be undone</b>. Selected variable will be deleted immediately"
        clean={() => setToRemove(null)}
        remove={deleteVariable}
      />
    </>
  )
}
