import styled from 'styled-components'

export const SelectedRecordsActions = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  padding: 12px 20px 15px;
  background: ${props => props.theme.colors.primaryBackground};
  border-bottom: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
`
