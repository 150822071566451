import React from 'react'
import { BorderProps } from 'styled-system'
import * as styled from './Image.styled'

interface IImage extends BorderProps {
  src: string
  alt?: string
  width?: string
  height?: string
  objectFit?: string
  maxHeight?: string
  maxWidth?: string
  style?: React.CSSProperties
}

export const Image: React.FC<IImage> = ({ src, alt, objectFit, width, height, maxHeight, maxWidth, ...props }) => (
  <styled.Image
    src={src}
    alt={alt}
    objectFit={objectFit}
    width={width}
    height={height}
    maxHeight={maxHeight}
    maxWidth={maxWidth}
    {...props}
  />
)
