import { Box } from '@mui/material'
import React, { ReactElement, cloneElement, useContext, useEffect, useRef, useState } from 'react'

import * as styled from './CustomPicker.styled'

import { Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { pickerId } from 'hooks'
import { moveElement } from 'utils'

interface Tab {
  title?: string | JSX.Element
  optionsContainer: JSX.Element
}

interface Props {
  undoMode?: boolean
  title?: string | JSX.Element
  close: () => void
  setSearch?: React.Dispatch<React.SetStateAction<string>>
  optionsContainer: ReactElement
  iconContainer?: ReactElement
  variableContainer?: ReactElement
  switchColorContainer?: ReactElement
  componentsContainer?: ReactElement
  defaultTab?: number
}

export const CustomPicker: React.FC<Props> = ({
  undoMode,
  title,
  close,
  setSearch,
  optionsContainer,
  iconContainer,
  variableContainer,
  switchColorContainer,
  componentsContainer,
  defaultTab,
}) => {
  const { undo } = useContext(ProjectContext)
  const [q, setQ] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  const tabsRefs = useRef<HTMLDivElement[]>([])
  const [currentTab, setCurrentTab] = useState(defaultTab || 0)
  const [leftBorder, setLeftBorder] = useState(16)
  const [widthBorder, setWidthBorder] = useState(0)
  const [mounted, setMounted] = useState(false)

  const qChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value
    setQ(q)
    if (setSearch) {
      setSearch(q)
    }
  }

  const tabs = [
    {
      title,
      optionsContainer: (
        <>
          {iconContainer}
          {setSearch && <styled.Search inputEmpty={!q} placeholder="Search" value={q} onChange={qChange} />}
          {cloneElement(optionsContainer, { close })}
        </>
      ),
    },
    variableContainer ? { title: 'Variable', optionsContainer: cloneElement(variableContainer, { close }) } : null,
    switchColorContainer ? { title: 'Custom', optionsContainer: switchColorContainer } : null,
    componentsContainer
      ? {
          title: 'Components',
          optionsContainer: (
            <>
              {setSearch && <styled.Search inputEmpty={!q} placeholder="Search" value={q} onChange={qChange} />}
              {componentsContainer}
            </>
          ),
        }
      : null,
  ].filter(el => !!el) as Tab[]

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted && tabs.filter(el => !!el).length > 1 && tabsRefs?.current && tabsRefs.current.length) {
      setLeftBorder(tabsRefs.current[currentTab].offsetLeft + 8)
      setWidthBorder(tabsRefs.current[currentTab].offsetWidth - 16)
    }
  }, [mounted, currentTab])

  return (
    <styled.Container ref={ref} id={pickerId}>
      {title && (
        <>
          <styled.Header onMouseDown={() => moveElement(ref.current)}>
            <styled.Tabs>
              {tabs.map((el, i) => (
                <styled.Tab
                  key={i}
                  onMouseDown={e => e.stopPropagation()}
                  onClick={() => setCurrentTab(i)}
                  active={currentTab === i}
                  alone={tabs.length === 1}
                  ref={el => (tabsRefs.current[i] = el as HTMLDivElement)}
                >
                  {el.title}
                </styled.Tab>
              ))}
            </styled.Tabs>
            <Box display="flex" alignItems="center" gap="15px">
              {undoMode && <Icon name={Name.RIGHT_SIDEBAR_PICKER_UNDO} onClick={undo} />}
              <Icon name={Name.PICKERS_CLOSE} onClick={close} />
            </Box>
          </styled.Header>
          <styled.Border>
            <styled.ActiveBorder left={leftBorder} width={widthBorder} />
          </styled.Border>
        </>
      )}
      {tabs[currentTab].optionsContainer}
    </styled.Container>
  )
}
