import React from 'react'
import { ISvg, Svg } from '../Svg'

const Qa: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M7 2H4C2.89543 2 2 2.89543 2 4V7M13 2H16C17.1046 2 18 2.89543 18 4V7M7 18H4C2.89543 18 2 17.1046 2 16V13M13 18H16C17.1046 18 18 17.1046 18 16V13"
      stroke="#212133"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.93262 12.082C9.35156 12.082 9.00977 11.6855 9.00977 11.125V11.0293C9.00977 10.2227 9.42676 9.68945 10.2334 9.21777C11.1152 8.69141 11.3818 8.34961 11.3818 7.7207C11.3818 7.05078 10.8623 6.58594 10.1104 6.58594C9.44043 6.58594 8.98926 6.91406 8.77051 7.52246C8.59961 7.95996 8.29199 8.15137 7.875 8.15137C7.33496 8.15137 7 7.81641 7 7.29688C7 7.00293 7.06836 6.74316 7.20508 6.4834C7.64258 5.58105 8.73633 5 10.2061 5C12.1475 5 13.4531 6.06641 13.4531 7.65918C13.4531 8.69141 12.9541 9.39551 11.9902 9.96289C11.0811 10.4893 10.876 10.7832 10.7871 11.3574C10.6846 11.8154 10.418 12.082 9.93262 12.082ZM9.94629 15.124C9.33105 15.124 8.83203 14.6523 8.83203 14.0508C8.83203 13.4492 9.33105 12.9775 9.94629 12.9775C10.5684 12.9775 11.0674 13.4492 11.0674 14.0508C11.0674 14.6523 10.5684 15.124 9.94629 15.124Z"
      fill="#212133"
    />
  </Svg>
)

export default Qa
