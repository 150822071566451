import React, { useContext, useEffect, useMemo, useState } from 'react'

import * as general from '../index.styled'

import { Crumbs, Text, Type } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { Invite, Permission } from 'hooks'
import { AddNew } from 'pages/Settings/pages/Teammates/components'
import { firestore } from 'services/firebase'
import { WorkspacesService } from 'services/workspaces'
import { TeammatesContent } from './TeammatesContent'

export const Teammates: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { workspace } = useContext(WorkspaceContext)
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [invites, setInvites] = useState<Invite[]>([])

  useEffect(() => {
    if (workspace) {
      const unsubscribe = firestore.collection(`workspaces/${workspace.id}/permissions`).onSnapshot({
        next: res => {
          setPermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as Permission[])
          getInvites(workspace.id)
        },
        error: err => toast(err),
      })
      return () => {
        unsubscribe()
      }
    }
  }, [workspace?.id])

  const getInvites = (id: string) =>
    WorkspacesService.getInvites(id)
      .then(res => setInvites(res.data))
      .catch(err => toast(err))

  const teammates = useMemo(() => [...permissions, ...invites], [permissions, invites])

  return workspace ? (
    <general.Container>
      <general.MaxWidthContainer>
        <Crumbs
          icon={null}
          firstCrumbTitle={workspace?.name}
          secondCrumbTitle="Teammates"
          to={`/workspaces/${workspace.id}`}
        />
        <Text type={Type.SUB_TITLE}>
          Workspace members can create, edit, and delete projects. They can also access project data and invite other
          members.
        </Text>
        <AddNew workspaceTeammates getInvites={getInvites} />
        <TeammatesContent teammates={teammates} />
      </general.MaxWidthContainer>
    </general.Container>
  ) : null
}
