import React from 'react'
import { ISvg, Svg } from '../Svg'

const More: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="2" cy="8" r="2" fill={props.color ? props.color : '#212133'} />
    <circle cx="8" cy="8" r="2" fill={props.color ? props.color : '#212133'} />
    <circle cx="14" cy="8" r="2" fill={props.color ? props.color : '#212133'} />
  </Svg>
)

export default More
