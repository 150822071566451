import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Button } from 'components'
import { SettingNumberField } from 'partials/RightSidebar/components'
import { CoordinateConstant } from 'utils'

interface Props {
  value?: CoordinateConstant
  onChange: (val: CoordinateConstant) => void
  close: () => void
}

const buttonPadding = { padding: '9.5px' }

export const OptionsContainer: React.FC<Props> = ({ value, onChange, close }) => {
  const [coordinate, setCoordinate] = useState(value)

  useEffect(() => {
    setCoordinate(value)
  }, [value])

  const onConfirm = () => {
    if (coordinate) {
      onChange(coordinate)
      close()
    }
  }

  const confirmDisabled = !coordinate || !coordinate.latitude || !coordinate.longitude

  return (
    <Box maxHeight="500px" overflow="auto">
      <Box p="0 10px">
        <SettingNumberField
          subLabel="Latitude"
          value={coordinate?.latitude}
          onChange={value => setCoordinate(coordinate => ({ ...coordinate, latitude: value }))}
          onlyNumber
          fullWidth
          small
          insidePicker
        />
        <SettingNumberField
          subLabel="Longitude"
          value={coordinate?.longitude}
          onChange={value => setCoordinate(coordinate => ({ ...coordinate, longitude: value }))}
          onlyNumber
          fullWidth
          small
          insidePicker
        />
      </Box>
      <Box padding="15px 10px" display="flex" justifyContent="flex-end" width="100%">
        <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
          Confirm
        </Button>
      </Box>
    </Box>
  )
}
