import { Box } from '@mui/material'
import React, { useRef, useState } from 'react'

import { CustomPicker } from 'components'
import { useOnClickOutside } from 'hooks'
import { AddComponents } from './AddComponents'
import { AddScreens } from './AddScreens'

interface Props {
  close: () => void
}

export const AddMedia: React.FC<Props> = ({ close }) => {
  const [search, setSearch] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  return (
    <Box zIndex="10" width="350px" position="fixed" top="52px" left="60px" ref={ref}>
      <CustomPicker
        title="Screens"
        close={close}
        setSearch={setSearch}
        optionsContainer={<AddScreens close={close} search={search} />}
        componentsContainer={<AddComponents search={search} />}
      />
    </Box>
  )
}
