import React from 'react'
import { ISvg, Svg } from '../Svg'

const Activities: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M1 8.38462H5L8 13V3L11.5 8.38462H15"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Activities
