import styled from 'styled-components'

export const Connected = styled.div`
  display: flex;
  gap: 20px;
  & > a {
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${props => props.theme.colors.primaryText};
    &:hover {
      color: ${props => props.theme.colors.primaryTextHover};
    }
  }
`
