import React from 'react'
import { ISvg, Svg } from '../Svg'

const ArrowThinDown: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M3 6L8 11" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M13 6L8 11" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default ArrowThinDown
