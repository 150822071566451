import React from 'react'
import { ISvg, Svg } from '../Svg'

const Video: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.75" y="1.75" width="12.5" height="12.5" rx="1.25" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M10.7519 7.16795C11.3457 7.56377 11.3457 8.43623 10.7519 8.83205L7.5547 10.9635C6.89014 11.4066 6 10.9302 6 10.1315L6 5.86852C6 5.06982 6.89015 4.59343 7.5547 5.03647L10.7519 7.16795Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Video
