import React from 'react'
import { ISvg, Svg } from '../Svg'

const Spacer: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M26 6L18 14" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M18 5H27V14" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 26L14 18" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14 27L5 27L5 18" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Spacer
