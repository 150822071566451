import React from 'react'
import { ISvg, Svg } from '../Svg'

const Image: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2 12L4.29519 9.70481C4.68482 9.31518 5.31621 9.31415 5.7071 9.70251L6.16236 10.1548C6.58023 10.57 7.26462 10.5358 7.63909 10.0811L10.2326 6.93181C10.6311 6.44792 11.3715 6.44576 11.7728 6.92733L14 9.6"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="5.5" cy="5.5" r="1.5" fill="#7F899E" />
    <rect x="1.75" y="1.75" width="12.5" height="12.5" rx="1.25" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Image
