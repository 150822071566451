import React from 'react'
import { ISvg, Svg } from '../Svg'

const ActiveNotification: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 26" fill="none" {...props}>
    <path
      d="M18 10C18 8.4087 17.3679 6.88258 16.2426 5.75736C15.1174 4.63214 13.5913 4 12 4C10.4087 4 8.88258 4.63214 7.75736 5.75736C6.63214 6.88258 6 8.4087 6 10C6 17 3 19 3 19H21C21 19 18 17 18 10Z"
      stroke="#CACFDB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7295 23C13.5537 23.3031 13.3014 23.5547 12.9978 23.7295C12.6941 23.9044 12.3499 23.9965 11.9995 23.9965C11.6492 23.9965 11.3049 23.9044 11.0013 23.7295C10.6977 23.5547 10.4453 23.3031 10.2695 23"
      stroke="#CACFDB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="18" cy="6" r="5" fill="#FA3E3E" stroke="#F2F2F3" strokeWidth="2" />
  </Svg>
)

export default ActiveNotification
