import React from 'react'
import { ISvg, Svg } from '../Svg'

const Youtube: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M31.2924 8.14343L31.3324 8.39707C31.15 7.75162 30.8011 7.16194 30.3186 6.68371C29.8362 6.20549 29.2361 5.8545 28.5751 5.6639L28.5471 5.65743C26.0524 5.00002 16.0137 5.00002 16.0137 5.00002C16.0137 5.00002 6.00039 4.98708 3.48039 5.65743C2.81823 5.84343 2.21578 6.19005 1.72986 6.66462C1.24394 7.13919 0.890569 7.72606 0.703059 8.3699L0.696392 8.39707C-0.235608 13.1219 -0.242274 18.7992 0.737726 23.8579L0.696392 23.6017C0.878815 24.2471 1.22772 24.8368 1.71017 25.315C2.19262 25.7932 2.7927 26.1442 3.45373 26.3348L3.48173 26.3413C5.97373 27 16.0151 27 16.0151 27C16.0151 27 26.0271 27 28.5484 26.3413C29.2109 26.1556 29.8136 25.8091 30.2998 25.3345C30.786 24.8599 31.1395 24.2729 31.3271 23.6288L31.3337 23.6017C31.7577 21.4042 32.0004 18.8755 32.0004 16.2925L31.9991 16.0065L32.0004 15.7088C32.0004 13.1245 31.7577 10.5958 31.2924 8.14343ZM12.8111 20.7248V11.2868L21.1657 16.0129L12.8111 20.7248Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Youtube
