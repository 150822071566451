import React from 'react'
import { ISvg, Svg } from '../Svg'

const LinkIcon: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19 15V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V7C5 5.89543 5.89543 5 7 5H9"
      stroke="#737BF8"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M14 5H19V10" stroke="#737BF8" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M19 5L10 14" stroke="#737BF8" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default LinkIcon
