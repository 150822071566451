import styled from 'styled-components'

export const MobileButtonBox = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: block;
    padding: 25px 20px;

    & a {
      height: 50px;
      border-radius: 16px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
`

interface IHeaderBox {
  burgerActive?: boolean
  shadow?: boolean
  zIndex?: boolean
}

export const HeaderBox = styled.header<IHeaderBox>`
  position: fixed;
  width: 100%;
  z-index: 13;
  height: ${props => props.theme.headerSize.heightDesktop}px;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-in-out;
    background-color: ${props => props.theme.colors.white};
    opacity: ${props => (props.zIndex ? '1' : '0.2')};
    box-shadow: ${props =>
      props.shadow && !props.burgerActive && '0 0 transparent, 0 0 transparent, 0 16px 44px rgba(0, 0, 0, 0.08)'};
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    height: ${props => props.theme.headerSize.heightMobile}px;
    backdrop-filter: none;

    ${MobileButtonBox} {
      & a {
        pointer-events: ${(props: IHeaderBox) => (props.burgerActive ? 'auto' : 'none')};
      }
    }
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: ${props => props.theme.fontSize.s3}px;
  line-height: ${props => props.theme.lineHeight.s3}px;
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
    justify-content: space-between;
  }
`

export const Logo = styled.a`
  display: flex;
  align-items: center;
  height: ${props => props.theme.logoSize.h}px;
  min-width: 200px;
`

export const LogoImage = styled.img`
  height: 100%;
  object-fit: cover;
`

export const LogoText = styled.img`
  margin-left: 13px;
  object-fit: cover;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: none;
  }
`

export const LogoTextMobile = styled.img`
  display: none;
  margin-left: 13px;
  object-fit: cover;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: block;
  }
`

interface IMenuBox {
  active: boolean
}

export const MenuBox = styled.nav<IMenuBox>`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-left: -15px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    position: fixed;
    width: 375px;
    height: ${props => `calc(100% - (${props.theme.headerSize.heightMobile}px - 1px))`};
    margin-top: ${props => `calc(${props.theme.headerSize.heightMobile}px - 1px)`};
    top: 0;
    right: 0;
    opacity: ${(props: IMenuBox) => (props.active ? '100%' : '0%')};
    pointer-events: ${(props: IMenuBox) => (props.active ? 'auto' : 'none')};
    padding-right: env(safe-area-inset-right);
    overflow: auto;
    background-color: ${props => props.theme.colors.white};
    transition: all 0.3s ease;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 50px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`

interface IMenuList {
  active?: boolean
}

export const MenuList = styled.ul<IMenuList>`
  display: flex;
  column-gap: 25px;
  transition: opacity 0.7s ease;
  margin: 0px;

  @media (max-width: ${props => props.theme.breakpoints.xl}px) {
    column-gap: 15px;
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 33px;
    padding: 0 20px;
    transition: opacity 0.3s linear, transform 0.4s ease-out;
    opacity: ${(props: IMenuList) => (props.active ? '1' : '0')};
    pointer-events: ${(props: IMenuList) => (props.active ? 'auto' : 'none')};
    transform: ${(props: IMenuList) => (props.active ? 'translateY(0);' : 'translateY(20px)')};
    will-change: opacity, transform;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  justify-content: flex-end;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: none;
  }
`

interface IBurgerBox {
  onClick: any
  active: boolean
}

export const BurgerBox = styled.div<IBurgerBox>`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: flex;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 5px;

    & span,
    &:before,
    &:after {
      position: absolute;
      background-color: ${props => props.theme.colors.black};
      height: 2px;
      left: 10px;
      width: 20px;
      transition: all 0.3s ease 0s;
      border-radius: ${props => props.theme.borderRadius.xss}px;
    }
    &:before,
    &:after {
      content: '';
    }
    &:before {
      top: ${(props: IBurgerBox) => (props.active ? '' : '10px')};
      transform: ${(props: IBurgerBox) => (props.active ? 'rotate(45deg)' : '')};
    }
    & span {
      transform: ${(props: IBurgerBox) => (props.active ? 'scale(0)' : '')};
    }
    &:after {
      bottom: ${(props: IBurgerBox) => (props.active ? '' : '10px')};
      transform: ${(props: IBurgerBox) => (props.active ? 'rotate(-45deg)' : '')};
    }
  }
`

export const Burger = styled.span``

interface IBurgerOverlay {
  active?: boolean
}

export const BurgerOverlay = styled.div<IBurgerOverlay>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0b0b17;
  opacity: ${(props: IBurgerOverlay) => (props.active ? 0.6 : 0)};
  z-index: 10;
  overflow: hidden;
  transition: all 0.3s ease;
  pointer-events: none;
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: block;
    pointer-events: ${(props: IBurgerOverlay) => (props.active ? 'auto' : 'none')};
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    display: none;
  }
`
