import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ small: boolean }>`
  border-bottom: 1px solid ${props => `${props.theme.colors.borderColor}`};
  padding: 0 15px;
  & > div:last-child {
    border-bottom: unset;
  }
  ${props =>
    props.small &&
    css`
      border-bottom: none;
      padding: 0;
      background: ${props => props.theme.colors.settingField};
      border-radius: 8px;
    `}
`

export const TitleWrapper = styled.div<{ pointer: boolean; small: boolean; isHidden: boolean }>`
  padding: ${props => (props.small ? '0 10px' : '0')};
  margin: ${props => (props.small ? '6px 0' : '13px 0')};
  display: ${props => (props.isHidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: ${props => (props.pointer ? 'pointer' : 'auto')};
  & > svg {
    cursor: pointer;
    user-select: none;
    & path,
    & circle {
      stroke: ${props => props.theme.colors.grey1};
      transition: 300ms ease-out;
    }
    &:hover {
      & path,
      & circle {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
  &:hover svg {
    & path,
    & circle {
      stroke: ${props => props.pointer && props.theme.colors.iconHover};
    }
  }
`

export const Title = styled.h3<{ grey1: boolean; small: boolean }>`
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
  color: ${props => (props.grey1 ? props.theme.colors.grey1 : props.theme.colors.darkBlue_800_const)};
  text-transform: uppercase;
  & > span {
    color: ${props => props.theme.colors.grey1};
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    transition: 300ms ease-out;
    & > svg path {
      transition: 300ms ease-out;
    }
    &:hover {
      color: ${props => props.theme.colors.darkBlue_800_const};
      & > svg path {
        stroke: ${props => props.theme.colors.darkBlue_800_const};
      }
    }
  }
  ${props =>
    !props.small &&
    css`
      padding: 1px 8px;
      background: ${props => props.theme.colors.white};
      border: 1px solid ${props => props.theme.colors.borderColor};
      border-radius: 4px;
    `};
`
