import React from 'react'

import { CoordinatePicker, Icon, Name } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { CoordinateConstant, getVariableName, LocalVariable, Screen, VariableSource } from 'utils'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  valueDescription?: string
  value?: CoordinateConstant
  onChange: (val?: CoordinateConstant) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  disabled?: boolean
  insidePicker?: boolean
}

export const CoordinateInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  valueDescription,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  disabled,
  insidePicker,
}) => (
  <SettingField
    big={big}
    small={small}
    label={label}
    subLabel={subLabel}
    insidePicker={insidePicker}
    value={
      source
        ? `${valueDescription || ''}${getVariableName(source)}`
        : value
        ? `${valueDescription || ''}${value.latitude} ${value.longitude}`
        : undefined
    }
    leftIcon={insidePicker ? undefined : <Icon name={Name.LEFT_MENU_CALENDAR_SMALL} />}
    disabled={disabled}
    optionsContainer={
      <CoordinatePicker
        close={() => {}}
        value={value}
        onChange={onChange}
        source={source}
        onSourceChange={onSourceChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    }
    actions={
      value || source ? (
        <Icon
          name={Name.RIGHT_SIDEBAR_DELETE}
          onClick={() => {
            onChange()
            onSourceChange?.()
          }}
        />
      ) : undefined
    }
  />
)
