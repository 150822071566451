import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Items } from '../CustomPicker.styled'
import * as styled from './ComponentStylePicker.styled'

import { ComponentPreview, CustomPicker } from 'components'
import { Screen, ScreenComponent } from 'utils'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectsService } from 'services/projects'

interface Props {
  componentId: string
  screenConfig: Screen
  onChange: (config: ScreenComponent) => void
  close: () => void
  listId?: string
}

export const ComponentStylePicker: React.FC<Props> = ({ componentId, screenConfig, onChange, close, listId }) => {
  const { toast } = useContext(ProgressContext)
  const { id } = useParams()
  const {
    state: { data },
  } = useContext(ProjectContext)
  const [values, setValues] = useState<ScreenComponent[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    ProjectsService.suggestComonentStyles(id as string, componentId, screenConfig, data, abortController)
      .then(res => setValues(res.data))
      .catch(err => !abortController.signal.aborted && toast(err))
    return () => {
      abortController.abort()
    }
  }, [componentId, JSON.stringify(screenConfig), JSON.stringify(data)])

  const onClick = (config: ScreenComponent) => {
    onChange(config)
    close()
  }

  return (
    <CustomPicker
      title="Style"
      close={close}
      optionsContainer={
        <Items>
          {values.map((el, i) => (
            <styled.StyleItem key={i} onClick={() => onClick(el)}>
              <ComponentPreview screenConfig={screenConfig} componentConfig={el} listId={listId} />
            </styled.StyleItem>
          ))}
        </Items>
      }
    />
  )
}
