import React from 'react'
import { ISvg, Svg } from '../Svg'

const Refactor: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M12.0119 5.83328C12.0119 5.61515 12.0974 5.40572 12.2501 5.24994L12.2585 5.24161L15.3918 2.10828C14.4658 1.68925 13.4341 1.56237 12.4342 1.74456C11.4342 1.92675 10.5136 2.40935 9.79489 3.12804C9.0762 3.84674 8.5936 4.7674 8.41141 5.76732C8.22922 6.76725 8.35609 7.79895 8.77512 8.72494L3.01679 14.4833C2.68527 14.8148 2.49902 15.2644 2.49902 15.7333C2.49902 16.2021 2.68527 16.6518 3.01679 16.9833C3.34831 17.3148 3.79795 17.501 4.26679 17.501C4.73563 17.501 5.18527 17.3148 5.51679 16.9833L11.2751 11.2249C12.2011 11.644 13.2328 11.7708 14.2327 11.5887C15.2327 11.4065 16.1533 10.9239 16.872 10.2052C17.5907 9.48648 18.0733 8.56582 18.2555 7.5659C18.4377 6.56597 18.3108 5.53427 17.8918 4.60828L14.7501 7.74994C14.5943 7.90263 14.3849 7.98816 14.1668 7.98816C13.9487 7.98816 13.7392 7.90263 13.5835 7.74994L12.2501 6.41661C12.0974 6.26084 12.0119 6.0514 12.0119 5.83328Z"
      stroke="#212133"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Refactor
