import styled from 'styled-components'

export const Plans = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 502px;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
`

export const Plan = styled.div<{ canSelect: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: ${props => props.canSelect && 'pointer'};
`

export const PlanCardWrapper = styled.div<{ selected: boolean }>`
  height: 100%;
  & > div {
    background: ${props => props.theme.colors.modalBg};
    border: 2px solid ${props => (props.selected ? props.theme.colors.primaryText : props.theme.colors.borderColor)};
    width: 250px;
    height: 100%;
    padding: 10px;
    &:hover {
      transform: unset;
    }
  }
`
