import React from 'react'
import { Outlet } from 'react-router-dom'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { DeploymentStatus, Links, SettingsLayout } from '../components'
import { LogicItem } from './sections'

export const Logic: React.FC = () => (
  <>
    <SettingsLayout>
      <Text type={Type.TITLE}>Logic</Text>
      <Text type={Type.SUB_TITLE}>
        Configure and manage the flows and prompts that define your application’s behavior.
      </Text>
      <Links />
      <CustomSectionsWrapper>
        <DeploymentStatus description="Deploy your changes to update your application with the latest configurations." />
        <LogicItem name="flows" />
        <LogicItem name="prompts" />
      </CustomSectionsWrapper>
    </SettingsLayout>
    <Outlet />
  </>
)
