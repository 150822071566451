import styled from 'styled-components'
import { border } from 'styled-system'

interface IImage {
  width?: string
  height?: string
  objectFit?: string
  maxHeight?: string
  maxWidth?: string
}

export const Image = styled.img<IImage>`
  object-fit: ${(props: IImage) => props.objectFit || 'cover'};
  width: ${(props: IImage) => props.width || '100%'};
  height: ${(props: IImage) => (props.height ? props.height : props.width ? 'auto' : '100%')};
  ${(props: IImage) => {
    if (props.maxHeight) {
      return `max-height: ${props.maxHeight};`
    }
  }}
  ${(props: IImage) => {
    if (props.maxWidth) {
      return `max-width: ${props.maxWidth};`
    }
  }}
  ${border}
`
