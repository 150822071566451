import React from 'react'

import * as styled from './ProductsItem.styled'

import { IProduct } from '../constants'
import { Icon } from 'components'
import { landingUrl } from 'utils'

const removeSpaces = (str: string) =>
  str
    .replaceAll(' ', '-')
    .replaceAll('&', 'and')
    .replaceAll(',', '')
    .replaceAll(':', '')
    .replaceAll('?', '')
    .toLowerCase()

interface IProductsItem {
  item: IProduct
}

export const ProductsItem: React.FC<IProductsItem> = ({ item }) => {
  const stroke = item.title === 'Visual App Builder' || item.title === 'Content Management System'

  return (
    <styled.ItemBox href={item.href ? item.href : `${landingUrl}/${removeSpaces(item.title)}`}>
      <styled.IconBox $stroke={stroke} $visualAppBuilder={item.title === 'Visual App Builder'}>
        <Icon name={item.icon} />
      </styled.IconBox>
      <styled.TitleBox>
        <styled.Title>{item.title}</styled.Title>
        <styled.Description>{item.description}</styled.Description>
      </styled.TitleBox>
    </styled.ItemBox>
  )
}
