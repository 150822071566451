import React from 'react'
import { ISvg, Svg } from '../Svg'

const GenerateContent: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M3.75 8.01884C3.75 7.06775 3.75041 6.39235 3.787 5.8611C3.82311 5.33667 3.8919 5.00825 4.00201 4.74518C4.3316 3.95775 4.95775 3.3316 5.74518 3.00201C6.00825 2.8919 6.33667 2.82311 6.8611 2.787C7.39235 2.75041 8.06775 2.75 9.01884 2.75C9.95019 2.75 10.3041 2.75404 10.6283 2.82483C11.0766 2.92272 11.499 3.11442 11.8679 3.38732L12.314 2.78439L11.8679 3.38732C12.1346 3.58466 12.3708 3.84837 12.984 4.54928L13.8106 5.49388L14.6371 6.43847C15.4653 7.385 15.773 7.74801 15.9607 8.16257L16.644 7.85325L15.9607 8.16257C16.0195 8.29229 16.0696 8.42573 16.1108 8.56202L16.8287 8.34489L16.1108 8.56202C16.2426 8.9976 16.25 9.47344 16.25 10.7312V11C16.25 12.4201 16.2491 13.4287 16.1696 14.2102C16.0914 14.9793 15.9433 15.4449 15.7023 15.8056C15.4652 16.1605 15.1605 16.4652 14.8056 16.7023C14.4449 16.9433 13.9793 17.0914 13.2102 17.1696C12.4287 17.2491 11.4201 17.25 10 17.25C8.57993 17.25 7.57133 17.2491 6.78975 17.1696C6.02071 17.0914 5.55507 16.9433 5.1944 16.7023C4.83953 16.4652 4.53484 16.1605 4.29772 15.8056C4.05673 15.4449 3.90865 14.9793 3.83041 14.2102C3.75091 13.4287 3.75 12.4201 3.75 11V8.01884Z"
      stroke="#212133"
      strokeWidth="1.5"
    />
    <path
      d="M10 3V5C10 6.88562 10 7.82843 10.5858 8.41421C11.1716 9 12.1144 9 14 9H16"
      stroke="#212133"
      strokeWidth="1.5"
    />
  </Svg>
)

export default GenerateContent
