import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
`

export const Clear = styled.div`
  margin-left: 20px;
  transition: ${props => props.theme.transition.primary};
  cursor: pointer;
  color: ${props => props.theme.colors.primaryText};
  &:hover {
    color: ${props => props.theme.colors.primaryTextHover};
  }
`
