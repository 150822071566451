import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'

import { Modal, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { MultiFactorUser, PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth'
import { updateUserInfo, useInputAutoFocusRef } from 'hooks'

interface Props {
  multiFactorUser: MultiFactorUser
  verificationId: string
  setVerificationId: React.Dispatch<React.SetStateAction<string>>
}

export const CheckCode: React.FC<Props> = ({ multiFactorUser, verificationId, setVerificationId }) => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [wait, setWait] = useState(false)
  const [code, setCode] = useState('')
  const inputAutoFocusElement = useInputAutoFocusRef(!!verificationId)

  const close = () => {
    setVerificationId('')
    setCode('')
  }

  const onClick = () => {
    setWait(true)
    startLoader()
    const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential)
    multiFactorUser
      .enroll(multiFactorAssertion)
      .then(() => {
        close()
        updateUserInfo()
      })
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
  }

  return (
    <Modal
      active={!!verificationId}
      onClose={close}
      title="Enter code"
      onButtonClick={onClick}
      buttonDisabled={!code}
      buttonTitle="Check code"
      buttonLoading={wait}
    >
      <Box>
        <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
          Code
        </Text>
        <InputWrapper inputEmpty={code === ''}>
          <input value={code} onChange={e => setCode(e.target.value)} ref={inputAutoFocusElement} />
        </InputWrapper>
      </Box>
    </Modal>
  )
}
