import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AIModal } from 'components'
import { WebappLayout } from 'partials'
import { landingUrl } from 'utils'
import { useParamsFromUrl } from 'hooks'

export const AI: React.FC = () => {
  const navigate = useNavigate()
  const inIframe = window.location !== window.parent.location
  const { conversationId, intent, setIntentToUrl } = useParamsFromUrl()
  const openAIByDefault = !!(conversationId || intent)
  const [openAI, setOpenAI] = useState(openAIByDefault)

  useEffect(() => {
    if (openAIByDefault) {
      setOpenAI(true)
    } else {
      setIntentToUrl('start-building')
    }
  }, [openAIByDefault])

  const close = inIframe ? () => landingUrl && window.parent.postMessage('close', landingUrl) : () => navigate('/')

  const parentWindowStripeRedirect = inIframe
    ? (url: string) => landingUrl && window.parent.postMessage({ url }, landingUrl)
    : undefined

  return (
    <WebappLayout>
      <AIModal active={openAI} close={close} parentWindowStripeRedirect={parentWindowStripeRedirect} previewMode />
    </WebappLayout>
  )
}
