import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'

import { CustomPicker } from 'components'
import { ProjectContext } from 'context'
import { includesSearch } from 'utils'
import { FontOption } from './FontOption'

interface Props {
  close: () => void
  onChange: (val: string) => void
}

export const BrandingFontPicker: React.FC<Props> = ({ close, onChange }) => {
  const [search, setSearch] = useState('')
  const {
    state: {
      branding: {
        typography: { iosDefaultFontFamily: fontFamily, fontStyles = [] },
      },
    },
  } = useContext(ProjectContext)

  const onClick = (styleName: string) => {
    onChange(`@${styleName}`)
    close()
  }

  return (
    <CustomPicker
      title="Font styles"
      close={close}
      setSearch={setSearch}
      optionsContainer={
        <Box height="300px" overflow="auto">
          {fontStyles
            .filter(el => includesSearch(el.styleName, search))
            .map(el => (
              <FontOption
                styleName={el.styleName}
                currentFontOption={el.ios}
                fontFamily={fontFamily}
                key={el.styleName}
                onClick={() => onClick(el.styleName)}
              />
            ))}
        </Box>
      }
    />
  )
}
