import React from 'react'

import * as styled from './Table.styled'

import { TableBody } from './TableBody'
import { TableHead } from './TableHead'
import { TABLE_DATA } from './constants'

export const Table: React.FC = () => (
  <styled.TableContainer>
    <p>All prices are charged per project, with applicable taxes added at checkout.</p>
    <styled.TableBox>
      <styled.TableRefBox>
        <TableHead />
        {TABLE_DATA.map(section => (
          <TableBody key={section.title} section={section} />
        ))}
      </styled.TableRefBox>
    </styled.TableBox>
  </styled.TableContainer>
)
