import styled from 'styled-components'

export const PWAPreviewWrapper = styled.div`
  transform: scale(0.77);
  margin-top: 20px;
  @media (max-width: 960px) {
    transform: scale(0.6);
  }
  @media (max-width: 767px) {
    transform: unset;
    margin-top: unset;
    width: 100%;
    height: 100%;
  }
`

export const TouchWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 767px) {
    display: none;
  }
`

export const ExploreProjectWrapper = styled.div`
  position: absolute;
  bottom: 0;
  padding: 25px 30px 30px;
  width: 100%;
  & > button {
    width: 100%;
  }
`
