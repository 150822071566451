import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'

export const connectGooglePlayFlag = 'connect-googleplay'

interface Props {
  disabled?: boolean
}

export const ConnectGooglePlayButton: React.FC<Props> = ({ disabled }) => (
  <Link
    to={`?${connectGooglePlayFlag}=true`}
    style={{
      textDecoration: 'none',
      pointerEvents: disabled ? 'none' : 'all',
    }}
  >
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SIZE_24_PLAY_STORE} />}
      disabled={disabled}
    >
      Link to Google Play
    </Button>
  </Link>
)
