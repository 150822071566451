import React from 'react'
import { ISvg, Svg } from '../Svg'

const Pointer: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <path
      d="M7.00192 7.06194C6.95255 6.23937 7.86344 5.71347 8.55112 6.16751L15.6174 10.833C16.4003 11.35 16.1046 12.5633 15.1716 12.662L11.9346 13.0044C11.6532 13.0341 11.3976 13.1817 11.2311 13.4105L9.31609 16.0427C8.76414 16.8013 7.56547 16.4508 7.50926 15.5143L7.00192 7.06194Z"
      fill="#F2F2F3"
    />
  </Svg>
)
export default Pointer
