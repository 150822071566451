import React from 'react'
import { Svg, ISvg } from '../Svg'

const PopoverIcon: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" preserveAspectRatio="none" fill="none" {...props}>
    <circle cx="10" cy="10" r="9.5" stroke="#DFDFEA" />
    <path
      d="M8.89453 11.4271V10.7565C8.88151 9.91667 9.48698 9.38281 10.0273 8.80339C10.457 8.35417 10.8802 7.8724 10.8802 7.26693C10.8802 6.61589 10.444 6.23828 9.80599 6.23828C9.30469 6.23828 8.79036 6.51172 8.45833 6.95443L7.21484 5.80208C7.82031 5.04688 8.84245 4.57813 9.94922 4.57813C11.4466 4.57813 12.7682 5.42448 12.7682 7.17578C12.7682 8.21745 12.1823 8.90104 11.5898 9.47396C11.0495 9.98177 10.5221 10.3789 10.4896 10.8607L10.444 11.4271H8.89453ZM9.70833 14.6107C9.10286 14.6107 8.62109 14.1354 8.62109 13.556C8.62109 12.9635 9.10286 12.4818 9.70833 12.4818C10.2943 12.4818 10.7826 12.9635 10.7826 13.556C10.7826 14.1354 10.2943 14.6107 9.70833 14.6107Z"
      fill="#7F899E"
    />
  </Svg>
)

export default PopoverIcon
