import React from 'react'

import { BrandingColorInput, CommonPicker, Icon, ImageInput, Name } from 'components'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { ImageLayout, LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Common, Id } from '../common'

const modeValues = Object.values(ImageLayout).map(el => ({ value: el, label: el }))

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ImageSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    componentType,
    name,
    image,
    imageColor,
    imageLayout,
    placeholderImage,
    placeholderImageColor,
    placeholderImageLayout,
  } = component

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <ImageInput
          label="Data"
          value={image?.constant?.resourceId}
          onChange={(value, resources) =>
            onComponentPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
          }
          source={image?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'image', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingField
          subLabel="Mode"
          value={imageLayout}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Modes"
              value={imageLayout}
              onChange={value => onComponentPropertiesChange([{ propName: 'imageLayout', value }])}
              values={modeValues}
              close={() => {}}
            />
          }
        />
        <BrandingColorInput
          subLabel="Tint color"
          value={imageColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { constant: value } }])}
          source={imageColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Placeholder" canHide childrenFor={componentType}>
        <ImageInput
          subLabel="Asset"
          value={placeholderImage?.constant?.resourceId}
          onChange={(value, resources) =>
            onComponentPropertiesChange([
              { propName: 'placeholderImage', value: { constant: { resourceId: value } }, resources },
            ])
          }
          source={placeholderImage?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'placeholderImage', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        {placeholderImage && (
          <>
            <SettingField
              subLabel="Mode"
              value={placeholderImageLayout}
              leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
              optionsContainer={
                <CommonPicker
                  title="Modes"
                  value={placeholderImageLayout}
                  onChange={value => onComponentPropertiesChange([{ propName: 'placeholderImageLayout', value }])}
                  values={modeValues}
                  close={() => {}}
                />
              }
            />
            <BrandingColorInput
              subLabel="Tint color"
              value={placeholderImageColor?.constant}
              onChange={value =>
                onComponentPropertiesChange([{ propName: 'placeholderImageColor', value: { constant: value } }])
              }
              source={placeholderImageColor?.source}
              onSourceChange={value =>
                onComponentPropertiesChange([{ propName: 'placeholderImageColor', value: { source: value } }])
              }
              screenConfig={screenConfig}
              allLocalVariables={allLocalVariables}
            />
          </>
        )}
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
