import { Icon, Name, Rotation } from 'components'
import React, { useState } from 'react'

import * as styled from '../../CustomPicker.styled'

interface Props {
  warning: {
    label: string
    values: {
      label: string
      value: string
    }[]
  }
  value: string
  onClick: (val: string) => void
}

export const WarningsItem: React.FC<Props> = ({ warning, value, onClick }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <styled.Item onClick={() => setOpen(open => !open)} active={open} color={open ? 'darkText' : 'darkText1'}>
        <styled.Arrow>
          <Icon name={Name.PICKERS_ARROW} rotation={open ? Rotation.DOWN : undefined} />
        </styled.Arrow>
        <Icon name={Name.PICKERS_WARNING} />
        <styled.ItemLabel>{warning.label}</styled.ItemLabel>
      </styled.Item>
      {open &&
        warning.values.map(el => (
          <styled.Item
            key={el.label + el.value}
            warning
            onClick={() => onClick(el.value)}
            active={el.value === value}
            color="darkText1"
          >
            <Icon name={Name.PICKERS_WARNING} />
            <styled.ItemLabel warning dangerouslySetInnerHTML={{ __html: el.label }} />
          </styled.Item>
        ))}
    </>
  )
}
