import React from 'react'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration, IntegrationGithub, IntegrationStatus } from 'hooks'
import { ConnectGithubButton } from './ConnectGithubButton'
import { GithubLink } from './GithubLink'

export const githubDescription =
  'Connect to GitHub to manage the source code and use automatics builds and store integration features.'

interface Props {
  github: Integration
}

export const GithubButton: React.FC<Props> = ({ github }) =>
  github.status === IntegrationStatus.READY ? (
    <GithubLink slug={(github.data as IntegrationGithub | undefined)?.slug}>
      <Button variant={ButtonVariant.LINK} color={ButtonColor.SECONDARY} leftIcon={<Icon name={Name.SIZE_24_GITHUB} />}>
        {(github.data as IntegrationGithub | undefined)?.slug}
      </Button>
    </GithubLink>
  ) : (
    <ConnectGithubButton />
  )
