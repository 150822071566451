import React from 'react'
import { ISvg, Svg } from '../Svg'

const TermsAndPolices: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="2.75" y="2.75" width="14.5" height="14.5" rx="3.25" stroke="#212133" strokeWidth="1.5" />
    <path d="M6 7H11M6 7H11" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 10H14M6 10H14" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 13H14M6 13H14" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default TermsAndPolices
