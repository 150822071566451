import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './AIModal.styled'

import { Button, ButtonColor, DropDown, Icon, Modal, Name, Text, Type } from 'components'
import { useParamsFromUrl } from 'hooks'
import { Resource } from 'utils'
import {
  Chat,
  LeftPanelAdminConversations,
  LeftPanelPreview,
  LeftPanelProjectSetup,
  OnlyLogo,
  OnlyName,
} from './components'

interface Props {
  active: boolean
  close: () => void
  parentWindowStripeRedirect?: (url: string) => void
  previewMode?: boolean
  adminMode?: boolean
}

export const AIModal: React.FC<Props> = ({ active, close, parentWindowStripeRedirect, previewMode, adminMode }) => {
  const themeContext = useContext(ThemeContext)
  const { clearParams } = useParamsFromUrl()
  const [leftPanelOpenOnMobile, setLeftPanelOpenOnMobile] = useState(false)
  const [templateId, setTemplateId] = useState<string | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [icon, setIcon] = useState<Resource | null>(null)
  const [color, setColor] = useState<string | null>(null)
  const [id, setId] = useState<string | null>(null)
  const [conversationIdSelectedByAdmin, setConversationIdSelectedByAdmin] = useState<string | null>(null)

  const onClose = () => {
    clearParams()
    close()
  }

  return (
    <Modal active={active} onClose={onClose} hasLeftPanel>
      <styled.TitleBox>
        <DropDown
          options={[{ label: 'Back', iconName: Name.PICKERS_BACK_TO_PROJECTS, onClick: onClose }]}
          top="54px"
          left="54px"
          width="110px"
          labelElement={
            <styled.Logo>
              <OnlyLogo logoUrl={icon?.url} />
              <styled.ArrowControl>
                <Icon width={8} height={8} name={Name.LOGO_CORNER_ARROW} />
              </styled.ArrowControl>
            </styled.Logo>
          }
        />
        <OnlyName name={name} />
        {(adminMode || previewMode) && (
          <Button
            color={leftPanelOpenOnMobile ? ButtonColor.BLACK : ButtonColor.SECONDARY1}
            leftIcon={<Icon name={Name.GLOBAL_PLAY} />}
            onClick={() => setLeftPanelOpenOnMobile(leftPanelOpenOnMobile => !leftPanelOpenOnMobile)}
          />
        )}
      </styled.TitleBox>
      {(adminMode || previewMode) && (
        <styled.LeftPanelWrapper open={leftPanelOpenOnMobile}>
          {adminMode ? (
            <LeftPanelAdminConversations
              conversationIdSelectedByAdmin={conversationIdSelectedByAdmin}
              setConversationIdSelectedByAdmin={setConversationIdSelectedByAdmin}
            />
          ) : id ? (
            <LeftPanelProjectSetup id={id} />
          ) : (
            <LeftPanelPreview templateId={templateId} name={name} icon={icon} color={color} />
          )}
        </styled.LeftPanelWrapper>
      )}
      <styled.ChatWrapper open={!leftPanelOpenOnMobile}>
        <styled.TitleBox insideChatWrapper>
          <Text type={Type.H3} fontWeight={themeContext.weights.bold}>
            {conversationIdSelectedByAdmin || 'AI App Builder'}
          </Text>
          <styled.Version>beta</styled.Version>
          <styled.CloseIconBox>
            <Icon name={Name.PICKERS_CLOSE} onClick={onClose} />
          </styled.CloseIconBox>
        </styled.TitleBox>
        {adminMode && !conversationIdSelectedByAdmin ? (
          <></>
        ) : (
          <Chat
            onClose={onClose}
            templateId={templateId}
            setTemplateId={setTemplateId}
            setName={setName}
            icon={icon}
            setIcon={setIcon}
            color={color}
            setColor={setColor}
            setId={setId}
            parentWindowStripeRedirect={parentWindowStripeRedirect}
            adminMode={adminMode}
            conversationIdSelectedByAdmin={conversationIdSelectedByAdmin}
          />
        )}
      </styled.ChatWrapper>
    </Modal>
  )
}
