import { memo } from 'react'
import AceEditor from 'react-ace'

import { SimpleTsInputWrapper } from '../../FieldTypes.styled'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const SimpleTsInput = memo((props: Props) => (
  <SimpleTsInputWrapper>
    <div>Code editor</div>
    <AceEditor
      value={props.value || undefined}
      onChange={value => props.onChange(value || null)}
      readOnly={props.disabled}
      mode="typescript"
      width="100%"
      height="calc(100vh - 170px)"
    />
  </SimpleTsInputWrapper>
))
