import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const TermsAndPoliciesGenerator: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.0625 8.24953H18.8863C18.7638 7.82953 18.5538 7.43578 18.23 7.12078L14.8787 3.76953C14.5637 3.44578 14.17 3.23578 13.75 3.11328V6.93703C13.75 7.66328 14.3362 8.24953 15.0625 8.24953Z"
      fill="#476CEC"
    />
    <path
      d="M15.0625 10C13.3737 10 12 8.62625 12 6.9375V3H7.625C6.18125 3 5 4.18125 5 5.625V17.375C5 18.8188 6.18125 20 7.625 20H16.375C17.8188 20 19 18.8188 19 17.375V10H15.0625Z"
      fill="#476CEC"
    />
  </Svg>
)
