import React from 'react'

import * as styled from '../../CustomPicker.styled'

import { Icon, Name, Rotation } from 'components'

interface Props {
  label: string
  value?: string
  onClick: () => void
}

export const CustomField: React.FC<Props> = ({ label, value, onClick }) => (
  <>
    <styled.ItemsHint>{label}</styled.ItemsHint>
    <styled.Item select onClick={onClick} color={!value ? 'grey1' : undefined}>
      <styled.ItemLabel>{value || 'Add...'}</styled.ItemLabel>
      <styled.Arrow>
        <Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={Rotation.RIGHT} width={16} height={16} />
      </styled.Arrow>
    </styled.Item>
  </>
)
