import styled, { css } from 'styled-components'

export const Container = styled.div<{
  paddingRight: boolean
  paddingTop: boolean
  left: boolean
  fullHeight: boolean
}>`
  z-index: ${props => props.left && 5};
  position: relative;
  padding-right: ${props => !props.fullHeight && props.paddingRight && '1px'};
  padding-top: ${props => props.paddingTop && '1px'};
  margin-bottom: ${props => props.paddingTop && '1px'};
  background: ${props => props.theme.colors.primaryBackground};
  height: ${props => props.fullHeight && '100%'};
  border-right: ${props => props.fullHeight && `1px solid ${props.theme.colors.borderColor}`};
`

export const Resize = styled.div<{ left: boolean; right: boolean; bottom: boolean }>`
  cursor: ${props => (props.bottom ? 'ns-resize' : 'ew-resize')};
  position: absolute;
  right: ${props => props.left && 0};
  left: ${props => props.right && 0};
  top: 0;
  height: ${props => (props.bottom ? '5px' : '100%')};
  width: ${props => (props.bottom ? '100%' : '5px')};
  margin-right: ${props => props.left && `-4px`};
  border-left: ${props => props.left && `1px solid ${props.theme.colors.borderColor}`};
  margin-left: ${props => props.right && `-4px`};
  border-right: ${props => props.right && `1px solid ${props.theme.colors.borderColor}`};
  margin-top: ${props => props.bottom && '-4px'};
  border-bottom: ${props => props.bottom && `1px solid ${props.theme.colors.borderColor}`};
  transition: 300ms ease-out;
  z-index: 1;
  &:hover {
    border-color: ${props => props.theme.colors.darkBlue_300};
  }
`

export const OpenClose = styled.div<{ left: boolean; right: boolean; bottom: boolean }>`
  transition: 300ms ease-out;
  z-index: 1;
  position: absolute;
  bottom: 30px;
  right: ${props => (props.left ? '-60px' : props.bottom && '70px')};
  left: ${props => props.right && '-60px'};
  cursor: pointer;
  padding: 7px;
  border-radius: 8px;
  background: ${props => props.theme.colors.primaryBackground};
  display: flex;
  & svg {
    & rect {
      transition: 300ms ease-out;
      &[fill] {
        fill: ${props => props.theme.colors.grey1};
      }
      &[stroke] {
        stroke: ${props => props.theme.colors.grey1};
      }
    }
  }
  &:hover svg {
    & rect[stroke] {
      stroke: ${props => props.theme.colors.iconHover};
    }
    & rect[fill] {
      fill: ${props => props.theme.colors.iconHover};
    }
  }
`

export const Navigation = styled.div<{ open: boolean; right: boolean; bottom: boolean; fullHeight: boolean }>`
  min-width: ${props => !props.bottom && (props.open ? (props.right ? '300px' : '220px') : '0px !important')};
  max-width: ${props => !props.bottom && (props.open ? (props.right ? '300px' : '220px') : '0px !important')};
  min-height: ${props =>
    props.fullHeight ? '100%' : !props.bottom ? 'calc(100vh - 60px)' : props.open ? '220px' : '0px !important'};
  max-height: ${props =>
    props.fullHeight ? '100%' : !props.bottom ? 'calc(100vh - 60px)' : props.open ? '220px' : '0px !important'};
  box-sizing: border-box;
  transition: all 100ms;
  display: flex;
  flex-direction: column;
  * {
    display: ${props => (!props.open ? 'none !important' : '')};
  }
`

export const Child = styled.div<{ right?: boolean; second?: boolean }>`
  flex: 1;
  overflow-y: auto;
  min-height: ${props => props.second && 'calc((100vh - 60px) / 2)'};
  max-height: ${props => props.second && 'calc((100vh - 60px) / 2)'};
  padding-bottom: ${props => props.right && '530px'};
`

export const NavHead = styled.div`
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.primaryBackground};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  & svg {
    cursor: pointer;
    & path,
    & circle {
      transition: 300ms ease-out;
      stroke: ${props => props.theme.colors.grey1};
    }
    &:hover {
      & path,
      & circle {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`

export const Title = styled.div`
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkText};
`

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  gap: 7px;
  & svg {
    min-width: 16px;
    &:last-child {
      cursor: pointer;
      & rect,
      path {
        transition: 300ms ease-out;
      }
      & rect {
        fill: ${props => props.theme.colors.grey1};
      }
      & path {
        stroke: ${props => props.theme.colors.primaryBackground};
      }
      &:hover {
        & rect {
          fill: ${props => props.theme.colors.iconHover};
        }
      }
    }
  }
  & input {
    padding: 0;
    font-family: ${props => props.theme.fonts.primary};
    font-size: 12px;
    line-height: 18px;
    color: ${props => props.theme.colors.darkText};
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
  }
`

export const NavBody = styled.ul<{ paddingBottom?: number }>`
  box-sizing: border-box;
  padding: ${props => `0 16px ${props.paddingBottom || 0}px`};
`

export const ShowMoreActions = styled.div`
  cursor: pointer;
  opacity: 0;
  transition: 300ms ease-out;
  display: flex;
  align-items: center;
  & svg:hover circle {
    fill: ${props => props.theme.colors.iconHover};
  }
`

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  & svg {
    pointer-events: auto;
  }
`

export const NavItem = styled.li<{
  lvl: number
  active: boolean
  notHover?: boolean
  hasActions?: boolean
  noArrowMode?: boolean
  movable?: boolean
  big?: boolean
}>`
  background: ${props => props.active && props.theme.colors.settingFieldHover};
  border-radius: ${props => (props.big ? '8px' : '4px')};
  padding: ${props =>
    props.big
      ? `5px ${props.hasActions ? '20px' : '7px'} 5px ${props.noArrowMode ? '7px' : `calc(15px * ${props.lvl} + 5px)`}`
      : `1px ${props.hasActions ? '20px' : '0px'} 1px 
    ${props.noArrowMode ? '8px' : `calc(15px * ${props.lvl} + 3px)`}`};
  margin: 0 -5px ${props => (props.big ? '5px' : '4px')};
  display: flex;
  align-items: center;
  gap: 1px;
  cursor: ${props => (props.big ? 'pointer' : 'default')};
  position: relative;
  user-select: none;
  ${props =>
    props.movable &&
    css`
      * {
        pointer-events: none;
      }
    `}

  & > span {
    color: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.darkText1)};
    font-size: ${props => (props.big ? '14px' : '12px')};
    line-height: ${props => (props.big ? '21px' : '18px')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    margin-left: ${props => (props.big ? '8px' : '2px')};
  }
  & b {
    color: ${props => props.theme.colors.blue};
  }
  & > svg,
  ${Arrow} > svg {
    min-width: 16px;
    & * {
      &[opacity] {
        opacity: 1;
      }
    }
    & path,
    & rect,
    & circle {
      &[fill] {
        fill: ${props => (props.active ? props.theme.colors.grey2 : props.theme.colors.folderIcon)};
      }
      &[stroke] {
        stroke: ${props => (props.active ? props.theme.colors.grey2 : props.theme.colors.folderIcon)};
      }
    }
  }
  &:hover {
    background: ${props => !props.active && props.theme.colors.settingField};
    ${ShowMoreActions} {
      opacity: 1;
    }
  }
`

export const Space = styled.svg`
  width: 16px;
  height: 16px;
`

export const Divider = styled.div`
  transition: 300ms ease-out;
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  cursor: ns-resize;
  padding-top: 3px;
  margin-bottom: -3px;
  z-index: 2;
  &:hover {
    border-color: ${props => props.theme.colors.darkBlue_300};
  }
`

export const NavMarginElement = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`
