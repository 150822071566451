import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  background-color: ${props => props.theme.colors.secondaryBackground};
  padding: 30px 0;
  display: flex;
  overflow: auto;
  user-select: none;
`

export const Editor = styled.div<{ isAddTextMode: boolean }>`
  width: 100%;
  margin: auto;
  cursor: ${props => props.isAddTextMode && 'crosshair'};
  & > svg {
    overflow: visible;
    margin: 0 86px;
    & * {
      pointer-events: ${props => props.isAddTextMode && 'none'};
    }
    & > g {
      &#marginsContainer {
        & > rect {
          fill: ${props => props.theme.colors.secondaryBackground}90;
        }
      }
      &#opacityContainer {
        & > rect {
          fill: ${props => props.theme.colors.secondaryBackground}90;
        }
      }
    }
    #resize {
      position: relative;
      overflow: visible;
      transform-box: fill-box;
      transform-origin: center;
      & div {
        width: 100%;
        height: 100%;
        outline: ${props => `1px solid ${props.theme.colors.blue}`};
      }
      & i {
        width: 8px;
        height: 8px;
        position: absolute;
        border: ${props => `1px solid ${props.theme.colors.blue}`};
        border-radius: 50%;
        background: ${props => props.theme.colors.white};
        cursor: all-scroll;
      }
      & #topRightResize {
        top: -6px;
        right: -6px;
      }
      & #topLeftResize {
        top: -6px;
        left: -6px;
      }
      & #bottomRightResize {
        bottom: -6px;
        right: -6px;
      }
      & #bottomLeftResize {
        bottom: -6px;
        left: -6px;
      }
      & #rotate {
        top: -30px;
        left: calc(50% - 4px);
        cursor: crosshair;
        &:after {
          content: '';
          display: block;
          height: 21px;
          width: 1px;
          background: ${props => props.theme.colors.blue};
          position: absolute;
          left: 3px;
          top: 8px;
        }
      }
    }
  }
`
