import React, { useEffect } from 'react'

import * as styled from './PlanCard.styled'

import { Button, ButtonColor, Icon, Name } from 'components'
import { firstLetterUpperCase, getMoney } from 'utils'

export enum Interval {
  month = 'month',
  year = 'year',
}

interface Props {
  plan: any
  interval: Interval
  setInterval?: (val: Interval) => void
  setCanSelect?: (val: boolean) => void
  setPriceId?: (val: string) => void
  activeSubscription?: any
  onPlanChange?: (priceId: string) => void
  workspaceCard?: boolean
}

export const PlanCard: React.FC<Props> = ({
  plan,
  interval,
  setInterval,
  setCanSelect,
  setPriceId,
  activeSubscription,
  onPlanChange,
  workspaceCard,
}) => {
  const price = plan.prices.find((el: any) => el.recurring.interval === interval)
  const activePrice = plan.prices.find((el: any) => el.id === activeSubscription?.plan.id)
  const active =
    plan.id === activeSubscription?.plan.product && price.recurring.interval === activePrice?.recurring.interval
  const custom = !active && !!plan.metadata.link

  useEffect(() => {
    setCanSelect?.(custom ? false : true)
  }, [custom])

  useEffect(() => {
    setPriceId?.(price.id)
  }, [price])

  return (
    <styled.BorderBox active={active} custom={custom}>
      <styled.Title>{plan.metadata.title}</styled.Title>
      <styled.Price>
        {custom
          ? plan.metadata.priceText
          : active
          ? getMoney(
              activePrice.currency,
              workspaceCard
                ? activePrice.tiers.slice(-1)[0]?.unit_amount
                : interval === Interval.year
                ? activePrice.unit_amount / 12
                : activePrice.unit_amount,
              undefined,
              activeSubscription?.discount?.coupon?.percent_off,
              activeSubscription?.discount?.coupon?.amount_off,
              activeSubscription?.discount?.coupon?.currency
            )
          : getMoney(
              price.currency,
              workspaceCard
                ? price.tiers.slice(-1)[0]?.unit_amount
                : interval === Interval.year
                ? price.unit_amount / 12
                : price.unit_amount
            )}
      </styled.Price>
      <styled.Description>
        {custom
          ? plan.metadata.description
          : workspaceCard
          ? 'Per month / per editor'
          : `Per month / billed ${interval}ly`}
      </styled.Description>
      <styled.CardButton>
        {onPlanChange || custom ? (
          <Button
            onClick={active || custom || !onPlanChange ? undefined : () => onPlanChange(price.id)}
            to={custom ? plan.metadata.link : undefined}
          >
            {active
              ? `You are on ${plan.metadata.title}`
              : custom
              ? 'Contact sales'
              : plan.metadata.action === 'get' || !activeSubscription
              ? workspaceCard
                ? 'Get workspace plan'
                : 'Get plan'
              : plan.metadata.action === 'none'
              ? 'Unavailable'
              : plan.metadata.action}
          </Button>
        ) : (
          setInterval && (
            <styled.SetIntervalButtons>
              {Object.values(Interval).map(el => (
                <Button
                  key={el}
                  color={interval === el ? ButtonColor.PRIMARY : ButtonColor.TERTIARY}
                  onClick={e => {
                    e.stopPropagation()
                    setInterval(el)
                  }}
                >
                  {firstLetterUpperCase(el)}ly
                </Button>
              ))}
            </styled.SetIntervalButtons>
          )
        )}
      </styled.CardButton>
      <styled.FeaturesHeading>{plan.metadata.featuresHeading || 'Includes:'}</styled.FeaturesHeading>
      <styled.FeaturesList>
        {plan.marketing_features.map((el: any) => (
          <li key={el.name}>
            <Icon name={Name.ADDITIONAL_OK_LIGHT} />
            {el.name}
          </li>
        ))}
      </styled.FeaturesList>
    </styled.BorderBox>
  )
}
