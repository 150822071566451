import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { CustomSection, CustomSectionTitle, Switch, SwitchVariant, Text, Type } from 'components'

const features = [
  {
    id: 0,
    title: 'Push Notifications (Beta)',
    subTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    checked: false,
  },
  {
    id: 1,
    title: 'Pin emails and texts sent via integrations ',
    subTitle: 'Lorem Ipsum is simply dummy text',
    checked: true,
  },
  {
    id: 2,
    title: 'Push Notifications (Beta)',
    subTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    checked: false,
  },
  {
    id: 3,
    title: 'Pin emails and texts sent via integrations ',
    subTitle: 'Lorem Ipsum is simply dummy text',
    checked: true,
  },
]

export const PreviewFeatures: React.FC = () => {
  const themeContext = useContext(ThemeContext)

  return (
    <CustomSection style={{ paddingBottom: 0 }}>
      <CustomSectionTitle>Preview features</CustomSectionTitle>
      {features.map(el => (
        <Box
          key={el.id}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="20px 24px"
          margin="0 -25px"
          borderTop={`1px solid ${themeContext.colors.darkBlue_100}`}
        >
          <Box>
            <Text type={Type.H3}>{el.title}</Text>
            <Text type={Type.BODY} as="p" color={themeContext.colors.grey1}>
              {el.subTitle}
            </Text>
          </Box>
          <Switch checked={el.checked} onChange={() => {}} variant={SwitchVariant.BIG} />
        </Box>
      ))}
    </CustomSection>
  )
}
