import React from 'react'
import { ISvg, Svg } from '../Svg'

const Overlay: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_15320_39104)">
      <path
        d="M7.83006 1.72361C7.93243 1.65323 8.0676 1.65323 8.16998 1.72361L14.7579 6.25279C14.9313 6.37199 14.9313 6.62801 14.7579 6.74721L8.16998 11.2764C8.0676 11.3468 7.93243 11.3468 7.83006 11.2764L1.24216 6.74721C1.06876 6.62801 1.06877 6.37199 1.24215 6.25279L7.83006 1.72361Z"
        stroke="#7F899E"
      />
      <path
        d="M15 10L8.67076 14.5894C8.3307 14.836 7.87281 14.8437 7.52457 14.6089L1 10.2083"
        stroke="#7F899E"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15320_39104">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default Overlay
