import { ProgressContext } from 'context'
import { marked } from 'marked'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectsService } from 'services/projects'

import * as styled from './Readme.styled'

interface IReadme {
  hash: string
}

export const Readme: React.FC<IReadme> = ({ hash }) => {
  const { id, repositoryId } = useParams()
  const [data, setData] = useState('')
  const { toast } = useContext(ProgressContext)

  useEffect(() => {
    const abortController = new AbortController()
    ProjectsService.getProjectApplicationFiles(
      id as string,
      repositoryId as string,
      `/${hash}/README.md`,
      abortController
    )
      .then(res => setData(res.data))
      .catch(err => !abortController.signal.aborted && toast(err))
    return () => {
      abortController.abort()
    }
  }, [])

  return data ? (
    <styled.Container id="readme">
      <styled.Name>README.md</styled.Name>
      <styled.File dangerouslySetInnerHTML={{ __html: marked(data, { async: false }) as string }} />
    </styled.Container>
  ) : null
}
