import React from 'react'
import { ISvg, Svg } from '../Svg'

const Deployments: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12 12C13.6569 12 15 10.6569 15 9C15 7.68924 14.1594 6.57481 12.9878 6.16643C12.8171 3.83692 10.8731 2 8.5 2C6.18372 2 4.27619 3.75002 4.02746 6H4C2.34315 6 1 7.34315 1 9C1 10.6569 2.34315 12 4 12"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M8 14V8" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 9L8 7L10 9" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Deployments
