import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const CMS: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 8C16.4183 8 20 6.65685 20 5C20 3.34315 16.4183 2 12 2C7.58172 2 4 3.34315 4 5C4 6.65685 7.58172 8 12 8Z"
      stroke="#476CEC"
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12C20 13.1067 16.4444 14 12 14C7.55556 14 4 13.1067 4 12"
      stroke="#476CEC"
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 5V18.1765C4 19.7388 7.55556 21 12 21C16.4444 21 20 19.7388 20 18.1765V5"
      stroke="#476CEC"
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
