import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, ButtonVariant, DropDown, Icon, Name } from 'components'
import { ProgressContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { refreshRecords } from 'pages/CMS/pages'
import { CMSCollectionRecord, CMSService, rootFolderName } from 'services/cms'
import { Collection, generateFirestoreId } from 'utils'

interface Props {
  active: boolean
  disabled: boolean
  onClick: () => void
  currentFolder?: Collection
  setOuterOpenAdd: React.Dispatch<React.SetStateAction<boolean>>
  collectionId: string
}

export const AddBtn: React.FC<Props> = ({
  active,
  disabled,
  onClick,
  currentFolder,
  setOuterOpenAdd,
  collectionId,
}) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    setOuterOpenAdd(open)
  }, [open])

  const onMultipleAssetsClick = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.onchange = (e: any) => {
      startLoader()
      const files = Array.from(e.target.files) as File[]
      Promise.all(
        files.map(el => {
          const recordId = generateFirestoreId()
          const record: CMSCollectionRecord = {
            id: recordId,
            folder: currentFolder?.name || rootFolderName,
            resource: el,
          }
          return CMSService.saveRecord(id as string, collectionId, recordId, record)
        })
      )
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          refreshRecords()
        })
    }
    input.click()
  }

  return currentFolder ? (
    <div ref={ref}>
      <DropDown
        top="36px"
        left="0px"
        width="220px"
        options={[
          { label: 'Single asset', iconName: Name.PICKERS_IMPORT_DATA, onClick },
          { label: 'Multiple assets', iconName: Name.PICKERS_IMPORT_DATA, onClick: onMultipleAssetsClick },
        ]}
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <Button
            variant={ButtonVariant.TOP_PANEL_ACTION}
            leftIcon={<Icon name={Name.ADDITIONAL_ADD} />}
            active={open || active}
            disabled={disabled}
            onClick={() => setOpen(open => !open)}
          >
            Add asset
          </Button>
        }
      />
    </div>
  ) : (
    <Button
      variant={ButtonVariant.TOP_PANEL_ACTION}
      leftIcon={<Icon name={Name.ADDITIONAL_ADD} />}
      active={active}
      disabled={disabled}
      onClick={onClick}
    >
      Add record
    </Button>
  )
}
