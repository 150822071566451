import React from 'react'
import { ISvg, Svg } from '../Svg'

const Fix: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11 6V4C11 2.34315 9.65685 1 8 1V1C6.34315 1 5 2.34315 5 4V6"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5 10V12C5 13.6569 6.34315 15 8 15V15C9.65685 15 11 13.6569 11 12V10"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M8 11L8 5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)
export default Fix
