import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const StoreManager: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="3" y="3" width="8" height="8" rx="2" fill="#476CEC" />
    <rect x="13" y="3" width="8" height="8" rx="2" fill="#476CEC" />
    <rect x="3" y="13" width="8" height="8" rx="2" fill="#476CEC" />
    <rect x="13" y="13" width="8" height="8" rx="2" fill="#476CEC" />
  </Svg>
)
