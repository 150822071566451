import React, { useContext } from 'react'
import { ISvg, Svg } from '../Svg'
import { ThemeContext } from 'styled-components'

const CodePlatform: React.FC<ISvg> = props => {
  const themeContext = useContext(ThemeContext)
  return (
    <Svg viewBox="0 0 34 34" fill="none" {...props}>
      <rect width="34" height="34" rx="8" fill={themeContext.colors.darkBlue_800} />
      <path
        d="M23.8132 6.97646L23.9016 6.7998H24.1081H25.9073H26.3497L26.2023 7.212L24.993 10.2446C23.4002 14.1899 23.4002 18.9008 24.9635 22.8461L26.2023 25.9376L26.3497 26.3498H25.9073H24.1081H23.8427L23.7837 26.1731L22.9283 23.9944C21.0701 19.313 21.0996 13.7483 22.9578 9.09634L23.8132 6.97646Z"
        fill={themeContext.colors.secondaryButtonBg}
      />
      <path
        d="M15.5255 13.8366V10.1563C15.5255 8.30139 16.6168 6.7998 17.9441 6.7998H19.5663H19.8613V7.09423V9.15523V9.44965H19.5663H17.9441C17.8851 9.4791 17.7081 9.74408 17.7081 10.1857V14.6905V14.7788L17.6491 14.8377L16.5283 16.5748L17.6491 18.3119L17.7081 18.3708V18.4591V22.9639C17.7081 23.4055 17.8851 23.6411 17.9441 23.7H19.5663H19.8613V23.9944V26.0554V26.3498H19.5663H17.9441C16.6168 26.3498 15.5255 24.8482 15.5255 22.9933V19.313L13.8148 16.722L13.6968 16.5454L13.8148 16.3687L15.5255 13.8366Z"
        fill={themeContext.colors.secondaryButtonBg}
      />
      <path
        d="M7.94485 6.7998H11.6317H11.9267V7.09423V9.15523V9.44965H11.6317H9.86204V23.6705H11.6612H11.9562V23.9649V26.0259V26.3204H11.6612H7.94485H7.6499V26.0259V7.09423V6.7998H7.94485Z"
        fill={themeContext.colors.secondaryButtonBg}
      />
    </Svg>
  )
}

export default CodePlatform
