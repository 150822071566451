import React from 'react'
import { ISvg, Svg } from '../Svg'

const ImageCircle: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#7F899E" />
    <path
      d="M2.75 11.5L5.06229 9.30941C5.31968 9.06557 5.72261 9.06491 5.98079 9.30792L6.80698 10.0855C7.08355 10.3459 7.5211 10.3239 7.77027 10.0373L10.5153 6.87946C10.7961 6.55645 11.3038 6.57657 11.5581 6.92079L13.8333 10"
      stroke="#7F899E"
      strokeLinecap="square"
    />
    <circle cx="5.5889" cy="5.58893" r="1.17227" stroke="#7F899E" />
  </Svg>
)

export default ImageCircle
