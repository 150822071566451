import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { HugeLinkBox, LinkBox } from '../ProjectMenu/ProjectMenu.styled'

import { Button, ButtonVariant, Icon, Modal, Name } from 'components'
import { ProgressContext } from 'context'
import { Invite, InviteStatus } from 'hooks'
import { auth, firestore } from 'services/firebase'
import { Banner } from '../Workspaces/components'
import { InviteItem } from './components'

interface Props {
  wide?: boolean
}

export const Notifications: React.FC<Props> = ({ wide }) => {
  const { toast } = useContext(ProgressContext)
  const [invites, setInvites] = useState<Invite[]>([])
  const [active, setActive] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const email = auth.currentUser?.email

  useEffect(() => {
    const unsubscribe = firestore
      .collection('invites')
      .where('email', '==', email)
      .where('status', '==', InviteStatus.PENDING)
      .onSnapshot({
        next: res => setInvites(res.docs.map(el => ({ id: el.id, ...el.data() })) as Invite[]),
        error: err => toast(err),
      })
    const observer = new ResizeObserver(() => setShowBanner(document.body.clientHeight > 800))
    if (wide) {
      observer.observe(document.body)
    }
    return () => {
      unsubscribe()
      observer.disconnect()
    }
  }, [])

  return (
    <>
      {wide ? (
        <>
          {showBanner && (
            <Banner invitesLength={invites.length} onClick={!!invites.length ? () => setActive(true) : undefined} />
          )}
          <HugeLinkBox onClick={() => setActive(true)}>
            <Box width="36px" minWidth="36px" display="flex" alignItems="center" justifyContent="center">
              <Icon
                width={24}
                height={24}
                name={!!invites.length ? Name.LEFT_SIDEBAR_ACTIVE_NOTIFICATION : Name.LEFT_SIDEBAR_NOTIFICATION}
              />
            </Box>
            <Box ml="10px" display="flex" alignItems="center" justifyContent="space-between" width="100%">
              Notifications
              <Button variant={ButtonVariant.TOP_PANEL_ACTION}>View</Button>
            </Box>
          </HugeLinkBox>
        </>
      ) : (
        <LinkBox hasHover darkBlue_200>
          <i onClick={() => setActive(true)}>
            <Icon
              width={24}
              height={24}
              name={!!invites.length ? Name.LEFT_SIDEBAR_ACTIVE_NOTIFICATION : Name.LEFT_SIDEBAR_NOTIFICATION}
            />
          </i>
        </LinkBox>
      )}
      <Modal active={active} onClose={() => setActive(false)} title="Notifications" cancelButton={false} width="640px">
        <Box
          height="450px"
          overflow="auto"
          display="flex"
          flexDirection="column"
          gap="10px"
          margin="0 -25px"
          p="0 25px"
        >
          {invites.length === 0 ? (
            <img
              src="/assets/noInvites.gif"
              alt="no invites"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '56%',
                borderRadius: '16px',
              }}
            />
          ) : (
            invites.map(el => <InviteItem key={el.id} invite={el} />)
          )}
        </Box>
      </Modal>
    </>
  )
}
