import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from '../GithubConnectionModal.styled'

import { Button, ButtonColor, ButtonSize, Icon, Name } from 'components'
import { ProgressContext } from 'context'
import { GithubParamsName } from 'pages'
import { ProjectsService } from 'services/projects'
import { githubPopup } from './GithubConnectionPopup'

interface Props {
  close: () => void
  stateParam: number
  setGithubToken: React.Dispatch<React.SetStateAction<string>>
}

export const GitRepository: React.FC<Props> = ({ close, stateParam, setGithubToken }) => {
  const { id, repositoryId } = useParams()
  const { toast } = useContext(ProgressContext)

  const continueWithGithub = () => {
    githubPopup(
      `https://github.com/login/oauth/authorize?scope=user&client_id=${
        process.env.REACT_APP_GITHUB_CLIENT_ID
      }&redirect_uri=${window.location.origin + '/connected'}&state=${stateParam}`
    )
      .then(() => {
        setTimeout(() => {
          const code = localStorage.getItem(GithubParamsName.CODE)
          if (code) {
            localStorage.removeItem(GithubParamsName.CODE)
            ProjectsService.getGithubToken(id as string, repositoryId as string, code)
              .then(res => setGithubToken(res.data.token))
              .catch(err => toast(err))
          }
        }, 0)
      })
      .catch(err => toast(err))
  }

  return (
    <>
      <styled.Content>
        <styled.Title>Git Repository</styled.Title>
        <styled.SubTitle>
          Connect repository to ge the source code. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor.
        </styled.SubTitle>
        <styled.Info>
          <p>No Repository Connected</p>
        </styled.Info>
      </styled.Content>
      <styled.Actions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close} style={{ minWidth: '90px' }}>
          Cancel
        </Button>
        <Button
          color={ButtonColor.BLACK}
          size={ButtonSize.DEFAULT}
          onClick={continueWithGithub}
          leftIcon={<Icon name={Name.SIZE_24_GITHUB} />}
        >
          Continue with GitHub
        </Button>
      </styled.Actions>
    </>
  )
}
