import { useEffect, useState } from 'react'

import { Modal, ProjectSetup, openProjectSetupModalEvent } from 'components'
import { ProjectStatus } from 'hooks'

interface Props {
  id: string
  status: ProjectStatus
}

export const ProjectSetupModal: React.FC<Props> = ({ id, status }) => {
  const [active, setActive] = useState(false)
  const pending = status === ProjectStatus.PENDING || status === ProjectStatus.CREATING

  const openModal = () => setActive(true)

  useEffect(() => {
    const openModal = () => setActive(true)
    document.addEventListener(openProjectSetupModalEvent, openModal)
    return () => document.removeEventListener(openProjectSetupModalEvent, openModal)
  }, [])

  useEffect(() => {
    if (pending) {
      openModal()
    }
  }, [pending])

  return (
    <Modal
      active={active}
      onClose={() => setActive(false)}
      title="Preparing you project"
      cancelButton={false}
      width="580px"
    >
      <ProjectSetup id={id} />
    </Modal>
  )
}
