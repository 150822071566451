import { Box } from '@mui/material'
import React from 'react'

import { Modal, Text, Type } from 'components'

interface Props {
  active: boolean
  onConfirm: (val: unknown) => void
  onCancel: (val: unknown) => void
}

export const Confirm: React.FC<Props> = ({ active, onConfirm, onCancel }) => (
  <Modal
    active={active}
    onClose={() => onCancel(true)}
    title="Leave and lose data"
    onButtonClick={() => onConfirm(true)}
    buttonTitle="Leave and lose data"
    deleteButton
  >
    <Box>
      <Text type={Type.BODY}>You are about to leave the page with unsaved data. Are you sure you want to proceed?</Text>
    </Box>
  </Modal>
)
