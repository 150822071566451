import React, { useContext, useEffect, useState } from 'react'

import { Notification, NotificationColor } from 'components'
import { ProgressContext } from 'context'

const oldVersion = process.env.REACT_APP_RELEASE

export const VersionController: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [newVersion, setNewVersion] = useState('')

  const getVersion = () =>
    fetch('/version.json')
      .then(res => res.json())
      .then(res => {
        const { currentVersion } = res
        if (oldVersion !== currentVersion) {
          setNewVersion(currentVersion)
          clearInterval(timer)
        }
      })
      .catch(err => toast(err))

  useEffect(() => {
    if (!window.location.href.includes('http://localhost')) {
      getVersion()
      const timer = setInterval(getVersion, 60000)
      setTimer(timer)
    }
    return () => clearInterval(timer)
  }, [])

  // @ts-ignore
  const reload = () => window.location.reload(true)

  return (
    <Notification open={!!newVersion} color={NotificationColor.DARK} close={() => setNewVersion('')} onClick={reload} />
  )
}
