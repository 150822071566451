import React from 'react'
import { ISvg, Svg } from '../Svg'

const WebView: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M5.24882 19C5.75931 19 6.05416 18.766 6.20379 18.2374L7.08395 15.2757H7.11916L7.99932 18.2374C8.15335 18.7781 8.4394 19 8.9631 19C9.46479 19 9.74204 18.774 9.90047 18.2455L11.1151 14.1742C11.1679 13.9805 11.2031 13.7868 11.2031 13.6577C11.2031 13.2421 10.9039 13 10.4066 13C9.98409 13 9.73324 13.2098 9.63643 13.6456L8.84428 17.0229H8.80907L7.8805 13.6093C7.77488 13.2058 7.51523 13 7.10156 13C6.67468 13 6.43263 13.1896 6.31821 13.6133L5.39404 17.0229H5.35884L4.56669 13.6456C4.46987 13.2098 4.21903 13 3.79655 13C3.29926 13 3 13.2421 3 13.6577C3 13.7909 3.03521 13.9886 3.08802 14.1742L4.29824 18.2334C4.45667 18.774 4.73392 19 5.24882 19Z"
      fill="#7F899E"
    />
    <path
      d="M14.1473 19C14.6578 19 14.9526 18.766 15.1022 18.2374L15.9824 15.2757H16.0176L16.8978 18.2374C17.0518 18.7781 17.3378 19 17.8615 19C18.3632 19 18.6405 18.774 18.7989 18.2455L20.0135 14.1742C20.0664 13.9805 20.1016 13.7868 20.1016 13.6577C20.1016 13.2421 19.8023 13 19.305 13C18.8825 13 18.6317 13.2098 18.5349 13.6456L17.7427 17.0229H17.7075L16.7789 13.6093C16.6733 13.2058 16.4137 13 16 13C15.5731 13 15.3311 13.1896 15.2167 13.6133L14.2925 17.0229H14.2573L13.4651 13.6456C13.3683 13.2098 13.1175 13 12.695 13C12.1977 13 11.8984 13.2421 11.8984 13.6577C11.8984 13.7909 11.9336 13.9886 11.9865 14.1742L13.1967 18.2334C13.3551 18.774 13.6324 19 14.1473 19Z"
      fill="#7F899E"
    />
    <path
      d="M23.0457 19C23.5562 19 23.851 18.766 24.0007 18.2374L24.8808 15.2757H24.916L25.7962 18.2374C25.9502 18.7781 26.2363 19 26.76 19C27.2617 19 27.5389 18.774 27.6974 18.2455L28.912 14.1742C28.9648 13.9805 29 13.7868 29 13.6577C29 13.2421 28.7007 13 28.2035 13C27.781 13 27.5301 13.2098 27.4333 13.6456L26.6412 17.0229H26.606L25.6774 13.6093C25.5718 13.2058 25.3121 13 24.8984 13C24.4716 13 24.2295 13.1896 24.1151 13.6133L23.1909 17.0229H23.1557L22.3636 13.6456C22.2668 13.2098 22.0159 13 21.5934 13C21.0961 13 20.7969 13.2421 20.7969 13.6577C20.7969 13.7909 20.8321 13.9886 20.8849 14.1742L22.0951 18.2334C22.2536 18.774 22.5308 19 23.0457 19Z"
      fill="#7F899E"
    />
  </Svg>
)

export default WebView
