import React from 'react'

import { ISvg, Svg } from '../Svg'

const Failed: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="9" fill="#E53E3D" />
    <path d="M15 9L9 15" stroke="#FDF1F1" strokeWidth="2" strokeLinecap="round" />
    <path d="M9 9L15 15" stroke="#FDF1F1" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)

export default Failed
