import { memo } from 'react'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  type: string
  value: string | null
  onChange: (val: string | null) => void
}

export const DateInput = memo((props: Props) => (
  <input
    type={props.type}
    value={props.value ? props.value.slice(0, props.type === 'date' ? 10 : 16) : undefined}
    onChange={({ target: { value } }) => props.onChange(value ? new Date(value).toISOString() : null)}
    placeholder={props.placeholder}
    disabled={props.disabled}
  />
))
