import React from 'react'

import { Text, Type } from 'components'
import { SettingsLayout } from '../components'

export const Domains: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Domains</Text>
    <Text type={Type.SUB_TITLE}>Connect your app to a custom domain.</Text>
  </SettingsLayout>
)
