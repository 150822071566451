import React from 'react'
import { ISvg, Svg } from '../Svg'

const Settings: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props} id="settingsIcon">
    <path
      fill="#212133"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1546 14L19.3093 20.6602C18.5948 21.8978 17.2743 22.6602 15.8452 22.6602H8.15463C6.72556 22.6602 5.40506 21.8978 4.69052 20.6602L0.845224 14C0.130693 12.7624 0.130693 11.2376 0.845224 9.99998L4.69053 3.33972C5.40506 2.10212 6.72557 1.33972 8.15463 1.33972L15.8452 1.33972C17.2743 1.33972 18.5948 2.10212 19.3093 3.33972L23.1546 9.99998C23.8692 11.2376 23.8692 12.7624 23.1546 14ZM21.4226 13C21.7798 12.3812 21.7798 11.6188 21.4226 11L17.5773 4.33972C17.22 3.72092 16.5598 3.33972 15.8452 3.33972L8.15463 3.33972C7.4401 3.33972 6.77984 3.72092 6.42258 4.33972L2.57728 11C2.22001 11.6188 2.22001 12.3812 2.57728 13L6.42257 19.6602C6.77984 20.279 7.4401 20.6602 8.15463 20.6602H15.8452C16.5598 20.6602 17.22 20.279 17.5773 19.6602L21.4226 13Z"
    />
    <path
      fill="#212133"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 12C15.9999 14.2091 14.2091 16 11.9999 16C9.79079 16 7.99993 14.2091 7.99993 12C7.99993 9.79084 9.79079 7.99998 11.9999 7.99998C14.2091 7.99998 15.9999 9.79084 15.9999 12ZM11.9999 14C13.1045 14 13.9999 13.1045 13.9999 12C13.9999 10.8954 13.1045 9.99998 11.9999 9.99998C10.8954 9.99998 9.99993 10.8954 9.99993 12C9.99993 13.1045 10.8954 14 11.9999 14Z"
    />
  </Svg>
)

export default Settings
