import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'

export const connectStripeFlag = 'connect-stripe'

interface Props {
  disabled?: boolean
}

export const ConnectStripeButton: React.FC<Props> = ({ disabled }) => (
  <Link
    to={`?${connectStripeFlag}=true`}
    style={{
      textDecoration: 'none',
      pointerEvents: disabled ? 'none' : 'all',
    }}
  >
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.LEFT_SIDEBAR_PROJECT_STRIPE} />}
      disabled={disabled}
    >
      Link to Stripe
    </Button>
  </Link>
)
