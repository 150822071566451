import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { SubscriptionStatus } from 'hooks'
import { firestore } from 'services/firebase'

export interface Project {
  id: string
  name: string
  backend: string
  logoUrl: string
  plan: ProjectPlan
  status: ProjectStatus
  workspace: any
  subscriptionStatus?: SubscriptionStatus
  subscriptionId?: string
  updatedAt?: any
  createdAt?: any
}

export enum ProjectPlan {
  DRAFT = 'DRAFT',
  STARTER = 'STARTER',
  BASIC = 'BASIC',
  PRO = 'PRO',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  CUSTOM = 'CUSTOM',
}

export enum ProjectStatus {
  PENDING = 'PENDING',
  CREATING = 'CREATING',
  READY = 'READY',
  FAILED = 'FAILED',
  ARCHIVED = 'ARCHIVED',
}

export const useProject = (id: string) => {
  const { toast } = useContext(ProgressContext)
  const [project, setProject] = useState<Project>({} as Project)
  const [projectWait, setProjectWait] = useState(true)

  useEffect(() => {
    setProject({} as Project)
    setProjectWait(true)
    if (id) {
      const unsubscribe = firestore
        .collection('projects')
        .doc(id)
        .onSnapshot({
          next: res => {
            const resData = res.data()
            if (resData) {
              setProject({ id: res.id, ...resData } as Project)
              setProjectWait(false)
            }
          },
          error: err => toast(err),
        })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  return { project, projectWait }
}
