import styled from 'styled-components'

export const Templates = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 502px;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
`

export const Template = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`

export const TemplateImage = styled.img`
  width: 185px;
  min-width: 185px;
  height: 400px;
  min-height: 400px;
  border: 2px solid ${props => props.theme.colors.blackBorder};
  border-radius: 22px;
`

export const ExploreTemplates = styled.a`
  text-decoration: none;
  width: 186px;
  min-width: 186px;
  height: 401px;
  min-height: 401px;
  border: 1.5px dashed ${props => props.theme.colors.borderColor};
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: ${props => props.theme.colors.primaryText};
  transition: ${props => props.theme.transition.primary};
  &:hover {
    color: ${props => props.theme.colors.primaryTextHover};
    border-color: ${props => props.theme.colors.primaryText};
    & > svg path {
      stroke: ${props => props.theme.colors.primaryTextHover};
    }
  }
`
