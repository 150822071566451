import styled from 'styled-components'

export const Container = styled.div<{ small?: boolean }>`
  padding: ${props => (props.small ? '0 25px' : '30px 30px 60px')};
  margin: ${props => props.small && '0 -25px'};
  width: ${props => (props.small ? 'calc(100% + 50px)' : '100%')};
  height: ${props => !props.small && 'calc(100vh - 60px)'};
  overflow: ${props => !props.small && 'auto'};
  background-color: ${props => props.theme.colors.secondaryBackground};
`
