import React from 'react'

import * as styled from './MobileMode.styled'

import { Icon, Name } from 'components'

export const MobileMode: React.FC = () => (
  <styled.Wrapper>
    <Icon name={Name.ADDITIONAL_SWITCH_TO_A_LARGER_SCREEN} width={230} height={180} />
    <span>Please switch to a larger screen</span>
    <p>For the full experience, please use a desktop. Mobile access is limited to the app preview.</p>
  </styled.Wrapper>
)
