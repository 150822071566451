import React from 'react'
import { ISvg, Svg } from '../Svg'

const Delete: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M5 9V14C5 15.1046 5.89543 16 7 16H13C14.1046 16 15 15.1046 15 14V9"
      stroke="#DF5636"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13 6L13 4C13 3.44772 12.5523 3 12 3L8 3C7.44772 3 7 3.44772 7 4L7 6"
      stroke="#DF5636"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M4 6L16 6" stroke="#DF5636" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Delete
