import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 14px;
  padding: 10px 16px;
  width: 314px;
  height: 130px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const Title = styled.h3`
  color: ${props => props.theme.colors.darkText};
  font-weight: ${props => props.theme.weights.bold};
  font-size: 14px;
  line-height: 21px;
`

export const TitleAction = styled(Link)`
  display: flex;
  align-items: center;
  & svg {
    & path {
      transition: ${props => props.theme.transition.primary};
      stroke: ${props => props.theme.colors.primaryText};
    }
    &:hover {
      & path {
        stroke: ${props => props.theme.colors.primaryTextHover};
      }
    }
  }
`

export const Value = styled.span`
  color: ${props => props.theme.colors.darkText};
  font-weight: ${props => props.theme.weights.medium};
  font-size: 34px;
  line-height: 51px;
`

export const Description = styled.div`
  color: ${props => props.theme.colors.darkText};
  font-size: 14px;
  line-height: 21px;
`
