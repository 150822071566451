import React from 'react'
import { ISvg, Svg } from '../Svg'

const Divider: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M13 8L3 8" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Divider
