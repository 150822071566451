import React from 'react'

import { GradientPicker, Icon, Name } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { BackgroundGradient } from 'utils'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  value?: BackgroundGradient
  onChange: (val?: BackgroundGradient) => void
  disabled?: boolean
  insidePicker?: boolean
}

export const GradientInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  value,
  onChange,
  disabled,
  insidePicker,
}) => (
  <SettingField
    big={big}
    small={small}
    label={label}
    subLabel={subLabel}
    insidePicker={insidePicker}
    value={value ? value.direction : undefined}
    leftIcon={insidePicker ? undefined : <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_COLOR} />}
    disabled={disabled}
    optionsContainer={<GradientPicker close={() => {}} value={value} onChange={onChange} />}
    actions={value ? <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onChange()} /> : undefined}
  />
)
