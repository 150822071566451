import styled from 'styled-components'

export const PlusBorderBox = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${props => props.theme.colors.darkText};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease;
  width: 90px;
  height: 90px;
`

export const Box = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colors.primaryBackground};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    ${PlusBorderBox} {
      width: 100px;
      height: 100px;
      margin-bottom: 12px;
    }
  }
`

export const PlusBox = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`

interface IPlusLine {
  orientation: 'horizontal' | 'vertical'
}

export const PlusLine = styled.div<IPlusLine>`
  width: 2px;
  height: 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.darkText};
  transform: rotate(${(props: IPlusLine) => (props.orientation === 'vertical' ? '0deg' : '90deg')});
  position: absolute;
  top: 0;
  left: 9px;
`

export const ModalContent = styled.div`
  & > p {
    color: ${props => props.theme.colors.darkText1};
    font-size: 14px;
    line-height: 150%;
  }
`
