import { useEffect, useState } from 'react'

import { MovableComponentsContext } from 'context'
import { ScreenComponent } from 'utils'

export const movableElementId = 'movableElementId'

const cursorStyle = document.createElement('style')
cursorStyle.innerHTML = '*{cursor: grabbing!important;}'

interface Props {
  children: React.ReactNode
}

export const MovableComponentsProvider: React.FC<Props> = ({ children }) => {
  const [movableComponent, setMovableComponent] = useState<ScreenComponent | null>(null)
  const [movableTabBarComponent, setMovableTabBarComponent] = useState(false)
  const [movableTopBarComponent, setMovableTopBarComponent] = useState(false)
  // const [movableTabComponent, setMovableTabComponent] = useState<TabBarItem | null>(null)

  useEffect(() => {
    if (
      movableComponent ||
      movableTabBarComponent ||
      movableTopBarComponent
      // || movableTabComponent
    ) {
      document.body.style.userSelect = 'none'
      document.head.appendChild(cursorStyle)
      document.onmouseup = () => {
        setMovableComponent(null)
        setMovableTabBarComponent(false)
        setMovableTopBarComponent(false)
        // setMovableTabComponent(null)
        const movable = document.getElementById(movableElementId)
        if (movable) {
          movable.parentNode?.removeChild(movable)
          document.onmousemove = () => {}
        }
        document.onmouseup = () => {}
      }
    }
    return () => {
      document.body.style.removeProperty('user-select')
      cursorStyle.parentNode?.removeChild(cursorStyle)
    }
  }, [
    movableComponent,
    movableTabBarComponent,
    movableTopBarComponent,
    // movableTabComponent
  ])

  return (
    <MovableComponentsContext.Provider
      value={{
        movableComponent,
        setMovableComponent,
        movableTabBarComponent,
        setMovableTabBarComponent,
        movableTopBarComponent,
        setMovableTopBarComponent,
        // movableTabComponent,
        // setMovableTabComponent,
      }}
    >
      {children}
    </MovableComponentsContext.Provider>
  )
}
