import { memo, useRef, useState, useEffect } from 'react'
import { usePicker } from '../context'
import { barWrap, barWrapInner, cResize, handle, psRl } from '../style'
import { usePaintHue } from '../hooks/usePaintHue'

export const Hue = memo(() => {
  const barRef = useRef(null)
  const { handleHue, internalHue, squareSize } = usePicker()
  const [dragging, setDragging] = useState(false)
  usePaintHue(barRef, squareSize)

  const stopDragging = () => {
    setDragging(false)
  }

  const handleMove = e => {
    if (dragging) {
      handleHue(e)
    }
  }

  const onMouseDown = e => {
    setDragging(true)
    handleHue(e)
  }

  useEffect(() => {
    if (dragging) {
      document.body.onmouseup = stopDragging
      return () => {
        document.body.onmouseup = () => {}
      }
    }
  }, [dragging])

  return (
    <div style={{ ...barWrap, width: squareSize + 36 }}>
      <div style={{ ...psRl, ...barWrapInner, width: squareSize + 30 }}>
        <div
          style={{ ...psRl, ...cResize }}
          onMouseDown={onMouseDown}
          onMouseMove={handleMove}
          className="c-resize ps-rl"
        >
          <div style={{ ...handle, left: internalHue * ((squareSize - 18) / 360), top: -2 }} />
          <canvas
            ref={barRef}
            width={`${squareSize}px`}
            height="14px"
            style={{ position: 'relative', borderRadius: 14 }}
          />
        </div>
      </div>
    </div>
  )
})
