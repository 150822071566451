export const getShadow = () => {
  const shadow = document.createElement('div')
  document.body.appendChild(shadow)
  shadow.style.width = '0'
  shadow.style.height = '0'
  shadow.style.overflow = 'hidden'
  shadow.style.position = 'fixed'
  shadow.style.top = '0'
  shadow.style.left = '0'
  shadow.style.zIndex = '-1'
  return shadow
}
