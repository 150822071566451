import { css } from 'styled-components'

export const FS64 = css`
  font-size: 64px;
  line-height: 117.5%;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 40px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 30px;
  }
`

export const FS60 = css`
  font-size: 60px;
  line-height: 70px;

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    font-size: 30px;
    line-height: 35px;
  }
`

export const FS50 = css`
  font-size: 50px;
  line-height: 120%;
  font-weight: ${props => props.theme.weights.extraBold};

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 35px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    font-size: 24px;
  }
`

export const FS42 = css`
  font-size: 42px;
  line-height: 125%;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 35px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    font-size: 24px;
  }
`

export const FS42_LG = css`
  font-size: 42px;
  line-height: 125%;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 35px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    font-size: 24px;
  }
`

export const FS40 = css`
  font-size: 40px;
  line-height: 47px;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 30px;
    line-height: 35px;
  }
`

export const FS40_EXTENDED = css`
  font-size: 40px;
  line-height: 47px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 24px;
    line-height: 28px;
  }
`

export const FS32 = css`
  font-size: 32px;
  line-height: 38px;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 24px;
    line-height: 28px;
  }
`

export const FS30 = css`
  font-size: 30px;
  line-height: 35px;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 20px;
    line-height: 23px;
  }
`

export const FS27 = css`
  font-size: 27px;
  line-height: 34px;
  font-weight: ${props => props.theme.weights.extraBold};

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    font-size: 19px;
  }
`

export const FS24 = css`
  font-size: 24px;
  line-height: 115%;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 22px;
  }
`

export const FS24toFS20 = css`
  font-size: 24px;
  line-height: 120%;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 20px;
  }
`

export const FS22 = css`
  font-size: 22px;
  line-height: 145%;
  font-weight: ${props => props.theme.weights.regular};

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 18px;
  }
`

export const FS20 = css`
  font-weight: ${props => props.theme.weights.regular};
  font-size: 20px;
  line-height: 23px;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const FS18 = css`
  font-size: 18px;
  line-height: 145%;
  font-weight: ${props => props.theme.weights.regular};

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 16px;
  }
`

export const FS17_NOT_CHANGE = css`
  font-size: 17px;
  line-height: 150%;
  font-weight: ${props => props.theme.weights.regular};
`

export const FS17 = css`
  ${FS17_NOT_CHANGE}

  @media (max-width: ${props => props.theme.breakpoints.xl}px) {
    font-size: 15px;
  }
`

export const FS16 = css`
  font-size: 16px;
  line-height: 19px;
  font-weight: ${props => props.theme.weights.regular};

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    line-height: 145%;
  }
`

export const FS15 = css`
  font-size: 15px;
  line-height: 150%;
  font-weight: ${props => props.theme.weights.regular};
`

export const FS14 = css`
  font-size: 14px;
  line-height: 16px;
  font-weight: ${props => props.theme.weights.regular};
`

export const FS10 = css`
  font-size: 10px;
  line-height: 130%;
  letter-spacing: -0.408px;
  font-weight: ${props => props.theme.weights.regular};
`
