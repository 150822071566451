import { Box } from '@mui/material'
import { showReportDialog } from '@sentry/react'
import { useEffect, useState } from 'react'

import { ContentBox, Title, UnderTitle } from '../NotFound/NotFound.styled'

import { Icon, Name } from 'components'
import { LandingLayout } from 'partials'
import { BlurBox } from 'partials/LandingLayout/components/BlurBox'
import { Button } from 'partials/LandingLayout/components/Button'
import firebase, { auth } from 'services/firebase'
import Container from '../../components/Container/Container'

export const SomethingWentWrong = (props: any) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const authorized = user && !user.isAnonymous

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser)
    return () => {
      unsubscribe()
    }
  }, [])

  const openDialog = () =>
    authorized &&
    showReportDialog({
      eventId: props.eventId,
      user: { email: user.email || undefined, name: user.displayName || undefined },
      lang: 'en',
    })

  return (
    <LandingLayout>
      <ContentBox maxHeight={780} maxHeightMobileLg={690} maxHeightMobileMd={630}>
        <Container>
          <Box display="flex" flexDirection="column" alignItems="center">
            <BlurBox $hasWrapper>
              <Icon name={Name.ADDITIONAL_NOT_FOUND} width={226} height={149} />
              <Title>Something went wrong</Title>
              <UnderTitle>
                While we check thingsbon our end, refresh the page and <span onClick={openDialog}>contact support</span>{' '}
                if the problem persists.
              </UnderTitle>
              <a href="/">
                <Button>Back to home</Button>
              </a>
            </BlurBox>
          </Box>
        </Container>
      </ContentBox>
    </LandingLayout>
  )
}
