import React from 'react'
import { ISvg, Svg } from '../Svg'

const ShowPW: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
      stroke="#7F899E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8201 5.77047C16.0701 4.45047 14.0701 3.73047 12.0001 3.73047C8.47009 3.73047 5.18009 5.81047 2.89009 9.41047C1.99009 10.8205 1.99009 13.1905 2.89009 14.6005C3.68009 15.8405 4.60009 16.9105 5.60009 17.7705"
      stroke="#7F899E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.41992 19.5297C9.55992 20.0097 10.7699 20.2697 11.9999 20.2697C15.5299 20.2697 18.8199 18.1897 21.1099 14.5897C22.0099 13.1797 22.0099 10.8097 21.1099 9.39969C20.7799 8.87969 20.4199 8.38969 20.0499 7.92969"
      stroke="#7F899E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5099 12.7002C15.2499 14.1102 14.0999 15.2602 12.6899 15.5202"
      stroke="#7F899E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.47 14.5303L2 22.0003" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 2L14.53 9.47" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
)
export default ShowPW
