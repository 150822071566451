import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'

import { CustomSection, InputProperty, inputs, validate } from 'components'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'appIcon',
    type: ValueType.appIcon,
    position: 0,
    title: 'App icon',
    helpText: 'The name of your app as it will appear on the app stores.',
    smallWidth: true,
  },
]

export const AppIcon: React.FC = () => {
  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  return (
    <CustomSection style={{ padding: '25px' }}>
      <Box mt="-30px">{inputs(properties, formik)}</Box>
    </CustomSection>
  )
}
