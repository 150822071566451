import { Box } from '@mui/material'
import React from 'react'

import { CustomPicker } from 'components'
import { SettingArrayField } from 'partials/RightSidebar/components'

interface Props {
  value?: string[]
  onChange: (val?: string[]) => void
  close: () => void
}

export const ValuesPicker: React.FC<Props> = ({ value, onChange, close }) => (
  <CustomPicker
    title="Values"
    close={close}
    optionsContainer={
      <Box maxHeight="500px" overflow="auto">
        <Box p="0 10px 10px 10px">
          <SettingArrayField value={value} onChange={onChange} />
        </Box>
      </Box>
    }
  />
)
