import { Box } from '@mui/material'
import React from 'react'

import { CustomField, SubPicker } from 'components'
import { SettingTextField } from 'partials/RightSidebar/components'

interface Props {
  label: string
  value?: string
  onChange: (val: string) => void
  setSubPicker: (
    value: React.SetStateAction<React.ReactElement<any, string | React.JSXElementConstructor<any>> | null>
  ) => void
}

export const StringField: React.FC<Props> = ({ label, value, onChange, setSubPicker }) => (
  <CustomField
    label={label}
    value={value}
    onClick={() =>
      setSubPicker(() => (
        <SubPicker onBack={() => setSubPicker(null)}>
          <Box m="0 10px 13px">
            <SettingTextField small subLabel={label} value={value} onChange={onChange} />
          </Box>
        </SubPicker>
      ))
    }
  />
)
