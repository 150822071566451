import styled from 'styled-components'

export const Textarea = styled.div<{ active: boolean; hasBottomPanel: boolean }>`
  border-radius: ${props => (props.hasBottomPanel ? '8px 8px 0 0' : '8px')};
  background: ${props => (props.active ? props.theme.colors.secondaryButtonBg1 : props.theme.colors.settingField)};
  transition: background 300ms ease-out;
  border: 1px solid ${props => (props.active ? props.theme.colors.borderColorToLightBorder : 'transparent')};
  box-sizing: border-box;
  padding: 5px 8px;
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  outline: none;
  &:hover {
    background: ${props =>
      props.active ? props.theme.colors.secondaryButtonBg1 : props.theme.colors.settingFieldHover};
  }
  & div[contenteditable='false'] {
    display: inline-flex;
    background: ${props => (props.active ? '#e8f5fa' : '#aed7e7')};
    transition: ${props => props.theme.transition.primary};
    border-radius: 4px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 17px;
    color: #1264a3;
    cursor: pointer;
  }
  &:empty:before {
    content: attr(placeholder);
    color: ${props => props.theme.colors.grey1};
  }
`

export const TextareaBottomPanel = styled.div`
  border-radius: 0 0 8px 8px;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  border-top: unset;
  background: ${props => props.theme.colors.secondaryButtonBg1};
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  display: flex;
  justify-content: space-between;
  padding: 5px 8px;
`

export const InsertVariable = styled.span`
  cursor: pointer;
  transition: 300ms ease-out;
  &:hover {
    color: ${props => props.theme.colors.iconHover};
  }
`
