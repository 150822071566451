import { memo, useRef } from 'react'

import * as styled from './AvatarInput.styled'

import { Avatar, Button, ButtonColor, Icon, Name } from 'components'
import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const AvatarInput = memo((props: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.files?.[0] && props.onChange(URL.createObjectURL(e.target.files[0]))

  const onRemove = () => {
    props.onChange(null)
    if (ref.current) {
      ref.current.value = ''
    }
  }

  return (
    <styled.AvatarInput>
      <Avatar value={props.value} width={65} logo={props.logo} />
      <Button color={ButtonColor.SECONDARY}>
        <input ref={ref} type="file" onChange={onChange} />
        Change
      </Button>
      {props.value && <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={onRemove} />}
    </styled.AvatarInput>
  )
})
