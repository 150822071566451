import { useState } from 'react'

import * as styled from 'components/Menu/Menu.styled'

import { NavHeadSearch } from 'components'
import { includesSearch } from 'utils'
import { Section } from './Section'
import { sections } from './constants'

export const ComponentsToAdd = () => {
  const [search, setSearch] = useState('')

  return (
    <>
      <NavHeadSearch title="Components" search={search} setSearch={setSearch} />
      <styled.NavBody paddingBottom={25}>
        {sections.map(el => (
          <Section
            key={el.name}
            title={el.name}
            components={el.components.filter(el => includesSearch(el.name, search))}
          />
        ))}
      </styled.NavBody>
    </>
  )
}
