import styled from 'styled-components'

export const ActionsLabel = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  margin-bottom: 6px;
`

export const Actions = styled.div`
  & > div {
    margin-top: 1px;
    &:first-child > div > div {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child > div > div {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`
