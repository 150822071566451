import { memo } from 'react'

import * as styled from './VariableInput.styled'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const VariableInput = memo((props: Props) => (
  <styled.VariableInput>
    <input value={props.name} disabled />
    <input
      value={props.value || undefined}
      onChange={({ target: { value } }) => props.onChange(value || null)}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  </styled.VariableInput>
))
