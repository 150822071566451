import { GetVariableValue } from 'hooks'
import {
  BackgroundGradient,
  BackgroundGradientDirection,
  BrandingColorStyles,
  BrandingThemeType,
  MediaConstant,
  Resource,
  StringVariable,
  getColor,
} from 'utils'

export const setColors = async (
  element: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  getVariableValue: GetVariableValue,
  backgroundColor?: StringVariable,
  backgroundGradient?: BackgroundGradient,
  backgroundPattern?: MediaConstant,
  textColor?: StringVariable,
  borderColor?: StringVariable
) => {
  if (backgroundColor) {
    element.style.backgroundColor = await getColor(backgroundColor, colorStyles, theme, getVariableValue)
  }

  if (backgroundGradient) {
    const { direction, start, end } = backgroundGradient
    const startColor = await getColor({ constant: start }, colorStyles, theme, getVariableValue)
    const endColor = await getColor({ constant: end }, colorStyles, theme, getVariableValue)
    element.style.backgroundImage =
      direction === BackgroundGradientDirection.radial
        ? `radial-gradient(${startColor}, ${endColor})`
        : `linear-gradient(to ${
            direction === BackgroundGradientDirection.vertical ? 'bottom' : 'right'
          }, ${startColor}, ${endColor})`
  }

  if (backgroundPattern) {
    const url = resources.find(el => el.id === backgroundPattern.resourceId)?.url || ''
    element.style.backgroundImage = `url(${url})`
    element.style.backgroundPosition = 'center'
    element.style.backgroundSize = 'cover'
  }

  if (borderColor) {
    element.style.borderColor = await getColor(borderColor, colorStyles, theme, getVariableValue)
  }

  if (textColor) {
    element.style.color = await getColor(textColor, colorStyles, theme, getVariableValue)
  }
}
