import { Box } from '@mui/material'
import React from 'react'

import * as styled from './NoContent.styled'

import { Button, ButtonColor, ButtonSize, Icon, Name, Text, Type } from 'components'

interface Props {
  children?: React.ReactNode
  text: string
  fullHeight?: boolean
  isTable?: boolean
  title?: string
  buttonTitle?: string
  onButtonClick?: () => void
  secondButtonTitle?: string
  onSecondButtonClick?: () => void
}

export const NoContent: React.FC<Props> = ({
  children,
  text,
  fullHeight,
  isTable,
  title,
  buttonTitle,
  onButtonClick,
  secondButtonTitle,
  onSecondButtonClick,
}) => {
  const isContainerWithButton = !!(title && text && buttonTitle && onButtonClick && secondButtonTitle)

  const Container = isContainerWithButton ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m="50px 0 20px"
      whiteSpace="normal"
    >
      <Text type={Type.TITLE} styles={{ textAlign: 'center', maxWidth: '365px' }}>
        {title}
      </Text>
      <Text type={Type.SUB_TITLE} styles={{ textAlign: 'center', maxWidth: '458px', marginBottom: '30px' }}>
        {text}
      </Text>
      <Button size={ButtonSize.DEFAULT} style={{ minWidth: '130px', height: '50px' }} onClick={onButtonClick}>
        {buttonTitle}
      </Button>
      <Button color={ButtonColor.ACTION} style={{ width: '130px', height: '50px' }} onClick={onSecondButtonClick}>
        {secondButtonTitle}
      </Button>
    </Box>
  ) : (
    <styled.Container fullHeight={fullHeight}>
      <Icon name={Name.ADDITIONAL_NO_CONTENT} width={40} height={40} />
      <p>{text}</p>
      {children}
    </styled.Container>
  )

  return isTable ? (
    <styled.TalbeBody>
      <tr>
        <td colSpan={100}>{Container}</td>
      </tr>
    </styled.TalbeBody>
  ) : (
    Container
  )
}
