import React from 'react'

import * as styled from '../CustomPicker.styled'

import { CustomField, SubPicker } from 'components'
import { includesSearch } from 'utils'

interface Props {
  label: string
  value?: string
  onChange: (val: string) => void
  setSubPicker: (
    value: React.SetStateAction<React.ReactElement<any, string | React.JSXElementConstructor<any>> | null>
  ) => void
  options: string[]
}

export const SelectStringValueField: React.FC<Props> = ({ label, value, onChange, setSubPicker, options }) => (
  <CustomField
    label={label}
    value={value}
    onClick={() =>
      setSubPicker(() => (
        <SubPicker
          onBack={() => setSubPicker(null)}
          ItemsContent={({ search }) => (
            <>
              {options
                .filter(el => includesSearch(el, search))
                .map(el => (
                  <styled.Item
                    key={el}
                    color="darkText1"
                    active={el === value}
                    onClick={() => {
                      onChange(el)
                      setSubPicker(null)
                    }}
                  >
                    <styled.ItemLabel>{el}</styled.ItemLabel>
                  </styled.Item>
                ))}
            </>
          )}
        />
      ))
    }
  />
)
