import styled from 'styled-components'

export const RecordHead = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  height: 56px;
`

export const RecordContent = styled.div`
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  height: 51px;
`
