import React, { useEffect } from 'react'

import { auth } from 'services/firebase'
import { landingUrl } from 'utils'

export const Bridge: React.FC = () => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      user =>
        window.location !== window.parent.location &&
        landingUrl &&
        window.parent.postMessage(
          {
            user:
              user && !user.isAnonymous
                ? { uid: user.uid, displayName: user.displayName, photoUrl: user.photoURL }
                : null,
          },
          landingUrl
        )
    )
    return () => {
      unsubscribe()
    }
  }, [])

  return <></>
}
