import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'
import * as styled from '../../GithubConnectionModal/GithubConnectionModal.styled'

import { Button, ButtonColor, ButtonSize, Icon, Loader, LoaderVariant, Name, Text, Type } from 'components'
import { ProgressContext } from 'context'
import { useInputAutoFocusRef } from 'hooks'
import { ProjectsService } from 'services/projects'
import { Terms } from './Terms'

interface Props {
  close: () => void
}

export const Login: React.FC<Props> = ({ close }) => {
  const themeContext = useContext(ThemeContext)
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const inputAutoFocusElement = useInputAutoFocusRef(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [wait, setWait] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const startAppstoreSession = () => {
    if (username && password) {
      setWait(true)
      ProjectsService.startAppstoreSession(id as string, { username, password })
        .catch(err => toast(err))
        .finally(() => setWait(false))
    }
  }

  return wait ? (
    <Box height="388px">
      <Loader variant={LoaderVariant.PACMAN} />
    </Box>
  ) : (
    <>
      <styled.Content>
        <styled.SubTitle>Connect App Store Connect to enable automated publishing and integrations</styled.SubTitle>
        <Box display="flex" flexDirection="column" gap="30px">
          <Box>
            <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
              Apple ID
            </Text>
            <InputWrapper inputEmpty={!username}>
              <input
                value={username}
                onChange={e => setUsername(e.target.value)}
                ref={inputAutoFocusElement}
                data-1p-ignore
              />
            </InputWrapper>
          </Box>
          <Box>
            <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
              Apple Password
            </Text>
            <InputWrapper inputEmpty={!password} hasIcon>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                data-1p-ignore
              />
              <Icon
                onClick={() => setShowPassword(show => !show)}
                name={showPassword ? Name.ADDITIONAL_HIDE_PW : Name.ADDITIONAL_SHOW_PW}
              />
            </InputWrapper>
          </Box>
        </Box>
        <Terms mt="10px" />
      </styled.Content>
      <styled.Actions>
        <Button size={ButtonSize.DEFAULT} color={ButtonColor.SECONDARY} onClick={close} style={{ minWidth: '90px' }}>
          Cancel
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          onClick={startAppstoreSession}
          disabled={!username || !password}
          style={{ minWidth: '90px' }}
        >
          Continue
        </Button>
      </styled.Actions>
    </>
  )
}
