import { GetVariableValue } from 'hooks'
import {
  BooleanVariable,
  StringVariable,
  progressActiveAttribute,
  progressIndicatorAttribute,
  progressPassedAttribute,
} from 'utils'

const nextEvent = 'nextEvent'
export const progressIndicatorHandler = async (
  fromVB: boolean,
  progressIndicator: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: StringVariable,
  duration?: number,
  loop?: BooleanVariable
) => {
  const progressParts = Array.from(
    progressIndicator.querySelectorAll(`:scope > i > svg > rect[${progressIndicatorAttribute}]`)
  ) as SVGRectElement[]
  if (progressParts.length) {
    const lastIndex = progressParts.length - 1
    const loopValue = loop?.source ? (await getVariableValue(loop.source)) === 'true' : !!loop?.constant
    progressParts.forEach((el, i) =>
      el.addEventListener(nextEvent, () => {
        progressParts.slice(0, i).forEach(el => {
          el.setAttribute(progressPassedAttribute, '')
          el.removeAttribute(progressActiveAttribute)
        })
        progressParts.slice(i + 1).forEach(el => {
          el.removeAttribute(progressPassedAttribute)
          el.removeAttribute(progressActiveAttribute)
        })
        progressParts[i].removeAttribute(progressPassedAttribute)
        progressParts[i].setAttribute(progressActiveAttribute, '')
        const next = () => {
          if (progressParts[i + 1]) {
            progressParts[i + 1].dispatchEvent(new Event(nextEvent))
          } else if (loopValue) {
            progressParts[0].dispatchEvent(new Event(nextEvent))
          }
        }
        if (!document.getElementById(progressIndicator.id) || !fromVB) {
          return
        } else {
          setTimeout(next, (duration || 5) * 1000)
        }
      })
    )
    const valueValue = value?.source ? await getVariableValue(value.source) : value?.constant
    const currentValue = valueValue ? +valueValue : 0
    progressParts[currentValue > lastIndex ? 0 : currentValue].dispatchEvent(new Event(nextEvent))
  }
}
