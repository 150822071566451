import React from 'react'
import { ISvg, Svg } from '../Svg'

const Dark: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21.4118 14.3808C21.3261 14.2954 21.218 14.2359 21.1 14.2094C20.982 14.1829 20.8589 14.1904 20.7449 14.231C19.2138 14.7769 17.5592 14.8772 15.9733 14.5201C14.3875 14.163 12.9355 13.3633 11.7861 12.2138C10.6366 11.0644 9.837 9.61236 9.48001 8.02651C9.12302 6.44065 9.22336 4.78605 9.76936 3.25495C9.80993 3.141 9.81737 3.01788 9.79082 2.89986C9.76427 2.78185 9.70481 2.67378 9.61935 2.58818C9.53388 2.50258 9.4259 2.44295 9.30792 2.41622C9.18995 2.38948 9.06682 2.39673 8.9528 2.43712C7.57238 2.92458 6.31966 3.71706 5.28787 4.75559C3.4382 6.60663 2.39941 9.11648 2.3999 11.7333C2.4004 14.35 3.44014 16.8595 5.2905 18.7098C7.14087 20.5602 9.65036 21.5999 12.2672 21.6004C14.884 21.6009 17.3939 20.5621 19.2449 18.7125C20.2836 17.6805 21.076 16.4276 21.5634 15.0469C21.6037 14.9329 21.6108 14.8098 21.5839 14.6919C21.5571 14.574 21.4974 14.4661 21.4118 14.3808Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Dark
