import { Collection } from 'utils'

export const collections = [
  {
    name: 'companies',
    datasource: {
      type: 'firestore',
      databaseName: '(default)',
      collectionName: 'companies',
    },
    properties: [
      {
        isRequired: true,
        isHidden: true,
        name: 'createdAt',
        type: 'dateTime',
        position: 0,
      },
      {
        isRequired: true,
        isHidden: true,
        name: 'updatedAt',
        type: 'dateTime',
        position: 1,
      },
      {
        isHidden: true,
        name: 'deletedAt',
        type: 'dateTime',
        position: 2,
      },
      {
        isHidden: true,
        name: 'ownerId',
        type: 'string',
        position: 3,
      },
      {
        isRequired: true,
        isHidden: true,
        name: 'id',
        type: 'string',
        position: 4,
      },
      {
        isRequired: true,
        name: 'title',
        type: 'string',
        position: 5,
      },
      {
        isRequired: true,
        name: 'description',
        type: 'string',
        position: 6,
      },
      {
        isRequired: true,
        name: 'logo',
        type: 'image',
        position: 7,
      },
      {
        isRequired: true,
        name: 'location',
        type: 'string',
        position: 8,
      },
      {
        isRequired: true,
        name: 'batchName',
        type: 'string',
        position: 9,
      },
      {
        isRequired: true,
        name: 'primaryCategory',
        type: 'string',
        position: 10,
      },
      {
        isRequired: true,
        name: 'secondaryCategory',
        type: 'string',
        position: 11,
      },
    ],
    permissions: {
      list: 'Anyone',
      get: 'Anyone',
      create: 'No-one',
      update: 'No-one',
      delete: 'No-one',
    },
  },
] as Collection[]
