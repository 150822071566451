import React from 'react'

import { ArrayConstantPicker, Icon, Name } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { AnyVariable, LocalVariable, Screen } from 'utils'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  value?: AnyVariable[]
  onChange: (val?: AnyVariable[]) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  disabled?: boolean
  insidePicker?: boolean
}

export const ArrayConstantInput: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  value,
  onChange,
  screenConfig,
  allLocalVariables,
  disabled,
  insidePicker,
}) => (
  <SettingField
    big={big}
    small={small}
    label={label}
    subLabel={subLabel}
    insidePicker={insidePicker}
    value={value ? `[${value.length} items]` : undefined}
    leftIcon={insidePicker ? undefined : <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
    disabled={disabled}
    optionsContainer={
      <ArrayConstantPicker
        close={() => {}}
        value={value}
        onChange={onChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    }
    actions={value ? <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onChange()} /> : undefined}
  />
)
