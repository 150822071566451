import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'

import { Modal, Recaptcha, Text, Type, recaptchaVerifierId } from 'components'
import { ProgressContext } from 'context'
import { MultiFactorUser, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth'
import { auth } from 'services/firebase'

interface Props {
  multiFactorUser: MultiFactorUser
  sendCodeModal: boolean
  setSendCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  setVerificationId: React.Dispatch<React.SetStateAction<string>>
}

export const SendCode: React.FC<Props> = ({ multiFactorUser, sendCodeModal, setSendCodeModal, setVerificationId }) => {
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [wait, setWait] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')

  const close = () => {
    setSendCodeModal(false)
    setPhoneNumber('')
  }

  const onClick = () => {
    setWait(true)
    startLoader()
    multiFactorUser.getSession().then(session => {
      // @ts-ignore
      const phoneAuthProvider = new PhoneAuthProvider(auth)
      const phoneInfoOptions = { phoneNumber, session }
      phoneAuthProvider
        // @ts-ignore
        .verifyPhoneNumber(phoneInfoOptions, new RecaptchaVerifier(auth, recaptchaVerifierId, { size: 'invisible' }))
        .then(verificationId => {
          setVerificationId(verificationId)
          close()
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    })
  }

  return (
    <>
      <Modal
        active={sendCodeModal}
        onClose={close}
        title="Add phone number"
        onButtonClick={onClick}
        buttonDisabled={!phoneNumber}
        buttonTitle="Send code"
        buttonLoading={wait}
      >
        <Box>
          <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
            Phone number
          </Text>
          <InputWrapper inputEmpty={phoneNumber === ''}>
            <PhoneInput country="us" value={phoneNumber} onChange={setPhoneNumber} />
          </InputWrapper>
        </Box>
      </Modal>
      {wait && <Recaptcha />}
    </>
  )
}
