import React from 'react'
import { ISvg, Svg } from '../Svg'

const Number: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M4.74305 12.0698C5.28739 12.0698 5.65435 11.7029 5.65435 11.1463V4.01494C5.65435 3.37886 5.26292 2.98132 4.6085 2.98132C4.2293 2.98132 3.93573 3.03636 3.5076 3.32994L1.7584 4.54092C1.47094 4.74276 1.36085 4.9507 1.36085 5.23204C1.36085 5.62347 1.63607 5.89258 2.00916 5.89258C2.19876 5.89258 2.32719 5.84977 2.49844 5.72745L3.78894 4.82838H3.82564V11.1463C3.82564 11.7029 4.19872 12.0698 4.74305 12.0698ZM8.49834 11.9414H13.3851C13.8622 11.9414 14.1496 11.6417 14.1496 11.2075C14.1496 10.761 13.8622 10.4735 13.3851 10.4735H10.0579V10.4368L11.9662 8.60202C13.4096 7.23812 13.9356 6.56535 13.9356 5.48892C13.9356 3.97213 12.6756 2.92016 10.8041 2.92016C9.13441 2.92016 8.0274 3.83146 7.72771 4.81003C7.6849 4.93847 7.66043 5.06691 7.66043 5.20758C7.66043 5.66629 7.95401 5.95986 8.44941 5.95986C8.84696 5.95986 9.06102 5.78861 9.26897 5.42164C9.58701 4.71217 10.0763 4.35744 10.7858 4.35744C11.5747 4.35744 12.1374 4.87731 12.1374 5.60513C12.1374 6.2412 11.8561 6.66933 10.7674 7.71518L8.13749 10.2289C7.76441 10.5714 7.62985 10.8038 7.62985 11.1524C7.62985 11.6173 7.91731 11.9414 8.49834 11.9414Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Number
