import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { connectGithubFlag } from 'hooks'

export const ConnectGithubButton: React.FC = () => (
  <Link to={`?${connectGithubFlag}=true`} style={{ textDecoration: 'none' }}>
    <Button variant={ButtonVariant.LINK} color={ButtonColor.SECONDARY} leftIcon={<Icon name={Name.SIZE_24_GITHUB} />}>
      Link to GitHub
    </Button>
  </Link>
)
