import React from 'react'
import { ISvg, Svg } from '../Svg'

const File: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M10.9 1.95L10.3 2.4L10.9 1.95C10.5695 1.50934 10.0508 1.25 9.5 1.25H4C3.0335 1.25 2.25 2.0335 2.25 3V13C2.25 13.9665 3.0335 14.75 4 14.75H12C12.9665 14.75 13.75 13.9665 13.75 13V6.33333C13.75 5.95468 13.6272 5.58625 13.4 5.28333L12.1 3.55L10.9 1.95Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path d="M5 6L7 6" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 9H11" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 12H11" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M9.5 2V5C9.5 5.55228 9.94772 6 10.5 6H13.5" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default File
