import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PlanPicker } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { Project, usePlanParamsFromUrl, useWorkspaces, Workspace } from 'hooks'
import { auth, firestore } from 'services/firebase'

const currentWorkspaceKey = 'currentWorkspaceKey'
const getCurrentWorkspaceController = () => JSON.parse(localStorage.getItem(currentWorkspaceKey) || '{}')
const setCurrentWorkspaceController = (controller: any) =>
  localStorage.setItem(currentWorkspaceKey, JSON.stringify(controller))

interface Props {
  children: React.ReactNode
  isWorkspacesPage: boolean
  wide: boolean
}

export const WorkspaceProvider: React.FC<Props> = ({ children, isWorkspacesPage, wide }) => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()
  const { toast } = useContext(ProgressContext)
  const workspaces = useWorkspaces(wide)
  const { addPlanParamsIfExists } = usePlanParamsFromUrl()
  const [workspace, setWorkspace] = useState<Workspace | null>(null)
  const [projects, setProjects] = useState<Project[]>([])
  const [waitProjects, setWaitProjects] = useState(true)
  const userId = auth.currentUser?.uid

  useEffect(() => {
    if (workspace && userId) {
      const controller = getCurrentWorkspaceController()
      controller[userId] = workspace.id
      setCurrentWorkspaceController(controller)
      setProjects(projects => (projects.length ? [] : projects))
      setWaitProjects(true)
      const unsubscribe = firestore
        .collection('projects')
        .where('workspace', '==', firestore.doc(`workspaces/${workspace.id}`))
        .where('memberIds', 'array-contains', userId)
        .onSnapshot({
          next: res => {
            setProjects(res.docs.map(el => ({ id: el.id, ...el.data() })) as Project[])
            setWaitProjects(false)
          },
          error: err => {
            toast(err)
            setWaitProjects(false)
          },
        })
      return () => {
        unsubscribe()
      }
    }
  }, [workspace?.id])

  useEffect(() => {
    if (workspaces.length && isWorkspacesPage && userId) {
      if (workspaceId) {
        setWorkspace(workspaces.find(el => el.id === workspaceId) || null)
      } else {
        navigate(
          `/workspaces/${
            (workspaces.find(el => el.id === getCurrentWorkspaceController()[userId]) || workspaces[0]).id
          }${addPlanParamsIfExists()}`,
          { replace: true }
        )
      }
    }
  }, [workspaces, workspaceId, isWorkspacesPage])

  return (
    <WorkspaceContext.Provider value={{ workspace, workspaces, projects, waitProjects, setWaitProjects }}>
      {children}
      {isWorkspacesPage && <PlanPicker workspaces={workspaces} />}
    </WorkspaceContext.Provider>
  )
}
