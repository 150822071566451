import React from 'react'

import { Name } from 'components'
import { SwitchedEdit } from '../../components'

export const Providers: React.FC = () => (
  <SwitchedEdit
    title="Authentication Providers"
    subTitle="Accept payments in your app with Stripe integration. This also enables credit card, Google Pay, and Apple Pay. Setup is easy and only requires a few extra steps to get started."
    items={[
      {
        id: '0',
        title: 'Email',
        subTitle: 'Users get an email with a PIN to sign-in',
        iconName: Name.SECOND_ORDER_PROVIDERS_EMAIL,
        checked: false,
      },
      {
        id: '1',
        title: 'Allow Sign-in with Google',
        subTitle: 'Users must have entries in the user profile table',
        iconName: Name.SECOND_ORDER_PROVIDERS_GOOGLE,
        checked: true,
      },
      {
        id: '2',
        title: 'Allow Sign-in with Apple',
        subTitle: 'Users get an email with a PIN to sign-in',
        iconName: Name.SECOND_ORDER_PROVIDERS_APPLE,
        checked: true,
      },
      {
        id: '3',
        title: 'Allow Sign-in with Phone',
        subTitle: 'Users must have entries in the user profile table',
        iconName: Name.SECOND_ORDER_PROVIDERS_PHONE,
        checked: true,
      },
    ]}
    properties={[]}
  />
)
