import styled from 'styled-components'

export const CustomSearch = styled.div<{ wide?: boolean }>`
  z-index: 1;
  position: relative;
  width: ${props => !props.wide && '250px'};
  margin: ${props => props.wide && '8px'};
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${props => props.theme.colors.settingField};
  box-sizing: border-box;
  padding: 8px 12px;
  transition: 0.4s background-color ease;
  & > input {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    font-family: ${props => props.theme.fonts.primary};
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.darkText};
    &::placeholder {
      color: ${props => props.theme.colors.grey1};
    }
  }
  &:hover {
    background-color: ${props => props.theme.colors.settingFieldHover};
  }
`

export const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${props => props.theme.colors.primaryText2};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  transition: ${props => props.theme.transition.primary};
  cursor: pointer;
  & svg path {
    transition: ${props => props.theme.transition.primary};
    stroke: ${props => props.theme.colors.primaryText2};
  }
  &:hover {
    color: ${props => props.theme.colors.primaryTextHover};
    & svg path {
      stroke: ${props => props.theme.colors.primaryTextHover};
    }
  }
`
