import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './DeployBtn.styled'

import { Button, DropDown, Icon, Name, Rotation } from 'components'
import { ProgressContext } from 'context'
import { ApplicationId, Deploy, DeploymentType, Step, StepType, Task, TaskType, useOnClickOutside } from 'hooks'
import { ActivityItemSmall, ActivityItemType } from 'pages/Repository/components'
import { Deployment, DeploymentId } from 'pages/Repository/pages'
import { firestore } from 'services/firebase'
import { ProjectsService, TaskDto } from 'services/projects'

const getLinks = (deployments: Deployment[]) =>
  deployments
    .map(el =>
      el.id === DeploymentId.FUNCTIONS
        ? el.data.map(el => (el.eventTrigger ? '' : el.serviceConfig?.uri ? el.serviceConfig.uri : ''))
        : el.id === DeploymentId.SITES
        ? el.data.map(el => el.defaultUrl)
        : el.id === DeploymentId.APPETIZE
        ? el.data.map(el => el.url)
        : ''
    )
    .flat()
    .filter(el => !!el) as string[]

interface Props {
  setOpenStoreManager: (val: boolean) => void
  tasks: Task[]
  setupSteps: Step[]
}

export const DeployBtn: React.FC<Props> = ({ setOpenStoreManager, tasks, setupSteps }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [iosLinks, setIosLinks] = useState<string[]>([])
  const [androidLinks, setAndroidLinks] = useState<string[]>([])
  const [webLinks, setWebLinks] = useState<string[]>([])
  const [landingLinks, setLandingLinks] = useState<string[]>([])
  const [backendLinks, setBackendLinks] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const [toUpdate, setToUpdate] = useState<TaskDto[]>([])
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  const sections: { name: string; deploys: Deploy[] }[] = useMemo(
    () => [
      {
        name: 'APP PREVIEW',
        deploys: [
          {
            name: 'iOS App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.APPETIZE,
              applicationId: ApplicationId.IOS,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.APPETIZE &&
                el.applicationId === ApplicationId.IOS
            ),
            step:
              setupSteps.find(el => el.type === StepType.IOS_PREVIEW) ||
              setupSteps.find(el => el.type === StepType.IOS_GEN),
            links: [],
          },
          {
            name: 'Android App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.APPETIZE,
              applicationId: ApplicationId.ANDROID,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.APPETIZE &&
                el.applicationId === ApplicationId.ANDROID
            ),
            step:
              setupSteps.find(el => el.type === StepType.ANDROID_PREVIEW) ||
              setupSteps.find(el => el.type === StepType.ANDROID_GEN),
            links: [],
          },
          {
            name: 'Web App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.STAGING,
              applicationId: ApplicationId.WEB,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.STAGING &&
                el.applicationId === ApplicationId.WEB
            ),
            step:
              setupSteps.find(el => el.type === StepType.PWA_PREVIEW) ||
              setupSteps.find(el => el.type === StepType.PWA_GEN),
            links: [],
          },
        ],
      },
      {
        name: 'APP LIVE',
        deploys: [
          {
            name: 'iOS App',
            onSetupClick: () => {
              setOpenStoreManager(true)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.TESTFLIGHT,
              applicationId: ApplicationId.IOS,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.TESTFLIGHT &&
                el.applicationId === ApplicationId.IOS
            ),
            setupText: 'Publish to Appstore',
            step:
              setupSteps.find(el => el.type === StepType.IOS_RELEASE) ||
              setupSteps.find(el => el.type === StepType.IOS_GEN),
            links: iosLinks,
          },
          {
            name: 'Android App',
            onSetupClick: () => {
              setOpenStoreManager(true)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.GOOGLEPLAY,
              applicationId: ApplicationId.ANDROID,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.GOOGLEPLAY &&
                el.applicationId === ApplicationId.ANDROID
            ),
            setupText: 'Publish to Google Play',
            step:
              setupSteps.find(el => el.type === StepType.ANDROID_RELEASE) ||
              setupSteps.find(el => el.type === StepType.ANDROID_GEN),
            links: androidLinks,
          },
          {
            name: 'Web App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.PRODUCTION,
              applicationId: ApplicationId.WEB,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.PRODUCTION &&
                el.applicationId === ApplicationId.WEB
            ),
            step:
              setupSteps.find(el => el.type === StepType.PWA_RELEASE) ||
              setupSteps.find(el => el.type === StepType.PWA_GEN),
            links: webLinks,
          },
        ],
      },
      {
        name: 'LANDING PAGE',
        deploys: [
          {
            name: 'Landing page',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.PRODUCTION,
              applicationId: ApplicationId.LANDING,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.PRODUCTION &&
                el.applicationId === ApplicationId.LANDING
            ),
            step:
              setupSteps.find(el => el.type === StepType.LANDING_RELEASE) ||
              setupSteps.find(el => el.type === StepType.LANDING_GEN),
            links: landingLinks,
          },
        ],
      },
      {
        name: 'BACKEND',
        deploys: [
          {
            name: 'Backend App',
            onSetupClick: () => {
              navigate(`/projects/${id}/settings/domains`)
              setOpen(false)
            },
            dto: {
              type: TaskType.GENERATE_AND_DEPLOY,
              deploymentType: DeploymentType.PRODUCTION,
              applicationId: ApplicationId.BACKEND,
            },
            task: tasks.find(
              el =>
                el.type === TaskType.GENERATE_AND_DEPLOY &&
                el.deploymentType === DeploymentType.PRODUCTION &&
                el.applicationId === ApplicationId.BACKEND
            ),
            step:
              setupSteps.find(el => el.type === StepType.BACKEND_RELEASE) ||
              setupSteps.find(el => el.type === StepType.BACKEND_GEN),
            links: backendLinks,
          },
        ],
      },
    ],
    [tasks, setupSteps, iosLinks, androidLinks, webLinks, landingLinks, backendLinks]
  )

  useEffect(() => {
    const iosUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ApplicationId.IOS}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setIosLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const androidUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ApplicationId.ANDROID}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setAndroidLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const webUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ApplicationId.WEB}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setWebLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const landingUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ApplicationId.LANDING}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setLandingLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    const backendUnsubscribe = firestore
      .collection(`projects/${id}/applications/${ApplicationId.BACKEND}/deployments`)
      .onSnapshot({
        next: res => {
          const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
          setBackendLinks(getLinks(deployments))
        },
        error: err => toast(err),
      })
    return () => {
      iosUnsubscribe()
      androidUnsubscribe()
      webUnsubscribe()
      landingUnsubscribe()
      backendUnsubscribe()
    }
  }, [])

  const createTask = (dto: TaskDto) => {
    startLoader()
    ProjectsService.createTask(id as string, dto)
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }

  const update = () => {
    toUpdate.forEach(createTask)
    setToUpdate([])
  }

  return (
    <div ref={ref}>
      <DropDown
        top="36px"
        width="400px"
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <Button
            rightIcon={<Icon name={Name.GLOBAL_ARROW_BUTTON} rotation={open ? Rotation.LEFT : undefined} />}
            onClick={() => setOpen(open => !open)}
          >
            Deploy
          </Button>
        }
      >
        <Box display="flex" flexDirection="column" gap="15px" p="0 15px" maxHeight="calc(100vh - 90px)" overflow="auto">
          {sections.map(el => (
            <styled.Section key={el.name}>
              <styled.SectionTitle>{el.name}</styled.SectionTitle>
              {el.deploys.map(el => (
                <ActivityItemSmall
                  key={el.name}
                  item={el}
                  type={ActivityItemType.DEPLOY}
                  checkBoxValue={
                    !!toUpdate.find(
                      ({ type, deploymentType, applicationId }) =>
                        type === el.dto.type &&
                        deploymentType === el.dto.deploymentType &&
                        applicationId === el.dto.applicationId
                    )
                  }
                  onCheckBoxChange={val =>
                    setToUpdate(toUpdate => {
                      const filtered = toUpdate.filter(
                        ({ type, deploymentType, applicationId }) =>
                          !(
                            type === el.dto.type &&
                            deploymentType === el.dto.deploymentType &&
                            applicationId === el.dto.applicationId
                          )
                      )
                      return val ? [...filtered, el.dto] : filtered
                    })
                  }
                />
              ))}
            </styled.Section>
          ))}
          <Box mt="10px">
            <Button disabled={!toUpdate.length} style={{ width: '100%' }} onClick={update}>
              Generate & Deploy selected apps
            </Button>
          </Box>
        </Box>
      </DropDown>
    </div>
  )
}
