import React, { useState } from 'react'

import { NavHead, Search, Title } from './Menu.styled'

import { Icon, Name } from 'components'

interface Props {
  title: string
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
}

export const NavHeadSearch: React.FC<Props> = ({ title, search, setSearch }) => {
  const [searchMode, setSearchMode] = useState(false)

  const onSearchBlur = () => !search && setSearchMode(false)

  return searchMode ? (
    <Search>
      <Icon name={Name.RIGHT_SIDEBAR_SEARCH} />
      <input
        autoFocus
        placeholder="Search"
        value={search}
        onChange={e => setSearch(e.target.value)}
        onBlur={onSearchBlur}
      />
      <Icon
        width={12}
        height={12}
        name={Name.ADDITIONAL_CLOSE_ROUND}
        onClick={() => {
          setSearch('')
          setSearchMode(false)
        }}
      />
    </Search>
  ) : (
    <NavHead>
      <Title>{title}</Title>
      <Icon name={Name.RIGHT_SIDEBAR_SEARCH} onClick={() => setSearchMode(true)} />
    </NavHead>
  )
}
