import { Box } from '@mui/material'
import React, { useContext, useRef } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './Template.styled'

import { Button, ButtonColor, ButtonSize, Text, Type } from 'components'
import { ITemplate } from 'context/types'
import { useIcon } from 'hooks'
import { landingUrl } from 'utils'

const removeSpaces = (str?: string) =>
  str
    ?.replaceAll(' ', '-')
    .replaceAll('&', 'and')
    .replaceAll(',', '')
    .replaceAll(':', '')
    .replaceAll('?', '')
    .toLowerCase()

interface Props {
  template?: ITemplate
  setCurrentTemplate?: () => void
}

export const Template: React.FC<Props> = ({ template, setCurrentTemplate }) => {
  const themeContext = useContext(ThemeContext)
  const ref = useRef<HTMLDivElement>(null)
  useIcon(ref, template)

  const tags = [template?.categories[0], template?.languages[0], template?.technologies[0]].join(', ')

  return (
    <styled.TemplateContainer>
      <styled.TemplateBox load={!template}>
        <styled.TemplateImage src={template?.studioImage?.url} />
        <styled.TemplateHover>
          <Text type={Type.H3} as="p" color={themeContext.colors.darkText3}>
            {template?.description}
          </Text>
          <styled.ButtonsBox>
            <Button
              size={ButtonSize.DEFAULT}
              color={ButtonColor.SECONDARY1}
              to={`${landingUrl}/templates/${removeSpaces(template?.title)}`}
            >
              Learn more
            </Button>
            <Button
              size={ButtonSize.DEFAULT}
              color={ButtonColor.SECONDARY1}
              to={`${landingUrl}/templates/${removeSpaces(template?.title)}/preview`}
            >
              Preview
            </Button>
            <Button size={ButtonSize.DEFAULT} onClick={setCurrentTemplate}>
              Start with template
            </Button>
          </styled.ButtonsBox>
        </styled.TemplateHover>
      </styled.TemplateBox>
      {template && (
        <styled.BottomBox>
          <styled.BottomAnchor onClick={setCurrentTemplate} />
          <styled.IconBox>
            <styled.MainIcon src={template.mainIcon.url} />
            {template.studioAdditionalIcon && <styled.AddIcon ref={ref} />}
          </styled.IconBox>
          <Box>
            <Text type={Type.H3} as="span">
              {template?.title}
            </Text>
            <Text
              type={Type.BODY}
              color={themeContext.colors.black_400}
              styles={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '210px',
              }}
            >
              {tags}
            </Text>
          </Box>
        </styled.BottomBox>
      )}
    </styled.TemplateContainer>
  )
}
