import React from 'react'
import { ISvg, Svg } from '../Svg'

const GenerateScreens: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="5.75" y="2" width="8.5" height="16" rx="1.75" stroke="#212133" strokeWidth="1.5" />
    <path d="M8.75 3.75H11.25" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default GenerateScreens
