import { Box } from '@mui/material'
import React from 'react'
import { ISvg, Svg } from '../Svg'

const PluginsLangChain: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 40 40" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" stroke="#E1E3E1" />
    <foreignObject width="100%" height="100%">
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" fontWeight="900">
        LC
      </Box>
    </foreignObject>
  </Svg>
)

export default PluginsLangChain
