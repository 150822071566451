import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ProgressContext } from 'context'
import { ApplicationId } from 'hooks'
import { firestore } from 'services/firebase'
import { TaskDto } from 'services/projects'

export enum DeploymentType {
  APPETIZE = 'APPETIZE',
  TESTFLIGHT = 'TESTFLIGHT',
  GOOGLEPLAY = 'GOOGLEPLAY',
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
}

export interface Task {
  id: string
  name: string
  progress: number
  status: TaskStatus
  type: TaskType
  logsUrl?: string
  jobName?: string
  error?: string
  preview?: {
    id: string
    path: string
  }
  applicationId?: ApplicationId
  deploymentType?: DeploymentType
  createdAt: any
  updatedAt: any
  // for local
  steps?: Step[]
}

export enum TaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum TaskType {
  PROJECT_SETUP = 'PROJECT_SETUP',
  GENERATE_AND_DEPLOY = 'GENERATE_AND_DEPLOY',
  GENERATE = 'GENERATE',
  BUILD = 'BUILD',
  DEPLOY = 'DEPLOY',
  SETUP = 'SETUP',
  OTHER = 'OTHER',
}

export const getTaskTitle = (task: Task) => {
  const { name, type, deploymentType, applicationId } = task
  return name
    ? name
    : type === TaskType.PROJECT_SETUP
    ? 'Setup project'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.APPETIZE &&
      applicationId === ApplicationId.IOS
    ? 'Building iOS preview'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.APPETIZE &&
      applicationId === ApplicationId.ANDROID
    ? 'Building Android preview'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.STAGING &&
      applicationId === ApplicationId.WEB
    ? 'Building PWA preview'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.TESTFLIGHT &&
      applicationId === ApplicationId.IOS
    ? 'Building iOS live'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.GOOGLEPLAY &&
      applicationId === ApplicationId.ANDROID
    ? 'Building Android live'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.PRODUCTION &&
      applicationId === ApplicationId.WEB
    ? 'Building PWA live'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.PRODUCTION &&
      applicationId === ApplicationId.LANDING
    ? 'Building Landing page'
    : type === TaskType.GENERATE_AND_DEPLOY &&
      deploymentType === DeploymentType.PRODUCTION &&
      applicationId === ApplicationId.BACKEND
    ? 'Building Backend'
    : ''
}

export interface Step {
  id: string
  executionTime?: number
  executionName?: string
  status: StepStatus
  type: StepType
  worker: StepWorker
  jobName?: string
  pipelineName?: string
  eventName?: string
  error?: string
  logsUrl?: string
  dependsOn?: string[]
  updatedAt?: any
  createdAt?: any
}

export enum StepStatus {
  PENDING = 'PENDING',
  EXECUTING = 'EXECUTING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum StepType {
  FIREBASE_INIT = 'FIREBASE_INIT',
  IOS_GEN = 'IOS_GEN',
  IOS_PREVIEW = 'IOS_PREVIEW',
  IOS_RELEASE = 'IOS_RELEASE',
  ANDROID_GEN = 'ANDROID_GEN',
  ANDROID_PREVIEW = 'ANDROID_PREVIEW',
  ANDROID_RELEASE = 'ANDROID_RELEASE',
  PWA_GEN = 'PWA_GEN',
  PWA_PREVIEW = 'PWA_PREVIEW',
  PWA_RELEASE = 'PWA_RELEASE',
  BACKEND_GEN = 'BACKEND_GEN',
  BACKEND_RELEASE = 'BACKEND_RELEASE',
  LANDING_GEN = 'LANDING_GEN',
  LANDING_RELEASE = 'LANDING_RELEASE',
  CMS_GEN = 'CMS_GEN',
  INDEX_UPDATE = 'INDEX_UPDATE',
}

enum StepWorker {
  RUN_JOB = 'RUN_JOB',
  EVENTARC = 'EVENTARC',
  BITBUCKET_PIPELINE = 'BITBUCKET_PIPELINE',
}

export interface Deploy {
  name: string
  onSetupClick: () => void
  setupText?: string
  dto: TaskDto
  task?: Task
  step?: Step
  links: string[]
}

export const useTasks = () => {
  const { toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [tasks, setTasks] = useState<Task[]>([])
  const runningTasks = useMemo(
    () => tasks.filter(el => el.status === TaskStatus.PENDING || el.status === TaskStatus.IN_PROGRESS),
    [tasks]
  )
  const failedTasks = useMemo(() => tasks.filter(el => el.status === TaskStatus.FAILED), [tasks])
  const setupTask = useMemo(() => tasks.find(el => el.type === TaskType.PROJECT_SETUP), [tasks])
  const [setupStepsWait, setSetupStepsWait] = useState(true)
  const setupSteps = useMemo(() => setupTask?.steps || [], [setupTask])

  useEffect(() => {
    const unsubscribe = firestore
      .collection(`projects/${id}/tasks`)
      .orderBy('createdAt', 'desc')
      .onSnapshot({
        next: res => {
          setTasks(tasks =>
            (res.docs.map(el => ({ id: el.id, ...el.data() })) as Task[]).map(a => ({
              ...a,
              steps: tasks.find(b => b.id === a.id)?.steps,
            }))
          )
          setLastUpdate(+new Date())
        },
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const unsubscribers = tasks.map(task =>
      firestore
        .collection(`projects/${id}/tasks/${task.id}/steps`)
        .orderBy('createdAt', 'desc')
        .onSnapshot({
          next: res => {
            const steps = res.docs.map(el => ({ id: el.id, ...el.data() })) as Step[]
            setTasks(tasks => {
              const tasksCopy = JSON.parse(JSON.stringify(tasks)) as Task[]
              const taskCopy = tasksCopy.find(el => el.id === task.id)
              if (taskCopy) {
                taskCopy.steps = steps
              }
              return tasksCopy
            })
            if (task.type === TaskType.PROJECT_SETUP) {
              setSetupStepsWait(false)
            }
          },
          error: err => {
            toast(err)
            if (task.type === TaskType.PROJECT_SETUP) {
              setSetupStepsWait(false)
            }
          },
        })
    )
    return () => {
      unsubscribers.map(el => el())
    }
  }, [lastUpdate])

  return { tasks, runningTasks, failedTasks, setupSteps, setupStepsWait }
}
