import { Switch } from '@mui/material'
import { memo } from 'react'

import * as styled from './BooleanInput.styled'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: boolean | null
  onChange: (val: boolean | null) => void
}

export const BooleanInput = memo((props: Props) => (
  <styled.SwitchWrapper>
    no
    <Switch checked={!!props.value} onChange={({ target: { checked } }) => props.onChange(checked)} />
    yes
    {props.value !== null && (
      <styled.Clear
        onClick={e => {
          e.preventDefault()
          props.onChange(null)
        }}
      >
        Clear
      </styled.Clear>
    )}
  </styled.SwitchWrapper>
))
