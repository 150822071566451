import React from 'react'
import { ISvg, Svg } from '../Svg'

const Text: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M5.10164 23C5.67213 23 5.99672 22.7342 6.20328 22.1173L7.27541 19.1749H12.7049L13.7869 22.1268C13.9836 22.7342 14.3082 23 14.8984 23C15.5377 23 16 22.5919 16 22.0319C16 21.8515 15.9607 21.6712 15.8623 21.4054L11.5344 10.101C11.2393 9.34169 10.7672 9 10 9C9.22295 9 8.74098 9.35119 8.45574 10.1105L4.1377 21.4054C4.03934 21.6617 4 21.8705 4 22.0319C4 22.6108 4.44262 23 5.10164 23ZM7.83607 17.438L9.95082 11.4298H10.0098L12.1443 17.438H7.83607Z"
      fill="#7F899E"
    />
    <path
      d="M21.9218 22.9899C23.4525 22.9899 24.9497 22.2431 25.6313 21.0624H25.6872V21.9606C25.7095 22.6064 26.1676 23 26.838 23C27.5196 23 28 22.5862 28 21.9V15.4917C28 13.3624 26.1788 12 23.3296 12C21.2179 12 19.4413 12.767 18.8268 13.9679C18.6816 14.2101 18.6145 14.4523 18.6145 14.6743C18.6145 15.2193 19.0503 15.5826 19.6536 15.5826C20.0782 15.5826 20.3911 15.4413 20.6369 15.1183C21.3073 14.1495 22.0782 13.7761 23.2402 13.7761C24.7151 13.7761 25.5978 14.4826 25.5978 15.6633V16.4807L22.3687 16.6523C19.5866 16.7936 18 17.9339 18 19.7908C18 21.6982 19.6201 22.9899 21.9218 22.9899ZM22.581 21.2642C21.2961 21.2642 20.4358 20.6587 20.4358 19.7303C20.4358 18.822 21.2514 18.2468 22.6927 18.156L25.5978 17.9945V18.8321C25.5978 20.2147 24.2682 21.2642 22.581 21.2642Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Text
