import React, { ReactElement } from 'react'

import * as styled from './Button.styled'

import { Loader } from 'components'
import { ButtonColor, ButtonSize, ButtonVariant } from './types'

interface Props {
  children?: React.ReactNode
  variant?: ButtonVariant
  color?: ButtonColor
  size?: ButtonSize
  height?: number
  disabled?: boolean
  rightIcon?: ReactElement
  leftIcon?: ReactElement
  input?: ReactElement
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e: any) => void
  to?: string
  blank?: boolean
  style?: React.CSSProperties
  active?: boolean
  loading?: boolean
  colorNoChange?: boolean
}

export const Button: React.FC<Props> = ({
  children,
  variant = ButtonVariant.DEFAULT,
  color = ButtonColor.PRIMARY,
  size = ButtonSize.SMALL,
  height = 40,
  disabled,
  rightIcon,
  leftIcon,
  input,
  type,
  onClick,
  to,
  style,
  active,
  loading,
  colorNoChange,
}) => {
  if (
    variant === ButtonVariant.TOP_PANEL_ACTION ||
    variant === ButtonVariant.TAG ||
    variant === ButtonVariant.LINK ||
    size === ButtonSize.SMALL
  ) {
    height = 30
  }
  if (variant === ButtonVariant.TOP_PANEL_ACTION) {
    color = ButtonColor.ACTION
  }

  return (
    <styled.Box
      variant={variant}
      color={color}
      disabled={disabled}
      height={height}
      rightIcon={!!rightIcon}
      leftIcon={!!leftIcon}
      colorNoChange={colorNoChange}
      type={type}
      onClick={onClick}
      style={style}
      active={active}
      as={to ? 'a' : undefined}
      href={to}
      target={to ? '_blank' : undefined}
    >
      {input}
      {leftIcon}
      {loading && <Loader styles={{ width: '18px', height: '18px' }} />}
      {children && <styled.Text>{children}</styled.Text>}
      {rightIcon}
    </styled.Box>
  )
}
