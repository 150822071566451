import React, { useMemo, useState } from 'react'

import { CustomPicker, OptionsContainer, Variable } from 'components'
import { ISize, LocalVariable, Screen, VariableSource } from 'utils'

interface Props {
  close: () => void
  value?: string
  onChange: (val: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  hasMax?: boolean
}

export const NumberPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  hasMax,
}) => {
  const [search, setSearch] = useState('')

  const values = useMemo(
    () =>
      Object.values(ISize)
        .filter(el => (!hasMax ? el !== ISize.max : true))
        .map(el => ({ label: el, value: el })),
    [hasMax]
  )

  return (
    <CustomPicker
      title="Constant"
      close={close}
      setSearch={setSearch}
      defaultTab={source ? 1 : 0}
      optionsContainer={
        <OptionsContainer search={search} value={value} onChange={onChange} values={values} close={close} />
      }
      variableContainer={
        onSourceChange && screenConfig && allLocalVariables ? (
          <Variable
            value={source}
            onChange={onSourceChange}
            onRemove={() => onSourceChange()}
            close={() => {}}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        ) : undefined
      }
    />
  )
}
