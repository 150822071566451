import styled, { css } from 'styled-components'

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
  overflow: auto;
`

export const PickIcon = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  transition: ${props => props.theme.transition.primary};
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border: 1px dashed ${props => props.theme.colors.borderColor};
    box-sizing: border-box;
    border-radius: 16px;
    background: ${props => props.theme.colors.white};
    transition: ${props => props.theme.transition.primary};
    & input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      &::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }
`

export const Separator = styled.div`
  height: 40px;
  width: 1.5px;
  min-width: 1.5px;
  background: ${props => props.theme.colors.settingField};
`

export const Icon = styled.div<{ selected: boolean; loading: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid ${props => (props.selected || props.loading ? props.theme.colors.primaryText : 'transparent')};
  transition: ${props => props.theme.transition.primary};
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.colors.primaryText};
  }
  & > img {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    box-sizing: border-box;
    object-fit: cover;
    border-radius: 16px;
  }
  ${props =>
    props.loading &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-left: 30px solid #00000050;
        border-bottom: 30px solid #00000050;
        border-right: 30px solid #00000050;
        border-radius: 16px;
      }
    `}
`
