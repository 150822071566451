import React from 'react'
import { ISvg, Svg } from '../Svg'

const APIDocumentation: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M13 5L17 10L13 15" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 5L3 10L7 15" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default APIDocumentation
