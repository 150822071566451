import React from 'react'
import { ISvg, Svg } from '../Svg'

const Branch: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="11.5" cy="3.5" r="1.75" stroke="#212133" strokeWidth="1.5" />
    <circle cx="4.5" cy="3.5" r="1.75" stroke="#212133" strokeWidth="1.5" />
    <path d="M11 5.5L11 6C11 7.10457 10.1046 8 9 8L5 8" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="4.5" cy="12.5" r="1.75" stroke="#212133" strokeWidth="1.5" />
    <path d="M4.5 5V10.5" stroke="#212133" strokeWidth="1.5" />
  </Svg>
)

export default Branch
