import React from 'react'
import { ISvg, Svg } from '../Svg'

const Data: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11 8C14.3137 8 17 7.10457 17 6C17 4.89543 14.3137 4 11 4C7.68629 4 5 4.89543 5 6C5 7.10457 7.68629 8 11 8Z"
      stroke="#81899C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 11C17 12.1067 14.3333 13 11 13C7.66667 13 5 12.1067 5 11"
      stroke="#81899C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 6V15.8824C5 17.0541 7.66667 18 11 18C14.3333 18 17 17.0541 17 15.8824V6"
      stroke="#81899C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Data
