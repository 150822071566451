import React from 'react'
import { ISvg, Svg } from '../Svg'

const String: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M1.32108 12.0576C1.81037 12.0576 2.08559 11.813 2.25684 11.2258L2.72166 9.8497H5.90815L6.37298 11.2442C6.53811 11.8191 6.81334 12.0576 7.33321 12.0576C7.87142 12.0576 8.25674 11.6968 8.25674 11.1891C8.25674 11.0056 8.22616 10.8405 8.14053 10.602L5.71855 4.03329C5.45556 3.31159 5.02744 2.99355 4.30574 2.99355C3.6085 2.99355 3.17426 3.32382 2.91738 4.0394L0.507636 10.602C0.428127 10.8283 0.385314 11.0362 0.385314 11.1891C0.385314 11.7212 0.746164 12.0576 1.32108 12.0576ZM3.12533 8.41853L4.26904 4.80392H4.31185L5.48003 8.41853H3.12533Z"
      fill="#7F899E"
    />
    <path
      d="M11.8704 12.0454C12.69 12.0454 13.5095 11.6356 13.8765 10.9384H13.9132V11.2748C13.9499 11.8007 14.2863 12.0698 14.7695 12.0698C15.2771 12.0698 15.6379 11.764 15.6379 11.1708V7.43384C15.6379 6.11276 14.5432 5.24428 12.8612 5.24428C11.5035 5.24428 10.4515 5.72745 10.1457 6.51031C10.0845 6.64486 10.0539 6.7733 10.0539 6.91397C10.0539 7.31763 10.3659 7.59897 10.8001 7.59897C11.0876 7.59897 11.3139 7.48888 11.5096 7.26871C11.9071 6.75495 12.2374 6.57759 12.7817 6.57759C13.4545 6.57759 13.8826 6.93232 13.8826 7.54393V7.97817L12.2313 8.07603C10.5922 8.17389 9.67474 8.86501 9.67474 10.0576C9.67474 11.2381 10.6227 12.0454 11.8704 12.0454ZM12.4576 10.7549C11.8582 10.7549 11.4545 10.4491 11.4545 9.95979C11.4545 9.49497 11.8337 9.19528 12.5004 9.14635L13.8826 9.06072V9.53778C13.8826 10.2472 13.2404 10.7549 12.4576 10.7549Z"
      fill="#7F899E"
    />
  </Svg>
)

export default String
