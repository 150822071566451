import { useContext, useEffect, useState } from 'react'

import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'

export enum ApplicationId {
  IOS = 'ios-application',
  ANDROID = 'android-application',
  WEB = 'web-application',
  LANDING = 'landing-page',
  BACKEND = 'backend-application',
}

export interface Application {
  id: ApplicationId
  name: ApplicationId
  slug: string
  language?: ApplicationLanguage
  platform: ApplicationPlatform
  type: ApplicationType
  thumbnailUrl: string
  tags: string[]
  status: ApplicationStatus
  url?: string
  githubSlug?: string
  description: string
}

enum ApplicationLanguage {
  KOTLIN = 'KOTLIN',
  JAVASCRIPT = 'JAVASCRIPT',
  SWIFT = 'SWIFT',
}

enum ApplicationPlatform {
  ANDROID = 'ANDROID',
  WEB = 'WEB',
  IOS = 'IOS',
}

enum ApplicationType {
  NATIVE_ANDROID = 'NATIVE_ANDROID',
  NESTJS_BACKEND = 'NESTJS_BACKEND',
  NATIVE_IOS = 'NATIVE_IOS',
  NEXTJS_LANDING = 'NEXTJS_LANDING',
  REACTJS_PWA = 'REACTJS_PWA',
}

export enum ApplicationStatus {
  PENDING = 'PENDING',
  REPO_CREATED = 'REPO_CREATED',
  REPO_PUSHED = 'REPO_PUSHED',
  DEPLOYING = 'DEPLOYING',
  READY = 'READY',
  FAILED = 'FAILED',
}

export const useApplications = (id: string) => {
  const { toast } = useContext(ProgressContext)
  const [applications, setApplications] = useState<Application[]>([])
  const iosApplication = applications.find(el => el.id === ApplicationId.IOS)
  const androidApplication = applications.find(el => el.id === ApplicationId.ANDROID)
  const webApplication = applications.find(el => el.id === ApplicationId.WEB)
  const landingApplication = applications.find(el => el.id === ApplicationId.LANDING)
  const backendApplication = applications.find(el => el.id === ApplicationId.BACKEND)

  useEffect(() => {
    const unsubscribe = firestore.collection(`projects/${id}/applications`).onSnapshot({
      next: res => setApplications(res.docs.map(el => ({ id: el.id, ...el.data() })) as Application[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    applications,
    iosApplication,
    androidApplication,
    webApplication,
    landingApplication,
    backendApplication,
  }
}
