import React from 'react'
import { ISvg, Svg } from '../Svg'

const Chat: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.46154 11.2143H7.31614L7.19342 11.2923L4.36364 13.09L4.36364 11.7143V11.2143H3.86364H2.23491C1.82903 11.2143 1.5 10.8853 1.5 10.4794V3.23491C1.5 2.82903 1.82903 2.5 2.23491 2.5H13.7651C14.171 2.5 14.5 2.82903 14.5 3.23491L14.5 10.4794C14.5 10.8853 14.171 11.2143 13.7651 11.2143H7.46154Z"
      stroke="#7F899E"
    />
    <path d="M4 5.5H12" stroke="#7F899E" strokeLinecap="round" />
    <path d="M4 7.5H8" stroke="#7F899E" strokeLinecap="round" />
  </Svg>
)

export default Chat
