import React from 'react'
import { ISvg, Svg } from '../Svg'

const PlayStore: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M7.65726 3.28326C7.25785 3.01829 6.77811 2.93821 6.32812 3.04726L14.2359 11.1658L16.7988 8.53457L7.65726 3.28326Z"
      fill="#34A853"
    />
    <path
      d="M5.3475 3.70609C5.12694 4.00712 5 4.37675 5 4.76774V19.2292C5 19.6202 5.12697 19.9898 5.3475 20.2909L13.4246 11.9985L5.3475 3.70609Z"
      fill="#4285F4"
    />
    <path
      d="M20.1191 10.4411L17.8157 9.11792L15.0234 11.9846L17.8159 14.8515L20.1195 13.5282C20.6714 13.2108 21.0008 12.6338 21.0008 11.9846C21.0008 11.3355 20.6714 10.7585 20.1191 10.4411Z"
      fill="#FBBC04"
    />
    <path
      d="M14.2354 12.8338L6.32812 20.9518C6.45777 20.9831 6.58971 21 6.72164 21C7.0477 21 7.37263 20.9048 7.65681 20.7163L16.7984 15.465L14.2354 12.8338Z"
      fill="#EA4335"
    />
  </Svg>
)

export default PlayStore
