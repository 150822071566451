import React from 'react'
import { ISvg, Svg } from '../Svg'

const Progress: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="3.25" y="17.25" width="25.5" height="10.5" rx="2.25" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="5" y="19" width="16.0538" height="6.8802" rx="1" fill="#7F899E" />
    <path
      d="M6.7663 13C7.11957 13 7.32065 12.8708 7.5 12.5262L10.4076 6.96501C10.6196 6.55585 10.7174 6.29744 10.7174 6.00673C10.7174 5.45222 10.3098 5.11844 9.73913 5.11844H5.67935C5.28804 5.11844 5 5.37147 5 5.75908C5 6.15209 5.28804 6.4105 5.67935 6.4105H9.05978V6.4428L6.15761 11.8264C6.05978 12.004 6.0163 12.1494 6.0163 12.3378C6.0163 12.7147 6.30435 13 6.7663 13Z"
      fill="#7F899E"
    />
    <path
      d="M13.1359 13C13.4891 13 13.6902 12.8708 13.8696 12.5262L16.7772 6.96501C16.9891 6.55585 17.087 6.29744 17.087 6.00673C17.087 5.45222 16.6793 5.11844 16.1087 5.11844H12.0489C11.6576 5.11844 11.3696 5.37147 11.3696 5.75908C11.3696 6.15209 11.6576 6.4105 12.0489 6.4105H15.4293V6.4428L12.5272 11.8264C12.4293 12.004 12.3859 12.1494 12.3859 12.3378C12.3859 12.7147 12.6739 13 13.1359 13Z"
      fill="#7F899E"
    />
    <path
      d="M19.9837 13C20.2065 13 20.3424 12.9192 20.5 12.677L22.9185 9.13459L25.1848 5.83984C25.2663 5.71602 25.3098 5.60296 25.3098 5.47376C25.3098 5.19919 25.087 5 24.7989 5C24.5435 5 24.413 5.08614 24.2446 5.33378L21.913 8.75774L19.5707 12.1548C19.4891 12.2732 19.4457 12.3755 19.4457 12.5316C19.4457 12.8223 19.7011 13 19.9837 13ZM19.5489 9.19381C20.6359 9.19381 21.3207 8.39704 21.3207 7.09421C21.3207 5.77524 20.6304 5.01077 19.5489 5.01077C18.462 5.01077 17.7717 5.78062 17.7717 7.09421C17.7717 8.40242 18.4565 9.19381 19.5489 9.19381ZM19.5489 8.32167C19.1685 8.32167 18.9348 7.93405 18.9348 7.09421C18.9348 6.25437 19.1739 5.88291 19.5489 5.88291C19.9239 5.88291 20.1576 6.25437 20.1576 7.09421C20.1576 7.93405 19.9239 8.32167 19.5489 8.32167ZM25.2228 12.9946C26.3152 12.9946 27 12.1978 27 10.8896C27 9.57066 26.3043 8.80619 25.2228 8.80619C24.1413 8.80619 23.4457 9.57604 23.4457 10.8896C23.4457 12.2032 24.1304 12.9946 25.2228 12.9946ZM25.2228 12.1225C24.8478 12.1225 24.6087 11.7295 24.6087 10.895C24.6087 10.0498 24.8478 9.67833 25.2228 9.67833C25.5978 9.67833 25.837 10.0498 25.837 10.8896C25.837 11.7295 25.5978 12.1225 25.2228 12.1225Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Progress
