import { LinearProgress } from '@mui/material'
import { useMemo, useState } from 'react'

import { Notification, NotificationColor } from 'components'
import { ProgressContext } from 'context'

const getErrorMessage = (err: any) =>
  err.statusCode === 422
    ? `${err.message}: ${err.errors.map((el: any) => el.messages.join('; ')).join('; ')}`
    : err.message

interface Props {
  children: React.ReactNode
}

export const ProgressProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationColor, setNotificationColor] = useState(NotificationColor.RED)

  const startLoader = () => setLoading(true)

  const stopLoader = () => setLoading(false)

  const toast = (err: any, detect403StatusCode?: boolean) => {
    console.error(err)
    setNotificationColor(NotificationColor.RED)
    if (typeof err == 'object') {
      if (err.error) {
        setNotificationMessage(err.error)
      } else {
        setNotificationMessage(getErrorMessage(err))
      }
      if (detect403StatusCode && err.statusCode === 403) {
        setNotificationColor(NotificationColor.YELLOW)
      }
    }
    if (typeof err === 'string') {
      setNotificationMessage(err)
    }
    setOpenNotification(true)
  }

  const closeNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setNotificationMessage('')
    setOpenNotification(false)
  }

  const value = useMemo(() => ({ startLoader, stopLoader, toast, getErrorMessage }), [])

  return (
    <>
      {loading && (
        <LinearProgress
          color="inherit"
          sx={{
            position: 'fixed',
            width: '100%',
            height: '2px',
            zIndex: 200,
            color: '#5C61DE',
          }}
        />
      )}
      <Notification
        open={openNotification}
        color={notificationColor}
        title={notificationMessage}
        close={closeNotification}
        autoClose
      />
      <ProgressContext.Provider value={value}>{children}</ProgressContext.Provider>
    </>
  )
}
