import React from 'react'

import { Container, FieldWrapper, SubLabel } from '../SettingField.styled'
import * as styled from './SettingAlignment.styled'

import { Icon, Name, Rotation } from 'components'
import { TextAlign } from 'utils'

interface Props {
  subLabel: string
  value?: string
  onChange: (val: TextAlign) => void
  verticalValue?: string
  onVerticalChange?: (val: TextAlign) => void
  disabled?: boolean
}

export const SettingTextAlignment: React.FC<Props> = ({
  subLabel,
  value,
  onChange,
  verticalValue,
  onVerticalChange,
  disabled,
}) => (
  <Container disabled={disabled}>
    <FieldWrapper spaceBetween={!!onVerticalChange}>
      <SubLabel autoWidth={!!onVerticalChange}>{subLabel}</SubLabel>
      <styled.Icons>
        <styled.IconWrapper active={value === TextAlign.START}>
          <Icon name={Name.RIGHT_SIDEBAR_TEXT_LEFT} onClick={() => onChange(TextAlign.START)} />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === TextAlign.CENTER}>
          <Icon name={Name.RIGHT_SIDEBAR_TEXT_CENTER} onClick={() => onChange(TextAlign.CENTER)} />
        </styled.IconWrapper>
        <styled.IconWrapper active={value === TextAlign.END}>
          <Icon name={Name.RIGHT_SIDEBAR_TEXT_RIGHT} onClick={() => onChange(TextAlign.END)} />
        </styled.IconWrapper>
      </styled.Icons>
      {onVerticalChange && (
        <styled.Icons>
          <styled.IconWrapper active={verticalValue === TextAlign.START}>
            <Icon name={Name.RIGHT_SIDEBAR_TEXT_TOP} onClick={() => onVerticalChange(TextAlign.START)} />
          </styled.IconWrapper>
          <styled.IconWrapper active={verticalValue === TextAlign.CENTER}>
            <Icon name={Name.RIGHT_SIDEBAR_TEXT_MIDDLE} onClick={() => onVerticalChange(TextAlign.CENTER)} />
          </styled.IconWrapper>
          <styled.IconWrapper active={verticalValue === TextAlign.END}>
            <Icon
              name={Name.RIGHT_SIDEBAR_TEXT_TOP}
              rotation={Rotation.LEFT}
              onClick={() => onVerticalChange(TextAlign.END)}
            />
          </styled.IconWrapper>
        </styled.Icons>
      )}
    </FieldWrapper>
  </Container>
)
