import { Box, Portal } from '@mui/material'
import { memo, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './RefField.styled'

import { Button, FieldWrapper, Icon, Name, RemoveContent } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CollectionRecord } from 'pages/CMS/pages'
import { CMSCollectionRecord, CMSService, collectionNameToFind } from 'services/cms'
import { generateFirestoreId } from 'utils'
import { FieldProps } from '../../types'
import { ReferenceItems } from './ReferenceItems'

interface SubCollectionFieldProps extends FieldProps {
  value: CMSCollectionRecord[] | null
  onChange: (val: CMSCollectionRecord[] | null) => void
}

export const SubCollectionField = memo((props: SubCollectionFieldProps) => {
  const {
    state: {
      data: { collections = [] },
    },
    language,
  } = useContext(ProjectContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [arrayValue, setArrayValue] = useState<CMSCollectionRecord[]>([])
  const [showMore, setShowMore] = useState(false)
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [toRemove, setToRemove] = useState<CMSCollectionRecord | null>(null)
  const [recordId, setRecordId] = useState('')
  const currentCollection = collections.find(
    el => collectionNameToFind(el.name) === collectionNameToFind(props.collection || '')
  )
  const singularName = props.name.slice(0, -1)
  const limit = showMore ? undefined : 3

  useEffect(() => {
    const abortController = new AbortController()
    CMSService.getRecords(
      id as string,
      props.collection as string,
      language,
      undefined,
      undefined,
      limit,
      undefined,
      undefined,
      abortController
    )
      .then(res => setArrayValue(res.data))
      .catch(err => !abortController.signal.aborted && toast(err, true))
    return () => {
      abortController.abort()
    }
  }, [limit, lastUpdate])

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      CMSService.deleteRecord(id as string, props.collection as string, (toRemove as CMSCollectionRecord).id)
        .then(() => setLastUpdate(+new Date()))
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  return (
    <>
      <FieldWrapper
        {...props}
        children={props => (
          <>
            <styled.RefFieldInput
              onClick={e => {
                e.preventDefault()
                setRecordId(generateFirestoreId())
              }}
            >
              <span>
                <Icon name={Name.RIGHT_SIDEBAR_ADD} />
                Add {props.name}
              </span>
            </styled.RefFieldInput>
            {arrayValue.length === limit && (
              <Box width="100%" mb="30px" display="flex" justifyContent="center">
                <Button onClick={() => setShowMore(true)}>Show more</Button>
              </Box>
            )}
            <ReferenceItems
              values={arrayValue}
              onChange={props.onChange}
              remove={val => setToRemove(val as CMSCollectionRecord)}
              open={setRecordId}
              name={props.name}
              subCollection
            />
          </>
        )}
      />
      {recordId && currentCollection && (
        <Portal>
          <CollectionRecord
            recordId={recordId}
            collectionId={props.collection as string}
            currentCollection={currentCollection}
            refreshRef={() => setLastUpdate(+new Date())}
            close={() => setRecordId('')}
          />
        </Portal>
      )}
      <RemoveContent
        toRemove={toRemove}
        title={`Delete ${singularName}`}
        text={`Are you sure? This action <b>can not be undone</b>. Selected data will be deleted immediately`}
        clean={() => setToRemove(null)}
        remove={remove}
      />
    </>
  )
})
