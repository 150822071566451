import React, { useContext } from 'react'

import { Container, MaxWidthContainer } from '../index.styled'
import * as styled from './Usage.styled'

import { Crumbs, CustomSection, CustomSectionTitle, Text, Type } from 'components'
import { WorkspaceContext } from 'context'

const daysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate()

export const Usage: React.FC = () => {
  const { workspace, projects } = useContext(WorkspaceContext)

  const date = new Date()
  const usagePeriod = `${date.toLocaleString('en-US', {
    month: 'short',
  })} 1, ${date.getFullYear()} - ${date.toLocaleString('en-US', { month: 'short' })} ${daysInMonth(
    date.getMonth(),
    date.getFullYear()
  )}, ${date.getFullYear()}`
  const nextMonth = date.getMonth() + 1
  const endDate = new Date(date.getFullYear(), nextMonth, 1)
  const usageEnd = `${endDate.toLocaleString('en-US', { month: 'short' })} 1, ${endDate.getFullYear()}`

  const items = workspace
    ? [
        { name: usagePeriod },
        {
          name: 'Members',
          value: `${workspace.memberIds.length}/10`,
          color: '#EC8933',
          currentValue: `${(workspace.memberIds.length / 10) * 100}`,
        },
        { name: 'Projects', value: `${projects.length}/100`, color: '#5F3894', currentValue: `${projects.length}` },
        { name: 'App previews', value: '1/20' },
        { name: 'Code builds*', value: '1/100' },
        { name: 'Build minutes*', value: '1/1k' },
        { name: 'Authentications*', value: '1/10k' },
        { name: 'Invocations*', value: '1/2M' },
        { name: 'Monthly active users*', value: '1/50k' },
      ]
    : []

  return workspace ? (
    <Container>
      <MaxWidthContainer>
        <Crumbs
          icon={null}
          firstCrumbTitle={workspace.name}
          secondCrumbTitle="Usage"
          to={`/workspaces/${workspace.id}`}
        />
        <Text type={Type.SUB_TITLE}>Track current usage and limits</Text>
        <CustomSection>
          <CustomSectionTitle>Summary</CustomSectionTitle>
          {items.map(el => (
            <styled.UsageItem key={el.name} color={el.color}>
              <b>{el.name}</b>
              <span>{el.value}</span>
              {el.value && <progress max="100" value={el.currentValue || 0} />}
            </styled.UsageItem>
          ))}
          <styled.Hint>*Usage amounts reset on {usageEnd}</styled.Hint>
        </CustomSection>
      </MaxWidthContainer>
    </Container>
  ) : null
}
