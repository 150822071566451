import React from 'react'
import { ISvg, Svg } from '../Svg'

const Studio: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 2L2 7L12 12L22 7L12 2Z"
      stroke="#212133"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 17L12 22L22 17" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 12L12 17L22 12" stroke="#212133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
)

export default Studio
