import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './Activities.styled'

import { NoContent } from 'components'
import { Activity, ActivityItem, ActivityItemType } from 'pages/Repository/components'
import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'

export const Activities: React.FC = () => {
  const { id, repositoryId } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [activities, setActivities] = useState<Activity[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    startLoader()
    const unsubscribe = firestore
      .collection(`projects/${id}/applications/${repositoryId}/activities`)
      .orderBy('time', 'desc')
      .onSnapshot({
        next: res => {
          const activities = res.docs.map(el => ({ id: el.id, ...el.data() })) as Activity[]
          setActivities(activities)
          setWait(false)
          stopLoader()
        },
        error: err => {
          toast(err)
          stopLoader()
        },
      })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <styled.Content>
      {!wait && !activities.length ? (
        <NoContent text="No activities yet." />
      ) : (
        <Box display="flex" flexDirection="column" gap="15px" p="30px 0 60px" maxWidth="950px">
          {activities.map(el => (
            <ActivityItem key={el.id} item={el} type={ActivityItemType.ACTIVITY} />
          ))}
        </Box>
      )}
    </styled.Content>
  )
}
