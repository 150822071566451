import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  *::-webkit-scrollbar {
    display: none;
  }
  *[hidden] {
    display: none !important;
  }
`
