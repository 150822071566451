import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import * as styled from './Code.styled'

import { Commit, FileContent, FileManager } from 'pages/Repository/components'
import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'

export interface FileOrDirectory {
  path: string
  type: string
  commit: {
    hash: string
  }
}

export interface File {
  content: string
  type: string
}

export const Code: React.FC = () => {
  const { id, repositoryId } = useParams()
  const { pathname } = useLocation()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [data, setData] = useState<FileOrDirectory[] | File>()
  const [wait, setWait] = useState(true)
  const [commits, setCommits] = useState<Commit[]>([])
  const flag = `/projects/${id}/repositories/${repositoryId}/src`

  useEffect(() => {
    const abortController = new AbortController()
    setWait(true)
    startLoader()
    if (!Array.isArray(data)) {
      setData([])
    }
    ProjectsService.getProjectApplicationFiles(
      id as string,
      repositoryId as string,
      pathname.replace(flag, ''),
      abortController
    )
      .then(res => {
        if (res.data.values) {
          setData(res.data.values)
          if (!commits.length) {
            return ProjectsService.getCommits(id as string, repositoryId as string).then(res =>
              setCommits(res.data.values)
            )
          }
        } else if (typeof res.data === 'string') {
          setData({ content: res.data, type: res.headers['content-type'] })
        } else {
          setData({ content: JSON.stringify(res.data, null, 2), type: res.headers['content-type'] })
        }
      })
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
    return () => {
      abortController.abort()
    }
  }, [pathname])

  return (
    <styled.Container>
      {data &&
        (Array.isArray(data) ? (
          <FileManager data={data} flag={flag} commits={commits} noContent={!wait && !data.length} />
        ) : (
          <FileContent data={data} flag={flag} />
        ))}
    </styled.Container>
  )
}
