import React from 'react'

import * as styled from '../index.styled'

import { TemplateList } from './TemplateList'

export const Templates: React.FC = () => (
  <styled.Container>
    <styled.MaxWidthContainer>
      <TemplateList isTemplatesPage />
    </styled.MaxWidthContainer>
  </styled.Container>
)
