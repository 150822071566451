import React, { useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { Modal } from 'components'
import { ProgressContext } from 'context'
import { Integration, IntegrationAppstore, IntegrationStatus, connectAppStoreFlag } from 'hooks'
import { ProjectsService } from 'services/projects'
import { Code, Login, Ready, Team } from './components'

interface Props {
  appstoreIntegration: Integration
  active: boolean
}

export const AppStoreConnectionModal: React.FC<Props> = ({ appstoreIntegration, active }) => {
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const close = () => {
    searchParams.delete(connectAppStoreFlag)
    setSearchParams(searchParams)
  }

  const unlink = () => {
    close()
    ProjectsService.deleteIntegration(id as string, appstoreIntegration.id).catch(err => toast(err))
  }

  return (
    <Modal
      title="App Store Connect Account"
      active={active}
      onClose={
        appstoreIntegration.status === IntegrationStatus.OTP || appstoreIntegration.status === IntegrationStatus.TEAM
          ? unlink
          : close
      }
      cancelButton={false}
      width="494px"
    >
      {appstoreIntegration.status === IntegrationStatus.READY ? (
        <Ready />
      ) : appstoreIntegration.status === IntegrationStatus.OTP ? (
        <Code token={appstoreIntegration.token} close={unlink} />
      ) : appstoreIntegration.status === IntegrationStatus.TEAM ? (
        <Team
          token={appstoreIntegration.token}
          teams={(appstoreIntegration.data as IntegrationAppstore | undefined)?.teams}
          close={unlink}
        />
      ) : (
        <Login close={close} />
      )}
    </Modal>
  )
}
