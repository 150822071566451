import { Box, Table, TableBody, TableHead } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './CollectionRecords.styled'

import { isFiltersNotValid, NoContent, RemoveContent, TableWrapper } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import {
  CMSCollectionProperty,
  CMSCollectionRecord,
  CMSService,
  getCollectionName,
  getParentCollectionName,
  isSubCollection,
  RecordStatus,
} from 'services/cms'
import { Collection, TableQuery, TableQueryFilterOperand, VariableSource, VariableSourceType } from 'utils'
import { CollectionRecord, refreshRecords, refreshRecordsEvent } from '../CollectionRecord'
import { About, CollectionRecordsTitle, HeadRow, Row } from './components'

const cmsColumnsKey = 'cmsColumnsKey'
const getCMSColumnsController = () => JSON.parse(localStorage.getItem(cmsColumnsKey) || '{}')
const setCMSColumnsController = (controller: any) => localStorage.setItem(cmsColumnsKey, JSON.stringify(controller))

interface Props {
  page: string
  collectionId: string
  recordId: string
  currentCollection: Collection
  currentFolder?: Collection
  small?: boolean
}

export const CollectionRecords: React.FC<Props> = ({
  page,
  collectionId,
  recordId,
  currentCollection,
  currentFolder,
  small,
}) => {
  const { language } = useContext(ProjectContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const [hasStickyColumns, setHasStickyColumns] = useState(false)
  const [wait, setWait] = useState(true)
  const [currentVariable, setCurrentVariable] = useState<VariableSource>({ type: VariableSourceType.collection })
  const [properties, setProperties] = useState<CMSCollectionProperty[]>([])
  const [records, setRecords] = useState<CMSCollectionRecord[]>([])
  const [selectedRecords, setSelectedRecords] = useState<CMSCollectionRecord[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toRemove, setToRemove] = useState<CMSCollectionRecord | null>(null)
  const localStorageCollectionId = `${id}/${collectionId}`

  const getRecords = (abortController: AbortController, query?: TableQuery, withLoader?: boolean) => {
    if (!isFiltersNotValid(query?.filters)) {
      if (withLoader) {
        startLoader()
      }
      return CMSService.getRecords(
        id as string,
        collectionId,
        language,
        query,
        undefined,
        undefined,
        undefined,
        undefined,
        abortController
      )
        .then(res => setRecords(res.data))
        .catch(err => !abortController.signal.aborted && toast(err, true))
        .finally(() => withLoader && stopLoader())
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    setWait(true)
    startLoader()
    const defaultFilters = currentFolder
      ? [
          {
            field: 'folder',
            operator: TableQueryFilterOperand.equal,
            value: { textConstant: { key: 'key', locales: { [language]: currentFolder.name } } },
            default: true,
          },
        ]
      : undefined
    const currentVariable = { type: VariableSourceType.collection, query: { filters: defaultFilters } }
    setCurrentVariable(currentVariable)
    getRecords(abortController, currentVariable.query)
      ?.then(() => {
        if (!abortController.signal.aborted) {
          const properties =
            currentCollection.properties?.map(el => ({ id: el.name, ...el })).sort((a, b) => a.position - b.position) ||
            []
          const controller = getCMSColumnsController()
          const activeColumns = controller[localStorageCollectionId]
          if (!activeColumns) {
            controller[localStorageCollectionId] = properties.map(el => el.id)
            setCMSColumnsController(controller)
            setProperties(
              properties
                .map(el => ({ ...el, activeInTable: !el.isHidden }))
                .sort((a, b) => (a.activeInTable === b.activeInTable ? 0 : b.activeInTable ? 1 : -1))
            )
          } else {
            setProperties(
              properties
                .map(el => ({ ...el, activeInTable: activeColumns.includes(el.id) }))
                .sort((a, b) => activeColumns.indexOf(a.id) - activeColumns.indexOf(b.id))
                .sort((a, b) => (a.activeInTable === b.activeInTable ? 0 : b.activeInTable ? 1 : -1))
            )
          }
        }
      })
      .catch(err => !abortController.signal.aborted && toast(err))
      .finally(() => {
        if (!abortController.signal.aborted) {
          stopLoader()
          setWait(false)
        }
      })
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const refreshRecords = () => getRecords(abortController, currentVariable.query, true)
    document.addEventListener(refreshRecordsEvent, refreshRecords)
    return () => {
      abortController.abort()
      document.removeEventListener(refreshRecordsEvent, refreshRecords)
    }
  }, [JSON.stringify(currentVariable.query)])

  useEffect(() => {
    if (!wait) {
      refreshRecords()
    }
  }, [JSON.stringify(currentVariable.query)])

  useEffect(() => {
    if (properties.length) {
      const activeColumns = properties.filter(el => el.activeInTable)
      const controller = getCMSColumnsController()
      controller[localStorageCollectionId] = activeColumns.map(el => el.id)
      setCMSColumnsController(controller)
    }
  }, [properties])

  useEffect(() => {
    if (!wait && ref.current) {
      const checkHasStickyColumns = () =>
        setHasStickyColumns(
          !!ref.current && ref.current.scrollLeft + ref.current.clientWidth + (small ? 0 : 30) < ref.current.scrollWidth
        )
      checkHasStickyColumns()
      const resizeObserver = new ResizeObserver(checkHasStickyColumns)
      resizeObserver.observe(ref.current)
      ref.current.addEventListener('scroll', checkHasStickyColumns)
      return () => {
        resizeObserver.disconnect()
        ref.current?.removeEventListener('scroll', checkHasStickyColumns)
      }
    }
  }, [wait])

  const deleteRecord = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      CMSService.deleteRecord(id as string, collectionId, toRemove.id)
        .then(() => {
          setSelectedRecords(selectedRecords => selectedRecords.filter(el => el.id !== toRemove.id))
          refreshRecords()
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  const onSelectAllClick = (val: boolean) => (val ? setSelectedRecords(records) : setSelectedRecords([]))

  const removeSelected = (callback: () => void) => {
    startLoader()
    Promise.all([...selectedRecords.map(el => CMSService.deleteRecord(id as string, collectionId, el.id))])
      .then(() => {
        setSelectedRecords([])
        refreshRecords()
      })
      .catch(err => toast(err))
      .finally(() => {
        callback()
        stopLoader()
      })
  }

  const activeColumns = useMemo(() => properties.filter(el => el.activeInTable), [properties])
  const inactiveColumns = useMemo(() => properties.filter(el => !el.activeInTable), [properties])

  return (
    <>
      {wait ? (
        <Box />
      ) : (
        <styled.Container ref={ref} small={small}>
          {!small && (
            <CollectionRecordsTitle
              page={page}
              recordsLength={records.length}
              currentVariable={currentVariable}
              setCurrentVariable={setCurrentVariable}
              properties={properties}
              collectionId={collectionId}
              currentFolder={currentFolder}
            />
          )}
          <TableWrapper
            empty={!records.length}
            hasStickyColumns={hasStickyColumns}
            mt={small ? '0px' : '30px'}
            small={small}
          >
            <Table>
              <TableHead>
                <HeadRow
                  recordsLength={records.length}
                  collectionId={collectionId}
                  currentFolder={currentFolder}
                  selectedRecords={selectedRecords}
                  setSelectedRecords={setSelectedRecords}
                  onSelectAllClick={onSelectAllClick}
                  refreshRecords={refreshRecords}
                  activeColumns={activeColumns}
                  inactiveColumns={inactiveColumns}
                  currentVariable={currentVariable}
                  setCurrentVariable={setCurrentVariable}
                  setOpenDeleteModal={setOpenDeleteModal}
                  properties={properties}
                  setProperties={setProperties}
                />
              </TableHead>
              {isSubCollection(collectionId) && collectionId === currentCollection?.name ? (
                <NoContent
                  isTable
                  title="Start by selecting a parent record."
                  text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                  buttonTitle={`Browse ${getCollectionName(getParentCollectionName(collectionId))}`}
                  onButtonClick={() => navigate(`/projects/${id}/${page}/${getParentCollectionName(collectionId)}`)}
                  secondButtonTitle="Lorem Ipsum"
                />
              ) : !!records.length ? (
                <TableBody>
                  {records.map(record => (
                    <Row
                      page={page}
                      key={record.id}
                      record={record}
                      collectionId={collectionId}
                      currentCollection={currentCollection}
                      currentFolder={currentFolder}
                      selectedRecords={selectedRecords}
                      setSelectedRecords={setSelectedRecords}
                      refreshRecords={refreshRecords}
                      activeColumns={activeColumns}
                      setToRemove={setToRemove}
                      small={small}
                    />
                  ))}
                </TableBody>
              ) : (
                <NoContent
                  isTable
                  text={`Your ${currentFolder ? 'folder' : 'collection'} is empty. Get started by adding a new ${
                    currentFolder ? 'asset' : 'record'
                  }.`}
                />
              )}
            </Table>
          </TableWrapper>
        </styled.Container>
      )}
      {!small && (
        <About
          title={currentFolder ? 'Assets' : 'Collection'}
          collectionId={collectionId}
          currentCollection={currentCollection}
          localStorageCollectionId={localStorageCollectionId}
        />
      )}
      <RemoveContent
        toRemove={toRemove || openDeleteModal}
        title={`${toRemove?.recordStatus === RecordStatus.CHANGED ? 'Discard' : 'Delete'} ${
          toRemove ? (currentFolder ? 'asset' : 'record') : currentFolder ? 'assets' : 'records'
        }`}
        text={`Are you sure? This action <b>can not be undone</b>. Selected data will be ${
          toRemove?.recordStatus === RecordStatus.CHANGED ? 'discarded' : 'deleted'
        } immediately`}
        clean={() => (toRemove ? setToRemove(null) : setOpenDeleteModal(false))}
        remove={toRemove ? deleteRecord : removeSelected}
      />
      {recordId && (
        <CollectionRecord
          recordId={recordId}
          collectionId={collectionId}
          currentCollection={currentCollection}
          currentFolder={currentFolder}
          page={page}
        />
      )}
    </>
  )
}
