import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import * as styled from 'components/Menu/Menu.styled'

import { Menu } from 'components'
import { IRoute, routes } from 'routes'

export const Navigation: React.FC = () => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const navRoutes: IRoute[] = routes.find(el => el.path === '/projects/:id/settings')?.children || []

  const groupBy = (arr: IRoute[]) =>
    arr
      .map((el: IRoute) => el.parent)
      .reduce((acc: any, val: any, i: number) => {
        acc[val] = (acc[val] || []).concat(arr[i])
        return acc
      }, {})

  const navigationChaptersObject = groupBy(navRoutes)
  let navigationChapters = []
  for (let key in navigationChaptersObject) {
    navigationChapters.push({
      chapter: key,
      childs: navigationChaptersObject[key],
    })
  }

  return (
    <Menu
      firstChild={
        <>
          {navigationChapters.map((parent: any) => (
            <styled.NavMarginElement key={parent.chapter}>
              <styled.NavHead>
                <styled.Title>{parent.chapter}</styled.Title>
              </styled.NavHead>
              <styled.NavBody>
                {parent.childs.map((el: any) => (
                  <styled.NavItem
                    key={el.path}
                    lvl={0}
                    active={pathname === el.path.replace(':id', id as string)}
                    onClick={() => navigate(el.path.replace(':id', id as string))}
                    big
                    noArrowMode
                  >
                    {el.icon}
                    <span>{el.name}</span>
                  </styled.NavItem>
                ))}
              </styled.NavBody>
            </styled.NavMarginElement>
          ))}
        </>
      }
    />
  )
}
