import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Text, Type } from 'components'
import { DeviceSettingsContext } from 'context'
import { BrandingFontStyleItem, firstLetterUpperCase } from 'utils'

enum TopFieldStyleName {
  HUGE_TITLE = 'Huge Title',
  BIG_TITLE = 'Big Title',
  TITLE_1 = 'Title 1',
  HEADLINE = 'Headline',
  CALLOUT = 'Callout',
  SUBHEADLINE = 'Subheadline',
  BODY = 'Body',
  FOOTNOTE = 'Footnote',
}

const isTopField = (styleName: string) =>
  styleName === TopFieldStyleName.HUGE_TITLE
    ? 'Huge titles'
    : styleName === TopFieldStyleName.BIG_TITLE
    ? 'Big titles'
    : styleName === TopFieldStyleName.TITLE_1
    ? 'Titles'
    : styleName === TopFieldStyleName.HEADLINE
    ? 'Headlines'
    : styleName === TopFieldStyleName.CALLOUT
    ? 'Callouts'
    : styleName === TopFieldStyleName.SUBHEADLINE
    ? 'Subheadlines'
    : styleName === TopFieldStyleName.BODY
    ? 'Body'
    : styleName === TopFieldStyleName.FOOTNOTE
    ? 'Footnotes'
    : undefined

interface Props {
  styleName: string
  currentFontOption: BrandingFontStyleItem
  fontFamily: string
  onClick: () => void
}

export const FontOption: React.FC<Props> = ({ styleName, currentFontOption, fontFamily, onClick }) => {
  const { fontSize, fontStyle, fontWeight, lineHeight, letterSpacing } = currentFontOption
  const themeContext = useContext(ThemeContext)
  const { compression } = useContext(DeviceSettingsContext)

  const topField = isTopField(styleName)

  return (
    <>
      {topField && (
        <Box width="100%" bgcolor={themeContext.colors.settingField} padding="6px 10px">
          <Text type={Type.BODY} styles={{ color: themeContext.colors.grey1, fontWeight: 500 }}>
            {topField}
          </Text>
        </Box>
      )}
      <Box margin="0 13px" padding="13px 0" onClick={onClick} sx={{ cursor: 'pointer' }}>
        <Box
          fontFamily={fontFamily}
          fontSize={`calc(${fontSize}px*${compression})`}
          fontWeight={fontWeight}
          lineHeight={`${lineHeight}%`}
          letterSpacing={`${letterSpacing}px`}
          fontStyle={fontStyle}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          color={themeContext.colors.darkText}
        >
          {styleName}
        </Box>
        <Box mt="5px">
          <Text type={Type.BODY} color={themeContext.colors.grey1}>
            {firstLetterUpperCase(fontStyle)}, {fontSize}
          </Text>
        </Box>
      </Box>
    </>
  )
}
