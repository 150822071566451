export const fontStylesNames = {
  '100normal': 'Thin',
  '200normal': 'Ultra Light',
  '300normal': 'Light',
  '400normal': 'Normal',
  '500normal': 'Medium',
  '600normal': 'Semi Bold',
  '700normal': 'Bold',
  '800normal': 'Ultra Bold',
  '900normal': 'Black',
  '100italic': 'Thin Italic',
  '200italic': 'Ultra Light Italic',
  '300italic': 'Light Italic',
  '400italic': 'Normal Italic',
  '500italic': 'Medium Italic',
  '600italic': 'Semi Bold Italic',
  '700italic': 'Bold Italic',
  '800italic': 'Ultra Bold Italic',
  '900italic': 'Black Italic',
}

const fontSizesNames = [
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '20',
  '24',
  '30',
  '36',
  '42',
  '48',
  '60',
  '72',
]
export const fontSizes = fontSizesNames.map(el => ({ value: el, label: el }))
