import { Box } from '@mui/material'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import React, { useContext, useEffect, useState } from 'react'
import ReactTimeago from 'react-timeago'

import { ProgressContext } from 'context'
import { Conversation } from 'services/conversations'
import { firestore } from 'services/firebase'
import { firstLetterUpperCase } from 'utils'

countries.registerLocale(enLocale)

interface Props {
  conversationIdSelectedByAdmin: string | null
  setConversationIdSelectedByAdmin: React.Dispatch<React.SetStateAction<string | null>>
}

export const LeftPanelAdminConversations: React.FC<Props> = ({
  conversationIdSelectedByAdmin,
  setConversationIdSelectedByAdmin,
}) => {
  const { toast } = useContext(ProgressContext)
  const [conversations, setConversations] = useState<Conversation[]>([])

  useEffect(() => {
    const unsubscribe = firestore
      .collection('conversations')
      .orderBy('createdAt', 'desc')
      .onSnapshot({
        next: res => setConversations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Conversation[]),
        error: err => toast(err),
      })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%" height="100%" padding="10px" overflow="auto">
      {conversations.map(el => (
        <Box
          key={el.id}
          onClick={() => setConversationIdSelectedByAdmin(el.id)}
          sx={{
            wordBreak: 'break-all',
            cursor: 'pointer',
            background: conversationIdSelectedByAdmin === el.id ? '#c1c1fa' : 'transparent',
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          <Box>
            {el.geocoding?.city ? firstLetterUpperCase(el.geocoding.city) : '-'}{' '}
            {el.geocoding?.country ? countries.getName(el.geocoding.country, 'en') : '-'}
          </Box>
          <Box>{el.lastMessageText}</Box>
          <Box>Page: {el.context?.page || '-'}</Box>
          <Box>Intent: {el.context?.intent || '-'}</Box>
          <Box>User Agent: {el.context?.userAgent || '-'}</Box>
          <Box>
            {/* @ts-ignore */}
            Created: {el.createdAt.seconds ? <ReactTimeago date={new Date(el.createdAt.seconds * 1000)} /> : '-'}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
