import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'

import { CustomSection, CustomSectionActions, CustomSectionTitle, InputProperty, inputs, validate } from 'components'
import { ProgressContext } from 'context'
import { updateUserInfo } from 'hooks'
import firebase from 'services/firebase'
import { UploadService } from 'services/upload'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'displayName',
    type: ValueType.string,
    position: 0,
    isRequired: true,
    title: 'Your name',
    helpText: 'Lorem Ipsum is simply dummy text',
    smallWidth: true,
  },
  {
    name: 'photoURL',
    type: ValueType.avatar,
    position: 1,
    title: 'Avatar',
    helpText: 'Lorem Ipsum is simply dummy text',
    smallWidth: true,
  },
]

interface Props {
  user: firebase.User
}

export const Profile: React.FC<Props> = ({ user }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const displayName = user.displayName || ''
  const photoURL = user.photoURL || ''

  const formik = useFormik({
    initialValues: { displayName, photoURL } as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {
      const { displayName, photoURL } = data
      startLoader()
      if (photoURL.startsWith('blob:')) {
        fetch(photoURL)
          .then(response => response.blob())
          .then(res => {
            const formData = new FormData()
            formData.append('resource', new File([res], `avatar.${res.type.split('/')[1]}`, { type: res.type }))
            return UploadService.uploadAvatar(formData)
              .then(res => user.updateProfile({ displayName, photoURL: res.data.url }))
              .then(() => updateUserInfo())
          })
          .catch(err => toast(err))
          .finally(() => stopLoader())
      } else {
        user
          .updateProfile({ displayName, photoURL })
          .then(() => updateUserInfo())
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }
    },
  })

  useEffect(() => {
    formik.setValues({ displayName, photoURL })
    setValuesBeforeEdit(JSON.stringify({ displayName, photoURL }))
  }, [displayName, photoURL])

  const cancel = () => {
    const { displayName, photoURL } = JSON.parse(valuesBeforeEdit)
    formik.setValues({ displayName, photoURL })
  }

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions}>
      <CustomSectionTitle mb="-5px">Profile</CustomSectionTitle>
      {inputs(properties, formik)}
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.submitForm} />}
    </CustomSection>
  )
}
