import styled from 'styled-components'

export const Navigation = styled.div`
  min-height: 30px;
  width: 100%;
  background: ${props => props.theme.colors.settingField};
  border-radius: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const NavItem = styled.span<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  line-height: 21px;
  color: ${props => (props.clickable ? props.theme.colors.darkText : props.theme.colors.grey)};
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  & > ul {
    cursor: auto;
  }
  & > img {
    border-radius: 4px;
    object-fit: cover;
  }
`

export const WarningsAndErrorsBox = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  display: flex;
  align-items: center;
  & button {
    column-gap: 5px;
    & > span {
      color: ${props => props.theme.colors.darkText1} !important;
      font-weight: 400 !important;
    }
    &:hover {
      & > span {
        color: ${props => props.theme.colors.darkText} !important;
      }
    }
  }
`
