import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, NoContent, PWAPreviewIframe } from 'components'
import { ProgressContext } from 'context'
import { TopPanelConversations } from 'partials'
import { auth } from 'services/firebase'
import { ProjectsService } from 'services/projects'

export const Conversations: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [token, setToken] = useState('')
  const email = auth.currentUser?.email

  const getPWAToken = () => {
    startLoader()
    ProjectsService.getPWAToken(id as string)
      .then(res => setToken(res.data))
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }

  useEffect(() => {
    document.title = 'Conversations | CodePlatform'
    const onMessage = (e: any) => e.data === 'NEED_TOKEN' && getPWAToken()
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [])

  return (
    <>
      <TopPanelConversations />
      {!email ? (
        <Box width="100%" height="calc(100vh - 60px)" display="flex" justifyContent="center" alignItems="center">
          <NoContent text="To enable the Chat feature, please add your email address to your account.">
            <Button onClick={() => navigate('/account')}>Go to account settings</Button>
          </NoContent>
        </Box>
      ) : !token ? (
        <Box width="100%" height="calc(100vh - 60px)" display="flex">
          <PWAPreviewIframe page="conversations" email={email} />
        </Box>
      ) : (
        <Box width="100%" height="calc(100vh - 60px)" display="flex">
          <PWAPreviewIframe page="conversations" token={token} />
        </Box>
      )}
    </>
  )
}
