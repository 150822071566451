import React from 'react'
import { ISvg, Svg } from '../Svg'

const Toggle: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="4.5" width="13" height="7" rx="3.5" stroke="#7F899E" />
    <rect x="7.5" y="4.5" width="7" height="7" rx="3.5" stroke="#7F899E" />
  </Svg>
)

export default Toggle
