import styled from 'styled-components'

export const Text = styled.div`
  color: ${props => props.theme.colors.darkText};
  font-size: 12px;
  line-height: 18px;
  & b {
    font-weight: 600;
  }
`
