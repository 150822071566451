import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const OptionsWrapper = styled.ul<{
  width?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
  lightBorder?: boolean
}>`
  position: absolute;
  ${props => {
    if (props.top) {
      return `top: ${props.top};`
    }
  }};
  ${props => {
    if (props.right) {
      return `right: ${props.right};`
    }
  }};
  ${props => {
    if (props.bottom) {
      return `bottom: ${props.bottom};`
    }
  }};
  ${props => {
    if (props.left) {
      return `left: ${props.left};`
    }
  }};

  width: ${props => props.width};
  min-width: ${props => props.width || '190px'};
  height: fit-content;
  box-sizing: border-box;
  padding: 8px 0;
  background-color: ${props => props.theme.colors.selectBackground};
  z-index: 12;
  border: 1px solid
    ${props => (props.lightBorder ? props.theme.colors.darkBlue_100_lightBorder : props.theme.colors.darkBlue_100)};
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px rgba(26, 24, 24, 0.18);
  overflow: hidden;
`

interface IOption {
  hasOwnDropDown?: boolean
  isProjectName?: boolean
  isLink?: boolean
  color?: string
}

export const Option = styled.li<IOption>`
  position: relative;
  font-size: 14px;
  ${(props: IOption) => !props.isLink && 'padding: 0 10px;'}
  line-height: 1.5;
  color: ${props => props.color || props.theme.colors.darkText1};
  font-weight: ${props => props.theme.weights.medium};
  min-height: 36px;
  padding: 0 8px;
  margin: 0 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: ${props => (props.isProjectName ? 'none' : 'auto')};
  ${(props: IOption) => {
    if (props.hasOwnDropDown) {
      return `justify-content: space-between; padding: 0; position: relative;`
    }
  }}
  & > a,
  & div {
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: inherit;
    min-height: 36px;
    &:visited {
      color: inherit;
    }
  }
  & svg {
    margin-right: 8px;
    min-width: 20px;
    min-height: 20px;
    & g {
      opacity: 1;
    }
    & path,
    circle,
    rect {
      &[fill] {
        fill: ${props => (props.color ? props.color : props.theme.colors.darkText1)};
      }
      &[stroke] {
        stroke: ${props => (props.color ? props.color : props.theme.colors.darkText1)};
      }
    }
  }
  & img {
    margin-right: 13px;
  }
  ${props =>
    props.isProjectName &&
    `
    color: ${props.theme.colors.darkText};
    & svg {
      & path,
      circle,
      rect {
        &[fill] {
          fill: ${props.theme.colors.darkText};
        }
        &[stroke] {
          stroke: ${props.theme.colors.darkText};
        }
      }
    }
  `}

  &:hover {
    background-color: ${props => props.theme.colors.settingField};
    color: ${props => props.color || props.theme.colors.darkText};
    & svg {
      margin-right: 8px;
      min-width: 20px;
      & path,
      circle,
      rect {
        &[fill] {
          fill: ${props => (props.color ? props.color : props.theme.colors.darkText)};
        }
        &[stroke] {
          stroke: ${props => (props.color ? props.color : props.theme.colors.darkText)};
        }
      }
    }
    ${props =>
      props.isProjectName &&
      `
    color: ${props.theme.colors.darkText};
    & svg {
      & path,
      circle,
      rect {
        &[fill] {
          fill: ${props.theme.colors.darkText};
        }
        &[stroke] {
          stroke: ${props.theme.colors.darkText};
        }
      }
    }
  `}
  }
`

interface IDivider {
  lower?: boolean
  width?: string
  optionsDivider?: boolean
}

export const Divider = styled.hr<IDivider>`
  background-color: ${props => props.theme.colors.darkBlue_100};
  height: 0.9px;
  width: ${(props: IDivider) => props.width || '162px'};
  position: ${(props: IDivider) => (props.optionsDivider ? 'static' : 'absolute')};
  left: 0;
  top: ${(props: IDivider) => (props.lower ? 'auto' : '0')};
  bottom: ${(props: IDivider) => (props.lower ? '0' : 'auto')};
  margin: ${(props: IDivider) => (props.optionsDivider ? '8px 0' : '0 10px')};
  border-style: none;
  border-width: 0;
`

export const Label = styled.div<{ disabled?: boolean }>`
  ${props => props.disabled && `pointer-events: none;`}
  position: relative;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
  padding: 0px 8px;
  border-radius: 8px;
  transition: all 0.3s ease-out;
  background-color: ${props => props.theme.colors.settingField};
  & > span {
    color: ${props => (props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText1)};
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    & b {
      font-weight: ${props => props.theme.weights.medium};
    }
    & svg:not(:last-child) {
      margin-right: 5px;
    }
    & svg:last-child {
      margin-left: 5px;
      /* fill: ${props => props.theme.colors.darkText}; */
    }
  }
  & * {
    ${props => props.disabled && `color: ${props.theme.colors.disabledTextColor}!important;`}
  }
  & > svg {
    & path {
      &[stroke] {
        stroke: ${props => (props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText2)};
      }
      &[fill] {
        fill: ${props => (props.disabled ? props.theme.colors.disabledTextColor : props.theme.colors.darkText2)};
      }
    }
  }

  &:hover {
    background-color: ${props => props.theme.colors.settingFieldHover};
  }
`
