import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './Deployments.styled'

import { Button, Icon, Name, NoContent, TableWrapper } from 'components'
import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'
import { firstLetterUpperCase, getReadableTimeFromSeconds } from 'utils'

export enum DeploymentId {
  FUNCTIONS = 'functions',
  SITES = 'sites',
  APPETIZE = 'appetize',
}

export interface Deployment {
  id: DeploymentId
  data: any[]
}

export const Deployments: React.FC = () => {
  const { id, repositoryId } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [deployments, setDeployments] = useState<Deployment[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    startLoader()
    const unsubscribe = firestore.collection(`projects/${id}/applications/${repositoryId}/deployments`).onSnapshot({
      next: res => {
        const deployments = res.docs.map(el => ({ id: el.id, ...el.data() })) as Deployment[]
        setDeployments(deployments)
        stopLoader()
        setWait(false)
      },
      error: err => {
        toast(err)
        stopLoader()
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <styled.Content>
      {!wait && !deployments.length ? (
        <NoContent text="No deployments yet." />
      ) : (
        deployments.map(el => (
          <styled.DeploymentsTable key={el.id}>
            <h2>{firstLetterUpperCase(el.id)}</h2>
            <TableWrapper empty={!el.data.length} mt="15px">
              {el.id === DeploymentId.FUNCTIONS ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Function</TableCell>
                      <TableCell>Trigger</TableCell>
                      <TableCell>Version</TableCell>
                      <TableCell>Min / Max Instances</TableCell>
                      <TableCell>Timeout</TableCell>
                    </TableRow>
                  </TableHead>
                  {!!el.data.length ? (
                    <TableBody>
                      {el.data.map(el => (
                        <TableRow key={el.name}>
                          <TableCell>
                            {el.buildConfig.entryPoint}
                            <i>{el.buildConfig.build.split('/')[3]}</i>
                          </TableCell>
                          <TableCell>
                            {el.eventTrigger?.eventType || 'Request'}
                            {el.eventTrigger ? (
                              <i>
                                {el.eventTrigger.eventFilters.map((el: any) => (
                                  <React.Fragment key={el.value}>
                                    {firstLetterUpperCase(el.attribute)}: {el.value}
                                  </React.Fragment>
                                ))}
                              </i>
                            ) : (
                              el.serviceConfig?.uri && (
                                <a href={el.serviceConfig.uri} target="_blank" rel="noreferrer">
                                  {el.serviceConfig.uri} <Icon name={Name.REPOSITORY_LINK} />
                                </a>
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            <i>{el.environment.replace('GEN_', 'v')}</i>
                          </TableCell>
                          <TableCell>
                            <i>0 / {el.serviceConfig.maxInstanceCount}</i>
                          </TableCell>
                          <TableCell>
                            <i>{getReadableTimeFromSeconds(el.serviceConfig.timeoutSeconds)}</i>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <NoContent isTable text="Empty" />
                  )}
                </Table>
              ) : el.id === DeploymentId.SITES ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Domain</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="flex-end">
                          <Button>Add custom domain</Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!!el.data.length ? (
                    <TableBody>
                      {el.data.map(el => (
                        <TableRow key={el.name}>
                          <TableCell>
                            <a href={el.defaultUrl} target="_blank" rel="noreferrer">
                              {el.defaultUrl} <Icon name={Name.REPOSITORY_LINK} />
                            </a>
                            <i>{el.type}</i>
                          </TableCell>
                          <TableCell>
                            <i>Status</i>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <NoContent isTable text="Empty" />
                  )}
                </Table>
              ) : el.id === DeploymentId.APPETIZE ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Preview </TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  {!!el.data.length ? (
                    <TableBody>
                      {el.data.map(el => (
                        <TableRow key={el.url}>
                          <TableCell>
                            <a href={el.url} target="_blank" rel="noreferrer">
                              {el.url} <Icon name={Name.REPOSITORY_LINK} />
                            </a>
                          </TableCell>
                          <TableCell>
                            <i>Status</i>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <NoContent isTable text="Empty" />
                  )}
                </Table>
              ) : (
                <></>
              )}
            </TableWrapper>
          </styled.DeploymentsTable>
        ))
      )}
    </styled.Content>
  )
}
