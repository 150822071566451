import styled from 'styled-components'

export const Screens = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  background: ${props => props.theme.colors.settingField};
  overflow: auto;
`

export const Screen = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  transition: ${props => props.theme.transition.primary};
  border-radius: 20px;
  padding: 2px;
  & > img {
    width: 190px;
    min-width: 190px;
  }
`
