import styled from 'styled-components'

export const DeviceSettings = styled.div<{ topPanel?: boolean }>`
  margin-right: ${props => !props.topPanel && '120px'};
  position: ${props => !props.topPanel && 'absolute'};
  bottom: 30px;
  right: 0;
  z-index: 2;
  display: flex;
  user-select: none;
  & > * {
    position: relative;
    margin: 0 5px;
    border-radius: 8px;
    background: ${props => props.theme.colors.primaryBackground};
    height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Box = styled.div<{ isCircle?: boolean; active?: boolean }>`
  background: ${props => props.active && props.theme.colors.settingField};
  cursor: pointer;
  ${props => {
    if (props.isCircle) {
      return `
        & path {
          transition: 300ms ease-out;
          &:nth-child(2) {
            fill: ${props.theme.colors.grey1};
            stroke: transparent;
          }
          &:last-child {
            fill: ${props.theme.colors.grey1};
          }
        }
        & circle {
          transition: 300ms ease-out;
          &:first-child {
            stroke: ${props.theme.colors.grey1};
          }
          &:nth-child(3) {
            fill: ${props.theme.colors.primaryBackground};
          }
        }
        &:hover {
          & path {
            &:nth-child(2) {
              fill: ${props.theme.colors.darkText};
              stroke: transparent;
            }
            &:last-child {
              fill: ${props.theme.colors.darkText};
            }
          }
          & circle {
            &:first-child {
              stroke: ${props.theme.colors.darkText};
            }
            &:nth-child(3) {
              fill: ${props.theme.colors.primaryBackground};
            }
          }
        }
      `
    } else {
      return `
      & path,
        & rect {
          transition: 300ms ease-out;
          &[fill] {
            fill: ${props.theme.colors.grey1};
          }
          &[stroke] {
            stroke: ${props.theme.colors.grey1};
          }
        }
        &:hover {
          & path,
            & rect {
            &[fill] {
              fill: ${props.theme.colors.iconHover};
            }
            &[stroke] {
              stroke: ${props.theme.colors.iconHover};
            }
          }
        }
      `
    }
  }}
`

export const Scale = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.darkText};
  & input {
    font-family: ${props => props.theme.fonts.primary};
    font-size: 12px;
    color: ${props => props.theme.colors.darkText};
    text-align: center;
    width: 20px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
  }
  & svg {
    margin: 0 7px;
    cursor: pointer;
    &:hover path {
      stroke: ${props => props.theme.colors.iconHover};
    }
  }
`

export const Picker = styled.div<{ topPanel?: boolean }>`
  position: absolute;
  bottom: ${props => !props.topPanel && '34px'};
  top: ${props => props.topPanel && '34px'};
  left: -105px;
  width: 240px;
  cursor: auto;
`

export const ScalePicker = styled.div<{ topPanel?: boolean }>`
  position: absolute;
  bottom: ${props => !props.topPanel && '34px'};
  top: ${props => props.topPanel && '34px'};
  left: 0;
  width: 88px;
  cursor: auto;
`
