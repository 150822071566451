import styled from 'styled-components'

export const ProfileInfo = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.darkBlue_100};
  margin-bottom: 8px;
  & p {
    max-width: 163px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      font-weight: ${props => props.theme.weights.medium};
      font-size: 14px;
      line-height: 21px;
      color: ${props => props.theme.colors.darkText};
    }
    &:last-child {
      font-size: 12px;
      line-height: 18px;
      color: ${props => props.theme.colors.grey1};
    }
  }
`
