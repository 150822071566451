import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const NewWorkspace: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M8 4V12" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4 8L12 8" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="3.25" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)
