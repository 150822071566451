import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import { Container, FieldWrapper, Label, OptionsContainer, SubLabel } from '../SettingField.styled'
import * as styled from './SettingTextField.styled'

import { CustomPicker, Translation, Variable } from 'components'
import { useOnClickOutside, useOptionsContainerPosition } from 'hooks'
import {
  LocalVariable,
  Screen,
  VariableSource,
  getCaret,
  getVariableName,
  onContentEditablePaste,
  openVariablePickerEvent,
  setCaret,
} from 'utils'

interface Props {
  label?: string
  subLabel?: string
  value?: string
  onChange: (val: string, values?: VariableSource[]) => void
  values?: VariableSource[]
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  limit?: number
  placeholder?: string
  small?: boolean
  disabled?: boolean
  translation?: React.ReactNode
  insidePicker?: boolean
}

export const SettingTextField: React.FC<Props> = ({
  label,
  subLabel,
  value = '',
  onChange,
  values,
  screenConfig,
  allLocalVariables,
  limit,
  placeholder,
  small,
  disabled,
  translation,
  insidePicker,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLDivElement>(null)
  const [caretPos, setCaretPos] = useState(0)
  const [focusedCount, setFocusedCount] = useState(0)
  const [active, setActive] = useState(false)
  const [open, setOpen] = useState(false)
  const [top, width] = useOptionsContainerPosition(ref, open, insidePicker)
  const [toSave, setToSave] = useState<string>()
  const [currentVariableIndex, setCurrentVariableIndex] = useState<number>()
  const [currentVariable, setCurrentVariable] = useState<VariableSource>()
  const hasBottomPanel = !!(active && values && screenConfig && allLocalVariables)
  useOnClickOutside(ref, () => {
    setActive(false)
    setOpen(false)
    setCurrentVariableIndex(undefined)
    setCurrentVariable(undefined)
  })

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.innerHTML = value
      if (caretPos && active) {
        setCaret(textAreaRef.current, caretPos)
      }
    }
  }, [JSON.stringify(values)])

  useEffect(() => {
    if (!values && textAreaRef.current && textAreaRef.current.innerText !== value) {
      textAreaRef.current.innerText = value
    }
  }, [value])

  useEffect(() => {
    if (values) {
      const openVariablePicker = (e: any) => {
        const index = e.detail - 1
        setCurrentVariableIndex(index)
        setCurrentVariable(values[index])
        setOpen(true)
      }
      document.addEventListener(openVariablePickerEvent, openVariablePicker)
      return () => document.removeEventListener(openVariablePickerEvent, openVariablePicker)
    }
  }, [JSON.stringify(values)])

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (toSave !== undefined) {
        let indexesToRemove: number[] = []
        values?.forEach((el, i) => {
          if (!toSave.includes(`$${i + 1}`)) {
            indexesToRemove.push(i)
          }
        })
        if (indexesToRemove.length) {
          onRemoveVariables(indexesToRemove)
        } else {
          onChange(toSave, !values?.length ? undefined : values)
        }
      }
    }, 600)
    return () => clearTimeout(debounceFn)
  }, [toSave])

  const getToSave = () => {
    const copy = textAreaRef.current?.cloneNode(true) as HTMLDivElement
    Array.from(copy.querySelectorAll('div')).forEach(el => el.replaceWith(el.id))
    return copy.innerText
  }

  const onChangeVariable = (val: VariableSource) => {
    if (values) {
      let toSave = getToSave()
      if (currentVariableIndex !== undefined) {
        const valuesCopy = JSON.parse(JSON.stringify(values)) as VariableSource[]
        valuesCopy.splice(currentVariableIndex, 1, val)
        onChange(toSave, !valuesCopy?.length ? undefined : valuesCopy)
      } else {
        onPasteVariable(val)
      }
    }
  }

  const onRemoveVariables = (indexesToRemove?: number[]) => {
    const toRemove = indexesToRemove || (currentVariableIndex !== undefined ? [currentVariableIndex] : [])
    if (toRemove.length) {
      let toSave = getToSave()
      const valuesCopy = JSON.parse(JSON.stringify(values)) as VariableSource[]
      valuesCopy.splice(toRemove[0], toRemove.length)
      toRemove.forEach(el => {
        toSave = toSave.replace(`$${el + 1}`, '')
      })
      valuesCopy.forEach((el, i) => {
        const newIndex = toRemove[toRemove.length - 1] + 1 + i
        toSave = toSave.replace(`$${newIndex + 1}`, `$${newIndex}`)
      })
      onChange(toSave, !valuesCopy?.length ? undefined : valuesCopy)
    }
  }

  const onPasteVariable = (val: VariableSource) => {
    if (values && screenConfig && textAreaRef.current) {
      let innerTextBeforeCaret = Array.from(textAreaRef.current.childNodes)
        .map(el => el.textContent)
        .join('')
        .slice(0, caretPos)
      values.forEach((el, i) => (innerTextBeforeCaret = innerTextBeforeCaret.replace(getVariableName(el), `$${i + 1}`)))
      const pos = innerTextBeforeCaret.length
      let toSave = getToSave()
      const stringBefore = toSave.slice(0, pos - focusedCount)
      let stringAfter = toSave.slice(pos)
      const index = Array.from(stringBefore.matchAll(/[$][0-9]+/g)).length
      const valuesCopy = JSON.parse(JSON.stringify(values)) as VariableSource[]
      valuesCopy.splice(index, 0, val)
      valuesCopy.slice(index + 1).forEach((el, i) => {
        const oldIndex = valuesCopy.length - 1 - i
        stringAfter = stringAfter.replace(`$${oldIndex}`, `$${oldIndex + 1}`)
      })
      toSave = `${stringBefore}$${index + 1}${stringAfter}`
      setCaretPos(caretPos => caretPos - focusedCount + getVariableName(val).length)
      setFocusedCount(0)
      onChange(toSave, !valuesCopy?.length ? undefined : valuesCopy)
    }
  }

  const onMouseDown = () => {
    setOpen(false)
    document.onmouseup = () => {
      setSelection()
      document.onmouseup = () => {}
    }
  }

  const setSelection = () => {
    if (textAreaRef.current) {
      const { caretAt, focusedCount } = getCaret(textAreaRef.current)
      setCaretPos(caretAt)
      setFocusedCount(focusedCount)
    }
  }

  const onFocus = () => {
    setActive(true)
    setOpen(false)
  }

  const onInput = () => {
    setSelection()
    setToSave(getToSave())
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => e.code === 'Enter' && e.preventDefault()

  const onInsertVariableClick = () => {
    setOpen(true)
    setCurrentVariableIndex(undefined)
    setCurrentVariable(undefined)
  }

  return (
    <Container withLabel={!!label} small={!!small} disabled={disabled}>
      {label && (
        <Label>
          {label} {translation && <Translation>{translation}</Translation>}
        </Label>
      )}
      <FieldWrapper ref={ref}>
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
        <Box width={subLabel ? 'calc(65% - 8px)' : '100%'}>
          <styled.Textarea
            ref={textAreaRef}
            contentEditable
            onMouseDown={onMouseDown}
            onFocus={onFocus}
            onInput={onInput}
            onKeyDown={onKeyDown}
            onPaste={onContentEditablePaste}
            placeholder={placeholder || 'Type here'}
            active={active}
            hasBottomPanel={hasBottomPanel}
          />
          {hasBottomPanel && (
            <styled.TextareaBottomPanel>
              {values ? (
                <styled.InsertVariable onClick={onInsertVariableClick}>Insert variable</styled.InsertVariable>
              ) : (
                <span />
              )}
              {limit && (
                <span>
                  {getToSave().length} / {limit}
                </span>
              )}
            </styled.TextareaBottomPanel>
          )}
        </Box>
        {!!(open && screenConfig && allLocalVariables) && (
          <OptionsContainer top={top} width={width}>
            <CustomPicker
              title="Variables"
              close={() => setOpen(false)}
              optionsContainer={
                <Variable
                  value={currentVariable}
                  onChange={onChangeVariable}
                  onRemove={onRemoveVariables}
                  close={() => setOpen(false)}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              }
            />
          </OptionsContainer>
        )}
      </FieldWrapper>
    </Container>
  )
}
