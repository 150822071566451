import React from 'react'
import { ISvg, Svg } from '../Svg'

const FileTransparent: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.75 3C2.75 2.30964 3.30964 1.75 4 1.75H10C10.3934 1.75 10.7639 1.93524 11 2.25L11.9 3.45L13 4.91667C13.1623 5.13304 13.25 5.3962 13.25 5.66667V13C13.25 13.6904 12.6904 14.25 12 14.25H4C3.30964 14.25 2.75 13.6904 2.75 13V3Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path d="M9 2V4C9 5.10457 9.89543 6 11 6H13" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default FileTransparent
