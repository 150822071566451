import React from 'react'
import { ISvg, Svg } from '../Svg'

const AccountSettings: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx="10" cy="6" r="3.25" stroke="#212133" strokeWidth="1.5" />
    <path
      d="M4 16V15C4 13.3431 5.34315 12 7 12H13C14.6569 12 16 13.3431 16 15V16"
      stroke="#212133"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Svg>
)

export default AccountSettings
