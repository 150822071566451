import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Averta', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 700;
  color: ${props => props.theme.colors.black};
`

export default Wrapper
