import React from 'react'

import * as styled from './BlurBox.styled'

interface Props {
  children: React.ReactNode
  blurComponent?: JSX.Element
  $maxHeightNone?: boolean
  $hasWrapper?: boolean
  $containTagsBox?: boolean
  style?: React.CSSProperties
}

export const BlurBox: React.FC<Props> = ({
  children,
  blurComponent,
  $maxHeightNone,
  $hasWrapper,
  $containTagsBox = false,
  style,
}) => (
  <styled.Wrapper style={style}>
    <styled.ContentWrapper $containTagsBox={$containTagsBox}>{children}</styled.ContentWrapper>
    {$hasWrapper ? (
      <styled.BlueBoxWrapper>
        {blurComponent || <styled.BlurBox $maxHeightNone={$maxHeightNone} />}
      </styled.BlueBoxWrapper>
    ) : (
      blurComponent || <styled.BlurBox $maxHeightNone={$maxHeightNone} />
    )}
  </styled.Wrapper>
)
