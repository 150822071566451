import styled from 'styled-components'

export const InviteItem = styled.div`
  padding: 15px;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  border-radius: 16px;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
`

export const Tag = styled.div`
  border-radius: 100px;
  border: 1px solid ${props => props.theme.colors.primaryText};
  background: #f6f4ff;
  color: ${props => props.theme.colors.primaryText};
  padding: 1px 10px;
`

export const Date = styled.div`
  color: ${props => props.theme.colors.grey1};
`
