import React, { ReactElement, useRef, useState } from 'react'

import { Container, FieldWrapper, Label, SubLabel } from '../SettingField.styled'

import { useOnClickOutside, useOptionsContainerPosition } from 'hooks'
import { LocalVariable, Screen, VariableSource } from 'utils'
import { NumberField } from '../common'

interface Props {
  label?: string
  subLabel?: string
  value?: string
  onChange: (val: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  leftIcon?: ReactElement
  units?: string
  small?: boolean
  disabled?: boolean
  onlyNumber?: boolean
  hasMax?: boolean
  fullWidth?: boolean
  insidePicker?: boolean
}

export const SettingNumberField: React.FC<Props> = ({
  label,
  subLabel,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  leftIcon,
  units,
  small,
  disabled,
  onlyNumber,
  hasMax,
  fullWidth,
  insidePicker,
}) => {
  const [optionsContainer, setOptionsContainer] = useState<((top?: number, width?: number) => ReactElement) | null>(
    null
  )
  const ref = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(false)
  useOnClickOutside(ref, () => {
    setActive(false)
    setOptionsContainer(null)
  })
  const [top, width] = useOptionsContainerPosition(ref, !!optionsContainer, insidePicker)

  return (
    <Container withLabel={!!label} small={small} disabled={disabled}>
      {label && <Label>{label}</Label>}
      <FieldWrapper ref={ref}>
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
        <NumberField
          value={value}
          onChange={onChange}
          source={source}
          onSourceChange={onSourceChange}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          leftIcon={leftIcon}
          units={units}
          onlyNumber={onlyNumber}
          active={active}
          setActive={setActive}
          setOptionsContainer={setOptionsContainer}
          hasMax={hasMax}
          fullWidth={fullWidth}
          insidePicker={insidePicker}
        />
        {!!optionsContainer && optionsContainer(top, width)}
      </FieldWrapper>
    </Container>
  )
}
