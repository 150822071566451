import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
`

export const Block = styled.div`
  padding: 25px 25px 30px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
`

export const Title = styled.h2`
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  color: ${props => props.theme.colors.darkText};
  margin-bottom: 25px;
`

export const Paragraph = styled.p<{ mb?: string; grey1?: boolean }>`
  font-size: 14px;
  line-height: 150%;
  color: ${props => (props.grey1 ? props.theme.colors.grey1 : props.theme.colors.darkText)};
  margin-bottom: ${props => props.mb};
`
