import styled from 'styled-components'

export const Balance = styled.div`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: ${props => props.theme.colors.settingField};
  font-size: 14px;
  font-weight: ${props => props.theme.weights.medium};
  color: ${props => props.theme.colors.darkText};
`

export const PaymentMethods = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.weights.medium};
  color: ${props => props.theme.colors.darkText};
`

export const PaymentMethod = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  & > span {
    font-size: 14px;
    &:first-child {
      color: ${props => props.theme.colors.grey1};
      font-weight: 500;
    }
    &:last-child {
      color: ${props => props.theme.colors.darkText};
    }
  }
`
