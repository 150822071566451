import React from 'react'

interface Props {
  children: React.ReactNode
  slug?: string
}

export const GithubLink: React.FC<Props> = ({ children, slug }) => (
  <a href={`https://github.com/${slug}`} target="_blank" rel="noreferrer">
    {children}
  </a>
)
