import styled, { css } from 'styled-components'

export const Crumbs = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  white-space: nowrap;
`

export const IconWrapper = styled.div`
  display: flex;
  background: ${props => props.theme.colors.settingField};
  border-radius: 8px;
  padding: 6px;
  & svg {
    & g {
      opacity: 1;
    }
    & path,
    & rect {
      transition: 300ms ease-out;
      &[stroke] {
        stroke: ${props => props.theme.colors.darkText};
      }
      &[fill] {
        fill: ${props => props.theme.colors.darkText};
      }
    }
  }
`

interface ICrumb {
  clickable?: boolean
  reverseColors?: boolean
  small?: boolean
}

export const Crumb = styled.div<ICrumb>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: ${props => (props.small ? 18 : 22)}px;
  line-height: 150%;
  color: ${props =>
    props.clickable
      ? props.reverseColors
        ? props.theme.colors.darkText
        : props.theme.colors.grey1
      : props.reverseColors
      ? props.theme.colors.grey1
      : props.theme.colors.darkText};
  transition: 300ms ease-out;
  ${props =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
        color: ${props.theme.colors.darkText};
        ${IconWrapper} {
          & svg {
            & path,
            & rect {
              &[stroke] {
                stroke: ${props.theme.colors.darkText};
              }
              &[fill] {
                fill: ${props.theme.colors.darkText};
              }
            }
          }
        }
      }
      ${IconWrapper} {
        & svg {
          & path,
          & rect {
            &[stroke] {
              stroke: ${props.theme.colors.grey1};
            }
            &[fill] {
              fill: ${props.theme.colors.grey1};
            }
          }
        }
      }
    `}
`
