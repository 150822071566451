import React from 'react'
import { ISvg, Svg } from '../Svg'

const NavigationTreeFolderOpen: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3 3C2.44772 3 2 3.44772 2 4V10.25L2.99711 7.75722C3.30084 6.9979 4.03626 6.5 4.85407 6.5H13V6C13 5.44772 12.5523 5 12 5H8.28078C7.82191 5 7.42193 4.6877 7.31063 4.24254L7.18937 3.75746C7.07807 3.3123 6.67809 3 6.21922 3H3Z"
      fill="#B8BDC8"
    />
    <path
      d="M5.14391 7.5C4.75177 7.5 4.39582 7.7292 4.23355 8.0862L2.64264 11.5862C2.34168 12.2483 2.82571 13 3.553 13H11.2471C11.6396 13 11.9958 12.7704 12.1579 12.4129L13.7445 8.91288C14.0446 8.25088 13.5606 7.5 12.8338 7.5H5.14391Z"
      fill="#B8BDC8"
    />
  </Svg>
)
export default NavigationTreeFolderOpen
