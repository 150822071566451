import styled from 'styled-components'

import * as text from 'partials/LandingLayout/components/TextStyles'

export const FooterBox = styled.div`
  margin-top: ${props => props.theme.spacing.s1}px;
  padding-bottom: ${props => props.theme.spacing.s1}px;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

export const Caption = styled.div`
  ${text.FS14}
  color: ${props => props.theme.colors.grayText};
  text-align: center;
`
