import React from 'react'
import { ISvg, Svg } from '../Svg'

const Checkbox: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="6.75" y="6.75" width="18.5" height="18.5" rx="2.05" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M12 15.5L15.375 19L21 12" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Checkbox
