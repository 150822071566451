import React, { useEffect, useState } from 'react'

import * as styled from '../CustomPicker.styled'

import { CustomPicker } from 'components'
import { addDevice, Component, Device } from 'pages/ScreenshotsStudio/utils'
import { convertSvgStringToImage, generateFirestoreId, includesSearch } from 'utils'

interface Props {
  onDeviceClick: (deviceType: Device) => void
  close: () => void
}

export const DevicePicker: React.FC<Props> = ({ onDeviceClick, close }) => {
  const [search, setSearch] = useState('')
  const [values, setValues] = useState<any[]>([])
  const [hovered, setHovered] = useState('')
  const devices = [Device.iPhone13Pro, Device.iPhone14Pro]

  useEffect(() => {
    getValues()
  }, [])

  const getValues = async () => {
    const values = []
    for (const el of devices) {
      values.push(
        await addDevice({
          type: Component.DEVICE,
          id: generateFirestoreId(),
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          angle: 0,
          deviceType: el,
          screenshotName: '',
          image: '',
          color: '',
          colorName: '',
        })
      )
    }
    setValues(
      values.map((el, i) => ({
        deviceType: devices[i],
        image: convertSvgStringToImage(el.innerHTML),
        device: el as SVGElement,
      }))
    )
  }

  const onClick = (deviceType: Device) => {
    onDeviceClick(deviceType)
    close()
  }

  return (
    <CustomPicker
      title="Devices"
      close={close}
      setSearch={setSearch}
      optionsContainer={
        <styled.Items>
          {values
            .filter(el => includesSearch(el.deviceType, search))
            .map(el => (
              <styled.Item
                key={el.deviceType}
                onClick={() => onClick(el.deviceType)}
                onMouseOver={() => setHovered(el.image)}
                onMouseLeave={() => setHovered('')}
              >
                <img height={22} src={el.image} alt="device" />
                <styled.ItemLabel>{el.deviceType}</styled.ItemLabel>
              </styled.Item>
            ))}
          {hovered && (
            <styled.HoveredItem>
              <img src={hovered} alt="hovered" />
            </styled.HoveredItem>
          )}
        </styled.Items>
      }
    />
  )
}
