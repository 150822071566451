import { addLines } from './addElements'
import { IDevice, IImage, IText, minHeight, minWidth } from './common'

export const resizeContainer = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
resizeContainer.id = 'resize'
const div = document.createElement('div')
resizeContainer.appendChild(div)

const topLeftResize = document.createElement('i')
topLeftResize.id = 'topLeftResize'
resizeContainer.appendChild(topLeftResize)

const topRightResize = document.createElement('i')
topRightResize.id = 'topRightResize'
resizeContainer.appendChild(topRightResize)

const bottomRightResize = document.createElement('i')
bottomRightResize.id = 'bottomRightResize'
resizeContainer.appendChild(bottomRightResize)

const bottomLeftResize = document.createElement('i')
bottomLeftResize.id = 'bottomLeftResize'
resizeContainer.appendChild(bottomLeftResize)

const rotate = document.createElement('i')
rotate.id = 'rotate'
resizeContainer.appendChild(rotate)

const rotatedPos = (x: number, y: number, cx: number, cy: number, angle: number) => [
  (x - cx) * Math.cos(angle) - (y - cy) * Math.sin(angle) + cx,
  (x - cx) * Math.sin(angle) + (y - cy) * Math.cos(angle) + cy,
]

const newXYPos = (
  x: number,
  y: number,
  width: number,
  height: number,
  newWidth: number,
  newHeight: number,
  angle: number
) => {
  angle = angle * (Math.PI / 180)
  const cx = x + width / 2
  const cy = y + height / 2
  const rotatedA = rotatedPos(x, y, cx, cy, angle)
  const rotatedC = rotatedPos(x + newWidth, y + newHeight, cx, cy, angle)
  const newCenter = [(rotatedA[0] + rotatedC[0]) / 2, (rotatedA[1] + rotatedC[1]) / 2]
  return rotatedPos(rotatedA[0], rotatedA[1], newCenter[0], newCenter[1], -angle)
}

export const resizeHandler = (
  element: SVGElement,
  linesContainer: SVGElement,
  updateActiveElement: (activeElement: IText | IImage | IDevice) => void,
  activeElement: IText | IImage | IDevice,
  isText: boolean,
  onMouseDown?: () => void
) => {
  // @ts-ignore
  const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
  let x = +aX.value
  let y = +aY.value
  let width = +aWidth.value
  let height = +aHeight.value
  let angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

  resizeContainer.setAttribute('width', String(width))
  resizeContainer.setAttribute('height', String(height))
  resizeContainer.setAttribute('x', String(x))
  resizeContainer.setAttribute('y', String(y))
  resizeContainer.style.transform = `rotate(${angle}deg)`

  topLeftResize.onmousedown = () => {
    if (onMouseDown) {
      onMouseDown()
    }
    onMouseUp(element, updateActiveElement, activeElement, linesContainer)
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    x = +aX.value
    y = +aY.value
    width = +aWidth.value
    height = +aHeight.value
    angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

    document.onmousemove = (e: MouseEvent) => {
      const radAngle = angle * (Math.PI / 180)
      if (isText) {
        const newWidth = width + -e.movementX * Math.cos(radAngle) + -e.movementY * Math.sin(radAngle)
        const newHeight = height + -e.movementY * Math.cos(radAngle) + e.movementX * Math.sin(radAngle)
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX + (newWidth - width) * -Math.cos(radAngle) + (newHeight - height) * Math.sin(radAngle)
          y = nY + (newHeight - height) * -Math.cos(radAngle) + (newWidth - width) * -Math.sin(radAngle)
          width = newWidth
          height = newHeight
        }
      } else {
        const c1 = Math.sin(radAngle) - Math.cos(radAngle)
        const c2 = -Math.sin(radAngle) - Math.cos(radAngle)
        const coefficient = (c1 * e.movementX + c2 * e.movementY) / (width / height + 1)
        const newWidth = (width * (height + coefficient)) / height
        const newHeight = height + coefficient
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x =
            nX + ((newWidth - width) * Math.sin(radAngle)) / (width / height) + (newWidth - width) * -Math.cos(radAngle)
          y = nY + (coefficient * -Math.sin(radAngle)) / (height / width) + coefficient * -Math.cos(radAngle)
          width = newWidth
          height = newHeight
        }
      }

      resizeContainer.setAttribute('x', String(x))
      resizeContainer.setAttribute('y', String(y))
      element.setAttribute('x', String(x))
      element.setAttribute('y', String(y))

      resizeContainer.setAttribute('width', String(width))
      resizeContainer.setAttribute('height', String(height))
      element.setAttribute('width', String(width))
      element.setAttribute('height', String(height))
      addLines(element, x, y, width, height, angle, linesContainer)
    }
  }

  topRightResize.onmousedown = () => {
    if (onMouseDown) {
      onMouseDown()
    }
    onMouseUp(element, updateActiveElement, activeElement, linesContainer)
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    x = +aX.value
    y = +aY.value
    width = +aWidth.value
    height = +aHeight.value
    angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

    document.onmousemove = (e: MouseEvent) => {
      const radAngle = angle * (Math.PI / 180)
      if (isText) {
        const newWidth = width + e.movementX * Math.cos(radAngle) + e.movementY * Math.sin(radAngle)
        const newHeight = height + -e.movementY * Math.cos(radAngle) + e.movementX * Math.sin(radAngle)
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX + (newHeight - height) * Math.sin(radAngle)
          y = nY + (newHeight - height) * -Math.cos(radAngle)
          width = newWidth
          height = newHeight
        }
      } else {
        const c1 = Math.sin(radAngle) + Math.cos(radAngle)
        const c2 = Math.sin(radAngle) - Math.cos(radAngle)
        const coefficient = (c1 * e.movementX + c2 * e.movementY) / (width / height + 1)
        const newWidth = (width * (height + coefficient)) / height
        const newHeight = height + coefficient
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX + ((newWidth - width) * Math.sin(radAngle)) / (width / height)
          y = nY + coefficient * -Math.cos(radAngle)
          width = newWidth
          height = newHeight
        }
      }

      resizeContainer.setAttribute('x', String(x))
      resizeContainer.setAttribute('y', String(y))
      element.setAttribute('x', String(x))
      element.setAttribute('y', String(y))

      resizeContainer.setAttribute('width', String(width))
      resizeContainer.setAttribute('height', String(height))
      element.setAttribute('width', String(width))
      element.setAttribute('height', String(height))
      addLines(element, x, y, width, height, angle, linesContainer)
    }
  }

  bottomRightResize.onmousedown = () => {
    if (onMouseDown) {
      onMouseDown()
    }
    onMouseUp(element, updateActiveElement, activeElement, linesContainer)
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    x = +aX.value
    y = +aY.value
    width = +aWidth.value
    height = +aHeight.value
    angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

    document.onmousemove = (e: MouseEvent) => {
      const radAngle = angle * (Math.PI / 180)
      if (isText) {
        const newWidth = width + e.movementX * Math.cos(radAngle) + e.movementY * Math.sin(radAngle)
        const newHeight = height + e.movementY * Math.cos(radAngle) + -e.movementX * Math.sin(radAngle)
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX
          y = nY
          width = newWidth
          height = newHeight
        }
      } else {
        const c1 = -Math.sin(radAngle) + Math.cos(radAngle)
        const c2 = Math.sin(radAngle) + Math.cos(radAngle)
        const coefficient = (c1 * e.movementX + c2 * e.movementY) / (width / height + 1)
        const newWidth = (width * (height + coefficient)) / height
        const newHeight = height + coefficient
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX
          y = nY
          width = newWidth
          height = newHeight
        }
      }

      resizeContainer.setAttribute('x', String(x))
      resizeContainer.setAttribute('y', String(y))
      element.setAttribute('x', String(x))
      element.setAttribute('y', String(y))

      resizeContainer.setAttribute('width', String(width))
      resizeContainer.setAttribute('height', String(height))
      element.setAttribute('width', String(width))
      element.setAttribute('height', String(height))
      addLines(element, x, y, width, height, angle, linesContainer)
    }
  }

  bottomLeftResize.onmousedown = () => {
    if (onMouseDown) {
      onMouseDown()
    }
    onMouseUp(element, updateActiveElement, activeElement, linesContainer)
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    x = +aX.value
    y = +aY.value
    width = +aWidth.value
    height = +aHeight.value
    angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

    document.onmousemove = (e: MouseEvent) => {
      const radAngle = angle * (Math.PI / 180)
      if (isText) {
        const newWidth = width + -e.movementX * Math.cos(radAngle) + -e.movementY * Math.sin(radAngle)
        const newHeight = height + e.movementY * Math.cos(radAngle) + -e.movementX * Math.sin(radAngle)
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX + (newWidth - width) * -Math.cos(radAngle)
          y = nY + (newWidth - width) * -Math.sin(radAngle)
          width = newWidth
          height = newHeight
        }
      } else {
        const c1 = -Math.sin(radAngle) - Math.cos(radAngle)
        const c2 = -Math.sin(radAngle) + Math.cos(radAngle)
        const coefficient = (c1 * e.movementX + c2 * e.movementY) / (width / height + 1)
        const newWidth = (width * (height + coefficient)) / height
        const newHeight = height + coefficient
        if (newWidth > minWidth && newHeight > minHeight) {
          const [nX, nY] = newXYPos(x, y, width, height, newWidth, newHeight, angle)
          x = nX + (newWidth - width) * -Math.cos(radAngle)
          y = nY + (coefficient * -Math.sin(radAngle)) / (height / width)
          width = newWidth
          height = newHeight
        }
      }

      resizeContainer.setAttribute('x', String(x))
      resizeContainer.setAttribute('y', String(y))
      element.setAttribute('x', String(x))
      element.setAttribute('y', String(y))

      resizeContainer.setAttribute('width', String(width))
      resizeContainer.setAttribute('height', String(height))
      element.setAttribute('width', String(width))
      element.setAttribute('height', String(height))
      addLines(element, x, y, width, height, angle, linesContainer)
    }
  }

  rotate.onmousedown = () => {
    if (onMouseDown) {
      onMouseDown()
    }
    onMouseUp(element, updateActiveElement, activeElement, linesContainer)
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    x = +aX.value
    y = +aY.value
    width = +aWidth.value
    height = +aHeight.value
    angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

    document.onmousemove = (e: MouseEvent) => {
      const boxBoundingRect = element.getBoundingClientRect()
      const boxCenter = {
        x: boxBoundingRect.left + boxBoundingRect.width / 2,
        y: boxBoundingRect.top + boxBoundingRect.height / 2,
      }
      angle = Math.atan2(e.pageX - boxCenter.x, -(e.pageY - boxCenter.y)) * (180 / Math.PI)

      if (angle > -5 && angle < 5) angle = 0
      if (angle > 40 && angle < 50) angle = 45
      if (angle < -40 && angle > -50) angle = -45
      if (angle > 85 && angle < 95) angle = 90
      if (angle < -85 && angle > -95) angle = -90
      if (angle > 130 && angle < 140) angle = 135
      if (angle < -130 && angle > -140) angle = -135
      if (angle > 175 || angle < -175) angle = 180

      resizeContainer.style.transform = `rotate(${angle}deg)`
      element.style.transform = `rotate(${angle}deg)`
      addLines(element, x, y, width, height, angle, linesContainer)
    }
  }
}

export const contenteditableHandler = (element: SVGElement, condition: boolean, action: (value: string) => void) => {
  const span = element.querySelector('span')
  if (span && condition) {
    span.setAttribute('contenteditable', 'true')
    setTimeout(() => span.focus(), 0)
    let debounceFn: any = null
    span.oninput = (e: any) => {
      if (debounceFn) {
        clearTimeout(debounceFn)
      }
      debounceFn = setTimeout(() => action(e.target.innerText), 600)
    }
    span.onblur = () => span.removeAttribute('contenteditable')
  }
}

export const elementMouseDown = (
  element: SVGElement,
  linesContainer: SVGElement,
  blue: string,
  onMouseDown: () => void,
  updateActiveElement: (activeElement: IText | IImage | IDevice) => void,
  activeElement: IText | IImage | IDevice,
  isText?: boolean
) => {
  element.onmousedown = e => {
    onMouseUp(element, updateActiveElement, activeElement, linesContainer)
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    let x = +aX.value
    let y = +aY.value
    let width = +aWidth.value
    let height = +aHeight.value
    let angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0

    onMouseDown()

    if (isText) {
      contenteditableHandler(element, e.detail === 2, value => updateActiveElement({ ...activeElement, value }))
    }

    let activeHorizontal: Element | false = false
    let activeVertical: Element | false = false
    document.onmousemove = (e: MouseEvent) => {
      const isBody = document.activeElement?.tagName === 'BODY'
      if (!isBody) return

      x += e.movementX
      y += e.movementY

      let nX: number | false = false
      let nY: number | false = false
      const lines = linesContainer.querySelectorAll(`line:not([id*=line-${element.id}])`)
      if (lines) {
        if (activeHorizontal) {
          activeHorizontal.removeAttribute('stroke')
        }
        if (activeVertical) {
          activeVertical.removeAttribute('stroke')
        }
        activeHorizontal = false
        activeVertical = false
        try {
          Array.from(lines).forEach(line => {
            // @ts-ignore
            const { x1: aX1, y1: aY1 } = line.attributes
            const x1 = +aX1.value
            const y1 = +aY1.value
            const notRotated = angle === 0 || angle === 180
            const distance = 8

            if (y > y1 - distance && y < y1 + distance && !activeHorizontal && notRotated) {
              nY = y1
              line.setAttribute('stroke', blue)
              activeHorizontal = line
            } else if (y + height / 2 > y1 - distance && y + height / 2 < y1 + distance && !activeHorizontal) {
              nY = y1 - height / 2
              line.setAttribute('stroke', blue)
              activeHorizontal = line
            } else if (y + height > y1 - distance && y + height < y1 + distance && !activeHorizontal && notRotated) {
              nY = y1 - height
              line.setAttribute('stroke', blue)
              activeHorizontal = line
            } else if (x > x1 - distance && x < x1 + distance && !activeVertical && notRotated) {
              nX = x1
              line.setAttribute('stroke', blue)
              activeVertical = line
            } else if (x + width / 2 > x1 - distance && x + width / 2 < x1 + distance && !activeVertical) {
              nX = x1 - width / 2
              line.setAttribute('stroke', blue)
              activeVertical = line
            } else if (x + width > x1 - distance && x + width < x1 + distance && !activeVertical && notRotated) {
              nX = x1 - width
              line.setAttribute('stroke', blue)
              activeVertical = line
            }
            if (activeHorizontal && activeVertical) {
              throw new Error('Search optimization')
            }
          })
        } catch {}
      }

      resizeContainer.setAttribute('x', String(nX !== false ? nX : x))
      resizeContainer.setAttribute('y', String(nY !== false ? nY : y))
      element.setAttribute('x', String(nX !== false ? nX : x))
      element.setAttribute('y', String(nY !== false ? nY : y))
      addLines(element, nX !== false ? nX : x, nY !== false ? nY : y, width, height, angle, linesContainer)
    }
  }
}

const onMouseUp = (
  element: SVGElement,
  updateActiveElement: (activeElement: IText | IImage | IDevice) => void,
  activeElement: IText | IImage | IDevice,
  linesContainer: SVGElement
) => {
  document.onmouseup = () => {
    document.onmousemove = () => {}
    document.onmouseup = () => {}
    // @ts-ignore
    const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
    const x = +aX.value
    const y = +aY.value
    const width = +aWidth.value
    const height = +aHeight.value
    const angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0
    updateActiveElement({ ...activeElement, x, y, width, height, angle })
    const lines = linesContainer.querySelectorAll('line')
    if (lines) {
      Array.from(lines).forEach(line => {
        line.removeAttribute('stroke')
      })
    }
  }
}
