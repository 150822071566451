import React from 'react'
import { ISvg, Svg } from '../Svg'

const BuildSuccessSmall: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_14170_36023)">
      <circle cx="8" cy="8" r="7.25" fill="white" stroke="#389E0D" strokeWidth="1.5" />
      <path d="M5 7.81818L7.11765 10L11 6" stroke="#389E0D" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_14170_36023">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default BuildSuccessSmall
