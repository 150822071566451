import styled from 'styled-components'

export const Item = styled.div<{ small: boolean; active: boolean; asset?: boolean; hasMovable?: boolean }>`
  cursor: pointer;
  margin-bottom: ${props => !props.small && '10px'};
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => (props.active ? props.theme.colors.blue : props.theme.colors.borderColor)};
  border-radius: 8px;
  width: ${props => (props.asset ? (props.hasMovable ? '302px' : props.small ? '32.34%' : '260px') : '100%')};
  height: ${props => props.asset && '260px'};
  box-sizing: border-box;
  display: flex;
  transition: border-color 300ms ease-out 0s;
  &:hover {
    border-color: ${props => props.theme.colors.blue};
  }
`

export const ShowMoreActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & > svg {
    & circle {
      transition: 300ms ease-out;
    }
    &:hover circle {
      fill: ${props => props.theme.colors.iconHover};
    }
  }
`

export const Move = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-right: 1px solid ${props => props.theme.colors.borderColor};
  & svg {
    pointer-events: none;
    & path {
      transition: 300ms ease-out;
    }
  }
  &:hover svg path {
    fill: ${props => props.theme.colors.iconHover};
  }
`
