import React from 'react'
import { ISvg, Svg } from '../Svg'

const Boolean: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3.3252 12.0928C3.78906 12.0928 4.0625 11.8096 4.0625 11.3262V9.36816L6.21094 6.04297C6.31836 5.87207 6.36719 5.71094 6.36719 5.54004C6.36719 5.14941 6.05469 4.85645 5.63965 4.85645C5.34668 4.85645 5.15625 4.98828 4.9707 5.31055L3.35449 7.96191H3.32031L1.70898 5.31055C1.51367 4.97363 1.34277 4.85645 1.02539 4.85645C0.600586 4.85645 0.288086 5.14941 0.288086 5.5498C0.288086 5.71582 0.332031 5.88184 0.439453 6.04785L2.58789 9.36816V11.3262C2.58789 11.8096 2.86133 12.0928 3.3252 12.0928ZM9.92676 12.0928C10.3662 12.0928 10.6299 11.8242 10.6299 11.3555V7.38574H10.6738L13.7305 11.668C13.9551 11.9805 14.1504 12.0928 14.4678 12.0928C14.9316 12.0928 15.1904 11.834 15.1904 11.375V5.59863C15.1904 5.125 14.9316 4.85645 14.4873 4.85645C14.043 4.85645 13.7793 5.125 13.7793 5.59863V9.54395H13.7402L10.6934 5.28613C10.4688 4.97852 10.2637 4.85645 9.96094 4.85645C9.49219 4.85645 9.21875 5.125 9.21875 5.59375V11.3555C9.21875 11.8242 9.48242 12.0928 9.92676 12.0928Z"
      fill="#7F899E"
    />
    <path
      d="M5.80078 12.6699C6.14746 12.6699 6.34766 12.5088 6.4502 12.1572L8.61328 4.69141C8.6377 4.6084 8.64746 4.53516 8.64746 4.44727C8.64746 4.09082 8.39355 3.84668 8.0127 3.84668C7.66602 3.84668 7.46582 4.02246 7.3584 4.39844L5.2002 11.8398C5.18066 11.9131 5.16602 12.001 5.16602 12.0986C5.16602 12.4209 5.39551 12.6699 5.80078 12.6699Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Boolean
