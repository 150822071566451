import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as cardStyled from '../AppCard/AppCard.styled'
import * as styled from './CreateProject.styled'

import { Text, Type } from 'components'
import { WorkspaceContext } from 'context'

export const CreateProject = () => {
  const { workspace } = useContext(WorkspaceContext)
  const navigate = useNavigate()
  const themeContext = useContext(ThemeContext)

  return workspace ? (
    <cardStyled.Container load={false}>
      <styled.Box onClick={() => navigate(`/workspaces/${workspace.id}/templates`)}>
        <cardStyled.CardBox>
          <styled.PlusBorderBox>
            <styled.PlusBox>
              <styled.PlusLine orientation="vertical" />
              <styled.PlusLine orientation="horizontal" />
            </styled.PlusBox>
          </styled.PlusBorderBox>
          <Text type={Type.H3} as="span" fontWeight={themeContext.weights.normal}>
            New project
          </Text>
        </cardStyled.CardBox>
      </styled.Box>
    </cardStyled.Container>
  ) : null
}
