import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { HugeLinkBox } from '../ProjectMenu/ProjectMenu.styled'
import * as styled from './Profile.styled'

import { Avatar, DropDown, Icon, Name } from 'components'
import { updateUserInfo, updateUserInfoEvent, useLastUpdate } from 'hooks'
import firebase, { auth } from 'services/firebase'

interface Props {
  isMobile: boolean
}

export const Profile: React.FC<Props> = ({ isMobile }) => {
  const themeContext = useContext(ThemeContext)
  const [user, setUser] = useState<firebase.User | null>(null)
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  JSON.stringify(lastUpdate)

  useLastUpdate(updateUserInfoEvent, () => {
    setUser(auth.currentUser)
    setLastUpdate(+new Date())
  })

  useEffect(() => {
    updateUserInfo()
  }, [])

  const logoutOption = {
    label: 'Log out',
    iconName: Name.PICKERS_LOGOUT,
    onClick: () => auth.signOut(),
    color: themeContext.colors.logOut,
  }

  return user ? (
    <DropDown
      options={
        isMobile
          ? [logoutOption]
          : [
              { label: 'Account settings', iconName: Name.PICKERS_ACCOUNT_SETTINGS, to: '/account' },
              { label: 'Terms & policies', iconName: Name.PICKERS_TERMS_AND_POLICES, to: '/account', divider: true },
              logoutOption,
            ]
      }
      top={isMobile ? '-120px' : '-180px'}
      left={!isMobile ? '232px' : undefined}
      right={isMobile ? '0px' : undefined}
      width="240px"
      topChildren
      style={{ paddingTop: '0' }}
      labelElement={
        <HugeLinkBox isProfile isMobile={isMobile}>
          <Avatar value={user.photoURL} width={35} />
          <Box ml="10px" display="flex" alignItems="center" justifyContent="space-between" width="100%">
            {user.displayName}
            <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
          </Box>
        </HugeLinkBox>
      }
    >
      <styled.ProfileInfo>
        <Avatar value={user.photoURL} width={35} />
        <Box ml="10px">
          <p>{user.displayName}</p>
          <p>{user.email}</p>
        </Box>
      </styled.ProfileInfo>
    </DropDown>
  ) : null
}
