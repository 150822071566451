import React from 'react'
import { ISvg, Svg } from '../Svg'

const AccessLevel: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="2.75" y="5.75" width="10.5" height="8.5" rx="1.49" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M10 6V4C10 2.89543 9.10457 2 8 2V2C6.89543 2 6 2.89543 6 4V6" stroke="#7F899E" strokeWidth="1.5" />
    <path
      d="M9.33268 9.33333C9.33268 10.0697 8.73573 10.6667 7.99935 10.6667C7.26297 10.6667 6.66602 10.0697 6.66602 9.33333C6.66602 8.59695 7.26297 8 7.99935 8C8.73573 8 9.33268 8.59695 9.33268 9.33333Z"
      fill="#7F899E"
    />
    <path
      d="M7.33268 9.33333C7.33268 8.96514 7.63116 8.66667 7.99935 8.66667C8.36754 8.66667 8.66602 8.96514 8.66602 9.33333V11.3333C8.66602 11.7015 8.36754 12 7.99935 12C7.63116 12 7.33268 11.7015 7.33268 11.3333V9.33333Z"
      fill="#7F899E"
    />
  </Svg>
)

export default AccessLevel
