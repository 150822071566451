import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Icon, Name } from 'components'
import { ProgressContext } from 'context'
import { Task } from 'hooks'
import { ProjectsService } from 'services/projects'
import { IconWrapper } from './IconWrapper'

interface Props {
  task?: Task
}

export const Rerun: React.FC<Props> = ({ task }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [wait, setWait] = useState(false)

  const createTask = (task: Task) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!wait) {
      setWait(true)
      startLoader()
      const { type, applicationId, deploymentType } = task
      ProjectsService.createTask(id as string, { type, applicationId, deploymentType })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setWait(false)
        })
    }
  }

  return task ? (
    <IconWrapper>
      <Icon name={Name.PICKERS_RERUN_TASK} onClick={createTask(task)} width={16} height={16} />
    </IconWrapper>
  ) : (
    <></>
  )
}
