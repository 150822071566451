import React from 'react'
import { ISvg, Svg } from '../Svg'

const Play: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12.6 7.26438C13.1333 7.59133 13.1333 8.40868 12.6 8.73562L4.2 13.8849C3.66667 14.2119 3 13.8032 3 13.1493L3 2.85068C3 2.1968 3.66667 1.78813 4.2 2.11507L12.6 7.26438Z"
      fill="#212133"
    />
  </Svg>
)

export default Play
