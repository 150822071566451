import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Img = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 7px;
  object-fit: cover;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
`
