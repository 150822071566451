import styled from 'styled-components'

export const Pick = styled.div<{ selected: boolean; transparent?: boolean }>`
  opacity: ${props => props.transparent && '0'};
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: ${props => (props.selected ? props.theme.colors.primaryText : props.theme.colors.settingField)};
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.darkText)};
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  transition: ${props => props.theme.transition.primary};
  & > i {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1.5px solid ${props => (props.selected ? props.theme.colors.white : props.theme.colors.grey)};
    background: ${props => props.selected && props.theme.colors.white};
    outline: ${props => props.selected && `1.5px solid ${props.theme.colors.primaryText}`};
    outline-offset: ${props => props.selected && '-2.5px'};
    transition: ${props => props.theme.transition.primary};
  }
  &:hover {
    background: ${props => !props.selected && props.theme.colors.settingFieldHover};
  }
`
