import React from 'react'
import { ISvg, Svg } from '../Svg'

const IOS: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.4218 1C14.3444 1.07274 13.0852 1.74594 12.3514 2.62257C11.6819 3.41783 11.1313 4.59901 11.346 5.74689C12.5231 5.78265 13.7394 5.09342 14.4442 4.20199C15.1035 3.37221 15.6024 2.19844 15.4218 1Z"
      fill="#212133"
    />
    <path
      d="M19.6767 7.74914C18.6423 6.48289 17.1885 5.74805 15.8156 5.74805C14.0032 5.74805 13.2365 6.59509 11.9772 6.59509C10.6788 6.59509 9.69239 5.75051 8.12495 5.75051C6.5853 5.75051 4.94588 6.66907 3.90639 8.23986C2.44505 10.4518 2.69513 14.6106 5.06334 18.1528C5.91084 19.4203 7.04253 20.8456 8.52281 20.858C9.84016 20.8703 10.2115 20.0331 11.9962 20.0245C13.7809 20.0146 14.1194 20.8691 15.4342 20.8555C16.9157 20.8444 18.1093 19.265 18.9568 17.9975C19.5643 17.0888 19.7904 16.6314 20.2615 15.6056C16.8349 14.3319 16.2855 9.57515 19.6767 7.74914Z"
      fill="#212133"
    />
  </Svg>
)

export default IOS
