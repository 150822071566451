import React from 'react'

import { ISvg, Svg } from '../Svg'

const News: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M17.3852 4C16.6297 4 15.9284 4.5082 15.3473 5.37255L8.72846 8.92957H4.23795C4.00901 8.92957 3.77821 9.10109 3.72849 9.31408C3.68767 9.48844 3.65428 9.66563 3.622 9.84141C3.15503 9.9675 2.74394 10.2357 2.45118 10.6054C2.15842 10.975 2 11.4258 2 11.8893C2 12.3527 2.15842 12.8035 2.45118 13.1731C2.74394 13.5428 3.15503 13.811 3.622 13.9371C3.65354 14.1143 3.68693 14.2915 3.72775 14.4641C3.77747 14.6767 4.00826 14.8486 4.23721 14.8486H4.88136L6.59192 19.7657C6.61813 19.8324 6.66432 19.8901 6.7247 19.9318C6.78509 19.9734 6.857 19.9972 6.93144 20H9.41751C9.70359 20 9.86277 19.7898 9.77075 19.5319L8.13625 14.8486H8.72994L15.3496 18.4081C15.9292 19.2714 16.6301 19.7789 17.3867 19.7789C19.3833 19.7789 21 16.2477 21 11.8891C21 7.53043 19.3818 4 17.3852 4ZM14.242 8.33986L9.76185 10.7476C9.75315 10.7516 9.74423 10.7551 9.73513 10.7582C9.724 10.7632 9.71361 10.7664 9.70248 10.7706C9.65854 10.7874 9.61177 10.7964 9.56445 10.7972H5.18599C5.07882 10.7972 4.97605 10.7565 4.90027 10.6842C4.82449 10.6118 4.78191 10.5136 4.78191 10.4113C4.78191 10.3089 4.82449 10.2108 4.90027 10.1384C4.97605 10.066 5.07882 10.0253 5.18599 10.0253H9.4587L13.8464 7.66723C13.9399 7.61704 14.0504 7.60437 14.1537 7.63202C14.2048 7.64571 14.2527 7.66888 14.2944 7.70022C14.3362 7.73156 14.371 7.77044 14.3971 7.81465C14.4231 7.85887 14.4397 7.90754 14.4461 7.9579C14.4524 8.00826 14.4483 8.05931 14.4339 8.10814C14.4196 8.15698 14.3953 8.20264 14.3625 8.24252C14.3297 8.2824 14.289 8.31572 14.2427 8.34057L14.242 8.33986ZM17.3852 18.3003C16.9462 18.3003 15.8156 16.7917 15.4438 13.8822V13.8623C16.8839 13.6795 17.9622 12.8658 17.9622 11.8891C17.9622 10.9124 16.8839 10.0955 15.4438 9.913V9.8928C15.8204 6.98893 16.9462 5.47922 17.3852 5.47922C17.9173 5.47922 19.4505 7.68459 19.4505 11.8898C19.4505 16.095 17.9188 18.3003 17.3852 18.3003Z"
      fill="#212133"
    />
  </Svg>
)

export default News
