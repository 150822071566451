import React from 'react'
import { ISvg, Svg } from '../Svg'

const DateTime: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M10.3878 10.4615C10.7808 10.5925 11.2055 10.3801 11.3365 9.98717C11.4675 9.59421 11.2551 9.16947 10.8622 9.03849L10.3878 10.4615ZM8 8.875H7.25C7.25 9.19782 7.45657 9.48443 7.76283 9.58651L8 8.875ZM8.75 5.21623C8.75 4.80202 8.41421 4.46623 8 4.46623C7.58579 4.46623 7.25 4.80202 7.25 5.21623H8.75ZM10.8622 9.03849L8.23717 8.16349L7.76283 9.58651L10.3878 10.4615L10.8622 9.03849ZM8.75 8.875V5.21623H7.25V8.875H8.75ZM14.25 8C14.25 11.4518 11.4518 14.25 8 14.25V15.75C12.2802 15.75 15.75 12.2802 15.75 8H14.25ZM8 14.25C4.54822 14.25 1.75 11.4518 1.75 8H0.25C0.25 12.2802 3.71979 15.75 8 15.75V14.25ZM1.75 8C1.75 4.54822 4.54822 1.75 8 1.75V0.25C3.71979 0.25 0.25 3.71979 0.25 8H1.75ZM8 1.75C11.4518 1.75 14.25 4.54822 14.25 8H15.75C15.75 3.71979 12.2802 0.25 8 0.25V1.75Z"
      fill="#7F899E"
    />
  </Svg>
)

export default DateTime
