import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import * as styled from './BillingAccount.styled'

import { Loader, NoContent } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { WorkspacesService } from 'services/workspaces'
import { getMoney } from 'utils'
import { Card } from '../Card'

const getAddress = (billingAccount: any) => {
  const { address, phone } = billingAccount
  return [address?.line1, address?.line2, address?.postal_code, address?.city, address?.state, address?.country, phone]
    .filter(el => !!el)
    .join(', ')
}

export const BillingAccount: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { workspace } = useContext(WorkspaceContext)
  const navigate = useNavigate()
  const [wait, setWait] = useState(true)
  const [billingAccount, setBillingAccount] = useState<any>()

  useEffect(() => {
    if (workspace) {
      setWait(true)
      const abortController = new AbortController()
      Promise.all([
        WorkspacesService.getBillingAccount(workspace.id, workspace.billingAccountId, abortController),
        WorkspacesService.getPaymentMethods(workspace.id, workspace.billingAccountId, abortController),
      ])
        .then(res => setBillingAccount({ ...res[0].data, paymentMethods: res[1].data }))
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => setWait(false))
      return () => {
        abortController.abort()
      }
    }
  }, [workspace?.id, workspace?.billingAccountId])

  return (
    <Card
      title="Billing account"
      buttonTitle={wait ? undefined : billingAccount ? 'Manage' : 'Add'}
      onClick={() => navigate(billingAccount ? billingAccount.id : 'add')}
    >
      {wait ? (
        <Box height="310px" display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      ) : !billingAccount ? (
        <NoContent text="No billing account yet." />
      ) : (
        <Box p="15px 20px 25px" display="flex" flexDirection="column" gap="15px">
          <styled.Balance>
            Balance
            <span>{getMoney(billingAccount.currency, billingAccount.balance)}</span>
          </styled.Balance>
          <styled.PaymentMethods>
            Payment methods
            {billingAccount.paymentMethods.map((el: any) => (
              <styled.PaymentMethod key={el.id}>
                {el.card.brand} **** {el.card.last4}
              </styled.PaymentMethod>
            ))}
          </styled.PaymentMethods>
          <styled.Item>
            <span>Account details</span>
            <span>{billingAccount.metadata?.billingAccountName || '-'}</span>
          </styled.Item>
          <styled.Item>
            <span>Billing address</span>
            <span>{getAddress(billingAccount) || '-'}</span>
          </styled.Item>
          <styled.Item>
            <span>Billing email</span>
            <span> {billingAccount.email || '-'}</span>
          </styled.Item>
          <styled.Item>
            <span>Tax ID</span>
            <span>{billingAccount.tax_ids?.data[0]?.value || '-'}</span>
          </styled.Item>
        </Box>
      )}
      <Outlet />
    </Card>
  )
}
