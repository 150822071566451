import React from 'react'
import { ISvg, Svg } from '../Svg'

const AI: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <g clipPath="url(#clip0_17697_45802)">
      <path
        d="M8.80354 3L10.3187 5.77057L13.0893 7.28571L10.3187 8.80086L8.80354 11.5714L7.28839 8.80086L4.51782 7.28571L7.28839 5.77057L8.80354 3ZM14.8035 10.1429L15.8138 11.9897L17.6607 13L15.8138 14.0103L14.8035 15.8571L13.7933 14.0103L11.9464 13L13.7933 11.9897L14.8035 10.1429ZM8.80354 16.1429L9.30868 17.0663L10.2321 17.5714L9.30868 18.0766L8.80354 19L8.29839 18.0766L7.37496 17.5714L8.29839 17.0663L8.80354 16.1429Z"
        fill="#F2F2F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_17697_45802">
        <rect width="16" height="16" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </Svg>
)
export default AI
