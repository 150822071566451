import { BrandingColorType } from 'utils'

export const colorStylesSections = [
  {
    name: 'Primary',
    values: [
      BrandingColorType.primary,
      BrandingColorType.onPrimary,
      BrandingColorType.primaryContainer,
      BrandingColorType.onPrimaryContainer,
    ],
  },
  {
    name: 'Secondary',
    values: [
      BrandingColorType.secondary,
      BrandingColorType.onSecondary,
      BrandingColorType.secondaryContainer,
      BrandingColorType.onSecondaryContainer,
    ],
  },
  {
    name: 'Tertiary',
    values: [
      BrandingColorType.tertiary,
      BrandingColorType.onTertiary,
      BrandingColorType.tertiaryContainer,
      BrandingColorType.onTertiaryContainer,
    ],
  },
  {
    name: 'Neutral',
    values: [
      BrandingColorType.neutral,
      BrandingColorType.background,
      BrandingColorType.onBackground,
      BrandingColorType.surface,
      BrandingColorType.onSurface,
      BrandingColorType.surfaceVariant,
      BrandingColorType.onSurfaceVariant,
      BrandingColorType.outline,
      BrandingColorType.shadow,
      BrandingColorType.inverseSurface,
      BrandingColorType.inverseOnSurface,
    ],
  },
  {
    name: 'Error',
    values: [
      BrandingColorType.error,
      BrandingColorType.onError,
      BrandingColorType.errorContainer,
      BrandingColorType.onErrorContainer,
    ],
  },
  {
    name: 'Others',
    values: [
      BrandingColorType.transparent,
      BrandingColorType.semiTransparentBackground,
      BrandingColorType.white,
      BrandingColorType.black,
      BrandingColorType.primaryVariant,
      BrandingColorType.secondaryVariant,
      BrandingColorType.inversePrimary,
    ],
  },
]
