import React from 'react'
import { useSearchParams } from 'react-router-dom'

export enum GithubParamsName {
  CODE = 'code',
  INSTALLATION_ID = 'installation_id',
  STATE_PARAM = 'stateParam',
}

export const GithubConnection: React.FC = () => {
  const [searchParams] = useSearchParams()
  const stateParam = localStorage.getItem(GithubParamsName.STATE_PARAM)
  const state = searchParams.get('state')

  if (stateParam === state) {
    const code = searchParams.get(GithubParamsName.CODE)
    if (code) {
      localStorage.setItem(GithubParamsName.CODE, code)
    }
  } else if (stateParam + 'add' === state) {
    const installation_id = searchParams.get(GithubParamsName.INSTALLATION_ID)
    if (installation_id) {
      localStorage.setItem(GithubParamsName.INSTALLATION_ID, installation_id)
    }
  }

  return <></>
}
