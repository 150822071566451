import React from 'react'
import { ISvg, Svg } from '../Svg'

const Movable: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M11 9.5C11 8.67157 11.6716 8 12.5 8C13.3284 8 14 8.67157 14 9.5C14 10.3284 13.3284 11 12.5 11C11.6716 11 11 10.3284 11 9.5Z"
      fill="#7F899E"
    />
    <path
      d="M11 21.5C11 20.6716 11.6716 20 12.5 20C13.3284 20 14 20.6716 14 21.5C14 22.3284 13.3284 23 12.5 23C11.6716 23 11 22.3284 11 21.5Z"
      fill="#7F899E"
    />
    <path
      d="M11 15.5C11 14.6716 11.6716 14 12.5 14C13.3284 14 14 14.6716 14 15.5C14 16.3284 13.3284 17 12.5 17C11.6716 17 11 16.3284 11 15.5Z"
      fill="#7F899E"
    />
    <path
      d="M18 9.5C18 8.67157 18.6716 8 19.5 8C20.3284 8 21 8.67157 21 9.5C21 10.3284 20.3284 11 19.5 11C18.6716 11 18 10.3284 18 9.5Z"
      fill="#7F899E"
    />
    <path
      d="M18 21.5C18 20.6716 18.6716 20 19.5 20C20.3284 20 21 20.6716 21 21.5C21 22.3284 20.3284 23 19.5 23C18.6716 23 18 22.3284 18 21.5Z"
      fill="#7F899E"
    />
    <path
      d="M18 15.5C18 14.6716 18.6716 14 19.5 14C20.3284 14 21 14.6716 21 15.5C21 16.3284 20.3284 17 19.5 17C18.6716 17 18 16.3284 18 15.5Z"
      fill="#7F899E"
    />
  </Svg>
)
export default Movable
