import React, { ReactElement } from 'react'

import { BooleanInput, BrandingColorInput, CommonPicker, GradientInput, Icon, ImageInput, Name } from 'components'
import { SettingField, SettingNumberField, SpacingField, SubWrapper } from 'partials/RightSidebar/components'
import { ComponentAlignment, LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Actions } from '../Actions'
import { LocalVariables } from '../LocalVariables'

const alignmentValues = Object.values(ComponentAlignment).map(el => ({ value: el, label: el }))

interface Props {
  childrenFor: string
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
  hideLocalVariables?: boolean
  hideActions?: boolean
  moreSettings?: ReactElement
}

export const Common: React.FC<Props> = ({
  childrenFor,
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
  hideLocalVariables,
  hideActions,
  moreSettings,
}) => {
  const {
    backgroundColor,
    backgroundGradient,
    backgroundPattern,
    cornersRadius,
    borderWidth,
    borderColor,
    width,
    height,
    widthHeightRatio,
    margins,
    contentPadding,
    componentAlignment,
    visible,
  } = component

  return (
    <>
      <SubWrapper title="View" canHide childrenFor={childrenFor}>
        <BrandingColorInput
          subLabel="Fill"
          value={backgroundColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'backgroundColor', value: { constant: value } }])}
          source={backgroundColor?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'backgroundColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <GradientInput
          subLabel="Gradient"
          value={backgroundGradient}
          onChange={value => onComponentPropertiesChange([{ propName: 'backgroundGradient', value }])}
        />
        <ImageInput
          subLabel="Image"
          value={backgroundPattern?.resourceId}
          onChange={(value, resources) =>
            onComponentPropertiesChange([{ propName: 'backgroundPattern', value: { resourceId: value }, resources }])
          }
        />
        <SettingNumberField
          subLabel="Corner radius"
          value={cornersRadius?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'cornersRadius', value: { constant: value } }])}
          source={cornersRadius?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'cornersRadius', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          hasMax
        />
        <SettingNumberField
          subLabel="Border"
          value={borderWidth?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'borderWidth', value: { constant: value } }])}
          source={borderWidth?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'borderWidth', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BrandingColorInput
          subLabel="Border color"
          value={borderColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'borderColor', value: { constant: value } }])}
          source={borderColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'borderColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Layout" canHide childrenFor={childrenFor}>
        <SpacingField
          margins={margins}
          onMarginsChange={value => onComponentPropertiesChange([{ propName: 'margins', value }])}
          paddings={contentPadding?.constant}
          onPaddingsChange={value =>
            onComponentPropertiesChange([{ propName: 'contentPadding', value: { constant: value } }])
          }
        />
        <SettingNumberField
          subLabel="Width"
          value={width?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'width', value: { constant: value } }])}
          source={width?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'width', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Height"
          value={height?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'height', value: { constant: value } }])}
          source={height?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'height', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Ratio"
          value={widthHeightRatio?.constant}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'widthHeightRatio', value: { constant: value } }])
          }
          source={widthHeightRatio?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'widthHeightRatio', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingField
          subLabel="Alignment"
          value={componentAlignment}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Alignments"
              value={componentAlignment}
              onChange={value => onComponentPropertiesChange([{ propName: 'componentAlignment', value }])}
              values={alignmentValues}
              close={() => {}}
            />
          }
        />
        <BooleanInput
          subLabel="Visible"
          value={visible?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'visible', value: { constant: value } }])}
          source={visible?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'visible', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        {moreSettings}
      </SubWrapper>
      {!hideLocalVariables && (
        <LocalVariables
          childrenFor={childrenFor}
          component={component}
          onChange={value => onComponentPropertiesChange([{ propName: 'localVariables', value }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )}
      {!hideActions && (
        <Actions
          childrenFor={childrenFor}
          component={component}
          onChange={value => onComponentPropertiesChange([{ propName: 'actions', value }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )}
    </>
  )
}
