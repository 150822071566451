import React from 'react'

import { Button, CustomSection, CustomSectionSubTitle, CustomSectionTitle, Status } from 'components'

interface Props {
  description?: string
}

export const DeploymentStatus: React.FC<Props> = ({ description }) => (
  <CustomSection style={{ paddingBottom: '25px' }}>
    <CustomSectionTitle mb="10px">
      Deployment status
      <Status status="NOT DEPLOYED" />
    </CustomSectionTitle>
    <CustomSectionSubTitle pb="20px">{description || 'Anyone with the link can join'}</CustomSectionSubTitle>
    <Button>Deploy</Button>
  </CustomSection>
)
