import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './RepositoriesList.styled'

import { ProjectContext } from 'context'
import { useApplications } from 'hooks'
import { ApplicationItem } from '../ApplicationItem'

interface Props {
  hidePreview?: boolean
}

export const RepositoriesList: React.FC<Props> = ({ hidePreview }) => {
  const { id } = useParams()
  const { applications } = useApplications(id as string)
  const {
    state: {
      resources = [],
      branding: {
        icons: { iosIcon, androidIcon },
      },
    },
  } = useContext(ProjectContext)
  const iosIconUrl = resources.find(el => el.id === iosIcon)?.url || ''
  const androidIconUrl = resources.find(el => el.id === androidIcon)?.url || ''

  return (
    <styled.AppsList>
      {applications.map(el => (
        <ApplicationItem
          key={el.id}
          application={el}
          iosIconUrl={iosIconUrl}
          androidIconUrl={androidIconUrl}
          hidePreview={hidePreview}
        />
      ))}
    </styled.AppsList>
  )
}
