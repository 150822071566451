import React, { memo, useContext, useEffect, useState } from 'react'

import { Button, ButtonVariant, Icon, Name } from 'components'
import { TopPanelContext } from 'context'
import { Actions } from '../components'
import { AddMedia } from './components'

export const TopPanelStudio: React.FC = memo(() => {
  const { setLeftButtons } = useContext(TopPanelContext)
  const [openMedia, setOpenMedia] = useState(false)
  const [openActions, setOpenActions] = useState(false)

  useEffect(() => {
    setLeftButtons(
      <>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_POINTER} />}
          active={!openMedia && !openActions}
        >
          Select
        </Button>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_ADD} />}
          active={openMedia}
          onClick={() => setOpenMedia(open => !open)}
        >
          Add
        </Button>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_ACTIONS} />}
          active={openActions}
          onClick={() => setOpenActions(open => !open)}
        >
          Actions
        </Button>
        {openMedia && <AddMedia close={() => setOpenMedia(false)} />}
        {openActions && <Actions close={() => setOpenActions(false)} />}
      </>
    )
    return () => setLeftButtons(null)
  }, [openMedia, openActions])

  return <></>
})
