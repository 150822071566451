import React from 'react'

import * as styled from '../../../Settings.styled'
import { Connected } from './index.styled'

import { ConnectGooglePlayButton, googlePlayDescription, GooglePlayLink, Icon, Name, Unlink } from 'components'
import { Integration, IntegrationGooglePlay, IntegrationStatus } from 'hooks'

interface Props {
  integration: Integration
}

export const GooglePlay: React.FC<Props> = ({ integration }) => (
  <styled.Block>
    <styled.Title>Google Play Integration</styled.Title>
    <styled.Paragraph mb="25px">{googlePlayDescription}</styled.Paragraph>
    {integration.status === IntegrationStatus.READY ? (
      <>
        <styled.Paragraph mb="10px" grey1>
          Connected to:
        </styled.Paragraph>
        <Connected>
          <GooglePlayLink>
            <Icon name={Name.SIZE_24_PLAY_STORE} />
            {(integration.data as IntegrationGooglePlay | undefined)?.teamName}
          </GooglePlayLink>
          <Unlink integration={integration} />
        </Connected>
      </>
    ) : (
      <ConnectGooglePlayButton />
    )}
  </styled.Block>
)
