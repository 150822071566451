import styled from 'styled-components'

export const TheadController = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 12px;
  & > svg {
    cursor: pointer;
    path {
      transition: 300ms ease-out;
      fill: ${props => props.theme.colors.grey1};
    }
  }
  &:hover > svg path {
    fill: ${props => props.theme.colors.iconHover};
  }
`

export const Items = styled.div<{ borderTop?: boolean }>`
  border-top: ${props => props.borderTop && `1px solid ${props.theme.colors.borderColor}`};
  padding: 10px 9px;
  & > div {
    margin: 0;
    font-weight: 400;
    & > div > div {
      cursor: default;
    }
  }
`

export const PropertyMove = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & svg {
    pointer-events: none;
    & path {
      transition: 300ms ease-out;
    }
  }
  &:hover svg path {
    fill: ${props => props.theme.colors.iconHover};
  }
`
