import React from 'react'

import * as styled from './Buttons.styled'

import { Button, ButtonColor, Icon, Name } from 'components'
import { Button as IButton } from 'services/conversations'

interface Props {
  messageButtons: IButton[]
  useDisabled: boolean
  use?: () => void
}

export const Buttons: React.FC<Props> = ({ messageButtons, useDisabled, use }) => (
  <styled.Buttons>
    {messageButtons.map(el =>
      el.action === 'regenerate' ? (
        <Button key={el.action} color={ButtonColor.TAG} leftIcon={<Icon name={Name.ADDITIONAL_AI_REGENERATE} />} />
      ) : el.action === 'use' ? (
        <Button
          key={el.action}
          disabled={useDisabled}
          onClick={use}
          leftIcon={<Icon name={Name.ADDITIONAL_AI_USE} />}
        />
      ) : (
        <Button key={el.action}>{el.action}</Button>
      )
    )}
  </styled.Buttons>
)
