import styled, { css } from 'styled-components'
import { ThemeType } from 'theme'

export const Container = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.secondaryBackground};
`

export const Content = styled.div`
  padding: 0 40px;
`

export const Header = styled.div`
  padding: 0 40px;
  max-width: 1360px;
  width: 100%;
  margin: 30px auto 10px;
  display: flex;
  justify-content: space-between;
  & a {
    text-decoration: none;
  }
`

export const Left = styled.div`
  display: flex;
  align-items: center;
`

export const AppName = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  gap: 10px;
  & button {
    gap: 0;
    padding: 0 6px;
  }
  & h1 {
    transition: ${props => props.theme.transition.primary};
    color: ${props => props.theme.colors.primaryText2};
    ${props =>
      props.theme.themeType === ThemeType.LIGHT &&
      css`
        &:hover {
          color: ${props.theme.colors.primaryTextHover};
        }
      `}
  }
`
