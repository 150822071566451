import styled from 'styled-components'

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  & svg {
    cursor: pointer;
    & circle {
      transition: 300ms ease-out;
    }
    &:hover circle {
      fill: ${props => props.theme.colors.iconHover};
    }
  }
`
