import React from 'react'
import { ISvg, Svg } from '../Svg'

const Coordinate: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.5 15C8.5 15 14 10.1304 14 6.47826C14 3.4527 11.5376 1 8.5 1C5.46243 1 3 3.4527 3 6.47826C3 10.1304 8.5 15 8.5 15Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path
      d="M10.2572 6.25011C10.2572 7.21661 9.47056 8.00011 8.50022 8.00011C7.52989 8.00011 6.74328 7.21661 6.74328 6.25011C6.74328 5.28361 7.52989 4.50011 8.50022 4.50011C9.47056 4.50011 10.2572 5.28361 10.2572 6.25011Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
  </Svg>
)

export default Coordinate
