import React from 'react'

import * as styled from './LocalVariables.styled'

import { Icon, LocalVariablePicker, Name, Rotation } from 'components'
import { SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { getVariableName, LocalVariable, Screen, ScreenComponent } from 'utils'

interface Props {
  childrenFor: string
  component?: ScreenComponent
  onChange: (val: LocalVariable[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const LocalVariables: React.FC<Props> = ({
  childrenFor,
  component,
  onChange,
  screenConfig,
  allLocalVariables,
}) => {
  const localVariables = (component ? component.localVariables : screenConfig.localVariables) || []

  const onMove = (index: number, start: boolean) => {
    const localVariablesCopy = JSON.parse(JSON.stringify(localVariables)) as LocalVariable[]
    let localVariable: LocalVariable | null = null
    if (start && index !== 0) {
      localVariable = localVariablesCopy[index - 1]
      localVariablesCopy[index - 1] = localVariablesCopy[index]
      localVariablesCopy[index] = localVariable
    } else if (!start && index !== localVariablesCopy.length - 1) {
      localVariable = localVariablesCopy[index + 1]
      localVariablesCopy[index + 1] = localVariablesCopy[index]
      localVariablesCopy[index] = localVariable
    }
    if (localVariable) {
      onChange(localVariablesCopy)
    }
  }

  const onLocalVariableChange = (index: number, value: LocalVariable) => {
    const localVariablesCopy = JSON.parse(JSON.stringify(localVariables)) as LocalVariable[]
    localVariablesCopy.splice(index, 1, value)
    onChange(localVariablesCopy)
  }

  const onRemove = (index: number) => {
    const localVariablesCopy = JSON.parse(JSON.stringify(localVariables)) as LocalVariable[]
    localVariablesCopy.splice(index, 1)
    onChange(localVariablesCopy)
  }

  return (
    <SubWrapper
      title="Local variables"
      canHide={!!localVariables.length}
      childrenFor={childrenFor}
      onAddContainer={
        <LocalVariablePicker
          value={{} as LocalVariable}
          onChange={value => onChange([...localVariables, value])}
          close={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      }
    >
      <styled.LocalVariables>
        {localVariables.map((el, i) => (
          <SettingField
            key={JSON.stringify(el)}
            noRadius
            value={el.name}
            valueDescription={
              el.variable?.source
                ? getVariableName(el.variable.source)
                : el.collection
                ? `${el.type} from ${el.collection}`
                : el.type
            }
            actions={
              <>
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} rotation={Rotation.LEFT} onClick={() => onMove(i, false)} />
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} onClick={() => onMove(i, true)} />
                <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />
              </>
            }
            optionsContainer={
              <LocalVariablePicker
                value={el}
                onChange={value => onLocalVariableChange(i, value)}
                onRemove={() => onRemove(i)}
                close={() => {}}
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            }
          />
        ))}
      </styled.LocalVariables>
    </SubWrapper>
  )
}
