import styled from 'styled-components'

export const Items = styled.div<{ referenceMode: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: ${props => (props.referenceMode ? 'column' : 'row')};
  height: 500px;
  flex-wrap: ${props => !props.referenceMode && 'wrap'};
  overflow-y: auto;
  padding: 0 10px 10px;
`
