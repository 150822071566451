import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Block, Title } from '../../Settings.styled'
import * as styled from './AppInfo.styled'

import { Icon, Name } from 'components'
import { ApplicationContext } from 'context'

export const AppInfo: React.FC = () => {
  const { id } = useParams()
  const { application } = useContext(ApplicationContext)

  return (
    <Block>
      <Title>App Information</Title>
      <styled.AppInfo>
        <div>
          <b>Name</b>
          <span>{application?.name}</span>
        </div>
        <div>
          <b>Firebase Project</b>
          <a href={`https://console.firebase.google.com/project/${id}`} target="_blank" rel="noopener noreferrer">
            {id}
            <Icon name={Name.REPOSITORY_LINK} />
          </a>
        </div>
        <div>
          <b>Platform</b>
          <span>{application?.platform}</span>
        </div>
        <div>
          <b>Language</b>
          <span>{application?.language}</span>
        </div>
        <div>
          <b>Framework</b>
          <span>{application?.type}</span>
        </div>
      </styled.AppInfo>
    </Block>
  )
}
