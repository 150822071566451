import styled from 'styled-components'

export const Item = styled.div<{ threePreviews?: boolean }>`
  position: relative;
  & > p {
    margin-bottom: 5px;
    margin-left: 7px;
    color: ${props => props.theme.colors.grey4};
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
  }
  & > div {
    padding: ${props => (props.threePreviews ? '10px 0px 10px 10px' : '10px')};
    overflow: hidden;
    background: ${props => props.theme.colors.settingField};
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      display: flex;
      overflow: hidden;
      column-gap: 10px;
      & > img {
        height: 233px;
      }
    }
  }
  & > span {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: block;
    padding: 5px 10px;
    border-radius: 100px;
    background: ${props => props.theme.colors.grey1};
    color: ${props => props.theme.colors.white};
    font-size: 12px;
  }
  &:hover > div {
    background: ${props => props.theme.colors.settingFieldHover};
  }
`
