import React from 'react'
import { ISvg, Svg } from '../Svg'

const EditFields: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <path
      d="M8.36227 5.65343C8.50522 5.11996 9.05355 4.80338 9.58702 4.94632L11.5189 5.46396C12.0523 5.6069 12.3689 6.15524 12.226 6.6887L10.0038 14.9819C9.93517 15.2381 9.76757 15.4565 9.53789 15.5891L7.88013 16.5462C7.64098 16.6843 7.33519 16.6024 7.19712 16.3632L6.24001 14.7055C6.1074 14.4758 6.07147 14.2028 6.14011 13.9467L8.36227 5.65343Z"
      stroke="#F2F2F3"
      strokeWidth="1.5"
    />
    <path d="M13 16L17 16" stroke="#F2F2F3" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default EditFields
