import styled from 'styled-components'

export const UsageItem = styled.div<{ color?: string }>`
  font-size: 14px;
  line-height: 21px;
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  color: ${props => props.theme.colors.darkText};
  & b {
    display: inline-block;
    font-weight: 600;
    width: 175px;
  }
  & span {
    display: inline-block;
    width: 142px;
  }
  & progress {
    width: 343px;
    height: 14px;
    appearance: none;

    &::-webkit-progress-bar {
      border-radius: 100px;
      background-color: ${props => props.theme.colors.settingField};
    }
    &::-webkit-progress-value {
      background-color: ${props => props.theme.colors.blue};
      border-radius: 100px;
    }
    &::-moz-progress-bar {
      border-radius: 100px;
      background-color: ${props => props.theme.colors.settingField};
    }
  }
`

export const Hint = styled.div`
  margin-top: 25px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.darkText1};
`
