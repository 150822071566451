import React from 'react'
import { ISvg, Svg } from '../Svg'

const TabBar: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="0.5" y="3.5" width="14" height="8" rx="1.03846" stroke="#7F899E" />
    <rect x="2" y="6" width="3" height="3" rx="0.538462" fill="#7F899E" />
    <rect x="6" y="6" width="3" height="3" rx="0.538462" fill="#7F899E" />
    <rect x="10" y="6" width="3" height="3" rx="0.538462" fill="#7F899E" />
  </Svg>
)

export default TabBar
