import styled from 'styled-components'

export const SelectBox = styled.div<{ activeCategory: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  row-gap: 8px;
  margin-top: 46.5px;
  & > div {
    &:nth-child(${props => props.activeCategory}) {
      & > div:first-child {
        background-color: ${props => props.theme.colors.black_50}!important;
        & > span {
          color: ${props => props.theme.colors.darkText}!important;
        }
        & > svg path {
          fill: ${props => props.theme.colors.darkText}!important;
        }
      }
    }
  }
  & > div:not(:first-child) {
    padding-bottom: 10px;
    margin-bottom: -10px;
    & > div:first-child {
      height: 36px;
      padding: 0 10px 0 15px;
      column-gap: 5px;
      background-color: transparent;
      & > span {
        font-weight: ${props => props.theme.weights.medium};
        color: ${props => props.theme.colors.grey1};
      }
      & > svg path {
        transition: all 0.3s ease-out 0s;
        fill: ${props => props.theme.colors.grey1};
      }
    }
    &:hover {
      & > div:first-child {
        background-color: ${props => props.theme.colors.black_50};
        & > span {
          color: ${props => props.theme.colors.darkText};
        }
        & > svg path {
          fill: ${props => props.theme.colors.darkText};
        }
      }
    }
  }
`

export const SelectAll = styled.div<{ active: boolean }>`
  height: 36px;
  padding: 0 15px;
  column-gap: 5px;
  background-color: ${props => (props.active ? props.theme.colors.black_50 : 'transparent')};
  font-size: 14px;
  line-height: 100%;
  font-weight: ${props => props.theme.weights.medium};
  color: ${props => (props.active ? props.theme.colors.darkText : props.theme.colors.grey1)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  &:hover {
    background-color: ${props => props.theme.colors.black_50};
    color: ${props => props.theme.colors.darkText};
  }
`
