import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Modal } from 'components'
import { GithubParamsName } from 'pages'
import { Integration, IntegrationStatus, connectGithubFlag } from 'hooks'
import { ConnectGitRepository, GitRepository, Ready } from './components'

interface Props {
  github: Integration
  name?: string
  active: boolean
}

export const GithubConnectionModal: React.FC<Props> = ({ github, name, active }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [stateParam, setStateParam] = useState(0)
  const [githubToken, setGithubToken] = useState('')

  useEffect(() => {
    if (active) {
      const stateParam = Math.random()
      setStateParam(stateParam)
      localStorage.setItem(GithubParamsName.STATE_PARAM, String(stateParam))
    }
  }, [active])

  const close = () => {
    searchParams.delete(connectGithubFlag)
    setSearchParams(searchParams)
  }

  return (
    <Modal active={active} onClose={close} cancelButton={false} width="640px">
      {github.status === IntegrationStatus.READY ? (
        <Ready />
      ) : githubToken ? (
        <ConnectGitRepository close={close} stateParam={stateParam} name={name} githubToken={githubToken} />
      ) : (
        <GitRepository close={close} stateParam={stateParam} setGithubToken={setGithubToken} />
      )}
    </Modal>
  )
}
