import React from 'react'
import { ISvg, Svg } from '../../Svg'

export const AppStore: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M9.78287 1.16358C10.0542 1.3202 10.2483 1.5732 10.3294 1.87584C10.4105 2.17848 10.3689 2.49463 10.2122 2.76597L6.18945 9.7336H8.72687L10.0814 12.0796H1.17302C0.526215 12.0796 0 11.5534 0 10.9066C0 10.2598 0.526215 9.7336 1.17302 9.7336H3.48048L6.64547 4.25162L5.78771 2.76592C5.46434 2.20574 5.65695 1.48696 6.21708 1.16353C6.77722 0.840203 7.496 1.03281 7.81943 1.59291L7.99998 1.90563L8.18053 1.59295C8.504 1.03272 9.22282 0.840203 9.78287 1.16358Z"
      fill="#7F899E"
    />
    <path
      d="M12.5195 9.73358H14.827C15.4738 9.73358 16 10.2598 16 10.9066C16 11.5534 15.4738 12.0796 14.827 12.0796H13.874L14.6465 13.4176C14.97 13.9777 14.7774 14.6965 14.2172 15.0199C14.0365 15.1243 13.8358 15.1776 13.6326 15.1776C13.5307 15.1776 13.4281 15.1642 13.3271 15.1372C13.0243 15.056 12.7714 14.8619 12.6148 14.5905L8.54186 7.5361L9.89632 5.19006L12.5195 9.73358Z"
      fill="#7F899E"
    />
    <path
      d="M1.35357 13.4176L1.58428 13.018H4.29324L3.38533 14.5906C3.16827 14.9665 2.77315 15.1768 2.36748 15.1768C2.16221 15.1769 1.96057 15.1228 1.78294 15.0199C1.2228 14.6966 1.03019 13.9777 1.35357 13.4176Z"
      fill="#7F899E"
    />
  </Svg>
)
