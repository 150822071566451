import React from 'react'
import { ISvg, Svg } from '../Svg'

const Field: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M12 24H3.1467C2.5134 24 2 23.4866 2 22.8533V9.1467C2 8.5134 2.5134 8 3.1467 8H12"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20 24H28.8533C29.4866 24 30 23.4866 30 22.8533V9.1467C30 8.5134 29.4866 8 28.8533 8H20"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M16 5L16 27" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14 5L18 5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14 27L18 27" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M5.64262 20C5.97541 20 6.16475 19.8292 6.28525 19.4325L6.91066 17.541H10.0779L10.709 19.4386C10.8238 19.8292 11.0131 20 11.3574 20C11.7303 20 12 19.7376 12 19.3776C12 19.2617 11.977 19.1458 11.9197 18.9749L9.39508 11.7078C9.22295 11.2197 8.94754 11 8.5 11C8.04672 11 7.76557 11.2258 7.59918 11.7139L5.08033 18.9749C5.02295 19.1397 5 19.2739 5 19.3776C5 19.7498 5.2582 20 5.64262 20ZM7.2377 16.4244L8.47131 12.562H8.50574L9.75082 16.4244H7.2377Z"
      fill="#7F899E"
    />
  </Svg>
)

export default Field
