import React from 'react'
import { ISvg, Svg } from '../Svg'

const Tablet: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 16" fill="none" {...props}>
    <rect x="2.75" y="1.75" width="14.5" height="12.5" rx="1.25" stroke="#7F899E" strokeWidth="1.5" />
    <path d="M7 4H13" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default Tablet
