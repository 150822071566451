import styled from 'styled-components'

export const ItemBox = styled.a`
  display: flex;
  padding: 10px;
  border-radius: 16px;
  transition: ${props => props.theme.transition.primary};

  &:hover {
    background-color: ${props => props.theme.colors.hoverWhite};
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    padding: 0px;
    border-radius: 8px;
    &:hover {
      background-color: transparent;
    }
  }
`

export const IconBox = styled.div<{
  $stroke: boolean
  $visualAppBuilder?: boolean
}>`
  width: 36px;
  height: 36px;
  border: 1px solid ${props => props.theme.colors.grayText};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    & * {
      ${props => !props.$stroke && `fill: ${props.theme.colors.secondaryBlack}!important;`}
      ${props => props.$stroke && `stroke: ${props.theme.colors.secondaryBlack}!important;`}
    }
  }
  & path:last-child {
    ${props =>
      props.$visualAppBuilder && `fill: ${props.theme.colors.secondaryBlack}!important; stroke: none!important;`}
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    & svg {
      & * {
        ${props => !props.$stroke && `fill: ${props.theme.colors.offWhite1}!important;`}
        ${props => props.$stroke && `stroke: ${props.theme.colors.offWhite1}!important;`}
      }
    }
    & path:last-child {
      ${props => props.$visualAppBuilder && `fill: ${props.theme.colors.offWhite1}!important; stroke: none!important;`}
    }
  }
`

export const TitleBox = styled.div`
  margin-left: 12px;
  max-width: 218px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    margin-left: 10px;
    max-width: none;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 10px;
  }
`

export const Title = styled.h3`
  font-size: 14px;
  line-height: 17px;

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 14px;
    line-height: 19px;
    line-height: 145%;
    font-weight: ${props => props.theme.weights.regular};
  }
`

export const Description = styled.p`
  margin-top: 3.5px;
  font-weight: ${props => props.theme.weights.regular};
  font-size: 14px;
  line-height: 145%;
  color: ${props => props.theme.colors.grayText};

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: none;
  }
`
