import React from 'react'
import { ISvg, Svg } from '../Svg'

const Conversations: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="1.5" width="3" height="3" rx="1.5" stroke="#7F899E" />
    <rect x="6.5" y="1.5" width="8" height="3" rx="1.05" stroke="#7F899E" />
    <path d="M8 3H11" stroke="#7F899E" strokeLinecap="round" />
    <path d="M8 13H11" stroke="#7F899E" strokeLinecap="round" />
    <path d="M8 8H9" stroke="#7F899E" strokeLinecap="round" />
    <rect x="1.5" y="6.5" width="3" height="3" rx="1.5" stroke="#7F899E" />
    <rect x="6.5" y="6.5" width="8" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="1.5" y="11.5" width="3" height="3" rx="1.5" stroke="#7F899E" />
    <rect x="6.5" y="11.5" width="8" height="3" rx="1.05" stroke="#7F899E" />
  </Svg>
)

export default Conversations
