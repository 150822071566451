import React from 'react'
import { ISvg, Svg } from '../Svg'

const Collection: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="1.5" y="1.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="1.5" y="6.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="1.5" y="11.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="6.5" y="1.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="6.5" y="6.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="6.5" y="11.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="11.5" y="1.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="11.5" y="6.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
    <rect x="11.5" y="11.5" width="3" height="3" rx="1.05" stroke="#7F899E" />
  </Svg>
)

export default Collection
