import React from 'react'

import { CustomField, Variable } from 'components'
import { getVariableName, LocalVariable, Screen, VariableSource } from 'utils'

interface Props {
  label: string
  source?: VariableSource
  onSourceChange: (val?: VariableSource) => void
  setSubPicker: (
    value: React.SetStateAction<React.ReactElement<any, string | React.JSXElementConstructor<any>> | null>
  ) => void
  dataMode?: boolean
  collectionMode?: boolean
  recordMode?: boolean
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const VariableField: React.FC<Props> = ({
  label,
  source,
  onSourceChange,
  setSubPicker,
  dataMode,
  collectionMode,
  recordMode,
  screenConfig,
  allLocalVariables,
}) => (
  <CustomField
    label={label}
    value={source ? getVariableName(source) : undefined}
    onClick={() =>
      setSubPicker(
        <Variable
          value={source}
          onChange={onSourceChange}
          onRemove={() => onSourceChange()}
          close={() => setSubPicker(null)}
          dataMode={dataMode}
          collectionMode={collectionMode}
          recordMode={recordMode}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      )
    }
  />
)
