import styled from 'styled-components'

export const LinksWrapper = styled.div`
  margin: 20px 0 30px;
  display: flex;
  gap: 15px;
  & > button {
    width: 190px;
    height: 60px;
    border-radius: 12px;
    & > svg:first-child {
      width: 32px;
      height: 32px;
    }
    &:hover {
      background-color: ${props => props.theme.colors.settingFieldHover};
    }
  }
`
