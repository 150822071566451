import { Box } from '@mui/material'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import ReactRouterPrompt from 'react-router-prompt'
import { ThemeContext } from 'styled-components'

import * as styled from './RightForm.styled'

import { Confirm, IPickerItem, getField } from 'components'
import { ResourceTypes } from 'services/cms'
import { CollectionProperty, DataObject, ValueType, firstLetterUpperCase } from 'utils'

export interface InputProperty extends CollectionProperty {
  title?: string
  optionsToSelect?: IPickerItem[]
  smallWidth?: boolean
  ai?: boolean
  editByModal?: boolean
  disabled?: boolean
  logo?: boolean
  hideLabel?: boolean
  onClick?: () => void
  onRun?: () => void
}

export const validate = (values: any, properties: InputProperty[], dataObjects?: DataObject[]) => {
  const errors: { [key: string]: string } = {}
  Object.keys(values)
    .map(key => properties.find(property => property.name === key))
    .filter(el => !!el)
    .forEach(p => {
      // here will be all validations
      const property = p as InputProperty
      const value = values[property.name]
      if (value !== null) {
        const typeErrorMessage = getField(property.type)?.geTypeErrorMessage?.(value)
        if (typeErrorMessage) {
          errors[property.name] = typeErrorMessage
        }
        if (property.type === ValueType.array) {
          const innerErrors = value
            .map((el: any) => !!getField(property.accept as ValueType)?.geTypeErrorMessage?.(el))
            .filter((el: boolean) => !!el)
          if (innerErrors.length) {
            errors[property.name] = `All items must be a ${property.accept} type!`
          }
        } else if (property.type === ValueType.coordinate) {
          const fields = Object.keys(value)
          const needFields = ['latitude', 'longitude']
          const missingFields = needFields.filter(el => !fields.includes(el))
          const extraFields = fields.filter(el => !needFields.includes(el))
          if (missingFields.length) {
            errors[property.name] = `Missing fields: ${missingFields.join(', ')}!`
          } else if (extraFields.length) {
            errors[property.name] = `Extra fields: ${extraFields.join(', ')}!`
          }
        } else if (property.type === ValueType.keyValueMap && dataObjects) {
          const fields = Object.keys(value)
          const properties = dataObjects.find(el => el.name === property.dataObject)?.properties
          if (properties) {
            const needFields = properties.map(el => el.name)
            const missingFields = needFields.filter(el => !fields.includes(el))
            const extraFields = fields.filter(el => !needFields.includes(el))
            if (missingFields.length) {
              errors[property.name] = `Missing fields: ${missingFields.join(', ')}!`
            } else if (extraFields.length) {
              errors[property.name] = `Extra fields: ${extraFields.join(', ')}!`
            } else {
              const innerErrors = validate(value, properties, dataObjects)
              if (Object.keys(innerErrors).length) {
                errors[property.name] = Object.keys(innerErrors)
                  .map(el => `Field "${el}": ${innerErrors[el]}`)
                  .join(' ')
              }
            }
          }
        }
      } else if (property.isRequired) {
        errors[property.name] = 'Required!'
      }
      if (property.acceptValues && typeof value === 'string' && !property.acceptValues.includes(value)) {
        errors[property.name] = `Accepted values: ${property.acceptValues.join(', ')}!`
      }
    })
  return errors
}

export const inputs = (properties: InputProperty[], formik: any, resourceType?: ResourceTypes) =>
  properties
    .filter(el => !el.isHidden)
    .sort((a, b) => a.position - b.position)
    .map(
      ({
        name,
        type,
        title,
        isRequired,
        helpText,
        acceptValues,
        optionsToSelect,
        accept,
        collection,
        smallWidth,
        ai,
        editByModal,
        disabled,
        logo,
        hideLabel,
        onClick,
        onRun,
      }) => {
        const error = formik.touched[name] && !!formik.errors[name]
        return (
          <div key={name}>
            {getField(type)?.component({
              name,
              label: firstLetterUpperCase(title || name) + (isRequired ? ' (required)' : ''),
              placeholder: `${
                (type === ValueType.accessLevel || type === ValueType.color || type === ValueType.string) &&
                (acceptValues || optionsToSelect)
                  ? 'Select'
                  : 'Enter'
              } ${title || name}`,
              value: formik.values[name],
              onChange: val => formik.setFieldValue(name, val),
              helperText: error ? (formik.errors[name] as string) : '',
              helpText,
              optionsToSelect: acceptValues?.map(el => ({ value: el, label: el })) || optionsToSelect,
              error,
              accept,
              collection,
              smallWidth,
              ai,
              editByModal,
              disabled,
              logo,
              hideLabel,
              resourceType,
              onClick,
              onRun,
            })}
          </div>
        )
      }
    )

interface Props {
  title: ReactElement
  form: ReactElement
  showConfirmWhen: boolean
  onClose: () => void
  wide?: boolean
}

export const RightForm: React.FC<Props> = ({ title, form, showConfirmWhen, onClose, wide }) => {
  const rightFormId = 'rightForm'
  const themeContext = useContext(ThemeContext)
  const [order] = useState(+new Date())

  useEffect(() => {
    const rightForms = Array.from(document.querySelectorAll(`[id^=${rightFormId}]`)).sort(
      (a, b) => +b.id.replace(rightFormId, '') - +a.id.replace(rightFormId, '')
    ) as HTMLDivElement[]
    rightForms.forEach((el, i) => (el.style.left = `-${(wide ? 366 : 50) * i}px`))
    return () => {
      rightForms.forEach((el, i) => (el.style.left = `-${(wide ? 366 : 50) * (i - 1)}px`))
    }
  }, [])

  return (
    <Box
      id={rightFormId + order}
      position="fixed"
      height="100vh"
      width="100vw"
      left="0"
      top="0"
      zIndex="10"
      display="flex"
      justifyContent="flex-end"
      bgcolor={themeContext.colors.overlayBg}
      onClick={onClose}
    >
      <ReactRouterPrompt when={showConfirmWhen}>
        {({ isActive, onConfirm, onCancel }) => <Confirm active={isActive} onConfirm={onConfirm} onCancel={onCancel} />}
      </ReactRouterPrompt>
      <styled.Container onClick={e => e.stopPropagation()} wide={wide}>
        <styled.TitleWrapper>{title}</styled.TitleWrapper>
        {form}
      </styled.Container>
    </Box>
  )
}
