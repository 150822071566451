import React from 'react'
import { ISvg, Svg } from '../Svg'

const DefaultColor: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <g clipPath="url(#clip0_15481_39657)">
      <rect width="22" height="22" rx="4" fill="#9A9A9A" fillOpacity="0.15" />
      <rect width="11" height="11" fill="#9A9A9A" fillOpacity="0.15" />
      <rect x="11" y="11" width="11" height="11" fill="#9A9A9A" fillOpacity="0.15" />
    </g>
    <defs>
      <clipPath id="clip0_15481_39657">
        <rect width="22" height="22" rx="4" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default DefaultColor
