import { Box } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from './AddScreens.styled'

import { ProjectContext } from 'context'
import { useScreensToAdd } from 'hooks'
import { includesSearch } from 'utils'

interface Props {
  close: () => void
  search: string
}

export const AddScreens: React.FC<Props> = ({ close, search }) => {
  const { setState } = useContext(ProjectContext)
  const { screens, addScreens } = useScreensToAdd()

  const onClick = (id: string) => addScreens(id, setState, close)

  return (
    <Box display="flex" flexDirection="column" gap="15px" p="0 8px 8px" mt="7px" overflow="auto" height="352px">
      {screens
        .filter(el => includesSearch(el.name, search))
        .map(el => (
          <styled.Item key={el.id} onClick={() => onClick(el.id)} threePreviews={el.thumbnailUrls.length >= 3}>
            <p>{el.name}</p>
            <div>
              <div>
                {el.thumbnailUrls.map(src => (
                  <img key={src} src={src} alt={el.name} />
                ))}
              </div>
            </div>
            {el.count > 2 && <span>{el.count} screens</span>}
          </styled.Item>
        ))}
    </Box>
  )
}
