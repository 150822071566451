import styled from 'styled-components'
import { FileInput } from '../FileInput/FileInput.styled'

export const RefFieldInput = styled(FileInput)`
  & span {
    position: relative;
    & > div {
      font-weight: 400;
      cursor: auto;
      position: absolute;
      top: 27px;
      width: 200px;
      background: ${props => props.theme.colors.selectBackground};
      border: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
      border-radius: 8px;
      font-size: 14px;
      z-index: 2;
      padding: 6px 0;
      text-transform: none;
      & div {
        padding: 10px 15px;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: ${props => props.theme.colors.settingField};
        }
      }
    }
  }
`
