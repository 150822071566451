import React, { cloneElement, ReactElement, useEffect, useRef, useState } from 'react'

import * as styled from './SettingField.styled'

import { useOnClickOutside, useOptionsContainerPosition } from 'hooks'

export const openStylePickerEvent = 'openStylePickerEvent'

interface Props {
  big?: boolean
  small?: boolean
  label?: string
  subLabel?: string
  leftIcon?: ReactElement
  value?: string
  valueDescription?: string
  actions?: ReactElement
  optionsContainer?: ReactElement
  transparent?: boolean
  active?: boolean
  onClick?: () => void
  onDateChange?: (val: string) => void
  disabled?: boolean
  canOpenStylePickerFromScreen?: boolean
  brandingFontInput?: boolean
  noRadius?: boolean
  insidePicker?: boolean
}

export const SettingField: React.FC<Props> = ({
  big,
  small,
  label,
  subLabel,
  leftIcon,
  value,
  valueDescription,
  actions,
  optionsContainer,
  transparent,
  active,
  onClick,
  onDateChange,
  disabled,
  canOpenStylePickerFromScreen,
  brandingFontInput,
  noRadius,
  insidePicker,
}) => {
  const [open, setOpen] = useState(false)
  const [openStylePickerFromScreen, setOpenStylePickerFromScreen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const close = () => {
    setOpen(false)
    setOpenStylePickerFromScreen(false)
  }
  useOnClickOutside(ref, close)
  const [top, width, left] = useOptionsContainerPosition(ref, open, insidePicker, openStylePickerFromScreen)

  useEffect(() => {
    const openStylePicker = () =>
      setOpenStylePickerFromScreen(openStylePickerFromScreen => {
        if (!openStylePickerFromScreen) {
          setOpen(false)
        }
        return !openStylePickerFromScreen
      })
    if (canOpenStylePickerFromScreen) {
      document.addEventListener(openStylePickerEvent, openStylePicker)
    }
    return () => document.removeEventListener(openStylePickerEvent, openStylePicker)
  }, [canOpenStylePickerFromScreen])

  const onFieldClick = (e: any) => {
    e.preventDefault()
    if (optionsContainer) {
      setOpen(open => {
        if (!open) {
          setOpenStylePickerFromScreen(false)
        }
        return !open
      })
    }
  }

  const noFlex = !!canOpenStylePickerFromScreen && !brandingFontInput

  return (
    <styled.Container withLabel={!!label} small={!!small} disabled={disabled} big={big}>
      {label && <styled.Label>{label}</styled.Label>}
      <styled.FieldWrapper ref={ref}>
        {subLabel && <styled.SubLabel>{subLabel}</styled.SubLabel>}
        <styled.Field
          onClick={onClick || onFieldClick}
          pointer={disabled ? undefined : !!(optionsContainer || onClick || onDateChange)}
          active={open || !!active}
          leftIcon={!!leftIcon}
          actions={!!actions}
          transparent={!!transparent}
          noFlex={noFlex}
          hasValueDescription={!!valueDescription}
          noRadius={!!noRadius}
          big={big}
        >
          {leftIcon}
          {onDateChange && (
            <styled.DateInput
              onClick={e => (e.target as HTMLInputElement).showPicker()}
              type="date"
              onChange={e => onDateChange(new Date(e.target.value).toISOString())}
            />
          )}
          {!noFlex && (
            <styled.FieldValueWrapper>
              <styled.FieldValue grey1={!value} dangerouslySetInnerHTML={{ __html: value || 'Add...' }} />
              {valueDescription && <styled.FieldValueDescription>{valueDescription}</styled.FieldValueDescription>}
            </styled.FieldValueWrapper>
          )}
          {actions && <styled.Actions onClick={e => e.stopPropagation()}>{actions}</styled.Actions>}
        </styled.Field>
        {!!(optionsContainer && (open || openStylePickerFromScreen)) && (
          <styled.OptionsContainer top={top} width={width} left={left} insidePicker={insidePicker}>
            {cloneElement(optionsContainer, { close })}
          </styled.OptionsContainer>
        )}
      </styled.FieldWrapper>
    </styled.Container>
  )
}
