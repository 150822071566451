import { useFormik } from 'formik'
import ISO6391 from 'iso-639-1'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { CustomSection, CustomSectionTitle, InputProperty, inputs, validate } from 'components'
import { ProjectContext } from 'context'
import { LocalizedContent, ValueType } from 'utils'

interface Props {
  title: string
  value: LocalizedContent
  onChange: (val: LocalizedContent) => void
}

export const ElementWithLocalizedContent: React.FC<Props> = ({ title, value, onChange }) => {
  const { key, locales, values } = value
  const {
    state: {
      localization: { primaryLanguage, languages = [] },
    },
    setLanguage,
  } = useContext(ProjectContext)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: { key, ...locales } as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {
      const { key, ...locales } = data
      onChange({ key, locales, values })
    },
  })

  useEffect(() => {
    formik.setValues({ key, ...locales })
    setValuesBeforeEdit(JSON.stringify({ key, ...locales }))
  }, [key, locales])

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'key',
        type: ValueType.string,
        position: 0,
        editByModal: true,
      },
      ...languages.map(el => ({
        name: el,
        type: ValueType.string,
        position: 1,
        title: `${ISO6391.getName(el).toLowerCase()}${el === primaryLanguage ? ' (primary)' : ''}`,
        ai: true,
        onClick: () => setLanguage(el),
      })),
    ],
    [languages, primaryLanguage]
  )

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  useEffect(() => {
    if (showActions) {
      formik.submitForm()
    }
  }, [showActions])

  return (
    <CustomSection>
      <CustomSectionTitle mb="-5px">{title}</CustomSectionTitle>
      {inputs(properties, formik)}
    </CustomSection>
  )
}
