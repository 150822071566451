import React from 'react'
import { ISvg, Svg } from '../Svg'

const EditRecords: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M11.75 3H6C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H14C15.6569 17 17 15.6569 17 14V8.25"
      stroke="#212133"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M15.9502 4L9.9502 10" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default EditRecords
