import { memo } from 'react'

import * as styled from './RightIcons.styled'

import { AIIconBtn } from 'components'
import { FieldProps } from '../../types'

export const AI = memo((props: FieldProps) => (
  <styled.RightIcon
    smallWidth={props.smallWidth}
    onClick={e => {
      e.stopPropagation()
      e.preventDefault()
    }}
  >
    <AIIconBtn input />
  </styled.RightIcon>
))
