import styled from 'styled-components'

export const Container = styled.div<{ withLabel?: boolean; small?: boolean; disabled?: boolean; big?: boolean }>`
  margin-top: ${props =>
    !props.big && (props.withLabel && props.small ? '13px' : props.withLabel ? '15px' : props.small ? '8px' : '10px')};
  width: ${props => props.big && '262px'};
  cursor: ${props => props.disabled && 'not-allowed'};
  & * {
    pointer-events: ${props => props.disabled && 'none'};
  }
`

export const Label = styled.div`
  font-weight: ${props => props.theme.weights.medium};
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`

export const FieldWrapper = styled.div<{ spaceBetween?: boolean }>`
  position: relative;
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.spaceBetween && 'space-between'};
  gap: 8px;
`

export const Field = styled.div<{
  pointer?: boolean
  active: boolean
  leftIcon: boolean
  actions: boolean
  transparent: boolean
  noFlex: boolean
  hasValueDescription: boolean
  noRadius: boolean
  big?: boolean
}>`
  position: relative;
  user-select: ${props => props.pointer && 'none'};
  cursor: ${props => (props.pointer ? 'pointer' : 'auto')};
  min-height: ${props => (props.big ? 65 : 30)}px;
  width: 100%;
  display: ${props => !props.noFlex && 'flex'};
  align-items: ${props => !props.noFlex && 'center'};
  gap: ${props => (props.big ? '15px' : !props.noFlex && '8px')};
  padding: ${props =>
    props.noFlex ? '10px' : props.leftIcon ? '4px 7px 4px 4px' : props.actions ? '4px 7px 4px 10px' : '4px 10px'};
  padding-left: ${props => props.transparent && props.leftIcon && '0'};
  padding-top: ${props => props.hasValueDescription && '6px'};
  padding-bottom: ${props => props.hasValueDescription && '6px'};
  border-radius: ${props => (props.big ? '12px' : !props.noRadius && '8px')};
  overflow: hidden;
  background: ${props =>
    props.active
      ? props.theme.colors.settingFieldHover
      : props.transparent
      ? 'transparent'
      : props.theme.colors.settingField};
  transition: background 300ms ease-out;
  &:hover {
    background: ${props =>
      props.pointer
        ? props.theme.colors.settingFieldHover
        : props.transparent
        ? 'transparent'
        : props.theme.colors.settingField};
  }
  & > svg,
  & > img {
    height: ${props => (props.big ? 57 : 22)}px;
    width: ${props => props.big && '57px'};
    min-height: ${props => (props.big ? 57 : 22)}px;
    min-width: ${props => (props.big ? 57 : 22)}px;
    border-radius: ${props => (props.big ? 12 : 4)}px;
    object-fit: cover;
  }
`

export const SubLabel = styled.div<{ autoWidth?: boolean }>`
  font-size: 12px;
  line-height: 100%;
  color: ${props => props.theme.colors.darkText};
  min-width: ${props => (props.autoWidth ? 'unset' : '35%')};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const DateInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  opacity: 0;
  cursor: pointer;
`

export const FieldValueWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export const FieldValue = styled.div<{ grey1: boolean }>`
  width: 100%;
  font-size: 12px;
  line-height: 150%;
  color: ${props => (props.grey1 ? props.theme.colors.grey1 : props.theme.colors.darkText)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  & > b {
    font-weight: 500;
  }
`

export const FieldValueDescription = styled.div`
  font-size: 10px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.darkText};
  & svg {
    min-height: 16px;
    min-width: 16px;
    cursor: pointer;
    user-select: none;
    & path,
    & circle {
      transition: 300ms ease-out;
    }
    & * {
      &[fill] {
        fill: ${props => props.theme.colors.grey1};
      }
      &[stroke] {
        stroke: ${props => props.theme.colors.grey1};
      }
    }
    &:hover {
      & path {
        stroke: ${props => props.theme.colors.iconHover};
      }
      & circle {
        fill: ${props => props.theme.colors.iconHover};
      }
    }
  }
  & div {
    transition: 300ms ease-out;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.iconHover};
    }
  }
`

export const OptionsContainer = styled.div<{ width?: number; top?: number; left?: number; insidePicker?: boolean }>`
  position: ${props => (props.insidePicker ? 'absolute' : 'fixed')};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  display: ${props => !props.width && 'none'};
  z-index: 10;
  pointer-events: none;
`
