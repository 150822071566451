import React from 'react'
import { ISvg, Svg } from '../Svg'

const Overlay: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M15.8566 8.01585C15.9427 7.95559 16.0573 7.95559 16.1434 8.01585L24.3996 13.7952C24.5418 13.8947 24.5417 14.1053 24.3996 14.2048L16.1434 19.9842C16.0573 20.0444 15.9427 20.0444 15.8566 19.9842L7.60043 14.2048C7.45825 14.1053 7.45825 13.8947 7.60043 13.7952L15.8566 8.01585L15.4265 7.40142L15.8566 8.01585Z"
      stroke="#7F899E"
      strokeWidth="1.5"
    />
    <path
      d="M24 18L16.7037 23.5551C16.356 23.8198 15.8767 23.8281 15.52 23.5755L8 18.25"
      stroke="#7F899E"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Svg>
)

export default Overlay
