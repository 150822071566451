import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactTimeago from 'react-timeago'

import { PickerOverlay, PickerWrapper } from 'components/Modal/Modal.styled'

import { CommonPicker, Plan } from 'components'
import { ProgressContext } from 'context'
import { Project, useParamsFromUrl, usePlanParamsFromUrl, useProjectPlan, useWorkspacePlan, Workspace } from 'hooks'
import { hasUpdatedAtSort, updatedAtSort } from 'pages/Projects/pages'
import { auth, firestore } from 'services/firebase'

interface Props {
  workspaces: Workspace[]
}

export const PlanPicker: React.FC<Props> = ({ workspaces }) => {
  const navigate = useNavigate()
  const { toast } = useContext(ProgressContext)
  const [projects, setProjects] = useState<Project[]>([])
  const { planPriceIdName } = useParamsFromUrl()
  const { projectPlanPriceId, workspacePlanPriceId, clearParams } = usePlanParamsFromUrl()
  const [workspace, setWorkspace] = useState<Workspace | null>(null)
  const [project, setProject] = useState<Project | null>(null)
  const userId = auth.currentUser?.uid

  const {
    wait: workspacePlanWait,
    onPlanChange: onWorkspacePlanChange,
    ChangePlanAlert: ChangeWorkspacePlanAlert,
  } = useWorkspacePlan(workspace, () => setWorkspace(null), true)
  const {
    wait: projectPlanWait,
    onPlanChange: onProjectPlanChange,
    ChangePlanAlert: ChangeProjectPlanAlert,
  } = useProjectPlan(project?.workspace.id, project?.id, () => setProject(null), true)

  useEffect(() => {
    if (!workspacePlanPriceId) {
      setWorkspace(null)
    }
  }, [workspacePlanPriceId])

  useEffect(() => {
    if (!projectPlanPriceId) {
      setProject(null)
    }
  }, [projectPlanPriceId])

  useEffect(() => {
    if (workspacePlanPriceId && !workspacePlanWait && onWorkspacePlanChange) {
      onWorkspacePlanChange(workspacePlanPriceId)
    }
  }, [workspacePlanWait])

  useEffect(() => {
    if (projectPlanPriceId && !projectPlanWait && onProjectPlanChange) {
      onProjectPlanChange(projectPlanPriceId)
    }
  }, [projectPlanWait])

  useEffect(() => {
    if (projectPlanPriceId && workspaces.length) {
      const unsubscribe = firestore
        .collection('projects')
        .where(
          'workspace',
          'in',
          workspaces.map(el => firestore.doc(`workspaces/${el.id}`))
        )
        .where('memberIds', 'array-contains', userId)
        .onSnapshot({
          next: res => {
            const projects = res.docs.map(el => ({ id: el.id, ...el.data() })) as Project[]
            if (projects.length) {
              setProjects(projects.sort(hasUpdatedAtSort).sort((a, b) => -updatedAtSort(a, b)))
            } else {
              navigate(`/ai?intent=start-building&${planPriceIdName}=${projectPlanPriceId}`)
            }
          },
          error: err => toast(err),
        })
      return () => {
        unsubscribe()
      }
    }
  }, [projectPlanPriceId, workspaces])

  const projectsValues = useMemo(
    () =>
      projects.map(project => ({
        value: project.id,
        label: project.name,
        bigIcon: <img src={project.logoUrl} alt="logo" />,
        description: (
          <>
            Edited{' '}
            {project.updatedAt?.seconds ? <ReactTimeago date={new Date(project.updatedAt.seconds * 1000)} /> : '-'}
          </>
        ),
        itemsCategory: workspaces.find(workspace => project.workspace.id === workspace.id)?.name,
        labelRight: (
          <Plan to={`/workspaces/${project.workspace.id}/billing/projects/${project.id}/plans`} plan={project.plan}>
            {project.plan}
          </Plan>
        ),
      })),
    [projects, workspaces]
  )

  const workspacesValues = useMemo(
    () =>
      workspaces.map(workspace => ({
        value: workspace.id,
        label: workspace.name,
        bigIcon: <div>{workspace.name[0]}</div>,
        description: <>Teammates: {workspace.memberIds.length}</>,
        itemsCategory: 'Pick a workspace',
      })),
    [workspaces]
  )

  return projectPlanPriceId || workspacePlanPriceId ? (
    <>
      <PickerWrapper>
        <PickerOverlay onClick={clearParams} />
        <Box width="350px" zIndex="1">
          <CommonPicker
            title={`Upgrade ${projectPlanPriceId ? 'project' : 'workspace'} plan`}
            onChange={value =>
              projectPlanPriceId
                ? setProject(projects.find(el => el.id === value) || null)
                : setWorkspace(workspaces.find(el => el.id === value) || null)
            }
            values={projectPlanPriceId ? projectsValues : workspacesValues}
            close={clearParams}
            height="400px"
            noCloseAfterChange
          />
        </Box>
      </PickerWrapper>
      {projectPlanPriceId ? ChangeProjectPlanAlert : ChangeWorkspacePlanAlert}
    </>
  ) : (
    <></>
  )
}
