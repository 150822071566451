import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'

import * as styled from './InviteItem.styled'

import { Button, ButtonColor } from 'components'
import { ProgressContext } from 'context'
import { Invite } from 'hooks'
import { WorkspacesService } from 'services/workspaces'

interface Props {
  invite: Invite
}

export const InviteItem: React.FC<Props> = ({ invite }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [wait, setWait] = useState(false)
  const { id, message } = invite

  const startLoading = () => {
    setWait(true)
    startLoader()
  }

  const stopLoading = () => {
    stopLoader()
    setWait(false)
  }

  const accept = () => {
    startLoading()
    WorkspacesService.accept(id)
      .catch(err => toast(err))
      .finally(() => stopLoading())
  }

  const decline = () => {
    startLoading()
    WorkspacesService.decline(id)
      .catch(err => toast(err))
      .finally(() => stopLoading())
  }

  return (
    <styled.InviteItem>
      <Box display="flex" alignItems="center" mb="6px" gap="10px">
        <Box fontWeight="600">You were invited to the workspace</Box>
        <styled.Tag>Invite</styled.Tag>
      </Box>
      <Box>{message}</Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt="20px">
        <styled.Date>July 9 th 2023, 12:30 PM</styled.Date>
        <Box display="flex" gap="10px">
          <Button color={ButtonColor.CUSTOM_DELETE} onClick={decline} disabled={wait}>
            Decline
          </Button>
          <Button onClick={accept} disabled={wait}>
            Accept
          </Button>
        </Box>
      </Box>
    </styled.InviteItem>
  )
}
