import styled from 'styled-components'

export const Container = styled.div<{ active?: boolean }>`
  outline: 1px solid ${props => (props.active ? props.theme.colors.primaryTextHover : props.theme.colors.darkBlue_100)};
`

export const IconContainer = styled.div<{ color?: string }>`
  cursor: pointer;
  width: 120px;
  height: 120px;
  border-radius: 32px;
  background-color: ${props => props.color};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
