import React from 'react'
import { ISvg, Svg } from '../Svg'

const TabBar: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 32 32" fill="none" {...props}>
    <rect x="2.25" y="9.25" width="27.5" height="13.5" rx="1.75" stroke="#7F899E" strokeWidth="1.5" />
    <rect x="5" y="13" width="6" height="6" rx="1" fill="#7F899E" />
    <rect x="21" y="13" width="6" height="6" rx="1" fill="#7F899E" />
    <rect x="13" y="13" width="6" height="6" rx="1" fill="#7F899E" />
  </Svg>
)

export default TabBar
