import React, { useRef, useState } from 'react'

import * as styled from './Popover.styled'

import { useOnClickOutside } from 'hooks'
import { Icon, Name } from '../Icon'

interface Props {
  text: string
}

export const Popover: React.FC<Props> = ({ text }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  return (
    <styled.PopoverBox ref={ref}>
      <styled.IconBox onClick={() => setOpen(open => !open)}>
        <Icon name={Name.ADDITIONAL_POPOVER_ICON} />
      </styled.IconBox>
      {open && <styled.Text dangerouslySetInnerHTML={{ __html: text }} />}
    </styled.PopoverBox>
  )
}
