import React, { useContext } from 'react'

import { Container, MaxWidthContainer } from '../index.styled'

import { Crumbs, CustomSectionsWrapper, Text, Type } from 'components'
import { WorkspaceContext } from 'context'
import { DangerZone, General, PreviewFeatures } from './sections'

export const WorkspaceSettings: React.FC = () => {
  const { workspace } = useContext(WorkspaceContext)

  return workspace ? (
    <Container>
      <MaxWidthContainer>
        <Crumbs
          icon={null}
          firstCrumbTitle={workspace.name}
          secondCrumbTitle="Workspace settings"
          to={`/workspaces/${workspace.id}`}
        />
        <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
          Workspace members can create, edit, and delete projects. They can also access project data and invite other
          members.
        </Text>
        <CustomSectionsWrapper>
          <General workspace={workspace} />
          <PreviewFeatures />
          <DangerZone workspace={workspace} />
        </CustomSectionsWrapper>
      </MaxWidthContainer>
    </Container>
  ) : null
}
