import React, { useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  CustomSection,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  Icon,
  Name,
} from 'components'
import { ProjectContext } from 'context'
import { CollectionRecords } from 'pages/CMS/pages'
import { collectionNameToFind, extractPath, productsId } from 'services/cms'
import { generateFirestoreId } from 'utils'

export const Products: React.FC = () => {
  const {
    state: {
      data: { collections = [] },
    },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()
  const { collectionId: cId, recordId } = extractPath(pathname.split('/').slice(4).join('/'))
  const collectionId = cId || productsId
  const currentCollection = collections.find(el => collectionNameToFind(el.name) === collectionNameToFind(collectionId))

  const onAddProduct = (e: any) => {
    e.preventDefault()
    navigate(`/projects/${id}/monetization/${collectionId}/${generateFirestoreId()}`)
  }

  return (
    <CustomSection>
      <CustomSectionTitleWithActionWrapper>
        <CustomSectionTitle>Products</CustomSectionTitle>
        <CustomSectionTitleAction to="" onClick={onAddProduct}>
          <Icon name={Name.RIGHT_SIDEBAR_ADD} />
          Add product
        </CustomSectionTitleAction>
      </CustomSectionTitleWithActionWrapper>
      {currentCollection && (
        <CollectionRecords
          page="monetization"
          collectionId={collectionId}
          recordId={recordId}
          currentCollection={currentCollection}
          small
        />
      )}
    </CustomSection>
  )
}
