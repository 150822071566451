import React from 'react'
import { ISvg, Svg } from '../Svg'

const Edit: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M15 5H8.5M1 5H3.5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15 11H12.75M1 11H7.5" stroke="#7F899E" strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="6" cy="5" r="2" stroke="#7F899E" strokeWidth="1.5" />
    <circle cx="10" cy="11" r="2" stroke="#7F899E" strokeWidth="1.5" />
  </Svg>
)

export default Edit
