import React from 'react'
import { ISvg, Svg } from '../Svg'

const Carousel: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <rect x="3.5" y="1.5" width="9" height="13" rx="2.5" stroke="#7F899E" />
    <path d="M4 4H2.5C1.67157 4 1 4.67157 1 5.5V10.5C1 11.3284 1.67157 12 2.5 12H4" stroke="#7F899E" />
    <path d="M12 4H13.5C14.3284 4 15 4.67157 15 5.5V10.5C15 11.3284 14.3284 12 13.5 12H12" stroke="#7F899E" />
  </Svg>
)

export default Carousel
