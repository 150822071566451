import React from 'react'

import { ISvg, Svg } from '../Svg'

const Warn: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21.1566 16.7728L14.4094 4.36967C13.3253 2.54466 10.6827 2.54223 9.59721 4.36967L2.85031 16.7728C1.74212 18.6377 3.0838 20.9992 5.25583 20.9992H18.7505C20.9207 20.9992 22.2648 18.6396 21.1566 16.7728ZM12.0033 18.7608C11.3863 18.7608 10.8841 18.2586 10.8841 17.6417C10.8841 17.0247 11.3863 16.5225 12.0033 16.5225C12.6202 16.5225 13.1224 17.0247 13.1224 17.6417C13.1224 18.2586 12.6202 18.7608 12.0033 18.7608ZM13.1224 14.2842C13.1224 14.9012 12.6202 15.4034 12.0033 15.4034C11.3863 15.4034 10.8841 14.9012 10.8841 14.2842V8.6884C10.8841 8.07144 11.3863 7.56924 12.0033 7.56924C12.6202 7.56924 13.1224 8.07144 13.1224 8.6884V14.2842Z"
      fill="#E3A009"
    />
  </Svg>
)

export default Warn
