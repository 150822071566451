import React from 'react'
import { ISvg, Svg } from '../Svg'

const Add: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <rect width="22" height="22" rx="4" fill="#B8BDC8" />
    <path d="M11 6V16" stroke="#F2F2F3" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 11L16 11" stroke="#F2F2F3" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)
export default Add
