import styled from 'styled-components'

export const AppInfo = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.darkText};
  & > div {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 17px;
    }
    & b {
      min-width: 102px;
      margin-right: 50px;
      font-weight: 500;
    }
    & > a {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${props => props.theme.colors.primaryText};
      & svg {
        margin-top: 2px;
        & path {
          stroke: ${props => props.theme.colors.primaryText};
        }
      }
      &:hover {
        color: ${props => props.theme.colors.primaryTextHover};
        & svg path {
          stroke: ${props => props.theme.colors.primaryTextHover};
        }
      }
    }
  }
`
