import { Box } from '@mui/material'
import { singular } from 'pluralize'
import { memo, useEffect, useState } from 'react'

import * as styled from 'components/Menu/Menu.styled'

import { DropDown, Icon, Name, Rotation } from 'components'
import { getCollectionName } from 'services/cms'
import { Collection } from 'utils'

export interface Branch extends Collection {
  children: Branch[]
}

interface Props {
  collection: Branch
  currentCollection?: Collection
  openSchema: (id: string) => void
  openRecords: (id: string) => void
  setToRemove: React.Dispatch<React.SetStateAction<Collection | null>>
  setSubCollectionParentName: (id: string) => void
  lvl: number
}

export const CollectionsItem: React.FC<Props> = memo(
  ({ collection, currentCollection, openRecords, openSchema, setToRemove, setSubCollectionParentName, lvl }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (currentCollection && currentCollection.name.startsWith(`${collection.name}/`)) {
        setOpen(true)
      }
    }, [currentCollection])

    const options = [
      {
        label: 'View/edit records',
        iconName: Name.PICKERS_EDIT_RECORDS,
        onClick: () => openRecords(collection.name),
      },
      {
        label: 'Edit fileds',
        iconName: Name.PICKERS_EDIT_SCHEMA,
        onClick: () => openSchema(collection.name),
      },
      {
        label: 'Start collection',
        iconName: Name.ADDITIONAL_PLUS,
        onClick: () =>
          setSubCollectionParentName(`${collection.name}/{${singular(getCollectionName(collection.name))}Id}`),
      },
      {
        label: 'API documentation',
        iconName: Name.PICKERS_API_DOCUMENTATION,
      },
      {
        label: 'Delete collection',
        iconName: Name.PICKERS_DELETE,
        onClick: () => setToRemove(collection),
        red: true,
      },
    ].filter((el, i) => (collection.isSystem ? i !== 4 : true))

    return (
      <>
        <styled.NavItem
          active={currentCollection?.name === collection.name}
          onClick={() => openRecords(collection.name)}
          hasActions
          lvl={lvl}
          big
        >
          {!!collection.children.length ? (
            <styled.Arrow>
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  setOpen(open => !open)
                }}
                name={Name.ADDITIONAL_NAVIGATION_TREE_ARROW}
                rotation={open ? Rotation.DOWN : undefined}
              />
            </styled.Arrow>
          ) : (
            <styled.Space />
          )}
          <Icon name={Name.ADDITIONAL_COLLECTION} />
          <span>{getCollectionName(collection.name)}</span>
          <Box position="absolute" right="4px" onClick={e => e.stopPropagation()}>
            <DropDown
              options={options}
              top="15px"
              width="196px"
              right="-5px"
              scrollIntoView
              labelElement={
                <styled.ShowMoreActions>
                  <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} width={14} />
                </styled.ShowMoreActions>
              }
            />
          </Box>
        </styled.NavItem>
        {open &&
          collection.children.map(el => (
            <CollectionsItem
              key={el.name}
              collection={el}
              currentCollection={currentCollection}
              openSchema={openSchema}
              openRecords={openRecords}
              setToRemove={setToRemove}
              setSubCollectionParentName={setSubCollectionParentName}
              lvl={lvl + 1}
            />
          ))}
      </>
    )
  }
)
