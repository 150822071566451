import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from '../DropDown/DropDown.styled'

import { Icon, Name, Rotation } from 'components'
import { useOnClickOutside } from 'hooks'

export enum SelectArrowVariant {
  DEFAULT_S16 = 'default',
  BIG_S24 = 'big',
  TEMPLATES_S20 = 'templates',
}

export interface ISelectOption {
  value: string
  visibleElement?: ReactElement
  icon?: ReactElement
  onClick?: () => void
  color?: string
  style?: React.CSSProperties
}

interface ISelect {
  children?: React.ReactNode
  options: ISelectOption[]
  initValue?: string
  outerValue?: boolean
  clearValueOnClose?: boolean
  onClick?: any
  disabled?: boolean
  width?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
  arrowVariant?: SelectArrowVariant
  optionsWrapperStyle?: React.CSSProperties
  eachOptionStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
}

export const Select: React.FC<ISelect> = ({
  options,
  initValue,
  outerValue,
  onClick,
  disabled,
  width = '190px',
  top,
  right,
  bottom,
  left,
  children,
  arrowVariant = SelectArrowVariant.DEFAULT_S16,
  optionsWrapperStyle,
  eachOptionStyle,
  labelStyle,
  wrapperStyle,
}) => {
  const themeContext = useContext(ThemeContext)
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  const [currentOption, setCurrentOption] = useState(initValue || options[0]?.value)

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const handleOptionClick = (val: string, onOptionClick?: any) => {
    if (!outerValue) {
      setCurrentOption(val)
    }
    if (onClick) {
      onClick(val)
    }
    if (onOptionClick) {
      onOptionClick()
    }
    setOpen(false)
  }

  const currentValue = options.find(option => option.value === currentOption)

  return (
    <styled.Wrapper ref={ref} style={wrapperStyle}>
      <styled.Label style={labelStyle} onClick={() => setOpen(open => !open)} disabled={disabled}>
        {currentValue?.visibleElement || <span>{currentValue?.value || initValue}</span>}
        {arrowVariant === SelectArrowVariant.DEFAULT_S16 && (
          <Icon rotation={open ? Rotation.LEFT : undefined} name={Name.REPOSITORY_DROPDOWN_ARROW} />
        )}
        {arrowVariant === SelectArrowVariant.BIG_S24 && (
          <Icon rotation={open ? Rotation.LEFT : undefined} name={Name.SIZE_24_ARROW} />
        )}
        {arrowVariant === SelectArrowVariant.TEMPLATES_S20 && (
          <Icon
            rotation={open ? Rotation.LEFT : undefined}
            width={22}
            height={22}
            name={Name.REPOSITORY_DROPDOWN_ARROW}
          />
        )}
        {open && (
          <styled.OptionsWrapper
            width={width}
            top={top || '40px'}
            right={right || '0'}
            bottom={bottom}
            left={left}
            style={optionsWrapperStyle}
            onClick={e => e.stopPropagation()}
          >
            {filteredOptions.map((option, index) => {
              if (option.value.includes('Delete')) {
                option.color = themeContext.colors.logOut
              }
              return (
                <React.Fragment key={`${option.value}-${index}`}>
                  <styled.Option
                    onClick={() => handleOptionClick(option.value, option.onClick)}
                    hasOwnDropDown={false}
                    color={option.color}
                    style={eachOptionStyle || option.style}
                  >
                    <div>
                      {option.icon}
                      {option?.visibleElement || <span>{option?.value}</span>}
                    </div>
                  </styled.Option>
                </React.Fragment>
              )
            })}
            {children}
          </styled.OptionsWrapper>
        )}
      </styled.Label>
    </styled.Wrapper>
  )
}
