import ReactDOMServer from 'react-dom/server'

import { i13, i14, urlToBase64 } from 'utils'
import {
  backgroundWidth,
  containerHeight,
  Device,
  IBackground,
  IDevice,
  IImage,
  iPhone13ProHeight,
  iPhone13ProWidth,
  iPhone14ProHeight,
  iPhone14ProWidth,
  IText,
  marginWidth,
} from './common'

export const addBackground = async ({ id, background }: IBackground, backgroundsContainer?: SVGElement) => {
  const old = document.getElementById(id) as any
  const backgroundContainer = old || document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
  backgroundContainer.id = id
  backgroundContainer.setAttribute('width', String(backgroundWidth))
  backgroundContainer.setAttribute('height', String(containerHeight))

  const div = old ? old.querySelector('div') : document.createElement('div')
  div.style.width = '100%'
  div.style.height = '100%'
  const url = background.slice(5, -24)
  if (background.startsWith('url(') && url) {
    await urlToBase64(url, res => (div.style.background = background.replace(url, res)))
  } else {
    div.style.background = background
  }
  if (!old) {
    backgroundContainer.appendChild(div)
    backgroundsContainer?.appendChild(backgroundContainer)
  }

  return backgroundContainer
}

export const addBackgroundBorder = (
  pos: number,
  id: string,
  buttonColor: string,
  backgroundsBordersContainer: SVGElement
) => {
  const borderId = 'border-' + id
  const old = document.getElementById(borderId) as any
  const backgroundBorder = old || document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
  backgroundBorder.id = borderId
  backgroundBorder.setAttribute('x', String(pos * (backgroundWidth + marginWidth) - 2))
  backgroundBorder.setAttribute('y', String(-2))
  backgroundBorder.setAttribute('width', String(backgroundWidth + 4))
  backgroundBorder.setAttribute('height', String(containerHeight + 4))
  backgroundBorder.style.pointerEvents = 'none'

  const div = old ? old.querySelector('div') : document.createElement('div')
  div.style.width = '100%'
  div.style.height = '100%'
  div.style.pointerEvents = 'none'
  div.style.border = `2px solid ${buttonColor}`
  if (!old) {
    backgroundBorder.appendChild(div)
    backgroundsBordersContainer.appendChild(backgroundBorder)
  }
}

export const addMargin = (pos: number, id: string, marginsContainer: SVGElement) => {
  if (pos !== 0) {
    const marginId = 'margin-' + id
    const old = document.getElementById(marginId) as any
    const margin = old || document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    margin.id = marginId
    margin.setAttribute('x', String(pos * (backgroundWidth + marginWidth) - marginWidth))
    margin.setAttribute('width', String(marginWidth))
    margin.setAttribute('height', String(containerHeight))
    margin.setAttribute('fill', '#FFFFFFFA')
    margin.style.pointerEvents = 'none'
    if (!old) {
      marginsContainer.appendChild(margin)
    }
  }
}

export const addText = (
  {
    id,
    x,
    y,
    width,
    height,
    angle,
    value,
    color,
    fontFamily,
    fontWeight,
    fontStyle,
    fontSize,
    textAlign,
    verticalAlign,
    lineHeight,
    letterSpacing,
  }: IText,
  elementsContainer?: SVGElement
) => {
  const old = document.getElementById(id) as any
  const textContainer = old || document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
  textContainer.id = id
  textContainer.setAttribute('x', String(x))
  textContainer.setAttribute('y', String(y))
  textContainer.setAttribute('width', String(width))
  textContainer.setAttribute('height', String(height))
  textContainer.style.overflow = 'visible'
  textContainer.style.transform = `rotate(${angle}deg)`
  textContainer.style.transformBox = 'fill-box'
  textContainer.style.transformOrigin = 'center'

  const textWrapper = old ? old.querySelector('div') : document.createElement('div')
  textWrapper.style.display = 'flex'
  textWrapper.style.alignItems = verticalAlign
  textWrapper.style.height = '100%'

  const text = old ? old.querySelector('span') : document.createElement('span')
  text.style.outline = 'unset'
  text.style.width = '100%'
  text.style.fontFamily = fontFamily
  text.style.fontWeight = fontWeight
  text.style.fontStyle = fontStyle
  text.style.fontSize = fontSize + 'px'
  text.style.lineHeight = lineHeight + '%'
  text.style.letterSpacing = letterSpacing + 'px'
  text.style.textAlign = textAlign
  text.style.color = color
  text.style.wordBreak = 'break-all'
  if (!text.getAttribute('contenteditable')) {
    text.innerText = value
  }

  if (!old) {
    textWrapper.appendChild(text)
    textContainer.appendChild(textWrapper)
    elementsContainer?.appendChild(textContainer)
  }

  return textContainer
}

export const addImage = async ({ id, x, y, width, height, angle, url }: IImage, elementsContainer?: SVGElement) => {
  const old = document.getElementById(id) as any
  const image = old || document.createElementNS('http://www.w3.org/2000/svg', 'image')
  image.id = id
  image.setAttribute('x', String(x))
  image.setAttribute('y', String(y))
  image.setAttribute('width', String(width))
  image.setAttribute('height', String(height))
  await urlToBase64(url, res => image.setAttribute('href', res))
  image.style.transform = `rotate(${angle}deg)`
  image.style.transformBox = 'fill-box'
  image.style.transformOrigin = 'center'

  if (!old) {
    elementsContainer?.appendChild(image)
  }

  return image
}

export const addDevice = async (
  { id, x, y, width, height, angle, deviceType, color, image }: IDevice,
  elementsContainer?: SVGElement
) => {
  const old = document.getElementById(id) as any
  const deviceContainer = old || document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
  deviceContainer.id = id
  deviceContainer.setAttribute('x', String(x))
  deviceContainer.setAttribute('y', String(y))
  deviceContainer.style.transform = `rotate(${angle}deg)`
  deviceContainer.style.transformBox = 'fill-box'
  deviceContainer.style.transformOrigin = 'center'

  const svg = old ? old.querySelector('svg') : document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
  svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')
  svg.setAttribute('width', '100%')
  svg.setAttribute('height', '100%')

  const imageContainer = old
    ? old.querySelector('foreignObject')
    : document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')

  const div = old ? old.querySelector('div') : document.createElement('div')
  div.style.width = '100%'
  div.style.height = '100%'
  if (image.startsWith('https://')) {
    await urlToBase64(image, res => (div.style.backgroundImage = `url(${res})`))
  } else if (image.startsWith('data:')) {
    div.style.backgroundImage = `url(${image})`
  } else {
    div.style.backgroundImage = 'url()'
  }
  div.style.backgroundSize = 'cover'
  div.style.backgroundPosition = 'center center'

  if (!old) {
    imageContainer.appendChild(div)
    svg.appendChild(imageContainer)
    deviceContainer.appendChild(svg)
  }

  switch (deviceType) {
    case Device.iPhone13Pro:
      deviceContainer.setAttribute('width', String(width || iPhone13ProWidth))
      deviceContainer.setAttribute('height', String(height || iPhone13ProHeight))

      svg.setAttribute('viewBox', i13.viewBox)

      imageContainer.setAttribute('x', String(i13.inner.x))
      imageContainer.setAttribute('y', String(i13.inner.y))
      imageContainer.setAttribute('width', String(i13.inner.width))
      imageContainer.setAttribute('height', String(i13.inner.height))

      div.style.borderRadius = i13.inner.borderRadius + 'px'

      if (!old) {
        svg.innerHTML += ReactDOMServer.renderToStaticMarkup(i13.frame(color))
      } else {
        svg.innerHTML = ''
        svg.appendChild(imageContainer)
        svg.innerHTML += ReactDOMServer.renderToStaticMarkup(i13.frame(color))
      }

      break
    case Device.iPhone14Pro:
      deviceContainer.setAttribute('width', String(width || iPhone14ProWidth))
      deviceContainer.setAttribute('height', String(height || iPhone14ProHeight))

      svg.setAttribute('viewBox', i14.viewBox)

      imageContainer.setAttribute('x', String(i14.inner.x))
      imageContainer.setAttribute('y', String(i14.inner.y))
      imageContainer.setAttribute('width', String(i14.inner.width))
      imageContainer.setAttribute('height', String(i14.inner.height))

      div.style.borderRadius = i14.inner.borderRadius + 'px'

      if (!old) {
        svg.innerHTML += ReactDOMServer.renderToStaticMarkup(i14.frame())
      } else {
        svg.innerHTML = ''
        svg.appendChild(imageContainer)
        svg.innerHTML += ReactDOMServer.renderToStaticMarkup(i14.frame())
      }

      break
  }

  if (!old) {
    elementsContainer?.appendChild(deviceContainer)
  }

  return deviceContainer
}

const addLine = (linesContainer: SVGElement, id: string, x1: number, x2: number, y1: number, y2: number) => {
  const line =
    linesContainer.querySelector(`line[id*="${id}"]`) || document.createElementNS('http://www.w3.org/2000/svg', 'line')
  line.id = id
  line.setAttribute('x1', String(x1))
  line.setAttribute('x2', String(x2))
  line.setAttribute('y1', String(y1))
  line.setAttribute('y2', String(y2))
  line.setAttribute('stroke-dasharray', '4')
  linesContainer.appendChild(line)
}

export const addLines = (
  element: SVGElement,
  x: number,
  y: number,
  width: number,
  height: number,
  angle: number,
  linesContainer: SVGElement
) => {
  addLine(linesContainer, `line-${element.id}-top`, -10000, 10000, y, y)
  addLine(linesContainer, `line-${element.id}-center-horizontal`, -10000, 10000, y + height / 2, y + height / 2)
  addLine(linesContainer, `line-${element.id}-bottom`, -10000, 10000, y + height, y + height)
  addLine(linesContainer, `line-${element.id}-left`, x, x, -10000, 10000)
  addLine(linesContainer, `line-${element.id}-center-vertical`, x + width / 2, x + width / 2, -10000, 10000)
  addLine(linesContainer, `line-${element.id}-right`, x + width, x + width, -10000, 10000)
}

export const addElementsLines = (element: SVGElement, linesContainer: SVGElement) => {
  // @ts-ignore
  const { x: aX, y: aY, width: aWidth, height: aHeight } = element.attributes
  const x = +aX.value
  const y = +aY.value
  const width = +aWidth.value
  const height = +aHeight.value
  const angle = +element.style.transform?.replace('rotate(', '').replace('deg)', '') || 0
  addLines(element, x, y, width, height, angle, linesContainer)
}
