import React from 'react'

import * as styled from './AppName.styled'

interface AppNameProps {
  name: string | null
  logoUrl?: string
}

export const AppName: React.FC<AppNameProps> = ({ name, logoUrl }) => (
  <styled.Wrapper>
    <OnlyLogo logoUrl={logoUrl} />
    <OnlyName name={name} />
  </styled.Wrapper>
)

interface LogoProps {
  logoUrl?: string
}

export const OnlyLogo: React.FC<LogoProps> = ({ logoUrl }) => (
  <styled.Logo src={logoUrl || '/assets/app-logo.png'} alt="app-logo" />
)

interface NameProps {
  name: string | null
}

export const OnlyName: React.FC<NameProps> = ({ name }) => (
  <styled.Name hasName={!!name}>{name || 'App name'}</styled.Name>
)
