import React from 'react'
import { Svg, ISvg } from '../../Svg'

export const InBrowserAppsPreview: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.12863 11.5311C0.957123 11.8208 0.957123 12.1792 1.12863 12.4689C3.45855 16.4041 7.45755 19 12 19C16.5424 19 20.5414 16.4041 22.8714 12.4689C23.0429 12.1792 23.0429 11.8208 22.8714 11.5311C20.5414 7.59587 16.5424 5 12 5C7.45755 5 3.45855 7.59587 1.12863 11.5311ZM12 17C14.796 17 17.0625 14.7614 17.0625 12C17.0625 9.23858 14.796 7 12 7C9.20403 7 6.93746 9.23858 6.93746 12C6.93746 14.7614 9.20403 17 12 17Z"
      fill="#476CEC"
    />
    <path
      d="M12 15C13.6776 15 15.0375 13.6569 15.0375 12C15.0375 10.3431 13.6776 9 12 9C11.6887 9 11.3883 9.04625 11.1055 9.13218C11.6329 9.36736 12 9.89135 12 10.5C12 11.3284 11.32 12 10.4812 12C9.86497 12 9.33443 11.6375 9.09631 11.1166C9.00931 11.3959 8.96247 11.6925 8.96247 12C8.96247 13.6569 10.3224 15 12 15Z"
      fill="#476CEC"
    />
  </Svg>
)
