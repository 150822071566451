import { httpClient } from 'utils'
import { host } from './projects'

const postLogicItemRecord = (id: string, name: string, data: { sourceCode: string }) => {
  const url = `${host}/projects/${id}/logic/${name}`
  return httpClient.post(url, data)
}

const updateLogicItemRecord = (projectId: string, name: string, id: string, data: { sourceCode: string }) => {
  const url = `${host}/projects/${projectId}/logic/${name}/${id}`
  return httpClient.put(url, data)
}

const deleteLogicItemRecord = (projectId: string, name: string, id: string) => {
  const url = `${host}/projects/${projectId}/logic/${name}/${id}`
  return httpClient.delete(url)
}

export const LogicService = {
  postLogicItemRecord,
  updateLogicItemRecord,
  deleteLogicItemRecord,
}
