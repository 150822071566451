import React from 'react'
import { ISvg, Svg } from '../Svg'

const Close: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 11 11" fill="none" {...props}>
    <path d="M1 1L10 10" stroke="#948C8C" strokeWidth="2" strokeLinecap="round" />
    <path d="M1 10L10 1" stroke="#948C8C" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)

export default Close
