import { Portal } from '@mui/material'
import { Button, ButtonColor, Crumbs, RightForm } from 'components'
import { memo, useState } from 'react'

import { CoordinateConstant } from 'utils'
import { Map } from './Map'

interface Props {
  value: CoordinateConstant | null
  onChange: (val: CoordinateConstant) => void
  close: () => void
}

export const MapPortal = memo(({ value, onChange, close }: Props) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null)

  const save = () => {
    if (marker) {
      const position = marker.getPosition()
      if (position) {
        onChange({ latitude: String(position.lat()), longitude: String(position.lng()) })
        close()
      }
    }
  }

  return (
    <Portal>
      <RightForm
        showConfirmWhen={false}
        onClose={close}
        title={
          <>
            <Crumbs firstCrumbTitle="Edit record" onFirstCrumbClick={close} secondCrumbTitle="Pick coordinate" small />
            <Button color={ButtonColor.OUTLINED} onClick={save}>
              Save
            </Button>
          </>
        }
        form={<Map value={value} setMarker={setMarker} />}
      />
    </Portal>
  )
})
