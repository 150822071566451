import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Crumbs, getField, Icon, InputProperty, inputs, Name, RightForm, validate } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { LogicService } from 'services/logic'
import { ConfigLogic, Embedder, firstLetterUpperCase, Flow, Indexer, Prompt, Retriever, ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'sourceCode',
    type: ValueType.typescript,
    position: 0,
    isRequired: true,
    hideLabel: true,
  },
]

interface Props {
  name: keyof ConfigLogic
  recordId?: string
  closeAddRef?: () => void
}

export const LogicItemRecord: React.FC<Props> = ({ name, recordId, closeAddRef }) => {
  const title = firstLetterUpperCase(name)
  const itemName = name.slice(0, -1)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const { id, logicItemRecordId = recordId } = useParams()
  const {
    state: { logic },
  } = useContext(ProjectContext)
  const items = logic[name]?.map(el => ({ id: el.name, ...el })) || []
  const item = items.find(el => el.id === logicItemRecordId)
  const dataObjects = item ? [item.input, item.output] : []
  const [runProperties, setRunProperties] = useState<InputProperty[]>([])
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const [idToNavigate, setIdToNavigate] = useState(logicItemRecordId)

  useEffect(() => {
    if (idToNavigate && idToNavigate !== logicItemRecordId) {
      navigate(`/projects/${id}/settings/logic/${name}/${idToNavigate}`)
    }
  }, [idToNavigate])

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {
      setSaveLoading(true)
      startLoader()
      ;(logicItemRecordId
        ? LogicService.updateLogicItemRecord(id as string, name, logicItemRecordId, data)
        : LogicService.postLogicItemRecord(id as string, name, data)
      )
        .then(res => {
          const logicItemId = res.data.name
          setValuesBeforeEdit(JSON.stringify(data))
          if (closeAddRef) {
            closeAddRef()
          } else {
            setIdToNavigate(logicItemId)
          }
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setSaveLoading(false)
        })
    },
  })

  const runFormik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, runProperties, dataObjects),
    onSubmit: data => {},
  })

  useEffect(() => {
    setForm()
  }, [logicItemRecordId, JSON.stringify(dataObjects)])

  const setForm = () => {
    const record: CMSCollectionRecord = {}
    const runRecord: CMSCollectionRecord = {}
    const runProperties: InputProperty[] = [
      {
        name: 'input',
        type: ValueType.keyValueMap,
        position: 0,
        onRun: runFormik.submitForm,
      },
      {
        name: 'output',
        type: ValueType.keyValueMap,
        position: 1,
      },
    ]
    setWait(true)
    if (logicItemRecordId) {
      if (item) {
        properties.forEach(property => {
          record[property.name] = item[property.name as keyof (Flow | Prompt | Indexer | Retriever | Embedder)] || null
        })
        runProperties[0].dataObject = dataObjects[0].name
        runProperties[1].dataObject = dataObjects[1].name
        runProperties.forEach(property => {
          runRecord[property.name] =
            getField(property.type)?.getDefaultValue?.(dataObjects, property.dataObject) || null
        })
      }
    } else {
      properties.forEach(property => {
        record[property.name] = null
      })
      runProperties[0].disabled = true
      runProperties[1].disabled = true
      runProperties.forEach(property => {
        runRecord[property.name] = null
      })
    }
    setValuesBeforeEdit(JSON.stringify(record))
    formik.setValues(record)
    runFormik.setValues(runRecord)
    setRunProperties(runProperties)
    setWait(false)
  }

  const showConfirmWhen = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)
  const closePath = `/projects/${id}/settings/logic`

  return (
    <RightForm
      wide
      showConfirmWhen={showConfirmWhen}
      onClose={() => (closeAddRef ? closeAddRef() : navigate(closePath))}
      title={
        <>
          {closeAddRef ? (
            <Crumbs
              firstCrumbTitle={title}
              onFirstCrumbClick={closeAddRef}
              secondCrumbTitle={`${logicItemRecordId ? 'Edit' : 'Add'} ${itemName}`}
              small
            />
          ) : (
            <Crumbs
              firstCrumbTitle={title}
              secondCrumbTitle={`${logicItemRecordId ? 'Edit' : 'Add'} ${itemName}`}
              icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
              to={closePath}
              small
            />
          )}
          <Button onClick={formik.submitForm} disabled={!showConfirmWhen || saveLoading} loading={saveLoading}>
            Save
          </Button>
        </>
      }
      form={
        wait ? (
          <></>
        ) : (
          <Box display="flex" gap="20px">
            <Box width="710px">{inputs(properties, formik)}</Box>
            <Box width="296px">{inputs(runProperties, runFormik)}</Box>
          </Box>
        )
      }
    />
  )
}
